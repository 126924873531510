import { Event, LocationOn, Pause, Route } from "@mui/icons-material";
import { Button, SpeedDial, SpeedDialAction, SpeedDialIcon, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  onVisitClick: () => void;
  onEventClick: () => void;
  onBreakClick: () => void;
  onRouteClick: () => void;
  isMyDay: boolean;
}

export const ActionButtons: React.FunctionComponent<Props> = ({
  onVisitClick,
  onEventClick,
  onBreakClick,
  onRouteClick,
  isMyDay,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isMobile)
    return (
      <SpeedDial ariaLabel="dodaj" className={classes.mobileFab} icon={<SpeedDialIcon />}>
        {isMyDay && <SpeedDialAction onClick={onVisitClick} tooltipOpen icon={<LocationOn />} tooltipTitle="Wizyta" />}
        <SpeedDialAction onClick={onRouteClick} tooltipOpen icon={<Route />} tooltipTitle="Trasa" />
        {isMyDay && <SpeedDialAction onClick={onBreakClick} tooltipOpen icon={<Pause />} tooltipTitle="Przerwa" />}
        {isMyDay && <SpeedDialAction onClick={onEventClick} tooltipOpen icon={<Event />} tooltipTitle="Inne" />}
      </SpeedDial>
    );

  return (
    <div className={classes.desktopButtons}>
      {isMyDay && (
        <Button variant="contained" color="primary" sx={{ marginRight: 1 }} onClick={onVisitClick}>
          <LocationOn sx={{ marginRight: 0.5 }} />
          Wizyta
        </Button>
      )}
      <Button variant="contained" sx={{ marginRight: 1 }} onClick={onRouteClick}>
        <Route sx={{ marginRight: 0.5 }} />
        Trasa
      </Button>
      {isMyDay && (
        <Button variant="contained" onClick={onEventClick}>
          <Event sx={{ marginRight: 0.5 }} />
          Inne
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  desktopButtons: {
    marginBottom: theme.spacing(2),
  },
  mobileFab: {
    position: "fixed",
    zIndex: 10001,
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
}));
