import { useQueries } from "react-query";

import { visitResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";

export const useUserWeekVisits = (dates: Date[], userId: number) => {
  const provider = useResourceProviderV2(visitResource);

  const queries = useQueries(
    dates.map((date) => ({
      queryKey: ["visitsUserDay", date, userId],
      queryFn: () => provider.userDay({ userId: userId!, date }).then((r) => r.assertOk().value),
    })),
  );

  const visits = queries.flatMap((q) => q.data ?? []);
  const isLoading = queries.some((q) => q.isFetching);

  return { visits, isLoading, refetch: () => queries.forEach((q) => q.refetch()) };
};
