import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { Uuid } from "@megarax/common";
import { DesignateHomeInput, SetManagerInput } from "@megarax/crm-contracts";
import { useProfile, useProfilesDictionary, useUsersResource } from "@megarax/iam-webapp";

import { useRegionsResource } from "../../resourceHooks";
import { useSalespeopleResource } from "../useSalespeopleResource";
import { SalespersonDetails } from "./SalespersonDetails";

interface Props {
  userId: number;
}

export const SalespersonDetailsContainer: React.FC<Props> = ({ userId }) => {
  const history = useHistory();
  const salespeopleResource = useSalespeopleResource();
  const regionsResource = useRegionsResource();
  const userResource = useUsersResource();

  const { data: salesperson, isFetching: isSalespeopleFetching, refetch } = useQuery(
    ["salesperson", userId],
    () => salespeopleResource.retrieve(userId),
    {},
  );

  const { data: regionsDictionary, isFetching: isRegionsFetching } = useQuery(
    ["regions"],
    regionsResource.getRegionsDictionary,
    {},
  );

  const profile = useProfile({ id: userId });

  const profilesDict = useProfilesDictionary(
    salesperson?.value?.managerId ? [{ id: salesperson.value.managerId }] : [],
  );
  const managerProfile =
    salesperson?.value?.managerId && profilesDict ? profilesDict[salesperson.value.managerId] : undefined;

  const navigateToVisitSchedule = () => history.push(`/crm/handlowcy/${userId}/wizyty`);

  return (
    <>
      <SalespersonDetails
        regionsDictionary={regionsDictionary}
        salesperson={salesperson}
        profile={profile}
        managerProfile={salesperson?.value?.managerId !== null ? managerProfile : null}
        navigateToVisitSchedule={navigateToVisitSchedule}
        dataLoading={profile === undefined || isSalespeopleFetching || isRegionsFetching}
        reassignRegion={(regionUuid: Uuid) =>
          salespeopleResource.updateRegion({ onSuccess: refetch })(userId, { regionUuid })
        }
        getRegions={(searchText: string) => regionsResource.getRegionsItems({ searchText, limit: 50 })}
        getUsers={userResource.getUsers}
        setWorkStartTime={(workStartTime: number) =>
          salespeopleResource.setWorkStartTime({ onSuccess: refetch })(userId, { workStartTime })
        }
        designateHome={(input: DesignateHomeInput) =>
          salespeopleResource.designateHome({ onSuccess: refetch })(userId, input)
        }
        setManager={(input: SetManagerInput) => salespeopleResource.setManager({ onSuccess: refetch })(userId, input)}
      />
    </>
  );
};
