import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const userPreferencesSerializer = Serializers.object({
  testPreference1: Serializers.string,
  testPreference2: Serializers.string,
  proStatusAcknowledgedAt: Serializers.datetime,
  tosAcknowledgedAt: Serializers.datetime,
  dv21NewsAcknowledgedAt: Serializers.datetime,
  c50sNewsAcknowledgedAt: Serializers.datetime,
  gv15NewsAcknowledgedAt: Serializers.datetime,
  lastSeenKilogramsStatus: Serializers.decimal,
  lastSeenPointsStatus: Serializers.integer,
}).partial();

export type UserPreferences = SerializerValue<typeof userPreferencesSerializer>;

export const userPreferencesActions = {
  myPreferences: HttpAction({
    path: "/myPreferences",
    method: "get",
    valueSerializer: userPreferencesSerializer,
    requiresAuth: true as const,
  }),
  saveMyPreferences: HttpAction({
    path: "/myPreferences",
    method: "post",
    bodySerializer: userPreferencesSerializer,
    requiresAuth: true as const,
    errorSerializer: Serializers.stringOneOf("AccountNotFound"),
  }),
};
