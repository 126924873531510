import type { PubSub } from "@google-cloud/pubsub";

import { Serializer } from "@megaron/serializers";

export type PubsubTopic<T> = {
  name: string;
  serializer: Serializer<T>;
};

export const PubsubTopic = <T>(topic: PubsubTopic<T>): PubsubTopic<T> => topic;

export type PubsubClient = {
  pubsub: PubSub;
  prefix: string;
};
