import { UnsafeSerializer } from "../serializer";

export const optional = <T>(serializer: UnsafeSerializer<T>): UnsafeSerializer<T | undefined> => ({
  ...serializer,
  serialize: (value) => {
    if (value === undefined) return value;
    return serializer.serialize(value);
  },
  forceDeserialize: (raw) => {
    if (raw === undefined) return raw;
    return serializer.forceDeserialize(raw);
  },
});
