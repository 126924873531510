import { useTheme } from "@emotion/react";

import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { DocType, IndexModuleDto } from "@megaron/docs-contracts";

import { DocsIndexDialogElement } from "./DocsIndexDialogElement";
import { RefreshButton } from "./RefreshButton";

type Props = {
  docsIndexModules: IndexModuleDto[];
  onRefresh: (module: DocType) => void;
  isRefreshing: boolean;
  refreshingDoctype: DocType | null;
  isLoading: boolean;
};

export const DocsTable: React.FC<Props> = ({
  docsIndexModules,
  onRefresh,
  isLoading,
  refreshingDoctype,
  isRefreshing,
}) => {
  const theme = useTheme();

  const tableColumns: TableHeadColumn<string>[] = [
    {},
    ...tableHeadingsList.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (entry: IndexModuleDto): RowCellContent[] => {
    return [
      {
        cellCss: {
          padding: "0",
          width: "2.5rem",
          overflow: "hidden",
          position: "relative",
        },
        element: (
          <>
            <div
              css={{
                position: "absolute",
                inset: 0,
                background: theme.colors.primaryLight,
                display: "block",
              }}
            ></div>
            <DocsIndexDialogElement
              docsIndex={entry}
              onRefresh={() => onRefresh(entry.docType)}
              isRefreshLoading={isRefreshing && refreshingDoctype === entry.docType}
            />
          </>
        ),
      },
      {
        isLink: true,
        element: entry.docType,
      },
      {
        isLink: true,
        element: entry.lastRefreshAt?.toLocaleDateString() ?? "-",
      },
      {
        isLink: true,
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "10rem" }}>
            {entry.indexAlias}
          </span>
        ),
      },
      {
        isLink: true,
        cellCss: { overflow: "hidden" },
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "15rem" }}>
            {entry.indexVersionName}
          </span>
        ),
      },
      {
        isLink: true,
        cellCss: { overflow: "hidden" },
        element: (
          <span
            css={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "20rem",
              minWidth: "10rem",
              color: theme.colors.danger,
            }}
          >
            {JSON.stringify(entry.setupError) ?? "-"}
          </span>
        ),
      },
      {
        element: (
          <RefreshButton
            onClick={() => onRefresh(entry.docType)}
            isLoading={isRefreshing && refreshingDoctype === entry.docType}
          />
        ),
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
      <Table columns={tableColumns}>
        <TableBody
          isLoading={isLoading}
          rows={docsIndexModules.map((entry) => ({
            uuid: entry.indexAlias,
            linkTo: `/dev/docs/${entry.indexVersionName}`,
            cellsContent: getTableRowCellsContent(entry),
            css: {
              "&:hover": {
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

const tableHeadingsList = ["Doctype", "Last refreshed at", "Index alias", "Index version name", "Error", "Actions"];
