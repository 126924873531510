import { Serializer, Serializers } from "@megaron/serializers";

export type HttpService = {
  [name: string]: HttpAction<any, any, any, any, any, boolean> | HttpSseAction<any, any, any, any, any, boolean>;
};

export const HttpService = <TSchema extends HttpService>(input: TSchema): TSchema => input;

export type HttpAction<TValue, TError, TBody, TParams, TQs, TAuth extends boolean> = {
  method: HttpMethod;
  path: string;
  valueSerializer: Serializer<TValue>;
  errorSerializer: Serializer<TError>;
  bodySerializer?: Serializer<TBody>;
  paramsSerializer?: Serializer<TParams>;
  qsSerializer?: Serializer<TQs>;
  requiresAuth: TAuth;
  sse: false;
};

export const HttpAction = <
  TValue = unknown,
  TError = unknown,
  TBody = unknown,
  TParams = unknown,
  TQs = unknown,
  TAuth extends boolean = false,
>(input: {
  path: string;
  method?: HttpMethod;
  valueSerializer?: Serializer<TValue>;
  errorSerializer?: Serializer<TError>;
  bodySerializer?: Serializer<TBody>;
  paramsSerializer?: Serializer<TParams>;
  qsSerializer?: Serializer<TQs>;
  requiresAuth?: TAuth;
}): HttpAction<TValue, TError, TBody, TParams, TQs, TAuth> => ({
  path: input.path,
  method: input.method ?? "get",
  errorSerializer: input.errorSerializer ?? Serializers.identity(),
  valueSerializer: input.valueSerializer ?? Serializers.identity(),
  bodySerializer: input.bodySerializer,
  paramsSerializer: input.paramsSerializer,
  qsSerializer: input.qsSerializer,
  requiresAuth: (input.requiresAuth ?? false) as TAuth,
  sse: false,
});

export type HttpMethod = "post" | "get" | "delete" | "put" | "patch";

export type HttpSseAction<TValue, TError, TBody, TParams, TQs, TAuth extends boolean> = {
  sse: true;
  path: string;
  valueSerializer: Serializer<TValue>;
  errorSerializer: Serializer<TError>;
  bodySerializer?: Serializer<TBody>;
  paramsSerializer?: Serializer<TParams>;
  qsSerializer?: Serializer<TQs>;
  requiresAuth: TAuth;
};

export const HttpSseAction = <
  TValue = unknown,
  TError = unknown,
  TBody = unknown,
  TParams = unknown,
  TQs = unknown,
  TAuth extends boolean = false,
>(input: {
  path: string;
  valueSerializer?: Serializer<TValue>;
  errorSerializer?: Serializer<TError>;
  bodySerializer?: Serializer<TBody>;
  paramsSerializer?: Serializer<TParams>;
  qsSerializer?: Serializer<TQs>;
  requiresAuth?: TAuth;
}): HttpSseAction<TValue, TError, TBody, TParams, TQs, TAuth> => ({
  sse: true,
  path: input.path,
  errorSerializer: input.errorSerializer ?? Serializers.identity(),
  valueSerializer: input.valueSerializer ?? Serializers.identity(),
  bodySerializer: input.bodySerializer,
  paramsSerializer: input.paramsSerializer,
  qsSerializer: input.qsSerializer,
  requiresAuth: (input.requiresAuth ?? false) as TAuth,
});
