import { useTheme } from "@emotion/react";
import { formatCurrency } from "@megarax/react-utils";
import { Link } from "react-router-dom-v5-compat";

import { Tile, TileStripe } from "@megaron/dash-tile";
import { DraftListEntry } from "@megaron/invoices-contracts";

import { DeleteButton } from "./DeleteButton";

type Props = {
  drafts: DraftListEntry[];
  queryKey: string | string[];
};

export const AllDraftsMobileList: React.FC<Props> = ({ drafts, queryKey }) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%", marginBottom: "1rem" }}>
      {drafts.map((draft) => (
        <div css={{ position: "relative" }}>
          <Link key={draft.uuid} to={`/faktury/wersje-robocze/${draft.uuid}`}>
            <Tile stripe={<TileStripe color={theme.colors.primary} />}>
              <div
                css={{ display: "flex", flexDirection: "column", gap: "0.5rem", fontSize: "0.875rem", color: "black" }}
              >
                <div css={{ width: "calc(100% - 1rem)", overflow: "hidden", textOverflow: "ellipsis" }}>
                  <span css={{ overflow: "hidden", whiteSpace: "nowrap" }}>{getHeader(draft)}</span>
                </div>
                <span css={{ fontSize: "0.75rem", color: theme.colors.secondaryText }}>{draft.invoice.issuer}</span>
                <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
                  <span css={{ fontWeight: 700 }}>
                    {formatCurrency(draft.invoice.total.toNumber(), draft.invoice.currency)}
                  </span>
                  <span>{draft.invoice.issueDate.toLocaleDateString()}</span>
                </div>
              </div>
            </Tile>
          </Link>
          <div css={{ position: "absolute", top: "0.125rem", right: "0.375rem" }}>
            <DeleteButton draftUuid={draft.uuid} queryKey={queryKey} />
          </div>
        </div>
      ))}
    </div>
  );
};

const getHeader = (draft: DraftListEntry) => {
  if (!draft.title && !draft.invoice.invoiceNumber) {
    return "-";
  }

  if (!draft.title) {
    return draft.invoice.invoiceNumber;
  }

  if (!draft.invoice.invoiceNumber) {
    return draft.title;
  }

  return "-";
};
