import _ from "lodash";

export type DenominatedQuantity<TDenomations extends string> = {
  [denomination in TDenomations]: number;
};

export type DenominationDefinitions<TDenomations extends string> = {
  [denomination in TDenomations]: number;
};

const getLargestDenomination = <T extends string>(
  def: DenominationDefinitions<T>,
) => (Object.keys(def) as T[]).reduce((a, b) => (def[a] > def[b] ? a : b));

const divideWithRemainder = (dividend: number, divisor: number) => {
  const integerQuotient = Math.floor(dividend / divisor);
  const remainder = dividend - integerQuotient * divisor;
  return [integerQuotient, remainder];
};

export const numberToDenominatedQuantity =
  <T extends string>(defs: DenominationDefinitions<T>) =>
  (totalItems: number): DenominatedQuantity<T> => {
    if (_.isEmpty(defs)) return {} as any; // base case

    const thisDenomination = getLargestDenomination(defs);

    const { [thisDenomination]: largestDenominationDef, ...remainingDefs } =
      defs;

    const [thisDenominationQuantity, remainingItemQuantity] =
      divideWithRemainder(totalItems, defs[thisDenomination]);

    return {
      [thisDenomination]: thisDenominationQuantity,
      ...numberToDenominatedQuantity(remainingDefs)(remainingItemQuantity),
    } as DenominatedQuantity<T>;
  };

export const denominatedQuantityToNumber =
  <T extends string>(defs: DenominationDefinitions<T>) =>
  (denominatedQuantity: DenominatedQuantity<T>): number => {
    let sum = 0;
    for (const denominationName in defs) {
      sum += defs[denominationName] * denominatedQuantity[denominationName];
    }
    return sum;
  };
