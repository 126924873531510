import { ValidationError } from "../errors";
import { SerializerExtensions, serializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export const stringOneOf = <T extends string>(...options: T[]): UnsafeSerializer<T> & SerializerExtensions<T> => ({
  serialize: (value) => value,
  forceDeserialize: (raw) => {
    if (options.includes(raw)) return raw;
    const isTruncated = options.length > 5;
    const truncatedOptions = options.slice(0, 5);

    throw new ValidationError(
      `Value must be one of the following: ${truncatedOptions.join(", ")}${
        isTruncated ? ", ..." : ""
      } (${raw} is not).`,
    );
  },
  ...serializerExtensions(),
});
