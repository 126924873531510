import { css, useTheme } from "@emotion/react";
import heic2any from "heic2any";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useQueryClient } from "react-query";

import { useToast } from "@megaron/dash-toast";
import { AttachmentUpload } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { newUuid, Uuid } from "@megaron/uuid";

import { fileListToBase64 } from "../fileListToBase64";

type Props = {
  threadUuid: Uuid;
  queryKey: string | string[];
};

const convertHeicToPng = async (file: File): Promise<File> => {
  const result = await heic2any({ blob: file, toType: "image/png" });
  return new File([result as Blob], file.name.replace(/\.[^.]+$/, ".png"), { type: "image/png" });
};

const createFileList = (files: File[]): FileList => {
  const dataTransfer = new DataTransfer();
  files.forEach(file => dataTransfer.items.add(file));
  return dataTransfer.files;
};

export const AddAttachment: React.FC<Props> = (props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const attachFilesMutation = useClientManager("invoices").attachFiles().useMutation();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        setIsLoading(true);

        const fileConversions = acceptedFiles.map(async (file) => {
          if (file.type === "image/heic") {
            return convertHeicToPng(file);
          }
          return file;
        });

        const convertedFiles = await Promise.all(fileConversions);
        const fileList = createFileList(convertedFiles);
        const newAttachments = await fileListToBase64(fileList);
        handleFileUploads(newAttachments);
      } catch (err) {
        console.log(err);
        toast.error("Nie udało się załadować plików.");
      } finally {
        setIsLoading(false);
      }
    },
    [toast],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleFileUploads = useCallback(
    (attachments: AttachmentUpload[]) => {
      if (attachments.length === 0) return;

      attachFilesMutation.mutate(
        {
          attachments: attachments.map((attachment) => ({
            uuid: newUuid(),
            fileB64: attachment.fileB64,
            fileName: attachment.fileName,
            fileType: attachment.fileType,
          })),
          threadUuid: props.threadUuid,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(props.queryKey);
          },
          onError: (err) => {
            setError(`Error attaching file: ${err}`);
            toast.error("Błąd podczas dodawania plików.");
          },
        },
      );
    },
    [attachFilesMutation, props.threadUuid, props.queryKey, queryClient, toast],
  );

  return (
    <div
      css={{
        position: "relative",
        border: isDragActive ? `2px dashed ${theme.colors.primary}` : `2px dashed ${theme.colors.border}`,
        padding: "10px",
        borderRadius: "8px",
        textAlign: "center",
      }}
    >
      <div
        {...getRootProps()}
        css={{
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
          padding: "16px",
        }}
      >
        <input {...getInputProps()} />
        <span
          css={{
            fontFamily: theme.displayFontFamily,
            fontSize: "14px",
            color: isDragActive ? theme.colors.border : theme.colors.primary,
            marginBottom: "4px",
          }}
        >
          {isLoading ? "Ładowanie ..." : "Dodaj załącznik"}
        </span>
      </div>
    </div>
  );
};
