import { toDateString } from "@megarax/common";
import { workDayResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import makeStyles from "@mui/styles/makeStyles";
import fileDownload from "js-file-download";
import React from "react";
import { useQuery } from "react-query";

import { reportToCsv } from "./reportToCsv";
import { DateRange, WorkTimeReportDialog } from "./WorkTimeReportDialog";

interface Props {
  userId: number;
  onClose: () => void;
}

export const WorkTimeReportDialogContainer: React.FunctionComponent<Props> = ({ userId, onClose }) => {
  const [dateRange, setDateRange] = React.useState<DateRange>([null, null]);
  const workDayProvider = useResourceProviderV2(workDayResource);

  const errorHandler = useSnackbarErrorHandler({ ...commonErrorsMap });

  const isDateRangeSelected = dateRange[0] !== null && dateRange[1] !== null;

  const { data: report } = useQuery(
    ["workTimeReport", userId, ...dateRange],
    () =>
      workDayProvider
        .byUserId(userId)
        .workTimeReport({
          since: toDateString(dateRange[0]!),
          until: toDateString(dateRange[1]!),
        })
        .mapError(errorHandler)
        .then((r) => r.assertOk().value),
    {
      enabled: isDateRangeSelected,
    },
  );

  const download = () => {
    if (!report) return;
    const csv = reportToCsv(report);
    fileDownload(csv, `zestawienie_czasu_pracy.csv`);
  };

  return (
    <WorkTimeReportDialog
      onClose={onClose}
      dateRange={dateRange}
      onDateRangeChange={setDateRange}
      report={isDateRangeSelected ? report : null}
      onDownload={download}
    />
  );
};

const useStyles = makeStyles((theme) => ({}));
