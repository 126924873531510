import { Page, PageHeader } from "@megaron/dash-page";

import { OrderList } from "./OrderList";

export const OrdersHome = () => {
  return (
    <Page>
      <PageHeader>Realizacja nagród</PageHeader>
      <OrderList />
    </Page>
  );
};
