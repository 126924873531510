import { toDateString, Uuid } from "@megarax/common";
import { EventDto, myWorkDayResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import React from "react";

import { ChangeEventDialog, OnComplete } from "./ChangeEventDialog";

interface Props {
  date: Date;
  isOpen: boolean;
  onClose: () => void;
  onChanged: () => void;
  eventUuid: Uuid;
  events: EventDto[];
}

export const ChangeEventDialogContainer: React.FunctionComponent<Props> = ({
  date,
  isOpen,
  onClose,
  onChanged,
  eventUuid,
  events,
}) => {
  const myWorkDayProvider = useResourceProviderV2(myWorkDayResource).byDate(toDateString(date));
  const existing = events.find((e) => e.uuid === eventUuid);

  const errorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
    InvalidEventTime: "Niepoprawne godziny wydarzenia.",
  });

  const fixDate = (time: Date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes());

  const onComplete: OnComplete = (formData) => {
    myWorkDayProvider.events
      .byUuid(eventUuid)
      .changeEvent(undefined, {
        title: formData.title,
        endTime: fixDate(formData.endTime),
        startTime: fixDate(formData.startTime),
        description: formData.description,
      })
      .mapError(errorHandler)
      .map((value) => {
        onChanged();
        onClose();
      });
  };

  const onRemove = () => {
    myWorkDayProvider.events
      .byUuid(eventUuid)
      .removeEvent()
      .mapError(errorHandler)
      .map((value) => {
        onChanged();
        onClose();
      });
  };

  return (
    <ChangeEventDialog
      onClose={onClose}
      isOpen={isOpen}
      key={date.toISOString()}
      onComplete={onComplete}
      initialStartTime={existing?.startTime ?? null}
      initialTitle={existing?.title ?? null}
      initialEndTime={existing?.endTime ?? null}
      initialDescription={existing?.description ?? null}
      onRemove={onRemove}
      showRemoveButton={existing !== undefined}
    />
  );
};
