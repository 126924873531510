import { AnyUser } from "@megaron/access-control";
import { HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";

import { anyDocActions } from "./anyDoc";
import { bcVendorActions } from "./docTypes/bcVendor";
import { budgetActions } from "./docTypes/budgets";
import { customerActions } from "./docTypes/customers";
import { invoiceThreadActions } from "./docTypes/invoiceThread";
import { itemActions } from "./docTypes/items";
import { userActions } from "./docTypes/users";
import { indexModuleActions } from "./indexModuleActions";

export const docsHttpService = HttpService({
  ...customerActions,
  ...userActions,
  ...anyDocActions,
  ...invoiceThreadActions,
  ...indexModuleActions,
  ...budgetActions,
  ...bcVendorActions,
  ...itemActions,
});

export type DocsHandlers = HandlerMap<typeof docsHttpService, AnyUser>;
