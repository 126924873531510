export const PermissionList = <TPerm extends string>(list: TPerm[]) => list;

export const actionPermissions = [
  "bc.orders.allOrders",
  "bc.orders.customerRecentOrders",
  "bc.orders.placeNewOrder",
  "bc.orders.refresh",
  "bc.orders.retrieve",
  "bc.tradeItems.list",
  "bc.tradeItems.refresh",
  "bc.tradeItems.retrieve",
  "bc.tradeItems.retrieveByGtin",
  "chat.threads.acknowledgeMessages",
  "chat.threads.myFeed",
  "chat.threads.post",
  "chat.threads.retrieve",
  "chat.threads.subscribe",
  "chat.threads.unsubscribe",
  "crm.chains.list",
  "crm.chains.nameChain",
  "crm.checklistTemplate.list",
  "crm.checklistTemplate.retrieve",
  "crm.checklistTemplate.save",
  "crm.contactList.allContacts",
  "crm.contactList.removeContact",
  "crm.contactList.retrieve",
  "crm.contactList.saveContact",
  "crm.customerTags.create",
  "crm.customerTags.list",
  "crm.customers.allCustomers",
  "crm.customers.assignToChain",
  "crm.customers.bulkRegisterFromCsv",
  "crm.customers.bulkRetrieve",
  "crm.customers.changeStatus",
  "crm.customers.designateVisitLocation",
  "crm.customers.reassignRegion",
  "crm.customers.registerCustomer",
  "crm.customers.renameCustomer",
  "crm.customers.retrieve",
  "crm.customers.tagCustomer",
  "crm.customers.untagCustomer",
  "crm.fixedRoutes.list",
  "crm.groups.assignGroups",
  "crm.groups.list",
  "crm.groups.myGroups",
  "crm.groups.retrieve",
  "crm.macroregions.list",
  "crm.macroregions.nameMacroregion",
  "crm.myWorkDays.changeBreak",
  "crm.myWorkDays.changeEnd",
  "crm.myWorkDays.changeEvent",
  "crm.myWorkDays.changeStart",
  "crm.myWorkDays.removeBreak",
  "crm.myWorkDays.removeEvent",
  "crm.myWorkDays.retrieve",
  "crm.regions.appendMacroregion",
  "crm.regions.list",
  "crm.regions.nameRegion",
  "crm.regions.retrieve",
  "crm.salespeople.designateHome",
  "crm.salespeople.list",
  "crm.salespeople.register",
  "crm.salespeople.retrieve",
  "crm.salespeople.setManager",
  "crm.salespeople.setWorkStartTime",
  "crm.salespeople.updateRegion",
  "crm.tradeRoutes.archiveTradeRoute",
  "crm.tradeRoutes.getTradeRoutesCount",
  "crm.tradeRoutes.list",
  "crm.tradeRoutes.recalculateDistances",
  "crm.tradeRoutes.renameTradeRoute",
  "crm.tradeRoutes.retrieve",
  "crm.tradeRoutes.retrieve",
  "crm.tradeRoutes.saveAndScheduleTradeRoute",
  "crm.tradeRoutes.saveRouteAsPermanent",
  "crm.tradeRoutes.scheduleTradeRoute",
  "crm.visitChecklist.retrieve",
  "crm.visitChecklist.toggleTask",
  "crm.visitScheduleDays.changeVisitDuration",
  "crm.visitScheduleDays.descheduleVisit",
  "crm.visitScheduleDays.loadFixedRoute",
  "crm.visitScheduleDays.retrieve",
  "crm.visitScheduleDays.scheduleVisit",
  "crm.visits.cancelVisit",
  "crm.visits.changeEndTime",
  "crm.visits.changeStartTime",
  "crm.visits.customerRecentVisits",
  "crm.visits.end",
  "crm.visits.myDay",
  "crm.visits.myUnfinishedVisits",
  "crm.visits.plan",
  "crm.visits.refreshScheduleDay",
  "crm.visits.retrieve",
  "crm.visits.start",
  "crm.visits.userDay",
  "crm.workDays.milageReport",
  "crm.workDays.refreshVisit",
  "crm.workDays.retrieve",
  "crm.workDays.workTimeReport",
  "discussion.orderComments.commentList",
  "discussion.orderComments.post",
  "discussion.priceListProposalComments.commentList",
  "discussion.priceListProposalComments.post",
  "ediConnector.orders.documentErrors",
  "ediConnector.orders.maintenance.syncComarchRelation",
  "ediConnector.orders.maintenance.syncInfiniteDocuments",
  "ediConnector.orders.retrieve",
  "ediConnector.orders.retry",
  "ediConnector.orders.skip",
  "feed.myNotifications.dismiss",
  "feed.myNotifications.list",
  "feed.notifications.refresh",
  "feed.tasks.maintenance.reset",
  "feed.tasks.myTasks",
  "iam.authorization.allUserPermissions",
  "iam.authorization.isUserAllowed",
  "iam.authorization.isUserAllowedEach",
  "iam.authorization.userDirectories",
  "iam.groups.create",
  "iam.groups.list",
  "iam.groups.retrieve",
  "iam.groups.update",
  "iam.me.isAllowed",
  "iam.me.isAllowedEach",
  "iam.me.updateProfile",
  "iam.me.user",
  "iam.permissions.accessibleServices",
  "iam.permissions.authorizedUsers",
  "iam.permissions.list",
  "iam.profiles.all",
  "iam.profiles.retrieve",
  "iam.roles.create",
  "iam.roles.list",
  "iam.roles.retrieve",
  "iam.roles.update",
  "iam.users.create",
  "iam.users.currentUser",
  "iam.users.delete",
  "iam.users.list",
  "iam.users.restore",
  "iam.users.retrieve",
  "iam.users.update",
  "invoices.budgets.addManager",
  "invoices.budgets.create",
  "invoices.budgets.list",
  "invoices.budgets.removeManager",
  "invoices.budgets.retrieve",
  "invoices.budgets.update",
  "invoices.budgets.updateAlert",
  "invoices.invoiceThreads.addComment",
  "invoices.invoiceThreads.allThreads",
  "invoices.invoiceThreads.approve",
  "invoices.invoiceThreads.attachFile",
  "invoices.invoiceThreads.close",
  "invoices.invoiceThreads.correctInvoice",
  "invoices.invoiceThreads.create",
  "invoices.invoiceThreads.inviteParticipant",
  "invoices.invoiceThreads.invoiceIssuerAutocomplete",
  "invoices.invoiceThreads.maintenance.rebuildIndices",
  "invoices.invoiceThreads.myThreads",
  "invoices.invoiceThreads.removeAttachment",
  "invoices.invoiceThreads.reopen",
  "invoices.invoiceThreads.retractBudgetSignature",
  "invoices.invoiceThreads.retractValiditySignature",
  "invoices.invoiceThreads.retrieve",
  "invoices.invoiceThreads.revokeApproval",
  "invoices.invoiceThreads.setAccountingStatus",
  "invoices.invoiceThreads.setAcknowledgement",
  "invoices.invoiceThreads.setDecreeNumber",
  "invoices.invoiceThreads.setReceptionStatus",
  "invoices.invoiceThreads.signBudget",
  "invoices.invoiceThreads.signValidity",
  "invoices.invoiceThreads.similarThreads",
  "invoices.invoiceThreads.titleAutocomplete",
  "pickList.groupings.all",
  "pickList.groupings.assignItem",
  "pickList.groupings.modify",
  "pickList.groupings.removeItem",
  "pickList.pickLists.preview",
  "priceReport.reports.create",
  "priceReport.reports.customerReports",
  "priceReport.reports.maintenance.rebuild",
  "priceReport.reports.myPastReports",
  "priceReport.subjects.all",
  "priceReport.subjects.search",
  "priceReport.subjects.update",
  "pricing.payers.approveProposal",
  "pricing.payers.getEventNotifications",
  "pricing.payers.proposeRuleChange",
  "pricing.payers.rejectProposal",
  "pricing.payers.retractProposal",
  "pricing.payers.retrieve",
  "pricing.priceLists.approveProposal",
  "pricing.priceLists.create",
  "pricing.priceLists.list",
  "pricing.priceLists.maintenance.rebuildIndices",
  "pricing.priceLists.priceListItemDetail",
  "pricing.priceLists.priceListProposals",
  "pricing.priceLists.proposeChanges",
  "pricing.priceLists.rejectProposal",
  "pricing.priceLists.rename",
  "pricing.priceLists.retractProposal",
  "pricing.priceLists.retrieve",
  "pricing.priceLists.upcomingChanges",
  "pricing.pricedItems.maintenance.rebuild",
  "pricing.pricedItems.search",
  "profitMargin.costTable.retrieve",
  "profitMargin.costTable.update",
  "profitMargin.discountTable.currentDiscountRate",
  "profitMargin.discountTable.retrieve",
  "profitMargin.discountTable.update",
  "profitMargin.orders.adjustMargin",
  "profitMargin.orders.maintenance.loadPastOrders",
  "profitMargin.orders.maintenance.rebuildOrderChanges",
  "profitMargin.orders.marginsByOrder",
  "profitMargin.orders.preview",
  "profitMargin.orders.regionMargins",
  "profitMargin.targetTable.retrieve",
  "profitMargin.targetTable.update",
  "sales.orderDrafts.addLine",
  "sales.orderDrafts.createCopy",
  "sales.orderDrafts.discard",
  "sales.orderDrafts.editCustomers",
  "sales.orderDrafts.list",
  "sales.orderDrafts.place",
  "sales.orderDrafts.removeLine",
  "sales.orderDrafts.retrieve",
  "sales.orderDrafts.startDraft",
  "sales.orderDrafts.updateLine",
  "sales.packSizeDefaults.retrieve",
  "sales.packSizeDefaults.setSize",
  "sales.packSizeDefaults.setSizes",
  "search.customers.list",
  "search.customers.maintenance.rebuild",
  "search.customers.maintenance.refresh",
  "search.customers.nearbyCustomers",
  "search.documents.maintenance.refreshAllUsers",
  "search.documents.maintenance.refreshUser",
  "search.documents.maintenance.resetAll",
  "search.documents.search",
  "search.documents.suggest",
  "search.orders.list",
  "search.orders.rebuild",
  "shipping.orders.fastestDeliveryDate",
] as const;

const LegacyPerm = <TServiceId extends string, TActionId extends string>(
  serviceId: TServiceId,
  actionId: TActionId,
): `${TServiceId}.${TActionId}` => `${serviceId}.${actionId}` as any;

const legacyCrudPerms = <TServiceId extends string>(serviceId: TServiceId) =>
  PermissionList([LegacyPerm(serviceId, "detail"), LegacyPerm(serviceId, "list"), LegacyPerm(serviceId, "edit")]);

const LegacyDirectoryPerm = <TAction extends string, TDirectory extends string>(
  action: TAction,
  directory: TDirectory,
): `${TAction}#${TDirectory}` => `${action}#${directory}` as any;

const legacyDecisionPerms = <TDirectory extends string>(dir: TDirectory) =>
  PermissionList([
    LegacyDirectoryPerm("management.decision.list", dir),
    LegacyDirectoryPerm("management.decision.detail", dir),
    LegacyDirectoryPerm("management.decision.resolve", dir),
  ]);

const legacyRegionalPerms = <TAction extends string>(action: TAction) =>
  PermissionList([
    LegacyDirectoryPerm(action, "MarkiObce"),
    LegacyDirectoryPerm(action, "BielskoBiała"),
    LegacyDirectoryPerm(action, "Częstochowa"),
    LegacyDirectoryPerm(action, "Kielce"),
    LegacyDirectoryPerm(action, "Kraków"),
    LegacyDirectoryPerm(action, "Przemyśl"),
    LegacyDirectoryPerm(action, "Bydgoszcz"),
    LegacyDirectoryPerm(action, "Gdańsk"),
    LegacyDirectoryPerm(action, "Koszalin"),
    LegacyDirectoryPerm(action, "Olsztyn"),
    LegacyDirectoryPerm(action, "Szczecin"),
    LegacyDirectoryPerm(action, "Białystok"),
    LegacyDirectoryPerm(action, "Lublin"),
    LegacyDirectoryPerm(action, "Łódź"),
    LegacyDirectoryPerm(action, "Radom"),
    LegacyDirectoryPerm(action, "Warszawa"),
    LegacyDirectoryPerm(action, "JeleniaGóra"),
    LegacyDirectoryPerm(action, "Kalisz"),
    LegacyDirectoryPerm(action, "Poznań"),
    LegacyDirectoryPerm(action, "Wrocław"),
    LegacyDirectoryPerm(action, "ZielonaGóra"),
    LegacyDirectoryPerm(action, "EXPORT"),
  ]);

const legacyCrmPermissions = PermissionList([...legacyCrudPerms("crm.salesperson"), ...legacyCrudPerms("crm.route")]);

const legacyPlmPermissions = PermissionList([
  // PLM
  ...legacyCrudPerms("plm.brand"),
  ...legacyCrudPerms("plm.komponent"),
  ...legacyCrudPerms("plm.productionRecipe"),
  ...legacyCrudPerms("plm.rodzajKomponentu"),
  ...legacyCrudPerms("plm.ingredientGroup"),
  ...legacyCrudPerms("plm.receptura"),
  ...legacyCrudPerms("plm.zastosowanieCategory"),
  ...legacyCrudPerms("plm.produkt"),
  ...legacyCrudPerms("plm.productGroup"),
  ...legacyCrudPerms("plm.czescBudynku"),
  ...legacyCrudPerms("plm.obiektZastosowania"),
  ...legacyCrudPerms("plm.narzedzie"),
  ...legacyCrudPerms("plm.rozpuszczalnik"),
  ...legacyCrudPerms("plm.rodzajOpakowania"),
  ...legacyCrudPerms("plm.podloze"),
  ...legacyCrudPerms("plm.productList"),
  ...legacyCrudPerms("plm.jednostkaSprzedazy"),
  ...legacyCrudPerms("plm.wyrob"),
  ...legacyCrudPerms("plm.language"),
  ...legacyCrudPerms("plm.opakowanie"),
  ...legacyCrudPerms("plm.asortyment"),
  "plm.komponent.dump",
  "plm.receptura.dump",
  "plm.produkt.dump",
  "plm.wyrob.dump",
  "plm.asortyment.dump",
  "plm.productionRecipe.ingredientsTable",
]);

export const permissions = PermissionList([
  "management.decision.list",
  "management.decision.detail",
  "management.decision.resolve",
  "maintenance.maintenance.rebuildIndices",
  "maintenance.maintenance.pendingMigrations",
  "maintenance.maintenance.runMigrations",
  "search.search.search",

  "invoices.invoiceThreads.retrieveAnyThread",
  "invoices.invoiceThreads.retrieveMyThread",
  "invoices.invoiceThreads.moderate",

  ...actionPermissions,
  ...legacyCrmPermissions,
  ...legacyPlmPermissions,
  ...legacyDecisionPerms("plm.productList"),
  ...legacyDecisionPerms("crm.route"),
  ...legacyDecisionPerms("plm.productionRecipe"),
  ...legacyDecisionPerms("plm.produkt"),
  ...legacyDecisionPerms("plm.receptura"),
  ...legacyDecisionPerms("plm.asortyment"),
  ...legacyDecisionPerms("plm.komponent"),
  ...legacyDecisionPerms("plm.wyrob"),
]);

export type Permission = typeof permissions extends (infer T)[] ? T : never;
