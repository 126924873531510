import { DefinitionItem, DefinitionList } from "@megarax/ui-components";
import { Box, Button, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  referenceNumer: string;

  sellToUuid: string | null;
  sellToName: string | null | undefined;
  billToUuid: string | null;
  billToName: string | null | undefined;

  placedByName: string | null;

  onCreateDraft: () => void;
};

export const OrderHeader: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box mb={4}>
      <Box display="flex" flexDirection="row" alignContent="space-between" mt={1}>
        <Typography variant="h5">{props.referenceNumer}</Typography>
        <Box ml="auto">
          <Button onClick={props.onCreateDraft}>Utwórz kopię roboczą</Button>
        </Box>
      </Box>
      {props.placedByName && <span>Złożone przez: {props.placedByName}</span>}
      <Box my={2}>
        <DefinitionList>
          <DefinitionItem term="Nabywca">
            {props.sellToUuid ? (
              <Link to={`/crm/klienci/${props.sellToUuid}`}>{props.sellToName}</Link>
            ) : (
              <span>-</span>
            )}
          </DefinitionItem>
          <DefinitionItem term="Odbiorca faktury">
            {props.billToUuid ? (
              <Link to={`/crm/klienci/${props.billToUuid}`}>{props.billToName}</Link>
            ) : (
              <span>-</span>
            )}
          </DefinitionItem>
        </DefinitionList>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({}));
