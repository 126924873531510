import React from "react";
import { generatePath, matchPath, Route, useHistory, useRouteMatch } from "react-router-dom";

type DialogProps<TParams extends {}> = {
  onClose: () => void;
} & TParams;

export const useDialogWithRoute = <TParams extends {}>(
  pathFragment: string,
  renderDialog: (props: DialogProps<TParams>) => React.ReactNode,
) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const safeToGoBack = React.useRef(false);

  type Open = [keyof TParams] extends [never] ? () => void : (params: TParams) => void;

  const open: Open = (params?: TParams) => {
    const path = generatePath(`${url}/${pathFragment}`, params ?? {});
    history.push(path);
    safeToGoBack.current = true;
  };

  const close = () => {
    if (safeToGoBack.current) {
      history.goBack();
      safeToGoBack.current = false;
    } else {
      history.replace(url);
    }
  };

  const isOpen = matchPath(history.location.pathname, `${path}/${pathFragment}`) !== null;

  const element = (
    <Route
      path={`${path}/${pathFragment}`}
      render={({ match }) => renderDialog({ onClose: close, ...(match.params as TParams) })}
    />
  );
  return { isOpen, open, close, element };
};
