import { DropResult } from "react-beautiful-dnd";

export const useDragAndDrop = <T,>(list: T[], setList: (list: T[]) => void) => {
  const reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(list, result.source.index, result.destination.index);

    setList(items);
  };

  return {
    onDragEnd,
  };
};
