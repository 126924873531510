import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  label?: string;
  options: Option[];
  onChange: (selectedValue: string) => void;
  value?: string | null;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
}

export const Select: React.FC<SelectProps> = (props) => {
  const theme = useTheme();

  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  useEffect(() => {
    if (props.value !== undefined) {
      setSelectedValue(props.value);
    }
  }, [props.value]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    props.onChange(value);
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      {props.label && (
        <span
          css={{
            fontFamily: theme.displayFontFamily,
            fontSize: "14px",
            color: theme.colors.primary,
          }}
        >
          {props.label}
        </span>
      )}
      <select
        disabled={props.isDisabled}
        className={props.className}
        value={selectedValue ?? ""}
        onChange={handleSelectChange}
        css={{
          padding: "0.4375rem 0.5rem",
          border: "none",
          background: theme.colors.primaryLight,
          borderRadius: theme.smallBorderRadius,
          fontSize: "1rem",
          height: "auto",
          color: "#757575",
          ":focus": {
            outlineStyle: "solid",
            outlineColor: theme.colors.primary,
            outlineWidth: "1px",
          },
        }}
      >
        <option value="" disabled>
          {props.placeholder ?? "Wybierz ..."}
        </option>
        {props.options.map((option) => (
          <option key={option.value} value={option.value} css={{ margin: 0 }}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
