import React from "react";

import { DateString, Uuid } from "@megarax/common";
import { visitResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, FeedbackSnackbarContext, useSnackbarErrorHandler } from "@megarax/ui-components";

export const useVisitActions = (uuid: Uuid, visitDate: Date | undefined, onChange: () => any) => {
  const visitProvider = useResourceProviderV2(visitResource);
  const snackbarContext = React.useContext(FeedbackSnackbarContext);
  const errorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
    VisitAlreadyStarted: "Wizyta jest już rozpoczęta.",
    UserIsNotTheVisitOwner: "To nie twoja wizyta.",
    VisitIsCancelled: "Wizyta jest anulowana.",
    VisitAlreadyEnded: "Wizyta została już zakończona.",
    VisitNotStarted: "Wizyta nie została jeszczę rozpoczęta.",
    InvalidVisitDuration: "Niepoprawny czas wizyty.",
    StartTimeInTheFuture: "Godzina rozpoczęcia wizyty nie może być w przyszłości.",
    EndTimeInTheFuture: "Godzina zakończenia wizyty nie może być w przyszłości.",
    InvalidStartTime: "Niepoprawna godzina rozpoczęcia",
    InvalidEndTime: "Niepoprawna godzina zakończenia",
  });

  const fixDate = (date: Date) => (time: Date | null) =>
    time && new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes());

  const cancelVisit = () =>
    visitProvider
      .byUuid(uuid)
      .cancel()
      .mapError(errorHandler)
      .map(() => {
        snackbarContext.pushMessage({ severity: "success", content: "Anulowano wizytę." });
      })
      .map(onChange);

  const start = () => visitProvider.byUuid(uuid).start().mapError(errorHandler).map(onChange);

  const end = () => visitProvider.byUuid(uuid).end().mapError(errorHandler).map(onChange);

  const changeStartTime = (startTime: Date | null) => {
    if (visitDate === undefined) return;
    return visitProvider
      .byUuid(uuid)
      .changeStartTime(undefined, { startTime: fixDate(visitDate)(startTime) })
      .mapError(errorHandler)
      .map(onChange);
  };

  const changeEndTime = (endTime: Date | null) => {
    if (visitDate === undefined) return;
    return visitProvider
      .byUuid(uuid)
      .changeEndTime(undefined, { endTime: fixDate(visitDate)(endTime) })
      .mapError(errorHandler)
      .map(onChange);
  };

  return { cancelVisit, start, end, changeStartTime, changeEndTime };
};
