import { ValidationError } from "../errors";
import { serializerExtensions, SerializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export const array = <T>(
  elementSerializer: UnsafeSerializer<T>,
): UnsafeSerializer<T[]> & SerializerExtensions<T[]> => ({
  serialize: (items) => items.map(elementSerializer.serialize),
  forceDeserialize: (items) => {
    if (!Array.isArray(items)) throw new ValidationError("Value is not an array");
    return items.map(elementSerializer.forceDeserialize);
  },
  ...serializerExtensions(),
});
