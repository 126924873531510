import { CustomerDetailVisitLocation, DesignateVisitLocationInput } from "@megarax/crm-contracts";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { formatAddress } from "../../shared/LocationPickerV2";
import { MapPinDisplay } from "../../shared/MapPinDisplay";
import { ChangeAddressDialog } from "./ChangeAddressDialog";

interface Props {
  visitLocation: CustomerDetailVisitLocation | null;
  onChangeVisitLocation: (input: DesignateVisitLocationInput) => Promise<any>;
}

export const AddressSection: React.FC<Props> = ({ visitLocation, onChangeVisitLocation }) => {
  const classes = useStyles();

  const currentLatLng =
    visitLocation?.lat && visitLocation?.lng
      ? { lat: visitLocation.lat.toNumber(), lng: visitLocation.lng.toNumber() }
      : null;
  const currentAddress = visitLocation ?? {
    name: "",
    locality: "",
    street: "",
    postalCode: "",
    country: "Polska",
  };

  const location = useLocation();
  const navigate = useNavigate();

  const openDialog = () => navigate(`${location.pathname}/zmien_adres`);
  const isDialogOpen = location.pathname.endsWith("zmien_adres");

  const addressFormated = visitLocation ? formatAddress(visitLocation) : "Brak";

  return (
    <Box>
      {isDialogOpen && (
        <ChangeAddressDialog
          currentAddress={currentAddress}
          currentLatLng={currentLatLng}
          onChangeVisitLocation={onChangeVisitLocation}
          onClose={() => navigate(-1)}
        />
      )}
      {currentLatLng && <MapPinDisplay latLng={currentLatLng} height="320px" />}
      <Box px={3} display="flex" justifyContent="space-between" alignItems="center">
        <Box py={1}>
          <Typography variant="subtitle2" className={classes.value}>
            {addressFormated === "" ? "Brak" : addressFormated}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button onClick={openDialog} className={classes.button} color="inherit">
            Edytuj
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  value: {
    fontSize: "1rem",
  },
  button: {
    fontSize: "0.8rem",
  },
}));
