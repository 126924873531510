import { Uuid } from "@megarax/common";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { v4 } from "uuid";

import { Result } from "@megaron/result";

import { NameStep } from "./NameStep";

interface Props {
  nameChain: (uuid: Uuid, name: string) => Promise<Result<void, void>>;
  closeDialog: () => void;
}

export const NewChainDialog: React.FC<Props> = ({ nameChain, closeDialog }) => {
  const [uuid] = useState<Uuid>(v4() as Uuid);

  const onFinalizeCreation = () => {
    closeDialog();
  };

  return (
    <>
      <Box px={3} pt={2}>
        <NameStep
          nameChain={(name) => nameChain(uuid, name)}
          onComplete={onFinalizeCreation}
          closeDialog={closeDialog}
        />
      </Box>
    </>
  );
};
