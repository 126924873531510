import { useTheme } from "@emotion/react";

import { Chip } from "@megaron/dash-form";
import { BlikCheckStatus } from "@megaron/loyalty-orders-contracts";

export const BlikCheckStatusChip = ({ status, isLabeled }: { status: BlikCheckStatus; isLabeled?: boolean }) => {
  const label = isLabeled ? <span css={{ fontWeight: "bold" }}>BLIK</span> : null;
  const theme = useTheme();

  if (status === "bankRejected")
    return (
      <Chip variant="primary" color={theme.colors.danger}>
        {label} Odrzucony przez bank
      </Chip>
    );
  if (status === "cancelled")
    return (
      <Chip variant="primary" color={theme.colors.danger}>
        {label} Anulowany
      </Chip>
    );
  if (status === "pspRejected")
    return (
      <Chip variant="primary" color={theme.colors.danger}>
        {label} Odrzucony przez PSP
      </Chip>
    );
  if (status === "expired")
    return (
      <Chip variant="primary" color={theme.colors.danger}>
        {label} Przeterminowany
      </Chip>
    );
  if (status === "blocked")
    return (
      <Chip variant="secondary" color={theme.colors.danger}>
        {label} Zablokowany
      </Chip>
    );
  if (status === "outstanding") return <Chip variant="secondary">{label} Do realizacji</Chip>;
  if (status === "processing") return <Chip variant="secondary">{label} Przetwarzany przez bank</Chip>;
  if (status === "pending") return <Chip variant="secondary">{label} Przetwarzanie</Chip>;
  if (status === "cashed") return <Chip variant="primary">{label} Zrealizowany</Chip>;

  throw new Error(`Unknown status: ${status}`);
};
