import React from "react";

const svgString = `<svg style="height:1em" width="17" height="29" viewBox="0 0 17 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_91_2229)">
  <path
    d="M17.2386 10.9118C17.1358 10.836 17.0683 10.7286 17.0552 10.6096L17.0542 10.6087C16.9695 9.76879 16.8163 8.42465 16.6107 7.26453C16.2832 5.41701 13.8542 3.058 12.2305 2.17032C11.267 1.64349 10.3659 1.28896 9.79244 1.08689C9.41751 0.956083 9.07887 0.756717 8.78558 0.510441C8.67572 0.418426 8.55377 0.332725 8.43685 0.284011C8.09518 0.141478 7.97928 0.0972746 7.03389 0.0233016C6.06734 -0.0524756 6.55817 0.0530712 5.99074 0.391362C5.72063 0.55284 5.36082 0.63403 5 0.637638V10.5455C11.2972 10.5455 16.4021 11.7047 16.4021 13.1355C16.4021 13.6037 15.8528 14.0421 14.8983 14.4201C16.258 13.9699 17.0643 13.4223 17.0925 12.8315C17.5773 12.5157 17.8423 12.1756 17.8423 11.8211C17.8423 11.5044 17.6307 11.1986 17.2386 10.9118Z"
    fill="currentcolor" />
  <path
    d="M13.9802 12.0457L13.5518 14.6456C13.5206 14.8828 13.4016 15.1039 13.2122 15.2726L12.036 16.3235C11.8132 16.522 11.6883 16.7917 11.6883 17.0732V17.9536C11.6883 18.2739 11.527 18.577 11.2478 18.7773L11.1269 18.8648C10.6683 19.1958 10.0071 19.1742 9.57676 18.8142L8.15465 17.6244C7.9309 17.4367 7.63358 17.3339 7.32718 17.3384L5 17.3754V19.5395C5.00202 19.5395 5.00403 19.5395 5.00605 19.5386C5.33361 19.5522 5.71861 19.5783 5.97058 19.6279C6.40599 19.7127 9.13934 20.1376 9.13934 20.1376C9.13934 20.1376 8.80775 21.2355 8.37235 21.5909C7.93795 21.9464 7.0863 21.6757 6.63276 21.6252C6.28101 21.5855 5.65713 21.3943 5.00605 21.3338C5.00403 21.3338 5.00202 21.3347 5 21.3347V29.0676H6.75673C7.24454 29.0676 7.72227 28.9458 8.13651 28.7149L12.8675 26.0861C13.8955 25.5151 14.5355 24.5219 14.5728 23.4403L15.0082 12.3497L13.9802 12.0457Z"
    fill="currentcolor" />
</g>
<defs>
  <clipPath id="clip0_91_2229">
    <rect width="17" height="29" fill="currentcolor" />
  </clipPath>
</defs>
</svg>`;

export const LoyaltyAdminLogo: React.FunctionComponent<{ className?: string }> = (props) => {
  const createMarkup = () => ({ __html: svgString });

  return React.createElement("div", {
    dangerouslySetInnerHTML: createMarkup(),
    style: {
      height: "1em",
    },
    className: props.className,
  });
};
