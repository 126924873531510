import { Serializer } from "../serializer";
import { array } from "./array";
import { integer } from "./integer";
import { object } from "./object";

export type PaginatedList<T> = {
  items: T[];
  count: number;
};

export const paginatedList = <T>(serializer: Serializer<T>) =>
  object<PaginatedList<T>>({
    items: array(serializer),
    count: integer,
  });
