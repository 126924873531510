import { AttachmentUpload } from "@megaron/invoices-contracts";
import { newUuid } from "@megaron/uuid";

export const fileToBase64 = (file: Blob): Promise<string | null> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) resolve((event.target.result as string).split(",")[1]);
    };
    reader.readAsDataURL(file);
  });
};

export const fileListToBase64 = (files: FileList): Promise<AttachmentUpload[]> => {
  return Promise.all(
    Array.from(files).map(async (file) => {
      const b64 = await fileToBase64(file);
      return {
        fileName: file.name,
        fileType: file.type,
        fileB64: b64 ?? "",
        uuid: newUuid(),
      };
    }),
  );
};
