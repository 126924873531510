import { Failure, Ok } from "@megaron/result";

import { serializerExtensions, SerializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export const boolean: Serializer<boolean> & SerializerExtensions<boolean> = {
  serialize: (value) => {
    if (typeof value !== "boolean") throw new Error("Invalid boolean: " + value);
    return value;
  },
  deserialize: (raw) => {
    if (raw === null || raw === undefined) return Failure("InvalidBoolean");
    return Ok(Boolean(raw));
  },
  ...serializerExtensions(),
};

export const booleanString: Serializer<boolean> & SerializerExtensions<boolean> = {
  serialize: (value) => value.toString(),
  deserialize: (raw) => {
    if (raw === "false") return Ok(false);
    if (raw === "true") return Ok(true);
    else return Failure("InvalidBooleanString");
  },
  ...serializerExtensions(),
};
