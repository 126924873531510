import { authAttributeSerializer } from "@megaron/access-control";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { baseDocSerializer } from "../baseDoc";

export const posCustomerDocSerializer = Serializers.object({
  ...baseDocSerializer("pos-customer").fields,
  uuid: uuidSerializer,
});

export type PosCustomerDoc = SerializerValue<typeof posCustomerDocSerializer>;

export const posCustomerActions = {
  searchPosCustomers: HttpAction({
    path: "/posCustomers",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      isArchived: Serializers.jsonString(Serializers.boolean),
      limit: Serializers.integer,
      offset: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.paginatedList(posCustomerDocSerializer),
    requiresAuth: true,
  }),
};
