import { PriceListSimple, ProposeRuleChangeInput } from "@megarax/crm-contracts";
import { DecimalInput, NewRouterResponsiveSelect, TextInput } from "@megarax/ui-components";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";

export type AddProposal = (input: ProposeRuleChangeInput) => Promise<any>;
export type GetPriceLists = (search: string) => Promise<PriceListSimple[]>;

interface Props {
  addProposal: AddProposal;
  getPriceLists: GetPriceLists;
  closeDialog: () => void;

  open: boolean;
}

interface NewProposal {
  baseDiscount: Decimal | undefined;
  priceListUuid: string | null;
  otherTerms: string;
}

const emptyProposal = {
  baseDiscount: new Decimal(0),
  priceListUuid: null,
  otherTerms: "",
};

export const NewDiscountProposalDialog: React.FC<Props> = ({ addProposal, getPriceLists, open, closeDialog }) => {
  const classes = useStyles();
  const [newProposal, setNewProposal] = useState<NewProposal>(emptyProposal);

  const onSubmit = () => {
    if (!newProposal.baseDiscount) return;
    addProposal({
      baseDiscount: newProposal.baseDiscount.div(100),
      otherTerms: newProposal.otherTerms,
      priceList: newProposal.priceListUuid ? { uuid: newProposal.priceListUuid } : null,
    });
  };

  useEffect(() => {
    setNewProposal(emptyProposal);
  }, [open]);

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="xs">
      <DialogTitle>Nowa propozycja</DialogTitle>
      <DialogContent>
        <div className={classes.newProposalContainer}>
          <NewRouterResponsiveSelect
            label="Cennik"
            getOptions={getPriceLists}
            value={newProposal.priceListUuid}
            getKey={(o) => o.uuid}
            getLabel={(o) => o.name}
            getValue={(o) => o.uuid}
            onChange={(uuid) => setNewProposal({ ...newProposal, priceListUuid: uuid })}
          />
          <DecimalInput
            fullWidth
            variant="outlined"
            label="Rabat podstawowy (%)"
            value={newProposal.baseDiscount}
            onChange={(value) => setNewProposal({ ...newProposal, baseDiscount: value })}
          />
          <TextInput
            multiline
            fullWidth
            value={newProposal.otherTerms}
            variant="outlined"
            label="Pozostałe warunki handlowe"
            onChange={(value) => setNewProposal({ ...newProposal, otherTerms: value })}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSubmit} className={classes.addProposalButton}>
          Dodaj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    addProposalButton: {
      display: "block",
      marginLeft: "auto",
    },

    newProposalContainer: {
      "& > .MuiFormControl-root": {
        margin: theme.spacing(1, 0),
      },
    },
  };
});
