import { stringify } from "csv/browser/esm/sync";

import { UserWorkTimeReport } from "@megarax/crm-contracts";

type CsvRow = {
  data: string;
  początek: string;
  koniec: string;
  "przerwy [min]": string;
  "czas pracy [min]": string;
  liczbaWizyt: number;
};

export const reportToCsv = (report: UserWorkTimeReport): string => {
  const dayRows = report.days.map<CsvRow>((d) => ({
    data: d.date,
    początek: d.startTime?.toLocaleTimeString() ?? "",
    koniec: d.endTime?.toLocaleTimeString() ?? "",
    "przerwy [min]": d.breakDurationMinutes.toString(),
    "czas pracy [min]": d.workTimeMinutes?.toString() ?? "",
    liczbaWizyt: d.visitCount,
  }));

  const summaryRow: CsvRow = {
    data: "razem",
    początek: "",
    koniec: "",
    "przerwy [min]": "",
    "czas pracy [min]": report.totalWorkTimeMinutes.toString(),
    liczbaWizyt: report.days.reduce((sum, d) => sum + d.visitCount, 0),
  };

  return stringify([summaryRow, ...dayRows], {
    header: true,
    columns: ["data", "", "początek", "koniec", "przerwy [min]", "czas pracy [min]", "", "", "", "liczbaWizyt"],
  });
};
