import { DateStringResourceParam, dateStringSerializer, UuidResourceParam, uuidSerializer } from "@megarax/common";
import { BaseResource, IdResourceParam, NestedResourceNode, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import {
  changeBreakErrors,
  changeEndErrors,
  changeEventErrors,
  changeStartErrors,
  refreshVisitErrors,
  removeBreakErrors,
} from "./errors";
import {
  changeBreakInputSerializer,
  changeEndInputSerializer,
  changeEventInputSerialzier,
  changeStartInputSerializer,
} from "./inputs";
import { milageReportSerializer } from "./milageReport";
import { workDayDtoSerializer } from "./MyWorkDayDto";
import { userWorkTimeReportSerializer } from "./workTimeReport";

export const myWorkDayResource = BaseResource(["crm", "myWorkDays"], {
  byDate: DateStringResourceParam("date", "date", {
    events: NestedResourceNode("events", {
      byUuid: UuidResourceParam("eventUuid", "uuid", {
        changeEvent: ResourceActionV2({
          name: "changeEvent",
          method: "put",
          path: "",
          requestBodySerializer: changeEventInputSerialzier,
          responseErrorSerializer: MSerializers.stringOneOf(...changeEventErrors),
        }),
        removeEvent: ResourceActionV2({
          name: "removeEvent",
          method: "delete",
          path: "",
        }),
      }),
    }),
    breaks: NestedResourceNode("breaks", {
      byUuid: UuidResourceParam("breakUuid", "uuid", {
        changeBreak: ResourceActionV2({
          name: "changeBreak",
          method: "put",
          path: "",
          requestBodySerializer: changeBreakInputSerializer,
          responseErrorSerializer: MSerializers.stringOneOf(...changeBreakErrors),
        }),
        removeBreak: ResourceActionV2({
          name: "removeBreak",
          method: "delete",
          path: "",
          responseErrorSerializer: MSerializers.stringOneOf(...removeBreakErrors),
        }),
      }),
    }),
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      path: "",
      responseValueSerializer: workDayDtoSerializer,
    }),
    changeStart: ResourceActionV2({
      name: "changeStart",
      method: "post",
      requestBodySerializer: changeStartInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf(...changeStartErrors),
    }),
    changeEnd: ResourceActionV2({
      name: "changeEnd",
      method: "post",
      requestBodySerializer: changeEndInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf(...changeEndErrors),
    }),
  }),
});

const milageReportAction = ResourceActionV2({
  name: "milageReport",
  method: "get",
  path: "milageReport",
  querySerializer: MSerializers.object({
    since: dateStringSerializer,
    until: dateStringSerializer,
  }),
  responseValueSerializer: milageReportSerializer,
});

export const workDayResource = BaseResource(["crm", "workDays"], {
  milageReport: milageReportAction,
  refreshVisit: ResourceActionV2({
    name: "refreshVisit",
    method: "post",
    requestBodySerializer: MSerializers.object({
      uuid: uuidSerializer,
    }),
    responseErrorSerializer: MSerializers.stringOneOf(...refreshVisitErrors),
  }),
  byUserId: IdResourceParam("userId", {
    workTimeReport: ResourceActionV2({
      name: "workTimeReport",
      method: "get",
      path: "workTimeReport",
      querySerializer: MSerializers.object({
        since: dateStringSerializer,
        until: dateStringSerializer,
      }),
      responseValueSerializer: userWorkTimeReportSerializer,
    }),

    byDate: DateStringResourceParam("date", "date", {
      retrieve: ResourceActionV2({
        name: "retrieve",
        method: "get",
        path: "",
        responseValueSerializer: workDayDtoSerializer,
      }),
    }),
  }),
});
