import { Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";
import Decimal from "decimal.js";

export type OrderMargin = {
  orderUuid: Uuid;

  margin: Decimal;
  lastChangeTime: Date;
  orderDate: Date;
};

export const orderMarginSerializer = MSerializers.object<OrderMargin>({
  lastChangeTime: MSerializers.datetime,
  margin: MSerializers.decimal,
  orderDate: MSerializers.dateOnly,
  orderUuid: uuidSerializer,
});
