import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export const float: Serializer<number> & SerializerExtensions<number> = {
  serialize: (num: number) => {
    if (typeof num !== "number") throw new Error("NotANumber");
    return num;
  },
  deserialize: (raw: any) => {
    if (typeof raw !== "number") return Failure("NotANumber");
    return Ok(raw);
  },
  ...serializerExtensions(),
};
