import { Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export interface SaveTemplateInput {
  name: string;
  tags: string[];
  tasks: { label: string; uuid: Uuid; priority: number }[];
}

export const saveTemplateInputSerializer = MSerializers.object<SaveTemplateInput>({
  name: MSerializers.string,
  tags: MSerializers.array(MSerializers.string),
  tasks: MSerializers.array(
    MSerializers.object<{ label: string; uuid: Uuid; priority: number }>({
      label: MSerializers.string,
      uuid: uuidSerializer,
      priority: MSerializers.integer,
    }),
  ),
});
