import { Box, Container, List, ListItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { Uuid } from "@megarax/common";
import { MacroregionSimple } from "@megarax/crm-contracts";
import { NewItemFab, Pagination, SearchBar } from "@megarax/ui-components";

interface Props {
  macroregions: {
    count: number;
    items: MacroregionSimple[];
  };
  search: {
    setSearchText: (text: string) => void;
    initialValue: string;
  };
  pagination: {
    onChange: (e: React.ChangeEvent<unknown>, page: number) => void;
    count: number;
    page: number;
  };
  navigation: {
    navigateToDetails: (uuid: Uuid) => void;
    openAddCustomerDialog: () => void;
  };
}

export const MacroregionsHome: React.FC<Props> = ({ macroregions, navigation, search, pagination }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" disableGutters>
      <Box display="flex" p={2} sx={{ gap: "8px" }} justifyContent="space-between" alignItems="center">
        <SearchBar onSearchChange={search.setSearchText} initialValue={search.initialValue} />
        <NewItemFab onClick={navigation.openAddCustomerDialog} text="Nowy" />
      </Box>
      <List dense disablePadding>
        {macroregions.items.map((macroregion) => {
          return (
            <ListItem
              className={classes.itemRow}
              key={macroregion.uuid}
              button
              onClick={() => navigation.navigateToDetails(macroregion.uuid as Uuid)}
            >
              <ListItemText>{macroregion.name}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Pagination {...pagination} allCount={macroregions.count} />
    </Container>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    itemRow: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  };
});
