import React, { useCallback, useState } from "react";

import { AsyncResult, Result } from "@megaron/result";

export const useInlineFieldManager = <TInput extends FieldManagerInput>(input: TInput) => {
  const [fields, setFields] = useState<FieldManagerOutput<TInput>>(mapInputToOutput(input));

  const enableEdit = (key: keyof FieldManagerOutput<TInput>) => () =>
    setFields({
      ...fields,
      [key]: {
        ...fields[key],
        isBeingEdited: true,
      },
    });

  const disableEdit = (key: keyof FieldManagerOutput<TInput>) => () =>
    setFields({
      ...fields,
      [key]: {
        ...fields[key],
        newValue: fields[key].currentValue,
        isBeingEdited: false,
      },
    });

  const onEditValueChange =
    (key: keyof FieldManagerOutput<TInput>) => (value: FieldManagerOutput<TInput>[typeof key]["newValue"]) => {
      setFields({
        ...fields,
        [key]: {
          ...fields[key],
          newValue: value,
        },
      });
    };

  const onSave = (key: keyof FieldManagerOutput<TInput>) => async () => {
    const fieldToSave = fields[key];
    setFields({
      ...fields,
      [key]: {
        ...fieldToSave,
        editQueryLoading: true,
      },
    });
    const result = await fieldToSave.editQuery(fieldToSave.newValue);
    if (result.isFailure)
      return setFields({
        ...fields,
        [key]: {
          ...fieldToSave,
          newValue: fieldToSave.currentValue,
          editQueryLoading: false,
        },
      });
    setFields({
      ...fields,
      [key]: {
        ...fieldToSave,
        currentValue: fieldToSave.newValue,
        editQueryLoading: false,
        isBeingEdited: false,
      },
    });
  };

  return attachUtilityFunctions(fields, {
    onSave,
    onEditValueChange,
    enableEdit,
    disableEdit,
  });
};

interface FieldManagerInput {
  [key: string]: FieldManagerInputEntry<any>;
}

interface FieldManagerInputEntry<TValue> {
  currentValue: TValue;
  editQuery: (input: TValue) => Promise<Result<void, void>>;
}

type FieldManagerOutput<TInput extends FieldManagerInput> = {
  [key in keyof TInput]: FieldManagerOutputEntry<TInput[key]["currentValue"]>;
};

interface FieldManagerOutputEntry<T> extends FieldManagerInputEntry<T> {
  isBeingEdited: boolean;
  newValue: T;
  editQueryLoading: boolean;
}

type FieldManagerWithFunctions<TInput extends FieldManagerInput> = {
  [key in keyof TInput]: FieldManagerEntryWithFunctions<TInput[key]["currentValue"]>;
};

interface FieldManagerEntryWithFunctions<TValue> extends FieldManagerOutputEntry<TValue> {
  onSave: () => void;
  disableEdit: () => void;
  enableEdit: () => void;
  onEditValueChange: (value: TValue | null) => void;
}

const mapInputToOutput = <TInput extends FieldManagerInput>(input: TInput): FieldManagerOutput<TInput> => {
  const output = {} as FieldManagerOutput<TInput>;
  Object.entries(input).forEach(([key, value]) => {
    output[key as keyof FieldManagerOutput<TInput>] = {
      ...value,
      isBeingEdited: false,
      newValue: value.currentValue,
      editQueryLoading: false,
    };
  });
  return output;
};

const attachUtilityFunctions = <TInput extends FieldManagerInput>(
  output: FieldManagerOutput<TInput>,
  functions: FunctionsToAttach<TInput>,
): FieldManagerWithFunctions<TInput> => {
  const withFunctions = {} as FieldManagerWithFunctions<TInput>;
  Object.entries(output).forEach(([key, value]) => {
    withFunctions[key as keyof FieldManagerWithFunctions<TInput>] = {
      ...value,
      onSave: functions.onSave(key),
      onEditValueChange: functions.onEditValueChange(key),
      enableEdit: functions.enableEdit(key),
      disableEdit: functions.disableEdit(key),
    };
  });
  return withFunctions;
};

interface FunctionsToAttach<TInput> {
  onSave: (key: keyof TInput) => () => void;
  disableEdit: (key: keyof TInput) => () => void;
  enableEdit: (key: keyof TInput) => () => void;
  onEditValueChange: (key: keyof TInput) => (value: TInput[typeof key]) => void;
}
