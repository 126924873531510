import { css, useTheme } from "@emotion/react";

import { Skeleton } from "@megaron/dash-skeleton";

import { DefaultAvatar } from "./DefaultAvatar";

type Props = {
  src: string | null | undefined;
  className?: string;
  size?: string;
};

export const Avatar: React.FC<Props> = (props) => {
  const theme = useTheme();
  const avatarCss = css({ borderRadius: "50%", width: props.size ?? "30px", height: props.size ?? "30px" });

  if (props.src === null) {
    return <div className={props.className} css={css(avatarCss, { border: `1px ${theme.colors.border} dashed` })} />;
  }

  if (props.src === undefined) {
    return <Skeleton className={props.className} css={avatarCss} />;
  }

  if (props.src === "") {
    return <DefaultAvatar pxSize={parseInt(props.size ?? "30px", 10)} />;
  }

  return <img alt="avatar" className={props.className} src={props.src} css={avatarCss} />;
};
