import _ from "lodash";

import { AsyncResult, Failure, isResult, Ok } from "@megaron/result";

import { ResourceAction } from "../resource";
import { ResourceActionProviderV2, ResourceProviderV2 } from "./resourceProvider";

export type HttpClient<TError> = {
  get: (url: string, query?: any) => Promise<Ok<unknown> | Failure<TError>>;
  post: (url: string, query?: any, body?: any) => Promise<Ok<unknown> | Failure<TError>>;
  put: (url: string, query?: any, body?: any) => Promise<Ok<unknown> | Failure<TError>>;
  delete: (url: string, query?: any, body?: any) => Promise<Ok<unknown> | Failure<TError>>;
};

export const HttpActionProviderV2 =
  <TError>(client: HttpClient<TError>) =>
  <TResponse, TQuery, TBody>(
    action: ResourceAction<TResponse, TQuery, TBody>,
    basePath: string[],
  ): ResourceActionProviderV2<TResponse, TQuery, TBody, TError> => {
    const fullPath = [...basePath, action.path];
    const fullUrl = fullPath.join("/");

    return (query?, body?) => {
      const resultPromise = (async () => {
        const queryRaw = action.querySerializer && action.querySerializer.serialize(query!);
        const bodyRaw = action.requestBodySerializer && action.requestBodySerializer.serialize(body!);

        const result = await client[action.method](fullUrl, queryRaw, bodyRaw);
        if (result.isFailure) return result;

        const response = action.responseSerializer.forceDeserialize(result.value);

        return isResult(response) ? (response as Ok<TResponse>) : Ok(response);
      })();

      const asyncResult = AsyncResult(resultPromise);
      return asyncResult as any;
    };
  };

export const HttpResourceProviderV2 = <TError>(client: HttpClient<TError>) =>
  ResourceProviderV2(HttpActionProviderV2(client));
