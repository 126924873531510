import { Gtin, packSizeDefaultsResource, SetSizesInput, validateGtin } from "@megarax/crm-contracts";
import { useResourceProvider } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { DialogLoading, FeedbackSnackbarContext, InvalidGtinDialog } from "@megarax/ui-components";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { PackSizeDialog } from "./PackSizeDialog";

interface Props {
  gtin: Gtin;
}

export const PackSizeDialogContainer: React.FC<Props> = ({ gtin }) => {
  const navigate = useNavigate();

  const feedbackSnackbar = useContext(FeedbackSnackbarContext);

  const packSizeProvider = useResourceProvider(packSizeDefaultsResource);

  const closeDialog = () => navigate(-1);

  const { value: packSize, loading } = useAsyncLoad(() => packSizeProvider.byGtin(gtin).retrieve(), []);

  const isGtinValid = validateGtin(gtin);
  if (isGtinValid.isFailure) return <InvalidGtinDialog gtin={gtin} />;

  const setPackSizes = (gtin: Gtin) => (input: SetSizesInput) =>
    packSizeProvider
      .byGtin(gtin)
      .setSizes(undefined, input)
      .then(() =>
        feedbackSnackbar.pushMessage({
          content: "Pomyślnie zapisano wartości",
          severity: "success",
        }),
      );

  const ready = packSize !== undefined;

  return (
    <>
      {ready && <PackSizeDialog closeDialog={closeDialog} setPackSizes={setPackSizes(gtin)} packSize={packSize} />}
      <DialogLoading loading={loading} />
    </>
  );
};
