import { ErrorScreen } from "./ErrorScreen";
import { Skeleton } from "./Skeleton";

type Props<TVal, TErr> = {
  width?: string;
  height?: string;
  customSekelton?: React.ReactNode;

  query: {
    data: TVal | undefined;
    error: TErr | null;
  };

  onRetry?: () => void;
  children?: (value: TVal) => React.ReactNode;
  errorScreen?: (error: TErr) => React.ReactNode;
  skeleton?: () => React.ReactNode;
};

export const QuerySkeleton = <TVal, TErr = never>(props: Props<TVal, TErr>): JSX.Element => {
  if (props.query.data !== undefined) return <>{props.children?.(props.query.data)}</>;

  if (props.query.error)
    return (
      <ErrorScreen height={props.height} width={props.width} onRetry={props.onRetry}>
        {props.errorScreen?.(props.query.error) ??
          (typeof props.query.error === "string" ? props.query.error : JSON.stringify(props.query.error))}
      </ErrorScreen>
    );

  return <>{props.customSekelton ?? <Skeleton height={props.height} width={props.width} />}</>;
};
