import _ from "lodash";

import { Uuid } from "@megarax/common";
import { MacroregionListQueryFilters, macroregionResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Failure, Ok, Result } from "@megaron/result";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

type OnSuccessFunction = (() => void) | (() => Promise<void>);

const voidifyResult = <TValue, TError>(result: Result<TValue, TError>): Result<void, void> =>
  result.flatMapError(() => Failure()).flatMap(() => Ok());

const promisifyResult = <TValue, TError>(result: Result<TValue, TError>): Promise<TValue> =>
  new Promise((resolve, reject) => {
    if (result.isFailure) return reject(result.error);
    resolve(result.value);
  });

export const useMacroregionsResource = () => {
  const macroregionProvider = useResourceProviderV2(macroregionResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const nameMacroregion =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, name: string) =>
      macroregionProvider
        .byUuid(uuid)
        .nameMacroregion(undefined, { name })
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const getMacroregions = (filters: MacroregionListQueryFilters) =>
    macroregionProvider.list(filters).mapError(handleError);

  const getMacroregionsArray = (searchText: string) =>
    getMacroregions({ searchText })
      .map((result) => result.items)
      .then(promisifyResult);

  const getMacroregionsDictionary = () =>
    getMacroregions({ limit: 1000 })
      .map((result) => _.keyBy(result.items, "uuid"))
      .then(promisifyResult);

  return {
    nameMacroregion,
    getMacroregions,
    getMacroregionsArray,
    getMacroregionsDictionary,
  };
};
