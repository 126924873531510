import { DateString, dateStringSerializer } from "@megaron/date-string";
import { Serializers } from "@megaron/serializers";

export type UserActivityDayDto = {
  date: DateString;
  redeemingUsers: number;
};

export const userActivityDayDtoSerializer = Serializers.object<UserActivityDayDto>({
  date: dateStringSerializer,
  redeemingUsers: Serializers.integer,
});
