import { Autocomplete, FormControl, TextField, useMediaQuery, useTheme } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";

import { NewRouterMobileSelect } from "./NewRouterMobileSelect";

type OptionKey<T> = (option: T) => string | number;
type OptionLabel<T> = (option: T) => string;
type OptionValue<T, V> = (option: T) => V;

export interface ResponsiveSelectProps<T, V> {
  getOptions: (search: string) => Promise<T[]>;
  value: V | null;
  onChange: (newValue: V | null) => void;
  getKey: OptionKey<T>;
  getValue: OptionValue<T, V>;
  getLabel: OptionLabel<T>;
  label?: string;
  error?: string | boolean;
  helperText?: string;
  initialValue?: T;
  disableMobileDialog?: boolean;
  variant?: "outlined" | "standard" | "filled";

  setValueChangeRef?: (setValue: (newValue: T | null) => void) => void;
  renderOption?: {
    desktop: (option: T) => React.ReactNode;
    mobile: (option: T) => React.ReactNode;
  };
}
export const NewRouterResponsiveSelect = <T, V>(props: ResponsiveSelectProps<T, V>) => {
  const {
    getKey,
    getValue,
    getLabel,
    getOptions,
    onChange,
    label,
    error,
    helperText,
    initialValue,
    disableMobileDialog,
    setValueChangeRef,
    renderOption,
    variant,
  } = props;

  const initialText = initialValue ? getLabel(initialValue) : "";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [search, setSearch] = useState<string>(initialText);
  const [loading, setLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<T[]>([]);
  const [selectedValue, setSelectedValue] = useState<T | null>(initialValue ?? null);

  const onGetOptions = (text: string) =>
    getOptions(text).then((options) => {
      setOptions(options);
      setLoading(false);
    });

  const debouncedGetOptions = useRef(debounce(onGetOptions, 400));

  useEffect(() => {
    debouncedGetOptions.current = debounce(onGetOptions, 400);
  }, [getOptions]);

  const onTextChange = (text: string) => {
    setSearch(text);
    setOptions([]);
    setLoading(true);
    debouncedGetOptions.current(text);
  };

  const onValueChange = (newValue: T | null) => {
    if (!newValue) {
      onTextChange("");
      onChange(null);
      setSelectedValue(null);
      return;
    }
    onTextChange(getLabel(newValue));
    setSelectedValue(newValue);
    onChange(getValue(newValue));
  };

  const onBlur = () => {
    if (!selectedValue) return onTextChange("");
    if (search !== getLabel(selectedValue)) onTextChange(getLabel(selectedValue));
  };

  useEffect(() => {
    if (setValueChangeRef) setValueChangeRef(onValueChange);
    debouncedGetOptions.current(initialText);
    return () => debouncedGetOptions.current.cancel();
  }, []);

  if (!disableMobileDialog && isMobile)
    return (
      <FormControl fullWidth>
        <NewRouterMobileSelect
          {...props}
          variant={variant}
          loading={loading}
          search={{ value: search, onTextChange }}
          options={options}
          selectedValue={selectedValue}
          onValueChange={onValueChange}
          renderOption={renderOption}
        />
      </FormControl>
    );

  return (
    <FormControl fullWidth>
      <Autocomplete
        options={options}
        loading={loading}
        value={selectedValue}
        autoComplete={false}
        isOptionEqualToValue={(option, value) => {
          return getKey(option) === getKey(value);
        }}
        onChange={(e, newValue) => onValueChange(newValue)}
        filterOptions={(x) => x}
        onBlur={onBlur}
        getOptionLabel={(option) => getLabel(option)}
        renderOption={renderOption ? (props, option) => <li {...props}>{renderOption.desktop(option)}</li> : undefined}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={label}
            value={search}
            onChange={(e) => onTextChange(e.target.value)}
            error={Boolean(error)}
            variant={variant ?? "outlined"}
            helperText={helperText ?? error}
          />
        )}
      ></Autocomplete>
    </FormControl>
  );
};
