import { useTheme } from "@emotion/react";
import React from "react";
import { MdList } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { useQsFilters } from "@megaron/dash-filters";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SearchBox } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

import { CustomersFilters } from "./CustomersFilters";
import { filtersSerializer } from "./CustomersHome";
import { CustomersMap } from "./CustomersMap";

export const CustomerMapView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const { filters, setFilter } = useQsFilters(filtersSerializer, {
    page: 0,
    searchText: "",
    sort: undefined,
    isPro: false,
    isAffiliate: false,
    pos: undefined,
    tags: undefined,
    regions: undefined,
    isArchived: undefined,
    isUnassigned: undefined,
    isActive: undefined,
  });

  const searchQuery = useClientManager("docs").searchCustomers().useQuery({
    searchText: filters.searchText,
    sort: filters.sort,
    limit: 10000,
    isPro: filters.isPro,
    isAffiliate: filters.isAffiliate,
    tags: filters.tags,
    pos: filters.pos,
    regions: filters.regions,
    isArchived: filters.isArchived,
    isUnassigned: filters.isUnassigned,
    isActive: filters.isActive,
  });

  return (
    <div
      css={{
        position: "relative",
        height: isMobile ? "calc(100dvh - 26px)" : "100vh",
        maxHeight: "100dvh",
        overflow: "hidden",
        marginBottom: isMobile ? "-26px" : 0,
      }}
    >
      <div
        css={{
          position: "absolute",
          zIndex: 5,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0.75rem",
          flexWrap: "wrap",
          justifyContent: "right",
          top: 0,
        }}
      >
        <div
          css={{
            display: "flex",
            gap: "0.75rem",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "right",
          }}
        >
          <SearchBox onChange={setFilter("searchText")} value={filters.searchText} css={{ width: "100%", flex: "1" }} />
          <Button
            css={{ background: "white", color: theme.colors.primary, padding: "0.325rem 0.625rem" }}
            icon={<MdList />}
            onClick={() => navigate(location.pathname.replace("/map", "") + location.search)}
          />
        </div>
        <CustomersFilters
          filters={filters}
          handlers={{
            onSortChange: setFilter("sort"),
            onProChange: setFilter("isPro"),
            onAffiliateChange: setFilter("isAffiliate"),
            onArchivedChange: setFilter("isArchived"),
            onUnassignedChange: setFilter("isUnassigned"),
            onActiveChange: setFilter("isActive"),
            onPosChange: setFilter("pos"),
            onRegionsChange: setFilter("regions"),
            onTagsChange: setFilter("tags"),
          }}
          hideSortFilter
        />
      </div>

      <QuerySkeleton height={"95vh"} query={searchQuery}>
        {(result) => <CustomersMap customers={result.items} queryKey={searchQuery.key} />}
      </QuerySkeleton>
    </div>
  );
};
