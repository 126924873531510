import { useTheme } from "@emotion/react";
import { MdShoppingCart } from "react-icons/md";

import { Chip } from "@megaron/dash-form";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { CustomerDoc, CustomerSortField } from "@megaron/docs-contracts";

import { CustomerProductsRow, headerText, isInactive, LastActivityText, LastInteractionRow } from "./CustomerTile";
import { OwnerProfile } from "./OwnerProfile";

type Props = {
  customers: CustomerDoc[];
  isLoading: boolean;
  activeSortValue: { field: CustomerSortField; order?: "ASC" | "DESC" | undefined } | undefined;
  onSortChange: (value: { field: CustomerSortField; order?: "ASC" | "DESC" | undefined } | undefined) => void;
  visibleTableColumns: string[];
  queryKey: string | string[];
};

export const CustomerTable: React.FC<Props> = ({
  customers,
  isLoading,
  activeSortValue,
  onSortChange,
  visibleTableColumns,
  queryKey,
}) => {
  const theme = useTheme();

  const getActivityColor = (time: Date | null, isArchived: boolean) => {
    if (isArchived) return theme.colors.border;
    if (!time) return theme.colors.primary;
    return isInactive(time) ? theme.colors.danger : theme.colors.success;
  };

  const tableColumns: TableHeadColumn<CustomerSortField>[] = [
    {},
    ...tableHeadingsList.map(({ heading, sortField }) =>
      sortField
        ? ({
            isSortable: true,
            sortFieldName: sortField,
            label: heading,
            isHidden: !visibleTableColumns.includes(heading),
          } as const)
        : ({
            isSortable: false,
            label: heading,
            isHidden: !visibleTableColumns.includes(heading),
          } as const),
    ),
  ];

  const visibleTableColumnsIndexes = tableColumns
    .map((column, index) => (!column.label === undefined || !column.isHidden ? index : null))
    .filter((index) => index !== null) as number[];

  const getTableRowCellsContent = (customer: CustomerDoc): RowCellContent[] => {
    const color = getActivityColor(customer.lastPurchaseTime, customer.isArchived);

    return [
      {
        isLink: true,
        element: <div css={{ position: "absolute", inset: 0, background: color }}></div>,
      },
      {
        isLink: true,
        element: (
          <div css={{ display: "flex", alignItems: "center", gap: "0.375rem" }}>
            <span css={{ color, fontWeight: 700, textWrap: "nowrap", fontSize: "0.9375rem" }}>
              {headerText(customer)}
            </span>
            {customer.isPro ? (
              <Chip
                variant="outline"
                color={color}
                css={{ textTransform: "uppercase", padding: "0 8px", fontWeight: "600", fontSize: "10px" }}
              >
                Pro
              </Chip>
            ) : customer.isAffiliate ? (
              <Chip
                variant="outline"
                color={color}
                css={{ textTransform: "uppercase", padding: "0 8px", fontWeight: "600", fontSize: "10px" }}
              >
                Partner
              </Chip>
            ) : null}
          </div>
        ),
      },
      {
        isLink: true,
        cellCss: { minWidth: "14rem" },
        element: (
          <div
            css={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.4rem",
              alignItems: "flex-start",
              fontSize: "0.875rem",
              opacity: "0.6",
              lineHeight: "0.625rem",
            }}
          >
            {customer.tags.map((tag) => (
              <span key={tag}>#{tag}</span>
            ))}
          </div>
        ),
      },
      {
        isLink: true,
        element: (
          <div css={{ width: "20rem" }}>
            <LastInteractionRow customer={customer} />
          </div>
        ),
      },
      {
        isLink: true,
        element: <CustomerProductsRow customer={customer} color={color} hideLastActivityText />,
      },

      {
        isLink: true,
        element: customer.lastPurchaseTime ? (
          <LastActivityText icon={MdShoppingCart} time={customer.lastPurchaseTime} css={{ fontSize: "0.9375rem" }} />
        ) : null,
      },
      {
        element: <OwnerProfile customer={customer} queryKey={queryKey} />,
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
      <Table<CustomerSortField> columns={tableColumns} activeSortValue={activeSortValue} onSort={onSortChange}>
        <TableBody
          visibleColumnIndexes={visibleTableColumnsIndexes}
          isLoading={isLoading}
          rows={customers.map((customer) => ({
            uuid: customer.uuid,
            linkTo: `/crm/customers/id/${customer.uuid}`,
            cellsContent: getTableRowCellsContent(customer),
            css: {
              "&:hover": {
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

export const tableHeadingsList: { heading: string; sortField?: CustomerSortField }[] = [
  { heading: "Klient" },
  { heading: "Tagi" },
  { heading: "Ostatnia interakcja", sortField: "lastInteractionTime" },
  { heading: "Całkowite zakupy", sortField: "totalMassKg" },
  { heading: "Ostatni zakup", sortField: "lastPurchaseTime" },
  { heading: "Region" },
];
