import _ from "lodash";
import {
  NestedResourceNode,
  ResourceNodes,
  ResourceAction,
  ResourceParamNode,
  isNodeParam,
  BaseResource,
  isNodeAction,
  isNodeNestedResource,
} from "./resource";

type ResourceActionParams<TQuery, TBody> = TBody extends undefined
  ? TQuery extends undefined
    ? []
    : [query: TQuery]
  : [query: TQuery, body: TBody];

export type ResourceActionProvider<TResponse, TQuery, TReqBody> = (
  ...args: ResourceActionParams<TQuery, TReqBody>
) => Promise<TResponse>;

export type ResourceParamProvider<TParam, TNested extends ResourceNodes> = (
  param: TParam,
) => ResourceProvider<TNested>;

export type ResourceProvider<TResource extends ResourceNodes> = {
  [key in keyof TResource]: TResource[key] extends ResourceParamNode<
    infer TParam,
    infer TNested
  >
    ? ResourceParamProvider<TParam, TNested>
    : TResource[key] extends ResourceAction<
        infer TResponse,
        infer TQuery,
        infer TReqBody
      >
    ? ResourceActionProvider<TResponse, TQuery, TReqBody>
    : TResource[key] extends NestedResourceNode<infer TNested>
    ? ResourceProvider<TNested>
    : never;
};

export type ActionProviderSource = <TResponse, TQuery, TReqBody>(
  node: ResourceAction<TResponse, TQuery, TReqBody>,
  basePath: string[],
) => ResourceActionProvider<TResponse, TQuery, TReqBody>;

export const ResourceProvider =
  (createActionProvider: ActionProviderSource) =>
  <TNodes extends ResourceNodes>({
    nodes,
    basePath,
  }: BaseResource<TNodes>): ResourceProvider<TNodes> => {
    const provider: Partial<ResourceProvider<TNodes>> = {};

    for (const nodeName in nodes) {
      const node = nodes[nodeName];
      if (isNodeParam(node))
        provider[nodeName] = ((param: any) =>
          ResourceProvider(createActionProvider)({
            basePath: node.path
              ? [...basePath, node.path, node.paramSerializer.serialize(param)]
              : [...basePath, node.paramSerializer.serialize(param)],
            nodes: node.children,
          })) as any;
      else if (isNodeAction(node))
        provider[nodeName] = createActionProvider(node, basePath) as any;
      else if (isNodeNestedResource(node))
        provider[nodeName] = ResourceProvider(createActionProvider)({
          basePath: [...basePath, node.path],
          nodes: node.children,
        }) as any;
      else throw new Error("Unknown node type");
    }
    return provider as ResourceProvider<TNodes>;
  };
