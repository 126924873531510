import Decimal from "decimal.js";
import { Validator } from "../validator";

interface DecimalValidatorOptions {
  greaterThan?: number;
  lessThan?: number;
}

export const decimalValidator =
  ({
    greaterThan,
    lessThan,
  }: DecimalValidatorOptions): Validator<
    Decimal | undefined,
    Decimal,
    "gtError" | "ltError" | "required"
  > =>
  (input) => {
    if (!input) return { failure: "required", successful: false };
    if (greaterThan !== undefined && !input.greaterThan(greaterThan))
      return { failure: "gtError", successful: false };
    if (lessThan !== undefined && !input.lessThan(lessThan))
      return { failure: "ltError", successful: false };
    return { value: input, successful: true };
  };
