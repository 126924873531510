import { Box, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  alerts: React.ReactNode | undefined;
  titleSection: React.ReactNode | undefined;
  tagSection: React.ReactNode | undefined;
  regionAndChainSection: React.ReactNode | undefined;
  pricingSection: React.ReactNode;
  addressSection: React.ReactNode | undefined;

  chat: React.ReactElement;
  contacts: React.ReactElement;
  recentOrders: React.ReactElement;
}

export const MobileViewLayout: React.FunctionComponent<Props> = ({
  alerts,
  titleSection,
  tagSection,
  regionAndChainSection,
  pricingSection,
  addressSection,
  chat,
  contacts,
  recentOrders,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box mt={2}>
        <Box mx={1.5}>
          {alerts}
          <Box mb={2}>{titleSection ?? <Skeleton variant="rectangular" height={80} />}</Box>
          <Box mb={3}>{tagSection ?? <Skeleton variant="rectangular" height={28} />}</Box>
          <Box mb={4}>{regionAndChainSection ?? <Skeleton variant="rectangular" height={24} />}</Box>
          <Box mb={4}>{pricingSection}</Box>
        </Box>
        <Box className={classes.addressBox}>{addressSection ?? <Skeleton variant="rectangular" height={360} />}</Box>
      </Box>

      <Box>
        <Box className={classes.chatBox}>{chat}</Box>
        <Box className={classes.contactsBox}>{contacts}</Box>
        <Box className={classes.recentOrdersBox}>{recentOrders}</Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  addressSection: {
    overflow: "hidden",
    borderColor: theme.palette.divider,
    borderWidth: "1px",
    borderStyle: "solid",
  },

  addressBox: {
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  chatBox: {
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  contactsBox: {
    padding: theme.spacing(1, 0),
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  recentOrdersBox: {
    padding: theme.spacing(1, 0),
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
}));
