import { ExtractValidatorError, ValidationResult } from "@megarax/validators";
import { useState } from "react";

export const useValidatorForm = <T, R>(
  initialValue: T,
  formValidator: (input: T) => ValidationResult<R, any>,
  submit: (formValue: R) => Promise<void>,
) => {
  const [formValue, setFormValue] = useState<T>(initialValue);
  const [formErrors, setFormErrors] = useState<
    ExtractValidatorError<typeof formValidator>
  >({});
  const onFormInput = (field: keyof T) => (value: T[typeof field]) =>
    setFormValue({ ...formValue, [field]: value });

  const onSubmit = () => {
    const result = formValidator(formValue);
    if (result.successful) return submit(result.value);
    return setFormErrors(result.failure);
  };

  return { formValue, onFormInput, onSubmit, formErrors };
};
