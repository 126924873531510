import { Box, Divider, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { Uuid } from "@megarax/common";
import { CustomerStatus, RegionSimple } from "@megarax/crm-contracts";
import {
  FilterBar,
  FilterBarConfig,
  MultiSelectFilterConfig,
  NewItemFab,
  SearchBar,
  SearchMultiSelectFilterConfig,
  SelectFilterConfig,
} from "@megarax/ui-components";

import { QueryFilters } from "./customerHomeFilters";

interface Props {
  queryFilters: QueryFilters;
  getRegions: (search: string) => Promise<RegionSimple[]>;
  getTags: (search: string) => Promise<string[]>;
  setQueryFilters: (qf: QueryFilters) => void;
  openAddCustomerDialog: () => void;
}

export type FilterSet = {
  status: CustomerStatus[];
  regionUuid: Uuid[];
  hasPricingRules: "true" | "false" | null;
  tags: string[];
};

export const TopSection: React.FC<Props> = ({
  queryFilters,
  setQueryFilters,
  openAddCustomerDialog,
  getRegions,
  getTags,
}) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1070px)");

  const setSearchText = (searchText: string) => setQueryFilters({ ...queryFilters, searchText });

  const getRegionOptions = (search: string) =>
    getRegions(search).then((result) => result.map((item) => ({ value: item.uuid, label: item.name })));

  const getTagOptions = (search: string) =>
    getTags(search).then((result) =>
      result.map((tag) => ({
        value: tag,
        label: tag,
      })),
    );

  const config: FilterBarConfig<FilterSet> = {
    status: MultiSelectFilterConfig<CustomerStatus>({
      label: "Status",
      options: [
        { label: "VIP", value: "vip" },
        { label: "Zweryfikowany", value: "verified" },
        { label: "Do weryfikacji", value: "unverified" },
        { label: "Nieaktywny", value: "inactive" },
      ],
    }),
    regionUuid: SearchMultiSelectFilterConfig({
      label: "Region",
      getOptions: getRegionOptions,
    }),
    hasPricingRules: SelectFilterConfig({
      label: "Jest płatnikiem?",
      options: [
        { label: "Jest płatnikiem", value: "true" },
        {
          label: "Nie jest płatnikiem",
          value: "false",
        },
      ],
    }),
    tags: SearchMultiSelectFilterConfig({
      label: "Tagi",
      getOptions: getTagOptions,
    }),
  };

  return (
    <>
      {matches ? (
        <Box display="flex" p={2} sx={{ gap: "8px" }} justifyContent="space-between" alignItems="center">
          <Box display="flex" width="100%" alignItems="center">
            <SearchBar onSearchChange={setSearchText} initialValue={queryFilters.searchText} />
            <Divider variant="middle" orientation="vertical" flexItem className={classes.divider} light />
            <FilterBar<FilterSet>
              filters={toFilterSet(queryFilters)}
              config={config}
              onChange={(filterSet) =>
                setQueryFilters({
                  ...queryFilters,
                  ...fromFilterSet(filterSet, queryFilters.searchText),
                })
              }
            />
          </Box>
          <NewItemFab onClick={openAddCustomerDialog} text="Nowy" />{" "}
        </Box>
      ) : (
        <Box py={2}>
          <Box display="flex" px={2} sx={{ gap: "8px" }} justifyContent="space-between" alignItems="center">
            <SearchBar onSearchChange={setSearchText} initialValue={queryFilters.searchText} />
            <NewItemFab onClick={openAddCustomerDialog} text="Nowy" />
          </Box>
          <Box px={2}>
            <FilterBar<FilterSet>
              filters={toFilterSet(queryFilters)}
              config={config}
              onChange={(filterSet) =>
                setQueryFilters({
                  ...queryFilters,
                  ...fromFilterSet(filterSet, queryFilters.searchText),
                })
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
};

const toFilterSet = (queryFilters: QueryFilters): FilterSet => ({
  regionUuid: queryFilters.regionUuid,
  hasPricingRules: queryFilters.hasPricingRules === "both" ? null : queryFilters.hasPricingRules,
  tags: queryFilters.tags,
  status: queryFilters.status,
});

const fromFilterSet = (filterSet: FilterSet, searchText: string): QueryFilters => ({
  ...filterSet,
  searchText,
  hasPricingRules: filterSet.hasPricingRules ?? "both",
  tags: filterSet.tags,
});

const useStyles = makeStyles((theme) => {
  return {
    divider: {
      margin: "0.5rem 1.5rem",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    container: {
      marginRight: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  };
});
