import Decimal from "decimal.js";

import { Serializers } from "@megaron/serializers";

export const currencies = ["PLN", "EUR", "USD", "GBP"] as const;
export type Currency = (typeof currencies)[number];
export const isCurrency = (x: unknown): x is Currency => currencies.includes(x as Currency);

export interface Invoice {
  invoiceNumber: string;
  total: Decimal;
  currency: Currency;
  issuer: string;
  issuerTaxId: string;
  issueDate: Date;
  dueDate: Date | null;
  receivedDate: Date;
  isDigital: boolean;
}

export const invoiceSerializer = Serializers.object<Invoice>({
  invoiceNumber: Serializers.string,
  total: Serializers.decimal,
  currency: Serializers.stringOneOf(...currencies),
  issuer: Serializers.string,
  issueDate: Serializers.dateOnly,
  dueDate: Serializers.dateOnly.nullable(),
  receivedDate: Serializers.dateOnly,
  isDigital: Serializers.boolean,
  issuerTaxId: Serializers.string,
});
