import { useTheme } from "@emotion/react";
import { endOfDay, formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import { MdOutlineCalendarToday } from "react-icons/md";

import { warningColor } from "./AllThreadsTableBody";

type Props = {
  remainingDays: number;
  deadline: Date;
};

export const RemainingDaysTile: React.FC<Props> = ({ remainingDays, deadline }) => {
  const theme = useTheme();

  const isInPast = remainingDays < 0;

  const remainingDaysString = formatDistanceToNowStrict(endOfDay(deadline), {
    locale: pl,
    unit: "day",
  });

  const getFullDaysString = () => {
    if (remainingDaysString === "dzień") {
      return isInPast ? "wczoraj" : "jutro";
    }

    if (remainingDaysString === "0 dni") {
      return "dzisiaj";
    }

    return isInPast ? `-${remainingDaysString}` : remainingDaysString;
  };

  const getTileStyles = () => {
    if (remainingDays <= 0) {
      return {
        background: theme.colors.danger,
        color: "white",
        fontWeight: 700,
      };
    }

    if (remainingDays <= 3) {
      return {
        background: warningColor,
        color: "white",
        fontWeight: 700,
      };
    }

    return {
      background: "none",
      color: theme.colors.secondaryText,
    };
  };

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.25rem",
        marginLeft: "auto",
        padding: "0.125rem 0.375rem",
        borderRadius: theme.extraSmallBorderRadius,
        ...getTileStyles(),
      }}
    >
      <MdOutlineCalendarToday size={12} />
      <span css={{ fontSize: "0.75rem", whiteSpace: "nowrap" }}>{getFullDaysString()}</span>
    </div>
  );
};
