import React from "react";
import { MdConveyorBelt, MdNewLabel } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { EntityList } from "@megaron/dash-entity-list";
import { Button } from "@megaron/dash-form";
import { Page, PageHeader, SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

type Props = {};

export const QrSyncHome: React.FunctionComponent<Props> = (props) => {
  const query = useClientManager("qrSync").linesQuery().useQuery({});

  return (
    <Page>
      <PageHeader>Linie produkcyjne</PageHeader>
      <QuerySkeleton query={query}>
        {(lines) => (
          <EntityList
            items={lines}
            getKey={(line) => line.id}
            columns={(line) => [{ header: "Nazwa", content: <Link to={`line/${line.id}`}>{line.name}</Link> }]}
          />
        )}
      </QuerySkeleton>
    </Page>
  );
};
