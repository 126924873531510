import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { differenceInMinutes, formatRelative } from "date-fns";
import { pl } from "date-fns/locale";
import _ from "lodash";
import React from "react";

import { Uuid } from "@megarax/common";
import { MentionDto } from "@megarax/crm-contracts";

import { getMessageParts, mentionTagRegex, nonGlobalMentionTagRegex } from "./mentions";

export type Message = {
  uuid: Uuid;
  userId: number;
  postedAt: Date;
  content: string;
  mentions: MentionDto[];
};

export type ProfileMap = {
  [userId: number]:
    | {
        userId: number;
        name: string;
        pictureUrl: string;
      }
    | undefined;
};

interface Props {
  messages: Message[];
  profiles: ProfileMap;
  myId: number | undefined;
}

type MessageGroup = {
  userId: number;
  userName: string | undefined;
  userPictureUrl: string | undefined;
  firstMessagePostedAt: Date;
  messages: Message[];
};

export const MessageLog: React.FunctionComponent<Props> = ({ messages, profiles, myId }) => {
  const classes = useStyles();
  const theme = useTheme();

  const messageGroups = groupMessages(messages, profiles);

  return (
    <List>
      {messageGroups.map((group) => (
        <ListItem key={group.messages[0].uuid} alignItems="flex-start" className={classes.messageGroup}>
          <ListItemAvatar>
            {group.userPictureUrl ? (
              <Avatar src={group.userPictureUrl} />
            ) : (
              <Skeleton variant="circular" width={40} height={40} />
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              group.userName ? (
                <>
                  <Typography component="span" fontWeight="500" sx={{ mr: 1 }}>
                    {group.userName}
                  </Typography>
                  <Typography component="span" variant="body2">
                    {formatRelative(group.firstMessagePostedAt, new Date(), {
                      locale: pl,
                    })}
                  </Typography>
                </>
              ) : (
                <Skeleton variant="text" width={100} />
              )
            }
            secondaryTypographyProps={{ component: "div" }}
            secondary={
              <>
                {group.messages.map((message) => (
                  <Typography key={message.uuid} variant="body1" className={classes.messageContent}>
                    {getMessageParts(message.content, message.mentions, myId)}
                  </Typography>
                ))}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  messageContent: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    whiteSpace: "pre-line",
  },
  messageGroup: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const canAppendToGroup = (group: MessageGroup, message: Message) => {
  if (group.userId !== message.userId) return false;
  if (differenceInMinutes(message.postedAt, group.firstMessagePostedAt) > 20) return false;
  return true;
};

const groupMessages = (messages: Message[], profiles: ProfileMap): MessageGroup[] => {
  const groups: MessageGroup[] = [];

  for (const message of messages) {
    const currentGroup = _.last(groups);

    if (currentGroup !== undefined && canAppendToGroup(currentGroup, message)) {
      currentGroup.messages = [...currentGroup.messages, message];
    } else {
      const profile = profiles[message.userId];
      groups.push({
        userId: message.userId,
        firstMessagePostedAt: message.postedAt,
        messages: [message],
        userName: profile?.name,
        userPictureUrl: profile?.pictureUrl,
      });
    }
  }
  return groups;
};
