import { Uuid, uuidSerializer } from "@megarax/common";
import { ResourceNodes, ResourceParamNode } from "@megarax/rest-resource";

export type OrderThreadIdentifier = {
  type: "order";
  orderUuid: Uuid;
};

export type PriceListProposalThreadIdentifier = {
  type: "priceListProposal";
  priceListUuid: Uuid;
  proposalUuid: Uuid;
};

export type CustomerThreadIdentifier = {
  type: "customer";
  customerUuid: Uuid;
};

export type ThreadIdentifier = OrderThreadIdentifier | PriceListProposalThreadIdentifier | CustomerThreadIdentifier;

export const threadIdentifierToString = (identifier: ThreadIdentifier): string => {
  if (identifier.type === "customer") return ["customer", identifier.customerUuid].join(":");
  if (identifier.type === "order") return ["order", identifier.orderUuid].join(":");

  return ["priceList", identifier.priceListUuid, "proposal", identifier.proposalUuid].join(":");
};

export const threadIdentifierFromString = (str: string): ThreadIdentifier => {
  const elements = str.split(":");

  if (elements[0] === "customer")
    return {
      type: "customer",
      customerUuid: uuidSerializer.forceDeserialize(elements[1]),
    };

  if (elements[0] === "order")
    return {
      type: "order",
      orderUuid: uuidSerializer.forceDeserialize(elements[1]),
    };

  if (elements[0] === "priceList")
    return {
      type: "priceListProposal",
      priceListUuid: uuidSerializer.forceDeserialize(elements[1]),
      proposalUuid: uuidSerializer.forceDeserialize(elements[3]),
    };

  throw new Error("Failed to decode identifier");
};

export const threadIdentifierStringSerializer = {
  serialize: threadIdentifierToString,
  forceDeserialize: threadIdentifierFromString,
};

export const ThreadIdentifierResourceParam = <TNestedActions extends ResourceNodes>(
  paramName: string,
  path: string,
  nested: TNestedActions,
): ResourceParamNode<ThreadIdentifier, TNestedActions> => ({
  paramName,
  children: nested,
  path,
  paramPattern: "\\b[a-zA-Z0-9-:]+\\b",
  paramSerializer: threadIdentifierStringSerializer,
});
