import { MSerializers } from "@megarax/serializers";
import Decimal from "decimal.js";

import { Month } from "./month";

export type RegionMarginFilters = {
  months: Month[];
};

export type RegionMargin = {
  regionUuid: string;
  margin: Decimal;
  target: Decimal | null;
  progress: Decimal | null;
};

export const regionMarginSerializer = MSerializers.object<RegionMargin>({
  margin: MSerializers.decimal,
  progress: MSerializers.decimal.nullable(),
  regionUuid: MSerializers.uuid(),
  target: MSerializers.decimal.nullable(),
});
