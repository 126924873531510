import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const listingSerializer = Serializers.object({
  listingUuid: uuidSerializer,
  rewardIds: Serializers.string.array(),
  requiresProStatus: Serializers.boolean,
  price: Serializers.decimal,
  limit: Serializers.integer.nullable(),
  limitPerUser: Serializers.integer.nullable(),
});

export type Listing = SerializerValue<typeof listingSerializer>;

export const listingActions = {
  listingsQuery: HttpAction({
    path: "/listings",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: Serializers.array(listingSerializer),
  }),
};
