import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, Divider, Paper, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Dictionary } from "lodash";
import React from "react";

import { Uuid } from "@megarax/common";
import { DesignateHomeInput, RegionSimple, SalespersonDto, SetManagerInput } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { UserSimple } from "@megarax/iam-contracts";
import { UserProfile } from "@megarax/iam-webapp";
import { Result } from "@megaron/result";
import { LoadingBar } from "@megarax/ui-components";

import { EditableProps } from "./EditableProps";

interface Props {
  regionsDictionary: Dictionary<RegionSimple> | undefined;
  profile: UserProfile | undefined;
  managerProfile: UserProfile | null | undefined;

  salesperson: Result<SalespersonDto, "SalespersonNotFound" | HttpRequestError> | undefined;
  dataLoading: boolean;
  navigateToVisitSchedule: () => void;
  reassignRegion: (regionUuid: Uuid) => Promise<Result<void, void>>;
  setWorkStartTime: (workStartTime: number) => Promise<Result<void, void>>;
  designateHome: (input: DesignateHomeInput) => Promise<Result<void, void>>;
  getRegions: (searchText: string) => Promise<RegionSimple[]>;
  getUsers: (searchText: string) => Promise<UserSimple[]>;
  setManager: (input: SetManagerInput) => Promise<Result<void, void>>;
}

export const SalespersonDetails: React.FC<Props> = ({
  regionsDictionary,
  profile,
  managerProfile,
  salesperson,
  dataLoading,
  navigateToVisitSchedule: navigateToPlan,
  reassignRegion,
  setWorkStartTime,
  designateHome,
  getUsers,
  getRegions,
  setManager,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Box display="flex" flexDirection="column" height="100%">
        <Container maxWidth="md" disableGutters>
          <Paper className={classes.topPaper}>
            <LoadingBar loading={dataLoading} />
            {profile ? (
              <Box px={3} py={1} position="relative" display="flex" justifyContent="space-between" alignItems="center">
                <div>
                  <Typography variant="caption" color="textSecondary">
                    Handlowiec
                  </Typography>
                  <Typography variant="subtitle2" className={classes.value}>
                    {profile?.displayedName ?? ""}
                  </Typography>
                </div>
                <Button onClick={navigateToPlan} variant="outlined" color="primary">
                  <FontAwesomeIcon icon={faCalendar} style={{ marginRight: "0.5rem" }} />
                  Wizyty
                </Button>
              </Box>
            ) : (
              <Skeleton variant="rectangular" height="64px" />
            )}
          </Paper>

          <Paper>
            {regionsDictionary && managerProfile !== undefined && salesperson?.value ? (
              <EditableProps
                regionsDictionary={regionsDictionary}
                managerProfile={managerProfile}
                salesperson={salesperson.value}
                reassignRegion={reassignRegion}
                setWorkStartTime={setWorkStartTime}
                getRegions={getRegions}
                designateHome={designateHome}
                setManager={setManager}
                getUsers={getUsers}
              />
            ) : (
              <Skeleton variant="rectangular" height="572px" />
            )}
            <Divider />
          </Paper>
        </Container>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => {
  return {
    topPaper: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: "none",
      marginBottom: theme.spacing(4),
    },
    value: {
      fontSize: "1rem",
    },
    container: {},
    background: {
      backgroundColor: theme.palette.grey[50],
      flexGrow: 1,
      width: "100%",
    },
  };
});
