import React, { useContext } from "react";

import { Uuid } from "@megarax/common";
import {
  customerListResource,
  PriceReportInput,
  priceReportResource,
  priceReportSubjectResource,
} from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { commonErrorsMap, FeedbackSnackbarContext, useSnackbarErrorHandler } from "@megarax/ui-components";

import { useCrmGroupsResource } from "../..";
import {
  GetCustomers,
  GetPreviousReports,
  GetPriceReportSubjects,
  PriceReportForm,
  SubmitPriceReport,
} from "./PriceReportForm";

interface Props {}

export const PriceReportFormContainer: React.FC<Props> = (props) => {
  const feedbackSnackbar = useContext(FeedbackSnackbarContext);
  const customerProvider = useResourceProviderV2(customerListResource);
  const priceReportProvider = useResourceProviderV2(priceReportResource);
  const priceReportSubjectProvider = useResourceProviderV2(priceReportSubjectResource);
  const { getMyGroups } = useCrmGroupsResource();

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const { value: myGroups } = useAsyncLoad(getMyGroups, []);

  const getCustomers: GetCustomers = (search: string) =>
    customerProvider.list({ searchText: search }).then((result) => result.assertOk().value.items);

  const getPriceReportSubjects: GetPriceReportSubjects = (search: string) =>
    priceReportSubjectProvider
      .search({ searchText: search, limit: 20, offset: 0 })
      .mapError((error) => {
        handleError(error);
        return Promise.reject(error);
      })
      .map((result) => result.items)
      .then((items) => items.value);

  const submitPriceReport: SubmitPriceReport = (uuid: Uuid, input: PriceReportInput) =>
    priceReportProvider
      .byUuid(uuid)
      .add(undefined, input)
      .mapError(handleError)
      .map((result) => {
        feedbackSnackbar.pushMessage({
          severity: "success",
          content: "Pomyślnie wysłano ankietę cenową.",
        });
        return result;
      })
      .then();

  const getPreviousReports: GetPreviousReports = (customerUuid) =>
    priceReportProvider
      .myPastReports({ customerUuid })
      .mapError((error) => {
        handleError(error);
        return Promise.reject(error);
      })
      .then((items) => items.value);

  return (
    <PriceReportForm
      myRegions={myGroups?.isOk ? myGroups.value.map((item) => item.regionUuid) : []}
      getCustomers={getCustomers}
      getPriceReportSubjects={getPriceReportSubjects}
      submitPriceReport={submitPriceReport}
      getPreviousReports={getPreviousReports}
    />
  );
};
