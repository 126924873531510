const generalErrorsMap = {
  InvalidOperationError: "Nieprawidłowa operacja.",
  EntityAlreadyExistsError: "Ten rekord już istnieje.",
  PermissionDeniedError:
    "Nie masz wystarczających uprawnień do wykonania tej operacji.",
  EntityNotFoundError: "Nie znaleziono tego rekordu.",
};

const invoiceErrorsMap = {
  ManagerDoesNotOwnBudgetError: "Nie masz uprawnień do tego budżetu.",
  NonPositiveSignatureAmountError: "Kwota musi być dodatnia.",
  UserNotAParticipantError: "Nie jesteś uczestnikiem tego wątku.",
  InvoiceParticipantAlreadyInvitedError: "Ten użytkownik jest już zaproszony.",
  InvoiceNotReceivedError: "Faktura nie dotarła jeszcze do firmy.",
  InvoiceContainsSignaturesError: "Faktura posiada podpisy.",
  UserDoesNotOwnTheAttachementError: "Nie jesteś właścicielem tego załącznika.",
  AttachmentDoesNotExistError: "Załącznik nie istnieje.",
};

export const errorsToMessagesMap = { ...generalErrorsMap, ...invoiceErrorsMap };

export const getMessageForCode = (code: string) => {
  const isKey = Object.keys(errorsToMessagesMap).includes(code);
  return isKey
    ? errorsToMessagesMap[code as keyof typeof errorsToMessagesMap]
    : "Wystąpił nieprzewidziany błąd";
};
