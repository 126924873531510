import { HttpClient } from "@megarax/rest-resource";
import axios, { AxiosError, AxiosResponse } from "axios";

import { Failure, Ok } from "@megaron/result";

export type HttpRequestError = "PermissionDenied" | "AuthenticationFailed" | "InternalServerError" | "ConnectionFailed";

export type HttpRequestFailure = Failure<HttpRequestError>;

type HttpRequestResult = Ok<unknown> | HttpRequestFailure;

export type MegaraxHttpClient = HttpClient<HttpRequestError>;

export const MegaraxHttpClient = (
  baseUrl: string,
  authToken: string | (() => Promise<string | undefined> | string),
): MegaraxHttpClient => {
  const axiosClient = axios.create({
    baseURL: baseUrl,
  });
  const handleResponse = (response: AxiosResponse<any>): HttpRequestResult => {
    return Ok(response.data);
  };

  const handleError = (error: AxiosError): HttpRequestResult => {
    if (error.response) {
      const status = error.response.status;

      const errorName = (error.response.data as any).errors && (error.response.data as any).errors[0].code;

      if (status === 403) return Failure("PermissionDenied");
      if (status === 401) return Failure("AuthenticationFailed");
      console.log(JSON.stringify(error));
      if (errorName) return Failure(errorName);
      if (status === 500) return Failure("InternalServerError");
      return Failure("InternalServerError");
    } else return Failure("ConnectionFailed");
  };

  const getHeaders = async () => ({
    Authorization: typeof authToken === "function" ? await authToken() : authToken,
  });

  return {
    get: async (url, params) =>
      axiosClient
        .get(url, {
          params,
          headers: await getHeaders(),
        })
        .then(handleResponse)
        .catch(handleError),
    post: async (url, params, data) =>
      axiosClient
        .post(url, data, {
          params,
          headers: await getHeaders(),
        })
        .then(handleResponse)
        .catch(handleError),
    put: async (url, params, data) =>
      axiosClient
        .put(url, data, {
          params,
          headers: await getHeaders(),
        })
        .then(handleResponse)
        .catch(handleError),
    delete: async (url, params, data) =>
      axiosClient
        .delete(url, {
          params,
          data,
          headers: await getHeaders(),
        })
        .then(handleResponse)
        .catch(handleError),
  };
};

export type InternalHttpClient = HttpClient<never>;

export const InternalHttpClient = (megaraxHttpClient: MegaraxHttpClient): InternalHttpClient => ({
  get: (...args) => megaraxHttpClient.get(...args).then(throwOnFailure),
  post: (...args) => megaraxHttpClient.post(...args).then(throwOnFailure),
  put: (...args) => megaraxHttpClient.put(...args).then(throwOnFailure),
  delete: (...args) => megaraxHttpClient.delete(...args).then(throwOnFailure),
});

const throwOnFailure = (r: Ok<unknown> | Failure<HttpRequestError>): Ok<unknown> => {
  if (r.isFailure) throw new Error(r.error);

  return r;
};
