import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import { MdChevronLeft, MdChevronRight, MdFunctions, MdMoreHoriz } from "react-icons/md";
import ReactPaginate from "react-paginate";

import { IconButton } from "@megaron/dash-form";

type PageSizeProps = {
  pageSize: number;
  itemCount: number | undefined;
};

type Props = {
  page: number;
  onPageChange: (page: number) => void;
  className?: string;
} & PageSizeProps;

export const Pagination: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const [pageCount, setPageCount] = React.useState<number>();

  const handlePageChange = (page: number) => {
    props.onPageChange(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (props.itemCount) setPageCount(Math.ceil(props.itemCount / props.pageSize));
  }, [props.itemCount, props.pageSize]);

  const setPage = (page: number) => {
    if (!pageCount || page < 0 || page >= pageCount) return;
    handlePageChange(page);
  };

  useEffect(() => {
    if (pageCount && props.page >= pageCount) {
      setPage(pageCount - 1);
    }
  }, [pageCount]);

  return (
    <div
      className={props.className}
      css={{
        width: "100%",
        height: "auto",
        background: "white",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "stretch",
        gap: "0.75rem",
        minWidth: "316px",
        maxWidth: "400px",
        padding: "0.25rem 1rem",
        borderRadius: theme.smallBorderRadius,
        color: theme.colors.primary,
      }}
    >
      <div
        css={{
          fontSize: "1rem",
          height: "1.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.25rem",
        }}
      >
        <MdFunctions size={20} />{" "}
        <div css={{ minWidth: "2em" }}>{props.itemCount && props.itemCount >= 10000 ? "10000+" : props.itemCount}</div>
      </div>

      <div css={{ display: "flex", alignItems: "center", justifyContent: "space-around", gap: "0", flexGrow: 1 }}>
        <IconButton css={{ padding: "0.5rem" }} onClick={() => setPage(props.page - 1)}>
          <MdChevronLeft css={{ fontSize: "1rem", color: "black" }} />
        </IconButton>
        <ReactPaginate
          css={{
            color: theme.colors.primary,
            listStyle: "none",
            margin: "0",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            padding: "0 0.25rem",
            borderRadius: theme.smallBorderRadius,
            gap: "0.1rem",
            fontSize: "0.875rem",
            minHeight: "1.825rem",
            "& > li": {
              borderRadius: theme.smallBorderRadius,
              display: "flex",
              alignItems: "center",
              lineHeight: "1.5",
            },
            "& > li.active": {
              backgroundColor: theme.colors.primary,
              color: "white",
            },
            "& > .next, & > .previous": {
              display: "none",
            },
            "& > .break > a": {
              padding: 0,
              opacity: 0.35,
            },
            "& > li > a": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "1.75rem",
              height: "1.825rem",
              cursor: "pointer",
            },
          }}
          previousLabel={<MdChevronLeft />}
          nextLabel={<MdChevronRight />}
          breakLabel={<MdMoreHoriz />}
          pageClassName="page"
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={(page) => handlePageChange(page.selected)}
          containerClassName={"pagination"}
          activeClassName={"active"}
          forcePage={props.page}
          pageCount={pageCount ?? 0}
        ></ReactPaginate>{" "}
        <IconButton css={{ padding: "0.5rem" }} onClick={() => setPage(props.page + 1)}>
          <MdChevronRight css={{ fontSize: "1rem", color: "black" }} />
        </IconButton>
      </div>
    </div>
  );
};
