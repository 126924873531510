import { CustomerStatus } from "@megarax/crm-contracts";
import { Check, Delete, Edit, QuestionMark, RestoreFromTrash, Star, StarOutline } from "@mui/icons-material";
import { List, ListItemButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  status: CustomerStatus;
  onRename: () => void;
  onChangeStatus: (status: CustomerStatus) => void;
}

export const ActionsMenu: React.FunctionComponent<Props> = ({ status, onRename, onChangeStatus }) => {
  const classes = useStyles();

  const btns = {
    rename: (
      <ListItemButton onClick={onRename}>
        <Edit className={classes.icon} />
        Zmień nazwę
      </ListItemButton>
    ),
    removeVip: (
      <ListItemButton onClick={() => onChangeStatus("verified")}>
        <StarOutline className={classes.icon} />
        Usuń status VIP
      </ListItemButton>
    ),
    addVip: (
      <ListItemButton onClick={() => onChangeStatus("vip")}>
        <Star className={classes.icon} />
        Nadaj status VIP
      </ListItemButton>
    ),
    verify: (
      <ListItemButton onClick={() => onChangeStatus("verified")}>
        <Check className={classes.icon} />
        Klient zweryfikowany
      </ListItemButton>
    ),
    setUnverified: (
      <ListItemButton onClick={() => onChangeStatus("unverified")}>
        <QuestionMark className={classes.icon} />
        Do weryfikacji
      </ListItemButton>
    ),
    deactivate: (
      <ListItemButton onClick={() => onChangeStatus("inactive")}>
        <Delete className={classes.icon} />
        Dezaktywuj
      </ListItemButton>
    ),
    reactivate: (
      <ListItemButton onClick={() => onChangeStatus("verified")}>
        <RestoreFromTrash className={classes.icon} />
        Reaktywuj
      </ListItemButton>
    ),
  };

  return (
    <List disablePadding>
      {btns.rename}
      {status === "vip" && btns.removeVip}
      {status === "verified" && btns.addVip}
      {status !== "unverified" && btns.setUnverified}
      {status === "unverified" && btns.verify}
      {status !== "inactive" && btns.deactivate}
      {status === "inactive" && btns.reactivate}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));
