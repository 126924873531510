import { CircularProgress, Modal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { MdOutlineLocalPrintshop } from "react-icons/md";

interface Props {
  loading: boolean;
}

export const LoadingModal: React.FC<Props> = ({ loading }) => {
  const classes = useStyles();
  return (
    <Modal open={true}>
      {loading ? (
        <div className={classes.wrapper}>
          <div className={classes.logo}>
            <MdOutlineLocalPrintshop size="30px" />
          </div>
          <CircularProgress size="8rem" />
        </div>
      ) : (
        <div />
      )}
    </Modal>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      height: "80vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    logo: {
      padding: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "6rem",
      height: "6rem",
      position: "absolute",
      borderRadius: "6rem",
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
  };
});
