import { useTheme } from "@emotion/react";
import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const Header4: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <h4
      className={props.className}
      css={{
        fontSize: "1rem",
        color: theme.colors.primary,
        margin: 0,
        fontWeight: 600,
        fontFamily: theme.displayFontFamily,
      }}
    >
      {props.children}
    </h4>
  );
};

export const Header3: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <h3
      className={props.className}
      css={{
        fontSize: "1.125rem",
        color: theme.colors.primary,
        margin: 0,
        fontWeight: 600,
        fontFamily: theme.displayFontFamily,
      }}
    >
      {props.children}
    </h3>
  );
};
export const Header2: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <h2
      className={props.className}
      css={{
        fontSize: "1.25rem",
        color: theme.colors.primary,
        margin: 0,
        fontWeight: 600,
        fontFamily: theme.displayFontFamily,
      }}
    >
      {props.children}
    </h2>
  );
};
