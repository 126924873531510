import React from "react";

export const useRefCallback = <T>(func: T, deps: any[]) => {
  const funRef = React.useRef<T>(func);
  React.useEffect(() => {
    funRef.current = func;
  }, deps);

  return funRef;
};
