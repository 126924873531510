import { BaseResource, NestedResourceNode, ResourceAction } from "@megarax/rest-resource";
import { Serializers } from "@megarax/serializers";

export interface ProfileDto {
  firstName: string;
  lastName: string;
  pictureUrl: string | null;
}

export const profileDtoSerializer = Serializers.object<ProfileDto>({
  firstName: Serializers.string,
  lastName: Serializers.string,
  pictureUrl: Serializers.string.nullable(),
});

const isAllowed = ResourceAction({
  name: "isAllowed",
  method: "get",
  querySerializer: Serializers.object({
    action: Serializers.string,
  }),
  responseSerializer: Serializers.boolean,
});

export const meResource = BaseResource(["iam", "me"], {
  user: ResourceAction({
    name: "user",
    method: "get",
    path: "",
    responseSerializer: Serializers.object({
      id: Serializers.integer,
      groups: Serializers.array(
        Serializers.object({
          uuid: Serializers.string,
          name: Serializers.string,
        }),
      ),
      email: Serializers.string,
    }),
  }),
  isAllowed,
  isAllowedEach: ResourceAction({
    name: "isAllowedEach",
    method: "post",
    requestBodySerializer: Serializers.array(Serializers.string),
    responseSerializer: Serializers.mapObject(Serializers.boolean),
  }),

  profile: NestedResourceNode("profile", {
    update: ResourceAction({
      name: "updateProfile",
      method: "put",
      requestBodySerializer: Serializers.object<ProfileDto>({
        firstName: Serializers.string,
        lastName: Serializers.string,
        pictureUrl: Serializers.string.nullable(),
      }),
      path: "",
    }),
  }),
});
