import { HttpAction } from "@megaron/http-service";
import { budgetDocSerializer } from "@megaron/invoices-contracts";
import { Serializers } from "@megaron/serializers";

export const budgetActions = {
  searchBudgets: HttpAction({
    path: "/budgets",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      limit: Serializers.integer,
      offset: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.paginatedList(budgetDocSerializer),
    requiresAuth: true,
  }),
};
