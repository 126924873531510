import { DateString, dateStringSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export type UserWorkTimeReportDay = {
  date: DateString;
  startTime: Date | null;
  endTime: Date | null;
  breakDurationMinutes: number;
  workTimeMinutes: number | null;
  visitCount: number;
};

export type UserWorkTimeReport = {
  days: UserWorkTimeReportDay[];
  totalWorkTimeMinutes: number;
};

export const userWorkTimeReportDaySerializer = MSerializers.object<UserWorkTimeReportDay>({
  breakDurationMinutes: MSerializers.integer,
  date: dateStringSerializer,
  endTime: MSerializers.datetime.nullable(),
  startTime: MSerializers.datetime.nullable(),
  workTimeMinutes: MSerializers.integer.nullable(),
  visitCount: MSerializers.integer,
});

export const userWorkTimeReportSerializer = MSerializers.object<UserWorkTimeReport>({
  days: MSerializers.array(userWorkTimeReportDaySerializer),
  totalWorkTimeMinutes: MSerializers.integer,
});
