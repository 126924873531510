import { serializerExtensions, SerializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export const match = <T, TKey extends string = any>(
  getInputKey: (input: T) => TKey,
  getRawKey: (input: any) => TKey,
  serializerMap: {
    [key in TKey]: UnsafeSerializer<any>;
  },
): UnsafeSerializer<T> & SerializerExtensions<T> => ({
  serialize: (input) => {
    const key = getInputKey(input);
    return serializerMap[key].serialize(input);
  },
  forceDeserialize: (raw) => {
    const key = getRawKey(raw);
    return serializerMap[key].forceDeserialize(raw);
  },
  ...serializerExtensions(),
});
