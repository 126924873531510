import { Uuid } from "@megarax/common";
import { RegionSimple } from "@megarax/crm-contracts";
import { NewRouterResponsiveSelect } from "@megarax/ui-components";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export type GetRegions = (search: string) => Promise<RegionSimple[]>;

interface Props {
  onClose: () => void;
  getRegions: GetRegions;
  initialRegion: RegionSimple;
  onReassignRegion: (regionUuid: Uuid) => Promise<void>;
}

export const ChangeRegionDialog: React.FunctionComponent<Props> = ({
  onClose,
  getRegions,
  initialRegion,
  onReassignRegion,
}) => {
  const classes = useStyles();
  const [regionUuid, setRegionUuid] = React.useState<Uuid | null>(initialRegion.uuid);

  const save = async () => {
    await onReassignRegion(regionUuid!);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Zmień region</DialogTitle>
      <DialogContent className={classes.content}>
        <NewRouterResponsiveSelect
          initialValue={initialRegion}
          value={regionUuid}
          variant="standard"
          onChange={setRegionUuid}
          getOptions={getRegions}
          label="Region"
          getKey={(o) => o.uuid}
          getLabel={(o) => o.name}
          getValue={(o) => o.uuid}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" disabled={regionUuid === null} onClick={save}>
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: "200px",
  },
}));
