import { Chip, Theme, Tooltip } from "@mui/material";
import { useTheme } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React from "react";

const progressColor = (theme: Theme) => (progress: Decimal) => {
  if (progress.lessThan(0)) return theme.palette.error.main;
  if (progress.eq(0)) return theme.palette.grey[300];
  if (progress.lessThan(1)) return theme.palette.warning.main;
  if (progress.greaterThan(1)) return theme.palette.error.main;
  return theme.palette.success.main;
};

const useStyles = makeStyles<Theme, { progress: Decimal }>((theme: Theme) =>
  createStyles({
    chip: {
      cursor: "pointer",
      width: "4rem",
      [theme.breakpoints.down("md")]: {
        width: "auto",
        minWidth: "1rem",
      },
    },
  }),
);

interface Props {
  progress: Decimal;
  label: string;
  children?: React.ReactNode;
}

export const ProgressChip: React.FunctionComponent<Props> = ({ progress, label, children }) => {
  const classes = useStyles({ progress });
  const theme = useTheme();

  const percentage = progress.mul(100).toFixed(0);

  return (
    <Tooltip title={label}>
      <Chip
        variant="outlined"
        className={classes.chip}
        size="small"
        label={children}
        /**
         * This cannot be added to the useStyles function, since functions resolving to a style
         * eg. `color: () => theme.palette.error.main`, don't work properly there (the style
         * just isn't added to the component).
         */
        sx={{
          borderColor: progressColor(theme)(progress),
          color:
            progress.eq(Infinity) || progress.lte(0)
              ? "black"
              : theme.palette.getContrastText(progressColor(theme)(progress)),
          background:
            percentage === "NaN"
              ? theme.palette.success.main
              : `linear-gradient(to right, ${progressColor(theme)(
                  progress,
                )} ${percentage}%, transparent ${percentage}%)`,
        }}
      />
    </Tooltip>
  );
};
