import { AnyUser } from "@megaron/access-control";
import { HttpAction, HttpService, HttpSseAction } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";
import { Serializers, SerializerValue } from "@megaron/serializers";

const scanStatusSerializer = Serializers.stringOneOf("ok", "paused", "failedScan", "duplicateCode", "invalidQr");
export type ScanStatus = SerializerValue<typeof scanStatusSerializer>;

export const lineDtoSerializer = Serializers.object({
  id: Serializers.string,
  name: Serializers.string,
  groupName: Serializers.string,
  groupNameSuffix: Serializers.string,
  isIoConnected: Serializers.boolean,
  cameras: Serializers.array(
    Serializers.object({
      id: Serializers.string,
      isConnected: Serializers.boolean,
      status: scanStatusSerializer,
      lastCode: Serializers.string.nullable(),
    }),
  ),
});

export type LineDto = SerializerValue<typeof lineDtoSerializer>;

const addCodesToGroup = HttpAction({
  path: "/group/id/:groupName/addCodes",
  method: "post",
  paramsSerializer: Serializers.object({
    groupName: Serializers.string,
  }),
  bodySerializer: Serializers.object({
    codes: Serializers.array(Serializers.string),
  }),
  requiresAuth: true,
});

const removeCodesFromGroup = HttpAction({
  path: "/group/id/:groupName/removeCodes",
  method: "post",
  paramsSerializer: Serializers.object({
    groupName: Serializers.string,
  }),
  bodySerializer: Serializers.object({
    codes: Serializers.array(Serializers.string),
  }),
  requiresAuth: true,
});

const groupsQuery = HttpAction({
  path: "/groups",
  valueSerializer: Serializers.array(
    Serializers.object({
      groupName: Serializers.string,
      lastChange: Serializers.datetime,
      totalCount: Serializers.integer,
      unsyncedCount: Serializers.integer,
    }),
  ),
  requiresAuth: true,
});

const linesQuery = HttpAction({
  path: "/lines",
  valueSerializer: Serializers.array(
    Serializers.object({
      id: Serializers.string,
      name: Serializers.string,
    }),
  ),
  requiresAuth: true,
});

const lineQuery = HttpSseAction({
  path: "/lines/id/:lineId",
  paramsSerializer: Serializers.object({
    lineId: Serializers.string,
  }),
  errorSerializer: Serializers.stringOneOf("LineNotFound"),
  valueSerializer: lineDtoSerializer,
  requiresAuth: true,
});

const changeGroupNameSuffix = HttpAction({
  path: "/lines/id/:lineId/changeGroupNameSuffix",
  method: "post",
  paramsSerializer: Serializers.object({
    lineId: Serializers.string,
  }),
  bodySerializer: Serializers.object({
    groupNameSuffix: Serializers.string,
  }),
  errorSerializer: Serializers.stringOneOf("LineNotFound"),
  requiresAuth: true,
});

const pauseLine = HttpAction({
  path: "/lines/id/:lineId/pause",
  method: "post",
  paramsSerializer: Serializers.object({
    lineId: Serializers.string,
  }),
  errorSerializer: Serializers.stringOneOf("LineNotFound"),
  requiresAuth: true,
});

const resumeCamera = HttpAction({
  path: "/lines/id/:lineId/cameras/id/:cameraId/resume",
  method: "post",
  paramsSerializer: Serializers.object({
    lineId: Serializers.string,
    cameraId: Serializers.string,
  }),
  errorSerializer: Serializers.stringOneOf("CameraNotFound", "AlreadyRunning"),
  requiresAuth: true,
});

const pauseCamera = HttpAction({
  path: "/lines/id/:lineId/cameras/id/:cameraId/pause",
  method: "post",
  paramsSerializer: Serializers.object({
    lineId: Serializers.string,
    cameraId: Serializers.string,
  }),
  errorSerializer: Serializers.stringOneOf("CameraNotFound", "NotRunning"),
  requiresAuth: true,
});

export const qrSyncService = HttpService({
  linesQuery,
  pauseLine,
  lineQuery,
  groupsQuery,
  addCodesToGroup,
  removeCodesFromGroup,
  changeGroupNameSuffix,
  resumeCamera,
  pauseCamera,
});

export type QrSyncHandlers = HandlerMap<typeof qrSyncService, AnyUser>;
