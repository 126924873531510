import "chart.js/auto";

import { useTheme } from "@emotion/react";
import { ChartData } from "chart.js";
import React from "react";
import { Pie } from "react-chartjs-2";
import tinycolor from "tinycolor2";

import { ProductCodeStatus } from "@megaron/loyalty-points-contracts";

type Props = {
  total: number;
  byStatus: {
    [status in ProductCodeStatus]: number;
  };
  className?: string;
};

export const StatusSummary: React.FunctionComponent<Props> = (props) => {
  return (
    <div css={{ display: "flex", flexDirection: "row", gap: "3rem", alignItems: "center" }} className={props.className}>
      <div
        css={{
          maxWidth: "240px",
          minWidth: "180px",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          flexGrow: 1,
        }}
      >
        <Row
          label="Całość"
          value={props.total}
          css={{
            fontSize: "1.25rem",
            borderBottom: "1px solid #777",
            paddingBottom: "0.5rem",
            marginBottom: "0.5rem",
          }}
        />
        <Row label="Usunięte" value={props.byStatus.destroyed} />
        <Row label="Przeterminowane" value={props.byStatus.expired} />
        <Row label="Nieaktywne" value={props.byStatus.blank} />
        <Row label="Aktywne" value={props.byStatus.active} />
        <Row label="Wykorzystane" value={props.byStatus.redeemed} />
      </div>
      <Chart {...props} />
    </div>
  );
};

const Row = (props: { label: string; value: number; className?: string }) => (
  <div css={{ display: "flex", flexDirection: "row" }} className={props.className}>
    <span css={{}}>{props.label}</span>
    <span css={{ fontWeight: "bold", marginLeft: "auto" }}>{props.value}</span>
  </div>
);

const Chart: React.FunctionComponent<Props> = ({ total, byStatus }) => {
  const theme = useTheme();

  const data: ChartData<"pie"> = {
    labels: ["Usunięte", "Przeterminowane", "Nieaktywne", "Aktywne", "Wykorzystane"],
    datasets: [
      {
        data: [byStatus.destroyed, byStatus.expired, byStatus.blank, byStatus.active, byStatus.redeemed],
        // backgroundColor: ["#AA0000", "#AA0000", "#D9D9D9", "#57AEFF", "#000000"],
        backgroundColor: [
          "#000",
          "#000",
          "#d9d9d9",
          tinycolor(theme.colors.primary).brighten(40).toHexString(),
          theme.colors.primary,
        ],
      },
    ],
  };

  return (
    <div css={{ height: "12rem" }}>
      <Pie data={data} options={{ plugins: { legend: { display: false } } }} />
    </div>
  );
};
