import { Add as AddIcon } from "@mui/icons-material";
import { Fab, FabProps, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props extends Pick<FabProps, "disabled"> {
  text: string;
  onClick: () => void;
  alwaysSmall?: boolean;
}

export const NewItemFab: React.FC<Props> = ({ onClick, text, alwaysSmall, ...fabProps }) => {
  const classes = useStyles();
  const hideText = useMediaQuery("@media (max-width:360px)");

  const isSmall = hideText || alwaysSmall;

  return (
    <Fab
      {...fabProps}
      size={isSmall ? "small" : "medium"}
      variant={isSmall ? "circular" : "extended"}
      className={classes.newButton}
      color="primary"
      onClick={onClick}
    >
      <AddIcon className={!isSmall ? classes.icon : undefined} />
      {!isSmall ? text : undefined}
    </Fab>
  );
};

const useStyles = makeStyles({
  newButton: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    minWidth: "40px",
  },
  icon: {
    marginRight: "0.5rem",
  },
});
