import { DeliveryAddress } from "@megarax/crm-contracts";
import { formatAddressLine } from "@megarax/react-utils";
import { TextInput } from "@megarax/ui-components";
import {
  ExtractValidatorError,
  getErrorMessage,
  objectValidator,
  ObjectValidatorSchema,
  requiredValidator,
  stringValidator,
} from "@megarax/validators";
import { Collapse, Radio, RadioGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

interface Props {
  onChange: (address: DeliveryAddress | null) => void;
  recipientAddress: DeliveryAddress | null;
}

const addressValidatorSchema = ObjectValidatorSchema<DeliveryAddress, DeliveryAddress>()({
  name: requiredValidator(),
  locality: requiredValidator(),
  street: requiredValidator(),
  houseNumber: stringValidator(),
  postalCode: requiredValidator(),
  country: stringValidator(),
});

const addressValidator = objectValidator(addressValidatorSchema);
export type FormErrors = ExtractValidatorError<typeof addressValidator>;

type AddressRadioOption = "new" | "old" | "recipient";

export const DeliveryAddressSelect: React.FC<Props> = ({ recipientAddress, onChange }) => {
  const classes = useStyles();

  const [addressRadio, setAddressRadio] = useState<AddressRadioOption>("old");
  const [newAddress, setNewAddress] = useState<DeliveryAddress>({
    name: "",
    locality: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    country: "Polska",
  });
  const [error, setError] = useState<FormErrors>();

  const onNewAddressChange = (field: keyof DeliveryAddress) => (value: string) =>
    setNewAddress({ ...newAddress, [field]: value });

  React.useEffect(() => {
    const effectiveAddress =
      addressRadio === "new" ? newAddress : addressRadio === "recipient" ? recipientAddress : null;

    onChange(effectiveAddress);
  }, [addressRadio, newAddress]);

  const newAddressError = (error: "required" | "invalidDecimal" | undefined) =>
    addressRadio === "new" ? getErrorMessage(error) : "";

  return (
    <RadioGroup value={addressRadio} onChange={(e, value) => setAddressRadio(value as AddressRadioOption)}>
      {recipientAddress && (
        <>
          <Typography variant="subtitle2" className={classes.title}>
            Użyj adresu nabywcy:
          </Typography>
          <div onClick={() => setAddressRadio("recipient")} className={classes.row}>
            <Radio value="recipient" />
            <Typography>{formatAddressLine(recipientAddress)}</Typography>
          </div>
          <Typography variant="subtitle2" className={classes.title}>
            lub wpisz inny:
          </Typography>
        </>
      )}
      <div className={classes.addressRow} onClick={() => setAddressRadio("new")}>
        {recipientAddress && <Radio value="new" />}
        <div>
          <div>
            <TextInput
              fullWidth
              label="Nazwa"
              variant="outlined"
              value={newAddress.name}
              onChange={onNewAddressChange("name")}
              error={newAddressError(error?.name)}
            />
          </div>
          <Collapse in={addressRadio === "new" || !recipientAddress}>
            <div className={classes.addressSecondRow}>
              <TextInput
                fullWidth
                label="Ulica"
                variant="outlined"
                value={newAddress.street}
                onChange={onNewAddressChange("street")}
                error={newAddressError(error?.street)}
              />
              <TextInput
                label="Numer domu"
                variant="outlined"
                value={newAddress.houseNumber}
                onChange={onNewAddressChange("houseNumber")}
              />
            </div>
            <div className={classes.addressThirdRow}>
              <TextInput
                label="Kod pocztowy"
                variant="outlined"
                value={newAddress.postalCode}
                onChange={onNewAddressChange("postalCode")}
                error={newAddressError(error?.postalCode)}
              />
              <TextInput
                fullWidth
                label="Miejscowość"
                variant="outlined"
                value={newAddress.locality}
                onChange={onNewAddressChange("locality")}
                error={newAddressError(error?.locality)}
              />
            </div>
            <div className={classes.singleRow}>
              <TextInput
                fullWidth
                label="Kraj"
                variant="outlined"
                value={newAddress.country}
                onChange={onNewAddressChange("country")}
              />
            </div>
          </Collapse>
        </div>
      </div>
    </RadioGroup>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    title: {
      margin: theme.spacing(1, 0),
    },
    content: {
      paddingTop: "8px !important",
      display: "flex",
      flexDirection: "column",
      "& > .MuiFormControl-root": {
        margin: theme.spacing(2, 0),
      },
      [theme.breakpoints.down("sm")]: {
        boxSizing: "border-box",
        padding: theme.spacing(2),
        width: "100%",
      },
    },

    row: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    addressRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "flex-start",
      "& > span": {
        marginTop: theme.spacing(1),
      },
    },
    addressSecondRow: {
      margin: theme.spacing(2, 0),
      display: "flex",
      "& > .MuiFormControl-root": {
        boxSizing: "border-box",
      },
      "& > :nth-child(odd)": {
        marginRight: theme.spacing(1),
        width: `calc(70% - 8px)`,
      },
      "& > :nth-child(even)": {
        marginLeft: theme.spacing(1),
        width: `calc(30% - 8px)`,
        minWidth: "130px",
      },
    },
    addressThirdRow: {
      display: "flex",
      margin: theme.spacing(2, 0),
      "& > .MuiFormControl-root": {
        boxSizing: "border-box",
      },
      "& > :nth-child(odd)": {
        width: `calc(30% - 8px)`,
        minWidth: "130px",

        marginRight: theme.spacing(1),
      },
      "& > :nth-child(even)": {
        width: `calc(70% - 8px)`,
        marginLeft: theme.spacing(1),
      },
    },
    singleRow: {
      margin: theme.spacing(2, 0),
    },
    miniInput: {
      marginRight: theme.spacing(1),
    },
  };
});
