import { DateString, dateStringSerializer } from "@megaron/date-string";
import { Serializers } from "@megaron/serializers";

export type CodeActivityDayDto = {
  date: DateString;
  scansWithKey: number;
  scansWithoutKey: number;
  redemptions: number;
};

export const codeActivityDayDtoSerializer = Serializers.object<CodeActivityDayDto>({
  date: dateStringSerializer,
  scansWithKey: Serializers.integer,
  scansWithoutKey: Serializers.integer,
  redemptions: Serializers.integer,
});
