import { authAttributeSerializer } from "@megaron/access-control";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { customerCategories } from "./categories";
import { customerProductSerializer } from "./purchases";

export const loyaltyRegistrationStatuses = ["preregistered", "joined", "registered", "deleted"] as const;
export type LoyaltyRegistrationStatus = (typeof loyaltyRegistrationStatuses)[number];

export const customerStatuses = ["vip", "verified", "unverified", "inactive"] as const;
export type CustomerStatus = (typeof customerStatuses)[number];

export const tags = [
  "glazurnik",
  "targi",
  "szkolenie",
  "social_media",
  "hurtownia",
  "polecenia",
  "baza_specjalista",
  "testy",
  "sucha_zabudowa",
];

const customerAddressSerializer = Serializers.object({
  country: Serializers.string,
  city: Serializers.string,
  street: Serializers.string,
  postalCode: Serializers.string,
  latitude: Serializers.decimal.nullable(),
  longitude: Serializers.decimal.nullable(),
});

export type CustomerAddress = SerializerValue<typeof customerAddressSerializer>;

const customersDetailsSerializer = Serializers.object({
  uuid: uuidSerializer,
  categories: Serializers.stringOneOf(...customerCategories).array(),
  phoneNumber: Serializers.string,
  firstName: Serializers.string,
  lastName: Serializers.string,
  email: Serializers.email.nullable(),
  address: customerAddressSerializer,
  region: Serializers.string.nullable(),
  lastPurchaseTime: Serializers.datetime.nullable(),
  totalMassKg: Serializers.decimal,
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  isArchived: Serializers.boolean,
  tags: Serializers.array(Serializers.string),
  pos: Serializers.array(Serializers.string),
  status: Serializers.stringOneOf(...customerStatuses),
  loyaltyRegistrationStatus: Serializers.stringOneOf(...loyaltyRegistrationStatuses).nullable(),
});

export type CustomerDetails = SerializerValue<typeof customersDetailsSerializer>;

const saveRegionSerializer = Serializers.object({
  region: Serializers.string.nullable(),
});

const archiveCustomerBodySerializer = Serializers.object({
  isArchived: Serializers.boolean,
});

export const customerListObjectSerializer = Serializers.object({
  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
});

export const customerListSerializer = Serializers.object({
  items: Serializers.array(customerListObjectSerializer),
  count: Serializers.integer,
});

export type CustomerListObject = SerializerValue<typeof customerListObjectSerializer>;

export const newCustomerSerializer = Serializers.object({
  uuid: uuidSerializer,
  region: Serializers.string.nullable(),
});

export type NewCustomer = SerializerValue<typeof newCustomerSerializer>;

export const updateCustomerDtoSerializer = Serializers.object({
  firstName: Serializers.string,
  lastName: Serializers.string,
  email: Serializers.email.nullable(),
  address: customerAddressSerializer,
});

export type UpdateCustomerDto = SerializerValue<typeof updateCustomerDtoSerializer>;

export const addCustomersFromListBodySerializer = Serializers.object({
  phoneNumber: Serializers.string.nullable(),
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  email: Serializers.string.nullable(),
  region: Serializers.string.nullable(),
});

export const sortSerializer = Serializers.object({
  field: Serializers.string,
  order: Serializers.stringOneOf("ASC", "DESC"),
});

export type SortFieldKeys = keyof {
  scan: string;
  mass: string;
  interaction: string;
};

export type Sort = SerializerValue<typeof sortSerializer>;

const customerBulkQueryEntrySerializer = Serializers.object({
  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  interactionCount: Serializers.integer,
  lastInteraction: Serializers.object({
    uuid: uuidSerializer,
    createdAt: Serializers.datetime,
    ownerEmail: Serializers.string,
    message: Serializers.string,
  }).nullable(),
  lastPurchaseTime: Serializers.datetime.nullable(),
  postalCode: Serializers.string,
  totalMassKg: Serializers.decimal,
  email: Serializers.email.nullable(),
  products: Serializers.array(customerProductSerializer),
  region: Serializers.string.nullable(),
  isArchived: Serializers.boolean,
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  tags: Serializers.array(Serializers.string),
  pos: Serializers.array(Serializers.string),
  regionOwnerEmail: Serializers.string.nullable(),
  authorizedAttributes: authAttributeSerializer.array(),
  latitude: Serializers.decimal.nullable(),
  longitude: Serializers.decimal.nullable(),
  status: Serializers.stringOneOf(...customerStatuses),
});

export type CustomerBulkQueryEntry = SerializerValue<typeof customerBulkQueryEntrySerializer>;

export const tagsSerializer = Serializers.object({
  tags: Serializers.array(Serializers.string),
});

export const customers = {
  addCustomer: HttpAction({
    path: "/customer",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: newCustomerSerializer,
    valueSerializer: Serializers.object({ uuid: uuidSerializer }),
    errorSerializer: Serializers.stringOneOf("CustomerAlreadyExists"),
  }),

  addCustomersFromList: HttpAction({
    path: "/customers",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.array(addCustomersFromListBodySerializer),
  }),

  customerBulkQuery: HttpAction({
    path: "/customers/bulk",
    method: "get",
    qsSerializer: Serializers.object({
      customerUuids: uuidSerializer.array().asJsonString(),
      regions: Serializers.string.array().asJsonString(),
    }).partial(),
    valueSerializer: customerBulkQueryEntrySerializer.array(),
    requiresAuth: true as const,
  }),

  getCustomer: HttpAction({
    path: "/customer/:customerId",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: customersDetailsSerializer,
    paramsSerializer: Serializers.object({
      customerId: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  saveCustomerRegion: HttpAction({
    path: "/customer/region/:customerId",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: saveRegionSerializer,
    paramsSerializer: Serializers.object({
      customerId: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  updateCustomer: HttpAction({
    path: "/customer/:customerId",
    method: "patch",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      customerId: uuidSerializer,
    }),
    bodySerializer: updateCustomerDtoSerializer,
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  saveCustomerDetails: HttpAction({
    path: "/customer/:customerId/saveCustomerDetails",
    method: "patch",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      customerId: uuidSerializer,
    }),
    bodySerializer: Serializers.object({
      phoneNumber: Serializers.string,
      firstName: Serializers.string,
      lastName: Serializers.string,
      email: Serializers.email.nullable(),
    }),
    errorSerializer: Serializers.stringOneOf(
      "CustomerNotFound",
      "Unauthorized",
      "CustomerRegistered",
      "CustomerDeleted",
      "UserInLoyalty",
    ),
  }),

  saveCustomerAddress: HttpAction({
    path: "/customer/:customerId/saveCustomerAddress",
    method: "patch",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      customerId: uuidSerializer,
    }),
    bodySerializer: customerAddressSerializer,
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  archiveCustomer: HttpAction({
    path: "/customer/:customerId/archive",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: archiveCustomerBodySerializer,
    paramsSerializer: Serializers.object({
      customerId: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  saveCustomerTags: HttpAction({
    path: "/customer/:customerId/tags",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      tags: Serializers.array(Serializers.string),
    }),
    paramsSerializer: Serializers.object({
      customerId: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  saveCustomerPos: HttpAction({
    path: "/customer/:customerId/pos",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      posUuidList: Serializers.array(Serializers.string),
    }),
    paramsSerializer: Serializers.object({
      customerId: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  syncLoyaltyCustomers: HttpAction({
    path: "/customers/syncLoyaltyCustomers",
    method: "post",
    requiresAuth: true as const,
  }),

  syncLoyaltyCoordinate: HttpAction({
    path: "/customers/syncLoyaltyCoordinate",
    method: "post",
    requiresAuth: true as const,
  }),

  refreshLoyaltyHistory: HttpAction({
    path: "/customers/RefreshLoyaltyHistory",
    method: "post",
    requiresAuth: true as const,
  }),
};
