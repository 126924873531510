import { validateUuidFormat } from "@megarax/common";
import { isMonth, Month } from "@megarax/crm-contracts";
import { Serializers } from "@megarax/serializers";
import Decimal from "decimal.js";
import _ from "lodash";

import { Failure, Ok } from "@megaron/result";

export type InputRow = {
  customerUuid: string;
  discountRates: {
    [month: Month]: Decimal | null;
  };
};

export const rawToInput = (raw: unknown) => {
  if (!Array.isArray(raw)) return Failure("InvalidInput");
  if (raw.length === 0) return Failure("InvalidInput");

  const mapped: InputRow[] = [];

  for (const rawRow of raw) {
    if (typeof rawRow !== "object") return Failure("InvalidInput");
    const row = rawToInputRow(rawRow);
    if (row.isFailure) return row;
    mapped.push(row.value);
  }

  return Ok(mapped);
};

export const rawToInputRow = (raw: { [key: string]: unknown }) => {
  if (!("customerUuid" in raw)) return Failure("MissingCustomerUuid");
  if (typeof raw.customerUuid !== "string") return Failure("InvalidCustomerUuid");
  const isUuidValid = validateUuidFormat(raw.customerUuid);
  if (!isUuidValid) return Failure("InvalidCustomerUuid");

  const allKeys = _.keys(raw);
  const months = allKeys.filter(isMonth);

  const discountRates: InputRow["discountRates"] = {};

  for (const month of months) {
    try {
      discountRates[month] = Serializers.decimal.forceDeserialize(raw[month]);
    } catch (err: any) {
      discountRates[month] = null;
    }
  }

  return Ok<InputRow>({
    discountRates: discountRates,
    customerUuid: raw.customerUuid,
  });
};
