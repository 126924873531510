import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAsyncLoad } from "@megarax/react-utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SearchBar } from "../../display";
import { CustomList } from "./CustomList";
import { GetOptionKey, TransferListProps } from "./propTypes";

const not = <T,>(a: T[], b: T[]) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = <T,>(a: T[], b: T[]) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const subtract = <T, K>(a: T[], b: T[], getKey: GetOptionKey<T, K>) => {
  return a.filter((n) => b.filter((e) => getKey(e) === getKey(n)).length === 0);
};

export const TransferList = <T, K>({
  getOptions,
  right,
  setRight,
  getKey,
  getLabel,
}: TransferListProps<T, K>): React.ReactElement => {
  const [searchValue, setSearchValue] = useState<string>("");
  const { value: options, loading } = useAsyncLoad(
    () => getOptions(searchValue),
    [searchValue],
  );

  const classes = useStyles();
  const [checked, setChecked] = useState<T[]>([]);
  const [left, setLeft] = useState<T[]>([]);

  const leftChecked = useMemo(() => intersection(checked, left), [
    checked,
    left,
  ]);
  const rightChecked = useMemo(() => intersection(checked, right), [
    checked,
    right,
  ]);

  const handleToggle = useCallback(
    (value: T) => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    },
    [checked],
  );

  const handleAllRight = useCallback(() => {
    setRight(right.concat(left));
    setLeft([]);
  }, [right, left]);

  const handleCheckedRight = useCallback(() => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  }, [right, left, leftChecked]);

  const handleCheckedLeft = useCallback(() => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  }, [right, left, rightChecked]);

  const handleAllLeft = useCallback(() => {
    setLeft(left.concat(right));
    setRight([]);
  }, [right, left]);

  useEffect(() => {
    if (!options) return;
    setLeft(subtract(options, right, getKey));
  }, [options]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item container direction="column" className={classes.listColumn}>
        <Typography variant="button" className={classes.title}>
          Dostępne
        </Typography>
        <SearchBar onSearchChange={setSearchValue} />
        <CustomList
          items={left}
          handleToggle={handleToggle}
          checked={checked}
          getKey={getKey}
          getLabel={getLabel}
          loading={loading}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row-reverse" alignItems="center">
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            <FontAwesomeIcon icon={faAngleDoubleDown} />
          </IconButton>
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </IconButton>
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <FontAwesomeIcon icon={faAngleUp} />
          </IconButton>
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            <FontAwesomeIcon icon={faAngleDoubleUp} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container direction="column" className={classes.listColumn}>
        <Typography variant="button" className={classes.title}>
          Wybrane
        </Typography>
        <CustomList
          items={right}
          handleToggle={handleToggle}
          checked={checked}
          getKey={getKey}
          getLabel={getLabel}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(0, 1),
      fontSize: "1.5rem",
      padding: "3px 8px",
    },
    listColumn: {
      width: "100%",
    },
  }),
);
