import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom-v5-compat";

import { Tile, TileStripe } from "@megaron/dash-tile";
import { DocType, IndexModuleDto } from "@megaron/docs-contracts";

import { DocsIndexDialogElement } from "./DocsIndexDialogElement";

type Props = {
  docsIndexModules: IndexModuleDto[];
  onRefresh: (module: DocType) => void;
  isRefreshing: boolean;
  isLoading: boolean;
};

export const DocsMobileList: React.FC<Props> = ({ docsIndexModules, isLoading, isRefreshing, onRefresh }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "0.625rem",
        width: "100%",
        marginBottom: "1rem",
        opacity: isLoading ? 0.5 : 1,
      }}
    >
      {docsIndexModules.map((entry) => (
        <>
          <Link key={entry.indexVersionName} to={`/dev/docs/${entry.indexVersionName}`}>
            <Tile stripe={<TileStripe color={theme.colors.primaryLight} />}>
              <div
                css={{ display: "flex", flexDirection: "column", gap: "0.5rem", fontSize: "0.875rem", color: "black" }}
              >
                <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
                  <span css={{ fontWeight: 700 }}>{entry.docType}</span>
                  <span>{entry.lastRefreshAt?.toLocaleDateString()}</span>
                </div>
                <div css={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                  <span css={{ overflow: "hidden", whiteSpace: "nowrap", color: theme.colors.primary }}>
                    {entry.indexAlias}
                  </span>
                </div>
                <div css={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                  <span css={{ overflow: "hidden", whiteSpace: "nowrap" }}>{entry.indexVersionName}</span>
                </div>
                <div css={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                  <span css={{ overflow: "hidden", whiteSpace: "nowrap", color: theme.colors.danger }}>
                    Error: {JSON.stringify(entry.setupError) || "-"}
                  </span>
                </div>
              </div>
            </Tile>
          </Link>
          <DocsIndexDialogElement
            docsIndex={entry}
            isRefreshLoading={isRefreshing}
            onRefresh={() => onRefresh(entry.docType)}
            key={entry.indexVersionName}
          />
        </>
      ))}
    </div>
  );
};
