import { TableCell, TableRow } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import React from "react";

interface Props {
  term: string;
  children?: React.ReactNode;
}

type ClassKey = "term" | "description";

const DefinitionItemUnstyled: React.FunctionComponent<Props & WithStyles<ClassKey>> = ({ term, classes, children }) => (
  <TableRow>
    <TableCell variant="head" component="th" scope="row" className={classes.term}>
      {term}
    </TableCell>
    <TableCell className={classes.description}>{children}</TableCell>
  </TableRow>
);

export const DefinitionItem = withStyles((theme) => ({
  term: {
    backgroundColor: theme.palette.background.default,
    whiteSpace: "nowrap",
    wordBreak: "keep-all",
    padding: "8px",
  },
  description: {
    width: "100%",
    padding: "8px",
    whiteSpace: "pre-line",
  },
}))(DefinitionItemUnstyled);
