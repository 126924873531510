import { UuidResourceParam, uuidSerializer } from "@megarax/common";
import { BaseResource, ResourceActionV2 } from "@megarax/rest-resource";
import { Serializers } from "@megarax/serializers";

import { newOrderInputSerializer } from "./newOrderInput";
import { orderDtoSerializer } from "./orderDto";
import { orderSimpleDtoSerializer } from "./orderSimpleDto";

const customerRecentOrdersAction = ResourceActionV2({
  name: "customerRecentOrders",
  method: "get",
  querySerializer: Serializers.object({
    customerUuid: uuidSerializer,
  }),
  responseValueSerializer: Serializers.array(orderSimpleDtoSerializer),
});
export const orderResource = BaseResource(["bc", "orders"], {
  refresh: ResourceActionV2({
    name: "refresh",
    method: "post",
    path: "refresh",
  }),
  byUuid: UuidResourceParam("uuid", "uuid", {
    placeNewOrder: ResourceActionV2({
      name: "placeNewOrder",
      method: "post",
      path: "",
      requestBodySerializer: newOrderInputSerializer,
      responseErrorSerializer: Serializers.stringOneOf("OrderAlreadyExists", "UnknownGtin"),
      responseValueSerializer: uuidSerializer,
    }),
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      path: "",
      responseValueSerializer: orderDtoSerializer.nullable(),
    }),
  }),
  allOrders: ResourceActionV2({
    name: "allOrders",
    method: "get",
    path: "",
    responseValueSerializer: Serializers.array(orderDtoSerializer),
  }),
  customerRecentOrders: customerRecentOrdersAction,
});
