import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import { MdArrowDownward, MdArrowUpward, MdSwapVert } from "react-icons/md";

import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";

import { FlagFilter } from "./FlagFilter";

type Option = {
  label: string;
  value: string;
};

type Props = {
  className?: string;
  label: string;
  value: { field: string; order: "ASC" | "DESC" | "" };
  options: Option[];
  type?: "filter" | "button";
  onChange: (value: { field: string; order: "ASC" | "DESC" } | null) => void;
};

export const Sort = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeOption, setActiveOption] = React.useState<string>(props.value.field);
  const [sortOrder, setSortOrder] = React.useState<"ASC" | "DESC" | "">(props.value.order);

  useEffect(() => {
    if (props.value.field !== activeOption || props.value.order !== sortOrder) {
      setActiveOption(props.value.field);
      setSortOrder(props.value.order);
    }
  }, [activeOption, sortOrder, props.value.field, props.value.order]);

  const isSelected = activeOption !== "" && sortOrder !== "";
  const { isMobile } = useDeviceType();
  const theme = useTheme();

  const handleOptionClick = (optionValue: string) => {
    let newSortOrder: "ASC" | "DESC" | "" = "";

    if (optionValue === activeOption) {
      if (sortOrder === "ASC") {
        newSortOrder = "DESC";
      } else if (sortOrder === "DESC") {
        newSortOrder = "";
      } else {
        newSortOrder = "ASC";
      }
    } else {
      newSortOrder = "ASC";
    }

    setActiveOption(optionValue);
    setSortOrder(newSortOrder);
    props.onChange(newSortOrder ? { field: optionValue, order: newSortOrder } : null);
  };

  const getIcon = (optionValue: string): React.ReactNode => {
    if (isSelected && optionValue === activeOption) {
      return sortOrder === "ASC" ? <MdArrowUpward /> : <MdArrowDownward />;
    } else {
      return <MdSwapVert />;
    }
  };

  const getLabel = (optionValue: string): string => {
    const selectedOption = props.options.find((option) => option.value === optionValue);
    return selectedOption ? selectedOption.label : props.label;
  };

  const ButtonStyle = {
    width: "100%",
    justifyContent: "space-between",
  };

  return (
    <>
      <FlagFilter
        css={props.type === "button" && ButtonStyle}
        isActive={isSelected}
        label={
          isSelected ? (
            <span css={{ display: "flex", alignItems: "center", gap: "0.2em" }}>
              {getLabel(activeOption)} {getIcon(activeOption)}
            </span>
          ) : (
            props.label
          )
        }
        onClick={() => setIsOpen(true)}
        onDelete={() => {
          setActiveOption("");
          setSortOrder("");
          props.onChange(null);
        }}
      />

      {isOpen && (
        <Dialog
          placement={isMobile ? "bottom" : "center"}
          onClose={() => setIsOpen(false)}
          header={"Sortuj"}
          css={{ minWidth: "400px" }}
        >
          <div css={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {props.options.map((option) => (
              <button
                key={option.value}
                css={{
                  fontWeight: "400",
                  fontSize: "1rem",
                  cursor: "pointer",
                  borderRadius: theme.smallBorderRadius,
                  border: "none",
                  outline: "none",
                  backgroundColor: isSelected
                    ? option.value === activeOption
                      ? theme.colors.primary
                      : "transparent"
                    : "transparent",
                  color: isSelected
                    ? option.value === activeOption
                      ? "#fff"
                      : theme.colors.secondaryText
                    : theme.colors.secondaryText,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 12px",
                }}
                onClick={() => handleOptionClick(option.value)}
              >
                <span>{option.label}</span>
                {getIcon(option.value)}
              </button>
            ))}
          </div>
        </Dialog>
      )}
    </>
  );
};
