import { Uuid } from "@megarax/common";
import { contactListResource, RemoveContactInput, SaveContactInput } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Failure, Ok, Result } from "@megaron/result";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

type OnSuccessFunction = (() => void) | (() => Promise<void>);

const voidifyResult = <TValue, TError>(result: Result<TValue, TError>): Result<void, void> =>
  result.flatMapError(() => Failure()).flatMap(() => Ok());

export const useCustomerContacts = () => {
  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const provider = useResourceProviderV2(contactListResource);

  const retrieveCustomerContacts = (customerUuid: Uuid) =>
    provider.byCustomerUuid(customerUuid).retrieve().mapError(handleError);

  const removeContact =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid) =>
    (input: RemoveContactInput) =>
      provider
        .byCustomerUuid(uuid)
        .removeContact(undefined, input)
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const saveContact =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid) =>
    (input: SaveContactInput) =>
      provider
        .byCustomerUuid(uuid)
        .saveContact(undefined, input)
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  return {
    retrieveCustomerContacts,
    removeContact,
    saveContact,
  };
};
