import { useTheme } from "@emotion/react";

export const Divider = () => {
  const theme = useTheme();

  return (
    <hr
      css={{
        width: "100%",
        height: 0,
        border: 0,
        borderTop: `1px solid ${theme.colors.border}`,
      }}
    />
  );
};
