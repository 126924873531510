import { DayView } from "@devexpress/dx-react-scheduler-material-ui";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

type Props = DayView.TimeTableCellProps & {
  dayStartTime: Date | null;
  dayEndTime: Date | null;
};

export const CalendarCell: React.FunctionComponent<Props> = ({
  dayEndTime,
  dayStartTime,
  ...props
}) => {
  const classes = useStyles();

  const isAfterStart =
    props.endDate === undefined || dayStartTime === null || props.endDate > dayStartTime;
  const isBeforeEnd =
    props.startDate === undefined || dayEndTime === null || props.startDate < dayEndTime;

  const isActive = isAfterStart && isBeforeEnd;

  return (
    <DayView.TimeTableCell
      {...props}
      isShaded={false}
      className={clsx({ [classes.inactive]: !isActive })}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  inactive: {
    // background: "rgba(0.8, 0.8, 0.8, 0.2) !important",
  },
}));
