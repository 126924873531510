import { useTheme } from "@emotion/react";
import { MdPerson } from "react-icons/md";

export const DefaultAvatar: React.FC<{ pxSize?: number }> = ({ pxSize }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        borderRadius: "100%",
        width: pxSize ? `${pxSize}px` : "1rem",
        height: pxSize ? `${pxSize}px` : "1rem",
        border: `1px solid ${theme.colors.border}`,
        position: "relative",
        backgroundColor: "white",
      }}
    >
      <MdPerson
        css={{
          color: theme.colors.secondaryText,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        size={pxSize ? pxSize - 4 : 12}
      />
    </div>
  );
};
