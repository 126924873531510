import { useShallowEffect } from "@mantine/hooks";
import { useCallback, useState } from "react";

import { AnyDoc, DocType } from "@megaron/docs-contracts";
import { useServiceClient } from "@megaron/react-clients";

import { BaseSelectDialog, CommonSearchProps, SelectOption, SelectTrigger } from "./BaseSelectDialog";

type AnyDocSelectOption = AnyDoc & SelectOption;

type SelectProps = CommonSearchProps<AnyDocSelectOption> & {
  docType?: DocType[];
  initiallySelectedDocIds?: string[] | undefined;
  noTrigger?: boolean;
};

export const DocsSelectDialog = ({ docType, initiallySelectedDocIds, noTrigger, ...selectProps }: SelectProps) => {
  const docs = useServiceClient("docs");

  const fetchSearchOptions = useCallback(
    async (searchText?: string) => {
      const searchOptions = await docs.searchAnyDoc({
        searchText,
        limit: 100,
        docType: docType && docType.length > 0 ? docType : undefined,
      });

      return searchOptions.value?.items.map(parseDocItemToOption) || [];
    },
    [docType, docs],
  );

  const [initialSelectedOptions, setInitialSelectedOptions] = useState<AnyDocSelectOption[]>();

  /**
   * Effect used to fetch initial selected options for the select, to get the labels for options provided
   * in props.initiallySelectedValues.
   */
  useShallowEffect(() => {
    if (selectProps.variant === "search") {
      return;
    }

    const fetchInitialSelectedOptions = async () => {
      // Fetching options for initially selected values
      const initialOptions = await docs.retrieveDocs({ docIds: initiallySelectedDocIds || [] });

      if (!initialOptions.value) {
        setInitialSelectedOptions([]);
        return;
      }

      const parsedOptions = [
        ...(initialOptions.value
          ?.map((item) => (item ? parseDocItemToOption(item) : null))
          .filter(Boolean) as AnyDocSelectOption[]),
      ];

      setInitialSelectedOptions(parsedOptions);
    };

    fetchInitialSelectedOptions();
  }, [docType, docs, initiallySelectedDocIds]);

  return (
    <BaseSelectDialog
      {...selectProps}
      renderTrigger={
        noTrigger
          ? undefined
          : selectProps.renderTrigger
          ? selectProps.renderTrigger
          : (selectedOptions, onUnselectOption, _, onOpen) => (
              <SelectTrigger
                isDisabled={selectProps.isDisabled}
                onUnselectOption={onUnselectOption}
                selectedOptions={selectedOptions}
                label={selectProps.label}
                onClick={onOpen}
              />
            )
      }
      initialSelectedOptions={initialSelectedOptions}
      search={fetchSearchOptions}
    />
  );
};

const parseDocItemToOption = (item: AnyDoc): AnyDocSelectOption => {
  if (item.docType === "budget") {
    return {
      ...item,
      value: item.uuid as string,
      label: item.name,
    };
  }

  if (item.docType === "customer") {
    return {
      ...item,
      value: item.uuid as string,
      label: item.name,
    };
  }

  if (item.docType === "invoice-thread") {
    return {
      ...item,
      value: item.threadUuid as string,
      label: `${item.invoice.invoiceNumber} - ${item.title}`,
    };
  }

  if (item.docType === "pos-customer") {
    return {
      ...item,
      value: item.uuid as string,
      label: item.name || (item.uuid as string),
    };
  }

  if (item.docType === "user") {
    return {
      ...item,
      value: item.email,
      label: `${item.firstName} ${item.lastName}`,
      imageUrl: item.profilePictureUrl || null,
    };
  }

  if (item.docType === "user-group") {
    return {
      ...item,
      value: item.id,
      label: item.name,
    };
  }

  return {
    ...item,
    value: item.docId as string,
    label: item.name || (item.docId as string),
  };
};
