import { Chip } from "@mui/material";
import React from "react";

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
  disabled?: boolean;
  className?: string;
}

export const ToggleChip: React.FC<Props> = ({ value, onChange, label, disabled, className }) => {
  return (
    <Chip
      className={className}
      disabled={disabled}
      color={value ? "primary" : "default"}
      onClick={() => {
        if (disabled) return;
        onChange(!value);
      }}
      label={label}
    />
  );
};
