import { ValidationError } from "../errors";
import { serializerExtensions, SerializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

const uuidPattern = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

export const validateUuidFormat = (uuid: string) => uuidPattern.test(uuid);

export const uuid = (): UnsafeSerializer<string> & SerializerExtensions<string> => ({
  serialize: (value) => value,
  forceDeserialize: (raw) => {
    if (typeof raw !== "string") throw new ValidationError(`Uuid needs to be a string (received ${typeof raw})`);
    if (!validateUuidFormat(raw)) {
      throw new ValidationError("Incorrect uuid format");
    }
    return raw;
  },
  ...serializerExtensions(),
});
