import { Uuid, uuidSerializer } from "@megarax/common";
import { paginatedListSerializer, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

export interface SalespersonQueryFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
}
export interface SalespersonSimple {
  userId: number;
  regionUuid: Uuid;
}

const salespersonQueryFiltersSerializer = MSerializers.object<SalespersonQueryFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
});

const salespersonSimpleSerializer = MSerializers.object<SalespersonSimple>({
  userId: MSerializers.integer,
  regionUuid: uuidSerializer,
});

export default ResourceActionV2({
  name: "list",
  method: "get",
  path: "list",
  querySerializer: salespersonQueryFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(salespersonSimpleSerializer),
});
