import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

const myPurchaseSummarySerializer = Serializers.object<MyPurchaseSummary>({
  byListingUuid: Serializers.record(Serializers.integer.optional(), uuidSerializer),
});

export type MyPurchaseSummary = {
  byListingUuid: { [key: Uuid]: number | undefined };
};

export const myPurchaseSummaryQuery = HttpAction({
  path: "/myPurchasesSummary",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: myPurchaseSummarySerializer,
});
