import _ from "lodash";
import {
  QueryClient,
  useMutation,
  UseMutationOptions as RqUseMaMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { Failure, Ok, Result } from "@megaron/result";

import { ClientAction, ClientName, Clients, useServiceClient } from "./clients";

export type ManagerUseQueryReturn<TValue, TErr> = UseQueryResult<TValue, TErr> & {
  key: string[] | string;
};

type UseMutationOptions<TValue, TErr, TIn> = Omit<RqUseMaMutationOptions<TValue, TErr, TIn, unknown>, "onSuccess"> & {
  onSuccess?: (data: TValue, variables: TIn, ctx: { queryClient: QueryClient }) => void;
};

type ManagerActionMethods<TAction> = TAction extends ClientAction<infer TIn, infer TValue, infer TErr>
  ? () => {
      useQuery: (input: TIn, options?: UseQueryOptions) => ManagerUseQueryReturn<TValue, TErr>;
      useMutation: (opts?: UseMutationOptions<TValue, TErr, TIn>) => UseMutationResult<TValue, TErr, TIn, unknown>;
    }
  : never;

type ClientManager<T> = {
  [name in keyof T]: ManagerActionMethods<T[name]>;
};

export const useClientManager = <TName extends ClientName>(serviceName: TName): ClientManager<Clients[TName]> => {
  const queryClient = useQueryClient();
  const client = useServiceClient(serviceName);

  const actionToMethods =
    <T extends ClientAction<unknown[], unknown, unknown>>(action: T, actionName: string) =>
    () => ({
      useQuery: (input: any, options?: UseQueryOptions) => {
        const defaultKey = [serviceName, actionName, JSON.stringify(input)];

        const queryFn = async () => {
          const result = await action(input);
          if (result.isFailure) return Promise.reject(result.error);
          return result.value;
        };

        const queryKey = options?.queryKey ?? defaultKey;

        const query = useQuery({
          queryKey,
          retry: (count, error) => error === "ConnectionError",
          ...options,
          queryFn,
        });

        return { ...query, key: queryKey };
      },
      useMutation: (options?: UseMutationOptions<any, any, any>) => {
        const wrappedMutationFn = (input: any) =>
          action(input).then((r) => {
            if (r.isFailure) return Promise.reject(r.error);
            return r.value;
          });

        const wrappedOnSuccess = (data: any, variables: any) => {
          if (options?.onSuccess) {
            options.onSuccess(data, variables, { queryClient });
          }
        };

        return useMutation({ ...options, mutationFn: wrappedMutationFn, onSuccess: wrappedOnSuccess });
      },
    });

  return _.mapValues(client, actionToMethods) as any;
};

export const mutationToResult = <TValue, TErr>(
  mut: UseMutationResult<TValue, TErr, any, any>,
): Result<TValue, TErr> | undefined => (mut?.data ? Ok(mut.data) : mut?.error ? Failure(mut.error) : undefined) as any;

export const queryToResult = <TValue, TErr>(query: UseQueryResult<TValue, TErr>): Result<TValue, TErr> | undefined =>
  (query?.data ? Ok<TValue>(query.data) : query?.error ? Failure<TErr>(query.error) : undefined) as any;
