import { CustomerStatus } from "@megarax/crm-contracts";
import { Star, Warning } from "@mui/icons-material";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  status: CustomerStatus;
}

export const StatusText: React.FunctionComponent<Props> = ({ status }) => {
  const classes = useStyles();

  if (status === "vip")
    return (
      <Typography
        variant="overline"
        fontWeight={600}
        fontSize="1.1rem"
        lineHeight="1.1rem"
        color="primary"
        display="flex"
        flexDirection="row"
      >
        <Star fontSize="inherit" className={classes.icon} />
        VIP
      </Typography>
    );

  if (status === "inactive")
    return (
      <Typography
        variant="overline"
        fontWeight={600}
        fontSize="1.1rem"
        lineHeight="1.1rem"
        color="error"
        display="flex"
        flexDirection="row"
      >
        <Warning fontSize="inherit" className={classes.icon} /> Klient nieaktywny
      </Typography>
    );
  return null;
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));
