import makeStyles from "@mui/styles/makeStyles";
import fileDownload from "js-file-download";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-query";

import { dateStringsInRange, toDateString } from "@megarax/common";
import { workDayResource } from "@megarax/crm-contracts";
import { useProfilesDictionary } from "@megarax/iam-webapp";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

import { DateRange, MilageReportDialog } from "./MilageReportDialog";
import { reportToCsv } from "./reportToCsv";

interface Props {
  onClose: () => void;
}

export const MilageReportDialogContainer: React.FunctionComponent<Props> = ({ onClose }) => {
  const classes = useStyles();

  const [dateRange, setDateRange] = React.useState<DateRange>([null, null]);
  const workDayProvider = useResourceProviderV2(workDayResource);

  const errorHandler = useSnackbarErrorHandler({ ...commonErrorsMap });

  const isDateRangeSelected = dateRange[0] !== null && dateRange[1] !== null;

  const { data: report } = useQuery(
    ["milageReport", ...dateRange],
    () =>
      workDayProvider
        .milageReport({
          since: toDateString(dateRange[0]!),
          until: toDateString(dateRange[1]!),
        })
        .mapError(errorHandler)
        .then((r) => r.assertOk().value),
    {
      enabled: isDateRangeSelected,
    },
  );

  const profilesDictionary = useProfilesDictionary(report?.users.map((p) => ({ id: p.userId })));

  const download =
    report &&
    profilesDictionary &&
    (() => {
      if (dateRange[0] === null || dateRange[1] === null) return;
      const csv = reportToCsv(
        report,
        _.mapValues(profilesDictionary, (v) => v?.fullName),
        dateStringsInRange(dateRange[0], dateRange[1]),
      );
      fileDownload(csv, `przejechane_kilometry.csv`);
    });

  return (
    <MilageReportDialog
      onClose={onClose}
      dateRange={dateRange}
      onDateRangeChange={setDateRange}
      onDownload={download}
    />
  );
};

const useStyles = makeStyles((theme) => ({}));
