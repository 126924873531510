import { Serializers } from "@megarax/serializers";
import { Navigate, useLocation, useMatch } from "react-router-dom-v5-compat";

export const VisitsRedirect = () => {
  const { pathname } = useLocation();

  const visitsPathMatch = useMatch({ end: true, path: "/crm/pos/handlowcy/:userId/wizyty" });
  const dayPathMatch = useMatch({ end: true, path: "/crm/pos/handlowcy/:userId/wizyty/dzien" });
  const weekPathMatch = useMatch({ end: true, path: "/crm/pos/handlowcy/:userId/wizyty/tydzien" });

  if (visitsPathMatch) {
    return <Navigate to={`${pathname}/tydzien`} />;
  }

  if (dayPathMatch) {
    return <Navigate to={`${pathname}/${Serializers.dateOnly.serialize(new Date())}`} />;
  }

  if (weekPathMatch) {
    return <Navigate to={`${pathname}/${Serializers.dateOnly.serialize(new Date())}`} />;
  }

  return null;
};
