import React from "react";
import { generatePath, matchPath, useHistory, useRouteMatch } from "react-router-dom";

import { Uuid } from "@megarax/common";

export const useVisitNavigation = () => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const safeToGoBack = React.useRef(false);

  const openVisit = (uuid: Uuid) => {
    const path = generatePath(`${url}/wizyta/:uuid`, { uuid });
    history.push(path);
    safeToGoBack.current = true;
  };

  const closeVisit = () => {
    if (safeToGoBack.current) {
      history.goBack();
      safeToGoBack.current = false;
    } else {
      history.replace(url);
    }
  };

  return {
    openVisit,
    closeVisit,
  };
};

export const useOnVisitClose = (onClose: () => void) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const isOpen =
    matchPath(history.location.pathname, {
      path: `${path}/wizyta/:uuid`,
    }) !== null;
  const wasOpen = React.useRef(isOpen);

  React.useEffect(() => {
    if (!isOpen && wasOpen.current) onClose();

    wasOpen.current = isOpen;
  }, [history.location.pathname]);
};
