import { Uuid } from "@megarax/common";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { v4 } from "uuid";

import { useChainsResource, useCustomersResource, useRegionsResource } from "@megaron/megarax-v2-resource-hooks";

import { AddCustomerDialog } from "./AddCustomerDialog";

interface Props {}

export const AddCustomerDialogContainer: React.FC<Props> = () => {
  const navigate = useNavigate();

  const [uuid] = useState<Uuid>(v4() as Uuid);

  const { getRegionsItems } = useRegionsResource();
  const { registerCustomer, designateVisitLocation, assignToChain, getNearbyCustomers } = useCustomersResource();
  const { getChainsArray } = useChainsResource();

  return (
    <AddCustomerDialog
      navigateToDetails={() => navigate(`/crm/pos/klienci/${uuid}`)}
      registerCustomerStep={{
        getRegions: (searchText: string) => getRegionsItems({ searchText }),
        registerCustomer: (input) => registerCustomer({ onSuccess: () => {} })(uuid, input),
      }}
      setVisitLocationStep={{
        getNearbyCustomers,
        designateVisitLocation: (input) => designateVisitLocation({ onSuccess: () => {} })(uuid, input),
      }}
      chainStep={{
        getChains: getChainsArray,
        assignToChain: (input) => assignToChain({ onSuccess: () => {} })(uuid, input.chainUuid),
      }}
    />
  );
};
