import { PackSizeDefaultsDto, SetSizesInput } from "@megarax/crm-contracts";
import { AsyncButton, DefinitionItem, DefinitionList, IntegerInput } from "@megarax/ui-components";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

interface Props {
  packSize: PackSizeDefaultsDto | null;
  setPackSizes: (input: SetSizesInput) => Promise<unknown>;
  closeDialog: () => void;
}

interface Sizes {
  pallet: number | null;
  layer: number | null;
  box: number | null;
}

const emptySizes = {
  pallet: null,
  layer: null,
  box: null,
};

export const PackSizeDialog: React.FC<Props> = ({ packSize, setPackSizes, closeDialog }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const initialPackSize = packSize ?? emptySizes;

  const [sizes, setSizes] = useState<Sizes>(initialPackSize);

  const setSize = (type: "pallet" | "layer" | "box") => (value: number) => setSizes({ ...sizes, [type]: value });
  const toggleSize = (type: "pallet" | "layer" | "box") => () => {
    const previousPackSize = packSize ? packSize[type] ?? 0 : 0;
    const sizeToSet = sizes[type] === null ? previousPackSize : null;
    setSizes({ ...sizes, [type]: sizeToSet });
  };

  return (
    <Dialog open={true} onClose={closeDialog} classes={{ paper: classes.paper }} fullScreen={isMobile}>
      <DialogTitle>{"Domyślne wielkości"}</DialogTitle>
      <DialogContent className={classes.container}>
        <div className={classes.container}>
          <Typography variant="subtitle1">Aktualne wartości:</Typography>
          <DefinitionList>
            <DefinitionItem term="Paleta">{initialPackSize.pallet ?? "Brak"}</DefinitionItem>
            <DefinitionItem term="Warstwa">{initialPackSize.layer ?? "Brak"}</DefinitionItem>
            <DefinitionItem term="Karton">{initialPackSize.box ?? "Brak"}</DefinitionItem>
          </DefinitionList>
        </div>
        <Typography variant="subtitle1">Edycja:</Typography>
        <div className={classes.row}>
          <Checkbox checked={sizes.pallet !== null} className={classes.checkbox} onClick={toggleSize("pallet")} />
          <IntegerInput
            disabled={sizes.pallet === null}
            label="Ilość na palecie"
            variant="outlined"
            value={sizes.pallet ?? 0}
            onChange={setSize("pallet")}
          />
        </div>
        <div className={classes.row}>
          <Checkbox className={classes.checkbox} checked={sizes.layer !== null} onClick={toggleSize("layer")} />
          <IntegerInput
            disabled={sizes.layer === null}
            label="Ilość w warstwie"
            variant="outlined"
            value={sizes.layer ?? 0}
            onChange={setSize("layer")}
          />
        </div>
        <div className={classes.row}>
          <Checkbox className={classes.checkbox} checked={sizes.box !== null} onClick={toggleSize("box")} />
          <IntegerInput
            disabled={sizes.box === null}
            label="Ilość w kartonie"
            variant="outlined"
            value={sizes.box ?? 0}
            onChange={setSize("box")}
          />
        </div>
        <DialogActions>
          <Button onClick={closeDialog}>Anuluj</Button>
          <AsyncButton variant="text" color="primary" asyncAction={() => setPackSizes(sizes).then(closeDialog)}>
            Zapisz
          </AsyncButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    checkbox: {
      padding: theme.spacing(1),
      width: "25px",
      height: "25px",
      marginRight: theme.spacing(1),
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    paper: {
      width: "400px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    container: {
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      "& > div": {
        margin: theme.spacing(1, 0),
      },
    },
  };
});
