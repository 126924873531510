import { Page } from "@megaron/dash-page";

import { ServiceUrlsSettings } from "./ServiceUrlsSettings";
import { TestUserSettings } from "./TestUserSettings";

export const EnvironmentPage = () => {
  return (
    <Page css={{ maxWidth: "756px", marginTop: "1rem" }}>
      <TestUserSettings />
      <ServiceUrlsSettings />
    </Page>
  );
};
