import { CustomerSimple, CustomerSortField, tagResource } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { useIndependentAsyncLoad, useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad, useDepPagination } from "@megarax/react-utils";
import { SortFilter } from "@megarax/rest-resource";
import {
  BreadcrumbMarker,
  Dialog,
  LoadingBar,
  NewRouterDialog,
  PageTitleManager,
  useDialog,
  useNewRouterDialog,
} from "@megarax/ui-components";
import { LinearProgress } from "@mui/material";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { useCustomersResource, useRegionsResource } from "@megaron/megarax-v2-resource-hooks";
import { Result } from "@megaron/result";

import { AddCustomerDialogContainer } from "./AddCustomerDialog";
import { CustomersHome } from "./CustomerHome";
import { getInitialValuesFromQs, QueryFilters } from "./customerHomeFilters";

interface Props {}

const perPage = 20;

const orderMap = {
  ASC: "DESC",
  DESC: undefined,
};

export const pricingRulesMap = {
  true: true,
  false: false,
  both: undefined,
};

export const CustomersHomeContainer: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const tagsResource = useResourceProviderV2(tagResource);
  const getTags = (searchText: string) =>
    tagsResource
      .list({ limit: 1000, searchText })
      .map((result) => result.items.map((t) => t.label))
      .then((r) => r.assertOk().value);

  const { listCustomers } = useCustomersResource();

  const { getRegionsItems, getRegionsDictionary } = useRegionsResource();

  const newCustomerDialog = useNewRouterDialog("newCustomer");

  const { page: initialPage, ...queryFiltersFromQs } = getInitialValuesFromQs(location.search.substring(1));

  const [queryFilters, setQueryFilters] = useState<QueryFilters>(queryFiltersFromQs);

  const setSortFilter = (filter: SortFilter<CustomerSortField> | undefined) =>
    setQueryFilters({ ...queryFilters, sortBy: filter });

  const [customersQuery, setCustomersQuery] = useState<CustomersQueryResult>();

  const onSortHeaderClick = (field: CustomerSortField) => () => {
    const sortBy = queryFilters.sortBy;

    if (!sortBy || sortBy.field !== field) return setSortFilter({ field, order: "ASC" });
    const order = orderMap[sortBy.order as keyof typeof orderMap] as "DESC" | undefined;
    if (!order) return setSortFilter(undefined);
    return setSortFilter({
      field,
      order,
    });
  };

  const regionsDictionary = useQuery(["regions-dictionary"], getRegionsDictionary);

  const { paginationProps, page } = useDepPagination(
    {
      perPage,
      allCount: customersQuery?.value?.count,
      initialPage: initialPage,
    },
    [queryFilters.searchText, queryFilters.regionUuid, queryFilters.hasPricingRules, queryFilters.tags],
  );

  const { loading: customersLoading } = useIndependentAsyncLoad(
    () =>
      listCustomers({
        limit: perPage,
        offset: page * perPage,
        searchText: queryFilters.searchText,
        sortBy: queryFilters.sortBy ? [queryFilters.sortBy] : undefined,
        regionUuid: queryFilters.regionUuid.length > 0 ? queryFilters.regionUuid : undefined,
        hasPricingRules: pricingRulesMap[queryFilters.hasPricingRules],
        tags: queryFilters.tags,
        status: queryFilters.status,
      }),
    setCustomersQuery,
    [queryFilters, page],
  );

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: qs.stringify({
          ...qs.parse(location.search.substring(1)),
          ...queryFilters,
          page,
        }),
      },
      { replace: true },
    );
  }, [queryFilters, page]);

  if (customersQuery === undefined || regionsDictionary.data === undefined) return <LinearProgress />;
  if (customersQuery.isFailure) return null;

  return (
    <>
      <PageTitleManager title="Klienci" />
      <BreadcrumbMarker title="Klienci" id="customers" />
      <LoadingBar loading={customersLoading} />
      <NewRouterDialog name={newCustomerDialog.name} fullWidth maxWidth="xs" onClose={newCustomerDialog.close}>
        <AddCustomerDialogContainer />
      </NewRouterDialog>

      <CustomersHome
        customers={customersQuery.value}
        pagination={paginationProps}
        navigation={{
          navigateToDetails: (uuid) => navigate(`/crm/pos/klienci/${uuid}`),
          openAddCustomerDialog: newCustomerDialog.open,
        }}
        queryFilters={{
          queryFilters,
          getRegions: (searchText: string) => getRegionsItems({ searchText, limit: 30 }),
          regionsDictionary: regionsDictionary.data,
          getTags: getTags,
          setQueryFilters,
          onSortHeaderClick,
        }}
      />
    </>
  );
};

type CustomersQueryResult = Result<
  {
    count: number;
    items: CustomerSimple[];
  },
  HttpRequestError
>;
