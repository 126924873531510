import { Table, TableBody } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import React from "react";

type ClassKey = "root";

const DefinitionListUnstyled: React.FC<WithStyles<ClassKey> & { children?: React.ReactNode }> = ({
  children,
  classes,
}) => (
  <Table>
    <TableBody className={classes.root}>{children}</TableBody>
  </Table>
);

export const DefinitionList = withStyles((theme) => ({
  root: {
    "& tr:first-child": {
      borderTopColor: theme.palette.divider,
      borderTopStyle: "solid",
      borderTopWidth: "1px",
    },
  },
}))(DefinitionListUnstyled);
