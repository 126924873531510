import { Download } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export type DateRange = [since: Date | null, until: Date | null];

interface Props {
  onClose: () => void;
  dateRange: DateRange;
  onDateRangeChange: (range: DateRange) => void;

  onDownload: (() => void) | undefined;
}

export const MilageReportDialog: React.FunctionComponent<Props> = ({
  onClose,
  dateRange,
  onDateRangeChange,
  onDownload,
}) => {
  const classes = useStyles();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Przejechane kilometry</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <DatePicker
            label="Od"
            className={classes.picker}
            value={dateRange[0]}
            onChange={(date) => onDateRangeChange([date, dateRange[1]])}
          />
        </div>
        <div>
          <DatePicker
            label="Do"
            className={classes.picker}
            value={dateRange[1]}
            onChange={(date) => onDateRangeChange([dateRange[0], date])}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.downloadButton}
          variant="contained"
          onClick={onDownload}
          disabled={onDownload === undefined}
        >
          <Download fontSize="inherit" sx={{ marginRight: 1 }} />
          CSV
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
  },
  downloadButton: {},
  header: {
    flex: 1,
  },
  picker: {
    marginBottom: theme.spacing(2),
  },
}));
