import { Uuid } from "@megarax/common";
import { LoadingBar } from "@megarax/ui-components";
import { Box, Drawer, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useMatch } from "react-router-dom-v5-compat";

import { VisitViewContainer } from "../../VisitView";

interface Props {
  dateSelect: React.ReactNode;
  isLoading: boolean;
  calendar: React.ReactNode;
  closeVisit: () => void;
}

export const ViewLayout: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const visitDrawerMatchPath = useMatch({
    end: false,
    path: `/crm/pos/handlowcy/:userId/wizyty/tydzien/:date/wizyta/:uuid`,
  });

  return (
    <div>
      <Paper className={classes.toolbarPaper}>
        <div className={classes.toolbar}>{props.dateSelect}</div>
        <LoadingBar loading={props.isLoading} />
      </Paper>
      {props.calendar}
      {visitDrawerMatchPath && (
        <Drawer anchor={isMobile ? "top" : "right"} open={true} onClose={props.closeVisit}>
          <Box maxWidth={isMobile ? "100%" : 450} minWidth={320} sx={{ overflowX: "hidden" }}>
            <VisitViewContainer uuid={visitDrawerMatchPath.params.uuid as Uuid} />
          </Box>
        </Drawer>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  calendarSection: {
    display: "flex",
    flexDirection: "row",
  },
  view: {
    flexGrow: "1",
  },
  toolbarPaper: {
    zIndex: 10,
    position: "sticky",
    top: 0,
    borderRadius: 0,
  },
  toolbar: {
    display: "flex",
    alignContent: "space-around",
    "& > *": {
      margin: "0 auto",
    },
  },
}));
