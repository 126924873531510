import { useTheme } from "@emotion/react";

export const NoSignsTile = () => {
  const theme = useTheme();

  return (
    <div
      css={{
        height: "68px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.smallBorderRadius,
        background: "rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        padding: "10px 12px",
        color: theme.colors.secondaryText,
      }}
    >
      <span>Brak podpisów</span>
    </div>
  );
};
