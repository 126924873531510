import { LinearProgress } from "@mui/material";
import React from "react";

import { Gtin } from "@megarax/crm-contracts";
import { PriceListDetail, priceListResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

import { TradeItemDictionary } from "../PriceListDetailsContainer";
import { PriceListCurrentView } from "./PriceListCurrentView";

interface ContainerProps {
  uuid: string;
  priceListDetail: PriceListDetail;
  tradeItems: TradeItemDictionary | null;
}

const snackbarErrors = commonErrorsMap;

export const PriceListCurrentContainer: React.FC<ContainerProps> = ({ uuid, priceListDetail, tradeItems }) => {
  const priceListProvider = useResourceProviderV2(priceListResource);
  const priceListDetailsProvider = priceListProvider.byUuid(uuid);

  const handleError = useSnackbarErrorHandler(snackbarErrors);

  const permissions = {
    seeUpcoming: true,
    seeCurrent: true,
  };

  const { value: upcomingChangesQuery } = useAsyncLoad(
    () => priceListDetailsProvider.upcomingChanges().mapError(handleError),
    [],
  );

  if (upcomingChangesQuery === undefined) return <LinearProgress />;
  if (upcomingChangesQuery.isFailure) return null;

  const getTradeItemDetails = (gtin: Gtin) => {
    if (!tradeItems) return undefined;
    return tradeItems[gtin];
  };

  return (
    <PriceListCurrentView
      getTradeItemDetails={getTradeItemDetails}
      upcomingChanges={upcomingChangesQuery.value}
      currentPrices={priceListDetail.currentPrices}
      permissions={permissions}
      currency={priceListDetail.currency}
      tradeItems={tradeItems}
    />
  );
};
