import { useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useParams } from "react-router-dom-v5-compat";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { DraftAttachmentDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { useScrollToTop } from "../../useScrollToTop";
import { FileView } from "../thread/attachment/FileView";
import { IssuerThreads } from "../thread/IssuerThreads";
import { SimilarThreadAlert } from "../thread/SimilarThreadAlert";
import { DeleteDraftDialog } from "./DeleteDraftDialog";
import { DraftAttachmentCard } from "./DraftAttachmentCard";
import { DraftForm } from "./DraftForm";

export const DraftPage = () => {
  const { isMobile } = useDeviceType();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const params = useParams();
  const draftUuid = params.uuid;

  useScrollToTop();

  const [selectedAttachment, setSelectedAttachment] = useState<DraftAttachmentDto | null>(null);

  const handleAttachmentClick = (attachment: DraftAttachmentDto) => {
    setSelectedAttachment(attachment);
  };

  const handleClosePreview = () => {
    setSelectedAttachment(null);
  };

  const draftQuery = useClientManager("invoices")
    .draftQuery()
    .useQuery(
      { uuid: draftUuid ? Uuid(draftUuid) : ("" as Uuid) },
      {
        enabled: !!draftUuid,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
      },
    );

  const draftQueryKey = draftQuery.key;

  if (!draftUuid) {
    // todo: show error
    return null;
  }

  return (
    <>
      <HeaderBar
        header={"Wesja robocza"}
        actions={
          <Button
            icon={<MdDeleteOutline />}
            css={{ padding: "0.25rem", border: "none", background: "transparent" }}
            onClick={() => setIsDialogOpen(true)}
          />
        }
      />
      <Page
        css={{
          display: "flex",
          flexDirection: isMobile ? "column-reverse" : "row",
          gap: "1rem",
          marginBottom: "2rem",
          marginTop: isMobile ? "4.5rem" : "5.5rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
        }}
      >
        <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <QuerySkeleton query={draftQuery} height={"0px"}>
            {(draft) => <SimilarThreadAlert thread={draft} height="0px" css={{ marginBottom: "1rem" }} />}
          </QuerySkeleton>
          <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
            {selectedAttachment ? (
              <FileView
                url={selectedAttachment.url}
                fileType={selectedAttachment.fileType}
                onClose={handleClosePreview}
              />
            ) : (
              <>
                <QuerySkeleton query={draftQuery} height={"680px"}>
                  {(draft) => (
                    <DraftForm
                      draft={draft}
                      draftQueryKey={draftQueryKey}
                      isFetching={draftQuery.isFetching || !draftQuery.data}
                    />
                  )}
                </QuerySkeleton>

                {isMobile && (
                  <QuerySkeleton query={draftQuery} height={"62px"}>
                    {(draft) => (
                      <div css={{ marginTop: "-1rem" }}>
                        <IssuerThreads
                          issuer={draft.invoice.issuer}
                          threadUuid={draft.uuid}
                          hideActions
                          areLinksExternal
                        />
                      </div>
                    )}
                  </QuerySkeleton>
                )}
              </>
            )}
          </div>
        </div>
        <div
          css={{
            minWidth: isMobile ? "auto" : "19rem",
            margin: isMobile ? "0 -1rem" : 0,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <QuerySkeleton query={draftQuery} height={"183px"}>
            {(draft) => (
              <div
                css={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "column",
                  gap: "16px",
                  overflowX: "auto",
                  padding: isMobile ? "0 1rem" : 0,
                }}
              >
                {draft.attachments.map((attachment) => (
                  <DraftAttachmentCard
                    attachment={attachment}
                    key={attachment.uuid}
                    onAttachmentClick={() => handleAttachmentClick(attachment)}
                  />
                ))}
              </div>
            )}
          </QuerySkeleton>

          {!isMobile && (
            <QuerySkeleton query={draftQuery} height={"62px"}>
              {(draft) => (
                <div css={{ marginTop: "-1rem" }}>
                  <IssuerThreads issuer={draft.invoice.issuer} threadUuid={draft.uuid} hideActions areLinksExternal />
                </div>
              )}
            </QuerySkeleton>
          )}
        </div>
        {isDialogOpen && <DeleteDraftDialog draftUuid={draftUuid} onClose={() => setIsDialogOpen(false)} />}
      </Page>
    </>
  );
};
