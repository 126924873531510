import { CommonSearchProps, DocsSelectDialog } from "@megaron/dash-select";
import { DocType } from "@megaron/docs-contracts";

import { FlagFilter } from "./FlagFilter";

type SelectProps = CommonSearchProps & {
  docType: DocType[];
  initiallySelectedDocIds: string[] | undefined;
};

export const SelectFilterDocs = (props: SelectProps) => {
  return (
    <DocsSelectDialog
      {...props}
      renderTrigger={(selectedOptions, onUnselectOption, onUnselectAll, onOpen) => {
        const isSelected = selectedOptions.length > 0;

        const chipLabel = isSelected
          ? selectedOptions.length === 1
            ? `${props.label}: ${selectedOptions[0].label}`
            : `${props.label} (${selectedOptions.length})`
          : props.label;

        return <FlagFilter isActive={isSelected} label={chipLabel} onClick={onOpen} onDelete={onUnselectAll} />;
      }}
    />
  );
};
