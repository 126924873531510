import { faArrowRight, faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Uuid } from "@megarax/common";
import { CustomerReference, customerResource, PendingPayerProposalTaskIdentifier } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { commonErrorsMap, ResultSkeletonLoader, useSnackbarErrorHandler } from "@megarax/ui-components";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom-v5-compat";

import { BaseTaskGroupCard } from "./BaseTaskGroupCard";

interface Props {
  identifiers: PendingPayerProposalTaskIdentifier[];
  time: Date;
}

type ProposalStatus = {
  customer: CustomerReference;
  count: number;
};

const identifiersToStatuses = (identifiers: PendingPayerProposalTaskIdentifier[]): ProposalStatus[] =>
  _(identifiers)
    .groupBy((t) => t.customerUuid)
    .mapValues((tasks) => tasks.length)
    .entries()
    .map<ProposalStatus>(([uuid, count]) => ({
      customer: { uuid: uuid },
      count,
    }))
    .value();

export const PendingPayerProposalTasksCard: React.FunctionComponent<Props> = ({ identifiers, time }) => {
  const statuses = identifiersToStatuses(identifiers);

  return (
    <BaseTaskGroupCard
      icon={<FontAwesomeIcon fixedWidth icon={faHandHoldingUsd} />}
      time={time}
      title="Propozycje zmian warunków handlowych"
    >
      <List dense>
        {statuses.map((status) => (
          <PayerProposalStatusItem
            pendingProposalCount={status.count}
            uuid={status.customer.uuid}
            key={status.customer.uuid}
          />
        ))}
      </List>
    </BaseTaskGroupCard>
  );
};

const PayerProposalStatusItem: React.FC<{
  pendingProposalCount: number;
  uuid: string;
}> = ({ uuid, pendingProposalCount }) => {
  const customerProvider = useResourceProviderV2(customerResource);

  const handleError = useSnackbarErrorHandler({ ...commonErrorsMap });

  const { value: customerResult } = useAsyncLoad(
    () =>
      customerProvider
        .byUuid(uuid as Uuid)
        .retrieve()
        .map((res) => res.name)
        .mapError(handleError),
    [],
  );

  return (
    <ListItem key={uuid} button component={Link} to={`/crm/pos/klienci/${uuid}/warunki_handlowe`}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faArrowRight} fixedWidth />
      </ListItemIcon>
      <ListItemText
        primary={
          <ResultSkeletonLoader
            result={customerResult}
            allowErrors={true}
            component={(res) => <Typography variant="inherit">{res.isOk ? res.value : uuid}</Typography>}
          />
        }
        secondary={`Oczekujące propozycje (${pendingProposalCount})`}
      />
    </ListItem>
  );
};
