import { useTheme } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { MdBlock, MdCheckCircleOutline, MdKeyboardArrowDown, MdOutlineInsertDriveFile } from "react-icons/md";
import { useQueryClient } from "react-query";

import { Dialog } from "@megaron/dash-dialog";
import { Button, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { useToast } from "@megaron/dash-toast";
import { ThreadDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  queryKey: string | string[];
  thread: ThreadDto;
};

export const ApprovalSelect: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { isMobile } = useDeviceType();
  const queryClient = useQueryClient();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [reason, setReason] = useState("");

  const dropdownRef = useRef<HTMLDivElement>(null);
  const toast = useToast();

  const approveThreadMutation = useClientManager("invoices").approveThread().useMutation();
  const revokeApprovalMutation = useClientManager("invoices").revokeApproval().useMutation();
  const closeThreadMutation = useClientManager("invoices").closeThread().useMutation();
  const reopenThreadMutation = useClientManager("invoices").reopenThread().useMutation();

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleApproval = () => {
    if (props.thread.status === "approved") {
      revokeApprovalMutation.mutate(
        { threadUuid: props.thread.uuid },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(props.queryKey);
          },
        },
      );
    } else {
      approveThreadMutation.mutate(
        { threadUuid: props.thread.uuid },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(props.queryKey);
          },
          onError: (e) => {
            switch (e) {
              case "InvoiceNotReceived":
                toast.error("Error", "Oryginał faktury nie dotarł do firmy");
                break;
              default:
                toast.error("Error", "Coś poszło nie tak");
            }
          },
        },
      );
    }
  };

  const handleCloseThread = () => {
    closeThreadMutation.mutate(
      {
        reason: reason,
        threadUuid: props.thread.uuid,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(props.queryKey);
        },
      },
    );
  };

  const handleReopenThread = () => {
    reopenThreadMutation.mutate(
      { threadUuid: props.thread.uuid },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(props.queryKey);
        },
      },
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <>
      <div css={{ position: "relative" }} ref={dropdownRef}>
        <div
          css={{
            borderRadius: theme.smallBorderRadius,
            display: "flex",
            padding: "10px 12px",
            justifyContent: "space-between",
            border: `1px solid ${theme.colors.border}`,
            alignItems: "center",
            fontSize: "14px",
            fontWeight: 700,
            cursor: "pointer",
          }}
          onClick={handleToggleDropdown}
        >
          {props.thread.status === "approved" ? (
            <span css={{ color: theme.colors.success, display: "flex", gap: "8px", alignItems: "center" }}>
              <MdCheckCircleOutline size={"20px"} />
              Zatwierdzony
            </span>
          ) : props.thread.status === "closed" ? (
            <span css={{ color: theme.colors.danger, display: "flex", gap: "8px", alignItems: "center" }}>
              <MdBlock size={"20px"} />
              Odrzucony
            </span>
          ) : (
            <span css={{ color: theme.colors.primary, display: "flex", gap: "8px", alignItems: "center" }}>
              <MdOutlineInsertDriveFile />
              Otwarty
            </span>
          )}

          <MdKeyboardArrowDown size={"18px"} color={theme.colors.secondaryText} />
        </div>
        {isDropdownOpen && (
          <div
            css={{
              position: "absolute",
              top: "100%",
              left: 0,
              width: "100%",
              borderRadius: theme.smallBorderRadius,
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
              padding: "0.25rem 0",
              backgroundColor: "white",
              zIndex: 1,
              color: theme.colors.primary,
            }}
          >
            {props.thread.status === "open" && (
              <>
                <div
                  css={{
                    padding: "0.5rem 1rem",
                    cursor: "pointer",
                    ":hover": {
                      fontWeight: 700,
                    },
                  }}
                  onClick={handleApproval}
                >
                  Zatwierdź
                </div>
                <div
                  css={{
                    padding: "0.5rem 1rem",
                    cursor: "pointer",
                    ":hover": {
                      fontWeight: 700,
                    },
                  }}
                  onClick={() => setIsDialogOpen(true)}
                >
                  Zamknij
                </div>
              </>
            )}
            {props.thread.status === "approved" && (
              <div
                css={{
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  ":hover": {
                    fontWeight: 700,
                  },
                }}
                onClick={handleApproval}
              >
                Cofnij zatwierdzenie
              </div>
            )}
            {props.thread.status === "closed" && (
              <div
                css={{
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  ":hover": {
                    fontWeight: 700,
                  },
                }}
                onClick={handleReopenThread}
              >
                Otwórz ponownie
              </div>
            )}
          </div>
        )}
      </div>
      {isDialogOpen && (
        <Dialog
          onClose={() => setIsDialogOpen(false)}
          placement={isMobile ? "top" : "center"}
          header={"Edytuj podpis"}
          css={{ width: "400px" }}
        >
          <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <TextField label="Powód zamkniecia" autoFocus multiline value={reason} onChange={setReason} />
            <Button onClick={handleCloseThread} css={{ width: "auto", alignSelf: "flex-end" }}>
              Zamknij wątek
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};
