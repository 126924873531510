import { Pagination as MuiPagination, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ChangeEvent, useEffect } from "react";

interface Props {
  count: number;
  allCount: number;
  onChange: (e: ChangeEvent<unknown>, page: number) => void;
  page: number;
}

export const Pagination: React.FC<Props> = ({ count, allCount, onChange, page }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const allCountText = allCount === 10000 ? ">10000" : allCount;

  return (
    <div>
      <Typography variant="caption" className={classes.info}>
        {`Wszystkich: ${allCountText}`}
      </Typography>
      <MuiPagination
        size={isMobile ? "small" : "medium"}
        classes={{ ul: classes.pagination }}
        count={count}
        page={page}
        onChange={onChange}
        showFirstButton
        showLastButton
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    info: {
      width: "200px",
      margin: theme.spacing(1),
    },
    pagination: {
      margin: "5px auto 1rem 0",
      display: "flex",
      justifyContent: "center",
      flexWrap: "nowrap",
    },
  };
});
