import { HttpAction, HttpService } from "@megaron/http-service";

import { accountActions } from "./account";
import { authActions } from "./auth";
import { groupActions } from "./group";

export const iamHttpService = HttpService({
  ...authActions,
  ...accountActions,
  ...groupActions,
});
