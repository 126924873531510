import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const biCodeActivitySerializer = Serializers.object({
  uuid: uuidSerializer,
  time: Serializers.datetime,
  codeId: Serializers.string,
  sessionUuid: uuidSerializer,
  userUuid: uuidSerializer.nullable(),
  ipAddress: Serializers.string,
  action: Serializers.string,
  result: Serializers.string,
});

export type BiCodeActivity = SerializerValue<typeof biCodeActivitySerializer>;

const biCodeActivityQuery = HttpAction({
  path: "/bi/codeActivity",
  valueSerializer: Serializers.array(biCodeActivitySerializer),
  requiresAuth: true,
});

export const biCodeRedemptionSerializer = Serializers.object({
  time: Serializers.datetime,
  codeId: Serializers.string,
  accountUuid: uuidSerializer,
  pointValue: Serializers.decimal,
  productId: Serializers.string,
  massKg: Serializers.decimal,
  transactionUuid: uuidSerializer,
});

export type BiCodeRedemption = SerializerValue<typeof biCodeRedemptionSerializer>;

const biCodeRedemptionsQuery = HttpAction({
  path: "/bi/codeRedemptions",
  valueSerializer: Serializers.array(biCodeRedemptionSerializer),
  requiresAuth: true,
});

export const biRewardPurchaseSerializer = Serializers.object({
  time: Serializers.datetime,
  accountUuid: uuidSerializer,
  price: Serializers.decimal,
  rewardId: Serializers.string,
  listingUuid: uuidSerializer,
  purchaseUuid: uuidSerializer,
});

const biRewardPurchasesQuery = HttpAction({
  path: "/bi/rewardPurchases",
  valueSerializer: Serializers.array(biRewardPurchaseSerializer),
  requiresAuth: true,
});

const biAccountsQuery = {};

export const biActions = { biCodeActivityQuery, biCodeRedemptionsQuery, biRewardPurchasesQuery };
