import _ from "lodash";

import { ValidationError } from "../errors";
import { serializerExtensions, SerializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export class ObjectSerializationError extends ValidationError {
  override name = "ObjectSerializationError";
}

export class ObjectDeserializationError extends ValidationError {
  override name = "ObjectDeserializationError";
}

export const object = <T extends { [key: string]: any }>(fieldSerializers: {
  [field in keyof T]: UnsafeSerializer<T[field]>;
}): UnsafeSerializer<T> & SerializerExtensions<T> & { fields: typeof fieldSerializers } => ({
  serialize: (item) =>
    _.mapValues(fieldSerializers, (fieldSerializer, fieldName: keyof T) => {
      try {
        return fieldSerializer.serialize(item[fieldName]);
      } catch (err) {
        const errMessage = err instanceof Error ? err.message : JSON.stringify(err);
        throw new ObjectSerializationError(JSON.stringify({ [fieldName]: errMessage }));
      }
    }),
  forceDeserialize: (raw) => {
    if (typeof raw !== "object")
      throw new ValidationError(`Object deserialization failure: incorrect input type (${typeof raw})`);

    const result: Partial<T> = {};
    for (const fieldName in fieldSerializers) {
      try {
        result[fieldName] = fieldSerializers[fieldName].forceDeserialize(raw[fieldName]);
      } catch (err) {
        const errMessage = err instanceof Error ? err.message : JSON.stringify(err);
        throw new ObjectDeserializationError(JSON.stringify({ [fieldName]: errMessage }));
      }
    }
    return result as T;
  },
  fields: fieldSerializers,
  ...serializerExtensions(),
});

/**
 * @deprecated TODO
 */
export const identity = <T>(): UnsafeSerializer<T> => ({
  forceDeserialize: _.identity,
  serialize: _.identity,
});
