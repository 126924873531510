import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export const NoTasksScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FontAwesomeIcon icon={faBell} className={classes.icon} />
      <Typography variant="h5" className={classes.text}>
        Brak powiadomień
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    marginTop: "6rem",
  },
  icon: {
    fontSize: "3rem",
    marginBottom: "1rem",
    color: theme.palette.grey[400],
  },
  text: {
    color: theme.palette.grey[400],
  },
}));
