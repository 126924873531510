import { css, useTheme } from "@emotion/react";

type Props = {
  profiles: { firstName?: string; lastName?: string; profilePictureUrl: string }[];
  fontColor?: string;
  text?: string;
  fullName?: boolean;
};

export const AvatarGroup: React.FC<Props> = (props) => {
  const avatar = css({ borderRadius: "50%", width: "30px", height: "30px" });
  const theme = useTheme();
  if (props.profiles.length === 1 && props.profiles[0].profilePictureUrl) {
    return (
      <div
        css={{
          display: "flex",
          gap: "1em",
          color: `${props.fontColor ? props.fontColor : "black"}`,
          alignItems: "center",
        }}
      >
        {props.text && props.text}
        {props.fullName && `${props.profiles[0].firstName} ${props.profiles[0].lastName}`}
        <img src={props.profiles[0].profilePictureUrl} css={avatar} />
      </div>
    );
  } else if (props.profiles.length > 1) {
    const limitedProfiles = props.profiles.slice(0, 3);
    return (
      <div css={{ display: "flex", alignItems: "center",  }}>
        {limitedProfiles.map((profile, index) => (
          <img key={index} src={profile.profilePictureUrl} css={css(avatar, { marginLeft: "-8px" })} />
        ))}
      </div>
    );
  } else {
    return (
      <div
        css={{
          display: "flex",
          gap: "1em",
          color: `${props.fontColor ? props.fontColor : "black"}`,
          alignItems: "center",
        }}
      >
        {props.text && props.text}
        <div css={css(avatar, { border: `1px ${props.fontColor ? props.fontColor : theme.colors.border} dashed` })} />
      </div>
    );
  }
};
