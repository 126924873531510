import _ from "lodash";

import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";
import { identity } from "./object";

export const record = <TValue, TKey extends string = string>(
  valueSerializer: Serializer<TValue>,
  keySerializer: Serializer<TKey> = identity<TKey>(),
): Serializer<Partial<Record<TKey, TValue>>> & SerializerExtensions<Partial<Record<TKey, TValue>>> => ({
  serialize: (input) =>
    _(input)
      .mapValues(valueSerializer.serialize)
      .mapKeys((v, key) => keySerializer.serialize(key as any))
      .value(),
  deserialize: (raw) => {
    if (typeof raw !== "object") return Failure(`Record deserialization failure: incorrect input type (${typeof raw})`);
    if (raw === null) return Failure(`Record deserialization failure: input is null`);

    const keyErrors: { [rawKey: string]: any } = {};
    const valueErrors: { [rawKey: string]: any } = {};

    const deserialzed: Partial<Record<TKey, TValue>> = {};

    for (const [rawKey, rawValue] of Object.entries(raw)) {
      const keyResult = keySerializer.deserialize(rawKey);
      const valueResult = valueSerializer.deserialize(rawValue);
      if (keyResult.isFailure) keyErrors[rawKey] = keyResult.error;
      if (valueResult.isFailure) valueErrors[rawKey] = valueResult.error;
      if (keyResult.isFailure || valueResult.isFailure) continue;
      deserialzed[keyResult.value] = valueResult.value;
    }

    if (Object.keys(keyErrors).length > 0 || Object.keys(valueErrors).length > 0)
      return Failure({ keyErrors, valueErrors });
    return Ok(deserialzed);
  },
  ...serializerExtensions(),
});
