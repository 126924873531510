import { useTheme } from "@emotion/react";
import { useState } from "react";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";

import { CustomerCategorySection } from "./CustomerCategorySection";
import { CustomerDetails } from "./CustomerDetails";
import { CustomerTypeSection } from "./CustomerTypeSection";

export type CustomerType = "firm" | "customer";
export type CustomerCategory = "punkt-sprzedazy" | "platnik" | "dystrybutor" | "siec";

export const AddCustomerPage = () => {
  const { isMobile } = useDeviceType();

  const [customerType, setCustomerType] = useState<CustomerType | undefined>(undefined);
  const [customerCategories, setCustomerCategories] = useState<CustomerCategory[]>([]);

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [acronym, setAcronym] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");

  const handleCustomerCategoryChange = (value: CustomerCategory) => {
    if (customerCategories.includes(value)) {
      setCustomerCategories(customerCategories.filter((category) => category !== value));
      return;
    }

    setCustomerCategories([...customerCategories, value]);
  };

  const customerDetailsProps =
    customerType === "firm"
      ? ({
          isFirm: true,
          customerData: {
            acronym,
            address,
            street,
            postalCode,
            city,
            country,
          },
          handlers: {
            onAcronymChange: setAcronym,
            onAddressChange: setAddress,
            onStreetChange: setStreet,
            onPostalCodeChange: setPostalCode,
            onCityChange: setCity,
            onCountryChange: setCountry,
          },
        } as const)
      : ({
          isFirm: false,
          customerData: {
            phoneNumber,
          },
          handlers: {
            onPhoneNumberChange: setPhoneNumber,
          },
        } as const);

  return (
    <>
      <HeaderBar header="Nowy klient" />

      <Page
        css={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
          marginTop: "83px",
          gap: "2rem",
        }}
      >
        <div
          css={{
            display: "flex",
            gap: "2rem",
            width: "100%",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              width: "100%",
            }}
          >
            <CustomerTypeSection customerType={customerType} onCustomerTypeChange={setCustomerType} />

            <CustomerCategorySection
              selectedCategories={customerCategories}
              onCategoryChange={handleCustomerCategoryChange}
              isDisabled={customerType !== "firm"}
            />
          </div>

          <CustomerDetails {...customerDetailsProps} />
        </div>

        <Button css={{ alignSelf: "flex-end" }}>Dalej</Button>
      </Page>
    </>
  );
};

export const SectionHeader = ({ header }: { header: string }) => {
  const theme = useTheme();

  return <h4 css={{ fontSize: "1rem", fontWeight: 700, color: theme.colors.primary, margin: 0 }}>{header}</h4>;
};
