import React, { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";

type ReturnObject<T> = {
  value: T | undefined;
  loading: boolean;
  error: null | Error;
  reload: () => Promise<void>;
};

export const useAsyncLoad = <T>(getValue: () => Promise<T>, deps: React.DependencyList): ReturnObject<T> => {
  const isMounted = useRef(true);
  const [value, setValue] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const lastCallId = useRef<string>("");
  const isSettable = (id: string) => lastCallId.current === id && isMounted.current;

  const reload = async () => {
    const callId = v4();
    lastCallId.current = callId;
    setLoading(true);

    await getValue()
      .then((v) => isSettable(callId) && setValue(v))
      .then(() => isSettable(callId) && setLoading(false))
      .catch((error) => isSettable(callId) && setError(error));
  };

  useEffect(() => {
    reload();
  }, deps);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return { value, loading, error, reload };
};
