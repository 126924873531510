import { Box, Button } from "@mui/material";
import React, { useState } from "react";

import { Uuid } from "@megarax/common";
import { GroupListFilters, GroupSimple } from "@megarax/iam-contracts";
import { Result } from "@megaron/result";
import { TransferList } from "@megarax/ui-components";

interface Props {
  getIamGroups: (filters: GroupListFilters) => Promise<GroupSimple[]>;
  assignGroups: (uuid: Uuid, groupUuids: Uuid[]) => Promise<Result<void, void>>;
  closeDialog: () => void;
  regionUuid: Uuid;
  currentGroups: GroupSimple[];
}

export const EditGroupsDialog: React.FC<Props> = ({
  getIamGroups,
  assignGroups,
  closeDialog,
  regionUuid,
  currentGroups,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [assignedGroups, setAssignedGroups] = useState<GroupSimple[] | undefined>(currentGroups);

  const getOptions = (searchText?: string) => getIamGroups({ searchText: searchText ?? "", limit: 1000 });

  const onSave = async () => {
    setLoading(true);
    const result = await assignGroups(
      regionUuid,
      assignedGroups ? assignedGroups.map((items) => items.uuid as Uuid) : [],
    );
    setLoading(false);
    if (result.isFailure) return;
    closeDialog();
  };

  return (
    <Box px={2}>
      <TransferList
        getOptions={getOptions}
        right={assignedGroups ?? []}
        setRight={setAssignedGroups}
        getKey={(o) => o.uuid}
        getLabel={(o) => o.name}
      />
      <Box display="flex" justifyContent="flex-end" py={1}>
        <Button disabled={loading} onClick={closeDialog}>
          Anuluj
        </Button>
        <Button color="primary" disabled={loading} onClick={onSave}>
          Zapisz
        </Button>
      </Box>
    </Box>
  );
};
