import { useTheme } from "@emotion/react";
import React from "react";
import { MdCancel, MdClose } from "react-icons/md";
import { Query, useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom-v5-compat";

import { DescriptionField, DescriptionList } from "@megaron/dash-description-list";
import { Button, Chip } from "@megaron/dash-form";
import { Card, Divider, Header3, Header4, Page, PageHeader, SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useToast } from "@megaron/dash-toast";
import { AccountDescriptionList, getUserPath } from "@megaron/loyalty-users-admin-webapp";
import { useClientManager } from "@megaron/react-clients";
import { Uuid, uuidSerializer } from "@megaron/uuid";

import { FulfillmentCard } from "./FulfillmentCard";
import { NewFulfillmentCard } from "./NewFulfillmentCard";

type Props = {};

export const OrderPage: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const orderUuid = uuidSerializer.deserialize(useParams<{ orderId: string }>().orderId).assertOk().value;

  const orderQuery = useClientManager("loyaltyOrders")
    .order()
    .useQuery({ uuid: orderUuid }, { enabled: orderUuid !== undefined });

  const accountQuery = useClientManager("loyaltyAccounts")
    .account()
    .useQuery(
      { uuid: orderQuery.data?.userUuid ?? ("" as Uuid) },
      {
        enabled: orderQuery.data !== undefined,
      },
    );

  const queryClient = useQueryClient();
  const toast = useToast();

  const cancelOrderMutation = useClientManager("loyaltyOrders")
    .cancelOrder()
    .useMutation({
      onSuccess: async () => {
        await queryClient.invalidateQueries(orderQuery.key);
      },
      onError: (error) => {
        toast.error("Nie udało się anulować zamówienia", error);
      },
    });

  return (
    <Page css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <PageHeader>{`Zamówienie ${orderUuid}`}</PageHeader>
      <QuerySkeleton query={orderQuery} height="200px">
        {(order) => (
          <DescriptionList>
            <DescriptionField label="Nagroda">{order.rewardId}</DescriptionField>
            <DescriptionField label="Data utworzenia">{order.orderedAt.toLocaleString()}</DescriptionField>
            <DescriptionField label="Użytkownik">
              <Link to={getUserPath(order.userUuid)}>{order.userUuid}</Link>
            </DescriptionField>
          </DescriptionList>
        )}
      </QuerySkeleton>
      <Divider />

      <QuerySkeleton query={accountQuery} height="200px">
        {(account) => (
          <Card variant="secondary">
            <Link to={getUserPath(account.uuid)}>
              <Header4>Użytkownik {account.uuid}</Header4>
            </Link>

            <AccountDescriptionList account={account} />
          </Card>
        )}
      </QuerySkeleton>
      <SectionHeader
        isHr
        actions={
          orderQuery.data?.isCancelled ? (
            <Chip color={theme.colors.danger}>Anulowane</Chip>
          ) : (
            <Button
              onClick={() => cancelOrderMutation.mutate({ uuid: orderUuid })}
              isLoading={cancelOrderMutation.isLoading}
              variant="outline"
              color="danger"
              size="small"
              icon={<MdClose />}
              css={{ whiteSpace: "pre" }}
            >
              Anuluj zamówienie
            </Button>
          )
        }
      >
        Realizacja
      </SectionHeader>
      <QuerySkeleton query={orderQuery} height="200px">
        {(order) => (
          <div css={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
            {order.fulfillments.map((f) => (
              <FulfillmentCard fulfillment={f} />
            ))}
            <NewFulfillmentCard orderUuid={orderUuid} />
          </div>
        )}
      </QuerySkeleton>
    </Page>
  );
};
