import { HttpAction } from "@megaron/http-service";
import { invoiceThreadDocSerializer, threadStatuses } from "@megaron/invoices-contracts";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const threadsSortFields = [
  "createdAt",
  "deadline",
  "referenceNumber",
  "invoiceTotal",
  "invoiceNumber",
  "invoiceIssuer",
  "title",
  "approvedAt",
  "invoiceReceivedDate",
  "invoiceIssueDate",
  "decreeNumber",
  "invitedAt",
  "dueDate",
] as const;

export type ThreadSortField = (typeof threadsSortFields)[number];

export const threadFiltersSerializer = Serializers.object({
  limit: Serializers.integer,
  offset: Serializers.integer,
  searchText: Serializers.string,
  issuer: Serializers.string,
  sort: Serializers.sortFilter(threadsSortFields).asJsonString(),
  status: Serializers.stringOneOf(...threadStatuses)
    .array()
    .asJsonString(),
  budgetUuid: uuidSerializer.array().asJsonString(),
  isDigital: Serializers.booleanFieldFilter,
  isReceived: Serializers.booleanFieldFilter,
  isPosted: Serializers.booleanFieldFilter,
  signedBy: Serializers.string.array().asJsonString(),
  participant: Serializers.string.array().asJsonString(),
  isRead: Serializers.booleanFieldFilter,
  isOverdue: Serializers.booleanFieldFilter,
  isMine: Serializers.booleanFieldFilter,
  tags: Serializers.string.array().asJsonString(),
}).partial();

export const invoiceThreadActions = {
  searchInvoiceThreads: HttpAction({
    path: "/invoiceThreads",
    qsSerializer: threadFiltersSerializer,
    valueSerializer: Serializers.paginatedList(invoiceThreadDocSerializer),
    requiresAuth: true,
  }),

  invoiceIssuers: HttpAction({
    path: "/invoiceThreads/issuers",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      limit: Serializers.integer,
      offset: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.string.array(),
    requiresAuth: true,
  }),

  invoiceThreadAutocomplete: HttpAction({
    path: "/invoiceThreads/autocomplete",
    qsSerializer: Serializers.object({
      text: Serializers.string,
      field: Serializers.stringOneOf("title", "invoiceIssuer"),
    }),
    valueSerializer: Serializers.string.array(),
    requiresAuth: true,
  }),

  similarInvoiceThreadsQuery: HttpAction({
    path: "/invoiceThreads/similar",
    qsSerializer: Serializers.object({
      invoiceIssuer: Serializers.string,
      invoiceNumber: Serializers.string,
      status: Serializers.stringOneOf(...threadStatuses)
        .array()
        .asJsonString(),
    }),
    requiresAuth: true,
    valueSerializer: Serializers.object({
      threadUuid: uuidSerializer,
      title: Serializers.string,
      referenceNumber: Serializers.string,
      invoiceIssuer: Serializers.string,
      invoiceNumber: Serializers.string,
      status: Serializers.oneOf(...threadStatuses),
      createdAt: Serializers.datetime,
    }).array(),
  }),

  unreadThreadCountsQuery: HttpAction({
    path: "/invoiceThreads/unreadThreadCounts",
    requiresAuth: true,
    valueSerializer: Serializers.record(Serializers.integer),
  }),
};
