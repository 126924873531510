import { Uuid } from "@megarax/common";
import { visitResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import React from "react";
import { useQuery } from "react-query";

import { VisitViewContainerContent } from "./VisitViewContainerContent";

interface Props {
  uuid: Uuid;
}

export const VisitViewContainer: React.FunctionComponent<Props> = ({ uuid }) => {
  const errorHandler = useSnackbarErrorHandler(commonErrorsMap);
  const visitProvider = useResourceProviderV2(visitResource);

  const {
    data: visitResult,
    isFetching,
    refetch,
  } = useQuery(["visit", uuid], () => visitProvider.byUuid(uuid).retrieve());

  if (visitResult?.isFailure) {
    if (visitResult.error === "VisitNotFound") return null; // TODO "not found" screen
    errorHandler(visitResult.error);
    return null;
  }

  const visit = visitResult?.value;

  return <VisitViewContainerContent uuid={uuid} visit={visit} refetch={refetch} />;
};
