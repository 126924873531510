import { newUuid, toDateString, Uuid } from "@megarax/common";
import { customerListResource, visitResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import React from "react";

import { AddUnplannedVisitDialog, GetCustomerOptions, OnComplete } from "./AddUnplannedVisitDialog";

interface Props {
  date: Date;
  onClose: () => void;
  onAdded: (visitUuid: Uuid) => void;
}

export const AddUnplannedVisitDialogContainer: React.FunctionComponent<Props> = ({ date, onClose, onAdded }) => {
  const uuid = newUuid();
  const customerListProvider = useResourceProviderV2(customerListResource);
  const myVisitProvider = useResourceProviderV2(visitResource);

  const errorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
    InvalidVisitTime: "Czas wizyty niezgodny z godzinami pracy.",
  });

  const getCustomerOptions: GetCustomerOptions = (searchText) =>
    customerListProvider
      .list({ searchText })
      .map((v) => v.items)
      .mapError(errorHandler)
      .then((r) => r.assertOk().value);

  const onComplete: OnComplete = (formData) => {
    myVisitProvider
      .byUuid(uuid)
      .plan(undefined, {
        customerUuid: formData.customerUuid,
        date: toDateString(date),
      })
      .mapError(errorHandler)
      .map((value) => {
        onAdded(uuid);
      });
  };

  return (
    <AddUnplannedVisitDialog
      onClose={onClose}
      isOpen={true}
      key={date.toISOString()}
      getCustomerOptions={getCustomerOptions}
      onComplete={onComplete}
    />
  );
};
