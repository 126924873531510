import qs from "qs";
import { useHistory } from "react-router-dom";

export const useDialog = (name: string) => {
  const history = useHistory();

  const open = (value?: string) => {
    const valueToSet = typeof value === "string" ? value : "true";

    const paramsCopy = {
      ...qs.parse(history.location.search.substring(1)),
      [name]: valueToSet,
    };
    history.push({ search: qs.stringify(paramsCopy), hash: history.location.hash });
  };

  const close = () => {
    if (history.action === "PUSH") return history.goBack();
    const paramsCopy = qs.parse(history.location.search.substring(1));
    delete paramsCopy[name];
    history.replace({ search: qs.stringify(paramsCopy), hash: history.location.hash });
  };

  const value = `${qs.parse(history.location.search.substring(1))[name]}`;

  return { open, close, value, name };
};
