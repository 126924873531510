export const getEnvString = (varName: string, fallback?: string): string => {
  const value = process.env[varName];
  if (value) return value;
  if (fallback === undefined) throw new Error("Missing env variable " + varName);
  return fallback;
};

export const getEnvMultilineString = (varName: string, fallback?: string): string => {
  const value = process.env[varName];
  if (value) return value.replace(/\\n/g, "\n");
  if (fallback === undefined) throw new Error("Missing env variable " + varName);
  return fallback;
};

export const getEnvInteger = (varName: string, fallback?: number): number => {
  const value = process.env[varName];
  if (value) return parseInt(value);
  if (fallback === undefined) throw new Error("Missing env variable " + varName);
  return fallback;
};

export const getEnvBoolean = (varName: string, fallback?: boolean): boolean => {
  const value = process.env[varName];
  if (value) {
    if (value && !["true", "false"].includes(value)) throw new Error("Incorrect boolean env value for " + varName);
    return value === "true";
  }
  if (fallback === undefined) throw new Error("Missing env variable " + varName);
  return fallback;
};
