import { MSerializers } from "@megarax/serializers";

import { MessageDto, messageDtoSerializer } from "./threadDto";
import { ThreadIdentifier, threadIdentifierStringSerializer } from "./threadIdentifier";

export type MySubscription = {
  threadIdentifier: ThreadIdentifier;
  lastMessage: MessageDto;
  unreadMessageCount: number;
};

export const mySubscriptionSerializer = MSerializers.object<MySubscription>({
  lastMessage: messageDtoSerializer,
  threadIdentifier: threadIdentifierStringSerializer,
  unreadMessageCount: MSerializers.integer,
});
