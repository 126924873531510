import { AttachMoney } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { PayerDetail, PriceListSimple } from "@megarax/crm-contracts";
import { ProfilesDictionary } from "@megarax/iam-webapp";

interface Props {
  payer: PayerDetail | null;
  priceLists: PriceListSimple[];
  profiles: ProfilesDictionary;
  onOpenDialog: () => void;
}

export const PricingSection: React.FunctionComponent<Props> = ({ payer, priceLists, profiles, onOpenDialog }) => {
  const classes = useStyles();

  const getPriceListName = (
    priceList: {
      uuid: string;
    } | null,
  ) => {
    if (!priceList) return "-";
    const list = priceLists.find((list) => list.uuid === priceList.uuid);
    if (!list) return "-";
    return list.name;
  };

  return (
    <Box display="flex" flexDirection="row">
      <AttachMoney />
      <Box>
        <Box ml={1}>
          <Typography>{payer?.priceList ? `Cennik ${getPriceListName(payer.priceList)}` : "Brak cennika"}</Typography>
          <Typography>
            {`Rabat podstawowy `}
            {payer ? `${payer.baseDiscount.mul(100).toFixed(2)}%` : "0%"}
          </Typography>
          <Typography className={classes.otherTermsText}>{payer?.otherTerms ?? "-"}</Typography>
        </Box>
        <Button onClick={onOpenDialog}>Więcej</Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  otherTermsText: {
    whiteSpace: "pre-wrap",
  },
}));
