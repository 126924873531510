import { SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { renderToString } from "react-dom/server";
import { useQuery } from "react-query";
import { z } from "zod";

import { Uuid } from "@megarax/common";
import { CustomerStatus, customerStatuses, TradeRouteSimple, VisitAddress } from "@megarax/crm-contracts";
import { BreadcrumbMarker, PageTitleManager, qsIdSchema, QsInput, useQsV2 } from "@megarax/ui-components";

import { formatAddress, Google } from "../../customers/shared/LocationPickerV2";
import { useCustomersResource } from "../../resourceHooks";
import { useSalespeopleResource } from "../../salespeople";
import { customerHasAddress } from "../mapsCommon/customerHasAddress";
import { ExtendedMarkerClusterer } from "../mapsCommon/ExtendedMarkerClusterer";
import { googleMarkerFactory } from "../mapsCommon/markerFactoryV2";
import { notEmpty } from "../utils";
import { Filters } from "./Filters";
import { RouteMenu } from "./RouteMenu";
import { useRefCallback } from "./useRefCallback";
import { useDrawRoute } from "./useRouteDrawer";

interface Props {
  initialProps?: {
    tradeRoute: TradeRouteSimple;
    stopDurations: Dictionary<{
      durationMinutes: string;
    }>;
    route: TradeRouteCustomer[];
  };
  map: google.maps.Map;
  google: Google;
}

export const TradeRouteDetails: React.FC<Props> = ({ initialProps, map, google }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [route, setRoute] = React.useState<TradeRouteCustomer[]>(initialProps?.route ?? []);
  const [stopDurations, setStopDurations] = React.useState<
    Dictionary<{
      durationMinutes: string;
    }>
  >(initialProps?.stopDurations ?? {});
  const [travelData, setTravelData] = React.useState<TravelData[]>([]);
  const [isEdited, setIsEdited] = React.useState(false);

  const prevZoom = React.useRef<number | undefined>(undefined);
  const idleListener = React.useRef<google.maps.MapsEventListener>();
  const routeMarkers = React.useRef<google.maps.Marker[]>([]);
  const homeMarker = React.useRef<google.maps.Marker>();
  const infoWindow = React.useRef<google.maps.InfoWindow>(new google.maps.InfoWindow());

  const { debouncedDrawRoute } = useDrawRoute({ google, map, setTravelData });
  const [markerFactory] = React.useState(googleMarkerFactory({ google, map }));
  const [markerClusterer] = React.useState(
    new ExtendedMarkerClusterer({
      map,
      markers: [],
      algorithm: new SuperClusterAlgorithm({}),
      renderer: {
        render: ({ count, position }) => {
          return markerFactory.generate({
            position: { lat: position.lat(), lng: position.lng() },
            variant: "cluster",
            idx: 0,
            text: String(count),
          });
        },
      },
    }),
  );

  const { qs, setQs, setAllQs } = useQsV2(tradeRouteQsSchema, {
    salespersonId: initialProps?.tradeRoute?.salespersonId
      ? initialProps.tradeRoute.salespersonId.toString()
      : undefined,
    status: ["vip"],
  });

  const { listCustomers } = useCustomersResource();
  const { retrieve } = useSalespeopleResource();

  const addRouteStop = useRefCallback(
    (entry: TradeRouteCustomer) => {
      setStopDurations({ ...stopDurations, [entry.uuid]: { durationMinutes: "20" } });
      setRoute([...route, entry]);
    },
    [route, stopDurations],
  );

  const removeRouteStop = useRefCallback(
    (uuid: Uuid) => {
      setStopDurations({ ...stopDurations, [uuid]: { durationMinutes: "20" } });
      setRoute(route.filter((item) => item.uuid !== uuid));
    },
    [route, stopDurations],
  );

  const { data: salesperson } = useQuery(
    ["salesperson", qs.salespersonId],
    () => {
      if (!qs.salespersonId) return;
      return retrieve(qs.salespersonId);
    },
    { enabled: qs.salespersonId !== undefined },
  );

  const { data: customers } = useQuery(
    ["customers", salesperson?.value, qs],
    () => {
      if (!salesperson?.value) return;
      return listCustomers({
        regionUuid: [salesperson.value.regionUuid],
        limit: 1000,
        searchText: qs.searchText,
        status: qs.status ?? [],
        macroregionUuid: (qs.macroregionUuid ?? undefined) as Uuid[] | undefined,
        visitedIn: qs.visitedSince.value
          ? qs.visitedSince.bool
            ? qs.visitedSince.value
            : -qs.visitedSince.value
          : undefined,
        orderedIn: qs.orderedSince.value
          ? qs.orderedSince.bool
            ? qs.orderedSince.value
            : -qs.orderedSince.value
          : undefined,
        isOnRoute: qs.onRoute === "true" ? true : qs.onRoute === "false" ? false : undefined,
      }).map((res) => res.items);
    },
    { keepPreviousData: true, enabled: salesperson?.isOk },
  );

  const customerEntries = React.useMemo(() => {
    if (!customers) return undefined;
    if (customers.isFailure) return customers;
    return customers.map((res) =>
      res.filter(customerHasAddress).filter((val) => !Boolean(route.find((stop) => stop.uuid === val.uuid))),
    );
  }, [customers, route]);

  const updateMarkers = React.useCallback(
    (fullRefresh: boolean) => {
      const skipUpdate = prevZoom.current === map.getZoom() && !fullRefresh;
      if (!customerEntries || !customerEntries.isOk || skipUpdate) return;
      prevZoom.current = map.getZoom();

      const markers = customerEntries.value
        .map((entry, idx) => {
          if (!entry.status) return null;

          const position = { lat: entry.visitAddress.lat.toNumber(), lng: entry.visitAddress.lng.toNumber() };

          const marker = markerFactory.generate({
            position,
            variant: entry.status,
            idx,
            text: entry.name ?? "",
          });
          marker.addListener("click", () => {
            map.panTo(position);
            infoWindow.current.setContent(
              renderToString(
                <InfoBox
                  onRoute={false}
                  name={entry.name ?? ""}
                  uuid={entry.uuid}
                  address={formatAddress({
                    locality: entry.visitAddress.locality,
                    street: entry.visitAddress.street,
                    postalCode: "",
                    country: "",
                    name: entry.visitAddress.name,
                  })}
                />,
              ),
            );
            google.maps.event.clearListeners(infoWindow.current, "domready");
            google.maps.event.addListener(infoWindow.current, "domready", () => {
              document
                .getElementById("infowindow-button")
                ?.addEventListener("click", () => addRouteStop.current({ ...entry, name: entry.name ?? "" }));
            });
            infoWindow.current.open({
              anchor: marker,
              map,
              shouldFocus: false,
            });
          });

          return marker;
        })
        .filter(notEmpty);

      markerClusterer.clearMarkers();
      markerClusterer.addMarkers(markers);
    },
    [customerEntries],
  );

  React.useEffect(() => {
    updateMarkers(true);
    markerClusterer.updateVisibility();

    if (idleListener.current) google.maps.event.removeListener(idleListener.current);
    idleListener.current = map.addListener("idle", () => {
      updateMarkers(false);
      markerClusterer.updateVisibility();
    });
  }, [updateMarkers]);

  const updateRouteMarkers = React.useCallback(() => {
    const markers = route
      .map((entry, idx) => {
        if (!entry.status) return null;

        const position = { lat: entry.visitAddress.lat.toNumber(), lng: entry.visitAddress.lng.toNumber() };

        const marker = markerFactory.generate({
          position,
          variant: "route",
          idx,
          text: entry.name,
        });
        marker.addListener("click", () => {
          map.panTo(position);

          infoWindow.current.setContent(
            renderToString(
              <InfoBox
                onRoute={true}
                name={entry.name ?? ""}
                uuid={entry.uuid}
                address={formatAddress({
                  locality: entry.visitAddress.locality,
                  street: entry.visitAddress.street,
                  postalCode: "",
                  country: "",
                  name: entry.visitAddress.name,
                })}
              />,
            ),
          );
          google.maps.event.clearListeners(infoWindow.current, "domready");
          google.maps.event.addListener(infoWindow.current, "domready", () => {
            document
              .getElementById("infowindow-button")
              ?.addEventListener("click", () => removeRouteStop.current(entry.uuid));
          });
          infoWindow.current.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });

        return marker;
      })
      .filter(notEmpty);

    routeMarkers.current.forEach((marker) => marker.setMap(null));
    routeMarkers.current = markers;
  }, [route]);

  React.useEffect(() => {
    updateRouteMarkers();
  }, [updateRouteMarkers]);

  React.useEffect(() => {
    map.addListener("zoom_changed", () => {
      markerClusterer.clearMarkers();
    });
  }, []);

  React.useEffect(() => {
    const salespersonHomePosition = salesperson?.value?.homeLocation
      ? {
          lat: salesperson.value.homeLocation.latitude.toNumber(),
          lng: salesperson.value.homeLocation.longitude.toNumber(),
        }
      : null;

    debouncedDrawRoute(route, salespersonHomePosition);
  }, [route, salesperson?.value?.homeLocation]);

  React.useEffect(() => {
    const salespersonHomePosition = salesperson?.value?.homeLocation
      ? {
          lat: salesperson.value.homeLocation.latitude.toNumber(),
          lng: salesperson.value.homeLocation.longitude.toNumber(),
        }
      : null;
    if (homeMarker.current) homeMarker.current.setMap(null);
    if (salespersonHomePosition) {
      const marker = markerFactory.generate({
        position: salespersonHomePosition,
        variant: "home",
        idx: 0,
        text: "",
      });
      homeMarker.current = marker;
    }
  }, [salesperson?.value?.homeLocation]);

  const revertToInitialRoute = () => {
    if (!initialProps) {
      setQs("salespersonId", undefined);
      setRoute([]);
      setStopDurations({});
      return;
    }
    setQs("salespersonId", initialProps.tradeRoute.salespersonId);
    setRoute(initialProps.route);
    setStopDurations(initialProps.stopDurations);
  };

  React.useEffect(() => {
    if (!initialProps) return setIsEdited(true);
    const tradeRouteString =
      route.map((entry) => entry.uuid + stopDurations[entry.uuid].durationMinutes).join("") + qs.salespersonId;
    const initialTradeRouteString =
      initialProps.route.map((entry) => entry.uuid + initialProps.stopDurations[entry.uuid].durationMinutes).join("") +
      initialProps.tradeRoute.salespersonId;
    setIsEdited(tradeRouteString !== initialTradeRouteString);
  }, [route, qs.salespersonId, stopDurations]);

  return (
    <>
      <PageTitleManager title={initialProps?.tradeRoute.name ?? "Trasa"} />
      <BreadcrumbMarker title={initialProps?.tradeRoute.name ?? "Trasa"} id="trasa" />
      <Filters
        isMobile={isMobile}
        qs={qs}
        setQueryFilters={setAllQs}
        isEdited={isEdited}
        revertToInitialRoute={revertToInitialRoute}
      />
      <RouteMenu
        initialTradeRoute={initialProps?.tradeRoute ?? null}
        homeSelected={salesperson?.isOk ?? false}
        isMobile={isMobile}
        travelData={travelData}
        stopDurations={stopDurations}
        setStopDurations={setStopDurations}
        route={route}
        setRoute={setRoute}
        salespersonId={qs.salespersonId}
        qs={qs}
        setQs={setQs}
        isEdited={isEdited}
      />
    </>
  );
};

const InfoBox: React.FC<{ name: string; address: string; uuid: Uuid; onRoute: boolean }> = ({
  name,
  uuid,
  address,
  onRoute,
}) => {
  const buttonStyle = {
    width: "100%",
    marginTop: "8px",
    border: "none",
    cursor: "pointer",
    padding: "4px",
  };
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <a href={`/crm/klienci/${uuid}`}>
        <Typography>{name}</Typography>
      </a>
      <Typography variant="caption">{address}</Typography>
      {onRoute ? (
        <button style={buttonStyle} id={"infowindow-button"}>
          Usuń z trasy
        </button>
      ) : (
        <button style={buttonStyle} id={"infowindow-button"}>
          Dodaj do trasy
        </button>
      )}
    </Box>
  );
};

export type TradeRouteCustomer = {
  uuid: Uuid;
  name: string;
  visitAddress: VisitAddress;
  permanentTradeRoutesCount: number | null;
  status: CustomerStatus | null;
};

export interface TravelData {
  duration: number;
  distance: number;
}

export interface QueryFilters {
  salespersonId: number | undefined;
  macroregionUuid: string[] | null;
  searchText: string;
  visitedSince: { value: number | null; bool: boolean };
  orderedSince: { value: number | null; bool: boolean };
  status: CustomerStatus[] | null;
  onRoute: "true" | "false" | null;
}
export type SetQueryFilters = (key: keyof QueryFilters, value: QueryFilters[typeof key]) => void;

const tradeRouteQsSchema: QsInput<QueryFilters> = {
  salespersonId: qsIdSchema,
  macroregionUuid: {
    validator: z.array(z.string()),
    fallback: null,
  },
  searchText: {
    validator: z.string(),
    fallback: "",
  },
  visitedSince: {
    validator: z.preprocess(
      (obj: any) => ({
        value:
          obj?.value === null
            ? null
            : typeof obj?.value === "string" && !isNaN(parseInt(obj?.value))
            ? _.toNumber(obj?.value)
            : null,
        bool:
          typeof obj?.bool === "string" ? (obj?.bool === "true" ? true : obj?.bool === "false" ? false : true) : true,
      }),
      z.object({ value: z.number().nullable(), bool: z.boolean() }),
    ),
    fallback: { value: null, bool: true },
  },
  orderedSince: {
    validator: z.preprocess(
      (obj: any) => ({
        value:
          obj?.value === null
            ? null
            : typeof obj?.value === "string" && !isNaN(parseInt(obj?.value))
            ? _.toNumber(obj?.value)
            : null,
        bool:
          typeof obj?.bool === "string" ? (obj?.bool === "true" ? true : obj?.bool === "false" ? false : true) : true,
      }),
      z.object({ value: z.number().nullable(), bool: z.boolean() }),
    ),
    fallback: { value: null, bool: true },
  },
  status: {
    validator: z.array(z.enum(customerStatuses)).nullable(),
    fallback: null,
  },
  onRoute: {
    validator: z.enum(["true", "false"]).nullable(),
    fallback: null,
  },
};
