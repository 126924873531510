import { Skeleton } from '@mui/material';
import { SkeletonProps } from '@mui/lab';
import React from "react";

interface Props<TData> {
  skeletonProps?: SkeletonProps;
  asyncData: TData | undefined;
  component: (asyncData: TData) => React.ReactNode;
}

export const SkeletonLoader = <TData,>({
  skeletonProps,
  asyncData,
  component,
}: Props<TData>) => {
  return asyncData === undefined ? (
    <Skeleton variant="text" width="100%" {...skeletonProps} />
  ) : (
    <>{component(asyncData)}</>
  );
};
