import { useTheme } from "@emotion/react";
import tinycolor from "tinycolor2";

type Props = {
  emailSubject: string;
  emailSender: string;
};

export const MailInforamtionTile: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "0.625rem",
        padding: "1rem",
        borderRadius: theme.smallBorderRadius,
        border: `1px solid ${tinycolor("#000000").setAlpha(0.1)}`,
        background: "white",
      }}
    >
      <span css={{ fontSize: "16px", color: theme.colors.primary, fontWeight: 700 }}>Wersja robocza z maila</span>
      <span css={{ fontSize: "14px", display: "flex", gap: "8px" }}>
        <span css={{ color: theme.colors.primary }}>Temat </span>
        {props.emailSubject}
      </span>
      <span css={{ fontSize: "14px", display: "flex", gap: "8px" }}>
        <span css={{ color: theme.colors.primary }}>Nadawca </span>
        {props.emailSender}
      </span>
    </div>
  );
};
