import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { invoiceSerializer } from "./invoice";
import { attachmentUploadSerializer } from "./thread";

export const draftAttachmentDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  fileName: Serializers.string,
  fileType: Serializers.string,
  url: Serializers.string,
});

export type DraftAttachmentDto = SerializerValue<typeof draftAttachmentDtoSerializer>;

const draftDtoSerializer = Serializers.object({
  uuid: Serializers.string,
  title: Serializers.string,
  invoice: invoiceSerializer,
  attachments: draftAttachmentDtoSerializer.array(),
  isDeleted: Serializers.boolean,
  emailSubject: Serializers.string.nullable(),
  emailSender: Serializers.string.nullable(),
});

export type DraftDto = SerializerValue<typeof draftDtoSerializer>;

const newDraftSerializer = Serializers.object({
  uuid: uuidSerializer,
  ocr: Serializers.boolean,
  attachments: attachmentUploadSerializer.array(),
});

export type NewDraft = SerializerValue<typeof newDraftSerializer>;

const draftListEntrySerializer = Serializers.object({
  uuid: Serializers.string,
  title: Serializers.string,
  invoice: invoiceSerializer,
  authorizedAttributes: Serializers.string.array(),
  isDeleted: Serializers.boolean,
  emailSubject: Serializers.string.nullable(),
  emailSender: Serializers.string.nullable(),
  createdAt: Serializers.datetime,
});

export type DraftListEntry = SerializerValue<typeof draftListEntrySerializer>;

export const draftActions = {
  draftQuery: HttpAction({
    path: "/drafts/uuid/:uuid",
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    method: "get",
    valueSerializer: draftDtoSerializer,
    errorSerializer: Serializers.stringOneOf("DraftNotFound"),
    requiresAuth: true,
  }),

  draftsQuery: HttpAction({
    path: "/drafts",
    method: "get",
    valueSerializer: draftListEntrySerializer.array(),
    requiresAuth: true,
  }),

  newDraft: HttpAction({
    path: "/drafts",
    method: "post",
    bodySerializer: newDraftSerializer,
    errorSerializer: Serializers.stringOneOf(),
    requiresAuth: true,
  }),

  saveDraft: HttpAction({
    path: "/drafts/:uuid",
    method: "put",
    bodySerializer: Serializers.object({
      title: Serializers.string,
      invoice: invoiceSerializer,
    }),
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
    }),
    errorSerializer: Serializers.stringOneOf("DraftNotFound"),
    requiresAuth: true,
  }),

  deleteDraft: HttpAction({
    path: "/drafts/:uuid",
    method: "delete",
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
    }),
    errorSerializer: Serializers.stringOneOf("DraftNotFound"),
    requiresAuth: true,
  }),

  createDraftsFromMails: HttpAction({
    path: "/drafts/mails",
    method: "post",
    bodySerializer: Serializers.object({
      ocr: Serializers.boolean,
    }),
    requiresAuth: true,
  }),
};
