import {
  decimalValidator,
  identityValidator,
  objectValidator,
  ObjectValidatorSchema,
} from "@megarax/validators";
import Decimal from "decimal.js";

export interface InitialProposeEditInput {
  activeFrom: Date | null;
  netPrice: Decimal | undefined;
  comment: string;
}

export interface ProposeEditInput {
  activeFrom: Date | null;
  netPrice: Decimal;
  comment: string;
}

export const validatorSchema = ObjectValidatorSchema<
  InitialProposeEditInput,
  ProposeEditInput
>()({
  activeFrom: identityValidator(),
  netPrice: decimalValidator({ greaterThan: 0 }),
  comment: identityValidator(),
});

const errorMessages = {
  required: "To pole jest wymagane.",
  gtError: "Cena musi być wyższa od zera.",
};

export const getErrorMessage = (failure: "required" | "gtError" | undefined) =>
  failure ? errorMessages[failure] : undefined;

export const formValidator = objectValidator(validatorSchema);
