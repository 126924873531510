import { useTheme } from "@emotion/react";
import { useShallowEffect } from "@mantine/hooks";
import { useContext, useRef, useState } from "react";
import { MdOutlineDrafts, MdOutlineMarkEmailUnread } from "react-icons/md";
import { useQueryClient } from "react-query";

import { useDeviceType } from "@megaron/dash-mq";
import { useToast } from "@megaron/dash-toast";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { ThreadDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

import { getIsReadAutomaticUpdateEnabled } from "../allThreads/IsReadSettings";

type Props = {
  thread: ThreadDto;
  queryKey: string | string[];
  isDisabled?: boolean;
};

export const IsReadSwitch: React.FC<Props> = ({ thread, queryKey, isDisabled }) => {
  const theme = useTheme();

  const { isDesktop } = useDeviceType();

  const auth = useContext(IamAuthContext);

  const toast = useToast();

  const wasIsReadSet = useRef(false);

  const queryClient = useQueryClient();

  const { mutate: setIsReadMutate } = useClientManager("invoices").setIsRead().useMutation();

  const [isRead, setIsRead] = useState<boolean | undefined>(undefined);

  const isParticipant =
    auth.iamUser?.userType === "megarax" && thread.participants.map((p) => p.user).includes(auth.iamUser.email);

  useShallowEffect(() => {
    if (isDisabled) {
      return;
    }

    const isReadAutomaticUpdateEnabled = getIsReadAutomaticUpdateEnabled();

    const userEmail = auth.iamUser?.userType === "megarax" && auth.iamUser.email;

    if (!isParticipant) {
      return;
    }

    const isRead = thread.participants.find((participant) => participant.user === userEmail)?.isRead;

    if (isRead === undefined) {
      return;
    }

    if ((!isReadAutomaticUpdateEnabled && !isRead) || wasIsReadSet.current || isRead) {
      wasIsReadSet.current = true;
      setIsRead(isRead);

      return;
    }

    setIsReadMutate(
      {
        isRead: true,
        threadUuid: thread.uuid,
      },
      {
        onSuccess: () => {
          wasIsReadSet.current = true;
          queryClient.invalidateQueries(queryKey);
          setIsRead(true);
        },
      },
    );
  }, [queryClient, queryKey, setIsReadMutate, thread.participants, thread.uuid]);

  const handleIsRedChange = (isRead: boolean) => {
    setIsReadMutate(
      {
        isRead,
        threadUuid: thread.uuid,
      },
      {
        onSuccess: () => {
          setIsRead(isRead);
          queryClient.invalidateQueries(queryKey);
        },

        onError: () => {
          toast.error(
            isRead
              ? "Nie udało się oznaczyć wątku jako przeczytany"
              : "Nie udało się oznaczyć wątku jako nieprzeczytany",
          );
        },
      },
    );
  };

  if (!isParticipant) {
    return null;
  }

  return (
    <button
      onClick={() => {
        if (isDisabled) {
          return;
        }

        handleIsRedChange(!isRead);
      }}
      css={{
        display: "flex",
        border: `1px solid ${theme.colors.border}`,
        borderRadius: theme.smallBorderRadius,
        cursor: isDisabled ? "auto" : "pointer",
        background: "none",
        padding: 0,
        overflow: "hidden",
        opacity: isRead === undefined ? 0.5 : 1,
      }}
    >
      <div
        css={{
          width: "2rem",
          alignSelf: "stretch",
          minHeight: "2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: isRead || isRead === undefined ? theme.colors.primaryLight : theme.colors.primary,
          color: isRead || isRead === undefined ? theme.colors.primary : "white",
        }}
      >
        {isRead || isRead === undefined ? <MdOutlineDrafts size={14} /> : <MdOutlineMarkEmailUnread size={14} />}
      </div>
      {isDesktop && (
        <div
          css={{
            padding: "12px",
            color: theme.colors.primary,
            fontSize: "0.875rem",
            fontWeight: isRead || isRead === undefined ? 400 : 700,
          }}
        >
          {isRead || isRead === undefined ? "Przeczytany wątek" : "Nieprzeczytany wątek"}
        </div>
      )}
    </button>
  );
};
