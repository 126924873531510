import { AmqpTopic } from "@megarax/amqp";
import { Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export type TradeRouteCreatedMessage = {
  uuid: Uuid;
};

export const tradeRouteCreatedEventMessageSerializer = MSerializers.object<TradeRouteCreatedMessage>({
  uuid: uuidSerializer,
});

export const tradeRouteCreatedEventMessageAmqpTopic: AmqpTopic<TradeRouteCreatedMessage> = {
  key: "crm.tradeRoute.created",
  serializer: tradeRouteCreatedEventMessageSerializer,
};

export type TradeRouteScheduledMessage = {
  uuid: Uuid;
  salespersonId: number;
  date: Date;
};

export const tradeRouteScheduledEventMessageSerializer = MSerializers.object<TradeRouteScheduledMessage>({
  uuid: uuidSerializer,
  salespersonId: MSerializers.integer,
  date: MSerializers.dateOnly,
});
export const tradeRouteScheduledEventMessageAmqpTopic: AmqpTopic<TradeRouteScheduledMessage> = {
  key: "crm.tradeRoute.routeScheduled",
  serializer: tradeRouteScheduledEventMessageSerializer,
};

export type CustomerTradeRouteChanged = {
  uuid: Uuid;
};

export const customerTradeRouteChangedEventMessageSerializer = MSerializers.object<TradeRouteCreatedMessage>({
  uuid: uuidSerializer,
});

export const customerTradeRouteChangedEventMessageAmqpTopic: AmqpTopic<TradeRouteCreatedMessage> = {
  key: "crm.tradeRoute.customerTradeRouteChanged",
  serializer: customerTradeRouteChangedEventMessageSerializer,
};
