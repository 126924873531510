import Decimal from "decimal.js";

import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

export const blikCheckStatuses = [
  "pending",

  "outstanding", // 0
  "cashed", // 1
  "cancelled", // 2
  "expired", // 3
  "blocked", // 4
  "bankRejected", // 5
  "pspRejected", // 6
  "processing", // 7
] as const;

export type BlikCheckStatus = typeof blikCheckStatuses[number];

export const blikCheckStatusSerializer = Serializers.oneOf(...blikCheckStatuses);

export type BlikCheckFulfillmentDto = {
  type: "blikCheck";
  uuid: Uuid;
  referenceNumber: string;
  status: BlikCheckStatus;
  amount: Decimal;

  isFulfilled: boolean;
  isFailed: boolean;
};

export const blikCheckFulfillmentDtoSerializer = Serializers.object<BlikCheckFulfillmentDto>({
  type: Serializers.stringOneOf("blikCheck"),
  uuid: uuidSerializer,
  amount: Serializers.decimal,
  referenceNumber: Serializers.string,
  status: blikCheckStatusSerializer,
  isFulfilled: Serializers.boolean,
  isFailed: Serializers.boolean,
});

export type MyBlikCheckFulfillmentDto = {
  type: "blikCheck";
  uuid: Uuid;
  referenceNumber: string;
  status: BlikCheckStatus;
  amount: Decimal;
  password: string | null;
  recipientPhoneNumber: string | null;

  isFulfilled: boolean;
  isFailed: boolean;
};

export const myBlikCheckFulfillmentDtoSerializer = Serializers.object<MyBlikCheckFulfillmentDto>({
  type: Serializers.stringOneOf("blikCheck"),
  uuid: uuidSerializer,
  amount: Serializers.decimal,
  referenceNumber: Serializers.string,
  status: blikCheckStatusSerializer,
  password: Serializers.string.nullable(),
  recipientPhoneNumber: Serializers.string.nullable(),
  isFulfilled: Serializers.boolean,
  isFailed: Serializers.boolean,
});

export const blikCheckActions = {
  syncBlikCheks: HttpAction({
    path: "/syncBlikChecks",
    method: "post",
    requiresAuth: true as const,
  }),
  myBlikCheck: HttpAction({
    path: "/myBlikChecks/uuid/:uuid",
    method: "get",
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
    }),
    valueSerializer: myBlikCheckFulfillmentDtoSerializer.nullable(),
    requiresAuth: true as const,
  }),
  cancelBlikCheck: HttpAction({
    path: "/myBlikChecks/uuid/:uuid/cancel",
    method: "post",
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
    }),
    errorSerializer: Serializers.stringOneOf("BlikCheckNotFound", "CheckNotCancellable", "BlikConnectionError"),
    requiresAuth: true as const,
  }),
};
