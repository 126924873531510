import { LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  loading: boolean;
}

export const LoadingBar: React.FC<Props> = ({ loading }) => {
  const classes = useStyles();
  return loading ? <LinearProgress /> : <div className={classes.dummy} />;
};

const useStyles = makeStyles({
  dummy: {
    height: "4px",
  },
});
