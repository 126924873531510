import "./styles.css";

import { Loader } from "@googlemaps/js-api-loader";
import React from "react";

import { getDefaultMapOptions } from "./defaultMapOptions";

interface Props {
  mapProps: (props: { map: google.maps.Map; google: Google }) => React.ReactChild;
}

const loader = new Loader({
  apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  version: "weekly",
  libraries: ["places"],
  language: "pl",
});

export type Google = typeof google;
export type LatLng = {
  lat: number;
  lng: number;
};

export const useGoogleLoader = (): { google: Google | undefined } => {
  const [googleState, setGoogleState] = React.useState<Google>();

  React.useEffect(() => {
    if (googleState) return;

    loader.load().then((google) => setGoogleState(google));
  }, []);

  return { google: googleState };
};

export const MapContainer: React.FC<Props> = ({ mapProps }) => {
  const { google } = useGoogleLoader();
  const [mapLoaded, setMapLoaded] = React.useState<boolean>(false);
  const mapRef = React.useRef<google.maps.Map>();

  const onInit = (ref: HTMLDivElement, google: Google) => {
    if (mapLoaded) return;
    mapRef.current = new google.maps.Map(ref, getDefaultMapOptions());
    setMapLoaded(true);
  };

  if (!google) return null;
  return (
    <>
      {mapLoaded && mapRef.current !== undefined && mapProps({ map: mapRef.current, google })}
      <div ref={(ref) => ref && onInit(ref, google)} style={{ width: "100%", height: "100%" }}></div>
    </>
  );
};
