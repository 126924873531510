import { useTheme } from "@emotion/react";
import { MdCheck, MdClose } from "react-icons/md";
import tinycolor from "tinycolor2";

import { useDeviceType } from "@megaron/dash-mq";

import { Button } from "./Button";

type Props = {
  selectedValue: boolean;
  onChange: (selectedValue: boolean) => void;
  label: string;
  trueLabel: string;
  falseLabel: string;
  fullWidth?: boolean;
  isDisabled?: boolean;
};

export const SwitchField: React.FC<Props> = ({
  selectedValue,
  onChange,
  label,
  trueLabel,
  falseLabel,
  fullWidth,
  isDisabled,
}) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const buttonStyles = {
    width: "50%",
    padding: "0.5rem",
    ":hover": {
      textDecoration: "none",
    },
  } as const;

  const buttonContentStyles = { display: "flex", gap: "0.25rem", alignItems: "center", fontSize: "0.875rem" } as const;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "0.25rem",
      }}
    >
      <label
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
        }}
      >
        {label}
      </label>
      <div css={{ display: "flex", width: isMobile || fullWidth ? "100%" : "20rem" }}>
        <Button
          isDisabled={isDisabled}
          onClick={() => onChange(true)}
          css={{
            ...buttonStyles,
            backgroundColor: !selectedValue ? theme.colors.primaryLight : theme.colors.primary,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <div
            css={{
              ...buttonContentStyles,
              fontWeight: !selectedValue ? 400 : 700,
              color: !selectedValue ? `${tinycolor(theme.colors.primary).setAlpha(0.7)}` : "white",
            }}
          >
            {selectedValue && <MdCheck size={14} />}
            {trueLabel}
          </div>
        </Button>
        <Button
          isDisabled={isDisabled}
          onClick={() => onChange(false)}
          css={{
            ...buttonStyles,
            backgroundColor: !selectedValue ? theme.colors.danger : `${tinycolor(theme.colors.danger).setAlpha(0.05)}`,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <div
            css={{
              ...buttonContentStyles,
              fontWeight: !selectedValue ? 700 : 400,
              color: !selectedValue ? "white" : `${tinycolor(theme.colors.danger).setAlpha(0.5)}`,
            }}
          >
            {!selectedValue && <MdClose size={14} />}
            {falseLabel}
          </div>
        </Button>
      </div>
    </div>
  );
};
