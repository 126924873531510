import { ChangeEvent, useEffect, useState, useRef } from "react";

interface PaginationOptions {
  perPage: number;
  allCount: number | undefined;
  initialPage?: number;
}

export const useDepPagination = (
  { perPage, allCount, initialPage }: PaginationOptions,
  deps: React.DependencyList,
) => {
  const isInit = useRef<boolean>(true);
  const [page, setPage] = useState<number>(initialPage ?? 0);

  const onChange = (e: ChangeEvent<unknown>, page: number) => setPage(page - 1);

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      return;
    }
    setPage(0);
  }, [JSON.stringify(deps)]);

  return {
    paginationProps: {
      onChange,
      count: Math.ceil((allCount ?? 0) / perPage),
      page: (page ?? 0) + 1,
    },
    page: page ?? 0,
    setPage,
  };
};
