import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { decodeBase64 } from "@megaron/utils";
import { uuidSerializer } from "@megaron/uuid";

export const megaraxIdTokenPayloadSerializer = Serializers.object({
  userType: Serializers.stringOneOf("megarax"),
  firstName: Serializers.string,
  lastName: Serializers.string,
  profilePictureUrl: Serializers.string,
  email: Serializers.string,
  groups: Serializers.array(Serializers.object({ id: Serializers.string, name: Serializers.string })),
  roles: Serializers.string.array(),
  exp: Serializers.integer,
  iat: Serializers.integer,
});

export const loyaltyIdTokenPayloadSerializer = Serializers.object({
  userType: Serializers.stringOneOf("loyalty"),
  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  exp: Serializers.integer,
  iat: Serializers.integer,
});

export const idTokenPayloadSerializer = Serializers.first(
  megaraxIdTokenPayloadSerializer,
  loyaltyIdTokenPayloadSerializer,
);

export type MegaraxIdTokenPayload = SerializerValue<typeof megaraxIdTokenPayloadSerializer>;
export type LoyaltyIdTokenPayload = SerializerValue<typeof loyaltyIdTokenPayloadSerializer>;
export type IdTokenPayload = MegaraxIdTokenPayload | LoyaltyIdTokenPayload;

export const authActions = {
  signInWithGoogle: HttpAction({
    path: "/signInWithGoogle",
    method: "post",
    bodySerializer: Serializers.object({
      googleIdToken: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("InvalidToken", "AccountNotFound", "AccountInactive"),
    valueSerializer: Serializers.object({
      tokenPayload: idTokenPayloadSerializer,
      idToken: Serializers.string,
    }),
  }),

  signInWithApiKey: HttpAction({
    path: "/signInWithApiKey",
    method: "post",
    bodySerializer: Serializers.object({
      apiKey: Serializers.string,
    }),
    valueSerializer: Serializers.object({
      tokenPayload: idTokenPayloadSerializer,
      idToken: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("InvalidApiKey", "AccountInactive", "AccountNotFound"),
  }),
};

export const decodeIdToken = (idToken: string): IdTokenPayload => {
  const payloadB64 = idToken.split(".")[1];
  const payloadJsonStr = decodeBase64(payloadB64);
  const payloadJson = JSON.parse(payloadJsonStr);

  return idTokenPayloadSerializer.deserialize(payloadJson).assertOk().value;
};
