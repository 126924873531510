import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { BoolFilter, useQsFilters } from "@megaron/dash-filters";
import { Button } from "@megaron/dash-form";
import { Page, PageHeader } from "@megaron/dash-page";
import { SearchWithPaginationSticky } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { BudgetDoc } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Serializers } from "@megaron/serializers";

import { parseBooleanFieldOptionToBoolean, parseBooleanToBooleanFieldOption } from "../invoiceThread";
import { BudgetList } from "./BudgetList";

const filtersSerializer = Serializers.object({
  page: Serializers.integer,
  searchText: Serializers.string,
  isArchived: Serializers.booleanFieldFilter,
});

export const BudgetsHome = () => {
  const { filters, setFilter } = useQsFilters(filtersSerializer, {
    page: 0,
    searchText: "",
    isArchived: "any",
  });

  const navigate = useNavigate();

  const pageSize = 30;

  const searchQuery = useClientManager("docs")
    .searchAnyDoc()
    .useQuery({
      docType: ["budget"],
      offset: filters.page * pageSize,
      limit: pageSize,
      searchText: filters.searchText,
      isArchived: filters.isArchived,
    });

  return (
    <Page
      css={{
        maxWidth: "1600px",
      }}
    >
      <PageHeader
        actions={
          <Button icon={<MdAdd />} onClick={() => navigate("/faktury/budzety/nowy")}>
            Dodaj
          </Button>
        }
      >
        Budżety
      </PageHeader>

      <SearchWithPaginationSticky
        onSearchChange={setFilter("searchText")}
        searchValue={filters.searchText}
        page={filters.page}
        onPageChange={setFilter("page")}
        pageSize={pageSize}
        itemCount={searchQuery.data?.count}
        css={{
          marginBottom: "1em",
        }}
      />

      <div css={{ marginBottom: "1rem" }}>
        <BoolFilter
          label={"Jest aktywny?"}
          value={parseBooleanFieldOptionToBoolean(filters.isArchived, true)}
          onChange={(value) => setFilter("isArchived")(parseBooleanToBooleanFieldOption(value, true))}
          falseLabel="Nie"
          trueLabel="Tak"
        />
      </div>

      <QuerySkeleton query={searchQuery}>
        {(result) => <BudgetList budgets={result.items as BudgetDoc[]} />}
      </QuerySkeleton>
    </Page>
  );
};
