import { useTheme } from "@emotion/react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { useDeviceType } from "@megaron/dash-mq";

import { PageHeader } from "./PageHeader";

type Props = {
  actions?: React.ReactNode;
  header: string;
  className?: string;
};

export const HeaderBar: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { isDesktop } = useDeviceType();
  const { isMobile } = useDeviceType();
  const navigate = useNavigate();
  const isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;

  return (
    <div
      css={{
        maxWidth: theme.siteMaxWidth,
        padding: isMobile ? 0 : "0 2rem",
        position: "fixed",
        width: isDesktop ? (isFirefox ? "-moz-available" : "-webkit-fill-available") : "100%",
        zIndex: 999,
      }}
    >
      <div
        css={{
          display: "flex",
          gap: "1rem",
          width: "100%",
          alignItems: "center",
          background: `linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%), ${theme.colors.primary}`,
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
          color: "white",
          padding: "0 16px",
          borderBottomLeftRadius: isMobile ? 0 : theme.smallBorderRadius,
          borderBottomRightRadius: isMobile ? 0 : theme.smallBorderRadius,
        }}
        className={props.className}
      >
        <button
          onClick={() => navigate(-1)}
          css={{
            display: "flex",
            background: "rgba(255, 255, 255, 0.15)",
            padding: "6px",
            borderRadius: "50%",
            cursor: "pointer",
            border: "none",
          }}
        >
          <MdArrowBack color={"white"} />
        </button>

        <div css={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <PageHeader
            css={{
              maxWidth: "calc(100% - 80px)",
              h2: {
                fontSize: "1rem",
                color: "white",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
          >
            {props.header}
          </PageHeader>
          {props.actions}
        </div>
      </div>
    </div>
  );
};
