import React from "react";
import {
  generatePath,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom-v5-compat";

type DialogProps = {
  onClose: () => void;
};

export const useDialogRoute = <TParams extends {}>(
  pathFragment: string,
  renderDialog: (props: DialogProps) => React.ReactNode,
) => {
  const navigate = useNavigate();
  const url = useResolvedPath("").pathname;
  const safeToGoBack = React.useRef(false);
  const location = useLocation();

  type Open = [keyof TParams] extends [never] ? () => void : (params: TParams) => void;

  const open: Open = (params?: TParams) => {
    const path = generatePath(`${url}/${pathFragment}`, params ?? {});
    navigate(path + location.search, {});
    safeToGoBack.current = true;
  };

  const close = () => {
    if (safeToGoBack.current) {
      navigate(-1);
      safeToGoBack.current = false;
    } else {
      navigate(url, { replace: true });
    }
  };

  const isOpen = useMatch(`${url}/${pathFragment}`)?.pathname === `${url}/${pathFragment}`;

  const element = (
    <Routes>
      <Route path={`${pathFragment}`} element={renderDialog({ onClose: close })} />
    </Routes>
  );
  return { open, close, element, isOpen };
};
