import { RegionSimple, SalespersonSimple } from "@megarax/crm-contracts";
import { UserSimple } from "@megarax/iam-contracts";
import { ProfilesDictionary } from "@megarax/iam-webapp";
import { LoadingBar, NewItemFab, SearchBar } from "@megarax/ui-components";
import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

interface Props {
  query: {
    loading: boolean;
    items: SalespersonSimple[] | undefined;
  };
  search: {
    onSearchChange: (search: string) => void;
    value: string;
  };
  profilesDictionary: ProfilesDictionary | undefined;
  regionsDictionary: _.Dictionary<RegionSimple> | undefined;
  allUsersDictionary: _.Dictionary<UserSimple> | undefined;
  navigation: {
    openAddSalespersonDialog: () => void;
    navigateToDetails: (userId: number) => void;
    navigateToPlan: (userId: number) => void;
  };
  onOpenMilageReport: () => void;
}

export const SalespeopleHome: React.FC<Props> = ({
  navigation,
  search,
  query,
  profilesDictionary,
  regionsDictionary,
  onOpenMilageReport,
  allUsersDictionary,
}) => {
  const ready = profilesDictionary && regionsDictionary && allUsersDictionary && query.items;

  const salespeopleToDisplay = React.useMemo(() => {
    if (!ready) return undefined;
    return query?.items?.filter((item) => {
      if (allUsersDictionary[item.userId]?.isDeleted === true) return false;
      const searchText = search.value.toLocaleLowerCase();
      if (searchText === "") return true;
      const region = regionsDictionary[item.regionUuid]?.name.toLocaleLowerCase() ?? "";
      const username = profilesDictionary[item.userId]?.displayedName.toLocaleLowerCase() ?? "";
      return region.includes(searchText) || username.includes(searchText);
    });
  }, [profilesDictionary, regionsDictionary, allUsersDictionary, query.items, search.value]);

  return (
    <>
      <LoadingBar loading={query.loading} />
      {ready && salespeopleToDisplay !== undefined && (
        <Container maxWidth="md" disableGutters>
          <Box display="flex" justifyContent="space-between" py={2} alignItems="center" px={1}>
            <SearchBar onSearchChange={search.onSearchChange} initialValue={search.value} />
            <Box display="flex">
              <Button variant="outlined" onClick={onOpenMilageReport} sx={{ mr: 2 }}>
                Kilometry
              </Button>
              <NewItemFab onClick={navigation.openAddSalespersonDialog} text="Nowy" />
            </Box>
          </Box>
          <Box>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="50%">Handlowiec</TableCell>
                  <TableCell width="50%">Region</TableCell>
                  <TableCell width="50px" />
                </TableRow>
              </TableHead>
              <TableBody>
                {salespeopleToDisplay.map((salesperson) => {
                  return (
                    <TableRow key={salesperson.userId} sx={{ cursor: "pointer" }}>
                      <TableCell width="50%" onClick={() => navigation.navigateToDetails(salesperson.userId)}>
                        {profilesDictionary[salesperson.userId]?.displayedName ?? "Nieznany"}
                      </TableCell>
                      <TableCell width="50%" onClick={() => navigation.navigateToDetails(salesperson.userId)}>
                        {regionsDictionary[salesperson.regionUuid]?.name ?? "Nieznany"}
                      </TableCell>
                      <TableCell width="50px">
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => navigation.navigateToPlan(salesperson.userId)}
                        >
                          Wizyty
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Container>
      )}
    </>
  );
};
