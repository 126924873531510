import { formatISO, parseISO } from "date-fns";

import { Failure, Ok } from "@megaron/result";

import { serializerExtensions, SerializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export const dateOnly: Serializer<Date> & SerializerExtensions<Date> = {
  serialize: (value) => formatISO(value, { representation: "date" }),
  deserialize: (raw) => {
    const date = parseISO(raw as any);
    if (isNaN(date.getTime())) return Failure("InvalidDate");

    return Ok(date);
  },
  ...serializerExtensions(),
};

export const datetime: Serializer<Date> & SerializerExtensions<Date> = {
  serialize: (value) => value.toISOString(),
  deserialize: (raw) => {
    const date = new Date(raw as any);
    if (isNaN(date.getTime())) return Failure("InvalidDate");

    return Ok(date);
  },
  ...serializerExtensions(),
};
