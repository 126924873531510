import { GtinResourceParam, gtinSerializer } from "../../common";
import { idObjectReferenceSerializer, NestedResourceNode, ResourceAction } from "@megarax/rest-resource";
import { MSerializers, Serializers } from "@megarax/serializers";

import { PriceListProposalDto } from "../read/detail";
import { priceListChangeDtoSerializer } from "./priceListChangeDtoSerializer";

const priceListProposalDtoSerializer = MSerializers.object<PriceListProposalDto>({
  uuid: MSerializers.uuid(),
  activeFrom: MSerializers.datetime.nullable(),
  comment: MSerializers.string,
  changes: MSerializers.array(
    MSerializers.object({
      gtin: gtinSerializer,
      netPrice: MSerializers.decimal.nullable(),
    }),
  ),
  proposedAt: MSerializers.datetime,
  proposedBy: idObjectReferenceSerializer,
});

const priceListRejectedChangeDtoSerializer = MSerializers.object({
  uuid: MSerializers.uuid(),
  activeFrom: MSerializers.datetime.nullable(),
  comment: MSerializers.string,
  gtin: gtinSerializer,
  netPrice: MSerializers.decimal.nullable(),
  proposedAt: MSerializers.datetime,
  proposedBy: idObjectReferenceSerializer,
  rejectedAt: MSerializers.datetime,
  rejectedBy: idObjectReferenceSerializer,
});

const priceListItemDetailSerializer = MSerializers.object({
  gtin: gtinSerializer,
  current: MSerializers.object({
    netPrice: MSerializers.decimal.nullable(),
    activeFrom: MSerializers.datetime,
  }).nullable(),
  allChanges: MSerializers.array(priceListChangeDtoSerializer),
  proposals: MSerializers.array(priceListProposalDtoSerializer),
  rejectedChanges: MSerializers.array(priceListRejectedChangeDtoSerializer),
});

export default NestedResourceNode("items", {
  byGtin: GtinResourceParam("gtin", "gtin", {
    retrieve: ResourceAction({
      name: "priceListItemDetail",
      method: "get",
      path: "",
      responseSerializer: MSerializers.result(
        priceListItemDetailSerializer,
        Serializers.stringOneOf("PriceListNotFound"),
      ),
    }),
  }),
});
