import { AmqpTopic } from "@megarax/amqp";
import { Serializers } from "@megarax/serializers";

export type PayerProposalStatusDto = {
  customers: {
    uuid: string;
    awaitingProposalCount: number;
  }[];
};

export type PayerProposalStatusEventMessage = {
  changeType: "proposalCountIncreased" | "proposalCountDecreased";
  status: PayerProposalStatusDto;
};

export const payerProposalStatusSerializer = Serializers.object<PayerProposalStatusDto>({
  customers: Serializers.array(
    Serializers.object({
      awaitingProposalCount: Serializers.integer,
      uuid: Serializers.uuid(),
    }),
  ),
});
export const payerProposalStatusEventAmqpTopic: AmqpTopic<PayerProposalStatusEventMessage> = {
  key: "pricing.payer.proposalStatusEvent",
  serializer: Serializers.object<PayerProposalStatusEventMessage>({
    changeType: Serializers.identity(),
    status: payerProposalStatusSerializer,
  }),
};

export type PayerProposalApprovedEventMessage = {
  type: "proposalApproved";
  customerUuid: string;
  proposalUuid: string;
};

export type RuleChangeProposedEventMessage = {
  type: "ruleChangeProposed";
  customerUuid: string;
  proposalUuid: string;
};

export type PayerProposalRetractedEventMessage = {
  type: "proposalRetracted";
  customerUuid: string;
  proposalUuid: string;
};

export type PayerProposalRejectedEventMessage = {
  type: "proposalRejected";
  customerUuid: string;
  proposalUuid: string;
};

export type PayerEventMessage =
  | PayerProposalApprovedEventMessage
  | RuleChangeProposedEventMessage
  | PayerProposalRetractedEventMessage
  | PayerProposalRejectedEventMessage;

export const payerEventMessageSerializer = Serializers.object<PayerEventMessage>({
  customerUuid: Serializers.uuid(),
  proposalUuid: Serializers.uuid(),
  type: Serializers.stringOneOf(
    "proposalApproved",
    "ruleChangeProposed",
    "proposalRetracted",
    "proposalRejected",
  ) as any,
});

export const payerEventAmqpTopic: AmqpTopic<PayerEventMessage> = {
  key: "pricing.payer.payerEvent",
  serializer: payerEventMessageSerializer,
};
