import { useTheme } from "@emotion/react";
import React from "react";
import { MdAdd, MdEdit, MdStorefront } from "react-icons/md";
import { useQueryClient } from "react-query";

import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { SectionHeader } from "@megaron/dash-page";
import { AnyDoc, getDocId } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { EditPosDialog } from "./EditPosDialog";

type Props = {
  pos: string[];
  queryKey: string | string[];
  customerId: Uuid;
};

export const PosSection: React.FC<Props> = (props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const posQuery = useClientManager("docs")
    .retrieveDocs()
    .useQuery({ docIds: props.pos.map((poc) => getDocId("pos-customer", poc)) });

  const posList =
    posQuery.data
      ?.filter((p): p is Extract<AnyDoc, { docType: "pos-customer" }> => p?.docType === "pos-customer")
      .map((poc) => {
        return poc;
      }) ?? [];

  const dialog = useDialogRoute("/edit-pos", ({ onClose }) => (
    <EditPosDialog
      customerId={props.customerId}
      values={props.pos}
      onClose={onClose}
      onSuccess={function (): void {
        queryClient.invalidateQueries(props.queryKey);
      }}
    />
  ));

  return (
    <>
      <SectionHeader
        isHr
        actions={
          <Button icon={<MdEdit />} onClick={dialog.open} variant="outline">
            Edytuj
          </Button>
        }
        css={{ marginTop: "0" }}
      >
        Powiązani klienci
      </SectionHeader>
      {posList.length ? (
        <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.75rem" }}>
          {posList.map((pos) => (
            <div
              key={pos.uuid}
              css={{
                display: "flex",
                height: "45px",
                alignItems: "center",
                borderRadius: theme.smallBorderRadius,
                overflow: "hidden",
                backgroundColor: "white",
                borderColor: theme.colors.border,
                cursor: "pointer",
              }}
              onClick={() => window.open(`/crm/pos/klienci/${pos.uuid}`, "_blank")}
            >
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  background: theme.colors.primary,
                  color: "white",
                  alignItems: "center",
                  height: "100%",
                  padding: "6px 12px",
                  fontSize: "13px",
                  justifyContent: "center",
                }}
              >
                <MdStorefront />
                POS
              </div>
              <span css={{ color: theme.colors.primary, paddingLeft: "15px" }}>{pos.name}</span>
            </div>
          ))}
        </div>
      ) : (
        <div
          onClick={dialog.open}
          css={{
            border: `1px dashed ${theme.colors.border}`,
            color: theme.colors.primary,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            height: "45px",
            cursor: "pointer",
            borderRadius: theme.smallBorderRadius,
          }}
        >
          <MdAdd />
          Dodaj
        </div>
      )}

      {dialog.element}
    </>
  );
};
