import { useTheme } from "@emotion/react";
import Papa from "papaparse";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { AvatarGroup } from "@megaron/dash-avatar";
import { Button, FileDrop, TextField } from "@megaron/dash-form";
import { SelectDialog } from "@megaron/dash-select";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";
import { Email, PhoneNumber } from "@megaron/serializers";
import { sleep } from "@megaron/utils";
import { newUuid } from "@megaron/uuid";

type Props = {
  onCustomerListSuccess: () => void;
};

export const AddCustomerBody: React.FC<Props> = (props) => {
  const theme = useTheme();
  const toast = useToast();
  const navigate = useNavigate();

  const preregisterMut = useClientManager("loyaltyAccounts").findAccount().useMutation();
  const addCustomerMut = useClientManager("crm").addCustomer().useMutation();
  const addCustomersFromFileMut = useClientManager("crm").addCustomersFromList().useMutation();
  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});

  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>("+48" as PhoneNumber);
  const [region, setRegion] = useState<string | null>(null);
  const [origin, setOrigin] = useState<string | null>(null);

  const handleInputChange = (value: PhoneNumber) => {
    const filteredValue = value.replace(/[^0-9+]/g, "") as PhoneNumber;
    setPhoneNumber(filteredValue);
  };

  const handleFileChange = async (data: string | null, error: string | null) => {
    if (error) {
      console.error("Error occurred while handling file change:", error);
      toast.error("Error", "Failed to handle file change");
      return;
    }

    if (!data) {
      console.error("No data received while handling file change");
      return;
    }

    const file = new Blob([data], { type: "text/csv" });
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result as string;
      Papa.parse(text, {
        header: true,
        complete: (results) => {
          const parsedData: {
            phoneNumber: PhoneNumber;
            firstName: string | null;
            lastName: string | null;
            email: Email | null;
            region: string | null;
          }[] = results.data.map((item: any) => ({
            phoneNumber:
              getPropertyCaseInsensitive(item, "telefon") ?? getPropertyCaseInsensitive(item, "numer telefonu"),
            firstName: getPropertyCaseInsensitive(item, "imie"),
            lastName: getPropertyCaseInsensitive(item, "nazwisko"),
            email:
              getPropertyCaseInsensitive(item, "email") ??
              getPropertyCaseInsensitive(item, "mail") ??
              getPropertyCaseInsensitive(item, "adres email"),
            region: getPropertyCaseInsensitive(item, "region"),
          }));

          addCustomersFromFileMut.mutate(parsedData, {
            onSuccess: () => {
              toast.info("Success", "Customers added successfully");
              props.onCustomerListSuccess();
            },
            onError: (error) => {
              console.error("Error adding customers from file:", error);
              toast.error("Error", "Failed to add customers from file");
            },
          });
        },
      });
    };

    reader.readAsText(file);
  };

  function getPropertyCaseInsensitive(obj: any, key: string): any {
    const variations = [key, key.toLowerCase(), key.toUpperCase(), key.replace(/^\w/, (c) => c.toUpperCase())];
    for (const variant of variations) {
      if (obj.hasOwnProperty(variant)) {
        return obj[variant];
      }
    }
    return null;
  }

  const addCustomer = async () => {
    const account = await preregisterMut.mutateAsync({
      phoneNumber,
      preregister: true,
    });

    // Wait for the preregistration to be handled by CRM
    if (account.isNew) await sleep(1500);

    await addCustomerMut.mutateAsync(
      { region, uuid: account.uuid },
      {
        onSuccess: (r) => {
          navigate(`/crm/customers/id/${account.uuid}`);
        },
        onError: (e) => {
          if (e === "CustomerAlreadyExists") navigate(`/crm/customers/id/${account.uuid}`);
          else toast.error(e);
        },
      },
    );
  };

  const options = regionsQuery.data
    ? regionsQuery.data.items.map((region) => ({
        label: region.name,
        value: region.name,
      }))
    : [];

  const customerOrigin = [
    "szkolenie",
    "social_media",
    "hurtownia",
    "polecenia",
    "baza_specjalista",
    "testy",
    "sucha_zabudowa",
  ].map((o) => ({
    label: o,
    value: o,
  }));

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <TextField
        css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
        label={"Nr telefonu"}
        value={phoneNumber}
        onChange={(value: string) => handleInputChange(value as PhoneNumber)}
        autoFocus
      />

      {!region ? (
        <div css={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <SelectDialog
            label="Region"
            options={options}
            onSelect={(option) => setRegion(option?.value || null)}
            initiallySelectedValues={region ? [region] : []}
            variant="single-select"
          />
        </div>
      ) : (
        <div>
          <span
            css={{
              fontFamily: theme.displayFontFamily,
              fontSize: "14px",
              color: theme.colors.primary,
            }}
          >
            Region
          </span>
          <div
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: theme.colors.primaryLight,
              padding: "8px 12px",
              borderRadius: theme.smallBorderRadius,
            }}
          >
            <div css={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <AvatarGroup profiles={[]} />
              {region}
            </div>

            <MdClose cursor="pointer" color={theme.colors.primary} onClick={() => setRegion(null)} />
          </div>
        </div>
      )}

      {/* <SelectDialog
        options={customerOrigin}
        onSelect={(option) => setOrigin(option?.value || null)}
        initiallySelectedValues={origin ? [origin] : []}
        label="Pochodzenie"
        variant="single-select"
      />
      <FileDrop label="Lista klientów" onFileChange={handleFileChange} allowedExtensions={["csv"]} error={null} /> */}
      <Button css={{ alignSelf: "flex-end", margin: "0.25rem 0" }} onClick={addCustomer}>
        Dalej
      </Button>
    </div>
  );
};
