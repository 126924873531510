import { Uuid } from "@megarax/common";
import { ChecklistTemplateQueryFilters, checklistTemplateResource, SaveTemplateInput } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Failure, Ok, Result } from "@megaron/result";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

export type OnSuccessFunction = (() => void) | (() => Promise<void>);

const voidifyResult = <TValue, TError>(result: Result<TValue, TError>): Result<void, void> =>
  result.flatMapError(() => Failure()).flatMap(() => Ok());

export const useChecklistResource = () => {
  const checklistsProvider = useResourceProviderV2(checklistTemplateResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const saveChecklist =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, input: SaveTemplateInput) =>
      checklistsProvider.byUuid(uuid).save(undefined, input).mapError(handleError).map(onSuccess).then(voidifyResult);

  const getChecklists = (filters: ChecklistTemplateQueryFilters) =>
    checklistsProvider.list(filters).mapError(handleError);

  const retrieveChecklist = (uuid: Uuid) => checklistsProvider.byUuid(uuid).retrieve().mapError(handleError);

  return {
    retrieveChecklist,
    getChecklists,
    saveChecklist,
  };
};
