import { useTheme } from "@emotion/react";

type Props = {
  text: string;
  icon: React.ReactNode;
};

export const TabHeader: React.FC<Props> = ({ text, icon }) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
      <div
        css={{
          display: "flex",
          gap: "0.375rem",
          alignItems: "center",
          fontSize: "12px",
          color: "white",
          background: theme.colors.primary,
          borderRadius: theme.smallBorderRadius,
          borderBottomRightRadius: 0,
          padding: "0.5rem 0.75rem",
          fontWeight: 700,
        }}
      >
        {icon}
        {text}
      </div>
      <hr
        css={{
          width: "100%",
          border: "none",
          borderBottom: `1px solid ${theme.colors.primary}`,
          background: "none",
          height: 0,
          margin: 0,
        }}
      ></hr>
    </div>
  );
};
