import { Link, useParams } from "react-router-dom-v5-compat";

import { DescriptionField, DescriptionList } from "@megaron/dash-description-list";
import { Divider, Page, PageHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { OrderList } from "@megaron/loyalty-orders-admin-webapp";
import { useClientManager } from "@megaron/react-clients";
import { Uuid, uuidSerializer } from "@megaron/uuid";

import { AccountDescriptionList } from "./AccountDescriptionList";

export const getUserPath = (uuid: Uuid) => `/specjalista/users/id/${uuid}`;

export const UserPage = () => {
  const userId = uuidSerializer.deserialize(useParams<{ userId: string }>().userId).value;

  const accountsAccountQuery = useClientManager("loyaltyAccounts")
    .account()
    .useQuery({ uuid: userId! }, { enabled: userId !== undefined });

  const pointsAccountQuery = useClientManager("loyaltyPoints")
    .account()
    .useQuery({ uuid: userId! }, { enabled: userId !== undefined });

  return (
    <Page css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <PageHeader>{`Użytkownik ${userId ?? ""}`}</PageHeader>
      <QuerySkeleton query={accountsAccountQuery} height="90px">
        {(account) => <AccountDescriptionList account={account} />}
      </QuerySkeleton>
      <Divider />
      <QuerySkeleton query={pointsAccountQuery} height="90px">
        {(account) => (
          <DescriptionList>
            <DescriptionField label="Stan konta">{account.balance.toFixed(1)} pkt</DescriptionField>
            <DescriptionField label="Status">{getStatusText(account)}</DescriptionField>
            <DescriptionField label="Nr konta PRO">{account.proNumber}</DescriptionField>
            <DescriptionField label="Liczba skanów">{account.lifetimeCodeCount}</DescriptionField>
            <DescriptionField label="Prowizja">{account.affiliateCommissionRate.mul(100).toFixed(0)}%</DescriptionField>
            <DescriptionField label="Zaproszony przez">
              {account.parentAffiliateUuid && (
                <Link to={getUserPath(account.parentAffiliateUuid)}>{account.parentAffiliateUuid}</Link>
              )}
            </DescriptionField>
          </DescriptionList>
        )}
      </QuerySkeleton>
      <Divider />
      {userId && <OrderList userUuid={userId} />}
    </Page>
  );
};

const getStatusText = (acc: { isAffiliate: boolean; isPro: boolean }) => {
  if (acc.isAffiliate && acc.isPro) return "Partner, PRO";
  if (acc.isAffiliate) return "Partner";
  if (acc.isPro) return "PRO";

  return null;
};
