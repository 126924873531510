import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const commentMentionSerializer = Serializers.object({
  user: Serializers.string,
  tagPosition: Serializers.integer,
  tagLength: Serializers.integer,
});

export type CommentMention = SerializerValue<typeof commentMentionSerializer>;

export const threadHistoryEntrySerializer = Serializers.first(
  Serializers.object({
    entryType: Serializers.stringOneOf("threadOpened"),
    time: Serializers.datetime,
    actingUser: Serializers.string,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("validitySignatureAdded"),
    signature: uuidSerializer,
    time: Serializers.datetime,
    actingUser: Serializers.string,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("validitySignatureRetracted"),
    signature: uuidSerializer,
    time: Serializers.datetime,
    actingUser: Serializers.string,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("threadApproved"),
    time: Serializers.datetime,
    actingUser: Serializers.string,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("approvalRevoked"),
    time: Serializers.datetime,
    actingUser: Serializers.string,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("threadClosed"),
    time: Serializers.datetime,
    actingUser: Serializers.string,
    reason: Serializers.string,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("threadReopened"),
    time: Serializers.datetime,
    actingUser: Serializers.string,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("comment"),
    time: Serializers.datetime,
    actingUser: Serializers.string,
    message: Serializers.string,
    mentions: commentMentionSerializer.array(),
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("budgetSignatureAdded"),
    signature: uuidSerializer,
    time: Serializers.datetime,
    actingUser: Serializers.string,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("budgetSignatureRetracted"),
    signature: uuidSerializer,
    actingUser: Serializers.string,
    time: Serializers.datetime,
  }),
  Serializers.object({
    actingUser: Serializers.string,
    entryType: Serializers.stringOneOf("receptionStatusSet"),
    status: Serializers.boolean,
    time: Serializers.datetime,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("invoiceUpdated"),
    time: Serializers.datetime,
    actingUser: Serializers.string,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("decreeNumberSet"),
    actingUser: Serializers.string,
    time: Serializers.datetime,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("threadRenamed"),
    actingUser: Serializers.string,
    newTitle: Serializers.string,
    time: Serializers.datetime,
  }),
  Serializers.object({
    entryType: Serializers.stringOneOf("invoiceAddedToBc"),
    actingUser: Serializers.string,
    time: Serializers.datetime,
    bcInvoiceId: Serializers.string.optional(),
    bcInvoiceNumber: Serializers.string.optional(),
  }),
);

export type ThreadHistoryEntry = SerializerValue<typeof threadHistoryEntrySerializer>;
