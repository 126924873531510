import { Uuid } from "@megarax/common";
import React from "react";
import { generatePath, matchPath, useLocation, useNavigate } from "react-router-dom-v5-compat";

export const useVisitNavigation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const safeToGoBack = React.useRef(false);

  const openVisit = (uuid: Uuid) => {
    const path = generatePath(`${pathname}/wizyta/:uuid`, { uuid });
    navigate(path);
    safeToGoBack.current = true;
  };

  const closeVisit = () => {
    if (safeToGoBack.current) {
      navigate(-1);
      safeToGoBack.current = false;
    } else {
      navigate(pathname, { replace: true });
    }
  };

  return {
    openVisit,
    closeVisit,
  };
};

export const useOnVisitClose = (onClose: () => void) => {
  const { pathname } = useLocation();
  const isOpen =
    matchPath(
      {
        path: `${pathname}/wizyta/:uuid`,
      },
      pathname,
    ) !== null;
  const wasOpen = React.useRef(isOpen);

  React.useEffect(() => {
    if (!isOpen && wasOpen.current) onClose();

    wasOpen.current = isOpen;
  }, [pathname]);
};
