import { useTheme } from "@emotion/react";
import React from "react";

type Props = {
  color?: string;
  className?: string;
  children?: React.ReactNode;
};

export const TileStripe: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const color = props.color ?? theme.colors.primary;

  return (
    <div
      css={{
        maxHeight: "100%",
        padding: "0.25rem",
        background: color,
        flexShrink: "0",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.children}
    </div>
  );
};
