import { useContext } from "react";

import { MegaraxHttpClient } from "@megarax/http-client";
import {
  BaseResource,
  HttpResourceProvider,
  LegacyHttpClient,
  ResourceNodes,
  ResourceProvider,
} from "@megarax/rest-resource";
import { Result } from "@megaron/result";

import { FeedbackSnackbarContext } from "../../ui-components/src";
import { getMessageForCode } from "./errorsToMessagesMap";
import { MegaraxClientContext } from "./megaraxClientContext";

const WithFailureHandler =
  (handler: (err: any) => any) =>
  (megaraxClient: MegaraxHttpClient): MegaraxHttpClient => {
    const handleFailure = (result: Result<any, any>) => {
      if (result.isFailure) handler(result.error);

      return result;
    };

    return {
      get: (...args) => megaraxClient.get(...args).then(handleFailure),
      post: (...args) => megaraxClient.post(...args).then(handleFailure),
      put: (...args) => megaraxClient.put(...args).then(handleFailure),
      delete: (...args) => megaraxClient.delete(...args).then(handleFailure),
    };
  };

export const useResourceProvider = <T extends ResourceNodes>(resource: BaseResource<T>): ResourceProvider<T> => {
  const megaraxClient = useContext(MegaraxClientContext);
  const snackbarContext = useContext(FeedbackSnackbarContext);

  if (!megaraxClient) throw Error("Resource provider must be used within Megarax Client context.");

  const clientWithSnackbarFailureHandler = WithFailureHandler((err) => {
    snackbarContext.pushMessage({
      severity: "error",
      content: getMessageForCode(err),
    });
  })(megaraxClient);

  const unwrapResponse = (r: Result<any, any>) => {
    if (r.isFailure) throw new Error();
    return r.value;
  };

  const httpClient: LegacyHttpClient = {
    get: (...args) => clientWithSnackbarFailureHandler.get(...args).then(unwrapResponse),
    post: (...args) => clientWithSnackbarFailureHandler.post(...args).then(unwrapResponse),
    put: (...args) => clientWithSnackbarFailureHandler.put(...args).then(unwrapResponse),
    delete: (...args) => clientWithSnackbarFailureHandler.delete(...args).then(unwrapResponse),
  };

  return HttpResourceProvider(httpClient)(resource);
};
