import { useTheme } from "@emotion/react";
import React from "react";
import tinycolor from "tinycolor2";

type Props = {
  children?: React.ReactNode;
  className?: string;
  stripe?: React.ReactNode;
};

export const Tile: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <div
      className={props.className}
      css={{
        borderRadius: theme.smallBorderRadius,
        background: "white",
        display: "flex",
        flexDirection: "row",
        justifyItems: "stretch",
        overflow: "hidden",
        maxWidth: "100%",
        height: "100%",
        border: `1px solid ${tinycolor("#000000").setAlpha(0.1)}`,
      }}
    >
      {props.stripe}
      <div
        css={{
          minWidth: "0",
          padding: "0.5rem 0.75rem",
          display: "flex",
          flex: "1 0 0",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
