import React, { useState } from "react";

import { DocsSelectDialog } from "@megaron/dash-select";
import { getDocId } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  values?: string[];
  customerId: Uuid;
};

export const EditPosDialog: React.FC<Props> = (props) => {
  const [selectedPOS, setSelectedPOS] = useState<string[]>(props.values ?? []);

  const savePos = useClientManager("crm").saveCustomerPos().useMutation();

  const saveCustomerPos = (posUuidList: string[]) => {
    savePos.mutate(
      {
        posUuidList,
        customerId: props.customerId,
      },
      {
        onSuccess: (r) => {
          props.onSuccess();
        },
      },
    );
  };

  const handlePosSelection = (posList: string[]) => {
    setSelectedPOS(posList);
    saveCustomerPos(posList);
  };

  return (
    <DocsSelectDialog
      variant="multi-select"
      noTrigger
      initiallySelectedDocIds={selectedPOS.map((id) => getDocId("pos-customer", id))}
      onClose={props.onClose}
      docType={["pos-customer"]}
      onSelectedChange={(posList) => handlePosSelection(posList.map((pos) => pos.value))}
      isOpen
      shouldNotCloseOnSelect
    />
  );
};
