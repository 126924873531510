import { TextField } from "@mui/material";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import React from "react";

interface Props extends Partial<DateTimePickerProps<Date | null>> {
  value: Date | null;
  onChange: (newValue: Date | null) => void;
  error?: string | boolean;
  helperText?: string;
  fullWidth?: boolean;
  inputVariant?: "outlined" | "filled" | "standard";
}

export const DateTimeInput: React.FC<Props> = (props) => {
  const { onChange, value, helperText, label, fullWidth, inputVariant, error, ...muiProps } = props;

  return (
    <DateTimePicker<Date | null>
      ampm={false}
      {...muiProps}
      label={label}
      value={value}
      onChange={onChange}
      format="dd.MM.yyyy HH:mm"
    />
  );
};
