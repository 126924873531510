import { newUuid, uuidSerializer } from "@megarax/common";
import { gtinSerializer, ReportSubject } from "@megarax/crm-contracts";
import { Serializers } from "@megarax/serializers";
import { Table, TableColumn, useSnackbarErrorHandler } from "@megarax/ui-components";
import { Download as DownloadIcon, Upload as UploadIcon } from "@mui/icons-material";
import { Button, Container, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { parse, stringify } from "csv/browser/esm/sync";
import fileDownload from "js-file-download";
import React from "react";

import { Failure, Ok } from "@megaron/result";

const downloadCsv = (subjects: ReportSubject[]) => {
  const columns = [
    { key: "uuid", header: "uuid" },
    { key: "name", header: "wyrob" },
    { key: "varietyName", header: "asortyment" },
    { key: "varietySizeKg", header: "wielkosc" },
    { key: "productGroup", header: "grupa_produktowa" },
    { key: "gtin", header: "ean" },
  ];

  const entries = subjects.map((s) => ({
    ...s,
    varietySizeKg: s.varietySizeKg.toFixed(1),
  }));

  const csv = stringify(entries, {
    header: true,
    columns: columns,
  });
  fileDownload(csv, `produkty_do_ankiet.csv`);
};

interface Props {
  subjects: ReportSubject[];
  onInput: (subjects: ReportSubject[]) => Promise<void>;
}

export const SubjectsView: React.FunctionComponent<Props> = ({ subjects, onInput }) => {
  const classes = useStyles();

  const handleError = useSnackbarErrorHandler({
    InvalidInput: "Niepoprawny plik.",
  });

  const columns: TableColumn<ReportSubject>[] = [
    { title: "Nazwa", getValue: (s) => s.name },
    { title: "Nazwa asortymentu", getValue: (s) => s.varietyName },
    { title: "Wielkość [kg]", getValue: (s) => s.varietySizeKg.toFixed(1) },
    { title: "Grupa produktowa", getValue: (s) => s.productGroup },
    { title: "EAN", getValue: (s) => s.gtin },
  ];

  const onFileUpload: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    const parsed: any[] = parse(await file.text(), {
      columns: true,
    });
    const inputRowsResult = rawToInput(parsed);
    if (inputRowsResult.isFailure) {
      handleError(inputRowsResult.error);
      return;
    }
    onInput(inputRowsResult.value);
  };

  return (
    <Container maxWidth="lg">
      <Paper className={classes.paper}>
        <div className={classes.buttonBar}>
          <Button startIcon={<DownloadIcon />} className={classes.downloadButton} onClick={() => downloadCsv(subjects)}>
            Pobierz CSV
          </Button>

          <input
            accept=".csv"
            type="file"
            id="contained-button-file"
            style={{ display: "none" }}
            onChange={onFileUpload}
          />
          <label htmlFor="contained-button-file">
            <Button component="span" startIcon={<UploadIcon />} className={classes.uploadButton}>
              Importuj CSV
            </Button>
          </label>
        </div>

        <Table stickyHeader columns={columns} items={subjects} getKey={(s) => s.uuid} />
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(1, 0),
  },
  buttonBar: {},
  downloadButton: {
    margin: "0.5rem",
  },
  uploadButton: {
    margin: "0.5rem",
  },
}));

const rawToInput = (raw: any) => {
  if (!Array.isArray(raw)) return Failure("InvalidInput");
  if (raw.length === 0) return Failure("InvalidInput");

  const mapped: ReportSubject[] = [];

  for (const rawRow of raw) {
    if (typeof rawRow !== "object") return Failure("InvalidInput");
    const row = rawToInputRow(rawRow);
    if (row.isFailure) return row;
    mapped.push(row.value);
  }

  return Ok(mapped);
};

const inputRowSerializer = Serializers.object({
  uuid: uuidSerializer.optional(),
  gtin: gtinSerializer.optional(),
  name: Serializers.string,
  varietyName: Serializers.string,
  productGroup: Serializers.string,
  varietySizeKg: Serializers.decimal,
});

export const rawToInputRow = (raw: { [key: string]: unknown }) => {
  try {
    const toParse = {
      uuid: raw.uuid === "" ? undefined : raw.uuid,
      name: raw.wyrob,
      varietyName: raw.asortyment,
      varietySizeKg: raw.wielkosc,
      productGroup: raw.grupa_produktowa,
      gtin: raw.ean === "" ? undefined : raw.ean,
    };

    const deserialized = inputRowSerializer.forceDeserialize(toParse);
    return Ok<ReportSubject>({
      ...deserialized,
      uuid: deserialized.uuid ?? newUuid(),
      gtin: deserialized.gtin ?? null,
    });
  } catch {
    return Failure("InvalidInput");
  }
};
