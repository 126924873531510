import { Uuid } from "@megarax/common";
import { tradeRoutesResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { AreUSureWrapper, commonErrorsMap, SearchBar, useSnackbarErrorHandler } from "@megarax/ui-components";
import { PlaylistAdd } from "@mui/icons-material";
import { Box, Dialog, DialogContent, IconButton, List, ListItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { useFixedRoutes } from "./useFixedRoutes";

interface Props {
  userId: number;
  date: Date;
  onChange: () => void;
  onClose: () => void;
}

export const PlanRouteDialogContainer: React.FunctionComponent<Props> = ({ userId, date, onChange, onClose }) => {
  const classes = useStyles();
  const [routeSearchText, setRouteSearchText] = React.useState("");

  const routes = useFixedRoutes(routeSearchText, userId);
  const routeProvider = useResourceProviderV2(tradeRoutesResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    FixedRouteNotFound: "Nie znaleziono trasy.",
    StopHasNoLocation: "Nieznany adres klienta",
    UnknownHomeLocation: "Użytkownik nie ma przypisanego adresu.",
  });

  const planRoute = (routeUuid: Uuid) => {
    routeProvider
      .byUuid(routeUuid)
      .schedule(undefined, {
        salespersonId: userId,
        scheduledAt: date,
      })
      .mapError(handleError)
      .then((r) => r.assertOk())
      .then(onChange);
  };

  const suggestionsSection = (
    <>
      <Box px={1}>
        <SearchBar initialValue={routeSearchText} onSearchChange={setRouteSearchText} width="100%" />
      </Box>
      <List className={classes.suggestionList}>
        {routes?.map((route) => (
          <ListItem
            key={route.uuid}
            secondaryAction={
              <AreUSureWrapper
                title="Zaplanuj stałą trasę"
                content="Planowanie trasy usunie wszystkie zaplanowane wizyty. Czy chcesz kontynuować?"
                color="primary"
                action={() => planRoute(route.uuid)}
              >
                <IconButton edge="end">
                  <PlaylistAdd />
                </IconButton>
              </AreUSureWrapper>
            }
          >
            <ListItemText primary={<a href={`/crm/pos/trasy/${route.uuid}`}>{route.name}</a>} />
          </ListItem>
        ))}
      </List>
    </>
  );

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogContent>{suggestionsSection}</DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "inherit",
  },
  suggestionList: {
    overflow: "auto",
    flexGrow: 1,
  },
  customerCard: {
    marginBottom: theme.spacing(2),
  },
  itemSecondaryText: {
    display: "flex",
    alignItems: "center",
  },
}));
