import { AmqpTopic } from "@megarax/amqp";
import { Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export type VisitChangedMessage = {
  visitUuid: Uuid;
  customerUuid: Uuid | null;
};

export const visitChangedMessageSerializer = MSerializers.object<VisitChangedMessage>({
  visitUuid: uuidSerializer,
  customerUuid: uuidSerializer.nullable(),
});

export const visitChangedAmqpTopic: AmqpTopic<VisitChangedMessage> = {
  key: "crm.visit.visit-changed",
  serializer: visitChangedMessageSerializer,
};
