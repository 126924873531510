import { tagResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { LoadingBar } from "@megarax/ui-components";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Fade, IconButton, InputBase, Paper, Popper, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { Result } from "@megaron/result";

interface Props {
  currentTags: { label: string }[];
  addTag: (tag: string) => Promise<Result<void, void>>;
  removeTag: (tag: string) => Promise<Result<void, void>>;
}

export const TagSelectRow: React.FC<Props> = ({ currentTags, addTag, removeTag }) => {
  const tagsResource = useResourceProviderV2(tagResource);
  const theme = useTheme();
  const tagsQuery = useQuery("tags", () => tagsResource.list({ limit: 1000 }).map((result) => result.items));

  const createTag = useMutation((label: string) => tagsResource.create(undefined, { label }), {
    onSuccess: () => {
      tagsQuery.refetch();
      setSearch("");
    },
  });

  const removeTagMutation = useMutation((tag: string) => removeTag(tag));

  const addTagMutation = useMutation((tag: string) => addTag(tag), {
    onSuccess: () => {
      setSearch("");
      setSelectOpen(false);
    },
  });

  const [search, setSearch] = useState<string>("");
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
  const popover = useRef<HTMLDivElement>(null);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setSelectOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const onBlur = () => {
    setSelectOpen(false);
    setSearch("");
  };

  const onAddTag = (label: string) => {
    addTagMutation.mutate(label);
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexWrap="wrap"
      style={{ gap: "8px" }}
    >
      {currentTags.map((tag) => {
        return (
          <Box
            key={"select-row-" + tag.label}
            sx={{
              display: "inline-block",
              color: theme.palette.grey[900],
              backgroundColor: theme.palette.grey[200],
              px: 2,
              py: 0.5,
              fontWeight: 700,
              fontSize: "0.8rem",
              borderRadius: "5px",
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              {tag.label}
              <IconButton
                onClick={() => removeTagMutation.mutate(tag.label)}
                sx={{ fontSize: "20px", padding: "1px", ml: "4px", width: "auto", height: "auto" }}
                disabled={removeTagMutation.isLoading}
              >
                <CloseIcon style={{ fontSize: "18px" }} />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      <div
        onBlur={(e) => {
          if (popover.current ? !popover.current.contains(e.relatedTarget) : true) onBlur();
        }}
      >
        <InputBase
          placeholder={selectOpen ? "" : " + Dodaj tag"}
          sx={{ fontSize: "0.9rem", px: "4px" }}
          onFocus={onFocus}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Popper open={selectOpen} anchorEl={anchorEl} placement="bottom-start" transition style={{ zIndex: 1400 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={100}>
              <Paper sx={{ mt: 1, width: "200px", pb: 0.5 }} ref={popover}>
                <LoadingBar loading={tagsQuery.isLoading} />
                {tagsQuery.data?.value &&
                  tagsQuery.data.value
                    .filter(
                      (tag, idx) =>
                        tag.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
                        !currentTags.map((tag) => tag.label).includes(tag.label),
                    )
                    .slice(0, 7)
                    .map((tag) => {
                      return (
                        <Button
                          sx={buttonSx}
                          key={"search-" + tag.label}
                          onClick={() => onAddTag(tag.label)}
                          endIcon={<Box px={1} />}
                        >
                          {tag.label}
                        </Button>
                      );
                    })}
                {tagsQuery.data?.value !== undefined &&
                  tagsQuery.data.value.filter(
                    (tag, idx) =>
                      tag.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
                      !currentTags.map((tag) => tag.label).includes(tag.label),
                  ).length === 0 && (
                    <Box sx={{ color: theme.palette.grey[500], fontWeight: 600, fontSize: "0.9rem" }} px={2} py={1}>
                      Brak wyników
                    </Box>
                  )}
                {tagsQuery.data?.value &&
                  !tagsQuery.data.value.find((tag) => tag.label.toLocaleLowerCase() === search) &&
                  search.length > 1 && (
                    <Button sx={buttonSx} endIcon={<Box px={1} />} onClick={() => createTag.mutate(search)}>
                      <span style={{ color: "lightgray", marginRight: "8px" }}>Utwórz</span>
                      {search}
                    </Button>
                  )}
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </Box>
  );
};

const buttonSx = {
  width: "100%",
  py: 0.5,
  px: 2,
  fontSize: "0.8rem",
  fontWeight: 600,
  textAlign: "left",
  justifyContent: "start",
  textTransform: "none",
};
