import { Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { Uuid } from "@megarax/common";

import { ActivityCard, ActivityListEntry } from "./ActivityCard";

interface Props {
  entries: ActivityListEntry[] | undefined;
  onVisitSeeMore: (uuid: Uuid) => void;
  onEventEdit: (uuid: Uuid) => void;
  className?: string;
}

export const ActivityList: React.FunctionComponent<Props> = ({
  className,
  entries,
  onVisitSeeMore,
  onEventEdit,
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {entries === undefined ? (
        <Skeleton variant="rectangular" height={120} />
      ) : (
        entries.map((entry) => (
          <ActivityCard
            entry={entry}
            key={entry.uuid}
            onVisitSeeMore={onVisitSeeMore}
            onEventEdit={onEventEdit}
          />
        ))
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({}));
