import { TextInput } from "@megarax/ui-components";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TimePicker } from "@mui/x-date-pickers";
import { addMinutes, differenceInMinutes } from "date-fns";
import React from "react";

export type OnComplete = (formData: { title: string; description: string; startTime: Date; endTime: Date }) => void;

interface Props {
  onClose: () => void;
  isOpen: boolean;
  onComplete: OnComplete;
  onRemove: () => void;
  showRemoveButton: boolean;

  initialTitle: string | null;
  initialDescription: string | null;
  initialStartTime: Date | null;
  initialEndTime: Date | null;
}

export const ChangeEventDialog: React.FunctionComponent<Props> = ({
  onClose,
  isOpen,
  onComplete,
  initialTitle,
  initialDescription,
  initialStartTime,
  initialEndTime,
  onRemove,
  showRemoveButton,
}) => {
  const classes = useStyles();

  const initialDuration = initialStartTime && initialEndTime && differenceInMinutes(initialEndTime, initialStartTime);
  const [title, setTitle] = React.useState<string>(initialTitle ?? "");
  const [description, setDescription] = React.useState<string>(initialDescription ?? "");
  const [startTime, setStartTime] = React.useState<Date | null>(initialStartTime ?? new Date());
  const [durationMinutes, setDurationMinutes] = React.useState<number>(initialDuration ?? 60);

  const endTime = startTime && addMinutes(startTime, durationMinutes);

  const setEndTime = (date: Date | null) => {
    if (date === null || startTime === null) return;
    setDurationMinutes(differenceInMinutes(date, startTime));
  };

  const isFormFilledIn = startTime !== null && endTime !== null;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Wydarzenie</DialogTitle>
      <DialogContent>
        <div className={classes.form}>
          <TextInput onChange={setTitle} value={title} label="Tytuł" fullWidth />
          <TextInput onChange={setDescription} value={description} label="Opis" fullWidth multiline />

          <TimePicker ampm={false} label={"Godzina rozpoczęcia"} value={startTime} onChange={setStartTime} />
          <TimePicker ampm={false} label={"Godzina zakończenia"} value={endTime} onChange={setEndTime} />
        </div>
      </DialogContent>
      <DialogActions>
        {showRemoveButton && (
          <Button color="error" onClick={onRemove} sx={{ marginRight: "auto" }}>
            Usuń
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          disabled={!isFormFilledIn}
          onClick={() => onComplete({ title, endTime: endTime!, startTime: startTime!, description })}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));
