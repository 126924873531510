import HistoryIcon from "@mui/icons-material/History";
import { Box, IconButton, Paper } from "@mui/material";
import React from "react";

import { CustomerStatus, customerStatuses, macroregionResource, regionResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import {
  AreUSureWrapper,
  BoolSelectFilterConfig,
  FilterBar,
  FilterBarConfig,
  MultiSelectChip,
  MultiSelectFilterConfig,
  SearchBar,
  SearchMultiSelectFilterConfig,
  SelectFilterConfig,
} from "@megarax/ui-components";

import { QueryFilters, SetQueryFilters } from "./TradeRouteDetails";

interface Props {
  isMobile: boolean;
  qs: QueryFilters;
  setQueryFilters: (qf: QueryFilters) => void;
  isEdited: boolean;
  revertToInitialRoute: () => void;
}

type FilterSet = {
  macroregionUuid: string[];
  searchText: string;
  visitedSince: { value: number | null; bool: boolean };
  orderedSince: { value: number | null; bool: boolean };
  status: CustomerStatus[];
  onRoute: "true" | "false" | null;
};

export const Filters: React.FC<Props> = ({ isMobile, qs, setQueryFilters, isEdited, revertToInitialRoute }) => {
  const { list: listMacroregions } = useResourceProviderV2(macroregionResource);

  const getMacroregionOptions = (searchText: string) =>
    listMacroregions({ searchText, limit: 30 })
      .map((result) =>
        result.items.map((macroregion) => ({ value: macroregion.uuid as string, label: macroregion.name })),
      )
      .mapError((err) => Promise.reject(err))
      .then((res) => res.value);

  const config: FilterBarConfig<FilterSet> = {
    status: MultiSelectFilterConfig<CustomerStatus>({
      label: "Status",
      options: [
        { label: "VIP", value: "vip" },
        { label: "Zweryfikowany", value: "verified" },
        { label: "Do weryfikacji", value: "unverified" },
        { label: "Nieaktywny", value: "inactive" },
      ],
    }),

    macroregionUuid: SearchMultiSelectFilterConfig({
      label: "Makroregion",
      getOptions: getMacroregionOptions,
    }),
    visitedSince: BoolSelectFilterConfig({
      label: "Odwiedzony w ciągu",
      labels: {
        positive: "Odwiedzony",
        negative: "Nie odwiedzony",
        main: "W ciągu?",
      },
      options: [
        { label: "30 dni", value: 30 },
        { label: "90 dni", value: 90 },
        { label: "180 dni", value: 180 },
        { label: "360 dni", value: 360 },
      ],
    }),
    orderedSince: BoolSelectFilterConfig({
      label: "Zamówił w ciągu",
      labels: {
        positive: "Zamówił",
        negative: "Nie zamówił",
        main: "W ciągu?",
      },
      options: [
        { label: "30 dni", value: 30 },
        { label: "90 dni", value: 90 },
        { label: "180 dni", value: 180 },
        { label: "360 dni", value: 360 },
      ],
    }),
    onRoute: SelectFilterConfig({
      label: "Na stałej trasie?",
      options: [
        { label: "Na stałej trasie: Tak", value: "true" },
        { label: "Na stałej trasie: Nie", value: "false" },
      ],
    }),
  };

  const filters = toFilterSet(qs);

  return (
    <Box
      position="absolute"
      top={50}
      zIndex={200}
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent={"flex-start"}
      alignItems={isMobile ? "center" : "flex-start"}
      sx={{ pointerEvents: "none" }}
    >
      <Box width="100%" display="flex" justifyContent={isMobile ? "center" : "space-between"}>
        <Paper sx={{ mx: "16px", mt: "16px", pointerEvents: "auto" }}>
          <SearchBar
            onSearchChange={(searchText) => setQueryFilters({ ...qs, searchText: searchText })}
            sx={{ my: 0 }}
          />
        </Paper>
        {isEdited && (
          <Box sx={{ position: isMobile ? "absolute" : "relative", right: "5px" }}>
            <AreUSureWrapper
              action={revertToInitialRoute}
              title="Przywrócić oryginalną trasę?"
              content="Stracisz wszystkie naniesione zmiany!"
            >
              <IconButton
                sx={{
                  pointerEvents: "auto",
                  right: isMobile ? "15px" : "315px",
                  top: "15px",
                  backgroundColor: "white",
                  ":hover": { backgroundColor: "lightgray" },
                }}
                color="error"
                size="large"
              >
                <HistoryIcon />
              </IconButton>
            </AreUSureWrapper>
          </Box>
        )}
      </Box>
      <Box
        width={isMobile ? "100%" : "50%"}
        display="flex"
        justifyContent={isMobile ? "center" : "flex-start"}
        p={2}
        flexWrap="wrap"
      >
        <FilterBar<FilterSet>
          filters={filters}
          config={config}
          className="opaque"
          onChange={(filterSet) =>
            setQueryFilters({
              ...qs,
              ...fromFilterSet(filterSet, qs.searchText, qs.salespersonId),
            })
          }
          options={{
            hideIcon: true,
            opaque: true,
            centered: isMobile,
          }}
        />
      </Box>
    </Box>
  );
};

export const statusMap: { [key in CustomerStatus]: string } = {
  vip: "VIP",
  verified: "Zweryfikowany",
  unverified: "Do weryfikacji",
  inactive: "Nieaktywny",
};

const toFilterSet = (qf: QueryFilters): FilterSet => ({
  ...qf,
  macroregionUuid: qf.macroregionUuid ?? [],
  status: qf.status ?? [],
});

const fromFilterSet = (filterSet: FilterSet, searchText: string, salespersonId: number | undefined): QueryFilters => ({
  ...filterSet,
  searchText,
  salespersonId,
  macroregionUuid: filterSet.macroregionUuid.length > 0 ? filterSet.macroregionUuid : null,
  status: filterSet.status.length > 0 ? filterSet.status : null,
});
