import { newUuid, Uuid } from "@megarax/common";
import { PriceReportDto, PriceReportInput, ReportSubject } from "@megarax/crm-contracts";
import { AsyncButton, DecimalInput, NewRouterResponsiveSelect } from "@megarax/ui-components";
import { Alert, Box, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";

import { ReportTable } from "../ReportTable";

export type GetCustomers = (search: string) => Promise<{ uuid: Uuid; name: string | null; regionUuid: Uuid | null }[]>;
export type GetPriceReportSubjects = (search: string) => Promise<ReportSubject[]>;
export type SubmitPriceReport = (uuid: Uuid, input: PriceReportInput) => Promise<void>;
export type GetPreviousReports = (customerUuid: Uuid) => Promise<PriceReportDto[]>;

const taxRate = new Decimal("1.23");

interface Props {
  getCustomers: GetCustomers;
  getPriceReportSubjects: GetPriceReportSubjects;
  submitPriceReport: SubmitPriceReport;
  getPreviousReports: GetPreviousReports;
  myRegions: Uuid[];
}

export const PriceReportForm: React.FC<Props> = ({
  getCustomers,
  getPriceReportSubjects,
  submitPriceReport,
  getPreviousReports,
  myRegions,
}) => {
  const classes = useStyles();
  const [customer, setCustomer] = useState<{
    uuid: Uuid;
    name: string | null;
    regionUuid: Uuid | null;
  } | null>(null);
  const [subject, setSubject] = useState<ReportSubject | null>(null);
  const [netPrice, setNetPrice] = useState<Decimal | undefined>();
  const [grossPrice, setGrossPrice] = useState<Decimal | undefined>();
  const [previousReports, setPreviousReports] = useState<PriceReportDto[]>();
  const [uuid, setUuid] = useState<Uuid>(newUuid());

  const onNetPriceSet = (newValue: Decimal | undefined) => {
    setNetPrice(newValue);
    setGrossPrice(newValue ? newValue.mul(taxRate).toDecimalPlaces(2) : undefined);
  };

  const onGrossPriceSet = (newValue: Decimal | undefined) => {
    setGrossPrice(newValue);
    setNetPrice(newValue ? newValue.div(taxRate).toDecimalPlaces(2) : undefined);
  };

  const onSubmit = async () => {
    if (!customer || !netPrice || !subject) return;
    await submitPriceReport(uuid, {
      customerUuid: customer.uuid,
      netPrice: netPrice,
      subjectUuid: subject.uuid,
    });

    await getPreviousReports(customer.uuid).then(setPreviousReports);
    setUuid(newUuid());
    setNetPrice(new Decimal(0));
    setGrossPrice(new Decimal(0));
    setSubject(null);
  };

  useEffect(() => {
    if (!customer) return setPreviousReports([]);
    getPreviousReports(customer.uuid).then(setPreviousReports);
  }, [customer]);

  const showRecipientAlert = customer && (customer.regionUuid === null || !myRegions.includes(customer.regionUuid));

  return (
    <Container maxWidth="md">
      <div className={classes.formContainer}>
        <Typography variant="h6">Wybierz klienta</Typography>
        <NewRouterResponsiveSelect
          label={"Klient"}
          value={customer}
          getOptions={getCustomers}
          getValue={(i) => i}
          getKey={(i) => i.uuid}
          getLabel={(i) => i.name ?? "?"}
          onChange={setCustomer}
        />
        {showRecipientAlert && (
          <Box mb={2}>
            <Alert severity="warning">Miejsce odbioru nie jest przypisane do twojego regionu.</Alert>
          </Box>
        )}
        {customer && (
          <div className={classes.priceAddContainer} key={uuid}>
            <Typography variant="h6">Dodaj cenę</Typography>
            <NewRouterResponsiveSelect
              label={"Produkt"}
              value={subject}
              getOptions={getPriceReportSubjects}
              getValue={(i) => i}
              getKey={(i) => i.uuid}
              getLabel={(i) => i.varietyName}
              onChange={setSubject}
            />
            <DecimalInput
              fullWidth
              value={netPrice}
              onChange={onNetPriceSet}
              label="Cena netto [PLN]"
              variant="outlined"
            />
            <DecimalInput
              fullWidth
              value={grossPrice}
              onChange={onGrossPriceSet}
              label="Cena brutto [PLN]"
              variant="outlined"
            />
            <AsyncButton asyncAction={onSubmit} color="primary" variant="contained" className={classes.button}>
              Dodaj
            </AsyncButton>
          </div>
        )}
      </div>
      {previousReports && previousReports.length > 0 && (
        <>
          <Typography variant="h6">Poprzednie ankiety</Typography>
          <ReportTable reports={previousReports} />
        </>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& > .MuiFormControl-root": {
        margin: theme.spacing(1, 0),
      },
      maxWidth: "300px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "500px",
      },
    },
    priceAddContainer: {
      marginTop: theme.spacing(4),
      "& > .MuiFormControl-root": {
        margin: theme.spacing(1, 0),
      },
    },
    button: {
      marginTop: theme.spacing(1),
      display: "block",
      marginLeft: "auto",
    },
  };
});
