import { CustomerDetail, DeliveryAddress } from "@megarax/crm-contracts";
import { TextInput } from "@megarax/ui-components";
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, useMediaQuery, useTheme } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";

import { DeliveryAddressSelect } from "./DeliveryAddressSelect";

export interface SendDraftInput {
  deliveryAddress: DeliveryAddress;
  comment: string;
  deliveryDeadline: Date;
  externalOrderNumber: string;
}

interface Props {
  closeDialog: () => void;
  onSendDraft: (input: SendDraftInput) => Promise<void>;
  earliestDeliveryDate: Date;
  recipient: CustomerDetail;
}

export const SendDraftDialog: React.FC<Props> = ({ closeDialog, onSendDraft, earliestDeliveryDate, recipient }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(false);

  const [deliveryDeadline, setDeliveryDeadline] = useState<Date>(earliestDeliveryDate);
  const [externalOrderNumber, setExternalOrderNumber] = useState<string>("");
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress | null>(null);
  const [comment, setComment] = useState<string>("");

  const submit = async () => {
    if (!deliveryAddress) {
      return;
    }
    setIsLoading(true);
    await onSendDraft({
      deliveryAddress,
      comment,
      deliveryDeadline,
      externalOrderNumber,
    }).finally(() => setIsLoading(false));
  };

  return (
    <Dialog open={true} onClose={closeDialog} fullScreen={isMobile}>
      <DialogContent>
        <Box style={{ maxHeight: "320px" }}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            minDate={earliestDeliveryDate}
            openTo="day"
            value={deliveryDeadline}
            onChange={(date: any) => {
              if (date) setDeliveryDeadline(date);
            }}
          />
        </Box>

        <DeliveryAddressSelect
          onChange={setDeliveryAddress}
          recipientAddress={recipient.visitLocation ? { ...recipient.visitLocation, houseNumber: "" } : null}
        />
        <Divider style={{ margin: "1rem 0" }} />
        <TextInput
          label="Numer zamówienia klienta (opcjonalny)"
          value={externalOrderNumber}
          onChange={setExternalOrderNumber}
          fullWidth
          variant="outlined"
        />
        <Box mt={1}>
          <TextInput fullWidth multiline value={comment} onChange={setComment} variant="outlined" label="Notatka" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Anuluj</Button>
        <Button onClick={submit} variant="contained" color="primary" disabled={!deliveryAddress}>
          Wyślij
        </Button>
      </DialogActions>
    </Dialog>
  );
};
