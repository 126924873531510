import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

const codeGroupPropertiesSerializer = Serializers.object({
  date: Serializers.dateOnly,
  createdBy: Serializers.string,
  createdAt: Serializers.datetime,
  type: Serializers.string,
  productId: Serializers.string.nullable(),
  notes: Serializers.string,
});

export type CodeGroupProperties = SerializerValue<typeof codeGroupPropertiesSerializer>;

const codeGroupDtoSerializer = Serializers.object({
  name: Serializers.string,
  firstChange: Serializers.datetime.nullable(),
  lastChange: Serializers.datetime.nullable(),
  total: Serializers.integer,
  byStatus: Serializers.object({
    blank: Serializers.integer,
    active: Serializers.integer,
    redeemed: Serializers.integer,
    expired: Serializers.integer,
    destroyed: Serializers.integer,
  }),
  properties: codeGroupPropertiesSerializer.optional(),
});

export type CodeGroupDto = SerializerValue<typeof codeGroupDtoSerializer>;

const codeGroupsQuery = HttpAction({
  path: "/productCodeGroups",
  method: "get",
  qsSerializer: Serializers.object({
    searchText: Serializers.string,
    type: Serializers.string.array().asJsonString(),
    productId: Serializers.string,
    codeId: Serializers.string,
    creationDate: dateStringSerializer,
    limit: Serializers.integer,
    offset: Serializers.integer,
  }).partial(),
  valueSerializer: Serializers.array(codeGroupDtoSerializer),
  requiresAuth: true as const,
});

const codeGroupQuery = HttpAction({
  path: "/productCodeGroups/name/:groupName",
  method: "get",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    groupName: Serializers.string,
  }),
  valueSerializer: codeGroupDtoSerializer.nullable(),
});

const appendCodesToGroup = HttpAction({
  path: "/productCodeGroups/name/:groupName/appendCodes",
  method: "post",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    groupName: Serializers.string,
  }),
  bodySerializer: Serializers.first(
    Serializers.object({ codeIds: Serializers.string.array() }),
    Serializers.object({ sourceGroupName: Serializers.string }),
  ),
  errorSerializer: Serializers.stringOneOf("CodeDoesNotExist"),
});

const removeCodesFromGroup = HttpAction({
  path: "/productCodeGroups/name/:groupName/removeCodes",
  method: "post",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    groupName: Serializers.string,
  }),
  bodySerializer: Serializers.first(
    Serializers.object({ codeIds: Serializers.string.array() }),
    Serializers.object({ sourceGroupName: Serializers.string }),
  ),
  errorSerializer: Serializers.stringOneOf(),
});

const createSubgroup = HttpAction({
  path: "/productCodeGroups/createSubgroup",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    parentGroups: Serializers.array(Serializers.string).optional(),
    subgroupName: Serializers.string,
    firstCodeId: Serializers.string,
    lastCodeId: Serializers.string,
    dryRun: Serializers.boolean,
  }),
  errorSerializer: Serializers.stringOneOf("MultipleCommonGroups", "CodeNotFound", "NoCommonGroups"),
  valueSerializer: Serializers.object({
    parentGroup: Serializers.string,
    count: Serializers.integer,
  }),
});

const saveGroupProperties = HttpAction({
  path: "/productCodeGroups/name/:groupName",
  method: "post",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    groupName: Serializers.string,
  }),
  bodySerializer: codeGroupPropertiesSerializer,
});

export const codeGroupsActions = {
  codeGroupsQuery,
  codeGroupQuery,
  appendCodesToGroup,
  removeCodesFromGroup,
  createSubgroup,
  saveGroupProperties,
};
