import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import HomeIcon from "@mui/icons-material/Home";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, FilledInput, InputAdornment, Typography } from "@mui/material";
import { Dictionary } from "lodash";
import React from "react";

import { formatGoogleDuration } from "../../utils";
import { TradeRouteCustomer, TravelData } from "../TradeRouteDetails";

export const DraggableRouteStops: React.FC<{
  homeSelected: boolean;
  route: TradeRouteCustomer[];
  setRoute: (route: TradeRouteCustomer[]) => void;
  travelData: TravelData[];
  stopDurations: Dictionary<{
    durationMinutes: string;
  }>;
  setStopDurations: (
    durations: Dictionary<{
      durationMinutes: string;
    }>,
  ) => void;
}> = ({ route, setRoute, travelData, stopDurations, setStopDurations, homeSelected }) => {
  const draggableRoute = React.useMemo(() => {
    return route.map((stop) => ({ ...stop, id: stop.uuid }));
  }, [route]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over?.id) {
      const oldIdx = draggableRoute.findIndex((item) => item.id === active.id);
      const newIdx = draggableRoute.findIndex((item) => item.id === over.id);
      setRoute(arrayMove(draggableRoute, oldIdx, newIdx));
    }
  };

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        flexGrow: "1",
        overflowY: "auto",
        display: "block",
        flexBasis: "0",
      }}
    >
      <DndContext
        onDragEnd={handleDragEnd}
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={draggableRoute} strategy={verticalListSortingStrategy}>
          <Box onTouchStart={(e) => e.stopPropagation()}>
            {route.length > 0 && (
              <Box width="100%" display="flex" justifyContent="center">
                <HomeIcon />
              </Box>
            )}
            {route.map((entry, idx) => {
              return (
                <React.Fragment key={entry.uuid}>
                  <RouteDistance travelData={homeSelected ? travelData[idx] : undefined} />
                  <RouteStop
                    entry={entry}
                    stopDuration={stopDurations[entry.uuid]?.durationMinutes ?? ""}
                    setStopDuration={(val) =>
                      setStopDurations({ ...stopDurations, [entry.uuid]: { durationMinutes: val } })
                    }
                  />
                </React.Fragment>
              );
            })}
          </Box>
          {route.length > 0 && (
            <>
              <RouteDistance travelData={homeSelected ? travelData[route.length] : undefined} />

              <Box width="100%" display="flex" justifyContent="center">
                <HomeIcon />
              </Box>
            </>
          )}
        </SortableContext>
      </DndContext>
    </Box>
  );
};

const RouteDistance: React.FC<{ travelData: TravelData | undefined }> = ({ travelData }) => {
  return (
    <Box display="flex" justifyContent="center" py={1} position="relative">
      {travelData && (
        <Box position="absolute" right={"calc(50% + 16px)"}>
          <Typography variant="caption">{formatGoogleDuration(travelData.duration)}</Typography>
        </Box>
      )}
      <MoreVertIcon sx={{ color: "gray" }} />
      {travelData && (
        <Box position="absolute" left={"calc(50% + 16px)"}>
          <Typography variant="caption">{(travelData.distance / 1000).toFixed(2)}km</Typography>
        </Box>
      )}
    </Box>
  );
};

const RouteStop: React.FC<{
  entry: TradeRouteCustomer;
  stopDuration: string;
  setStopDuration: (duration: string) => void;
}> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.entry.uuid,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      sx={{
        backgroundColor: "white",
        border: "1px lightgray solid",
        borderRadius: "4px",
        px: 1,
        py: 0.5,
        position: "relative",
        zIndex: 200,
      }}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="caption">{props.entry.name}</Typography>
        <Box {...listeners} sx={{ cursor: isDragging ? "grabbing" : "grab" }}>
          <DragIndicatorIcon />
        </Box>
      </Box>
      <FilledInput
        fullWidth
        size="small"
        value={props.stopDuration}
        onChange={(e) => props.setStopDuration(e.target.value)}
        type="number"
        error={isNaN(parseFloat(props.stopDuration))}
        inputProps={{
          sx: {
            py: 0.5,
          },
        }}
        endAdornment={<InputAdornment position="end">min</InputAdornment>}
      />
    </Box>
  );
};
