import Decimal from "decimal.js";

import { Failure, Ok } from "@megaron/result";

import { serializerExtensions, SerializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export const decimal: Serializer<Decimal> & SerializerExtensions<Decimal> = {
  serialize: (value) => value.toString(),
  deserialize: (raw) => {
    try {
      return Ok(new Decimal(raw as any));
    } catch (err) {
      return Failure(`InvalidDecimal`);
    }
  },
  ...serializerExtensions(),
};
