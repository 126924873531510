import { useTheme } from "@emotion/react";
import React from "react";

type DateTimeFieldProps = {
  value: Date;
  onChange: (value: Date) => void;
  label?: string;
  className?: string;
  showTime?: boolean;
  isDisabled?: boolean;
  disableFuture?: boolean;
};

export const DateTimeField: React.FunctionComponent<DateTimeFieldProps> = ({
  value,
  onChange,
  label,
  className,
  showTime,
  isDisabled,
  disableFuture,
}) => {
  const theme = useTheme();

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const formatTime = (date: Date) => {
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${hours}:${minutes}`;
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const [year, month, day] = e.target.value.split("-").map((num) => parseInt(num, 10));
      const selectedDate = new Date(year, month - 1, day);
      onChange(selectedDate);
    }
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const [hours, minutes] = e.target.value.split(":").map((num) => parseInt(num, 10));
      const selectedTime = new Date(value);
      selectedTime.setHours(hours);
      selectedTime.setMinutes(minutes);
      onChange(selectedTime);
    }
  };

  const today = new Date();
  const maxDate = formatDate(today);

  const inputProps = {
    value: formatDate(value),
    onChange: handleDateChange,
    type: "date",
    max: disableFuture ? maxDate : undefined,
    css: {
      width: "100%",
      border: "none",
      padding: "0.5rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "6px",
      cursor: "text",
      input: {
        cursor: "pointer",
      },
      backgroundColor: theme.colors.primaryLight,
      ":focus": {
        outline: `1px solid ${theme.colors.primary}`,
      },
    },
    disabled: isDisabled ? true : false,
  };

  const timeInputProps = {
    value: formatTime(value),
    onChange: handleTimeChange,
    type: "time",
    css: {
      width: "100%",
      border: "none",
      padding: "0.5rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "6px",
      cursor: "text",
      backgroundColor: theme.colors.primaryLight,
      ":focus": {
        outline: `1px solid ${theme.colors.primary}`,
      },
      input: {
        cursor: "pointer",
      },
      "input::-webkit-calendar-picker-indicator": {
        cursor: "pointer",
      },
    },
    disabled: isDisabled ? true : false,
  };

  return (
    <div css={{ display: "flex", flexDirection: "column" }} className={className}>
      {label && (
        <label
          css={{
            color: theme.colors.primary,
            fontSize: "0.875rem",
            fontFamily: theme.displayFontFamily,
            marginBottom: "0.25rem",
          }}
        >
          {label}
        </label>
      )}
      <div css={{ display: "flex", gap: "8px" }}>
        <input {...inputProps} />
        {showTime && <input {...timeInputProps} />}
      </div>
    </div>
  );
};
