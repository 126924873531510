import { useTheme } from "@emotion/react";
import { Loader } from "@googlemaps/js-api-loader";
import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import { CustomerAddress } from "@megaron/crm-contracts";
import { TextField } from "@megaron/dash-form";

type Props = {
  address?: CustomerAddress;
  onChange: (value: CustomerAddress) => void;
};
type PlacePrediction = google.maps.places.AutocompletePrediction;

export const LocationPicker: React.FC<Props> = ({ address, onChange }) => {
  const theme = useTheme();
  const [predictions, setPredictions] = useState<PlacePrediction[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [localAddress, setLocalAddress] = useState<CustomerAddress>(
    address ?? {
      country: "",
      city: "",
      street: "",
      postalCode: "",
      latitude: null,
      longitude: null,
    },
  );

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
      version: "weekly",
      libraries: ["places"],
      language: "pl",
    });

    loader.load().then(() => {
      console.log("Google Maps API loaded");
    });
  }, []);

  const fetchPredictions = async (input: string) => {
    if (!input.trim()) {
      setPredictions([]);
      return;
    }

    const result = await getPredictions(input);
    if (result && Array.isArray(result)) {
      setPredictions(result);
    } else {
      console.error("Unexpected response from Google Places API");
    }
  };

  const handlePredictionSelect = async (prediction: PlacePrediction) => {
    setInputValue(prediction.description);
    setPredictions([]);
    const placeService = new google.maps.places.PlacesService(document.createElement("div"));
    try {
      const details = await new Promise<google.maps.places.PlaceResult | null>((resolve, reject) => {
        placeService.getDetails({ placeId: prediction.place_id }, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(place);
          } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            resolve(null);
          } else {
            reject(new Error(`Failed to get place details. Status: ${status}`));
          }
        });
      });
      if (details) {
        const addressComponents = details.address_components;
        if (addressComponents) {
          const country = addressComponents.find((component) => component.types.includes("country"))?.long_name || "";
          const city = addressComponents.find((component) => component.types.includes("locality"))?.long_name || "";
          const street = addressComponents.find((component) => component.types.includes("route"))?.long_name || "";
          const postalCode =
            addressComponents.find((component) => component.types.includes("postal_code"))?.long_name || "";
          const latitude =
            details.geometry && details.geometry.location ? new Decimal(details.geometry.location.lat()) : null;
          const longitude =
            details.geometry && details.geometry.location ? new Decimal(details.geometry.location.lng()) : null;

          const updatedAddress: CustomerAddress = {
            country,
            city,
            street,
            postalCode,
            latitude,
            longitude,
          };

          setLocalAddress(updatedAddress);
          onChange(updatedAddress);
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching place details:", error);
    }
  };

  const getPredictions = async (input: string): Promise<PlacePrediction[]> => {
    const autocompleteService = new google.maps.places.AutocompleteService();
    const result = await new Promise<PlacePrediction[]>((resolve, reject) => {
      autocompleteService.getPlacePredictions(
        {
          input,
          types: ["address"],
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (predictions) {
              resolve(predictions);
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        },
      );
    });
    return result;
  };

  const handleInputChange = (field: keyof CustomerAddress) => (value: string) => {
    setLocalAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
    onChange({
      ...localAddress,
      [field]: value,
    });
    setInputValue(value);
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", position: "relative" }}>
      <span
        css={{
          fontFamily: theme.displayFontFamily,
          fontSize: "14px",
          color: theme.colors.primary,
          marginBottom: "4px",
        }}
      >
        Wyszukiwanie adresu
      </span>

      <div css={{ position: "relative", display: "flex", alignItems: "center" }}>
        <input
          id="autocomplete"
          type="text"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            fetchPredictions(e.target.value);
          }}
          placeholder="Szukaj adresu..."
          css={{
            width: "100%",
            border: "none",
            padding: "0.5rem 2.25rem 0.5rem 0.75rem",
            fontSize: "1rem",
            borderRadius: theme.smallBorderRadius,
            marginBottom: "20px",
            backgroundColor: theme.colors.primaryLight,
            ":focus": {
              outline: `1px solid ${theme.colors.primary}`,
            },
          }}
        />
        <div css={{ position: "absolute", right: "5px", top: "40%", transform: "translateY(-50%)" }}>
          {predictions.length > 0 ? (
            <FaAngleUp
              onClick={() => setPredictions([])}
              css={{ cursor: "pointer", color: theme.colors.primary, fontSize: "20px" }}
            />
          ) : (
            <FaAngleDown
              onClick={() => fetchPredictions(inputValue)}
              css={{ cursor: "pointer", color: theme.colors.primary, fontSize: "20px" }}
            />
          )}
        </div>
      </div>

      {predictions.length > 0 && (
        <ul
          css={{
            listStyleType: "none",
            padding: 0,
            backgroundColor: theme.colors.primaryLight,
            borderRadius: theme.smallBorderRadius,
            border: `1px solid ${theme.colors.border}`,
            marginTop: "4px",
            width: "100%",
            position: "absolute",
            top: "62px",
            zIndex: 1,
          }}
        >
          {predictions.map((prediction) => (
            <li
              key={prediction.place_id}
              onClick={() => handlePredictionSelect(prediction)}
              css={{
                padding: "8px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: theme.colors.primary,
                  color: "#fff",
                },
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {prediction.description}
            </li>
          ))}
        </ul>
      )}
      <div css={{ display: "flex", gap: "20px", flexDirection: "column" }}>
        <div css={{ display: "flex", gap: "20px" }}>
          <TextField label="Kraj" value={localAddress.country} onChange={handleInputChange("country")} />
          <TextField label="Miasto" value={localAddress.city} onChange={handleInputChange("city")} />
        </div>
        <div css={{ display: "flex", gap: "20px" }}>
          <TextField label="Ulica" value={localAddress.street} onChange={handleInputChange("street")} />
          <TextField label="Kod pocztowy" value={localAddress.postalCode} onChange={handleInputChange("postalCode")} />
        </div>
      </div>
    </div>
  );
};
