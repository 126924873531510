import { Box, Container, Paper, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  alerts: React.ReactNode | undefined;
  titleSection: React.ReactNode | undefined;
  tagSection: React.ReactNode | undefined;
  regionAndChainSection: React.ReactNode | undefined;
  pricingSection: React.ReactNode;
  addressSection: React.ReactNode | undefined;

  chat: React.ReactElement;
  contacts: React.ReactElement;
  recentOrders: React.ReactElement;
}

export const DesktopViewLayout: React.FunctionComponent<Props> = ({
  alerts,
  titleSection,
  tagSection,
  regionAndChainSection,
  pricingSection,
  addressSection,
  chat,
  contacts,
  recentOrders,
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <Box display="flex" flexDirection="row" mt={2} mb={4}>
        <Box flexGrow={1} flexBasis={0} mr={1}>
          <Box>
            {alerts}
            <Box mb={2}>{titleSection ?? <Skeleton variant="rectangular" height={40} />}</Box>
            <Box mb={4}>{tagSection ?? <Skeleton variant="rectangular" height={28} />}</Box>
            <Box mb={6}>{regionAndChainSection ?? <Skeleton variant="rectangular" height={24} />}</Box>
            <Box mb={4}>{pricingSection}</Box>
          </Box>
        </Box>
        <Box flexGrow={1} flexBasis={0} ml={1}>
          <Box className={classes.addressSection}>
            {addressSection ?? <Skeleton variant="rectangular" height={360} />}
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box flexGrow={1} flexBasis={0} minWidth={320}>
          <Paper elevation={1} className={classes.sectionPaper}>
            {chat}
          </Paper>
        </Box>
        <Box flexGrow={1} flexBasis={0} mx={2}>
          <Paper elevation={1} className={classes.sectionPaper}>
            {contacts}
          </Paper>
        </Box>
        <Box flexGrow={1} flexBasis={0}>
          <Paper elevation={1} className={classes.sectionPaper}>
            {recentOrders}
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  addressSection: {
    overflow: "hidden",
    borderRadius: "3px",
    borderColor: theme.palette.divider,
    borderWidth: "1px",
    borderStyle: "solid",
  },
  sectionPaper: {
    overflow: "hidden",
  },
}));
