import { Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

import { MentionDto, mentionDtoSerializer } from "./threadDto";

export type PostMessageInput = {
  content: string;
  mentions: MentionDto[];
  uuid: Uuid;
};

export const postMessageInputSerializer = MSerializers.object<PostMessageInput>({
  uuid: uuidSerializer,
  content: MSerializers.string,
  mentions: MSerializers.array(mentionDtoSerializer),
});
