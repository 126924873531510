import _ from "lodash";

import { HttpService } from "@megaron/http-service";
import { Failure, Ok } from "@megaron/result";

import { AuthAttribute } from "./authAttribute";

import type { PermissionMiddleware } from "@megaron/http-service-host";
type ServiceAllowListMap<TService extends HttpService> = {
  [action in keyof TService as TService[action] extends { requiresAuth: true } ? action : never]: AuthAttribute[];
};

export const AttributePermissionMiddleware =
  <
    TUser extends {
      authAttributes: AuthAttribute[];
    },
    TService extends HttpService,
  >(
    table: ServiceAllowListMap<TService>,
  ): PermissionMiddleware<TUser> =>
  async (ctx) => {
    const allowedAttributes = table[ctx.action as keyof ServiceAllowListMap<TService>] ?? [];

    if (isUserAuthorized(ctx.user.authAttributes, allowedAttributes)) return Ok();

    return Failure("PermissionDenied");
  };

export const isUserAuthorized = (userAttrs: AuthAttribute[], authorizedAttrs: AuthAttribute[]) => {
  const matchingAttributes = _.intersectionBy(userAttrs, authorizedAttrs);

  return matchingAttributes.length > 0;
};
