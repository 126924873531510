import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { notificationTypes } from "./notificationsDetailsMap";

export const produceNotificationSerializer = Serializers.object({
  recipientId: Serializers.string,
  type: Serializers.stringOneOf(...notificationTypes),
  message: Serializers.string,
  link: Serializers.string.nullable(),
  alertId: Serializers.string.nullable(),
});

export const notificationSerializer = Serializers.object({
  uuid: uuidSerializer,
  recipientId: Serializers.string,
  type: Serializers.stringOneOf(...notificationTypes),
  message: Serializers.string,
  link: Serializers.string.nullable(),
  emailSentAt: Serializers.datetime.nullable(),
  smsSentAt: Serializers.datetime.nullable(),
  webPushSentAt: Serializers.datetime.nullable(),
  sentAt: Serializers.datetime.nullable(),
  readAt: Serializers.datetime.nullable(),
  deactivatedAt: Serializers.datetime.nullable(),
  alertId: Serializers.string.nullable(),
  createdAt: Serializers.datetime,
});

export type NotificationObject = SerializerValue<typeof notificationSerializer>;

export const alertSerializer = Serializers.object({
  uuid: uuidSerializer,
  recipientId: Serializers.string,
  type: Serializers.stringOneOf(...notificationTypes),
  message: Serializers.string,
  link: Serializers.string.nullable(),
  metric: Serializers.integer.nullable(),
  createdAt: Serializers.datetime,
});

export type AlertObject = SerializerValue<typeof alertSerializer>;

export const getNotificationsSerializer = Serializers.object({
  notifications: Serializers.array(notificationSerializer),
  alerts: Serializers.array(alertSerializer),
  count: Serializers.integer,
  unreadCount: Serializers.integer,
});

export type NotificationsListObject = SerializerValue<typeof getNotificationsSerializer>;

export const permissionSerializer = Serializers.object({
  type: Serializers.stringOneOf(...notificationTypes),
  isEmail: Serializers.boolean,
  isWebPush: Serializers.boolean,
  isApp: Serializers.boolean,
});

export const webPushNotificationPaylodSerializer = Serializers.object({
  title: Serializers.string,
  body: Serializers.string,
  image: Serializers.string.nullable(),
  link: Serializers.string.nullable(),
  userId: Serializers.string,
  app: Serializers.string,
  actions: Serializers.array(
    Serializers.object({
      title: Serializers.string,
      action: Serializers.stringOneOf("openLink"),
    }),
  ),
});

export type WebPushNotificationPayload = SerializerValue<typeof webPushNotificationPaylodSerializer>;

export const produceAlertSerializer = Serializers.object({
  recipientId: Serializers.string,
  type: Serializers.stringOneOf(...notificationTypes),
  message: Serializers.string,
  link: Serializers.string.nullable(),
  metric: Serializers.integer.nullable(),
});

export const notifications = {
  produceNotification: HttpAction({
    path: "/notification",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: produceNotificationSerializer,
    valueSerializer: notificationSerializer,
  }),

  getNotifications: HttpAction({
    path: "/notification",
    method: "get",
    qsSerializer: Serializers.object({
      app: Serializers.string,
    }).partial(),
    requiresAuth: true as const,
    valueSerializer: getNotificationsSerializer,
  }),

  readNotification: HttpAction({
    path: "/notification/:notificationId/read",
    method: "put",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      notificationId: Serializers.string,
    }),
  }),

  deactivateNotification: HttpAction({
    path: "/notification/:notificationId/deactivate",
    method: "put",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      notificationId: Serializers.string,
    }),
  }),

  getPermissions: HttpAction({
    path: "/permissions",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: Serializers.array(permissionSerializer),
  }),

  savePermission: HttpAction({
    path: "/permission",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      type: Serializers.stringOneOf(...notificationTypes),
      isEmail: Serializers.boolean,
      isWebPush: Serializers.boolean,
      isApp: Serializers.boolean,
    }),
  }),

  produceTestNotification: HttpAction({
    path: "/testNotification",
    method: "post",
    requiresAuth: true as const,
    valueSerializer: notificationSerializer,
  }),

  produceAlert: HttpAction({
    path: "/alert",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: produceAlertSerializer,
    valueSerializer: alertSerializer,
  }),
};
