import { Uuid } from "@megarax/common";
import { ChatBoxContainer } from "@megarax/crm-chat-webapp";
import { useAsyncLoad } from "@megarax/react-utils";
import { BreadcrumbMarker, PageTitleManager } from "@megarax/ui-components";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";

import { TagSelectRow } from "@megaron/megarax-v2-checklist-templates";
import { ContactListContainer } from "@megaron/megarax-v2-contacts";
import {
  useChainsResource,
  useCustomerActions,
  useCustomerQuery,
  useRegionsResource,
} from "@megaron/megarax-v2-resource-hooks";
import { Ok } from "@megaron/result";

import { ActionsMenu } from "./ActionsMenu";
import { AddressSection } from "./AddressSection";
import { CustomerRecentOrdersSectionContainer } from "./CustomerRecentOrdersSectionContainer";
import { PricingSectionContainer } from "./PricingSection";
import { RegionAndChainSection } from "./RegionAndChainSection";
import { RenameCustomerDialog } from "./RenameCustomerDialog";
import { TitleSection } from "./TitleSection";
import { UnverifiedAlert } from "./UnverifiedAlert";
import { ViewLayout } from "./ViewLayout";

interface Props {}

export const CustomerViewContainer: React.FC<Props> = ({}) => {
  const { uuid } = useParams<{ uuid: Uuid }>();

  const location = useLocation();
  const navigate = useNavigate();

  const { getChainsDictionary, getChainsArray } = useChainsResource();
  const { getRegionsDictionary, getRegionsItems } = useRegionsResource();
  const { data: customer, refetch, isFetching } = useCustomerQuery(uuid || ("" as Uuid));
  const {
    renameCustomer,
    reassignRegion,
    assignToChain,
    designateVisitLocation,
    untagCustomer,
    tagCustomer,
    changeStatus,
  } = useCustomerActions(uuid || ("" as Uuid), () => refetch().then());

  const { value: regionsDictionary } = useAsyncLoad(getRegionsDictionary, []);
  const { value: chainsDictionary } = useAsyncLoad(getChainsDictionary, []);

  const openRenameCustomerDialog = () => navigate(`${location.pathname}/zmien_nazwe`);
  const isRenameCustomerDialogOpen = location.pathname.endsWith("zmien_nazwe");

  return (
    <>
      <PageTitleManager title={customer?.name ?? "Klient"} />
      <BreadcrumbMarker title="Klienci" to={"/crm/pos/klienci"} id="customers" />
      {customer && <BreadcrumbMarker title={customer.name} id="customer" />}
      {isRenameCustomerDialogOpen && customer && (
        <RenameCustomerDialog onClose={() => navigate(-1)} initialName={customer.name} onChangeName={renameCustomer} />
      )}

      <ViewLayout
        alerts={customer && <UnverifiedAlert status={customer.status} onChangeStatus={changeStatus} />}
        titleSection={
          customer && (
            <TitleSection
              name={customer.name}
              status={customer.status}
              actionsMenu={
                <ActionsMenu
                  status={customer.status}
                  onChangeStatus={changeStatus}
                  onRename={openRenameCustomerDialog}
                />
              }
            />
          )
        }
        addressSection={
          customer && (
            <AddressSection onChangeVisitLocation={designateVisitLocation} visitLocation={customer.visitLocation} />
          )
        }
        tagSection={
          customer && (
            <TagSelectRow
              addTag={(t) => tagCustomer(t).then((r) => Ok(r))}
              removeTag={(t) => untagCustomer(t).then((r) => Ok(r))}
              currentTags={customer.tags.map((label) => ({ label }))}
            />
          )
        }
        regionAndChainSection={
          customer && (
            <RegionAndChainSection
              chainUuid={customer.chainUuid}
              regionUuid={customer.regionUuid}
              chainsDictionary={chainsDictionary ?? {}}
              getChains={getChainsArray}
              getRegions={(searchText) => getRegionsItems({ searchText })}
              onAssignToChain={assignToChain}
              onReassignRegion={reassignRegion}
              regionsDictionary={regionsDictionary ?? {}}
            />
          )
        }
        pricingSection={<PricingSectionContainer customerUuid={uuid || ("" as Uuid)} />}
        chat={
          <ChatBoxContainer
            threadIdentifier={{
              customerUuid: uuid || ("" as Uuid),
              type: "customer",
            }}
          />
        }
        contacts={<ContactListContainer customerUuid={uuid || ("" as Uuid)} />}
        recentOrders={<CustomerRecentOrdersSectionContainer customerUuid={uuid || ("" as Uuid)} />}
      />
    </>
  );
};
