import { useTheme } from "@emotion/react";
import { MdCheck } from "react-icons/md";
import tinycolor from "tinycolor2";

import { Avatar, DefaultAvatar } from "@megaron/dash-avatar";

type Props<TOptionValue> = {
  label: string;
  value: TOptionValue;
  imageUrl?: string | null;
  isSelected: boolean;
  onClick?: (value: TOptionValue) => void;
  className?: string;
  isUnselectDisabled?: boolean;
  showBorder?: boolean;
};

export const SelectListElement = <TOptionValue,>({
  label,
  value,
  isSelected,
  onClick,
  className,
  imageUrl,
  isUnselectDisabled,
  showBorder,
}: Props<TOptionValue>) => {
  const theme = useTheme();

  return (
    <div
      key={label}
      css={{
        cursor: isSelected && isUnselectDisabled ? "not-allowed" : "pointer",
        justifyContent: "space-between",
        borderRadius: theme.smallBorderRadius,
        width: "100%",
        display: "flex",
        alignItems: "center",
        background: "white",
        fontWeight: isSelected ? 700 : 400,
        overflow: "hidden",
        fontSize: "0.875rem",
        color: theme.colors.primary,
        border: showBorder
          ? isSelected
            ? `1px solid ${theme.colors.primary}`
            : `1px solid ${tinycolor("rgb(0, 0, 0)").setAlpha(0.1)}`
          : "none",
        "&:hover": {
          background: isSelected && isUnselectDisabled ? "white" : theme.colors.background,
        },
      }}
      className={className}
      onClick={isSelected && isUnselectDisabled ? undefined : () => onClick?.(value)}
    >
      <div
        style={{
          marginRight: "0.5rem",
          padding: "4px 12px",
          minHeight: "36px",
          display: "flex",
          alignItems: "center",
          gap: "0.625rem",
          textAlign: "left",
        }}
      >
        {imageUrl && <Avatar src={imageUrl} size="24px" />}
        {imageUrl === null && <DefaultAvatar pxSize={24} />}
        {label}
      </div>
      {isSelected && (
        <div
          css={{
            display: "flex",
            background: theme.colors.primary,
            minHeight: "36px",
            color: "white",
            padding: "10px",
            alignSelf: "stretch",
            alignItems: "center",
          }}
        >
          <MdCheck size="0.875rem" />
        </div>
      )}
    </div>
  );
};

export default SelectListElement;
