import { uuidSerializer } from "@megarax/common";
import { paginatedListSerializer, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { ChainSimple } from "./";

export interface ChainListQueryFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
}

const chainListQueryFiltersSerializer = MSerializers.object<ChainListQueryFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
});

const chainSimpleSerializer = MSerializers.object<ChainSimple>({
  uuid: uuidSerializer,
  name: MSerializers.string,
});

export default ResourceActionV2({
  name: "list",
  method: "get",
  path: "",
  querySerializer: chainListQueryFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(chainSimpleSerializer),
});
