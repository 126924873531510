import { BoolSelectChip } from "./BoolSelectChip";
import { FilterConfig } from "./FilterBar";
import { MultiSelectChip } from "./MultiSelectChip";
import { GetOptions, SearchMultiSelectChip } from "./SearchMultiSelectChip";
import { SelectChip } from "./SelectChip";

export const MultiSelectFilterConfig = <T extends string | number>(config: {
  label: string;
  options: { label: string; value: T }[];
}): FilterConfig<T[]> => ({
  ...config,
  render: (props) => (
    <MultiSelectChip<T>
      label={config.label}
      onChange={props.onChange}
      options={config.options}
      value={props.value}
      className={props.className}
      style={props.style}
    />
  ),
});

export const SelectFilterConfig = <T extends string | number>(config: {
  label: string;
  options: { label: string; value: T }[];
}): FilterConfig<T | null> => ({
  ...config,
  render: (props) => (
    <SelectChip<T>
      label={config.label}
      onChange={props.onChange}
      options={config.options}
      value={props.value}
      className={props.className}
      style={props.style}
    />
  ),
});

export const BoolSelectFilterConfig = <T extends { value: string | number | null; bool: boolean }>(config: {
  labels: {
    positive: string;
    negative: string;
    main: string;
  };
  label: string;
  options: { label: string; value: NonNullable<T["value"]> }[];
}): FilterConfig<T> => ({
  ...config,
  render: (props) => (
    <BoolSelectChip<NonNullable<T["value"]>>
      labels={config.labels}
      onChange={props.onChange as (val: { value: T["value"]; bool: boolean }) => void}
      options={config.options}
      value={props.value as { value: NonNullable<T["value"]> | null; bool: boolean }}
      className={props.className}
      style={props.style}
    />
  ),
});

export const SearchMultiSelectFilterConfig = <T extends string | number>(config: {
  label: string;
  getOptions: GetOptions<T>;
}): FilterConfig<T[]> => ({
  ...config,
  render: (props) => (
    <SearchMultiSelectChip<T>
      label={config.label}
      getOptions={config.getOptions}
      onChange={props.onChange}
      value={props.value}
      className={props.className}
      style={props.style}
    />
  ),
});
