import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { MdFolder, MdLink, MdOutlineContactPage, MdWallet } from "react-icons/md";
import { useLocation } from "react-router-dom-v5-compat";

import { CardLink } from "@megaron/dash-page";
import { DocsSelectDialog } from "@megaron/dash-select";
import { AnyDoc } from "@megaron/docs-contracts";

import { getAppColor, getLinkDocIcon } from "./helpers";

type Props = {
  renderTrigger: (onOpen: () => void) => React.ReactNode;
};

export const GlobalSearchDialog: React.FC<Props> = ({ renderTrigger }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const [pathname, setPathname] = useState<string>(location.pathname);

  useEffect(() => {
    if (location.pathname !== pathname) {
      setPathname(location.pathname);
      setIsOpen(false);
    }
  }, [location.pathname, pathname]);

  return (
    <DocsSelectDialog
      isOpen={isOpen}
      docType={["customer", "link", "pos-customer", "budget", "invoice-thread"]}
      variant="search"
      onClose={() => setIsOpen(false)}
      renderItem={(item) => <GlobalSearchListElement result={item} key={`global-search-item-${item.docId}`} />}
      renderTrigger={() => renderTrigger(() => setIsOpen(true))}
      hideDefaultResults
      hideHeader
    />
  );
};

const GlobalSearchListElement: React.FC<{ result: AnyDoc }> = ({ result }) => {
  const theme = useTheme();

  if (result.docType === "customer") {
    return (
      <CardLink
        color={theme.colors.crm}
        linkTo={`/crm/customers/id/${result.uuid}`}
        isNarrow
        icon={<MdOutlineContactPage css={{ width: "1rem", height: "1rem" }} />}
        css={{ border: "none" }}
      >
        <span
          css={{
            fontSize: "0.875rem",
            color: theme.colors.crm,
            fontWeight: 700,
            textAlign: "left",
          }}
        >{`${result.firstName ? `${result.firstName} ${result.lastName}` : result.phoneNumber}`}</span>

        {/* <div
                  css={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "flex-start" }}
                >
                  <div css={{ display: "flex", flexDirection: "column" }}>
                    <span css={{ fontSize: "0.875rem", color: theme.colors.crm, fontWeight: 700 }}>{`${
                      result.firstName ? `${result.firstName} ${result.lastName}` : result.phoneNumber
                    }`}</span>
                    {result.tags.length > 0 && (
                      <div css={{ fontSize: "0.75rem", color: theme.colors.secondaryText }}>
                        {`Tagi: ${result.tags?.map((tag) => `#${tag}`).join(" ")}`}
                      </div>
                    )}
                  </div>
                  {result.lastInteraction?.createdAt && (
                    <div
                      css={{
                        fontSize: "0.75rem",
                        color: theme.colors.secondaryText,
                        fontWeight: 400,
                        display: "flex",
                        alignItems: "center",
                        gap: "0.25rem",
                      }}
                    >
                      <MdOutlineBorderColor css={{ width: "0.625rem", height: "0.625rem" }} />
                      {formatDistanceToNowStrict(result.lastInteraction?.createdAt, { locale: pl, addSuffix: true })}
                    </div>
                  )}
                </div> */}
      </CardLink>
    );
  }

  if (result.docType === "link") {
    const Icon = getLinkDocIcon(result);
    const colorPrimary = getAppColor(result.app || "", theme);

    const isExternal = !result.baseUrl?.startsWith("/");

    return (
      <CardLink
        linkTo={`${result.baseUrl ?? ""}${result.url ?? ""}`}
        color={colorPrimary}
        icon={<Icon css={{ width: "1rem", height: "1rem" }} />}
        isExternal={isExternal}
        isNarrow
        css={{ border: "none", textAlign: "left" }}
      >
        <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <span
            css={{
              fontSize: "0.875rem",
              color: colorPrimary,
              fontWeight: 700,
            }}
          >
            {result.name}
          </span>
          <MdLink css={{ width: "1rem", height: "1rem", color: theme.colors.default }} />
        </div>
      </CardLink>
    );
  }

  if (result.docType === "pos-customer") {
    return (
      <CardLink
        color={theme.colors.crm}
        linkTo={`/crm/pos/klienci/${result.uuid}`}
        icon={<MdOutlineContactPage css={{ width: "1rem", height: "1rem" }} />}
        css={{ border: "none", textAlign: "left" }}
        isNarrow
      >
        <span
          css={{
            fontSize: "0.875rem",
            color: theme.colors.crm,
            fontWeight: 700,
          }}
        >
          {result.name}
        </span>
      </CardLink>
    );
  }

  if (result.docType === "budget") {
    return (
      <CardLink
        color={theme.colors.invoices}
        linkTo={`/faktury/budzety/${result.uuid}`}
        icon={<MdWallet css={{ width: "1rem", height: "1rem" }} />}
        isNarrow
        css={{ border: "none", textAlign: "left" }}
      >
        <span
          css={{
            fontSize: "0.875rem",
            color: theme.colors.invoices,
            fontWeight: 700,
          }}
        >
          {result.name}
        </span>
      </CardLink>
    );
  }

  if (result.docType === "invoice-thread") {
    return (
      <CardLink
        color={theme.colors.invoices}
        linkTo={`/faktury/watki/${result.threadUuid}`}
        icon={<MdFolder css={{ width: "1rem", height: "1rem" }} />}
        isNarrow
        css={{ border: "none", textAlign: "left" }}
      >
        <span
          css={{
            fontSize: "0.875rem",
            color: theme.colors.invoices,
            fontWeight: 700,
          }}
        >
          {result.referenceNumber} {result.title}
        </span>
      </CardLink>
    );
  }

  return null;
};
