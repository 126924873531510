import { Uuid } from "@megarax/common";
import { Skeleton } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { ContactCreatorDialog } from "./ContactCreatorDialog";
import { ContactList } from "./ContactList";
import { useCustomerContacts } from "./useCustomerContacts";

interface Props {
  customerUuid: Uuid;
}

export const ContactListContainer: React.FC<Props> = ({ customerUuid }) => {
  const { retrieveCustomerContacts, saveContact, removeContact } = useCustomerContacts();

  const location = useLocation();
  const navigate = useNavigate();

  const openNewContactDialog = () => navigate(`${location.pathname}/kontakt/nowy`);
  const openEditContactDialog = (contactUuid: Uuid) => navigate(`${location.pathname}/kontakt/${contactUuid}`);

  const isNewContactDialogOpen = location.pathname.endsWith("kontakt/nowy");
  const isEditContactDialogOpen = location.pathname.includes("kontakt/");

  const contacts = useQuery(["contact-list", customerUuid], () => retrieveCustomerContacts(customerUuid));

  const pathnameElements = location.pathname.split("/");
  const contactUuid = pathnameElements[pathnameElements.length - 1];

  return (
    <>
      {isEditContactDialogOpen && contacts.data?.value && (
        <ContactCreatorDialog
          contact={contacts.data?.value?.contacts.find((contact) => contact.uuid === contactUuid) ?? null}
          saveContact={saveContact({ onSuccess: contacts.refetch })(customerUuid)}
          onClose={() => navigate(-1)}
        />
      )}
      {isNewContactDialogOpen && (
        <ContactCreatorDialog
          contact={null}
          saveContact={saveContact({ onSuccess: contacts.refetch })(customerUuid)}
          onClose={() => navigate(-1)}
        />
      )}
      {contacts.data?.value ? (
        <ContactList
          contactList={contacts.data.value}
          removeContact={removeContact({ onSuccess: contacts.refetch })(customerUuid)}
          navigation={{
            openCreateNewDialog: openNewContactDialog,
            openEditDialog: openEditContactDialog,
          }}
        />
      ) : (
        <Skeleton height="100px" />
      )}
    </>
  );
};
