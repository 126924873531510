import { useEffect } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";

import { Serializer, Serializers } from "@megaron/serializers";

export const useQsFilters = <T>(serializer: Serializer<T>, defaultFilters: T) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filtersQs = searchParams.get("filters");

  const filters = Serializers.jsonString(serializer).deserialize(filtersQs)?.value || defaultFilters;

  useEffect(() => {
    if (!filtersQs) {
      setFilters(filters);
    }
  }, [searchParams]);

  const setFilters = (newFilters: T) => {
    const serialized = serializer.serialize(newFilters);

    searchParams.set("filters", JSON.stringify(serialized));
    setSearchParams(searchParams, { replace: true });
  };

  const setFilter =
    <TKey extends keyof T>(key: TKey) =>
    (value: T[TKey]) => {
      setFilters({ ...filters, [key]: value });
    };

  return { filters, setFilters, setFilter } as const;
};
