import { Check, Delete } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { CustomerStatus } from "@megarax/crm-contracts";

interface Props {
  status: CustomerStatus;
  onChangeStatus: (status: CustomerStatus) => void;
}

export const UnverifiedAlert: React.FunctionComponent<Props> = ({ status, onChangeStatus }) => {
  const classes = useStyles();

  if (status !== "unverified") return null;

  return (
    <Box mb={2}>
      <Alert severity="warning">
        <AlertTitle>Klient do weryfikacji</AlertTitle>
        <Button startIcon={<Check />} className={classes.button} onClick={() => onChangeStatus("verified")}>
          Zweryfikowany
        </Button>
        <Button startIcon={<Delete />} className={classes.button} onClick={() => onChangeStatus("inactive")}>
          Nieaktywny
        </Button>
      </Alert>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
