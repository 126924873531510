import { useTheme } from "@emotion/react";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import tinycolor from "tinycolor2";

type Props = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  className?: string;
  multiline?: boolean;
  inputElementProps?: React.InputHTMLAttributes<HTMLInputElement>;
  isDisabled?: boolean;
  autoFocus?: boolean;
  getOptions: (search: string) => Promise<string[]>;
  keyName: string;
};

export const AutocompleteTextField: React.FunctionComponent<Props> = ({
  value,
  onChange,
  label,
  className,
  multiline = false,
  isDisabled = false,
  autoFocus = false,
  getOptions,
  inputElementProps,
  keyName,
}) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [options, setOptions] = useState<string[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const ignoreBlur = useRef(false);

  const debouncedGetOptions = useRef(
    debounce(
      (text: string) =>
        getOptions(text).then((options) => {
          setOptions(options);
        }),
      200,
    ),
  );

  useEffect(() => {
    if (autoFocus) {
      if (multiline && textAreaRef.current) {
        textAreaRef.current.focus();
        textAreaRef.current.setSelectionRange(value.length, value.length);
      } else if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.selectionStart = value.length;
        inputRef.current.selectionEnd = value.length;
      }
    }
  }, [autoFocus, value, multiline]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(newValue);
    debouncedGetOptions.current(newValue);
    setShowOptions(true);
  };

  const handleOptionClick = (option: string) => {
    onChange(option);
    setShowOptions(false);
  };

  const [currentOption, setCurrentOption] = useState<number | undefined>(0);

  const handleInputFocus = () => {
    const newCurrentIndex = options.findIndex((option) => option === value);

    if (newCurrentIndex !== currentOption) {
      setCurrentOption(newCurrentIndex);
    }
  };

  useEffect(() => {
    if (options.length === 0) {
      setCurrentOption(undefined);
    }

    if (options.length > 0 && currentOption === undefined) {
      setCurrentOption(0);
    }
  }, [options, currentOption]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (options.length > 0 && currentOption !== undefined) {
        if (e.key === "ArrowDown") {
          e.preventDefault();

          if (currentOption < options.length - 1) {
            document
              .getElementById(`autocomplete-option-${keyName}-${currentOption + 1}`)
              ?.scrollIntoView({ behavior: "smooth", block: "center" });

            setCurrentOption(currentOption + 1);
          }
        }

        if (e.key === "ArrowUp") {
          e.preventDefault();

          if (currentOption > 0) {
            document
              .getElementById(`autocomplete-option-${keyName}-${currentOption - 1}`)
              ?.scrollIntoView({ behavior: "smooth", block: "center" });

            setCurrentOption(currentOption - 1);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [options, currentOption, keyName]);

  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter" && currentOption !== undefined) {
        e.preventDefault();
        const resultElement = document.getElementById(`autocomplete-option-${keyName}-${currentOption}`);

        if (resultElement) {
          const linkElement = resultElement.querySelector("a");
          if (linkElement) {
            linkElement.click();
          } else {
            resultElement.click();
          }
        }
      }
    };

    document.addEventListener("keydown", handleEnter);

    return () => document.removeEventListener("keydown", handleEnter);
  }, [currentOption, keyName]);

  const inputProps = {
    value,
    onChange: handleChange,
    css: {
      width: "100%",
      border: "none",
      padding: "0.5rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "6px",
      backgroundColor: theme.colors.primaryLight,
      ":focus": {
        outline: `1px solid ${theme.colors.primary}`,
      },
      disabled: {
        cursor: "text",
      },
    },
    disabled: isDisabled ? true : false,
    onBlur: () => {
      if (!ignoreBlur.current) {
        setShowOptions(false);
      }

      ignoreBlur.current = false;
    },
    onFocus: handleInputFocus,
  };

  const element = multiline ? (
    <textarea ref={textAreaRef} {...inputProps} />
  ) : (
    <input ref={inputRef} {...inputProps} {...inputElementProps} type="text" />
  );

  return (
    <div css={{ position: "relative", width: "100%" }} className={className}>
      <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {label && (
          <label
            css={{
              color: theme.colors.primary,
              fontSize: "0.875rem",
              fontFamily: theme.displayFontFamily,
              marginBottom: "0.25rem",
            }}
          >
            {label}
          </label>
        )}
        {element}
      </div>
      {showOptions && options.length > 0 && (
        <ul
          css={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            backgroundColor: "white",
            border: `1px solid ${tinycolor("#000000").setAlpha(0.1)}`,
            boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
            borderRadius: "6px",
            listStyle: "none",
            padding: 0,
            margin: "0.5rem 0 0 0",
            zIndex: 10,
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {options.map((option, index) => (
            <li
              key={option}
              id={`autocomplete-option-${keyName}-${index}`}
              onMouseDown={() => (ignoreBlur.current = true)}
              onClick={() => handleOptionClick(option)}
              css={{
                padding: "0.5rem 0.75rem",
                cursor: "pointer",
                backgroundColor: index === currentOption ? theme.colors.background : "transparent",
                ":hover": {
                  backgroundColor: theme.colors.background,
                },
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
