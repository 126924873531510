import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import React from "react";

import { InputProps } from "../props";
import makeStyles from "@mui/styles/makeStyles";

interface Props extends InputProps<Date | null> {
  fullWidth?: boolean;
  disablePast?: boolean;
  inputFormat?: string;
  maxDate?: Date;
  minDate?: Date;
}

export const DateInput: React.FC<Props> = (props) => {
  const { onChange, value, helperText, label, fullWidth, error, ...muiProps } = props;

  const styles = useStyles();

  return (
    <DatePicker<Date | null>
      {...muiProps}
      className={styles.picker}
      label={label}
      value={value}
      onChange={onChange}
      format="dd.MM.yyyy"
    />
  );
};

const useStyles = makeStyles(() => ({
  picker: {
    width: "100%",
  },
}));
