import { GroupListFilters, groupResource } from "@megarax/iam-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Result } from "@megaron/result";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

const promisifyResult = <TValue, TError>(result: Result<TValue, TError>): Promise<TValue> =>
  new Promise((resolve, reject) => {
    if (result.isFailure) return reject(result.error);
    resolve(result.value);
  });

export const useIamGroupsResource = () => {
  const groupsProvider = useResourceProviderV2(groupResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const getIamGroups = (filters: GroupListFilters) =>
    groupsProvider
      .list(filters)
      .mapError(handleError)
      .map((result) => result.items)
      .then(promisifyResult);

  return {
    getIamGroups,
  };
};
