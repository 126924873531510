import Decimal from "decimal.js";

import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

import { ProductCodeStatus, productCodeStatusSerializer } from "./codeStatus";

export type ProductCodeDto = {
  id: string;
  status: ProductCodeStatus;
  pointValue: Decimal | null;
  productId: string | null;
};

export const productCodeDtoSerializer = Serializers.object<ProductCodeDto>({
  id: Serializers.string,
  status: productCodeStatusSerializer,
  pointValue: Serializers.decimal.nullable(),
  productId: Serializers.string.nullable(),
});
