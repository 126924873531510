import { UnsafeSerializer } from "./serializer";
import { optional } from "./serializers/optional";

const nullable = <T>(serializer: UnsafeSerializer<T>): UnsafeSerializer<T | null> => ({
  ...serializer,
  serialize: (value) => {
    if (value === null) return value;
    return serializer.serialize(value);
  },
  forceDeserialize: (raw) => {
    if (raw === null) return raw;
    return serializer.forceDeserialize(raw);
  },
});

export interface SerializerExtensions<T> {
  nullable: () => UnsafeSerializer<T | null>;
  optional: () => UnsafeSerializer<T | undefined>;
}

export const serializerExtensions = <T>(): SerializerExtensions<T> => ({
  nullable(this: any) {
    return nullable(this);
  },
  optional(this: any) {
    return optional(this);
  },
});
