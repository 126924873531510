import { AnyUser } from "@megaron/access-control";
import { HttpAction, HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";

import { notifications } from "./notifications";
import { webPush } from "./webPush";

export const notificationsHttpService = HttpService({
  ...notifications,
  ...webPush,
});

export type NotificationsHandlers = HandlerMap<typeof notificationsHttpService, AnyUser>;
