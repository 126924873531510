import { useState } from "react";
import { MdMoreVert, MdOutlineEdit, MdOutlineLocalPrintshop } from "react-icons/md";
import { useQueryClient } from "react-query";

import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { Dropdown } from "@megaron/dash-dropdown";
import { Button, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { ThreadDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

import { PrintViewContainer } from "./PrintView/PrintViewContainer";

type Props = {
  thread: ThreadDto;
  queryKey: string | string[];
};

export const HeaderBarSettings: React.FC<Props> = ({ thread, queryKey }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useDeviceType();

  const [title, setTitle] = useState(thread.title);
  const [print, setPrint] = useState<{
    uuid: string | undefined;
    active: boolean;
  }>({ uuid: undefined, active: false });

  const renameThreadMutation = useClientManager("invoices").renameThread().useMutation();

  const onPrint = () => {
    setPrint({ active: true, uuid: thread.uuid });
  };

  const onPrintEnd = () => setPrint({ active: false, uuid: undefined });

  const renameDialog = useDialogRoute(`/zmien-nazwe`, ({ onClose }) => (
    <Dialog onClose={onClose} placement={isMobile ? "top" : "center"} header={"Zmień nazwę"} css={{ width: "400px" }}>
      {title && (
        <div css={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <TextField value={title} onChange={setTitle} autoFocus />
          <Button
            onClick={() => {
              renameThreadMutation.mutate(
                {
                  title: title,
                  threadUuid: thread.uuid,
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries(queryKey);
                    onClose();
                  },
                },
              );
            }}
          >
            Zapisz
          </Button>
        </div>
      )}
    </Dialog>
  ));

  return (
    <>
      <Dropdown openButton={<MdMoreVert color="white" css={{ cursor: "pointer", fontSize: "24px" }} />}>
        <div
          css={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "6px 8px",
            cursor: "pointer",
          }}
          onClick={renameDialog.open}
        >
          <MdOutlineEdit />
          Zmień nazwę
        </div>
        {thread.status === "approved" && (
          <div
            css={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "6px 8px",
              cursor: "pointer",
            }}
            onClick={onPrint}
          >
            <MdOutlineLocalPrintshop />
            Drukuj
          </div>
        )}
      </Dropdown>
      {renameDialog.element}
      {print.active && print.uuid && <PrintViewContainer threadUuid={thread.uuid} onPrintEnd={onPrintEnd} />}
    </>
  );
};
