import { ValidationError } from "../errors";
import { serializerExtensions, SerializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export const boolean: UnsafeSerializer<boolean> & SerializerExtensions<boolean> = {
  serialize: (value) => value,
  forceDeserialize: (raw) => {
    if (raw === null) throw new ValidationError("Expected boolean. Received null");
    if (raw === undefined) throw new ValidationError("Expected boolean. Received undefined");
    return Boolean(raw);
  },
  ...serializerExtensions(),
};

export const booleanString: UnsafeSerializer<boolean> & SerializerExtensions<boolean> = {
  serialize: (value) => value.toString(),
  forceDeserialize: (raw) => {
    if (raw === "false") return false;
    if (raw === "true") return true;
    else throw new ValidationError(`Input value must be either "true" or "false" string. Received: ${raw}`);
  },
  ...serializerExtensions(),
};
