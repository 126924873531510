import { useQuery } from "react-query";

import { profileResource } from "@megarax/iam-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";

export const useMentions = () => {
  const profileProvider = useResourceProviderV2(profileResource);
  const usersQuery = useQuery(["users"], () =>
    profileProvider
      .list({ searchText: "", limit: 1000 })
      .map((result) =>
        result.items.map((item) => {
          if (!item.firstName && !item.lastName) return null;
          return {
            id: item.user.id,
            name: item.firstName ?? "",
            surname: item.lastName ?? "",
            pictureUrl: item.pictureUrl ?? null,
          };
        }),
      )
      .map((result) =>
        result.filter((x): x is { id: number; name: string; surname: string; pictureUrl: string | null } => x !== null),
      ),
  );

  const getMentionableUsers = (search: string, limit?: number) => {
    return usersQuery.data?.isOk
      ? usersQuery.data.value
          .filter(
            (item) =>
              item.name.toLocaleLowerCase().startsWith(search.toLocaleLowerCase()) ||
              item.surname.toLocaleLowerCase().startsWith(search.toLocaleLowerCase()),
          )
          .map((user) => ({ ...user, display: `@${user.name ?? ""} ${user.surname ?? ""}`.trim() }))
          .slice(0, limit ?? 10)
      : [];
  };

  return { getMentionableUsers };
};
