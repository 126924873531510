import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const accountChangedTopic = PubsubTopic({
  name: "loyalty-accounts-account-changed",
  serializer: Serializers.object({
    accountUuid: uuidSerializer,
    changeType: Serializers.stringOneOf(
      "userPreregistered",
      "userJoined",
      "userRegistered",
      "profileUpdated",
      "emailVerified",
      "accountDeleted",
    ),
  }),
});

export type AccountChangedMessage = SerializerValue<typeof accountChangedTopic.serializer>;

export const accountsTopics = [accountChangedTopic];
