import { dateStringSerializer, UuidResourceParam } from "@megarax/common";
import { BaseResource, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import {
  cancelVisitErrors,
  changeEndTimeErrors,
  changeStartTimeErrors,
  endVisitErrors,
  myVisitQueryErrors,
  planMyVisitErrors,
  startVisitErrors,
} from "./errors";
import {
  changeEndTimeInputSerializer,
  changeStartTimeInputSerializer,
  customerRecentVisitsQueryFiltersSerializer,
  planMyVisitInputSerializer,
} from "./inputs";
import { visitDtoSerializer } from "./visitDto";

const userDayAction = ResourceActionV2({
  name: "userDay",
  method: "get",
  querySerializer: MSerializers.object({
    date: MSerializers.dateOnly,
    userId: MSerializers.integer,
  }),
  responseValueSerializer: MSerializers.array(visitDtoSerializer),
});

const myDayAction = ResourceActionV2({
  name: "myDay",
  method: "get",
  querySerializer: MSerializers.object({
    date: MSerializers.dateOnly,
  }),
  responseValueSerializer: MSerializers.array(visitDtoSerializer),
});

const myUnfinishedVisitsAction = ResourceActionV2({
  name: "myUnfinishedVisits",
  method: "get",
  responseValueSerializer: MSerializers.array(visitDtoSerializer),
});

const customerRecentVisitsQuery = ResourceActionV2({
  name: "customerRecentVisits",
  method: "get",
  querySerializer: customerRecentVisitsQueryFiltersSerializer,
  responseValueSerializer: MSerializers.array(visitDtoSerializer),
});

export const visitResource = BaseResource(["crm", "visits"], {
  myDay: myDayAction,
  userDay: userDayAction,
  myUnfinishedVisits: myUnfinishedVisitsAction,
  refreshScheduleDay: ResourceActionV2({
    name: "refreshScheduleDay",
    method: "post",
    requestBodySerializer: MSerializers.object({
      userId: MSerializers.integer,
      date: dateStringSerializer,
    }),
  }),
  customerRecentVisits: customerRecentVisitsQuery,
  byUuid: UuidResourceParam("uuid", "uuid", {
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      path: "",
      responseValueSerializer: visitDtoSerializer,
      responseErrorSerializer: MSerializers.stringOneOf(...myVisitQueryErrors),
    }),
    cancel: ResourceActionV2({
      name: "cancelVisit",
      method: "post",
      responseErrorSerializer: MSerializers.stringOneOf(...cancelVisitErrors),
    }),

    start: ResourceActionV2({
      name: "start",
      method: "post",
      responseErrorSerializer: MSerializers.stringOneOf(...startVisitErrors),
    }),
    end: ResourceActionV2({
      name: "end",
      method: "post",
      responseErrorSerializer: MSerializers.stringOneOf(...endVisitErrors),
    }),
    changeStartTime: ResourceActionV2({
      name: "changeStartTime",
      method: "post",
      requestBodySerializer: changeStartTimeInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf(...changeStartTimeErrors),
    }),
    changeEndTime: ResourceActionV2({
      name: "changeEndTime",
      method: "post",
      requestBodySerializer: changeEndTimeInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf(...changeEndTimeErrors),
    }),
    plan: ResourceActionV2({
      name: "plan",
      method: "post",
      requestBodySerializer: planMyVisitInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf(...planMyVisitErrors),
    }),
  }),
});
