import _ from "lodash";
import React from "react";

import { Google, LatLng } from "../mapsCommon/MapContainer";
import { TradeRouteCustomer, TravelData } from "./TradeRouteDetails";

export const useDrawRoute = ({
  setTravelData,
  google,
  map,
}: {
  setTravelData: (input: TravelData[]) => void;
  google: Google;
  map: google.maps.Map;
}) => {
  const [service] = React.useState<google.maps.DirectionsService>(new google.maps.DirectionsService());
  const [renderer] = React.useState<google.maps.DirectionsRenderer>(
    new google.maps.DirectionsRenderer({
      ...directionsRendererOptions,
      map: map,
    }),
  );
  const [returnRenderer] = React.useState<google.maps.DirectionsRenderer>(
    new google.maps.DirectionsRenderer({
      ...directionsRendererOptions,
      map: map,
      polylineOptions: returnRendererPolylineOptions,
    }),
  );

  const drawRoute = async (route: TradeRouteCustomer[], homeEntryCoordinates: LatLng | null) => {
    if (!homeEntryCoordinates || route.length === 0) {
      renderer.setDirections({ routes: [] } as any);
      returnRenderer.setDirections({ routes: [] } as any);
      setTravelData([]);
      return;
    }

    const routeWithPositions = route.map((stop) => {
      const position = { lat: stop.visitAddress.lat.toNumber(), lng: stop.visitAddress.lng.toNumber() };

      return { ...stop, position: position };
    });

    const googleRoute: google.maps.DirectionsRequest = {
      origin: new google.maps.LatLng(homeEntryCoordinates),
      destination: routeWithPositions[routeWithPositions.length - 1].position,
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints: routeWithPositions.slice(0, -1).map((stop) => ({
        location: new google.maps.LatLng(stop.position),
      })),
    };
    const googleReturnRoute: google.maps.DirectionsRequest = {
      origin: new google.maps.LatLng(routeWithPositions[routeWithPositions.length - 1].position),
      destination: new google.maps.LatLng(homeEntryCoordinates),
      travelMode: google.maps.TravelMode.DRIVING,
    };
    const responses = await Promise.all(
      route.length > 1 ? [service.route(googleRoute), service.route(googleReturnRoute)] : [service.route(googleRoute)],
    );

    renderer.setDirections(responses[0] ?? { routes: [] });
    returnRenderer.setDirections(responses[1] ?? ({ routes: [] } as any));

    if (responses[0]?.routes[0]?.legs && (responses[1]?.routes[0]?.legs || route.length === 1))
      setTravelData(
        routeDistancesFromDirections(
          responses[0]?.routes[0]?.legs,
          responses[1]?.routes[0]?.legs[0] ?? responses[0]?.routes[0]?.legs[0],
        ),
      );
  };

  const debouncedDrawRoute = React.useRef(
    _.debounce(
      (route: TradeRouteCustomer[], homeEntryCoordinates: LatLng | null) => drawRoute(route, homeEntryCoordinates),
      500,
    ),
  );

  return {
    debouncedDrawRoute: debouncedDrawRoute.current,
  };
};

export const routeDistancesFromDirections = (
  legs: google.maps.DirectionsLeg[],
  returnLeg: google.maps.DirectionsLeg,
) => {
  return [
    ...legs.map((leg) => ({
      duration: leg.duration?.value ?? 0,
      distance: leg.distance?.value ?? 0,
    })),
    { duration: returnLeg.duration?.value ?? 0, distance: returnLeg.distance?.value ?? 0 },
  ];
};

const directionsRendererOptions = {
  suppressInfoWindows: true,
  suppressMarkers: true,
  preserveViewport: true,
};

const returnRendererPolylineOptions = {
  strokeColor: "#6c7a91",
  strokeOpacity: 0.5,
  strokeWeight: 5,
};
