import _ from "lodash";

import { Uuid } from "@megarax/common";
import { ChainListQueryFilters, chainResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Failure, Ok, Result } from "@megaron/result";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

type OnSuccessFunction = (() => void) | (() => Promise<void>);

const voidifyResult = <TValue, TError>(result: Result<TValue, TError>): Result<void, void> =>
  result.flatMapError(() => Failure()).flatMap(() => Ok());

const promisifyResult = <TValue, TError>(result: Result<TValue, TError>): Promise<TValue> =>
  new Promise((resolve, reject) => {
    if (result.isFailure) return reject(result.error);
    resolve(result.value);
  });

export const useChainsResource = () => {
  const chainProvider = useResourceProviderV2(chainResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const nameChain =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, name: string) =>
      chainProvider
        .byUuid(uuid)
        .nameChain(undefined, { name })
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const getChains = (filters: ChainListQueryFilters) => chainProvider.list(filters).mapError(handleError);

  const getChainsArray = (searchText: string) =>
    getChains({ searchText })
      .map((result) => result.items)
      .then(promisifyResult);

  const getChainsDictionary = () =>
    getChains({ limit: 1000 })
      .map((result) => _.keyBy(result.items, "uuid"))
      .then(promisifyResult);

  return {
    nameChain,
    getChains,
    getChainsArray,
    getChainsDictionary,
  };
};
