import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";

interface Props {}

export const PermissionDeniedView: React.FC<Props> = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const goBack = () => history.goBack();
  const goHome = () => history.push("/");
  return (
    <div>
      <div className={classes.container}>
        <Typography variant="h4" align="center">
          Nie posiadasz wystarczających uprawnień do wyświetlenia tej strony.
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button variant="contained" onClick={goBack}>
          Wróć do poprzedniej strony
        </Button>
        <Button variant="contained" color="primary" onClick={goHome}>
          Strona główna
        </Button>
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginTop: "10%",
      marginBottom: theme.spacing(6),
      display: "flex",
      justifyContent: "center",
      "& > h4": {
        maxWidth: "600px",
      },
    },
    actions: {
      margin: theme.spacing(4),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& > button": {
        margin: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  };
});
