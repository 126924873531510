import { AxiosError } from "axios";

export const sanitizeError = (err: unknown): unknown => {
  if (err instanceof AxiosError)
    return {
      name: err.name,
      message: err.message,
      stack: err.stack,
      code: err.code,
      url: err.config?.url,
      baseUrl: err.config?.baseURL,
      responseStatus: err.response?.status,
      responseData: err.response?.data,
    };

  if (err instanceof Error)
    return {
      ...err,
      name: err.name,
      message: err.message,
      stack: err.stack,
    };

  return err;
};
