import { newUuid, Uuid } from "@megarax/common";
import { orderDraftResource, orderResource } from "@megarax/crm-contracts";
import { useProfile } from "@megarax/iam-webapp";
import { useResourceProviderV2 } from "@megarax/react-client";
import { formatCurrency } from "@megarax/react-utils";
import { BreadcrumbMarkers, commonErrorsMap, PageTitleManager, useSnackbarErrorHandler } from "@megarax/ui-components";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { useCustomer } from "@megaron/megarax-v2-resource-hooks";

import { LinesTable } from "./LinesTable";
import { OrderHeader } from "./OrderHeader";

export const OrderViewContainer = () => {
  const { uuid } = useParams<{ uuid: Uuid }>();
  const navigate = useNavigate();

  const provider = useResourceProviderV2(orderResource).byUuid(uuid || ("" as Uuid));
  const draftProvider = useResourceProviderV2(orderDraftResource);

  const orderQuery = useQuery(["bcOrder", uuid], () => provider.retrieve());
  const sellTo = useCustomer(orderQuery.data?.value?.sellToCustomerUuid);
  const billTo = useCustomer(orderQuery.data?.value?.billToCustomerUuid);
  const placedByProfile = useProfile(
    orderQuery.data?.value?.placedByUserId ? { id: orderQuery.data?.value?.placedByUserId } : undefined,
  );

  const errorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
    DraftNotFound: "Tylko zamówienia złożone w megarax.net mogą być kopiowane.",
  });

  const createDraft = () => {
    const newOrderUuid = newUuid();
    return draftProvider
      .createCopy(undefined, { templateUuid: uuid || ("" as Uuid), uuid: newOrderUuid })
      .map(() => {
        navigate("/crm/pos/moje_zamowienia/" + newOrderUuid);
      })
      .mapError(errorHandler);
  };

  if (!orderQuery.data)
    return (
      <Box display="flex" alignItems="center" width="100%" mt={4} flexDirection="column">
        <Box>
          <CircularProgress />
        </Box>
      </Box>
    );

  if (orderQuery.data.isFailure) return <Container maxWidth="md">Wystąpił błąd</Container>;

  const order = orderQuery.data.value;
  if (order === null) return <Container maxWidth="md">Nie znaleziono</Container>;

  return (
    <Container maxWidth="md">
      <BreadcrumbMarkers
        breadcrumbs={[
          { title: "Zamówienia", to: "/crm/pos/zamowienia", id: "orders" },
          { id: "order", title: order.referenceNumber },
        ]}
      />

      <PageTitleManager title={order.referenceNumber} />
      <OrderHeader
        referenceNumer={order.referenceNumber}
        sellToName={sellTo?.name ?? ""}
        sellToUuid={order.sellToCustomerUuid}
        billToName={billTo?.name ?? ""}
        billToUuid={order.billToCustomerUuid}
        onCreateDraft={createDraft}
        placedByName={placedByProfile?.fullName ?? null}
      />
      <LinesTable lines={order.lines} currency={order.currency} />
      <Box my={2} mb={6} display="flex" flexDirection="column">
        <Typography textAlign="right">Suma netto</Typography>
        <Typography textAlign="right" variant="h5">
          {formatCurrency(order.netTotal, order.currency)}
        </Typography>
      </Box>
    </Container>
  );
};
