import { Month } from "@megarax/crm-contracts";
import { Table, TableColumn } from "@megarax/ui-components";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import React from "react";

import { DiscountTableRow } from "./DiscountsView";

interface Props {
  rows: DiscountTableRow[];
}

export const DiscountTableDataGrid: React.FunctionComponent<Props> = ({ rows }) => {
  const classes = useStyles();

  const months = (_.keys(rows[0]?.discountRates) as Month[]).sort();

  const columns: TableColumn<DiscountTableRow>[] = [
    {
      title: "UUID",
      getValue: (r) => <span className={classes.uuid}>{r.customerUuid}</span>,
    },
    {
      title: "Nazwa klienta",
      getValue: (r) => r.customerName ?? "",
    },
    ...months.map<TableColumn<DiscountTableRow>>((month) => ({
      title: month,
      getValue: (r) => r.discountRates[month]?.mul(100).toFixed(2) + "%" ?? "-",
    })),
  ];

  return (
    <div>
      <Table stickyHeader items={rows} getKey={(r) => r.customerUuid} columns={columns}></Table>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  uuid: {
    fontFamily: "Monospace",
  },
}));
