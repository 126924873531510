import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

export const useNewRouterDialog = (name: string) => {
  const navigate = useNavigate();
  const location = useLocation();

  const open = (value?: string) => {
    const valueToSet = typeof value === "string" ? value : "true";

    const paramsCopy = {
      ...qs.parse(location.search.substring(1)),
      [name]: valueToSet,
    };
    navigate({ search: qs.stringify(paramsCopy), hash: location.hash });
  };

  const close = () => {
    const paramsCopy = qs.parse(location.search.substring(1));
    delete paramsCopy[name];
    navigate({ search: qs.stringify(paramsCopy), hash: location.hash }, { replace: true });
  };

  const value = `${qs.parse(location.search.substring(1))[name]}`;

  return { open, close, value, name };
};
