import { Box, Checkbox, Divider, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

import { Uuid } from "@megarax/common";

interface Props {
  isDisabled: boolean;
  onToggleTask: (uuid: Uuid) => void;
  isLoading: boolean;
  tasks: { uuid: Uuid; label: string; completedAt: Date | null }[];
}

export const ChecklistSection: React.FC<Props> = ({
  tasks,
  onToggleTask,
  isLoading,
  isDisabled,
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" sx={{ gap: 2 }}>
      {tasks.length === 0 && (
        <Typography align="center" variant="caption" color="textSecondary" py={2}>
          Brak zadań
        </Typography>
      )}
      {tasks.map((task, idx) => {
        return (
          <Box
            key={task.uuid}
            display="flex"
            gap="12px"
            pr="8px"
            borderRadius="8px"
            alignItems="flex-start"
            sx={{ backgroundColor: theme.palette.grey[50] }}
          >
            <Checkbox
              checked={task.completedAt !== null}
              onChange={() => onToggleTask(task.uuid)}
              disabled={isDisabled || isLoading}
              sx={{ p: "4px", m: "4px" }}
            />
            <Typography
              sx={{
                mt: 1,
                pb: "4px",
                textDecoration: task.completedAt !== null ? "line-through" : "none",
                color: task.completedAt !== null ? theme.palette.grey[400] : "inherit",
                whiteSpace: "pre-line",
              }}
            >
              {task.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
