import { tags } from "@megaron/crm-contracts";
import { BoolFilter, FilterList, FlagFilter, SelectFilter, SelectFilterDocs, Sort } from "@megaron/dash-filters";
import { CustomerSortField, getDocId } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";

import { Filters, SortFilterValue } from "./CustomersHome";

type Props = {
  filters: Filters;
  handlers: {
    onProChange: (isPro: boolean) => void;
    onAffiliateChange: (isAffiliate: boolean) => void;
    onArchivedChange: (isArchived: boolean | undefined) => void;
    onUnassignedChange: (isUnassigned: boolean | undefined) => void;
    onActiveChange: (isActive: boolean | undefined) => void;
    onTagsChange: (tags: string[] | undefined) => void;
    onRegionsChange: (regions: string[] | undefined) => void;
    onPosChange: (pos: string[] | undefined) => void;
    onSortChange: (sort: { field: CustomerSortField; order?: "ASC" | "DESC" | undefined } | undefined) => void;
  };
  hideSortFilter?: boolean;
};

export const sortOptions: { label: string; value: CustomerSortField }[] = [
  { label: "Ostatnia interakcja", value: "lastInteractionTime" },
  { label: "Ostatni zakup", value: "lastPurchaseTime" },
  { label: "Całkowite zakupy", value: "totalMassKg" },
];

export const tagsOptions = tags.map((tag: string) => ({ label: tag, value: tag }));

export const CustomersFilters: React.FC<Props> = ({ filters, handlers, hideSortFilter }) => {
  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});

  const regionsOptions = regionsQuery.data
    ? regionsQuery.data.items.map((region) => ({
        label: region.name,
        value: region.name,
      }))
    : [];

  return (
    <FilterList>
      {!hideSortFilter && (
        <Sort
          type="button"
          label={"Sortuj"}
          value={{
            field: filters.sort?.field || "",
            order: filters.sort?.order || "",
          }}
          options={sortOptions}
          onChange={(v) => handlers.onSortChange((v as SortFilterValue) ?? undefined)}
        />
      )}
      <FlagFilter
        isActive={filters.isPro}
        label={"Pro"}
        onDelete={() => handlers.onProChange(false)}
        onClick={() => handlers.onProChange(!filters.isPro)}
      />
      <FlagFilter
        isActive={filters.isAffiliate}
        label={"Partner"}
        onDelete={() => handlers.onAffiliateChange(false)}
        onClick={() => handlers.onAffiliateChange(!filters.isAffiliate)}
      />
      <FlagFilter
        isActive={filters.isArchived === true}
        label={"Zarchiwizowani"}
        onDelete={() => handlers.onArchivedChange(undefined)}
        onClick={() => handlers.onArchivedChange(filters.isArchived ? undefined : true)}
      />
      <BoolFilter
        label={"Przypisani?"}
        value={filters.isUnassigned}
        onChange={(value) => handlers.onUnassignedChange(value)}
        falseLabel="Tak"
        trueLabel="Nie"
      />
      <BoolFilter
        label={"Aktywny?"}
        value={filters.isActive}
        onChange={(value) => handlers.onActiveChange(value)}
        falseLabel="Nie"
        trueLabel="Tak"
      />
      <SelectFilter
        label="Tagi"
        initiallySelectedValues={filters.tags ?? []}
        options={tagsOptions ?? []}
        onSelectedChange={(options) =>
          handlers.onTagsChange((options.map((option) => option.value) as string[]) ?? undefined)
        }
        variant="multi-select"
      />
      <SelectFilter
        label="Regiony"
        initiallySelectedValues={filters.regions ?? []}
        options={regionsOptions ?? []}
        onSelectedChange={(options) => handlers.onRegionsChange(options.map((option) => option.value) ?? undefined)}
        variant="multi-select"
      />
      <SelectFilterDocs
        label="Pos"
        initiallySelectedDocIds={filters.pos?.map((pos) => getDocId("pos-customer", pos)) ?? []}
        onSelectedChange={(options) =>
          handlers.onPosChange((options.map((option) => option.value) as string[]) ?? undefined)
        }
        docType={["pos-customer"]}
        variant="multi-select"
      />
    </FilterList>
  );
};
