import { Gtin, TradeItemDto, tradeItemResource } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { useIndependentAsyncLoad, useResourceProviderV2 } from "@megarax/react-client";
import { useDepPagination } from "@megarax/react-utils";
import { BreadcrumbMarker, commonErrorsMap, LoadingBar, useSnackbarErrorHandler } from "@megarax/ui-components";
import { LinearProgress } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom-v5-compat";

import { Failure, Ok } from "@megaron/result";

import { PackSizeDialogContainer } from "./TradeItemPackSizeDialog";
import TradeItemsHome from "./TradeItemsHome";

interface Props {}

type TradeItemsQuery =
  | Ok<{
      count: number;
      items: TradeItemDto[];
    }>
  | Failure<HttpRequestError>;

const perPage = 20;

export const TradeItemsHomeContainer: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dialogPathMatch = useMatch("/crm/pos/jednostki_handlowe/:gtin/wielkosci");

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const tradeItemProvider = useResourceProviderV2(tradeItemResource);

  const [searchText, setSearchText] = useState<string>("");

  const [tradeItemsQuery, setTradeItemsQuery] = useState<TradeItemsQuery>();

  const { paginationProps, page } = useDepPagination({ perPage: perPage, allCount: tradeItemsQuery?.value?.count }, [
    searchText,
  ]);

  const { loading: tradeItemsLoading } = useIndependentAsyncLoad(
    () =>
      tradeItemProvider
        .list({
          limit: perPage,
          offset: page * perPage,
          searchText,
          sortBy: [{ field: "name", order: "ASC" }],
        })
        .mapError(handleError),
    setTradeItemsQuery,
    [page, searchText],
  );

  const openPackSizeDefaultsDialog = (gtin: Gtin) => navigate(location.pathname + "/" + gtin + "/wielkosci");

  const goToGroupings = () => navigate("/crm/pos/jednostki_handlowe/grupy");

  if (tradeItemsQuery === undefined) return <LinearProgress />;
  if (tradeItemsQuery.isFailure) return null;

  return (
    <>
      <BreadcrumbMarker title="Jednostki handlowe" to={location.pathname} id="tradeItems" />

      {dialogPathMatch && <PackSizeDialogContainer gtin={dialogPathMatch.params.gtin as Gtin} />}

      <LoadingBar loading={tradeItemsLoading} />
      <TradeItemsHome
        goToGroupings={goToGroupings}
        tradeItemsQuery={tradeItemsQuery.value}
        pagination={paginationProps}
        setSearchText={setSearchText}
        openPackSizeDefaultsDialog={openPackSizeDefaultsDialog}
      />
    </>
  );
};
