import { BaseResource, paginatedListSerializer, ResourceActionV2 } from "@megarax/rest-resource";

import { orderFiltersSerializer } from "./orderFilters";
import { orderSimpleSerializer } from "./orderSimple";

export const listAction = ResourceActionV2({
  method: "get",
  name: "list",
  path: "",
  querySerializer: orderFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(orderSimpleSerializer),
});

export const orderListResource = BaseResource(["search", "orders"], {
  list: listAction,
  rebuild: ResourceActionV2({
    method: "post",
    name: "rebuild",
  }),
});
