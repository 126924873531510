import { AppLinkTree } from "@megaron/megarax-links";

type Props = {
  linkTree: AppLinkTree;
};

export const LinkGroupHeader: React.FC<Props> = ({ linkTree }) => {
  return (
    <div css={{ display: "flex", gap: "1rem", alignItems: "center", color: linkTree.color }}>
      <linkTree.Icon css={{ width: "1.5rem", height: "1.5rem", flexShrink: 0, fill: linkTree.color }} />
      <span css={{ fontWeight: 700, fontSize: "1.25rem", flexShrink: 0 }}>{linkTree.name}</span>
      <div css={{ height: "1px", width: "100%", backgroundColor: linkTree.color, border: "none", opacity: 0.3 }} />
    </div>
  );
};
