import { userResource } from "@megarax/iam-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Result } from "@megaron/result";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

const promisifyResult = <TValue, TError>(result: Result<TValue, TError>): Promise<TValue> =>
  new Promise((resolve, reject) => {
    if (result.isFailure) return reject(result.error);
    resolve(result.value);
  });

export const useUsersResource = () => {
  const usersProvider = useResourceProviderV2(userResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });
  const getUsers = (searchText: string) =>
    usersProvider
      .list({ searchText, limit: 100 })
      .mapError(handleError)
      .map((result) => result.items)
      .then(promisifyResult);

  return {
    getUsers,
  };
};
