import { useTheme } from "@emotion/react";
import React from "react";
import { MdMap, MdPersonAdd } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { useQsFilters } from "@megaron/dash-filters";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Page, PageHeader } from "@megaron/dash-page";
import { SearchWithPaginationSticky } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { customerSortFields } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Serializers, SerializerValue } from "@megaron/serializers";

import { AddCustomerBody } from "./AddCustomerBody";
import { ColumnsFilter } from "./ColumnsFilter";
import { CustomerList } from "./CustomerList";
import { CustomersFilters } from "./CustomersFilters";
import { CustomerTable } from "./CustomerTable";
import { useCustomerVisibleTableColumns } from "./useCustomerVisibleTableColumns";

export const filtersSerializer = Serializers.object({
  page: Serializers.integer,
  searchText: Serializers.string,
  sort: Serializers.sortFilter(customerSortFields).optional(),
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  pos: Serializers.array(Serializers.string).optional(),
  tags: Serializers.array(Serializers.string).optional(),
  regions: Serializers.array(Serializers.string).optional(),
  isArchived: Serializers.boolean.optional(),
  isUnassigned: Serializers.boolean.optional(),
  isActive: Serializers.boolean.optional(),
});

export type Filters = SerializerValue<typeof filtersSerializer>;

export const CustomersHome: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile, isDesktop } = useDeviceType();

  const theme = useTheme();

  const { visibleTableColumns, handleVisibleColumnsChange } = useCustomerVisibleTableColumns();

  const pageSize = 30;
  const { filters, setFilter } = useQsFilters(filtersSerializer, {
    page: 0,
    searchText: "",
    sort: undefined,
    isPro: false,
    isAffiliate: false,
    pos: undefined,
    tags: undefined,
    regions: undefined,
    isArchived: undefined,
    isUnassigned: undefined,
    isActive: undefined,
  });

  const searchQuery = useClientManager("docs")
    .searchCustomers()
    .useQuery({
      offset: filters.page * pageSize,
      limit: pageSize,
      searchText: filters.searchText,
      sort: filters.sort,
      isPro: filters.isPro,
      isAffiliate: filters.isAffiliate,
      tags: filters.tags,
      pos: filters.pos,
      regions: filters.regions,
      isArchived: filters.isArchived,
      isUnassigned: filters.isUnassigned,
      isActive: filters.isActive,
    });

  const addCustomerDialog = useDialogRoute(`/add-customer`, ({ onClose }) => (
    <Dialog onClose={onClose} header="Nowy klient" placement={isMobile ? "top" : "center"} css={{ width: "400px" }}>
      <AddCustomerBody onCustomerListSuccess={onClose} />
    </Dialog>
  ));

  return (
    <>
      <Page
        css={{
          maxWidth: "1600px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <PageHeader
          actions={
            <Button icon={<MdPersonAdd />} onClick={addCustomerDialog.open}>
              Dodaj
            </Button>
          }
        >
          Klienci
        </PageHeader>

        <SearchWithPaginationSticky
          onSearchChange={setFilter("searchText")}
          searchValue={filters.searchText}
          page={filters.page}
          onPageChange={setFilter("page")}
          pageSize={pageSize}
          itemCount={searchQuery.data?.count}
        >
          <div css={{ display: "flex", alignItems: "stretch" }}>
            {isDesktop && (
              <>
                <ColumnsFilter
                  visibleTableColumns={visibleTableColumns}
                  onVisibleTableColumnsChange={handleVisibleColumnsChange}
                />
                <div css={{ width: "1px", alignSelf: "stretch", backgroundColor: theme.colors.primaryLight }} />
              </>
            )}
            <Button
              color="normal"
              icon={<MdMap />}
              css={{ borderRadius: "0" }}
              onClick={() => navigate(`${location.pathname}/map${location.search}`)}
            />
          </div>
        </SearchWithPaginationSticky>

        <CustomersFilters
          filters={filters}
          handlers={{
            onSortChange: setFilter("sort"),
            onProChange: setFilter("isPro"),
            onAffiliateChange: setFilter("isAffiliate"),
            onArchivedChange: setFilter("isArchived"),
            onUnassignedChange: setFilter("isUnassigned"),
            onActiveChange: setFilter("isActive"),
            onPosChange: setFilter("pos"),
            onRegionsChange: setFilter("regions"),
            onTagsChange: setFilter("tags"),
          }}
        />

        <QuerySkeleton query={searchQuery}>
          {(result) =>
            isDesktop ? (
              <CustomerTable
                customers={result.items}
                isLoading={searchQuery.isFetching}
                onSortChange={setFilter("sort")}
                activeSortValue={filters.sort}
                visibleTableColumns={visibleTableColumns}
                queryKey={searchQuery.key}
              />
            ) : (
              <CustomerList customers={result.items} queryKey={searchQuery.key} />
            )
          }
        </QuerySkeleton>
      </Page>

      {addCustomerDialog.element}
    </>
  );
};

export type SortFilterValue =
  | Serializers.SortFilter<"totalMassKg" | "lastInteractionTime" | "lastPurchaseTime">
  | undefined;
