import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TimePicker } from "@mui/x-date-pickers";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: { time: Date | null; odometer: number | null }) => Promise<void>;

  startTime: Date | null;
  startOdometer: number | null;
}

export const EditStartDialog: React.FunctionComponent<Props> = ({
  onClose,
  isOpen,
  onSave,
  startTime,
  startOdometer,
}) => {
  const classes = useStyles();
  const [time, setTime] = React.useState<Date | null>(startTime ?? new Date());
  const [odometer, setOdometer] = React.useState<number | null>(startOdometer);

  const onOdometerInputChange = (input: string) => {
    const parsed = parseInt(input);
    if (isNaN(parsed)) setOdometer(null);
    else setOdometer(parsed);
  };

  const onConfirm = async () => {
    await onSave({
      odometer,
      time,
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Początek dnia</DialogTitle>
      <DialogContent>
        <TimePicker ampm={false} onChange={setTime} value={time} className={classes.field} label="Godzina" />
        <TextField
          fullWidth
          label="Stan licznika [km]"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={odometer ?? ""}
          onChange={(e) => onOdometerInputChange(e.target.value)}
          className={classes.field}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Anuluj</Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(1),
  },
}));
