import { useTheme } from "@emotion/react";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom-v5-compat";

type Props = {
  icon: React.ReactNode;
  color?: string;
  label: React.ReactNode;
  href?: string;
  isExternal?: boolean;
};

export const AppTile: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <AppTileWrapper {...props}>
      <div
        css={{
          height: "32px",
          width: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "16px",
          flexShrink: 0,
          padding: "",
          color: "white",
        }}
      >
        {props.icon}
      </div>

      <div
        css={{
          color: "white",
          fontSize: "12px",
          textAlign: "center",
          fontFamily: theme.displayFontFamily,
        }}
      >
        {props.label}
      </div>
    </AppTileWrapper>
  );
};

const AppTileWrapper = (props: Props & PropsWithChildren) => {
  const theme = useTheme();

  const linkCss = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.125rem",
    minWidth: 0,
    background: `linear-gradient(90deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.15) 100%), ${
      props.color ?? theme.colors.primary
    }`,
    border: "1px solid rgba(255, 255, 255, 0.1)",
    overflow: "hidden",
    borderRadius: theme.smallBorderRadius,
    padding: "0.125rem",
    ":hover": {
      // transform: "scale(1.05)",
      textDecoration: "none",
    },
  } as const;

  return (
    <div>
      {props.isExternal ? (
        <a href={props.href} css={linkCss}>
          {props.children}
        </a>
      ) : (
        <Link to={props.href || ""} css={linkCss}>
          {props.children}
        </Link>
      )}
    </div>
  );
};
