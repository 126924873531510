import { useTheme } from "@emotion/react";
import React from "react";
import { MdAlternateEmail, MdDirectionsCar, MdLocalPhone, MdOutlineHeadsetMic, MdSchool } from "react-icons/md";
import { useQueryClient } from "react-query";

import { Interaction, InteractionType } from "@megaron/crm-contracts";
import { Avatar } from "@megaron/dash-avatar";
import { useDialogRoute } from "@megaron/dash-dialog";
import { CommentEntry } from "@megaron/dash-history";
import { Header4 } from "@megaron/dash-page";
import { Profile } from "@megaron/iam-contracts";

import { InteractionPage } from "./InteractionPage";

const highlightMentions = (message: string, theme: any): JSX.Element[] => {
  const mentionPattern = /(@[\wáéíóúüñłśżźćń]+(?: [\wáéíóúüñłśżźćń]+)?)/gi;
  const highlightedMessage: JSX.Element[] = [];
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = mentionPattern.exec(message)) !== null) {
    const matchedText = match[0];
    const matchStart = match.index;
    const matchEnd = matchStart + matchedText.length;

    if (matchStart > lastIndex) {
      highlightedMessage.push(
        <React.Fragment key={`text-${lastIndex}`}>{message.slice(lastIndex, matchStart)}</React.Fragment>
      );
    }

    highlightedMessage.push(
      <span
        key={`highlight-${matchStart}`}
        css={{
          color: theme.colors.primary,
          fontWeight: 600,
        }}
      >
        {matchedText}
      </span>
    );

    lastIndex = matchEnd;
  }

  if (lastIndex < message.length) {
    highlightedMessage.push(<React.Fragment key={`text-end`}>{message.slice(lastIndex)}</React.Fragment>);
  }

  return highlightedMessage;
};

interface Props {
  interaction: Interaction;
  profile?: Profile;
  queryKey: string | string[];
}

export const getInteractionTypeObject = (type: InteractionType) => {
  switch (type) {
    case "call":
      return { icon: <MdLocalPhone />, name: "Rozmowa" };
    case "visit":
      return { icon: <MdDirectionsCar />, name: "Wizyta" };
    case "training":
      return { icon: <MdSchool />, name: "Szkolenie" };
    case "hotline":
      return { icon: <MdOutlineHeadsetMic />, name: "Infolinia" };
    default:
      return { icon: <MdAlternateEmail />, name: "Inne" };
  }
};

export const formattedDateString = (date: Date) => {
  return `${date
    .toLocaleString("pl-PL", {
      timeZone: "Europe/Warsaw",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(/(\d+).(\d+).(\d+), (\d+):(\d+)/, "$3-$2-$1 $4:$5")}`;
};

export const InteractionTile: React.FC<Props> = (props) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const interactionTypeObject = getInteractionTypeObject(props.interaction.type ?? "other");

  const dialog = useDialogRoute(`/${props.interaction.uuid}`, ({ onClose }) => (
    <InteractionPage
      onClose={onClose}
      header={
        <div css={{ display: "flex", justifyContent: "space-between", gap: "1em", alignItems: "center" }}>
          <Avatar src={props.profile?.profilePictureUrl} />
          {formattedDateString(props.interaction.interactionDate)}
        </div>
      }
      interaction={props.interaction}
      action={() => {
        queryClient.invalidateQueries(props.queryKey);
      }}
    />
  ));

  return (
    <>
      <CommentEntry
        fullName={`${props.profile?.firstName} ${props.profile?.lastName}`}
        message={
          <div>
            {!!props.interaction.samples && (
              <div css={{ display: "flex", gap: "8px" }}>
                {props.interaction.samples.map((sample) => (
                  <span css={{ color: theme.colors.primary }}>#{sample}</span>
                ))}
              </div>
            )}
            <span>{highlightMentions(props.interaction.message, theme)}</span>
          </div>
        }
        time={formattedDateString(props.interaction.interactionDate)}
        badge={
          <Header4
            css={{
              margin: 0,
              fontSize: "12px",
              gap: "6px",
              display: "flex",
              alignItems: "center",
              color: theme.colors.primary,
            }}
          >
            {interactionTypeObject.icon}
            {interactionTypeObject.name}
          </Header4>
        }
        imageSrc={props.profile?.profilePictureUrl}
        onClick={dialog.open}
      />
      {dialog.element}
    </>
  );
};
