export const AccountSectionHeader = (props: { name: string; icon: React.ReactNode }) => {
  return (
    <div
      css={{
        display: "flex",
        gap: "0.75rem",
        fontWeight: "500",
        alignItems: "center",
        fontSize: "1.125rem",
        lineHeight: "1.125rem",
        textTransform: "uppercase",
        marginBottom: "1rem",
      }}
    >
      <span css={{ fontSize: "1.5rem" }}>{props.icon}</span>
      <span>{props.name}</span>
    </div>
  );
};
