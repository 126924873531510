import { FeedbackSnackbarContext } from "@megarax/ui-components";
import { useContext, useEffect, useRef, useState } from "react";

import { AttachmentDto } from "@megaron/invoices-contracts";

type PrintState = "init" | "loading" | "loaded";

interface PdfInput {
  numPages: number;
}

interface PagesDictionary {
  [uuid: string]: {
    number: number;
    error: boolean;
  };
}

export const useDocumentPrint = (attachments: AttachmentDto[]) => {
  const snackbarContext = useContext(FeedbackSnackbarContext);

  const [state, setState] = useState<PrintState>("init");
  const [pages, setPages] = useState<PagesDictionary | undefined>();
  const [pagesLoaded, setPagesLoaded] = useState<number>(0);
  const attachmentUrls = useRef(
    attachments.filter((attachment) => attachment.fileType === "application/pdf").map((attachment) => attachment.url),
  );
  const allPagesCount = pages ? Object.values(pages).reduce((prev, current) => prev + current.number, 0) : undefined;

  const onDocumentload = (uuid: string) => (pdf: PdfInput) =>
    setPages((pages) => {
      return { ...pages, [uuid]: { number: pdf.numPages, error: false } };
    });
  const onPageRender = () => setPagesLoaded((loaded) => loaded + 1);
  const onError = (uuid: string) => {
    setPages((pages) => {
      return { ...pages, [uuid]: { number: 0, error: true } };
    });
    setPagesLoaded((loaded) => loaded + 1);

    snackbarContext.pushMessage({
      severity: "error",
      content: "Wystąpił bląd przy ładowaniu załącznika do druku.",
    });
  };

  useEffect(() => {
    if (attachmentUrls.current.length === 0) return setState("loaded");
    if (!pages) return setState("init");
    if (Object.entries(pages).length !== attachmentUrls.current.length) return setState("init");

    if (allPagesCount !== pagesLoaded) return setState("loading");
    return setState("loaded");
  }, [pagesLoaded, allPagesCount, pages]);

  return {
    state,
    onDocumentload,
    onPageRender,
    onError,
    allPagesCount,
    pagesLoaded,
    pages,
  };
};
