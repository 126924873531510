import Decimal from "decimal.js";

import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

import { blikCheckFulfillmentDtoSerializer, myBlikCheckFulfillmentDtoSerializer } from "./blikChecks";
import { myShoperFulfillmentDtoSerializer, shoperFulfillmentDtoSerializer } from "./shoper";

export type CustomFulfillmentDto = {
  type: "custom";
  uuid: Uuid;

  isFulfilled: boolean;
  isFailed: boolean;
};

export const customFulfillmentDtoSerializer = Serializers.object<CustomFulfillmentDto>({
  type: Serializers.stringOneOf("custom"),
  uuid: uuidSerializer,
  isFulfilled: Serializers.boolean,
  isFailed: Serializers.boolean,
});
export const fulfillmentDtoSerializer = Serializers.first(
  blikCheckFulfillmentDtoSerializer,
  customFulfillmentDtoSerializer,
  shoperFulfillmentDtoSerializer,
);
export type FulfillmentDto = SerializerValue<typeof fulfillmentDtoSerializer>;

export type OrderDto = {
  purchaseUuid: Uuid;
  userUuid: Uuid;
  rewardId: string;
  orderedAt: Date;
  fulfillments: FulfillmentDto[];
  isCancelled: boolean;
};

export const orderDtoSerializer = Serializers.object<OrderDto>({
  purchaseUuid: uuidSerializer,
  userUuid: uuidSerializer,
  rewardId: Serializers.string,
  orderedAt: Serializers.datetime,
  fulfillments: Serializers.array(fulfillmentDtoSerializer),
  isCancelled: Serializers.boolean,
});

export type MyCustomFulfillmentDto = {
  type: "custom";
  uuid: Uuid;

  isFulfilled: boolean;
  isFailed: boolean;
};

export const myCustomFulfillmentDtoSerializer = Serializers.object<MyCustomFulfillmentDto>({
  type: Serializers.stringOneOf("custom"),
  uuid: uuidSerializer,
  isFulfilled: Serializers.boolean,
  isFailed: Serializers.boolean,
});

export const myFulfillmentDtoSerializer = Serializers.first(
  myBlikCheckFulfillmentDtoSerializer,
  myCustomFulfillmentDtoSerializer,
  myShoperFulfillmentDtoSerializer,
);
export type MyFulfillmentDto = SerializerValue<typeof myFulfillmentDtoSerializer>;

export type MyOrderDto = {
  purchaseUuid: Uuid;
  rewardId: string;
  orderedAt: Date;
  fulfillments: MyFulfillmentDto[];
  isRecipientRegistrationRequired: boolean;
  isRecipientRegistered: boolean;
  isTaxpayerRegistrationRequired: boolean;
  isTaxpayerRegistered: boolean;
  valuePln: Decimal;
  taxedValuePln: Decimal;
  isCancelled: boolean;
};

export const myOrderDtoSerializer = Serializers.object<MyOrderDto>({
  purchaseUuid: uuidSerializer,
  rewardId: Serializers.string,
  orderedAt: Serializers.datetime,
  fulfillments: Serializers.array(myFulfillmentDtoSerializer),
  isRecipientRegistrationRequired: Serializers.boolean,
  isRecipientRegistered: Serializers.boolean,
  isTaxpayerRegistrationRequired: Serializers.boolean,
  isTaxpayerRegistered: Serializers.boolean,
  taxedValuePln: Serializers.decimal,
  valuePln: Serializers.decimal,
  isCancelled: Serializers.boolean,
});

export type OrderCancelledMessage = {
  orderUuid: Uuid;
  userUuid: Uuid;
};

export const orderCancelledTopic: PubsubTopic<OrderCancelledMessage> = {
  name: "loyalty-orders-order-cancelled",
  serializer: Serializers.object<OrderCancelledMessage>({
    orderUuid: uuidSerializer,
    userUuid: uuidSerializer,
  }),
};
