import React from "react";
import { matchPath, useHistory, useRouteMatch } from "react-router-dom";

export const useDialogRoute = (pathFragment: string) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const safeToGoBack = React.useRef(false);

  const open = () => {
    history.push(`${url}/${pathFragment}`);
    safeToGoBack.current = true;
  };

  const close = () => {
    if (safeToGoBack.current) {
      history.goBack();
      safeToGoBack.current = false;
    } else {
      history.replace(url);
    }
  };

  const isOpen = matchPath(history.location.pathname, `${path}/${pathFragment}`) !== null;

  return { isOpen, open, close };
};
