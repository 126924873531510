import { css, useTheme } from "@emotion/react";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import Decimal from "decimal.js";
import { useState } from "react";
import { MdFavorite, MdNotInterested } from "react-icons/md";

import { InterestLevel } from "@megaron/crm-contracts";
import { DescriptionField } from "@megaron/dash-description-list";
import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { Card } from "@megaron/dash-page";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { useActivityColor } from "../customers/CustomerTile";
import { InterestLevelButtons } from "./InterestLevelButtons";

const parseDateToString = (date: Date | null) => {
  if (!date) {
    return "-";
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

type Props = {
  productData: {
    product: string;
    interestLevel: string | null;
    massKg: Decimal;
    lastPurchaseTime: Date | null;
  };
  customerId: Uuid;
  isCustomerArchived: boolean;
  onRefetch: () => void;
};

export const ProductTile: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { isMobile } = useDeviceType();
  const saveFunnel = useClientManager("crm").savePurchaseFunnel().useMutation();

  const color = useActivityColor(props.productData.lastPurchaseTime, props.isCustomerArchived);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const saveChanges = (interestLevel: string | null) => {
    saveFunnel.mutate(
      {
        customerId: props.customerId,
        productId: props.productData.product,
        interestLevel,
      },
      {
        onSuccess: () => {
          props.onRefetch();
          toggleDrawer();
        },
      },
    );
  };

  const defaultColorScheme = {
    backgroundColor: "transparent",
    textColor: theme.colors.primary,
  };

  const colorScheme = () => {
    if (!props.productData.lastPurchaseTime && props.productData.interestLevel !== "interested") {
      return {
        backgroundColor: "transparent",
        textColor: theme.colors.primary,
      };
    }

    return {
      backgroundColor: color,
      textColor: "white",
    };
  };

  const iconStyle = css({
    cursor: "pointer",
    fontSize: "1.5rem",
    alignItems: "center",
  });

  const getIcon = (interestLevel: InterestLevel | null, hasPurchase: boolean) => {
    if (interestLevel === "notInterested") {
      return <MdNotInterested css={iconStyle} />;
    }

    if (interestLevel === "interested" && !hasPurchase) {
      return <MdFavorite css={iconStyle} />;
    }

    return null;
  };

  const tile = css({
    color: colorScheme().textColor || defaultColorScheme.textColor,
    border: `1px solid ${theme.colors.border}`,
    cursor: "pointer",
  });

  const dialog = useDialogRoute(`/${props.productData.product}`, ({ onClose }) => (
    <Dialog
      header={props.productData.product}
      onClose={onClose}
      placement={isMobile ? "bottom" : "center"}
      css={{ width: "600px" }}
    >
      <div css={{ display: "Flex", flexDirection: "column", gap: "1em" }}>
        <div css={{ display: "Flex", gap: "25%" }}>
          <DescriptionField label="Całość">{props.productData.massKg.toString()} kg</DescriptionField>
          <DescriptionField label="Ostatni zakup">{`${
            parseDateToString(props.productData.lastPurchaseTime) || "-"
          }`}</DescriptionField>
        </div>
        <InterestLevelButtons
          onSelect={function (interestLevel: string | null): void {
            saveChanges(interestLevel);
          }}
          selectedInterest={props.productData.interestLevel}
        />
      </div>
    </Dialog>
  ));

  return (
    <>
      <Card
        css={tile}
        color={colorScheme().backgroundColor || defaultColorScheme.backgroundColor}
        onClick={dialog.open}
      >
        <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <div css={{ display: "flex", flexDirection: "column", gap: "0.125rem" }}>
            <span css={{ fontSize: "0.875rem" }}>{props.productData.product}</span>
            <span>{props.productData.massKg.toString()} kg</span>
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {getIcon(props.productData.interestLevel, !!props.productData.lastPurchaseTime)}
            {props.productData.lastPurchaseTime && (
              <span
                css={{
                  fontSize: "0.75rem",
                  marginTop: "auto",
                }}
              >
                {formatDistanceToNowStrict(props.productData.lastPurchaseTime, { locale: pl })}
              </span>
            )}
          </div>
        </div>
      </Card>
      {dialog.element}
    </>
  );
};
