import { Box, Container, List, ListItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { Uuid } from "@megarax/common";
import { MacroregionSimple, RegionSimple } from "@megarax/crm-contracts";
import { NewItemFab, Pagination, SearchBar } from "@megarax/ui-components";

interface Props {
  regions: {
    count: number;
    items: RegionSimple[];
  };
  search: {
    setSearchText: (text: string) => void;
    initialValue: string;
  };
  pagination: {
    onChange: (e: React.ChangeEvent<unknown>, page: number) => void;
    count: number;
    page: number;
  };
  navigation: {
    navigateToDetails: (uuid: Uuid) => void;
    openAddCustomerDialog: () => void;
  };
  macroregionsDictionary: _.Dictionary<MacroregionSimple | undefined>;
}

export const RegionsHome: React.FC<Props> = ({ regions, navigation, search, pagination, macroregionsDictionary }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" disableGutters>
      <Box display="flex" p={2} sx={{ gap: "8px" }} justifyContent="space-between" alignItems="center">
        <SearchBar onSearchChange={search.setSearchText} initialValue={search.initialValue} />
        <NewItemFab onClick={navigation.openAddCustomerDialog} text="Nowy" />
      </Box>
      <List dense disablePadding>
        {regions.items.map((region) => {
          const MacroregionName = region.macroregionUuid
            ? macroregionsDictionary[region.macroregionUuid]?.name ?? ""
            : "";
          return (
            <ListItem
              className={classes.itemRow}
              key={region.uuid}
              button
              onClick={() => navigation.navigateToDetails(region.uuid as Uuid)}
            >
              <Box width="50%">
                <ListItemText>{region.name}</ListItemText>
              </Box>
              <Box width="50%">
                <ListItemText>{MacroregionName}</ListItemText>
              </Box>
            </ListItem>
          );
        })}
      </List>
      <Pagination {...pagination} allCount={regions.count} />
    </Container>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    itemRow: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  };
});
