import _ from "lodash";

import { AsyncResult, Failure, Ok, Result } from "@megaron/result";

import {
  BaseResource,
  isNodeAction,
  isNodeNestedResource,
  isNodeParam,
  NestedResourceNode,
  ResourceAction,
  ResourceNodes,
  ResourceParamNode,
} from "../resource";

type ResourceActionParams<TQuery, TBody> = TBody extends undefined
  ? TQuery extends undefined
    ? []
    : [query: TQuery]
  : [query: TQuery, body: TBody];

export type ResourceActionProviderV2<TResponse, TQuery, TReqBody, TClientError> = (
  ...args: ResourceActionParams<TQuery, TReqBody>
) => AsyncResult<(TResponse extends Result<any, any> ? TResponse : Ok<TResponse>) | Failure<TClientError>>;

export type ResourceParamProviderV2<TParam, TNested extends ResourceNodes, TClientError> = (
  param: TParam,
) => ResourceProviderV2<TNested, TClientError>;

export type ResourceProviderV2<TResource extends ResourceNodes, TClientError> = {
  [key in keyof TResource]: TResource[key] extends ResourceParamNode<infer TParam, infer TNested>
    ? ResourceParamProviderV2<TParam, TNested, TClientError>
    : TResource[key] extends ResourceAction<infer TResponse, infer TQuery, infer TReqBody>
    ? ResourceActionProviderV2<TResponse, TQuery, TReqBody, TClientError>
    : TResource[key] extends NestedResourceNode<infer TNested>
    ? ResourceProviderV2<TNested, TClientError>
    : never;
};

export type ActionProviderSourceV2<TClientError> = <TResponse, TQuery, TReqBody>(
  node: ResourceAction<TResponse, TQuery, TReqBody>,
  basePath: string[],
) => ResourceActionProviderV2<TResponse, TQuery, TReqBody, TClientError>;

export const ResourceProviderV2 =
  <TClientError>(createActionProvider: ActionProviderSourceV2<TClientError>) =>
  <TNodes extends ResourceNodes>({
    nodes,
    basePath,
  }: BaseResource<TNodes>): ResourceProviderV2<TNodes, TClientError> => {
    const provider: Partial<ResourceProviderV2<TNodes, TClientError>> = {};

    for (const nodeName in nodes) {
      const node = nodes[nodeName];
      if (isNodeParam(node))
        provider[nodeName] = ((param: any) =>
          ResourceProviderV2(createActionProvider)({
            basePath: node.path
              ? [...basePath, node.path, node.paramSerializer.serialize(param)]
              : [...basePath, node.paramSerializer.serialize(param)],
            nodes: node.children,
          })) as any;
      else if (isNodeAction(node)) provider[nodeName] = createActionProvider(node, basePath) as any;
      else if (isNodeNestedResource(node))
        provider[nodeName] = ResourceProviderV2(createActionProvider)({
          basePath: [...basePath, node.path],
          nodes: node.children,
        }) as any;
      else throw new Error("Unknown node type");
    }
    return provider as ResourceProviderV2<TNodes, TClientError>;
  };
