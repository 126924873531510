import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

import { baseDocSerializer } from "../baseDoc";

export const bcVendorSerializer = Serializers.object({
  ...baseDocSerializer("bc-vendor").fields,
  id: Serializers.string,
  vendorNumber: Serializers.string,
  contacts: Serializers.object({
    contactNumber: Serializers.string,
    contactName: Serializers.string,
  }).array(),
  taxId: Serializers.string,
});

export type BcVendorDoc = SerializerValue<typeof bcVendorSerializer>;

export const bcVendorActions = {
  matchBcVendor: HttpAction({
    path: "/bcVendors/match",
    qsSerializer: Serializers.object({
      name: Serializers.string,
      taxId: Serializers.string,
    }),
    valueSerializer: bcVendorSerializer.nullable(),
    requiresAuth: true,
  }),
};
