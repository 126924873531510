import { useTheme } from "@emotion/react";
import { MdClose } from "react-icons/md";

type Props = {
  isActive: boolean;
  label: React.ReactNode;
  onDelete: () => void;
  onClick: () => void;
  className?: string;
  color?: "normal" | "danger";
};

export const FlagFilter: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const textColor = props.color === "danger" ? theme.colors.danger : theme.colors.primary;

  return (
    <div
      className={props.className}
      css={{
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        fontWeight: props.isActive ? 700 : 400,
        color: props.isActive ? "white" : textColor,
        borderRadius: theme.smallBorderRadius,
        background: props.isActive ? theme.colors.primary : theme.colors.background,
        border: `1px solid ${props.isActive ? "transparent" : textColor}`,
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
      }}
      color={props.color}
      onClick={props.onClick}
    >
      <div
        css={{
          padding: "5px 10px",
        }}
      >
        {props.label}
      </div>
      {props.isActive && (
        <button
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            color: "white",
            padding: "0.25rem",
            alignSelf: "stretch",
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            margin: 0,
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            props.onDelete();
          }}
        >
          <MdClose size={14} />
        </button>
      )}
    </div>
  );
};
