import { useTheme } from "@emotion/react";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";

import { Tile, TileStripe } from "@megaron/dash-tile";
import { CodeGroupDto } from "@megaron/loyalty-points-contracts";

type Props = {
  group: CodeGroupDto;
};

export const GroupTile: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const color = theme.colors.primary;

  const lastChangeRelative =
    props.group.lastChange &&
    formatDistanceToNow(props.group.lastChange, {
      locale: pl,
    }) + " temu";

  return (
    <Tile stripe={<TileStripe />}>
      <div css={{ color }}>{props.group.name}</div>
      <div css={{ display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: "center", fontSize: "0.75rem" }}>
        <span css={{ color, fontWeight: "bold" }}>{props.group.total} szt.</span>
        {lastChangeRelative && <span css={{ opacity: "0.7" }}>{lastChangeRelative} </span>}
        <span css={{ opacity: "0.7" }}>{props.group.properties?.createdBy} </span>
      </div>
    </Tile>
  );
};
