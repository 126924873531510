import { DateString, dateStringSerializer, Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export type PlanMyVisitInput = {
  customerUuid: Uuid;
  date: DateString;
};

export const planMyVisitInputSerializer = MSerializers.object<PlanMyVisitInput>({
  customerUuid: uuidSerializer,
  date: dateStringSerializer,
});

export type ChangeStartTimeInput = {
  startTime: Date | null;
};

export const changeStartTimeInputSerializer = MSerializers.object<ChangeStartTimeInput>({
  startTime: MSerializers.datetime.nullable(),
});

export type ChangeEndTimeInput = {
  endTime: Date | null;
};

export const changeEndTimeInputSerializer = MSerializers.object<ChangeEndTimeInput>({
  endTime: MSerializers.datetime.nullable(),
});

export type CustomerRecentVisitsQueryFilters = {
  customerUuid: Uuid;
  limit?: number;
  offset?: number;
};

export const customerRecentVisitsQueryFiltersSerializer = MSerializers.object<CustomerRecentVisitsQueryFilters>({
  customerUuid: uuidSerializer,
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
});
