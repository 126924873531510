import { keyframes, useTheme } from "@emotion/react";
import React from "react";

type Props = {
  size?: string;
  color?: string;
  className?: string;
};

export const Spinner: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const spin = keyframes`
	100% {
    transform: rotate(360deg)
  }
  `;

  return (
    <div
      className={props.className}
      css={{
        pointerEvents: "none",
        width: props.size ?? "1em",
        height: props.size ?? "1em",
        border: "1.2px solid transparent",
        borderLeftColor: props.color ?? theme.colors.primary,
        borderRightColor: props.color ?? theme.colors.primary,
        borderRadius: "50%",
        animation: `${spin} 1s linear infinite`,
      }}
    ></div>
  );
};
