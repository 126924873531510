import { useShallowEffect } from "@mantine/hooks";
import { useState } from "react";

import { tableHeadingsList } from "./AllThreadsHome";

export const useTableVisibleColumns = () => {
  const [visibleTableColumns, setVisibleTableColumns] = useState<string[]>([]);

  const defaultVisibleColumns = tableHeadingsList
    .filter((heading) => heading.isDefault)
    .map((heading) => heading.label);

  useShallowEffect(() => {
    const visibleTableColumns = localStorage.getItem("invoicesVisibleTableColumns");

    const areValuesCompatible =
      visibleTableColumns &&
      JSON.parse(visibleTableColumns).every((column: string) =>
        tableHeadingsList.some((heading) => heading.label === column),
      );

    if (visibleTableColumns && areValuesCompatible) {
      setVisibleTableColumns(JSON.parse(visibleTableColumns));
      return;
    }

    setVisibleTableColumns(defaultVisibleColumns);
    localStorage.setItem("invoicesVisibleTableColumns", JSON.stringify(defaultVisibleColumns));
  }, [defaultVisibleColumns]);

  const handleVisibleColumnsChange = (columns: string[]) => {
    if (columns.length === 0) {
      setVisibleTableColumns(defaultVisibleColumns);
      localStorage.setItem("invoicesVisibleTableColumns", JSON.stringify(defaultVisibleColumns));
      return;
    }

    setVisibleTableColumns(columns);
    localStorage.setItem("invoicesVisibleTableColumns", JSON.stringify(columns));
  };

  return { visibleTableColumns, handleVisibleColumnsChange };
};
