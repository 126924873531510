import { CustomerStatus } from "@megarax/crm-contracts";
import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { StatusText } from "./StatusText";

interface Props {
  name: string;
  status: CustomerStatus;
  actionsMenu: React.ReactNode;
}

export const TitleSection: React.FunctionComponent<Props> = ({ name, status, actionsMenu }) => {
  const classes = useStyles();

  const [popoverAnchor, setPopoverAnchor] = React.useState<Element | null>(null);

  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow={1}>
        <StatusText status={status} />
        <Typography variant="h4" fontWeight={700} className={classes.title}>
          {name}
        </Typography>
      </Box>
      <Box mr={1}>
        <IconButton size="large" onClick={(e) => setPopoverAnchor(e.currentTarget)} className={classes.moreButton}>
          <MoreVert fontSize="inherit" />
        </IconButton>
        <Popover
          open={popoverAnchor !== null}
          anchorEl={popoverAnchor}
          onClose={() => setPopoverAnchor(null)}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          {actionsMenu}
        </Popover>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    wordBreak: "break-word",
  },
  moreButton: {
    margin: theme.spacing(-1, 0, -1, 0),
  },
}));
