import { dateStringSerializer } from "@megaron/date-string";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

export type TaxAddress = {
  country: string;
  voivodeship: string;
  gmina: string;
  powiat: string;
  locality: string;
  street: string;
  houseNumber: string;
  apartmentNumber: string;
  postalCode: string;
};

export const taxAddressSerializer = Serializers.object<TaxAddress>({
  country: Serializers.string,
  voivodeship: Serializers.string,
  gmina: Serializers.string,
  powiat: Serializers.string,
  locality: Serializers.string,
  street: Serializers.string,
  houseNumber: Serializers.string,
  apartmentNumber: Serializers.string,
  postalCode: Serializers.string,
});

export type RegisterRecipientInput = {
  firstName: string;
  lastName: string;
  citizenship: string;
  pesel: string;
};

export const registerRecipientInputSerializer = Serializers.object<RegisterRecipientInput>({
  firstName: Serializers.string,
  lastName: Serializers.string,
  citizenship: Serializers.string,
  pesel: Serializers.string,
});

export const registerTaxpayerInputSerializer = Serializers.object({
  firstName: Serializers.string,
  lastName: Serializers.string,
  citizenship: Serializers.string,
  pesel: Serializers.string,
  taxAddress: taxAddressSerializer,
  taxOfficeId: Serializers.string,
  dateOfBirth: dateStringSerializer,
});

export type RegisterTaxpayerInput = SerializerValue<typeof registerTaxpayerInputSerializer>;

export type MyAccountDataDto = {
  uuid: Uuid;
  isRecipientRegistered: boolean;
  isTaxpayerRegistered: boolean;
  firstName: string | null;
  lastName: string | null;
  pesel: string | null;
  citizenship: string | null;
  phoneNumber: string | null;
};

export const myAccountDataDtoSerializer = Serializers.object<MyAccountDataDto>({
  uuid: uuidSerializer,
  isRecipientRegistered: Serializers.boolean,
  isTaxpayerRegistered: Serializers.boolean,
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  pesel: Serializers.string.nullable(),
  citizenship: Serializers.string.nullable(),
  phoneNumber: Serializers.string.nullable(),
});
