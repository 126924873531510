import React, { Component, useContext } from "react";
import { MdClose, MdEdit, MdSend } from "react-icons/md";
import { useQueryClient } from "react-query";

import { DescriptionField, DescriptionList } from "@megaron/dash-description-list";
import { useDialogRoute } from "@megaron/dash-dialog";
import { Button, ButtonGroup } from "@megaron/dash-form";
import { ToastContext } from "@megaron/dash-toast";
import { ShoperFulfillmentDto } from "@megaron/loyalty-orders-contracts";
import { useClientManager } from "@megaron/react-clients";

import { BaseFulfillmentCard } from "./BaseFulfillmentCard";
import { EditShoperFulfillmentDialog } from "./EditShoperFulfillmentDialog";
import { ShoperStatusChip } from "./ShoperStatusChip";

export const ShoperFulfillmentCard: React.FunctionComponent<{
  fulfillment: ShoperFulfillmentDto;
}> = (props) => {
  const toast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const editDialog = useDialogRoute(`/fulfillment/${props.fulfillment.uuid}/edit`, ({ onClose }) => (
    <EditShoperFulfillmentDialog fulfillment={props.fulfillment} onClose={onClose} />
  ));

  const resendMutation = useClientManager("loyaltyOrders")
    .resendShoperOrder()
    .useMutation({
      onError: (error) => toast.error("Nie udało się wysłać zamówienia"),
      onSuccess: () => {
        queryClient.invalidateQueries(["loyaltyOrders", "order"]);
        toast.info("Wysłano zamówienie do shopera");
      },
    });

  const cancelFulfillmentMutation = useClientManager("loyaltyOrders")
    .cancelShoperFulfillment()
    .useMutation({
      onError: (error) => toast.error("Nie udało się anulować zamówienia", error),
      onSuccess: () => {
        queryClient.invalidateQueries(["loyaltyOrders", "order"]);
        toast.info("Anulowano zamówienie");
      },
    });

  return (
    <BaseFulfillmentCard
      header="Zamówienie Shoper"
      status={<ShoperStatusChip status={props.fulfillment.status} />}
      actions={
        <ButtonGroup>
          <Button
            isLoading={resendMutation.isLoading}
            variant="outline"
            icon={<MdSend />}
            size="small"
            color="normal"
            onClick={() => resendMutation.mutate({ fulfillmentUuid: props.fulfillment.uuid })}
          >
            Wyślij do Shopera
          </Button>
          <Button
            isLoading={cancelFulfillmentMutation.isLoading}
            size="small"
            variant="outline"
            icon={<MdEdit />}
            onClick={editDialog.open}
          >
            Edytuj
          </Button>
          <Button
            isLoading={cancelFulfillmentMutation.isLoading}
            size="small"
            variant="outline"
            icon={<MdClose />}
            color="danger"
            onClick={() => cancelFulfillmentMutation.mutate({ fulfillmentUuid: props.fulfillment.uuid })}
          >
            Anuluj
          </Button>
        </ButtonGroup>
      }
    >
      <DescriptionList>
        <DescriptionField label="Nr zamówienia Shopera">{props.fulfillment.shoperOrderId}</DescriptionField>
        <DescriptionField label="Id wybranej opcji produktu">{props.fulfillment.productOption}</DescriptionField>
      </DescriptionList>
      {editDialog.element}
    </BaseFulfillmentCard>
  );
};
