import { Uuid, uuidSerializer } from "@megarax/common";
import { paginatedListSerializer, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { TradeRouteSimple } from "./rest";

export interface TradeRouteListQueryFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
  salespersonId?: number;
}

export type NamedTradeRoute = Omit<TradeRouteSimple, "name"> & { name: string };

const namedTradeRouteListQueryFiltersSerializer = MSerializers.object<TradeRouteListQueryFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
  salespersonId: MSerializers.integer.optional(),
});

export const namedTradeRouteSerializer = MSerializers.object<NamedTradeRoute>({
  uuid: uuidSerializer,
  name: MSerializers.string,
  salespersonId: MSerializers.integer,
  route: MSerializers.array(
    MSerializers.object<{ uuid: Uuid; customerUuid: Uuid; durationMinutes: number }>({
      uuid: uuidSerializer,
      customerUuid: uuidSerializer,
      durationMinutes: MSerializers.integer,
    }),
  ),
  distanceMeters: MSerializers.integer,
  fullDurationMinutes: MSerializers.integer,
});

export default ResourceActionV2({
  name: "list",
  method: "get",
  path: "",
  querySerializer: namedTradeRouteListQueryFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(namedTradeRouteSerializer),
});
