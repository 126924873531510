import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";

type Props = {
  children: string;
  actions?: React.ReactNode;
  className?: string;
  isHr?: boolean;
};

export const PageHeader: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  useEffect(() => {
    document.title = props.children;
  }, []);

  return (
    <div css={{ display: "flex", margin: "1rem 0 1rem", alignItems: "center" }} className={props.className}>
      <h2
        css={{
          fontSize: "1.25rem",
          color: theme.colors.primary,
          margin: 0,
          fontFamily: theme.displayFontFamily,
          fontWeight: 700,
        }}
      >
        {props.children}
      </h2>

      {props.actions && <div css={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>{props.actions}</div>}
    </div>
  );
};

export const SectionHeader: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", margin: "1rem 0 0rem", alignItems: "center", gap: "1em" }} className={props.className}>
      <h2
        css={{
          fontSize: "1.125rem",
          color: theme.colors.primary,
          margin: 0,
          fontFamily: theme.displayFontFamily,
          fontWeight: 700,
          whiteSpace: "nowrap",
        }}
      >
        {props.children}
      </h2>
      {props.isHr && (
        <hr
          css={{
            width: "100%",
            border: "none",
            borderTop: `1px solid ${theme.colors.border}`,
            margin: 0,
          }}
        />
      )}
      {props.actions && <div css={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>{props.actions}</div>}
    </div>
  );
};
