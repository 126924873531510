import _ from "lodash";

import { Uuid } from "@megarax/common";
import { VisitDto, WorkDayDto } from "@megarax/crm-contracts";

import { ActivityListEntry, ActivityListVisit } from "./ActivityList";

export type CustomerDictionary = {
  [uuid: Uuid]: { name: string } | undefined | null;
};

export const getActivityListEntries = (
  visits: VisitDto[],
  myWorkDay: WorkDayDto,
  customerDictionary: CustomerDictionary,
) => {
  const entryDictionary: {
    [uuid: Uuid]: ActivityListEntry;
  } = {};

  myWorkDay.visits.forEach((v) => {
    const existing = entryDictionary[v.uuid] as ActivityListVisit | undefined;
    entryDictionary[v.uuid] = {
      type: "visit",
      uuid: v.uuid,
      customerName: existing?.customerName ?? null,
      customerUuid: existing?.customerUuid ?? null,
      endTime: v.endTime,
      startTime: v.startTime,
      scheduledDurationMinutes: null,
      scheduledStartTime: null,
    };
  });

  visits.forEach((v) => {
    const existing = entryDictionary[v.uuid] as ActivityListVisit | undefined;
    entryDictionary[v.uuid] = {
      type: "visit",
      uuid: v.uuid,
      customerName: customerDictionary[v.customerUuid]?.name,
      customerUuid: v.customerUuid,
      endTime: existing?.endTime ?? null,
      startTime: existing?.startTime ?? null,
      scheduledDurationMinutes: v.scheduledDurationMinutes,
      scheduledStartTime: v.scheduledStartTime,
    };
  });

  myWorkDay.events.forEach((e) => {
    entryDictionary[e.uuid] = {
      type: "event",
      endTime: e.endTime,
      name: e.title,
      startTime: e.startTime,
      description: e.description,
      uuid: e.uuid,
    };
  });

  const listEntries = _(entryDictionary)
    .values()
    .sortBy((entry) => {
      if (entry.type === "visit" && entry.startTime === null) {
        if (entry.scheduledStartTime === null) return "";
        return `2${entry.scheduledStartTime}`;
      }
      return `1${entry.startTime}`;
    })
    .value();

  return [...listEntries];
};
