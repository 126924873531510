import { Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";
import Decimal from "decimal.js";

import { CustomerStatus, customerStatuses } from "./customerStatus";

export interface TagCustomerInput {
  tag: string;
}

export const tagCustomerInputSerializer = MSerializers.object<TagCustomerInput>({
  tag: MSerializers.string,
});

export interface ChangeStatusInput {
  status: CustomerStatus;
}

export const changeStatusInputSerializer = MSerializers.object<ChangeStatusInput>({
  status: MSerializers.stringOneOf(...customerStatuses),
});

export interface ReassignRegionInput {
  regionUuid: Uuid;
}

export const reassignRegionInputSerializer = MSerializers.object<ReassignRegionInput>({
  regionUuid: uuidSerializer,
});

export interface AssignToChainInput {
  chainUuid: Uuid | null;
}

export const assignToChainInputSerializer = MSerializers.object<AssignToChainInput>({
  chainUuid: uuidSerializer.nullable(),
});

export interface RenameCustomerInput {
  name: string;
}

export const renameCustomerInputSerialized = MSerializers.object<RenameCustomerInput>({
  name: MSerializers.string,
});

export interface RegisterCustomerInput {
  name: string;
  regionUuid: Uuid;
}

export const registerCustomerInputSerializer = MSerializers.object<RegisterCustomerInput>({
  regionUuid: uuidSerializer,
  name: MSerializers.string,
});

export type VisitLocation = {
  locality: string;
  street: string;
  postalCode: string;
  country: string;
  name: string;
  lat: Decimal;
  lng: Decimal;
};

export interface DesignateVisitLocationInput {
  name: string;
  locality: string;
  street: string;
  postalCode: string;
  country: string;
  lat: Decimal;
  lng: Decimal;
}

export const designateVisitLocationInputSerializer = MSerializers.object<DesignateVisitLocationInput>({
  name: MSerializers.string,
  locality: MSerializers.string,
  street: MSerializers.string,
  postalCode: MSerializers.string,
  country: MSerializers.string,
  lat: MSerializers.decimal,
  lng: MSerializers.decimal,
});
