import { DateString, dateStringSerializer, Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export type VisitDto = {
  uuid: Uuid;

  userId: number;
  date: DateString;

  customerUuid: Uuid;
  isCancelled: boolean;

  startTime: Date | null;
  endTime: Date | null;

  scheduledStartTime: Date | null;
  scheduledDurationMinutes: number | null;
};

export const visitDtoSerializer = MSerializers.object<VisitDto>({
  uuid: uuidSerializer,
  userId: MSerializers.integer,
  date: dateStringSerializer,
  customerUuid: uuidSerializer,
  isCancelled: MSerializers.boolean,
  startTime: MSerializers.datetime.nullable(),
  endTime: MSerializers.datetime.nullable(),
  scheduledDurationMinutes: MSerializers.integer.nullable(),
  scheduledStartTime: MSerializers.datetime.nullable(),
});
