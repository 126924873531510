import { useQuery } from "react-query";

import { toDateString } from "@megarax/common";
import { workDayResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";

export const useWorkDay = (date: Date, userId: number) => {
  const workDayProvider = useResourceProviderV2(workDayResource);

  const { data: workDay, isFetching, refetch } = useQuery(["userWorkDay", date, userId], () =>
    workDayProvider
      .byUserId(userId!)
      .byDate(toDateString(date))
      .retrieve()
      .then((r) => r.assertOk().value),
  );

  return {
    workDay,
    isLoading: isFetching,
    refetch,
  };
};
