import { userResource } from "@megarax/iam-contracts";
import { useProfilesDictionary } from "@megarax/iam-webapp";
import { useResourceProviderV2 } from "@megarax/react-client";
import { QsInput, useDialogWithRoute, useQsV2 } from "@megarax/ui-components";
import _ from "lodash";
import qs from "qs";
import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { z } from "zod";

import { useRegionsResource } from "../resourceHooks";
import { MilageReportDialogContainer } from "../visitSchedule";
import { NewSalespersonDialogContainer } from "./NewSalespersonDialog";
import { SalespeopleHome } from "./SalespeopleHome";
import { useSalespeopleResource } from "./useSalespeopleResource";

interface Props {}

const qsSchema: QsInput<QueryFilters> = {
  searchText: {
    validator: z.string(),
    fallback: "",
  },
};

interface QueryFilters {
  searchText: string;
}

export const SalespeopleHomeContainer: React.FC<Props> = (props) => {
  const history = useHistory();

  const salespeopleResource = useSalespeopleResource();
  const regionsResource = useRegionsResource();
  const { list } = useResourceProviderV2(userResource);

  const { qs, setQs } = useQsV2(qsSchema);

  const milageReportDialog = useDialogWithRoute<{}>("przejechane_kilometry", ({ onClose }) => (
    <MilageReportDialogContainer onClose={onClose} />
  ));
  const newItemDialog = useDialogWithRoute("nowy", ({ onClose }) => (
    <NewSalespersonDialogContainer salespeople={salespeople?.value?.items} onClose={onClose} />
  ));

  const { data: salespeople, isFetching: isSalespeopleFetching } = useQuery(
    ["salespeople"],
    salespeopleResource.listAll,
    {},
  );

  const { data: regionsDictionary, isFetching: isRegionsFetching } = useQuery(
    ["regions"],
    regionsResource.getRegionsDictionary,
    {},
  );

  const { data: allUsersDictionary } = useQuery(["allUsers"], () =>
    list({ limit: 1000 })
      .map((result) => result.items)
      .then((res) => _.keyBy(res.value ?? [], "id")),
  );

  const profilesDictionary = useProfilesDictionary(salespeople?.value?.items.map((item) => ({ id: item.userId })));

  const navigation = {
    openAddSalespersonDialog: newItemDialog.open,
    navigateToDetails: (userId: number) => history.push("/crm/handlowcy/" + userId),
    navigateToPlan: (userId: number) => history.push(`/crm/handlowcy/${userId}/wizyty`),
  };
  return (
    <>
      <SalespeopleHome
        search={{
          onSearchChange: (val) => setQs("searchText", val),
          value: qs.searchText,
        }}
        navigation={navigation}
        query={{
          items: salespeople?.value?.items,
          loading:
            isRegionsFetching ||
            isSalespeopleFetching ||
            profilesDictionary === undefined ||
            allUsersDictionary === undefined,
        }}
        profilesDictionary={profilesDictionary}
        regionsDictionary={regionsDictionary}
        allUsersDictionary={allUsersDictionary}
        onOpenMilageReport={milageReportDialog.open}
      />
      {milageReportDialog.element}
      {newItemDialog.element}
    </>
  );
};
