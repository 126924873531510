import { Uuid } from "@megarax/common";
import { MacroregionSimple } from "@megarax/crm-contracts";
import { NewRouterResponsiveSelect } from "@megarax/ui-components";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";

import { Result } from "@megaron/result";

interface Props {
  appendMacroregion: (uuid: Uuid, macroregionUuid: Uuid) => Promise<Result<void, void>>;
  onComplete: () => void;
  getMacroregions: (searchText: string) => Promise<MacroregionSimple[]>;
  closeDialog: () => void;
  uuid: Uuid;
}

export const MacroregionStep: React.FC<Props> = ({
  appendMacroregion,
  onComplete,
  getMacroregions,
  closeDialog,
  uuid,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [macroregionUuid, setMacroregionUuid] = useState<Uuid | null>(null);

  const nextStepDisabled = !macroregionUuid || loading;

  const onNameRegion = async () => {
    if (nextStepDisabled) return;
    const result = await appendMacroregion(uuid, macroregionUuid);
    if (result.isFailure) return setLoading(false);
    onComplete();
  };

  return (
    <div>
      <Box mb={2}>
        <Typography variant="subtitle2">Wybierz makro region dla regionu:</Typography>
      </Box>
      <Box mb={2}>
        <NewRouterResponsiveSelect
          value={macroregionUuid}
          onChange={setMacroregionUuid}
          getOptions={getMacroregions}
          label="Makro region"
          getKey={(o) => o.uuid}
          getLabel={(o) => o.name}
          getValue={(o) => o.uuid as Uuid}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" py={2} style={{ gap: "8px" }}>
        <Button onClick={closeDialog}>Pomiń</Button>
        <Button variant="contained" color="primary" disabled={nextStepDisabled} onClick={onNameRegion}>
          Zakończ
        </Button>
      </Box>
    </div>
  );
};
