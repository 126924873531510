import { TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { InputProps, PickedTextFieldProps } from "../props";

interface Props extends InputProps<string>, PickedTextFieldProps {
  getOptions: (search: string) => Promise<string[]>;
}

const FILL_SUGGESTION_KEYS = ["Tab", "ArrowRight"];

export const InlineAutosuggest: React.FC<Props> = ({
  getOptions,
  onChange,
  error,
  helperText,
  label,
  value,
  ...muiProps
}) => {
  const classes = useStyles();

  const [options, setOptions] = useState<string[]>([]);
  const [option, setOption] = useState<string>("");

  const debounced = useRef(
    debounce((text: string) => getOptions(text).then(setOptions), 300),
  );

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    setOption("");
    if (!e.target.value) return;
    debounced.current(e.target.value);
  };

  useEffect(() => {
    if (!options[0] || !value) return;
    if (!options[0].includes(value)) return;
    setOption(options[0]);
  }, [options]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (!FILL_SUGGESTION_KEYS.includes(e.key)) return;
    if (!option) return;
    if (e.key === "Tab") {
      e.preventDefault();
    }
    onChange(option);
    setOption("");
  };

  return (
    <div className={classes.wrapper}>
      <TextField
        fullWidth
        variant="outlined"
        disabled
        className={classes.suggestionField}
        value={option}
      />
      <TextField
        {...muiProps}
        value={value}
        error={Boolean(error)}
        helperText={helperText ?? error}
        label={label}
        onChange={onTextChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      position: "relative",
    },
    suggestionField: {
      display: "inline-block",
      position: "absolute",
      left: "0",
      "& fieldset": {
        border: "none",
      },
    },
  };
});
