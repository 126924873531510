import { DraftDto } from "@megaron/invoices-contracts";

import { CreateDraftForm } from "./CreateDraftForm";
import { MailInforamtionTile } from "./MailInformationTile";
import { ThreadDetails } from "./ThreadDetails";
import { useDraftForm } from "./useDraftForm";

type Props = {
  draft: DraftDto;
  isFetching: boolean;
  draftQueryKey: string | string[];
};

export const DraftForm: React.FC<Props> = ({ draft, isFetching, draftQueryKey }) => {
  const { draftData, setDraft, isLoading } = useDraftForm({ draft, draftQueryKey });

  return (
    <>
      {draft.emailSubject && (
        <MailInforamtionTile emailSubject={draft.emailSubject} emailSender={draft.emailSender ?? ""} />
      )}
      <ThreadDetails draftData={draftData} setDraft={setDraft} />
      <CreateDraftForm
        draft={draft}
        isFetching={isFetching}
        draftData={draftData}
        setDraft={setDraft}
        isLoading={isLoading}
      />
    </>
  );
};
