import { useEffect } from "react";

const newUrl = "https://megarax.net";
const newPosCrmUrl = `${newUrl}/crm/pos`;

const getRedirectPathname = (pathname: string) => {
  if (pathname.startsWith("/oferta")) {
    return newPosCrmUrl + pathname.replace("/oferta", "");
  }

  if (pathname.startsWith("/sprzedaz")) {
    return newPosCrmUrl + pathname.replace("/sprzedaz", "");
  }

  if (pathname.startsWith("/crm")) {
    return newPosCrmUrl + pathname.replace("/crm", "");
  }

  if (pathname.startsWith("/faktury")) {
    return newUrl + pathname;
  }

  return newPosCrmUrl + pathname;
};

export const useLegacyMegaraxRedirects = () => {
  useEffect(() => {
    const { pathname, host } = window.location;

    if (host !== "v2.megarax.net") return;

    const destinationPathname = getRedirectPathname(pathname);
    window.location.replace(destinationPathname);
  }, []);
};
