export abstract class MegaraxError extends Error {}

export class EntityNotFoundError extends MegaraxError {
  override name = "EntityNotFoundError";
}

export class MultipleEntitiesFoundError extends MegaraxError {
  override name = "MultipleEntitiesFoundError";
}

export class PermissionDeniedError extends MegaraxError {
  override name = "PermissionDeniedError";
  override message = "You do not have sufficient privilege to perform this operation.";
}

export class InvalidOperationError extends MegaraxError {
  override name = "InvalidOperationError";
}

export class EntityAlreadyExistsError extends InvalidOperationError {
  override name = "EntityAlreadyExistsError";
}

export class ServiceNotFoundError extends MegaraxError {
  override name = "ServiceNotFoundError";
}
