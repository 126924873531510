import { Uuid } from "@megarax/common";
import { customerResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { isNotNull } from "@megarax/utils";
import _ from "lodash";
import { useQuery } from "react-query";

import { Failure, Ok } from "@megaron/result";

export const useCustomer = (uuid: Uuid | undefined | null) => {
  const customerProvider = useResourceProviderV2(customerResource);
  const getCustomer = (uuid: Uuid) =>
    customerProvider
      .byUuid(uuid)
      .retrieve()
      .flatMapError((err) => (err === "CustomerNotFound" ? Ok(null) : Failure(err)))
      .then((r) => r.assertOk().value);

  const { data: customer } = useQuery(["customer", uuid], () => getCustomer(uuid!) ?? null, {
    enabled: !!uuid,
  });

  return uuid && customer;
};

export const useCustomers = (uuids: Uuid[]) => {
  const customerProvider = useResourceProviderV2(customerResource);
  const getCustomers = (uuids: Uuid[]) =>
    customerProvider.bulkRetrieve(undefined, uuids).then((r) => r.assertOk().value);

  const { data: customers } = useQuery(["customers", uuids], () => getCustomers(uuids) ?? null, {
    enabled: uuids.length > 0,
    refetchOnWindowFocus: false,
  });

  const dictionary = _(customers)
    .filter(isNotNull)
    .keyBy(({ uuid }) => uuid)
    .value();

  return dictionary;
};
