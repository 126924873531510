import { useDeviceType } from "@megaron/dash-mq";
import { SectionHeader } from "@megaron/dash-page";
import { Select } from "@megaron/dash-select";

import { Config, useConfig } from "../ConfigContextProvider";
import {
  ACCOUNTS_URL,
  API_URL,
  CRM_URL,
  CrmUrl,
  crmUrls,
  DEV_ACCOUNTS_URL,
  DEV_API_URL,
  DEV_CRM_URL,
  DEV_DOCS_URL,
  DEV_IAM_URL,
  DEV_INVOICES_URL,
  DEV_ORDERS_URL,
  DEV_POINTS_URL,
  DOCS_URL,
  DocsUrl,
  docsUrls,
  IAM_URL,
  IamUrl,
  iamUrls,
  INVOICES_URL,
  InvoicesUrl,
  invoicesUrls,
  LegacyUrl,
  legacyUrls,
  LOCAL_ACCOUNTS_URL,
  LOCAL_API_URL,
  LOCAL_CRM_URL,
  LOCAL_DOCS_URL,
  LOCAL_IAM_URL,
  LOCAL_INVOICES_URL,
  LOCAL_ORDERS_URL,
  LOCAL_POINTS_URL,
  LOCAL_QR_SYNC_URL,
  LoyaltyAccountsUrl,
  loyaltyAccountsUrls,
  loyaltyOrdersUrls,
  LoyaltyOrderUrl,
  LoyaltyPointsUrl,
  loyaltyPointsUrls,
  ORDERS_URL,
  POINTS_URL,
  QR_SYNC_URL,
  QrSyncUrl,
  QrSyncUrls,
  UrlTemplate,
  urlTemplates,
} from "../constants";

const ENVIRONMENT_TEMPLATE = process.env.NX_PUBLIC_ENVIRONMENT_TEMPLATE;

export const ServiceUrlsSettings = () => {
  const { isMobile } = useDeviceType();

  const { config, setConfig } = useConfig();

  const isDisabled = !!ENVIRONMENT_TEMPLATE;

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SectionHeader isHr>Service URLS</SectionHeader>
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", opacity: isDisabled ? 0.5 : 1 }}>
        <Select
          isDisabled={isDisabled}
          label="Service URLS"
          options={urlTemplates}
          onChange={(value) => {
            const newConfig = getTemplateConfig(value as UrlTemplate, config);

            setConfig(newConfig);
          }}
          value={config.urlTemplate}
        />
        <div css={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "0.625rem" }}>
          <Select
            isDisabled={isDisabled}
            label="IAM"
            options={iamUrls}
            onChange={(value) => setConfig({ ...config, iamUrl: value as IamUrl })}
            value={config.iamUrl}
          />
          <Select
            isDisabled={isDisabled}
            label="Legacy"
            options={legacyUrls}
            onChange={(value) => setConfig({ ...config, apiUrl: value as LegacyUrl })}
            value={config.apiUrl}
          />
          <Select
            isDisabled={isDisabled}
            label="Docs"
            options={docsUrls}
            onChange={(value) => setConfig({ ...config, docsUrl: value as DocsUrl })}
            value={config.docsUrl}
          />
          <Select
            isDisabled={isDisabled}
            label="Invoices"
            options={invoicesUrls}
            onChange={(value) => setConfig({ ...config, invoicesUrl: value as InvoicesUrl })}
            value={config.invoicesUrl}
          />
          <Select
            isDisabled={isDisabled}
            label="CRM"
            options={crmUrls}
            onChange={(value) => setConfig({ ...config, crmUrl: value as CrmUrl })}
            value={config.crmUrl}
          />
          <Select
            isDisabled={isDisabled}
            label="Loyalty-points"
            options={loyaltyPointsUrls}
            onChange={(value) => setConfig({ ...config, pointsUrl: value as LoyaltyPointsUrl })}
            value={config.pointsUrl}
          />
          <Select
            isDisabled={isDisabled}
            label="Loyalty-accounts"
            options={loyaltyAccountsUrls}
            onChange={(value) => setConfig({ ...config, accountsUrl: value as LoyaltyAccountsUrl })}
            value={config.accountsUrl}
          />
          <Select
            isDisabled={isDisabled}
            label="Loyalty-orders"
            options={loyaltyOrdersUrls}
            onChange={(value) => setConfig({ ...config, ordersUrl: value as LoyaltyOrderUrl })}
            value={config.ordersUrl}
          />
          <Select
            isDisabled={isDisabled}
            label="QR Sync"
            options={QrSyncUrls}
            onChange={(value) => setConfig({ ...config, qrSyncUrl: value as QrSyncUrl })}
            value={config.qrSyncUrl}
          />
        </div>
      </div>
    </div>
  );
};

const getTemplateConfig = (template: UrlTemplate, config: Config): Config => {
  const prodConfig = {
    ...config,
    urlTemplate: "PROD",
    iamUrl: IAM_URL,
    apiUrl: API_URL,
    docsUrl: DOCS_URL,
    invoicesUrl: INVOICES_URL,
    crmUrl: CRM_URL,
    pointsUrl: POINTS_URL,
    accountsUrl: ACCOUNTS_URL,
    ordersUrl: ORDERS_URL,
    qrSyncUrl: QR_SYNC_URL,
  } as const;

  const devConfig = {
    ...config,
    urlTemplate: "DEV",
    iamUrl: DEV_IAM_URL,
    apiUrl: DEV_API_URL,
    docsUrl: DEV_DOCS_URL,
    invoicesUrl: DEV_INVOICES_URL,
    crmUrl: DEV_CRM_URL,
    pointsUrl: DEV_POINTS_URL,
    accountsUrl: DEV_ACCOUNTS_URL,
    ordersUrl: DEV_ORDERS_URL,
    qrSyncUrl: LOCAL_QR_SYNC_URL,
  } as const;

  const localConfig = {
    ...config,
    urlTemplate: "LOCAL",
    iamUrl: LOCAL_IAM_URL,
    apiUrl: LOCAL_API_URL,
    docsUrl: LOCAL_DOCS_URL,
    invoicesUrl: LOCAL_INVOICES_URL,
    crmUrl: LOCAL_CRM_URL,
    pointsUrl: LOCAL_POINTS_URL,
    accountsUrl: LOCAL_ACCOUNTS_URL,
    ordersUrl: LOCAL_ORDERS_URL,
    qrSyncUrl: LOCAL_QR_SYNC_URL,
  } as const;

  if (template === "LOCAL") {
    return localConfig;
  }

  if (template === "DEV") {
    return devConfig;
  }

  return prodConfig;
};
