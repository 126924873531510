import { validateUuidFormat } from "@megarax/common";
import { isMonth, Month } from "@megarax/crm-contracts";
import { Serializers } from "@megarax/serializers";
import Decimal from "decimal.js";
import _ from "lodash";

import { Failure, Ok } from "@megaron/result";

export type InputRow = {
  regionUuid: string;
  targets: {
    [month: Month]: Decimal | null;
  };
};

export const rawToInput = (raw: unknown) => {
  if (!Array.isArray(raw)) return Failure("InvalidInput");
  if (raw.length === 0) return Failure("InvalidInput");

  const mapped: InputRow[] = [];

  for (const rawRow of raw) {
    if (typeof rawRow !== "object") return Failure("InvalidInput");
    const row = rawToInputRow(rawRow);
    if (row.isFailure) return row;
    mapped.push(row.value);
  }

  return Ok(mapped);
};

export const rawToInputRow = (raw: { [key: string]: unknown }) => {
  if (!("regionUuid" in raw)) return Failure("MissingRegionUuid");
  if (typeof raw.regionUuid !== "string") return Failure("InvalidRegionUuid");
  const isUuidValid = validateUuidFormat(raw.regionUuid);
  if (!isUuidValid) return Failure("InvalidRegionUuid");

  const allKeys = _.keys(raw);
  const months = allKeys.filter(isMonth);

  const targets: InputRow["targets"] = {};

  for (const month of months) {
    try {
      targets[month] = Serializers.decimal.forceDeserialize(raw[month]);
    } catch (err: any) {
      targets[month] = null;
    }
  }

  return Ok<InputRow>({
    targets: targets,
    regionUuid: raw.regionUuid,
  });
};
