import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export type PhoneNumber = string & { __brand: "phoneNumber" };

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const phoneRegex = /^\+\d{1,3}\d{6,14}$/;
  return phoneRegex.test(phoneNumber);
};

export const phoneNumber: Serializer<PhoneNumber> & SerializerExtensions<PhoneNumber> = {
  serialize: (phoneNumber: string) => {
    if (typeof phoneNumber !== "string") throw new Error("PhoneNumberNotAString");
    return phoneNumber;
  },
  deserialize: (raw: any) => {
    if (typeof raw !== "string") return Failure("PhoneNumberNotAString");

    const isValid = isValidPhoneNumber(raw);

    if (!isValid) {
      return Failure("InvalidPhoneNumber");
    }

    return Ok(raw as PhoneNumber);
  },
  ...serializerExtensions(),
};
