import { useQueryClient } from "react-query";

import { Region } from "@megaron/crm-contracts";
import { Avatar } from "@megaron/dash-avatar";
import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { Card, Header2 } from "@megaron/dash-page";
import { Profile } from "@megaron/iam-contracts";

import { EditRegion } from "./EditRegion";

type Props = {
  region: Region;
  profile?: Profile;
  queryKey: string | string[];
};

export const RegionTile: React.FC<Props> = (props) => {
  const queryClient = useQueryClient();
  const { isMobile } = useDeviceType();

  const updateRegionDialog = useDialogRoute(`/${props.region.name}`, ({ onClose }) => (
    <Dialog
      onClose={onClose}
      header={props.region.name}
      placement={isMobile ? "bottom" : "center"}
      css={{ minWidth: "400px" }}
    >
      <EditRegion
        profile={props.profile}
        region={props.region}
        onSuccess={function (): void {
          queryClient.invalidateQueries(props.queryKey);
        }}
      />
    </Dialog>
  ));

  return (
    <>
      <Card variant="secondary" css={{ cursor: "pointer" }} onClick={updateRegionDialog.open}>
        <div css={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <Header2 css={{ fontSize: "16px", fontWeight: "bold" }}>{props.region.name}</Header2>
            <span css={{ color: "black" }}>
              {props.region.customerCount} {props.region.customerCount === 1 ? "klient" : "klientów"}
            </span>
          </div>
          <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <span>
              {props.profile?.firstName} {props.profile?.lastName}
            </span>
            <Avatar src={props.profile ? props.profile.profilePictureUrl : null} />
          </div>
        </div>
      </Card>
      {updateRegionDialog.element}
    </>
  );
};
