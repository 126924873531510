import { isToday, isYesterday } from "date-fns";

export const formatDate = (date: Date) => {
  const timeString = getTimeString(date);

  if (isToday(date)) return `Dziś o ${timeString}`;
  if (isYesterday(date)) return `Wczoraj o ${timeString}`;
  return `${date.toLocaleDateString("pl-PL")} ${timeString}`;
};

export const getTimeString = (date: Date) => {
  const formatNumber = (num: number) => (num < 10 ? `0${num}` : num);
  return `${formatNumber(date.getHours())}:${formatNumber(date.getMinutes())}`;
};
