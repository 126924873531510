import { Uuid } from "@megarax/common";
import { notEmpty } from "@megarax/utils";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom-v5-compat";

import { useCustomersResource } from "@megaron/megarax-v2-resource-hooks";

import { useTradeRoutesResource } from "../Home";
import { Google, MapContainer } from "../mapsCommon/MapContainer";
import { TradeRouteDetails } from "./TradeRouteDetails";

export const TradeRouteDetailsContainer = () => {
  const params = useParams();
  const tradeRouteUuid = params.tradeRouteUuid as Uuid;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="row-reverse"
      height={isMobile ? "calc(100vh - 64px)" : "100vh"}
      position="relative"
    >
      <MapContainer
        mapProps={({ map, google }) => (
          <TradeRouteDetailsInitialPropsInjector map={map} google={google} tradeRouteUuid={tradeRouteUuid} />
        )}
      />
    </Box>
  );
};

interface Props {
  map: google.maps.Map;
  google: Google;
  tradeRouteUuid: Uuid;
}

export const TradeRouteDetailsInitialPropsInjector: React.FC<Props> = ({ tradeRouteUuid, ...mapProps }) => {
  const { bulkRetrieveCustomers } = useCustomersResource();
  const { retrieve: retrieveTradeRoute } = useTradeRoutesResource();

  const tradeRouteQuery = useQuery(["tradeRoute", tradeRouteUuid], () => retrieveTradeRoute(tradeRouteUuid));

  const tradeRouteCustomersQuery = useQuery(
    ["tradeRouteCustomers", tradeRouteQuery.data?.value?.uuid],
    () => {
      if (!tradeRouteQuery.data?.value) return;

      return bulkRetrieveCustomers(tradeRouteQuery.data.value.route.map((item) => item.customerUuid));
    },
    {
      enabled: tradeRouteQuery.data?.isOk,
    },
  );

  const initialStopDurations = tradeRouteQuery.data?.value
    ? _.keyBy(
        tradeRouteQuery.data.value.route.map((route) => ({
          customerUuid: route.customerUuid,
          durationMinutes: route.durationMinutes.toString(),
        })),
        "customerUuid",
      )
    : undefined;

  const initialRoute = tradeRouteCustomersQuery.data?.value
    ?.map((customer) => {
      if (!customer.visitLocation) return null;
      return {
        uuid: customer.uuid,
        name: customer.name ?? "",
        visitAddress: customer.visitLocation,
        status: customer.status,
        permanentTradeRoutesCount: 0,
      };
    })
    .filter(notEmpty);

  if (tradeRouteQuery.isLoading || tradeRouteCustomersQuery.isLoading) return null;
  if (tradeRouteQuery.isError || tradeRouteCustomersQuery.isError) return null;
  if (!initialRoute || !initialStopDurations || !tradeRouteQuery.data?.value)
    return <TradeRouteDetails {...mapProps} />;

  return (
    <TradeRouteDetails
      initialProps={{
        tradeRoute: tradeRouteQuery.data?.value,
        stopDurations: initialStopDurations,
        route: initialRoute,
      }}
      {...mapProps}
    />
  );
};
