import { MSerializers } from "@megarax/serializers";

export type TaskIdentifier = PendingPriceListProposalTaskIdentifier | PendingPayerProposalTaskIdentifier;

export type PendingPriceListProposalTaskIdentifier = {
  type: "pendingPriceListProposal";
  priceListUuid: string;
  proposalUuid: string;
};

export type PendingPayerProposalTaskIdentifier = {
  type: "pendingPayerProposal";
  customerUuid: string;
  proposalUuid: string;
};

export type TaskType = TaskIdentifier["type"];

export const taskStatuses = ["todo", "completed", "cancelled"] as const;
export type TaskStatus = (typeof taskStatuses)[number];
export const taskStatusSerializer = MSerializers.oneOf(...taskStatuses);

export const taskIdentifierSerializer = MSerializers.identity<TaskIdentifier>(); // TODO

export type TaskDto<TIdentifier extends TaskIdentifier = TaskIdentifier> = {
  identifier: TIdentifier;
  addedAt: Date;
};

export const taskDtoSerializer = MSerializers.object<TaskDto>({
  addedAt: MSerializers.datetime,
  identifier: taskIdentifierSerializer,
});

export type TaskGroup<TType extends TaskType> = {
  type: TType;
  identifiers: Extract<TaskIdentifier, { type: TType }>[];
};
