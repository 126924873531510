import { Avatar, ListItemAvatar, ListItemButton, ListItemText, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

import { Uuid } from "@megarax/common";

import { getMessageParts } from "../ChatBox/mentions";

interface Props {
  lastMessage: {
    content: string;
    userId: number;
    userProfile:
      | {
          userId: number;
          name: string;
          pictureUrl: string;
        }
      | undefined;
  };
  customerName: string | undefined;
  isUnread: boolean;
  onClick: () => void;
}

export const CustomerChatFeedEntry: React.FunctionComponent<Props> = ({
  lastMessage,
  customerName,
  onClick,
  isUnread,
}) => {
  const classes = useStyles();

  const secondaryText =
    lastMessage.userProfile && `${lastMessage.userProfile.name}: ${lastMessage.content}`;

  return (
    <ListItemButton onClick={onClick}>
      <ListItemAvatar>
        {lastMessage.userProfile ? (
          <Avatar src={lastMessage.userProfile.pictureUrl} />
        ) : (
          <Skeleton variant="circular" width={40} height={40} />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={customerName ?? <Skeleton width="60%" />}
        className={clsx(classes.itemText, { [classes.highlighted]: isUnread })}
        secondary={secondaryText ?? <Skeleton />}
      />
    </ListItemButton>
  );
};

const useStyles = makeStyles((theme) => ({
  highlighted: {
    [`& > .MuiListItemText-secondary,
      & > .MuiListItemText-primary`]: {
      fontWeight: "bold",
    },
  },

  itemText: {
    "& > .MuiListItemText-secondary": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
}));
