import { useTheme } from "@emotion/react";
import React, { useContext } from "react";
import {
  MdApi,
  MdCheck,
  MdCloseFullscreen,
  MdDriveFileRenameOutline,
  MdOutlineFactCheck,
  MdOutlineInsertDriveFile,
  MdOutlineMailLock,
  MdOutlineMarkEmailRead,
  MdOutlineNumbers,
  MdOutlineUndo,
} from "react-icons/md";

import { BaseEntry, CommentEntry } from "@megaron/dash-history";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { CommentMention, ThreadHistoryEntry } from "@megaron/invoices-contracts";
import { formatDate } from "@megaron/loyalty-webapp-my-account";
import { useProfiles } from "@megaron/megarax-webapps";

type Props = {
  entry: ThreadHistoryEntry;
  approvedAt: Date | null;
  isPrint?: boolean;
};

type CommentEntryType = { message: string; mentions: CommentMention[] };

export const ThreadHistoryTile: React.FC<Props> = (props) => {
  const { profile } = useProfiles();

  const { entryType, actingUser, time } = props.entry;
  const isPrint = props.isPrint;
  const formattedTime = formatDate(new Date(time));
  const profileData = profile(actingUser);
  const fullName = profileData?.firstName ? `${profileData?.firstName} ${profileData?.lastName}` : "Imie Nazwisko";

  const isAfterApproved = props.approvedAt && time > props.approvedAt;

  const ThreadCommentEntry: React.FC<CommentEntryType> = (props) => {
    const theme = useTheme();
    const auth = useContext(IamAuthContext);

    const user = auth.iamUser?.userType === "megarax" && auth.iamUser.email;
    const isMentioned = user && props.mentions.map((m) => m.user).includes(user);

    const highlightMentionsAndHashtags = (message: string): JSX.Element[] => {
      const combinedPattern = /(@[\p{L}\p{N}áéíóúüñłśżźćń]+(?: [\p{L}\p{N}áéíóúüñłśżźćń]+)*|#\p{L}[\p{L}\p{N}áéíóúüñłśżźćń]*)/giu;
    
      const highlightedMessage: JSX.Element[] = [];
      let lastIndex = 0;
      let match: RegExpExecArray | null;
    
      while ((match = combinedPattern.exec(message)) !== null) {
        const matchedText = match[0];
        const matchStart = match.index;
        const matchEnd = matchStart + matchedText.length;
    
        if (matchStart > lastIndex) {
          highlightedMessage.push(
            <React.Fragment key={`text-${lastIndex}`}>{message.slice(lastIndex, matchStart)}</React.Fragment>,
          );
        }
    
        highlightedMessage.push(
          <span
            key={`highlight-${matchStart}`}
            css={{
              color: theme.colors.primary,
              fontWeight: 600,
            }}
          >
            {matchedText}
          </span>,
        );
    
        lastIndex = matchEnd;
      }
    
      if (lastIndex < message.length) {
        highlightedMessage.push(<React.Fragment key={`text-end`}>{message.slice(lastIndex)}</React.Fragment>);
      }
    
      return highlightedMessage;
    };
    

    return (
      <CommentEntry
        fullName={fullName}
        message={highlightMentionsAndHashtags(props.message)}
        time={formattedTime}
        hideAvatar={isPrint}
        imageSrc={profileData?.profilePictureUrl}
        isMessageHighlighted={!!isMentioned}
        showWarningColor={!!isAfterApproved}
      />
    );
  };

  const commonEntryProps = { time: formattedTime, additionalText: fullName, hideIcon: isPrint };

  switch (entryType) {
    case "comment":
      return <ThreadCommentEntry message={props.entry.message} mentions={props.entry.mentions} />;
    case "threadOpened":
      return <BaseEntry title={"Utworzono wątek"} icon={<MdOutlineInsertDriveFile />} {...commonEntryProps} />;
    case "threadApproved":
      return <BaseEntry title={"Zatwierdzono wątek"} icon={<MdCheck />} {...commonEntryProps} />;
    case "approvalRevoked":
      return <BaseEntry title={"Zatwierdzenie cofnięte"} icon={<MdOutlineUndo />} {...commonEntryProps} />;
    case "threadClosed":
      return (
        <BaseEntry
          title={props.entry.reason ? `Zamknięto wątek: ${props.entry.reason}` : "Zamknięto wątek"}
          icon={<MdCloseFullscreen />}
          {...commonEntryProps}
        />
      );
    case "threadReopened":
      return <BaseEntry title={"Ponownie otwarty wątek"} icon={<MdOutlineInsertDriveFile />} {...commonEntryProps} />;
    case "receptionStatusSet":
      return (
        <BaseEntry
          title={props.entry.status ? "Faktura dotarła do firmy" : "Faktura nie dotarła do firmy"}
          icon={props.entry.status ? <MdOutlineMarkEmailRead /> : <MdOutlineMailLock />}
          {...commonEntryProps}
        />
      );
    case "invoiceUpdated":
      return <BaseEntry title={"Poprawienie faktury"} icon={<MdOutlineFactCheck />} {...commonEntryProps} />;
    case "decreeNumberSet":
      return <BaseEntry title={"Numer dekretu ustawiony"} icon={<MdOutlineNumbers />} {...commonEntryProps} />;
    case "threadRenamed":
      return <BaseEntry title={"Zmiana nazwy wątku"} icon={<MdDriveFileRenameOutline />} {...commonEntryProps} />;
    case "invoiceAddedToBc":
      return <BaseEntry title={"Faktura dodana do BC"} icon={<MdApi />} {...commonEntryProps} />;
    default:
      return;
  }
};
