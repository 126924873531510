import React from "react";

import { Page, PageHeader } from "@megaron/dash-page";

import { UsersList } from "./UsersList";

type Props = {};

export const UsersHome: React.FunctionComponent<Props> = (props) => {
  return (
    <Page>
      <PageHeader>Użytkownicy</PageHeader>
      <UsersList />
    </Page>
  );
};
