import { BaseResource, NestedResourceNode, paginatedListSerializer, ResourceActionV2 } from "@megarax/rest-resource";

import { taskDtoSerializer } from "./common";

export const taskResource = BaseResource(["feed", "tasks"], {
  myTasks: ResourceActionV2({
    name: "myTasks",
    method: "get",
    path: "",
    responseValueSerializer: paginatedListSerializer(taskDtoSerializer),
  }),
  maintenance: NestedResourceNode("maintenance", {
    reset: ResourceActionV2({
      name: "maintenance.reset",
      method: "post",
      path: "reset",
    }),
  }),
});
