import { useIntersection } from "@mantine/hooks";
import { PropsWithChildren, useEffect, useState } from "react";

import { SearchWithPagination } from "./SearchWithPagination";

type Props = {
  onSearchChange: (value: string) => void;
  searchValue: string;
  autofocus?: boolean;
  className?: string;
} & (
  | {
      itemCount: number | undefined;
      pageSize: number;
      page: number;
      onPageChange: (page: number) => void;
      hidePagination?: false;
    }
  | {
      itemCount?: undefined;
      pageSize?: undefined;
      page?: undefined;
      onPageChange?: undefined;
      hidePagination: true;
    }
) &
  PropsWithChildren;

export const SearchWithPaginationSticky: React.FC<Props> = (props) => {
  const { ref, entry } = useIntersection({
    threshold: 1,
    rootMargin: "-16px 0px 0px 0px",
  });

  const isSticky = entry?.isIntersecting === false;

  const [searchWidth, setSearchWidth] = useState<number | null>(null);

  useEffect(() => {
    function handleResize() {
      setSearchWidth(document.getElementById("search-with-pagination")?.offsetWidth || 0);
    }

    if (!searchWidth) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [searchWidth]);

  return (
    <>
      <div ref={ref} id="search-with-pagination" css={{ visibility: isSticky ? "hidden" : "visible" }}>
        <SearchWithPagination {...props} />
      </div>
      {isSticky && <SearchWithPagination {...props} isSticky css={{ maxWidth: searchWidth || "100%" }} />}
    </>
  );
};
