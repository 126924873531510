import { DesignateVisitLocationInput } from "@megarax/crm-contracts";
import { Box, Button, Dialog } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React, { useState } from "react";

import { Address, LocationPickerV2 } from "../../shared/LocationPickerV2";

interface Props {
  onClose: () => void;
  currentAddress: {
    name: string;
    locality: string;
    street: string;
    postalCode: string;
    country: string;
  };
  currentLatLng: { lat: number; lng: number } | null;
  onChangeVisitLocation: (input: DesignateVisitLocationInput) => Promise<any>;
}

export const ChangeAddressDialog: React.FunctionComponent<Props> = ({
  onClose,
  currentAddress,
  currentLatLng,
  onChangeVisitLocation,
}) => {
  const classes = useStyles();

  const [address, setAddress] = useState<Address>(currentAddress);

  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>(currentLatLng);
  const [queryLoading, setQueryLoading] = useState<boolean>(false);

  const closeDialog = () => {
    setAddress(currentAddress);
    setLatLng(currentLatLng);
    onClose();
  };

  const save = async () => {
    if (!latLng) return;
    setQueryLoading(true);

    await onChangeVisitLocation({
      ...address,
      lat: new Decimal(latLng.lat),
      lng: new Decimal(latLng.lng),
    }).finally(() => {
      setQueryLoading(false);
    });

    onClose();
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm" onClose={onClose}>
      <Box pt={3} pb={1}>
        <LocationPickerV2 address={address} setAddress={setAddress} latLng={latLng} setLatLng={setLatLng} />
        <Box display="flex" justifyContent="flex-end" alignItems="center" pt={2} px={3}>
          <Button onClick={() => closeDialog()} className={classes.button} disabled={queryLoading} color="inherit">
            Anuluj
          </Button>
          <Button color="primary" disabled={!latLng || queryLoading} onClick={save} className={classes.button}>
            Zapisz
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "0.8rem",
  },
}));
