import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Uuid } from "@megarax/common";
import { Group, MacroregionSimple, RegionSimple } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { GroupListFilters, GroupSimple } from "@megarax/iam-contracts";
import { useAsyncLoad } from "@megarax/react-utils";
import {
  InlineEditableRow,
  NewRouterDialog,
  NewRouterResponsiveSelect,
  TextInput,
  useInlineFieldManager,
  useNewRouterDialog,
} from "@megarax/ui-components";
import { Box, Button, Divider, List, ListItem, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import React, { useMemo } from "react";

import { OnSuccessFunction } from "@megaron/megarax-v2-resource-hooks";
import { Result } from "@megaron/result";

import { EditGroupsDialog } from "./EditGroupsDialog";

interface Props {
  region: RegionSimple;
  renameRegion: (uuid: Uuid, name: string) => Promise<Result<void, void>>;
  appendMacroregion: (uuid: Uuid, macroregionUuid: Uuid) => Promise<Result<void, void>>;
  closeDialog: () => void;
  macroregionsDictionary: _.Dictionary<MacroregionSimple | undefined>;
  getMacroregions: (searchText: string) => Promise<MacroregionSimple[]>;
  getIamGroups: (search: GroupListFilters) => Promise<GroupSimple[]>;
  assignGroups: ({
    onSuccess,
  }: {
    onSuccess: OnSuccessFunction;
  }) => (uuid: Uuid, groupUuids: Uuid[]) => Promise<Result<void, void>>;
  retrieveGroup: (regionUuid: Uuid) => Promise<Result<Group | null, HttpRequestError>>;
}

export const RegionDetailDialog: React.FC<Props> = ({
  region,
  renameRegion,
  closeDialog,
  appendMacroregion,
  macroregionsDictionary,
  getMacroregions,
  retrieveGroup,
  assignGroups,
  getIamGroups,
}) => {
  const classes = useStyles();
  const dialog = useNewRouterDialog("editGroups");

  const { value: group, reload } = useAsyncLoad(() => retrieveGroup(region.uuid), []);
  const { value: groups } = useAsyncLoad(() => getIamGroups({ limit: 1000 }), []);
  const groupsDictionary = useMemo(() => _.keyBy(groups, "uuid"), [groups]);

  const fields = useInlineFieldManager({
    name: {
      currentValue: region.name,
      editQuery: (name) => renameRegion(region.uuid, name),
    },
    macroregionUuid: {
      currentValue: region.macroregionUuid,
      editQuery: (macroregionUuid) => appendMacroregion(region.uuid, macroregionUuid),
    },
  });

  const macroregionName = region.macroregionUuid
    ? macroregionsDictionary[fields["macroregionUuid"].currentValue as Uuid]?.name ?? "Brak"
    : "Brak";

  const editGroupsReady = group?.isOk && groups;

  return (
    <>
      {editGroupsReady && (
        <NewRouterDialog name={dialog.name} fullWidth maxWidth="xs">
          <EditGroupsDialog
            assignGroups={assignGroups({ onSuccess: reload })}
            getIamGroups={getIamGroups}
            currentGroups={group.value ? groups.filter((grp) => group.value.groupUuids.includes(grp.uuid as Uuid)) : []}
            closeDialog={dialog.close}
            regionUuid={region.uuid}
          />
        </NewRouterDialog>
      )}
      <Box px={3} py={1}>
        <InlineEditableRow
          label="Nazwa"
          {...fields["name"]}
          currentValue={region.name}
          InputNode={
            <TextInput
              fullWidth
              label="Nazwa"
              variant="standard"
              value={fields["name"].newValue}
              onChange={fields["name"].onEditValueChange}
            />
          }
        />
      </Box>
      <Divider />
      <Box px={3} py={1}>
        <InlineEditableRow
          label="Makro region"
          {...fields["macroregionUuid"]}
          currentValue={macroregionName}
          InputNode={
            <NewRouterResponsiveSelect
              initialValue={macroregionsDictionary[fields["macroregionUuid"].currentValue as Uuid]}
              variant="standard"
              value={fields["macroregionUuid"].newValue}
              onChange={(val) => fields["macroregionUuid"].onEditValueChange(val)}
              getOptions={getMacroregions}
              label="Makro region"
              getKey={(o) => o.uuid}
              getLabel={(o) => o.name}
              getValue={(o) => o.uuid}
            />
          }
        />
      </Box>
      <Divider />
      {editGroupsReady ? (
        <>
          <Box px={3} py={1}>
            <Typography variant="caption" color="textSecondary">
              Przypisane stanowiska
            </Typography>
          </Box>

          <List disablePadding dense className={classes.groupsList}>
            {group.value ? (
              group.value.groupUuids.map((uuid) => (
                <ListItem key={uuid} disablePadding className={classes.listItem}>
                  <FontAwesomeIcon icon={faCircle} className={classes.dot} />
                  <Typography className={classes.value} variant="subtitle2">
                    {groupsDictionary[uuid]?.name ?? ""}
                  </Typography>
                </ListItem>
              ))
            ) : (
              <Box px={3}>
                <Typography className={classes.value} variant="subtitle2">
                  Brak
                </Typography>
              </Box>
            )}
          </List>
          <Box display="flex" justifyContent="flex-end" px={3}>
            <Button onClick={() => dialog.open()} className={classes.button}>
              Edytuj
            </Button>
          </Box>
        </>
      ) : (
        <Skeleton variant="rectangular" height="100px" />
      )}
      <Divider />

      <Box px={3} py={1} display="flex" justifyContent="flex-end">
        <Button onClick={closeDialog}>Zamknij</Button>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    value: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "0.8rem",
    },
    groupsList: {
      overflowY: "auto",
      maxHeight: "150px",
    },
    dot: {
      fontSize: "0.5rem",
      marginRight: theme.spacing(1),
    },
    listItem: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 3),
    },
  };
});
