import { Box, Button, Divider } from "@mui/material";
import React from "react";

import { Uuid } from "@megarax/common";
import { MacroregionSimple } from "@megarax/crm-contracts";
import { Result } from "@megaron/result";
import { InlineEditableRow, TextInput, useInlineFieldManager } from "@megarax/ui-components";

interface Props {
  macroregion: MacroregionSimple;
  renameMacroregion: (uuid: Uuid, name: string) => Promise<Result<void, void>>;
  closeDialog: () => void;
}

export const MacroregionDetailDialog: React.FC<Props> = ({ macroregion, renameMacroregion, closeDialog }) => {
  const fields = useInlineFieldManager({
    name: {
      currentValue: macroregion.name,
      editQuery: (name) => renameMacroregion(macroregion.uuid, name),
    },
  });
  return (
    <>
      <Box px={3} py={1}>
        <InlineEditableRow
          label="Nazwa"
          {...fields["name"]}
          currentValue={macroregion.name}
          InputNode={
            <TextInput
              fullWidth
              label="Nazwa"
              variant="standard"
              value={fields["name"].newValue}
              onChange={fields["name"].onEditValueChange}
            />
          }
        />
      </Box>
      <Divider />
      <Box px={3} py={1} display="flex" justifyContent="flex-end">
        <Button onClick={closeDialog}>Zamknij</Button>
      </Box>
    </>
  );
};
