import { useTheme } from "@emotion/react";
import _ from "lodash";
import React from "react";
import tinycolor from "tinycolor2";

import { EntityList } from "@megaron/dash-entity-list";
import { SearchBox } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { ProductCodeStatus } from "@megaron/loyalty-points-contracts";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  selectedGroups: string[];
  onSelect: (groupNames: string[]) => void;
};

export const GroupList: React.FunctionComponent<Props> = (props) => {
  const [searchText, setSearchText] = React.useState("");
  const query = useClientManager("loyaltyPoints").codeGroupsQuery().useQuery({ searchText });

  return (
    <div>
      <SearchBox value={searchText} onChange={setSearchText} css={{ marginBottom: "1.5rem", maxWidth: "420px" }} />
      <QuerySkeleton query={query}>
        {(groups) => (
          <EntityList
            items={groups}
            getKey={(g) => g.name}
            isSelectable
            onSelect={(groups) => props.onSelect(groups.map((g) => g.name))}
            selected={props.selectedGroups.map((name) => ({ name }))}
            columns={(item) => [
              { header: "Grupa", content: item.name, isPrimary: true },
              { header: "Wielkość", content: item.total },
              { header: "Ostatnia zmiana", content: item.lastChange?.toLocaleString() },
              { header: "Statusy", content: <StatusProportionBar byStatus={item.byStatus} /> },
            ]}
          />
        )}
      </QuerySkeleton>
    </div>
  );
};

const StatusProportionBar: React.FunctionComponent<{
  byStatus: { [status in ProductCodeStatus]: number };
}> = ({ byStatus }) => {
  const total = _.sum(_.values(byStatus));
  const theme = useTheme();

  return (
    <div
      css={{
        width: "100%",
        minWidth: "250px",
        height: "0.75rem",
        background: "blue",
        display: "flex",
        flexDirection: "row",
        borderRadius: "3px",
        overflow: "hidden",
      }}
    >
      <StatusProportionBarSection color={"#000"} count={byStatus["destroyed"]} total={total} />
      <StatusProportionBarSection color={"#000"} count={byStatus["expired"]} total={total} />
      <StatusProportionBarSection color={"#d9d9d9"} count={byStatus["blank"]} total={total} />
      <StatusProportionBarSection
        color={tinycolor(theme.colors.primary).brighten(40).toHexString()}
        count={byStatus["active"]}
        total={total}
      />
      <StatusProportionBarSection color={theme.colors.primary} count={byStatus["redeemed"]} total={total} />
    </div>
  );
};

const StatusProportionBarSection = (props: { color: string; count: number; total: number }) => {
  return (
    <div
      css={{
        height: "100%",
        background: props.color,
        width: (props.count / props.total) * 100 + "%",
      }}
    />
  );
};
