import { RegionSimple } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { useIamGroupsResource } from "@megarax/iam-webapp";
import { useIndependentAsyncLoad } from "@megarax/react-client";
import { useAsyncLoad, useDepPagination } from "@megarax/react-utils";
import {
  BreadcrumbMarker,
  LoadingBar,
  NewRouterDialog,
  PageTitleManager,
  useNewRouterDialog,
} from "@megarax/ui-components";
import { LinearProgress } from "@mui/material";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { useCrmGroupsResource, useMacroregionsResource, useRegionsResource } from "@megaron/megarax-v2-resource-hooks";
import { Result } from "@megaron/result";

import { NewRegionDialog } from "./NewRegionDialog";
import { RegionDetailDialog } from "./RegionDetailDialog";
import { RegionsHome } from "./RegionsHome";

interface Props {}

const perPage = 20;

export const RegionsHomeContainer: React.FC<Props> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { nameRegion, appendMacroregion, getRegions } = useRegionsResource();
  const { getMacroregionsArray, getMacroregionsDictionary } = useMacroregionsResource();
  const { getIamGroups } = useIamGroupsResource();
  const { assignGroups, retrieveGroup } = useCrmGroupsResource();

  const regionDialog = useNewRouterDialog("region");
  const newRegionDialog = useNewRouterDialog("newRegion");

  const updateQueryUrl = (filters: RegionListQueryFilters, current: qs.ParsedQs) => {
    navigate(
      {
        pathname: location.pathname,
        search: qs.stringify({
          ...current,
          currentPage: filters.page,
          searchTerm: filters.searchTerm,
        }),
      },
      { replace: true },
    );
  };

  const initialValues = getInitialValues(location.search.substring(1));

  const [searchText, setSearchText] = useState<string>(initialValues.search);
  const [regionsQuery, setRegionsQuery] = useState<RegionsQueryResult>();
  const { paginationProps, page } = useDepPagination(
    {
      perPage,
      allCount: regionsQuery?.value?.count,
      initialPage: initialValues.page,
    },
    [searchText],
  );

  const { loading: regionsLoading, reload } = useIndependentAsyncLoad(
    () =>
      getRegions({
        limit: perPage,
        offset: page * perPage,
        searchText,
      }),
    setRegionsQuery,
    [page, searchText],
  );

  const { value: macroregionsDictionary } = useAsyncLoad(getMacroregionsDictionary, []);

  useEffect(() => {
    updateQueryUrl({ searchTerm: searchText, page: page }, qs.parse(location.search.substring(1)));
  }, [searchText, page]);

  if (regionsQuery === undefined || macroregionsDictionary === undefined) return <LinearProgress />;
  if (regionsQuery.isFailure || macroregionsDictionary["isFailure"]) return null;

  const region = regionsQuery.value.items.find((region) => region.uuid === regionDialog.value);

  return (
    <>
      <PageTitleManager title="Regiony" />
      <BreadcrumbMarker title="Regiony" id="regions" />
      <LoadingBar loading={regionsLoading} />

      <NewRouterDialog name={regionDialog.name} fullWidth maxWidth="xs">
        {region && (
          <RegionDetailDialog
            region={region}
            macroregionsDictionary={macroregionsDictionary}
            renameRegion={nameRegion({ onSuccess: reload })}
            appendMacroregion={appendMacroregion({ onSuccess: reload })}
            closeDialog={regionDialog.close}
            getMacroregions={getMacroregionsArray}
            getIamGroups={getIamGroups}
            assignGroups={assignGroups}
            retrieveGroup={retrieveGroup}
          />
        )}
      </NewRouterDialog>
      <NewRouterDialog name={newRegionDialog.name} fullWidth maxWidth="xs">
        <NewRegionDialog
          reload={reload}
          nameRegion={nameRegion({ onSuccess: reload })}
          appendMacroregion={appendMacroregion({ onSuccess: reload })}
          getMacroregions={getMacroregionsArray}
        />
      </NewRouterDialog>
      <RegionsHome
        macroregionsDictionary={macroregionsDictionary}
        regions={regionsQuery.value}
        pagination={paginationProps}
        navigation={{
          navigateToDetails: regionDialog.open,
          openAddCustomerDialog: newRegionDialog.open,
        }}
        search={{
          setSearchText,
          initialValue: initialValues.search,
        }}
      />
    </>
  );
};
const getInitialValues = (queryStrings: string) => {
  const { currentPage, searchTerm } = qs.parse(queryStrings);
  return {
    search: typeof searchTerm === "string" ? searchTerm : "",
    page: typeof currentPage === "string" && !isNaN(parseInt(currentPage)) ? parseInt(currentPage) : 0,
  };
};

type RegionsQueryResult = Result<
  {
    count: number;
    items: RegionSimple[];
  },
  HttpRequestError
>;

export type RegionListQueryFilters = { searchTerm: string; page: number };
