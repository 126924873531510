import { Uuid } from "@megarax/common";
import { customerResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import { useQuery } from "react-query";

import { forceUnpack } from "@megaron/result";

export const useCustomerQuery = (uuid: Uuid) => {
  const customersProvider = useResourceProviderV2(customerResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  return useQuery(["crmCustomer", uuid], () =>
    customersProvider.byUuid(uuid).retrieve().mapError(handleError).then(forceUnpack),
  );
};
