import { useTheme } from "@emotion/react";
import React from "react";
import { MdCheck } from "react-icons/md";
import { useParams } from "react-router-dom-v5-compat";

import { EntitySelect } from "@megaron/dash-entity-list";
import { Button, CheckboxField } from "@megaron/dash-form";
import { Page, PageHeader, SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { AccountDto, SaveAccountBody } from "@megaron/iam-contracts";
import { GroupList } from "@megaron/iam-groups-webapp";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {};

export const AccountPage: React.FunctionComponent<Props> = (props) => {
  const email = useParams<{ email: string }>().email!;
  const theme = useTheme();

  const accountQuery = useClientManager("iam").accountQuery().useQuery({ email });

  const saveMutation = useClientManager("iam")
    .saveAccount()
    .useMutation({
      onSuccess: () => {
        accountQuery.refetch();
      },
    });

  return (
    <Page>
      <PageHeader>{email}</PageHeader>
      <QuerySkeleton query={accountQuery}>
        {(account) => (
          <>
            <AccountForm
              account={account}
              isLoading={saveMutation.isLoading}
              onSave={(body) => saveMutation.mutate({ email, ...body })}
            />
            <div>
              <SectionHeader>Role</SectionHeader>

              <div css={{ display: "flex", flexDirection: "column" }}>
                {account.roles.map((r) => (
                  <span key={r}>{r}</span>
                ))}
              </div>
            </div>
          </>
        )}
      </QuerySkeleton>
    </Page>
  );
};

const AccountForm: React.FunctionComponent<{
  account: AccountDto;
  onSave: (account: SaveAccountBody) => void;
  isLoading: boolean;
}> = (props) => {
  const [groups, setGroups] = React.useState<{ id: Uuid; name: string }[]>(props.account.groups);
  const [isActive, setIsActive] = React.useState(props.account.isActive);

  return (
    <div css={{ maxWidth: "500px" }}>
      <CheckboxField value={isActive} onChange={setIsActive} label="Aktywny?" />
      <EntitySelect items={groups} label="Grupy" dialogPath="grupy" getName={(g) => g.name}>
        <GroupList isSelectable onSelect={setGroups} selected={groups} />
      </EntitySelect>

      <div css={{ marginTop: "1rem", display: "flex" }}>
        <Button
          icon={<MdCheck />}
          isLoading={props.isLoading}
          css={{ marginLeft: "auto" }}
          onClick={() =>
            props.onSave({
              groupIds: groups.map((g) => g.id),
              isActive,
            })
          }
        >
          Zapisz
        </Button>
      </div>
    </div>
  );
};
