import { Serializers } from "@megarax/serializers";

import { KeyValueStore } from "../keyValueStore";

export type CheckpointStore = {
  get: () => Promise<number | null>;
  set: (index: number) => Promise<void>;
};

export const CheckpointStore = (kvStore: KeyValueStore, identifier: string): CheckpointStore => ({
  get: () => kvStore.key(identifier).get().then(Serializers.integer.nullable().forceDeserialize),
  set: (index) => kvStore.key(identifier).set(Serializers.integer.nullable().serialize(index)),
});
