import React, { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";

type ReturnObject<T> = {
  loading: boolean;
  error: null | Error;
  reload: () => Promise<void>;
};

export const useIndependentAsyncLoad = <T>(
  getValue: () => Promise<T>,
  setValue: (value: T) => void,
  deps: React.DependencyList,
): ReturnObject<T> => {
  const [isMounted, setIsMounted] = useState(true);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const relevantCallId = useRef<string>("");
  const isSettable = (id: string) => relevantCallId.current === id && isMounted;

  const reload = async () => {
    const callId = v4();
    relevantCallId.current = callId;
    setLoading(true);

    getValue()
      .then((v) => isSettable(callId) && setValue(v))
      .then(() => isSettable(callId) && setLoading(false))
      .catch((error) => isSettable(callId) && setError(error));
  };

  useEffect(() => {
    setIsMounted(true);
    reload();
    return () => {
      setIsMounted(false);
    };
  }, deps);

  return { loading, error, reload };
};
