import Decimal from "decimal.js";

import { Gtin, gtinSerializer } from "../common";
import { BaseResource, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { Month, monthSerializer } from "./month";

export type MonthCostList = {
  month: Month;

  costs: {
    [gtin: Gtin]: Decimal | null;
  };
};

export const costsSerializer = MSerializers.mapObject(MSerializers.decimal.nullable(), gtinSerializer);

export const monthCostListSerializer = MSerializers.object<MonthCostList>({
  costs: costsSerializer,
  month: monthSerializer,
});

export const costTableResource = BaseResource(["profitMargin", "costTable"], {
  retrieve: ResourceActionV2({
    name: "retrieve",
    method: "get",
    path: "",
    responseValueSerializer: MSerializers.array(monthCostListSerializer),
  }),
  update: ResourceActionV2({
    name: "update",
    method: "put",
    path: "",
    requestBodySerializer: MSerializers.array(monthCostListSerializer),
  }),
});
