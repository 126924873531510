import { Uuid, UuidResourceParam, uuidSerializer } from "@megarax/common";
import { BaseResource, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { listAll, myGroups } from "./list";

export interface Group {
  regionUuid: Uuid;
  groupUuids: Uuid[];
}

export interface AssignGroupsInput {
  groupUuids: Uuid[];
}

export const groupSerializer = MSerializers.object<Group>({
  regionUuid: uuidSerializer,
  groupUuids: MSerializers.array(uuidSerializer),
});

const assignGroupsInputSerialized = MSerializers.object<AssignGroupsInput>({
  groupUuids: MSerializers.array(uuidSerializer),
});

export const groupResource = BaseResource(["crm", "groups"], {
  byUuid: UuidResourceParam("uuid", "uuid", {
    assignGroups: ResourceActionV2({
      name: "assignGroups",
      method: "post",
      path: "assignGroups",
      requestBodySerializer: assignGroupsInputSerialized,
    }),
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      path: "retrieve",
      responseValueSerializer: groupSerializer.nullable(),
    }),
  }),
  listAll: listAll,
  myGroups: myGroups,
});
