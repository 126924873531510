import { authAttributeSerializer } from "@megaron/access-control";
import { customerProductSerializer } from "@megaron/crm-contracts";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { baseDocSerializer } from "../baseDoc";

export const customerDocSerializer = Serializers.object({
  ...baseDocSerializer("customer").fields,

  uuid: uuidSerializer,
  name: Serializers.string,
  phoneNumber: Serializers.string,
  phoneNumberWithoutCountryCode: Serializers.string,
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  postalCode: Serializers.string,
  latitude: Serializers.decimal.nullable(),
  longitude: Serializers.decimal.nullable(),
  interactionCount: Serializers.integer,
  lastInteraction: Serializers.object({
    uuid: uuidSerializer,
    createdAt: Serializers.datetime,
    ownerEmail: Serializers.string,
    message: Serializers.string,
  }).nullable(),
  email: Serializers.email.nullable(),
  lastPurchaseTime: Serializers.datetime.nullable(),
  totalMassKg: Serializers.decimal,
  products: customerProductSerializer.array(),
  region: Serializers.string.nullable(),
  regionOwnerEmail: Serializers.string.nullable(),
  isArchived: Serializers.boolean,
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  tags: Serializers.array(Serializers.string),
  pos: Serializers.array(Serializers.string),
});

export type CustomerDoc = SerializerValue<typeof customerDocSerializer>;

export const customerSortFields = ["totalMassKg", "lastInteractionTime", "lastPurchaseTime"] as const;
export type CustomerSortField = (typeof customerSortFields)[number];

export const customerActions = {
  searchCustomers: HttpAction({
    path: "/customers",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      sort: Serializers.sortFilter(customerSortFields).asJsonString(),
      isPro: Serializers.jsonString(Serializers.boolean),
      isAffiliate: Serializers.jsonString(Serializers.boolean),
      tags: Serializers.jsonString(Serializers.array(Serializers.string)),
      pos: Serializers.jsonString(Serializers.array(Serializers.string)),
      regions: Serializers.jsonString(Serializers.array(Serializers.string)),
      isArchived: Serializers.jsonString(Serializers.boolean),
      isUnassigned: Serializers.jsonString(Serializers.boolean),
      isActive: Serializers.jsonString(Serializers.boolean),
      limit: Serializers.integer,
      offset: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.paginatedList(customerDocSerializer),
    requiresAuth: true,
  }),
};
