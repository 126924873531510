import { TableCell, TableHead, TableRow } from "@mui/material";

export const LinesTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Jednostka</TableCell>
        <TableCell>Ilość</TableCell>
        <TableCell align="right">Ostateczna cena</TableCell>
      </TableRow>
    </TableHead>
  );
};
