import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateString, fromDateString } from "@megarax/common";
import { Currency } from "@megarax/crm-contracts";
import { formatCurrency } from "@megarax/react-utils";
import { Box, Typography } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import Decimal from "decimal.js";

const getOrderLink = (params: GridCellParams) => `/crm/pos/zamowienia/${params.getValue(params.id, "uuid") as string}`;

export const getOrderListColumns = (): GridColDef[] => [
  {
    field: "orderDate",
    headerName: "Data",
    width: 140,
    disableColumnMenu: true,
    valueFormatter: (params) => {
      const date = params.value as DateString;
      return fromDateString(date).toLocaleDateString();
    },
  },
  {
    field: "referenceNumber",
    headerName: "Numer",
    sortable: true,
    width: 160,
    disableColumnMenu: true,
    renderCell: (params) => {
      const referenceNumber = params.value as string;
      return (
        <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="inherit">{referenceNumber}</Typography>
          <a
            onClick={(e) => {
              e.stopPropagation();
            }}
            target="_blank"
            rel="noreferrer"
            href={getOrderLink(params)}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </Box>
      );
    },
  },
  {
    field: "billToName",
    headerName: "Odbiorca faktury",
    sortable: true,
    minWidth: 180,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: "sellToName",
    headerName: "Nabywca",
    sortable: true,
    flex: 1,
    minWidth: 190,
    disableColumnMenu: true,
  },
  {
    field: "netTotal",
    headerName: "Wartość netto",
    headerAlign: "right",
    align: "right",
    flex: 0.5,
    minWidth: 150,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (params) => {
      const netPrice = params.value as Decimal;

      const currency = params.id && (params.api.getCellValue(params.id, "currency") as Currency);
      return currency && formatCurrency(netPrice.toNumber(), currency);
    },
  },
];
