import { MacroregionSimple } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { useIndependentAsyncLoad } from "@megarax/react-client";
import { useDepPagination } from "@megarax/react-utils";
import {
  BreadcrumbMarker,
  LoadingBar,
  NewRouterDialog,
  PageTitleManager,
  useNewRouterDialog,
} from "@megarax/ui-components";
import { LinearProgress } from "@mui/material";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { useMacroregionsResource } from "@megaron/megarax-v2-resource-hooks";
import { Result } from "@megaron/result";

import { MacroregionDetailDialog } from "./MacroregionDetailDialog";
import { MacroregionsHome } from "./MacroregionsHome";
import { NewMacroregionDialog } from "./NewMacroregionDialog";

interface Props {}

const perPage = 20;

export const MacroregionsHomeContainer: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { getMacroregions, nameMacroregion } = useMacroregionsResource();

  const macroregionDialog = useNewRouterDialog("macroregion");
  const newMacroregionDialog = useNewRouterDialog("newmacroregion");

  const updateQueryUrl = (filters: MacroregionListQueryFilters, current: qs.ParsedQs) => {
    navigate(
      {
        pathname: location.pathname,
        search: qs.stringify({
          ...current,
          currentPage: filters.page,
          searchTerm: filters.searchTerm,
        }),
      },
      { replace: true },
    );
  };

  const initialValues = getInitialValues(location.search.substring(1));

  const [searchText, setSearchText] = useState<string>(initialValues.search);
  const [macroregionsQuery, setMacroregionsQuery] = useState<MacroregionsQueryResult>();
  const { paginationProps, page } = useDepPagination(
    {
      perPage,
      allCount: macroregionsQuery?.value?.count,
      initialPage: initialValues.page,
    },
    [searchText],
  );

  const { loading: macroregionsLoading, reload } = useIndependentAsyncLoad(
    () =>
      getMacroregions({
        limit: perPage,
        offset: page * perPage,
        searchText,
      }),
    setMacroregionsQuery,
    [page, searchText],
  );

  useEffect(() => {
    updateQueryUrl({ searchTerm: searchText, page: page }, qs.parse(location.search.substring(1)));
  }, [searchText, page]);

  if (macroregionsQuery === undefined) return <LinearProgress />;
  if (macroregionsQuery.isFailure) return null;

  const macroregion = macroregionsQuery.value.items.find((macroregion) => macroregion.uuid === macroregionDialog.value);

  return (
    <>
      <PageTitleManager title="Makroregiony" />
      <BreadcrumbMarker title="Makroregiony" id="macroregions" />
      <LoadingBar loading={macroregionsLoading} />
      <NewRouterDialog name={macroregionDialog.name} fullWidth maxWidth="xs">
        {macroregion && (
          <MacroregionDetailDialog
            macroregion={macroregion}
            renameMacroregion={nameMacroregion({ onSuccess: reload })}
            closeDialog={macroregionDialog.close}
          />
        )}
      </NewRouterDialog>
      <NewRouterDialog name={newMacroregionDialog.name} fullWidth maxWidth="xs">
        <NewMacroregionDialog
          nameMacroregion={nameMacroregion({ onSuccess: reload })}
          closeDialog={newMacroregionDialog.close}
        />
      </NewRouterDialog>
      <MacroregionsHome
        macroregions={macroregionsQuery.value}
        pagination={paginationProps}
        navigation={{
          navigateToDetails: macroregionDialog.open,
          openAddCustomerDialog: newMacroregionDialog.open,
        }}
        search={{
          setSearchText,
          initialValue: initialValues.search,
        }}
      />
    </>
  );
};
const getInitialValues = (queryStrings: string) => {
  const { currentPage, searchTerm } = qs.parse(queryStrings);
  return {
    search: typeof searchTerm === "string" ? searchTerm : "",
    page: typeof currentPage === "string" && !isNaN(parseInt(currentPage)) ? parseInt(currentPage) : 0,
  };
};

type MacroregionsQueryResult = Result<
  {
    count: number;
    items: MacroregionSimple[];
  },
  HttpRequestError
>;

export type MacroregionListQueryFilters = { searchTerm: string; page: number };
