import { useTheme } from "@emotion/react";
import { FiAlertTriangle } from "react-icons/fi";

export const ProductionEnvAlert = () => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        gap: "0.5rem",
        padding: "0.75rem",
        borderRadius: theme.smallBorderRadius,
        background: theme.colors.dangerBackground,
        color: theme.colors.danger,
        alignItems: "center",
      }}
    >
      <FiAlertTriangle size="1rem" css={{ flexShrink: 0 }} />
      <span css={{ fontSize: "0.875rem" }}>Używasz produkcyjnego API!</span>
    </div>
  );
};
