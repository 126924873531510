import { useTheme } from "@emotion/react";
import { AlertObject, NotificationObject } from "@megarax/notifications-contracts";
import React, { useState } from "react";
import { MdNotificationsNone, MdOutlineNotifications, MdOutlineSettings } from "react-icons/md";

import { Dialog } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { useNotifications } from "@megaron/megarax-webapps";

import { AlertTile } from "./AlertTile";
import { NotificationsPermissions } from "./NotificationsPermissions.";
import { NotificationTile } from "./NotificationTile";

type Props = {
  hideNotification?: boolean;
  closeDrawer?: () => void;
};

export const NotificationButton: React.FunctionComponent<Props> = (props) => {
  const { unreadNotifications, queryKey } = useNotifications();
  const theme = useTheme();
  const { isDesktop } = useDeviceType();

  const [isOpen, setIsOpen] = useState(false);
  const [notificationListOpen, setNotificationListOpen] = useState(false);

  const notifications = unreadNotifications();

  const sortNotificationsByDate = (notifications: { notifications: NotificationObject[]; alerts: AlertObject[] }) => {
    return [...notifications.notifications, ...notifications.alerts].sort((a, b) => {
      const dateA = a.createdAt instanceof Date ? a.createdAt : new Date();
      const dateB = b.createdAt instanceof Date ? b.createdAt : new Date();
      return dateB.getTime() - dateA.getTime();
    });
  };

  return (
    <>
      {!props.hideNotification && (
        <div
          css={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            icon={<MdOutlineNotifications />}
            css={{
              background: "rgba(255,255,255,0.15)",
              width: "32px",
              height: "32px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.closeDrawer && props.closeDrawer();
              setIsOpen(true);
            }}
          />
          {notifications.count > 0 && (
            <div
              css={{
                position: "absolute",
                top: "-4px",
                right: "-4px",
                width: "15px",
                height: "15px",
                backgroundColor: "white",
                borderRadius: "50%",
                boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.colors.primary,
                fontSize: "10px",
                fontWeight: 900,
              }}
            >
              {notifications.count}
            </div>
          )}
        </div>
      )}

      {isOpen && (
        <Dialog
          onClose={() => setIsOpen(false)}
          hideHeader
          css={
            isDesktop && {
              position: "fixed",
              margin: 0,
              width: "400px",
              top: "8px",
              left: "268px",
            }
          }
          placement={isDesktop ? "center" : "top"}
        >
          <div
            css={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              marginBottom: "12px",
              color: theme.colors.primary,
            }}
          >
            <MdOutlineNotifications
              css={{ width: "1.5rem", height: "1.5rem", flexShrink: 0, fill: theme.colors.primary }}
            />
            <span css={{ fontWeight: 700, fontSize: "1.25rem", flexShrink: 0 }}>Powiadomienia</span>
            <div css={{ height: "1px", width: "100%", backgroundColor: theme.colors.primary, border: "none" }} />
            <MdOutlineSettings
              css={{
                width: "1.25rem",
                height: "1.25rem",
                flexShrink: 0,
                fill: theme.colors.primary,
                cursor: "pointer",
              }}
              onClick={() => setNotificationListOpen(true)}
            />
          </div>
          {notifications && notifications.count > 0 ? (
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                maxHeight: "750px",
                overflowY: "auto",
              }}
            >
              {sortNotificationsByDate(notifications)
                .slice(0, 5)
                .map((item, i) => (
                  <div key={i}>
                    {item.hasOwnProperty("readAt") ? (
                      <NotificationTile notification={item as NotificationObject} queryKey={queryKey} />
                    ) : (
                      <AlertTile alert={item as AlertObject} />
                    )}
                  </div>
                ))}
            </div>
          ) : (
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                borderRadius: theme.smallBorderRadius,
                background: "rgba(0, 0, 0, 0.1)",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "8px",
                color: theme.colors.secondaryText,
                height: "104px",
                width: "100%",
              }}
            >
              <MdNotificationsNone css={{ fontSize: "40px" }} />
              <span css={{ fontSize: "14px" }}>Brak powiadomień</span>
            </div>
          )}
        </Dialog>
      )}
      {notificationListOpen && (
        <Dialog onClose={() => setNotificationListOpen(false)} header="Ustawienia powiadomień" css={{ width: "400px" }}>
          <NotificationsPermissions notificationsQueryKey={queryKey} />
        </Dialog>
      )}
    </>
  );
};
