import React from "react";
import { MdPlayArrow, MdPlaylistAdd } from "react-icons/md";

import { useDialogRoute } from "@megaron/dash-dialog";
import { Button, ButtonGroup } from "@megaron/dash-form";
import { Page, PageHeader, SectionHeader } from "@megaron/dash-page";
import { useClientManager } from "@megaron/react-clients";

import { ActivateGroupsDialog } from "./ActivateGroupsDialog";
import { CreateSubgroupDialog } from "./CreateSubgroupDialog";
import { DailyEventStats } from "./DailyEventStats";
import { DailyUserStats } from "./DailyUserStats";
import { GroupList } from "./GroupList";
import { StatusSummary } from "./StatusSummary";

type Props = {};

export const CodeGroupsHome: React.FunctionComponent<Props> = (props) => {
  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([]);

  const query = useClientManager("loyaltyPoints")
    .productCodeSummary()
    .useQuery({
      groupNames: selectedGroups.length ? selectedGroups : undefined,
    });

  const activateDialog = useDialogRoute("/activate-groups", ({ onClose }) => (
    <ActivateGroupsDialog onClose={onClose} groups={selectedGroups} />
  ));
  const subgroupDialog = useDialogRoute("/create-subgroup", ({ onClose }) => (
    <CreateSubgroupDialog onClose={onClose} groups={selectedGroups} />
  ));

  return (
    <Page>
      <PageHeader
        actions={
          <ButtonGroup isVertical={false}>
            <Button
              size="small"
              variant="outline"
              icon={<MdPlayArrow />}
              onClick={activateDialog.open}
              isDisabled={selectedGroups.length < 1}
            >
              Aktywuj
            </Button>
            <Button size="small" icon={<MdPlaylistAdd />} onClick={subgroupDialog.open}>
              Dodaj
            </Button>
          </ButtonGroup>
        }
      >
        Grupy kodów
      </PageHeader>
      <GroupList onSelect={setSelectedGroups} selectedGroups={selectedGroups} />
      <SectionHeader>Statusy</SectionHeader>
      {query.data ? (
        <StatusSummary total={query.data.total} byStatus={query.data.byStatus} css={{ marginBottom: "3rem" }} />
      ) : null}
      <SectionHeader>Skany dziennie</SectionHeader>
      <DailyEventStats groups={selectedGroups.length ? selectedGroups : undefined} />
      <SectionHeader>Użytkownicy dziennie</SectionHeader>
      <DailyUserStats groups={selectedGroups.length ? selectedGroups : undefined} />
      {activateDialog.element}
      {subgroupDialog.element}
    </Page>
  );
};
