import { useTheme } from "@emotion/react";
import React from "react";

import { useDeviceType } from "@megaron/dash-mq";
import { SearchButton } from "@megaron/dash-search";
import { GlobalSearchDialog } from "@megaron/megarax-webapps-home";

import { NavDrawer } from "./NavDrawer";
import { NavEntry } from "./NavEntry";
import { NavHeader } from "./NavHeader";
import { ProductionEnvAlert } from "./ProductionEnvAlert";

export type NavEntryProps = {
  to: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  isActive?: (pathname: string, searchParams: URLSearchParams) => boolean;
};

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  logo: React.ReactNode;
  backgroundImageSrc?: string;
  footer?: React.ReactNode;
  entries: NavEntryProps[];
  hideHeader?: boolean;
  showProductionEnvAlert?: boolean;
  app?: string;
};

export const Nav: React.FunctionComponent<Props> = (props) => {
  const { isDesktop } = useDeviceType();
  const theme = useTheme();

  if (!isDesktop) return <NavDrawer {...props} />;
  const entryElements = props.entries.map((entry) => <NavEntry {...entry} key={entry.to} color="white" />);

  return (
    <>
      <div
        css={{
          width: "260px",
          position: "fixed",
          left: 0,
          padding: "1rem 0.75rem",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          overflowX: "hidden",
          overflowY: "auto",
          backgroundColor: theme.colors.primary,
          background: `radial-gradient(circle at top, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 50%), ${theme.colors.primary}`,
        }}
      >
        <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {!props.hideHeader && <NavHeader title={props.title} logo={props.logo} />}

          <GlobalSearchDialog
            renderTrigger={(onOpen) => (
              <SearchButton onClick={onOpen} shortcut={{ key: "k", modifier: "ctrl" }} variant="transparent" />
            )}
          />
          <div css={{ height: "1px", width: "100%", backgroundColor: "white", opacity: 0.3 }} />

          {props.showProductionEnvAlert && <ProductionEnvAlert />}

          <div css={{ display: "flex", flexDirection: "column", gap: "0.25rem" }}>{entryElements}</div>
        </div>
        {props.footer}
      </div>
      <div css={{ width: "260px", flexShrink: 0 }}></div>
    </>
  );
};
