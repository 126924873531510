import { AnyUser } from "@megaron/access-control";
import { HttpAction, HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";

import { budgetActions } from "./budget";
import { draftActions } from "./draft";
import { threadActions } from "./thread";
import { threadDocsActions } from "./threadDoc";

export const invoicesHttpService = HttpService({
  ...budgetActions,
  ...threadActions,
  ...threadDocsActions,
  ...draftActions,
});

export type InvoicesHandlers = HandlerMap<typeof invoicesHttpService, AnyUser>;
