import { useTheme } from "@emotion/react";
import React from "react";
import { MdCheck, MdClose } from "react-icons/md";

import { Checkbox } from "./Checkbox";

type Props = {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
};

export const CheckboxField: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const IconComponent = props.value ? MdCheck : MdClose;

  return (
    <div
      className={props.className}
      css={{
        padding: "0.5rem 0",
      }}
    >
      <div
        css={{
          backgroundColor: theme.colors.primaryLight,
          minHeight: "2rem",
          maxWidth: "500px",
          borderRadius: theme.smallBorderRadius,
          display: "flex",
          alignItems: "center",
          gap: "0.75rem",
          padding: "0.5rem 0.75rem",
        }}
        onClick={(e) => {
          props.onChange(!props.value);
        }}
      >
        <Checkbox value={props.value} onChange={props.onChange} css={{ backgroundColor: "white" }} />
        <label
          css={{
            color: theme.colors.primary,
            fontFamily: theme.displayFontFamily,
            fontWeight: props.value ? "bold" : "normal",
          }}
        >
          {props.label}
        </label>
        {<IconComponent css={{ marginRight: "0.25rem", marginLeft: "auto", color: theme.colors.primary }} />}
      </div>
    </div>
  );
};
