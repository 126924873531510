export const PRICE_LIST_HOME = "/crm/pos/cenniki";
export const PRICE_LIST_DETAILS = `${PRICE_LIST_HOME}/:uuid`;
export const PRICE_LIST_NEW = `${PRICE_LIST_HOME}/nowy`;

export const PROPOSAL_DETAILS = `${PRICE_LIST_DETAILS}/propozycje/propozycja/:proposalUuid`;
export const PROPOSAL_NEW = `${PRICE_LIST_DETAILS}/:tab/propozycja`;

export const CURRENT_DETAILS = `${PRICE_LIST_DETAILS}/aktualne/jednostka_handlowa/:gtin`;
export const PROPOSE_CHANGE = `${CURRENT_DETAILS}/popozycja_zmiany`;
export const PROPOSE_DELETION = `${CURRENT_DETAILS}/popozycja_usuniecia`;

export const getPriceListHomeUrl = () => PRICE_LIST_HOME;
export const getPriceListDetailsUrl = (uuid: string) => `${PRICE_LIST_HOME}/${uuid}/aktualne`;
export const getPriceListNewUrl = () => PRICE_LIST_NEW;

export const getCurrentDetailsUrl = (uuid: string) => (gtin: string) =>
  `${getPriceListDetailsUrl(uuid)}/jednostka_handlowa/${gtin}`;
export const getProposeDeletionUrl = (uuid: string, gtin: string) =>
  `${getCurrentDetailsUrl(uuid)(gtin)}/popozycja_usuniecia`;
export const getProposeEditUrl = (uuid: string, gtin: string) => `${getCurrentDetailsUrl(uuid)(gtin)}/popozycja_zmiany`;

export const getNewProposalUrl = (uuid: string, tab: "aktualne" | "propozycje") =>
  `${PRICE_LIST_HOME}/${uuid}/${tab}/propozycja`;
export const getProposalDetailUrl = (uuid: string, proposalUuid: string) =>
  `${PRICE_LIST_HOME}/${uuid}/propozycje/propozycja/${proposalUuid}`;
