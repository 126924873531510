import { orderDraftResource, payerResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { BreadcrumbMarkers, commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import { Skeleton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { useCrmGroupsResource, useCustomersResource } from "@megaron/megarax-v2-resource-hooks";

import { NewDraftInput, NewDraftOrder } from "./NewDraftOrder";
import { useInitialCustomer } from "./useInitialCustomerUuid";

interface Props {}

export const NewDraftOrderContainer: React.FC<Props> = (props) => {
  const { getMyGroups } = useCrmGroupsResource();
  const { getCustomersItems } = useCustomersResource();
  const orderDraftProvider = useResourceProviderV2(orderDraftResource);
  const payerProvider = useResourceProviderV2(payerResource);

  const navigate = useNavigate();

  const initialCustomerResult = useInitialCustomer();

  const navigateToDetails = (uuid: string) => navigate("/crm/pos/moje_zamowienia/" + uuid);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    VersionConflict: "Wystąpił konflikt wersji wydarzenia.",
    InvalidDiscountRate: "Nieprawidłowy rabat.",
    PayerDoesNotExist: "Płatnik nie istnieje.",
  });

  const { value: myGroups } = useAsyncLoad(getMyGroups, []);

  const getBuyers = (search: string) => getCustomersItems(search);

  const getPayers = (search: string) => getCustomersItems(search, true);

  const getPayerData = (customerUuid: string) =>
    payerProvider
      .byCustomerUuid(customerUuid)
      .retrieve()
      .mapError((error) => {
        handleError(error);
        return Promise.reject();
      })
      .then((result) => result.value);

  const submitDraft = (input: NewDraftInput) =>
    orderDraftProvider
      .startDraft(undefined, input)
      .mapError(handleError)
      .map(() => navigateToDetails(input.uuid))
      .then(() => {});

  return (
    <>
      <BreadcrumbMarkers
        breadcrumbs={[
          { id: "myOrders", title: "Zamówienia robocze", to: "/crm/pos/moje_zamowienia" },
          { title: "Nowe", id: "newDraft" },
        ]}
      />
      {initialCustomerResult.value === null ? (
        <Skeleton />
      ) : (
        <NewDraftOrder
          myRegions={myGroups?.isOk ? myGroups.value.map((item) => item.regionUuid) : []}
          getBuyers={getBuyers}
          getPayers={getPayers}
          getPayerData={getPayerData}
          submitDraft={submitDraft}
          initialBuyer={initialCustomerResult.value ?? null}
          initialRecipient={initialCustomerResult.value ?? null}
        />
      )}
    </>
  );
};
