import { useTheme } from "@emotion/react";
import axios from "axios";
import heic2any from "heic2any";
import React, { useCallback, useEffect, useState } from "react";
import { MdOutlineInsertDriveFile } from "react-icons/md";
import { Document, Page, pdfjs } from "react-pdf";

import { useDeviceType } from "@megaron/dash-mq";
import { AttachmentDto, DraftAttachmentDto } from "@megaron/invoices-contracts";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type Props = {
  attachment: AttachmentDto | DraftAttachmentDto;
  showPreviewFunction: () => void;
};

export const SUPPORTED_TYPES = ["application/pdf", "image/jpeg", "image/png", "image/heic"];

const PdfViewer: React.FC<{
  file: Uint8Array | null;
  showPreviewFunction: () => void;
  error: string | null;
  loading: boolean;
}> = React.memo(({ file, showPreviewFunction, error, loading }) => {
  const theme = useTheme();

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60px",
          width: "auto",
          color: theme.colors.primary,
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div
      css={{
        position: "relative",
        width: "100%",
        height: "180px",
        overflow: "hidden",
        cursor: "pointer",
      }}
      onClick={showPreviewFunction}
    >
      <div
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "top",
          overflow: "hidden",
        }}
      >
        <Document
          file={{ data: file as Uint8Array }}
          onLoadError={(error) => console.error("PDF Load Error:", error)}
          loading={
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "180px",
                width: "100%",
                color: theme.colors.primary,
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <p>Ładowanie...</p>
            </div>
          }
        >
          <Page
            pageNumber={1}
            scale={0.5}
            css={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
        </Document>
      </div>
    </div>
  );
});

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.attachment.url === nextProps.attachment.url &&
    prevProps.attachment.fileType === nextProps.attachment.fileType
  );
};

export const FilePreview: React.FC<Props> = React.memo(({ attachment, showPreviewFunction }) => {
  const theme = useTheme();
  const { isMobile } = useDeviceType();

  const [file, setFile] = useState<Uint8Array | string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const url = attachment.url;
  const fileType = attachment.fileType;

  const fetchFile = useCallback(async () => {
    if (!url || file) return;

    try {
      setLoading(true);
      const response = await axios({ url, responseType: "arraybuffer" });
      let data = new Uint8Array(response.data);

      if (fileType === "image/heic") {
        try {
          const blob = new Blob([data.buffer], { type: "image/heic" });
          const convertedBlob = await heic2any({ blob, toType: "image/jpeg" });
          const reader = new FileReader();
          reader.readAsDataURL(convertedBlob as Blob);
          reader.onloadend = () => {
            setFile(reader.result as string);
          };
        } catch (conversionError) {
          console.error("HEIC conversion error:", conversionError);
          setError("Nie udało się załadować podglądu HEIC.");
        }
      } else if (fileType === "application/pdf") {
        setFile(data);
      } else {
        setFile(url);
      }
      setLoading(false);
    } catch (err) {
      console.error("Fetch error:", err);
      setError("Nie udało się załadować podglądu");
      setLoading(false);
    }
  }, [url, file, fileType]);

  useEffect(() => {
    fetchFile();
  }, [fetchFile]);

  if (!SUPPORTED_TYPES.includes(fileType)) {
    return (
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme.colors.primaryLight,
          padding: "10px",
          height: isMobile ? "180px" : "auto",
        }}
      >
        <MdOutlineInsertDriveFile size={30} />
      </div>
    );
  }

  if (fileType === "application/pdf") {
    return (
      <PdfViewer file={file as Uint8Array} showPreviewFunction={showPreviewFunction} error={error} loading={loading} />
    );
  }

  return (
    <img
      src={file as string}
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "180px",
        width: "auto",
        objectFit: "cover",
        objectPosition: "top",
        cursor: "pointer",
      }}
      alt="Podgląd"
      onClick={showPreviewFunction}
    />
  );
}, areEqual);
