import { Table as MuiTable, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

export type TableColumn<TValue> = {
  title: string;
  getValue: (item: TValue) => React.ReactNode;
  align?: "right" | "left" | "center";
};

interface Props<TValue, TKey> {
  onSelected?: (key: TKey) => void;
  items: TValue[];
  getKey: (item: TValue) => TKey;
  columns: TableColumn<TValue>[];
  footer?: React.ReactNode;
  showHeader?: boolean;
  stickyHeader?: boolean;
}

export const Table = <TValue, TKey>({
  items,
  columns,
  footer,
  getKey,
  onSelected,
  stickyHeader,
  showHeader = true,
}: Props<TValue, TKey>) => {
  const classes = useStyles();

  const isClickable = onSelected !== undefined;

  return (
    <MuiTable size="small" stickyHeader={stickyHeader}>
      {showHeader && (
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              return (
                <TableCell key={`${column.title}_head`} align={column.align}>
                  {column.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {items.map((item) => {
          return (
            <TableRow
              hover={true}
              key={`${getKey(item)}`}
              className={
                classes.row + " " + (isClickable ? classes.clickableRow : "")
              }
              onClick={() => {
                if (onSelected) onSelected(getKey(item));
              }}
            >
              {columns.map((column) => {
                return (
                  <TableCell
                    key={`${column.title}_value_${getKey(item)}`}
                    align={column.align}
                  >
                    {column.getValue(item)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
        {items.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={columns.length}>
              Brak
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {footer ? footer : null}
    </MuiTable>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    clickableRow: {
      cursor: "pointer",
    },
    row: {
      whiteSpace: "nowrap",
      "& > span": {
        whiteSpace: "normal",
      },
    },
  };
});
