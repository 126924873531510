import { FormControlLabel, FormGroup, Switch, SwitchProps, SxProps, Theme } from "@mui/material";
import React from "react";

interface Props extends Pick<SwitchProps, "color" | "size" | "sx"> {
  label: string;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  labelSx?: SxProps<Theme>;
  value: boolean;
  setValue: (value: boolean) => void;
}

export const SwitchInput: React.FC<Props> = (props) => {
  const { label, value, setValue, labelPlacement, labelSx, ...muiProps } = props;
  return (
    <FormGroup>
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        componentsProps={{
          typography: {
            sx: labelSx,
          },
        }}
        control={
          <Switch
            color="primary"
            checked={value}
            onChange={(e) => setValue(e.target.checked)}
            {...muiProps}
          />
        }
      />
    </FormGroup>
  );
};
