import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { MegaraxRole } from "@megaron/access-control";

import {
  ACCOUNTS_URL,
  API_URL,
  CRM_URL,
  CrmUrl,
  DEV_ACCOUNTS_URL,
  DEV_API_URL,
  DEV_CRM_URL,
  DEV_DOCS_URL,
  DEV_IAM_URL,
  DEV_INVOICES_URL,
  DEV_ORDERS_URL,
  DEV_POINTS_URL,
  DOCS_URL,
  DocsUrl,
  IAM_URL,
  IamUrl,
  INVOICES_URL,
  InvoicesUrl,
  LegacyUrl,
  LoyaltyAccountsUrl,
  LoyaltyOrderUrl,
  LoyaltyPointsUrl,
  ORDERS_URL,
  POINTS_URL,
  QR_SYNC_URL,
  QrSyncUrl,
  UrlTemplate,
} from "./constants";

export type Config = {
  testUser:
    | {
        enabled: true;
        type: "megarax";
        uuid: string;
        email: string | null;
        phoneNumber: string | null;
        roles: MegaraxRole[];
        groups: { id: string; name: string }[];
      }
    | {
        enabled: false;
        type: "megarax";
        uuid: string | null;
        email: string | null;
        phoneNumber: string | null;
        roles: MegaraxRole[];
        groups: { id: string; name: string }[];
      };
  urlTemplate: UrlTemplate;
  iamUrl: IamUrl;
  apiUrl: LegacyUrl;
  docsUrl: DocsUrl;
  invoicesUrl: InvoicesUrl;
  crmUrl: CrmUrl;
  pointsUrl: LoyaltyPointsUrl;
  accountsUrl: LoyaltyAccountsUrl;
  ordersUrl: LoyaltyOrderUrl;
  qrSyncUrl: QrSyncUrl;
};

const defaultConfig: Config = {
  testUser: {
    enabled: false,
    type: "megarax",
    uuid: "25a4ed5b-bd18-4112-9853-dbace86168ba",
    email: null,
    phoneNumber: null,
    roles: [],
    groups: [],
  },
  urlTemplate: "PROD",
  iamUrl: IAM_URL,
  apiUrl: API_URL,
  docsUrl: DOCS_URL,
  invoicesUrl: INVOICES_URL,
  crmUrl: CRM_URL,
  pointsUrl: POINTS_URL,
  accountsUrl: ACCOUNTS_URL,
  ordersUrl: ORDERS_URL,
  qrSyncUrl: QR_SYNC_URL,
};

const developmentConfig: Config = {
  testUser: {
    enabled: false,
    type: "megarax",
    uuid: "25a4ed5b-bd18-4112-9853-dbace86168ba",
    email: null,
    phoneNumber: null,
    roles: [],
    groups: [],
  },
  urlTemplate: "DEV",
  iamUrl: DEV_IAM_URL,
  apiUrl: DEV_API_URL,
  docsUrl: DEV_DOCS_URL,
  invoicesUrl: DEV_INVOICES_URL,
  crmUrl: DEV_CRM_URL,
  pointsUrl: DEV_POINTS_URL,
  accountsUrl: DEV_ACCOUNTS_URL,
  ordersUrl: DEV_ORDERS_URL,
  qrSyncUrl: QR_SYNC_URL,
};

type ConfigContext = {
  config: Config;
  setConfig: (newConfig: Config) => void;
};

const ConfigContext = createContext<ConfigContext>({ config: defaultConfig, setConfig: () => null });

const LOCAL_STORAGE_CONFIG_KEY = "config";
const ENVIRONMENT_TEMPLATE = process.env.NX_PUBLIC_ENVIRONMENT_TEMPLATE;


export const ConfigContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [config, setConfig] = useState<Config>(defaultConfig);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (ENVIRONMENT_TEMPLATE && ENVIRONMENT_TEMPLATE === "production") {
      setConfig(defaultConfig);
      return;
    }

    if (ENVIRONMENT_TEMPLATE && ENVIRONMENT_TEMPLATE === "development") {
      setConfig(developmentConfig);
      return;
    }

    const localConfig = localStorage.getItem(LOCAL_STORAGE_CONFIG_KEY);

    if (!localConfig) {
      setLocalStorageConfig(defaultConfig);
      return;
    }

    setConfig(JSON.parse(localConfig));
  }, []);

  const setNewConfig = useCallback(
    (newConfig: Config) => {
      if (ENVIRONMENT_TEMPLATE) return;

      setConfig(newConfig);
      setLocalStorageConfig(newConfig);
      queryClient.resetQueries();
    },
    [queryClient],
  );

  return (
    <ConfigContext.Provider key={config.iamUrl} value={{ config, setConfig: setNewConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

const setLocalStorageConfig = (newConfig: Config) => {
  localStorage.setItem(LOCAL_STORAGE_CONFIG_KEY, JSON.stringify(newConfig));
};

export const useConfig = () => useContext(ConfigContext);
