import _ from "lodash";

import { configField, makeConfig } from "@megaron/config-manager";
import { AuthMiddleware } from "@megaron/http-service-host";
import { Failure, Ok } from "@megaron/result";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

import { getUserAuthAttributes } from "./authAttribute";
import { MegaraxRole, megaraxRoles } from "./roles";
import { AnyUser, userTypes } from "./user";

const getConfig = makeConfig({
  enableTestUserAuth: configField({ default: false, nonProd: true }, Serializers.booleanString),
});

export const testUserPayloadSerializer = Serializers.object({
  userType: Serializers.stringOneOf(...userTypes),
  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  email: Serializers.string,
  roles: Serializers.stringOneOf(...megaraxRoles).array(),
  groups: Serializers.array(Serializers.object({ id: Serializers.string, name: Serializers.string })),
}).partial();

export type TestUserPayload = SerializerValue<typeof testUserPayloadSerializer>;

export const TestUserAuthMiddleware = (
  configPatch?: Partial<ReturnType<typeof getConfig>>,
): AuthMiddleware<AnyUser> => {
  const config = getConfig(configPatch);
  return async (req) => {
    if (req.authHeader === undefined) return Failure("Unauthenticated");
    const tokenPayloadJson = req.authHeader?.match(/TestUser (.*)/)?.[1];
    if (!tokenPayloadJson) return Failure("Unauthenticated");

    if (!config.enableTestUserAuth) return Failure("TestUserAuthDisabled");

    try {
      const tokenPayloadResult = testUserPayloadSerializer.deserialize(JSON.parse(tokenPayloadJson));
      if (tokenPayloadResult.isFailure) return Failure("InvalidTokenPayload");

      const tokenPayload = tokenPayloadResult.value;

      const userType = tokenPayload.userType ?? "megarax";
      const email = tokenPayload.email ?? "testuser@example.com";
      const uuid = tokenPayload.uuid ?? Uuid("00000000-0000-0000-0000-000000000000");
      const roles = _.intersection(tokenPayload.roles, megaraxRoles) as MegaraxRole[];

      const id = { megarax: email, loyalty: uuid }[userType];

      return Ok<AnyUser>({
        id,
        userType,
        authAttributes: getUserAuthAttributes({ id, roles, userType }),
        uuid,
        email,
        groups: tokenPayload.groups,
        phoneNumber: tokenPayload.phoneNumber,
        roles,
      });
    } catch (error) {
      return Failure("InvalidTokenPayload");
    }
  };
};

export const createTestUserAuthHeader = (tokenPayload?: TestUserPayload): string => {
  const serializedPayload = testUserPayloadSerializer.serialize(tokenPayload ?? {});
  const json = JSON.stringify(serializedPayload);
  return `TestUser ${json}`;
};
