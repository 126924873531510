import { TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  getDetailsLink: () => string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  height: string;
}

export const LinkRow: React.FC<Props> = ({ getDetailsLink, onMouseEnter, onMouseLeave, height }) => {
  const classes = useStyles({ height });
  return (
    <TableRow className={classes["row"]} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <td className={classes["wideCell"]}>
        <Link to={getDetailsLink()} className={classes["link"]} />
      </td>
    </TableRow>
  );
};

interface StyleProps {
  height: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    link: {
      width: "100%",
      height: "100%",
      display: "block",
    },
    wideCell: {
      width: "100%",
      padding: "0",
      display: "block",
      height: ({ height }) => height,
    },
    row: { position: "absolute", width: "100%", display: "table-row" },
  };
});
