import qs from "qs";
import React from "react";
import { useHistory } from "react-router-dom";
import { z, ZodType } from "zod";

interface Entry<TValue> {
  validator: ZodType<TValue>;
  fallback: TValue;
  preprocess?: (val: PossibleQsTypes) => any;
}

type ObjectLiteral = { [key: string]: any };

export type QsInput<TObj extends ObjectLiteral> = {
  [key in keyof TObj]: Entry<TObj[key]>;
};

export type QsOutput<T> = {
  qs: T;
  setQs: (key: keyof T, value: T[typeof key]) => void;
  setAllQs: (values: T) => void;
};

export type PossibleQsTypes = string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined;

export const useQsV2 = <T extends ObjectLiteral>(props: QsInput<T>, initial?: qs.ParsedQs): QsOutput<T> => {
  const history = useHistory();
  const queryStrings = qs.parse(history.location.search.substring(1));

  const validateQs = (entries: [string, any][], qs: qs.ParsedQs) =>
    entries.reduce<T>((parsed, [key, value]) => {
      const queryString = qs[key];
      const result = value.validator.safeParse(value.preprocess ? value.preprocess(queryString) : queryString);
      parsed[key as keyof T] = result.success ? result.data : value.fallback;
      return parsed;
    }, {} as T);

  const entries = Object.entries(props);
  const validated = React.useMemo(() => {
    return validateQs(entries, { ...initial, ...queryStrings });
  }, [entries, queryStrings]);

  const setQs = (key: keyof T, value: T[typeof key]) => {
    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify({
        ...validated,
        [key]: value,
      }),
    });
  };

  const setAllQs = (values: T) => {
    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify(values),
    });
  };

  React.useEffect(() => {
    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify(validated),
    });
  }, []);

  return {
    qs: validated,
    setQs: setQs,
    setAllQs: setAllQs,
  };
};

export const qsIdSchema = {
  validator: z.undefined().or(z.number()),
  fallback: undefined,
  preprocess: (val: PossibleQsTypes) => (typeof val === "string" ? parseInt(val) : undefined),
};

export const qsPageSchema = {
  validator: z.number().positive(),
  fallback: 0,
  preprocess: (val: PossibleQsTypes) => (typeof val === "string" ? parseInt(val) : 0),
};
