import { Loader } from "@googlemaps/js-api-loader";
import { BasicAddress } from "@megarax/common";
import { MiniSelectInput, ResponsiveSelect, TextInput } from "@megarax/ui-components";
import { Box, Divider } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { countryToAbbreviationMap } from "./countriesMap";
import { MapPinDisplay } from "./MapPinDisplay";

export interface Address extends BasicAddress {
  name: string;
}

interface Props {
  address: Address;
  setAddress: (address: Address) => void;
  latLng: { lat: number; lng: number } | null;
  setLatLng: (latLng: { lat: number; lng: number } | null) => void;
}

const loader = new Loader({
  apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  version: "weekly",
  libraries: ["places"],
  language: "pl",
});

export type Google = typeof google;
type PlacePrediction = google.maps.places.AutocompletePrediction;
type GeocoderResult = google.maps.GeocoderResult;

export const LocationPickerV2: React.FC<Props> = ({ address, setAddress, latLng, setLatLng }) => {
  const [prediction, setPrediction] = useState<PlacePrediction | null>(null);
  const [country, setCountry] = useState<string>(
    countryToAbbreviationMap[address.country as keyof typeof countryToAbbreviationMap] ?? "PL",
  );

  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.AutocompleteService | null>(null);

  const updateAutocompleteRef = useRef<(newValue: null) => void>();
  const setUpdateAutocompleteRef = (change: (newValue: null) => void) => {
    updateAutocompleteRef.current = change;
  };

  const onAddressChange = (field: keyof Address) => (value: string) => {
    const newAddress = { ...address, [field]: value };
    setAddress(newAddress);
    if (field === "name") return;
    if (updateAutocompleteRef?.current) updateAutocompleteRef.current(null);
    debouncedLatLngUpdate(newAddress);
  };

  const debouncedLatLngUpdate = useCallback(
    _.debounce(async (address: Address) => {
      if (!geocoder) return null;

      const result = await geocoder.geocode({
        address: formatAddressToGeocode(address),
      });
      if (result.results.length === 0) return setLatLng(null);

      const latLng = result.results[0].geometry.location.toJSON();
      setLatLng(latLng);
    }, 1000),
    [geocoder],
  );

  useEffect(() => {
    loader.load().then((google) => {
      setGeocoder(new google.maps.Geocoder());
      setAutocomplete(new google.maps.places.AutocompleteService());
    });
  }, []);

  const getPredictions =
    (country: string) =>
    async (input: string): Promise<PlacePrediction[]> => {
      if (!autocomplete || input === "") return [];
      const result = await autocomplete.getPlacePredictions({
        input,
        componentRestrictions: { country: country },
        types: ["address"],
      });
      return result.predictions;
    };

  const getPlaceDetailsByPrediction = async (prediction: PlacePrediction) => {
    if (!geocoder) return null;
    const result = await geocoder.geocode({
      placeId: prediction.place_id,
    });
    return result.results[0];
  };

  useEffect(() => {
    if (!prediction) return;
    getPlaceDetailsByPrediction(prediction).then((result) => {
      if (!result) return;
      const fromGeocoder = geocodedResultToLocationAddress(result);
      setAddress({ ...address, ...fromGeocoder });
      setLatLng(result.geometry.location.toJSON());
    });
  }, [prediction]);

  return (
    <>
      {autocomplete && (
        <>
          <Box px={3} mb={2}>
            <Box display="flex" style={{ gap: "16px" }}>
              <Box width="100px" mb={2}>
                <MiniSelectInput
                  label="Kraj"
                  fullWidth
                  value={country}
                  onChange={setCountry}
                  options={allowedCountries}
                  variant="outlined"
                />
              </Box>
              <TextInput
                fullWidth
                label="Nazwa"
                variant={"outlined"}
                value={address.name}
                onChange={onAddressChange("name")}
              />
            </Box>
            <ResponsiveSelect
              label="Wyszukiwanie Adresu"
              getOptions={getPredictions(country)}
              value={prediction}
              onChange={setPrediction}
              getKey={(o) => o.place_id}
              getValue={(o) => o}
              getLabel={(o) => o.description}
              setValueChangeRef={setUpdateAutocompleteRef}
            />
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Box px={3}>
            <Box my={2}>
              <TextInput
                fullWidth
                label="Ulica"
                variant={"outlined"}
                value={address.street}
                onChange={onAddressChange("street")}
              />
            </Box>
            <Box display="flex" style={{ gap: "16px" }} mb={2}>
              <TextInput
                label="Kod pocztowy"
                variant={"outlined"}
                value={address.postalCode}
                onChange={onAddressChange("postalCode")}
              />
              <TextInput
                fullWidth
                label="Miejscowość"
                variant={"outlined"}
                value={address.locality}
                onChange={onAddressChange("locality")}
              />
            </Box>
          </Box>
        </>
      )}
      {latLng && (
        <>
          <Divider />
          <MapPinDisplay latLng={latLng} />
          <Divider />
        </>
      )}
    </>
  );
};

const geocodedResultToLocationAddress = (geocoded: GeocoderResult) => {
  const findByName = (name: string) =>
    geocoded.address_components.find((comp) => comp.types.includes(name))?.long_name ?? "";

  return {
    locality: findByName("locality"),
    street: [findByName("route"), findByName("street_number")].filter((x) => x !== "").join(" "),
    postalCode: findByName("postal_code"),
    country: findByName("country"),
  };
};

export const formatAddressToGeocode = (address: Address) => {
  const secondPartArr = [address.postalCode, address.locality];
  const isSecondPartPresent = secondPartArr.filter((x) => x).length > 0;
  const secondPart = isSecondPartPresent ? secondPartArr.join(" ") : "";
  const thirdPartArr = [address.country];
  const isThirdPartPresent = thirdPartArr.filter((x) => x).length > 0;
  const thirdPart = isThirdPartPresent ? thirdPartArr.join(" ") : "";
  return [address.street, secondPart, thirdPart].filter((x) => x !== "").join(", ");
};

const allowedCountries = ["PL", "DE", "GB", "CZ", "SE", "LT", "LV", "RU", "IE", "BG", "ES", "NL"];

export const formatAddress = (address: Address) => {
  const secondPartArr = [address.postalCode, address.locality];
  const isSecondPartPresent = secondPartArr.filter((x) => x).length > 0;
  const secondPart = isSecondPartPresent ? secondPartArr.join(" ") : "";
  const thirdPartArr = [address.country];
  const isThirdPartPresent = thirdPartArr.filter((x) => x).length > 0;
  const thirdPart = isThirdPartPresent ? thirdPartArr.join(" ") : "";
  return [address.street, address.name, secondPart, thirdPart].filter((x) => x !== "").join(", ");
};
