import { Uuid } from "@megarax/common";
import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom-v5-compat";

interface Props {
  customerName: string;
  customerUuid: Uuid;
}

export const CustomerHeader: React.FunctionComponent<Props> = ({ customerName, customerUuid }) => {
  return (
    <Box>
      <Typography variant="h5">{customerName}</Typography>
      <Link component={RouterLink} to={`/crm/pos/klienci/${customerUuid}`}>
        Strona klienta
      </Link>
    </Box>
  );
};
