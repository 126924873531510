import { AmqpTopic } from "@megarax/amqp";
import { Uuid, uuidSerializer } from "@megarax/common";
import { Gtin, gtinSerializer } from "../common";
import { Serializers } from "@megarax/serializers";

export type TradeItemEventMessage = {
  uuid: Uuid;
  gtin: Gtin;
  name: string;
};

export const tradeItemEventMessageSerializer = Serializers.object<TradeItemEventMessage>({
  uuid: uuidSerializer,
  gtin: gtinSerializer,
  name: Serializers.string,
});

export const tradeItemEventAmqpTopic: AmqpTopic<TradeItemEventMessage> = {
  key: "bc.tradeitem.event",
  serializer: tradeItemEventMessageSerializer,
};

export type OrderPlacedMessage = {
  uuid: Uuid;
};

export const orderPlacedMessageSerializer = Serializers.object<OrderPlacedMessage>({
  uuid: uuidSerializer,
});

export const orderPlacedAmqpTopic: AmqpTopic<OrderPlacedMessage> = {
  key: "bc.order.orderPlaced",
  serializer: orderPlacedMessageSerializer,
};

export type OrdersChangedMessage = {
  uuids: Uuid[];
};

export const ordersChangedMessageSerializer = Serializers.object<OrdersChangedMessage>({
  uuids: Serializers.array(uuidSerializer),
});

export const ordersChangedAmqpTopic: AmqpTopic<OrdersChangedMessage> = {
  key: "bc.order.ordersChanged",
  serializer: ordersChangedMessageSerializer,
};
