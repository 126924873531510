import { CalendarViewWeek } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { DateString, fromDateString, newUuid, toDateString, Uuid } from "@megarax/common";
import { useCurrentUser } from "@megarax/react-client";
import { BreadcrumbMarker, PageTitleManager, useDialogWithRoute } from "@megarax/ui-components";

import { DaySelect } from "../../../DaySelect";
import { useCustomers } from "../../../resourceHooks";
import { UnfinishedVisitsAlertContainer } from "../../UnfinishedVisitsAlert";
import { ActionButtons } from "./ActionButtons";
import { ActivityList } from "./ActivityList";
import { AddUnplannedVisitDialogContainer } from "./AddUnplannedVisitDialog";
import { ChangeEventDialogContainer } from "./ChangeEventDialog";
import { getActivityListEntries } from "./getActivityListEntries";
import { PlanRouteDialogContainer } from "./PlanRouteDialogContainer";
import { useUserVisits } from "./useUserVisits";
import { useOnVisitClose, useVisitNavigation } from "./useVisitNavigation";
import { useWorkDay } from "./useWorkDay";
import { ViewLayout } from "./ViewLayout";
import { WorkTimeCardContainer } from "./WorkTimeCard";

interface Props {
  dateString: DateString;
  onChangeDateString: (str: DateString) => void;
  onGoToWeek: (str: DateString) => void;
  userId: number;
}

export const DayViewContainer: React.FunctionComponent<Props> = ({
  dateString,
  userId,
  onChangeDateString,
  onGoToWeek,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { path, url } = useRouteMatch();

  const date = fromDateString(dateString);

  const { workDay, isLoading: workDayIsLoading, refetch: refetchWorkDay } = useWorkDay(
    date,
    userId,
  );
  const { visits, isLoading: visitsIsLoading, reload: reloadUserVisits } = useUserVisits(
    date,
    userId,
  );
  const customerMap = useCustomers(visits?.map((v) => v.customerUuid) ?? []);

  const entries =
    visits !== undefined && workDay !== undefined
      ? getActivityListEntries(visits, workDay, customerMap)
      : undefined;

  const { closeVisit, openVisit } = useVisitNavigation();

  const openNewBreakDialog = () => history.push(`${url}/przerwa/${newUuid()}`);

  const currentUser = useCurrentUser();
  const isMyDay = userId === currentUser?.id;

  useOnVisitClose(() => {
    refetchWorkDay();
    reloadUserVisits();
  });

  const newVisitDialog = useDialogWithRoute("nowa_wizyta", ({ onClose }) => (
    <AddUnplannedVisitDialogContainer
      date={date}
      onAdded={(uuid) => {
        window.addEventListener("popstate", () => openVisit(uuid), { once: true });
        onClose();
      }}
      onClose={onClose}
    />
  ));

  const changeEventDialog = useDialogWithRoute<{ eventUuid: Uuid }>(
    "wydarzenie/:eventUuid",
    ({ onClose, eventUuid }) =>
      workDay && (
        <ChangeEventDialogContainer
          date={date}
          eventUuid={eventUuid}
          events={workDay.events}
          isOpen={true}
          onChanged={refetchWorkDay}
          onClose={onClose}
        />
      ),
  );
  const openNewEventDialog = () => changeEventDialog.open({ eventUuid: newUuid() });

  const planRouteDialog = useDialogWithRoute("zaplanuj", ({ onClose }) => (
    <PlanRouteDialogContainer
      date={date}
      onChange={() => {
        setTimeout(() => {
          reloadUserVisits();
          refetchWorkDay();
        }, 1700);
      }}
      onClose={onClose}
      userId={userId}
    />
  ));

  return (
    <>
      <PageTitleManager title={dateString} />
      <BreadcrumbMarker title={dateString} to={url} id="date" />
      {newVisitDialog.element}
      {changeEventDialog.element}
      {planRouteDialog.element}
      <ViewLayout
        dateSelect={
          <DaySelect date={date} onChange={(date) => onChangeDateString(toDateString(date))} />
        }
        activityList={
          <ActivityList
            entries={entries}
            onVisitSeeMore={openVisit}
            onEventEdit={(eventUuid) => changeEventDialog.open({ eventUuid })}
          />
        }
        alerts={isMyDay && <UnfinishedVisitsAlertContainer />}
        buttons={
          <ActionButtons
            isMyDay={isMyDay}
            onBreakClick={openNewBreakDialog}
            onEventClick={openNewEventDialog}
            onVisitClick={newVisitDialog.open}
            onRouteClick={planRouteDialog.open}
          />
        }
        workTime={<WorkTimeCardContainer userId={userId} date={date} />}
        isLoading={workDayIsLoading || visitsIsLoading}
        closeVisit={closeVisit}
        viewSwitchButton={
          <IconButton onClick={() => onGoToWeek(dateString)}>
            <CalendarViewWeek />
          </IconButton>
        }
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    overflowX: "hidden",
  },
}));
