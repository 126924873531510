import { Skeleton } from '@mui/material';
import { SkeletonProps } from '@mui/lab';
import React from "react";

export type Success<TValue> = {
  value: TValue;
  isOk: true;
  error: undefined;
};

export type Failure<TError> = {
  value: undefined;
  isOk: false;
  error: TError;
};

export type SimpleResult<TValue, TError> = Success<TValue> | Failure<TError>;

export type SimpleResultInput<TValue, TError> =
  | Success<TValue>
  | Failure<TError>
  | undefined;

interface DefaultProps<TValue, TError> {
  skeletonProps?: SkeletonProps;
  result: SimpleResultInput<TValue, TError>;
  component: (result: Success<TValue>) => React.ReactNode;
  allowErrors: false;
}

interface PropsWithExceptions<TValue, TError> {
  skeletonProps?: SkeletonProps;
  result: SimpleResultInput<TValue, TError>;
  component: (result: SimpleResult<TValue, TError>) => React.ReactNode;
  allowErrors: true;
}

const isPropsWithExceptions = <TValue, TError>(
  props: DefaultProps<TValue, TError> | PropsWithExceptions<TValue, TError>,
): props is PropsWithExceptions<TValue, TError> => {
  const withExceptions = props as PropsWithExceptions<TValue, TError>;
  return withExceptions.allowErrors == true;
};

export const ResultSkeletonLoader = <TValue, TError>(
  props: DefaultProps<TValue, TError> | PropsWithExceptions<TValue, TError>,
) => {
  if (props.result === undefined)
    return <Skeleton variant="text" width="100%" {...props.skeletonProps} />;
  if (!props.result.isOk) {
    if (isPropsWithExceptions(props))
      return <>{props.component(props.result as Failure<TError>)}</>;
    return <Skeleton variant="text" width="100%" {...props.skeletonProps} />;
  }
  return <>{props.component(props.result)}</>;
};
