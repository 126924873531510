import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const permissionScopes = ["allCustomers", "unassignedCustomers"] as const;
export type PermissionScope = (typeof permissionScopes)[number];

const saveRegionBodySerialzier = Serializers.object({
  name: Serializers.string,
  ownerEmail: Serializers.string.nullable(),
});

const regionSerializer = Serializers.object({
  name: Serializers.string,
  ownerEmail: Serializers.string.nullable(),
  customerCount: Serializers.integer,
});

export type Region = SerializerValue<typeof regionSerializer>;

const regionListSerializer = Serializers.object({
  items: Serializers.array(regionSerializer),
  count: Serializers.integer,
});

const saveMacroregionBodySerializer = Serializers.object({
  name: Serializers.string,
  regions: Serializers.array(Serializers.string),
  emails: Serializers.array(Serializers.string),
});

const macroregionSerializer = Serializers.object({
  name: Serializers.string,
  regions: Serializers.array(Serializers.string),
  emails: Serializers.array(Serializers.string),
  customerCount: Serializers.integer,
});

export type Macroregion = SerializerValue<typeof macroregionSerializer>;

const macroregionListSerializer = Serializers.object({
  items: Serializers.array(macroregionSerializer),
  count: Serializers.integer,
});

export const regions = {
  saveRegion: HttpAction({
    path: "/region",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: saveRegionBodySerialzier,
  }),

  saveMacroregion: HttpAction({
    path: "/macroregion",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: saveMacroregionBodySerializer,
  }),

  regionsQuery: HttpAction({
    path: "/region",
    method: "get",
    requiresAuth: true as const,
    qsSerializer: Serializers.object({
      regionName: Serializers.string,
    }).partial(),
    valueSerializer: regionListSerializer,
  }),

  macroregionsQuery: HttpAction({
    path: "/macroregions",
    method: "get",
    requiresAuth: true as const,
    qsSerializer: Serializers.object({
      macroregionName: Serializers.string,
    }).partial(),
    valueSerializer: macroregionListSerializer,
  }),
};
