import { profileResource, UserProfileDto } from "@megarax/iam-contracts";
import { ResourceProvider } from "@megarax/rest-resource";

export interface UserProfile {
  user: {
    id: number;
    email: string;
  };
  firstName?: string;
  lastName?: string;
  fullName?: string;
  pictureUrl?: string | null;

  displayedName: string;
}

const userProfileFromDto = (dto: UserProfileDto): UserProfile => {
  const fullName = dto.firstName && dto.lastName ? dto.firstName + " " + dto.lastName : undefined;

  const displayedName = fullName ?? dto.user.email;

  return {
    ...dto,
    fullName,
    displayedName,
  };
};

export type UserProfileStore = (user: { id: number }) => Promise<UserProfile>;

interface ProfilePromiseMap {
  [userId: number]: Promise<UserProfile> | undefined;
}

export const createUserProfileStore = (
  profileProvider: ResourceProvider<typeof profileResource["nodes"]>,
): UserProfileStore => {
  const profilePromises: ProfilePromiseMap = {};

  return (user) => {
    if (profilePromises[user.id]) return profilePromises[user.id]!;
    profilePromises[user.id] = profileProvider.byId(user.id).retrieve().then(userProfileFromDto);
    return profilePromises[user.id]!;
  };
};
