import { SortFilter, sortFilterSerializer } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

export const orderSortFields = ["referenceNumber", "sellToName", "billToName", "orderDate"] as const;
export type OrderSortField = typeof orderSortFields[number];

export type OrderFilters = {
  searchText?: string;
  sortBy?: SortFilter<OrderSortField>[];
  limit?: number;
  offset?: number;
};

export const orderFiltersSerializer = MSerializers.object<OrderFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
  sortBy: sortFilterSerializer(...orderSortFields).optional(),
});
