import { faDollyFlatbed } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {}

export const NoOrdersScreen: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles();

  return (
    <Box display="flex" height="150px" alignItems="center">
      <Box textAlign="center" marginX="auto">
        <Box mb={1}>
          <FontAwesomeIcon icon={faDollyFlatbed} size="2x" />
        </Box>
        <Typography align="center" color="textSecondary" variant="caption">
          Brak zamówień.
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({}));
