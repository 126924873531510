import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";

import { Uuid, validateUuid } from "@megarax/common";
import { useCurrentUser } from "@megarax/react-client";

import { EditBreakDialog } from "./EditBreakDialog";
import { EditEndDialog } from "./EditEndDialog";
import { EditStartDialog } from "./EditStartDialog";
import { useDialogRoute } from "./useDialogRoute";
import { useWorkTime } from "./useWorkTime";
import { WorkTimeCard } from "./WorkTimeCard";
import { WorkTimeCardSkeleton } from "./WorkTimeCardSkeleton";

interface Props {
  userId: number;
  date: Date;
}

export const WorkTimeCardContainer: React.FunctionComponent<Props> = ({ userId, date }) => {
  const classes = useStyles();
  const editStartDialog = useDialogRoute("poczatek");
  const editEndDialog = useDialogRoute("koniec");
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const {
    myWorkDay,
    isLoading,
    refetch,
    changeBreak,
    changeEnd,
    changeStart,
    deleteBreak,
  } = useWorkTime(userId, date);

  const openBreakDialog = (uuid: Uuid) => history.push(`${url}/przerwa/${uuid}`);

  const currentUser = useCurrentUser();
  const isMine = currentUser?.id === userId;

  return myWorkDay?.value !== undefined ? (
    <>
      <WorkTimeCard
        myWorkDay={myWorkDay.value}
        isLoading={isLoading}
        onEditEndDialogOpen={editEndDialog.open}
        onEditStartDialogOpen={editStartDialog.open}
        openBreakDialog={openBreakDialog}
        isDisabled={!isMine}
      />

      <Route
        path={`${path}/przerwa/:breakUuid`}
        render={(props) => (
          <EditBreakDialog
            onDelete={deleteBreak}
            onClose={history.goBack}
            allBreaks={myWorkDay.value.breaks}
            onSave={changeBreak}
            breakUuid={validateUuid(props.match.params.breakUuid).assertOk().value}
          />
        )}
      ></Route>

      {editStartDialog.isOpen && (
        <EditStartDialog
          onSave={changeStart}
          isOpen={true}
          onClose={editStartDialog.close}
          startOdometer={myWorkDay.value.startOdometer}
          startTime={myWorkDay.value.startTime}
        />
      )}

      {editEndDialog.isOpen && (
        <EditEndDialog
          onSave={changeEnd}
          isOpen={true}
          onClose={editEndDialog.close}
          endOdometer={myWorkDay.value.endOdometer}
          endTime={myWorkDay.value.endTime}
        />
      )}
    </>
  ) : (
    <WorkTimeCardSkeleton />
  );
};

const useStyles = makeStyles((theme) => ({}));
