import { useTheme } from "@emotion/react";
import React from "react";

type Props = {
  children?: React.ReactNode;
  logo?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  actions?: React.ReactNode;
  onClick?: () => void;
  className?: string;
};

export const TitleBar: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <div
      onClick={props.onClick}
      className={props.className}
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: theme.smallBorderRadius,
        overflow: "hidden",
        width: "100%",
        boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.colors.primary,
          color: "white",
          padding: "1.35rem",
          fontSize: "2rem",
        }}
      >
        {props.logo}
      </div>
      <div
        css={{
          backgroundColor: "white",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 1.5rem",
        }}
      >
        <div css={{}}>
          <div
            css={{
              fontSize: "1.25rem",
              fontFamily: theme.displayFontFamily,
              fontWeight: "700",
              color: theme.colors.primary,
              lineHeight: 1.1,
            }}
          >
            {props.title}
          </div>
          <div css={{ fontSize: "14px", lineHeight: 0.9, opacity: "0.6" }}>{props.subtitle}</div>
        </div>
        {props.actions}
      </div>
    </div>
  );
};
