import { UserWorkTimeReport } from "@megarax/crm-contracts";
import { Download } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "@mui/x-date-pickers";

export type DateRange = [since: Date | null, until: Date | null];

interface Props {
  onClose: () => void;
  dateRange: DateRange;
  onDateRangeChange: (range: DateRange) => void;

  report: UserWorkTimeReport | null | undefined;
  onDownload: () => void;
}

export const WorkTimeReportDialog: React.FunctionComponent<Props> = ({
  onClose,
  dateRange,
  onDateRangeChange,
  report,
  onDownload,
}) => {
  const classes = useStyles();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Zestawienie czasu pracy</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <DatePicker
            label="Od"
            className={classes.picker}
            value={dateRange[0]}
            onChange={(date) => onDateRangeChange([date, dateRange[1]])}
          />
        </div>
        <div>
          <DatePicker
            label="Do"
            className={classes.picker}
            value={dateRange[1]}
            onChange={(date) => onDateRangeChange([dateRange[0], date])}
          />
        </div>
        {report !== null &&
          (report === undefined ? (
            <Skeleton height={400} />
          ) : (
            <>
              <Box className={classes.summaryRow}>
                <Box>
                  <Typography variant="overline">Razem</Typography>
                  <Typography variant="h5">{formatWorkTime(report.totalWorkTimeMinutes)}</Typography>
                </Box>
                <IconButton color="primary" className={classes.downloadButton} onClick={onDownload}>
                  <Download fontSize="inherit" />
                </IconButton>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Początek</TableCell>
                    <TableCell>Koniec</TableCell>
                    <TableCell>Przerwy</TableCell>
                    <TableCell>Czas pracy</TableCell>
                    <TableCell>Ilość wizyt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.days.map((day) => (
                    <TableRow key={day.date}>
                      <TableCell>{day.date}</TableCell>
                      <TableCell>{day.startTime ? formatTime(day.startTime) : "-"}</TableCell>
                      <TableCell>{day.endTime ? formatTime(day.endTime) : "-"}</TableCell>
                      <TableCell>{formatWorkTime(day.breakDurationMinutes)}</TableCell>
                      <TableCell>{formatWorkTime(day.workTimeMinutes ?? 0)}</TableCell>
                      <TableCell>{day.visitCount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ))}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
  },
  downloadButton: {
    marginLeft: "auto",
    fontSize: "3rem",
  },
  summaryRow: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
  },
  header: {
    flex: 1,
  },
  picker: {
    marginBottom: theme.spacing(2),
  },
}));

const formatTime = (date: Date) =>
  date.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "2-digit",
  });

const formatWorkTime = (minutes: number) => {
  const fullHours = Math.floor(minutes / 60);
  const remainingMinutes = minutes - fullHours * 60;

  const hourText = fullHours > 0 ? `${fullHours} godz.` : null;
  const minuteText = remainingMinutes > 0 ? `${remainingMinutes} min.` : null;

  if (hourText === null) return minuteText ?? "-";
  if (minuteText === null) return hourText;
  return `${hourText} ${minuteText}`;
};
