import React from "react";
import { BsStars } from "react-icons/bs";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import { RiMedal2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom-v5-compat";

import { CustomerEvent, EventType, UserInvitedEvent } from "@megaron/crm-contracts";
import { BaseEntry } from "@megaron/dash-history";
import { LoyaltyAdminLogo } from "@megaron/megarax-links";

import { formattedDateString } from "./InteractionTile";

interface Props {
  event: CustomerEvent;
}

export const EventTile: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const additionalText = (event: CustomerEvent): string | undefined => {
    switch (event.type) {
      case "userJoined":
        return undefined;
      case "proStatusEarned":
        return undefined;
      case "userInvited":
        return event.eventBody.invitedCustomerFirstName && event.eventBody.invitedCustomerLastName
          ? `${event.eventBody.invitedCustomerFirstName} ${event.eventBody.invitedCustomerLastName}`
          : `${event.eventBody.invitedCustomerPhoneNumber}`;
      case "rewardPurchased":
        return event.eventBody.rewardId;
      default:
        return undefined;
    }
  };

  const titleAdditionalText = additionalText(props.event);

  return (
    <BaseEntry
      icon={getIconByEventType(props.event.type)}
      title={`${getMessageByEventType(props.event.type)}${titleAdditionalText ? `: ${titleAdditionalText}` : ""}`}
      time={formattedDateString(props.event.createdAt)}
      onClick={
        props.event.type === "userInvited"
          ? () => navigate(`../id/${(props.event as UserInvitedEvent).eventBody.invitedCustomerUuid}`)
          : undefined
      }
    />
  );
};

const getMessageByEventType = (eventType: EventType): string => {
  switch (eventType) {
    case "userJoined":
      return "Klient dołączył do programu";
    case "proStatusEarned":
      return "Klient uzyskał status Pro";
    case "userInvited":
      return "Klient zaprosił użytkownika";
    case "rewardPurchased":
      return "Klient odebrał nagrodę";
    default:
      return "";
  }
};

const getIconByEventType = (eventType: EventType): React.ReactNode => {
  switch (eventType) {
    case "userJoined":
      return (
        <LoyaltyAdminLogo
          css={{
            height: "16px",
            width: "16px",
          }}
        />
      );
    case "proStatusEarned":
      return <BsStars />;
    case "userInvited":
      return <MdOutlinePersonAddAlt />;
    case "rewardPurchased":
      return <RiMedal2Line />;
    default:
      return (
        <LoyaltyAdminLogo
          css={{
            height: "16px",
            width: "16px",
          }}
        />
      );
  }
};
