import { TableHeadColumn } from "libs/dash/table/src/TableHead";

import { Table } from "@megaron/dash-table";
import { ThreadSortField } from "@megaron/docs-contracts";
import { Profile } from "@megaron/iam-contracts";
import { InvoiceThreadDoc } from "@megaron/invoices-contracts";

import { tableHeadingsList } from "./AllThreadsHome";
import { AllThreadsTableBody } from "./AllThreadsTableBody";
import { SortField } from "./useThreadFilters";

type Props = {
  threads: InvoiceThreadDoc[];
  className?: string;
  onRefetch: () => void;
  userEmail: string;
  userRoles: string[] | undefined;
  profiles: Profile[] | undefined;
  sortValue: SortField | undefined;
  onSortChange: (value: SortField | undefined) => void;
  isLoading: boolean;
  visibleTableColumns: string[];
};

export const AllThreadsTable: React.FC<Props> = ({
  className,
  threads,
  onRefetch,
  userEmail,
  userRoles,
  profiles,
  sortValue,
  onSortChange,
  isLoading,
  visibleTableColumns,
}) => {
  const tableColumns: TableHeadColumn<ThreadSortField>[] = [
    {},
    ...tableHeadingsList.map((row) =>
      row.sortFieldName
        ? ({
            isSortable: true,
            label: row.label,
            sortFieldName: row.sortFieldName,
            isHidden: !visibleTableColumns.includes(row.label),
          } as const)
        : ({
            isSortable: false,
            label: row.label,
            isHidden: !visibleTableColumns.includes(row.label),
          } as const),
    ),
    {},
  ];

  const visibleTableColumnsIndexes = tableColumns
    .map((column, index) => (!column.label === undefined || !column.isHidden ? index : null))
    .filter((index) => index !== null) as number[];

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
      <Table<ThreadSortField> activeSortValue={sortValue} onSort={onSortChange} columns={tableColumns}>
        <AllThreadsTableBody
          threads={threads}
          onRefetch={onRefetch}
          userEmail={userEmail}
          userRoles={userRoles}
          profiles={profiles}
          isLoading={isLoading}
          visibleTableColumnsIndexes={visibleTableColumnsIndexes}
        />
      </Table>
    </div>
  );
};
