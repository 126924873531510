import { Avatar, Card, CardActions, CardHeader } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";

interface Props {
  actions?: React.ReactNode;
  icon: React.ReactNode;
  title: string;
  time: Date;
  children?: React.ReactNode;
}

export const BaseTaskGroupCard: React.FunctionComponent<Props> = ({ children, actions, icon, title, time }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        title={title}
        subheader={formatDistanceToNowStrict(time, {
          locale: pl,
          addSuffix: true,
        })}
        avatar={<Avatar className={classes.cardAvatar}>{icon}</Avatar>}
      />
      {children}
      <CardActions disableSpacing>{actions}</CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "1rem",
  },
  cardAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  supplementaryCardAction: {
    marginLeft: "auto",
  },
}));
