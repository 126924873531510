import Decimal from "decimal.js";

import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

export type MyAccountDto = {
  uuid: Uuid;
  phoneNumber: string;
  scheduledDeletionTime: Date | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  unverifiedEmail: string | null;
  isRegistered: boolean;
  isVerified: boolean;
  emailConsent: boolean;
  phoneCallConsent: boolean;
  smsConsent: boolean;
};

export const myAccountDtoSerializer = Serializers.object<MyAccountDto>({
  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  scheduledDeletionTime: Serializers.datetime.nullable(),
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  email: Serializers.string.nullable(),
  unverifiedEmail: Serializers.string.nullable(),
  isRegistered: Serializers.boolean,
  isVerified: Serializers.boolean,
  emailConsent: Serializers.boolean,
  phoneCallConsent: Serializers.boolean,
  smsConsent: Serializers.boolean,
});
