import { Uuid } from "@megarax/common";
import { Serializers } from "@megarax/serializers";

export type FixedRouteSimple = {
  id: number;
  name: string;
};

export const fixedRouteSimpleSerializers = Serializers.object<FixedRouteSimple>({
  id: Serializers.integer,
  name: Serializers.string,
});

export type FixedRouteFilters = {
  userId: number | undefined;
};
