import _ from "lodash";

import { Serializer, Serializers } from "@megaron/serializers";

type Config = Record<string, any>;

type GetConfig<T extends Config> = (params?: Partial<T>) => T;

type ConfigFieldSchema<T = string> = {
  serializer: Serializer<T>;
  default?: T;
  prod?: T;
  nonProd?: T;
  test?: T;
  dev?: T;
};

type ConfigSchema<T extends Config> = {
  [key in keyof T]: ConfigFieldSchema<T[key]>;
};

export const makeConfig =
  <T extends Config>(schema: ConfigSchema<T> | (() => ConfigSchema<T>)): GetConfig<T> =>
  (params = {}) => {
    schema = typeof schema === "function" ? schema() : schema;
    return _.mapValues(schema, (fieldSchema, fieldName) => getField(fieldName, fieldSchema, params));
  };

const getField = (fieldName: string, schema: ConfigFieldSchema<any>, params: Partial<Config>) => {
  // 1. param
  if (params[fieldName] !== undefined) return params[fieldName];

  // 2. env var
  const envVarName = _.snakeCase(fieldName).toUpperCase();
  const envVarValue = process.env[envVarName];
  if (envVarValue !== undefined) {
    const result = schema.serializer.deserialize(envVarValue);
    if (result.error) throw new Error(`Invalid config value: ${fieldName}, error: ${JSON.stringify(result.error)}`);
    return result.value;
  }

  // 3. environment-specific default
  const nodeEnvStr = "NODE_ENV";
  const environment =
    process.env[nodeEnvStr] ?? // actual env var at runtime
    process.env["NODE_ENV"]; // inlined value during build (for broswer)

  if (environment === "production" && schema.prod !== undefined) return schema.prod;
  if (environment === "test" && schema.test !== undefined) return schema.test;
  if (environment === "development" && schema.dev !== undefined) return schema.dev;
  if (environment !== "production" && schema.nonProd !== undefined) return schema.nonProd;

  // 4. general default
  if (schema.default !== undefined) return schema.default;

  // 5. undefined env var
  const result = schema.serializer.deserialize(undefined); // Throws if undefined is not allowed
  if (result.error) throw new Error(`Missing config value: ${fieldName}`);
  return result.value;
};

type Defaults<T> =
  | Omit<ConfigFieldSchema<T>, "serializer">
  | (T extends string ? T : never) // Allows primitives to be passed directly as the default
  | (T extends number ? T : never)
  | (T extends boolean ? T : never);

export function configField(defaults: Defaults<string>, serializer?: Serializer<string>): ConfigFieldSchema<string>;
export function configField<T>(defaults: Defaults<T>, serializer: Serializer<T>): ConfigFieldSchema<T>;
export function configField(defaults: Defaults<any>, serializer?: Serializer<any>): ConfigFieldSchema<any> {
  const isDefaultsAPrimitive =
    typeof defaults === "string" || typeof defaults === "number" || typeof defaults === "boolean";

  if (isDefaultsAPrimitive) defaults = { default: defaults };

  return {
    serializer: serializer ?? Serializers.string,
    ...defaults,
  };
}
