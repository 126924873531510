import { TableRow, TableRowProps } from "./TableRow";

type Props = {
  className?: string;
  isLoading?: boolean;
  visibleColumnIndexes?: number[];
  rows: Omit<TableRowProps, "visibleColumnIndexes">[];
};

export const TableBody: React.FC<Props> = ({ isLoading, className, rows, visibleColumnIndexes }) => {
  return (
    <tbody className={className} css={{ position: "relative", opacity: isLoading ? 0.4 : 1 }}>
      {rows.map(({ uuid, ...rowProps }, index) => (
        <TableRow key={`table-row-${uuid || index}`} {...rowProps} visibleColumnIndexes={visibleColumnIndexes} />
      ))}
    </tbody>
  );
};
