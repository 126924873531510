import { PriceListSimple } from "@megarax/crm-contracts";
import { NewItemFab, Pagination, SearchBar, Table } from "@megarax/ui-components";
import { Box, Container } from "@mui/material";
import React from "react";

interface Props {
  priceListQuery: {
    count: number;
    items: PriceListSimple[];
  };
  pagination: {
    onChange: (e: React.ChangeEvent<unknown>, page: number) => void;
    count: number;
    page: number;
  };
  search: {
    setSearchText: (search: string) => void;
    initialValue: string;
  };
  openNewItemDialog: () => void;
  navigateToDetails: (uuid: string) => void;
}

export const PriceListsHome: React.FC<Props> = ({
  priceListQuery,
  pagination,
  search,
  openNewItemDialog,
  navigateToDetails,
}) => {
  return (
    <Container maxWidth="md" disableGutters>
      <Box display="flex" p={2} sx={{ gap: "8px" }} justifyContent="space-between" alignItems="center">
        <SearchBar onSearchChange={search.setSearchText} initialValue={search.initialValue} />
        <NewItemFab onClick={openNewItemDialog} text={"Nowy"} />
      </Box>
      <Table
        items={priceListQuery.items}
        columns={[{ title: "Nazwa", getValue: (item) => item.name }]}
        onSelected={navigateToDetails}
        getKey={(item) => item.uuid}
      />
      <Pagination {...pagination} allCount={priceListQuery.count} />
    </Container>
  );
};
