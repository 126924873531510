import { SelectDialog } from "@megaron/dash-select";

import { tableHeadingsList } from "./AllThreadsHome";

type Props = {
  visibleTableColumns: string[];
  onVisibleTableColumnsChange: (columns: string[]) => void;
};

export const ColumnsFilter: React.FC<Props> = ({ visibleTableColumns, onVisibleTableColumnsChange }) => {
  return (
    <SelectDialog
      label={"Kolumny"}
      initiallySelectedValues={visibleTableColumns}
      options={tableHeadingsList.map(({ label }) => ({ label: label, value: label }))}
      onSelectedChange={(options) => onVisibleTableColumnsChange(options.map((option) => option.value))}
      variant="multi-select"
    />
  );
};
