import { LinearProgress } from "@mui/material";
import Decimal from "decimal.js";
import React, { useContext, useEffect } from "react";

import { Currency, Gtin } from "@megarax/crm-contracts";
import { priceListResource } from "@megarax/crm-contracts";
import { useProfilesDictionary } from "@megarax/iam-webapp";
import { CurrentUserContext, useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

import { TradeItemDictionary } from "../PriceListDetailsContainer";
import { ProposalsView } from "./ProposalsView";

interface ContainerProps {
  uuid: string;
  currency: Currency;
  tradeItems: TradeItemDictionary | null;
}

export const ProposalsContainer: React.FC<ContainerProps> = ({ uuid, currency, tradeItems }) => {
  const priceListProvider = useResourceProviderV2(priceListResource);
  const proposalsProvider = priceListProvider.byUuid(uuid).proposals;
  const currentUser = useContext(CurrentUserContext);
  const isMyProposal = (userId: number) => currentUser?.id === userId;

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    PriceListNotFound: "Cennik nie istnieje",
  });

  const permissions = {
    create: true,
    approve: true,
    reject: true,
  };

  const { value: proposalsQuery, reload } = useAsyncLoad(() => proposalsProvider.all(), []);

  const profiles = useProfilesDictionary(proposalsQuery?.value?.map((proposal) => proposal.proposedBy));

  const proposalProvider = priceListProvider.byUuid(uuid);

  const approveProposal = (proposalUuid: string) =>
    proposalProvider.proposals.byUuid(proposalUuid).approve().then(reload);
  const rejectProposal = (proposalUuid: string) =>
    proposalProvider.proposals.byUuid(proposalUuid).reject().then(reload);
  const retractProposal = (proposalUuid: string) =>
    proposalProvider.proposals.byUuid(proposalUuid).retract().then(reload);
  const proposeChanges = (input: {
    changes: { gtin: Gtin; netPrice: Decimal | null }[];
    activeFrom: Date | null;
    comment: string;
  }) => proposalProvider.proposals.create(undefined, input).then(reload);

  useEffect(() => {
    if (proposalsQuery?.isFailure) handleError(proposalsQuery.error);
  }, [proposalsQuery]);

  if (proposalsQuery === undefined || !profiles) return <LinearProgress />;
  if (proposalsQuery.isFailure) return null; // TODO

  return (
    <ProposalsView
      permissions={permissions}
      isMyProposal={isMyProposal}
      data={{
        priceListUuid: uuid,
        currency,
        tradeItems,
        proposals: proposalsQuery.value,
        profiles,
      }}
      proposalActions={{
        reloadProposals: reload,
        approveProposal,
        rejectProposal,
        retractProposal,
        proposeChanges,
      }}
    />
  );
};
