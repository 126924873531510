import { Uuid } from "@megarax/common";
import React, { useEffect } from "react";
import { generatePath, matchPath, useLocation, useNavigate } from "react-router-dom-v5-compat";

export const useVisitNavigation = () => {
  const navigate = useNavigate();

  const [initialPathname, setInitialPathname] = React.useState<string | null>(null);

  const { pathname } = useLocation();
  const safeToGoBack = React.useRef(false);

  useEffect(() => {
    if (initialPathname === null) {
      setInitialPathname(pathname);
    }
  }, [initialPathname, pathname]);

  const openVisit = (uuid: Uuid) => {
    const path = generatePath(`${initialPathname}/wizyta/:uuid`, { uuid });
    navigate(path);
    safeToGoBack.current = true;
  };

  const closeVisit = () => {
    if (safeToGoBack.current) {
      navigate(-1);
      safeToGoBack.current = false;
    } else {
      navigate(initialPathname || pathname, { replace: true });
    }
  };

  return {
    openVisit,
    closeVisit,
  };
};

export const useOnVisitClose = (onClose: () => void) => {
  const [initialPathname, setInitialPathname] = React.useState<string | null>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    if (initialPathname === null) {
      setInitialPathname(pathname);
    }
  }, [initialPathname, pathname]);

  const isOpen =
    matchPath(
      {
        path: `${initialPathname}/wizyta/:uuid`,
      },
      pathname,
    ) !== null;
  const wasOpen = React.useRef(isOpen);

  React.useEffect(() => {
    if (!isOpen && wasOpen.current) onClose();

    wasOpen.current = isOpen;
  }, [pathname]);
};
