import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";

export const emailActions = {
  verifyEmail: HttpAction({
    path: "/verifyEmail",
    method: "post",
    bodySerializer: Serializers.object({ token: Serializers.string }),
    errorSerializer: Serializers.stringOneOf("InvalidToken", "AccountDeleted", "EmailAlreadyInUse"),
  }),
  resendVerificationMessage: HttpAction({
    path: "/resendVerificationMessage",
    method: "post",
    requiresAuth: true as const,
    errorSerializer: Serializers.stringOneOf(
      "NoPendingVerification",
      "AccountNotFound",
      "InvalidEmail",
      "FailedToSendEmail",
    ),
  }),
  skipEmailVerification: HttpAction({
    path: "/skipEmailVerification",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({ accountUuid: Serializers.string }),
    errorSerializer: Serializers.stringOneOf("NoPendingVerification", "AccountNotFound", "AccountDeleted"),
  }),
};
