import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const interestLevels = ["notInterested", "interested"];
export type InterestLevel = (typeof interestLevels)[number];

const interestSerializer = Serializers.object({
  customerId: uuidSerializer,
  productId: Serializers.string,
  interestLevel: Serializers.stringOneOf(...interestLevels).nullable(),
});

export const customerProductSerializer = Serializers.object({
  product: Serializers.string,
  interestLevel: Serializers.stringOneOf(...interestLevels).nullable(),
  lastPurchaseTime: Serializers.datetime.nullable(),
  massKg: Serializers.decimal,
});

export type CustomerProduct = SerializerValue<typeof customerProductSerializer>;

const purchasesDetailSerializer = Serializers.object({
  funnels: Serializers.array(customerProductSerializer),
  lastInteractionTime: Serializers.datetime.nullable(),
  lastPurchaseTime: Serializers.datetime.nullable(),
  totalMassKg: Serializers.decimal.nullable(),
  lastThreeMonthsTotalMassKg: Serializers.decimal.nullable(),
});

const addPurchaseBodySerializer = Serializers.object({
  customerId: uuidSerializer,
  productId: Serializers.string,
  source: Serializers.string,
  massKg: Serializers.decimal,
  transactionUuid: uuidSerializer.nullable(),
});

const purchaseSerializer = Serializers.object({
  uuid: uuidSerializer,
  source: Serializers.string,
  customerId: uuidSerializer,
  productId: Serializers.string,
  massKg: Serializers.decimal,
  transactionUuid: uuidSerializer.nullable(),
  createdAt: Serializers.datetime,
});

export const purchases = {
  getCustomerPurchaseFunnels: HttpAction({
    path: "/purchaseFunnel/:customerId",
    method: "get",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      customerId: uuidSerializer,
    }),
    valueSerializer: purchasesDetailSerializer,
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  savePurchaseFunnel: HttpAction({
    path: "/purchaseFunnel",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: interestSerializer,
    valueSerializer: interestSerializer,
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  addPurchase: HttpAction({
    path: "/purchase",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: addPurchaseBodySerializer,
    errorSerializer: Serializers.stringOneOf("CustomerNotFound"),
    valueSerializer: purchaseSerializer,
  }),

  getPurchase: HttpAction({
    path: "/purchase/:transactionUuid",
    method: "get",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      transactionUuid: uuidSerializer,
    }),
    valueSerializer: purchaseSerializer,
    errorSerializer: Serializers.stringOneOf("PurchaseNotFound"),
  }),
};
