import { Circle, MoreVert } from "@mui/icons-material";
import { Avatar, Box, IconButton, Popover, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formatRelative } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";

interface Props {
  startTime: Date | null | undefined;
  scheduledStartTime: Date | null | undefined;
  customerName: string | undefined;
  profilePictureUrl: string | undefined;
  isCompleted: boolean | undefined;
  isCancelled: boolean | undefined;
  actionsMenu: React.ReactNode;
}

export const VisitHeader: React.FunctionComponent<Props> = ({
  startTime,
  scheduledStartTime,
  customerName,
  profilePictureUrl,
  isCompleted,
  isCancelled,
  actionsMenu,
}) => {
  const classes = useStyles();

  const [popoverAnchor, setPopoverAnchor] = React.useState<Element | null>(null);
  const isStarted = !!startTime;

  const visitStatusText =
    isCancelled === undefined && isCompleted === undefined
      ? undefined
      : isCancelled
      ? "Anulowana wizyta"
      : isCompleted
      ? "Zakończona wizyta"
      : isStarted
      ? "Rozpoczęta wizyta"
      : "Planowana wizyta";

  const visitTime = startTime !== null ? startTime : scheduledStartTime;
  const visitTimeText = visitTime && formatRelative(visitTime, new Date(), { locale: pl });

  return (
    <Box display="flex" flexDirection="row" overflow="hidden">
      <Box mr={1.5}>
        <Avatar src={profilePictureUrl} />
      </Box>
      <Box minWidth={0}>
        <Typography
          paragraph
          variant="overline"
          lineHeight={1.5}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          mb="2px"
        >
          {visitStatusText}
          {visitTimeText && " ⸱ " + visitTimeText}
        </Typography>
        <Typography
          variant="body1"
          lineHeight={1}
          fontWeight="500"
          fontSize="1.1rem"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {customerName}
        </Typography>
      </Box>
      <Box ml="auto">
        {actionsMenu && (
          <>
            <IconButton size="large" onClick={(e) => setPopoverAnchor(e.currentTarget)} className={classes.moreButton}>
              <MoreVert fontSize="inherit" />
            </IconButton>
            <Popover
              open={popoverAnchor !== null}
              anchorEl={popoverAnchor}
              onClose={() => setPopoverAnchor(null)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
            >
              {actionsMenu}
            </Popover>
          </>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  moreButton: {
    margin: theme.spacing(-2, -3, -1, 0),
  },
}));
