import { Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export interface CreateVisitChecklistInput {
  visitUuid: Uuid;
  customerUuid: Uuid;
}

export interface ToggleTaskInput {
  taskUuid: Uuid;
}

export const toggleTaskInputSerializer = MSerializers.object<ToggleTaskInput>({
  taskUuid: uuidSerializer,
});
