import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

import { Uuid } from "@megarax/common";
import { ContactListDto, RemoveContactInput } from "@megarax/crm-contracts";
import { Ok } from "@megaron/result";
import { AreUSureWrapper, Failure } from "@megarax/ui-components";

interface Props {
  contactList: ContactListDto;
  removeContact: (input: RemoveContactInput) => Promise<Ok<void> | Failure<void>>;

  navigation: {
    openCreateNewDialog: () => void;
    openEditDialog: (uuid: Uuid) => void;
  };
}
export const ContactList: React.FC<Props> = ({ contactList, navigation, removeContact }) => {
  const isMobile = useMediaQuery("(max-width:500px)");

  const getSecondaryActions = (contact: { email: string; phone: string; uuid: Uuid }) => (
    <Box
      display="flex"
      pl={0}
      pr={1.5}
      justifyContent={isMobile ? "flex-start" : "flex-end"}
      sx={{ gap: 1, backgroundColor: isMobile ? "inherit" : "white", borderRadius: "8px" }}
      onClick={(e) => e.stopPropagation()}
    >
      {contact.phone && (
        <a href={`tel:${contact.phone}`}>
          <IconButton>
            <PhoneInTalkIcon />
          </IconButton>
        </a>
      )}
      {contact.email && (
        <a href={`mailto:${contact.email}`}>
          <IconButton>
            <EmailIcon />
          </IconButton>
        </a>
      )}
      <AreUSureWrapper action={() => removeContact({ uuid: contact.uuid })} title="Na pewno usunąć?">
        <IconButton edge="end" aria-label="delete">
          <DeleteOutlineIcon />
        </IconButton>
      </AreUSureWrapper>
    </Box>
  );

  return (
    <Box>
      <Box display="flex" alignItems="center" sx={{ gap: 2 }} px={2} py={1}>
        <ContactPhoneIcon />
        <Typography variant="h5">Kontakty</Typography>
      </Box>
      <Box width="100%">
        <List dense disablePadding>
          <ListItem
            button
            key={"new"}
            sx={{ cursor: "pointer", height: "60px" }}
            onClick={navigation.openCreateNewDialog}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "transparent", color: "black" }}>
                <PersonAddAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Dodaj nowy kontakt"} sx={{ fontWeight: "600" }} />
          </ListItem>
          {contactList.contacts.map((contact) => {
            return (
              <ListItem
                button
                key={contact.uuid}
                sx={{ cursor: "pointer" }}
                onClick={() => navigation.openEditDialog(contact.uuid)}
                secondaryAction={!isMobile && getSecondaryActions(contact)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box display="flex" sx={{ gap: 1 }} flexWrap="wrap">
                      <span>{contact.name}</span>
                      <span style={{ color: "grey" }}>{contact.position ? `(${contact.position})` : ""}</span>
                    </Box>
                  }
                  secondary={
                    <>
                      <Box
                        display="flex"
                        sx={{ gap: isMobile ? 0 : 1, overflowX: "hidden" }}
                        flexDirection={isMobile ? "column" : "row"}
                      >
                        {contact.email && <span>{`${contact.email}`}</span>}
                        {contact.email && !isMobile && contact.phone && <span>|</span>}
                        {contact.phone && <span>{`${contact.phone}`}</span>}
                      </Box>
                      {isMobile && getSecondaryActions(contact)}
                    </>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};
