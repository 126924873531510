import { Month } from "@megarax/crm-contracts";
import { Table, TableColumn } from "@megarax/ui-components";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import React from "react";

import { TargetTableRow } from "./TargetsView";

interface Props {
  rows: TargetTableRow[];
}

export const TargetTableDataGrid: React.FunctionComponent<Props> = ({ rows }) => {
  const classes = useStyles();

  const months = (_.keys(rows[0]?.targets) as Month[]).sort();

  const columns: TableColumn<TargetTableRow>[] = [
    {
      title: "UUID",
      getValue: (r) => <span className={classes.uuid}>{r.regionUuid}</span>,
    },
    {
      title: "Nazwa regionu",
      getValue: (r) => r.regionName ?? "",
    },
    ...months.map<TableColumn<TargetTableRow>>((month) => ({
      title: month,
      getValue: (r) => r.targets[month]?.toFixed(2) ?? "-",
    })),
  ];

  return (
    <div>
      <Table stickyHeader items={rows} getKey={(r) => r.regionUuid} columns={columns}></Table>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  uuid: {
    fontFamily: "Monospace",
  },
}));
