import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export const oneOf = <T>(...options: T[]): Serializer<T> & SerializerExtensions<T> => ({
  serialize: (value) => value,
  deserialize: (raw) => {
    if (options.includes(raw as T)) return Ok(raw as T);
    const isTruncated = options.length > 5;
    const truncatedOptions = options.slice(0, 5);

    return Failure(
      `Value must be one of the following: ${truncatedOptions.join(", ")}${
        isTruncated ? ", ..." : ""
      } (${raw} is not).`,
    );
  },
  ...serializerExtensions(),
});

export const stringOneOf = <T extends string>(...options: T[]): Serializer<T> & SerializerExtensions<T> => ({
  serialize: (value) => {
    if (!options.includes(value)) throw new Error(`Value ${value} not allowed`);

    return value;
  },
  deserialize: (raw) => {
    if (options.includes(raw as T)) return Ok(raw as T);
    const isTruncated = options.length > 5;
    const truncatedOptions = options.slice(0, 5);

    return Failure(
      `Value must be one of the following: ${truncatedOptions.join(", ")}${
        isTruncated ? ", ..." : ""
      } (${raw} is not).`,
    );
  },
  ...serializerExtensions(),
});
