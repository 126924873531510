import { useTheme } from "@emotion/react";
import { useState } from "react";
import { MdOutlineContactPage } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { Macroregion } from "@megaron/crm-contracts";
import { Button, TextField } from "@megaron/dash-form";
import { SelectDialog } from "@megaron/dash-select";
import { useToast } from "@megaron/dash-toast";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  macroregion?: Macroregion;
  onSuccess: () => void;
};

export const SaveMacroregion: React.FC<Props> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const toast = useToast();
  const { allProfiles } = useProfiles();

  const saveMacroegion = useClientManager("crm").saveMacroregion().useMutation();
  const regions = useClientManager("crm").regionsQuery().useQuery({}).data?.items;

  const [name, setName] = useState<string>(props.macroregion?.name ?? "");
  const [emailsList, setEmailsList] = useState<string[]>(props.macroregion?.emails ?? []);
  const [selectedRegions, setSelectedRegions] = useState<string[]>(props.macroregion?.regions ?? []);

  const addCustomerMutation = async () => {
    saveMacroegion.mutate(
      {
        name,
        emails: emailsList.map((email) => email.toString()),
        regions: selectedRegions.map((region) => region.toString()),
      },
      {
        onSuccess: () => {
          props.onSuccess();
        },
        onError: (e) => {
          switch (e) {
            case "NoRegionProvided":
              toast.error("Error", "Nie podano żadnego regionu");
              break;
            case "NoEmailProvided":
              toast.error("Error", "Nie podano żadnej uprawnionej osoby");
              break;
            default:
              toast.error("Error", "Coś poszło nie tak");
          }
        },
      },
    );
  };

  const regionOptions = regions
    ? regions.map((region) => ({
        label: `${region.name}`,
        value: region.name,
      }))
    : [];

  const emailsOptions = allProfiles?.map((profile) => ({
    label: profile.firstName ? `${profile.firstName} ${profile.lastName}` : `${profile.email}`,
    value: profile.email,
  }));

  const regionsQueryParam = props.macroregion?.regions.map((region) => encodeURIComponent(region)).join("%22%2C%22");

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <TextField
        css={{ input: { height: "46px" } }}
        label={"Makroregion"}
        value={name}
        onChange={(value) => setName(value)}
        autoFocus
      />

      <SelectDialog
        label="Regiony"
        initiallySelectedValues={selectedRegions}
        options={regionOptions}
        onSelectedChange={(options) => setSelectedRegions(options.map((option) => option.value))}
        variant="multi-select"
      />
      <SelectDialog
        label="Osoby uprawnione"
        initiallySelectedValues={emailsList}
        options={emailsOptions ?? []}
        onSelectedChange={(options) => setEmailsList(options.map((option) => option.value))}
        variant="multi-select"
      />

      {props.macroregion && (
        <div
          css={{
            display: "flex",
            margin: "20px 0",
            height: "46px",
            alignItems: "center",
            borderRadius: theme.smallBorderRadius,
            overflow: "hidden",
            backgroundColor: "white",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate(
              `/crm/customers?filters=%7B%22page%22%3A0%2C%22searchText%22%3A%22%22%2C%22regions%22%3A%5B%22${regionsQueryParam}%22%5D%7D`,
            )
          }
        >
          <div
            css={{
              display: "flex",
              gap: "6px",
              background: theme.colors.primary,
              color: "white",
              alignItems: "center",
              height: "100%",
              padding: "6px 12px",
            }}
          >
            <MdOutlineContactPage />
            Klienci
          </div>
          <span css={{ color: theme.colors.primary, paddingLeft: "15px" }}>{props.macroregion?.customerCount}</span>
        </div>
      )}

      <Button css={{ alignSelf: "flex-end", margin: "0.25rem 0" }} onClick={addCustomerMutation}>
        Zapisz
      </Button>
    </div>
  );
};
