import { useCallback, useEffect, useRef, useState } from "react";

export const useDraggableDrawer = (props: { baseHeight: number; navElement: React.RefObject<HTMLDivElement> }) => {
  const [isDragging, setIsDragging] = useState(false);
  const isDraggingRef = useRef(isDragging);
  const [touchElementOffset, setTouchElementOffset] = useState(0);
  const touchStartY = useRef(0);
  const touchPositionY = useRef(0);
  const [drawerOffset, setDrawerOffset] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const isExpandedRef = useRef(isExpanded);

  const onTouchStart = useCallback(
    (event: React.TouchEvent) => {
      const touch = event.touches[0];

      if (!touch) return;
      if (!props.navElement.current) return;

      touchStartY.current = touch.clientY;
      touchPositionY.current = touch.clientY;
      setTouchElementOffset(touch.clientY - props.navElement.current.getBoundingClientRect().top);
      setDrawerOffset(window.innerHeight - touch.clientY);
      setIsDragging(true);
    },
    [props.navElement],
  );

  const onTouchMove = useCallback((event: TouchEvent) => {
    if (!isDraggingRef.current) return;
    const touch = event.touches[0];

    if (!touch) return;

    touchPositionY.current = touch.clientY;
    setDrawerOffset(window.innerHeight - touch.clientY);
    event.preventDefault();
  }, []);

  const onTouchEnd = useCallback(() => {
    if (!isDraggingRef.current) return;

    const distance = touchPositionY.current - touchStartY.current;

    setIsDragging(false);
    if (isExpandedRef.current && distance > 50) {
      setIsExpanded(false);
      setDrawerOffset(0);
    }
    if (!isExpandedRef.current && distance < -50) {
      setIsExpanded(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("touchmove", onTouchMove, { passive: false });
    window.addEventListener("touchend", onTouchEnd);

    return () => {
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchend", onTouchEnd);
    };
  }, [onTouchMove, onTouchEnd]);

  useEffect(() => {
    isDraggingRef.current = isDragging;
  }, [isDragging]);

  useEffect(() => {
    isExpandedRef.current = isExpanded;
  }, [isExpanded]);

  const drawerHeight = isDragging
    ? `${drawerOffset + touchElementOffset}px`
    : isExpanded
    ? `${props.baseHeight}px`
    : "0";

  return {
    setIsExpanded,
    onTouchStart,
    isDragging,
    isExpanded,
    drawerHeight,
  };
};
