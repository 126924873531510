import { Box, Button, Dialog, Divider, Typography } from "@mui/material";
import React from "react";
import { useMutation } from "react-query";
import { v4 } from "uuid";

import { Uuid } from "@megarax/common";
import { SaveContactInput } from "@megarax/crm-contracts";
import { Ok } from "@megaron/result";
import { Failure, TextInput } from "@megarax/ui-components";

interface Props {
  contact: Contact | null;
  onClose: () => void;
  saveContact: (input: SaveContactInput) => Promise<Ok<void> | Failure<void>>;
}

export interface Contact {
  uuid: Uuid;
  name: string;
  email: string;
  phone: string;
  position: string;
}

const emptyContact = () => ({ uuid: v4() as Uuid, name: "", email: "", phone: "", position: "" });

export const ContactCreatorDialog: React.FC<Props> = ({ contact, onClose, saveContact }) => {
  const [newContact, setNewContact] = React.useState<Contact>(contact ?? emptyContact());

  const editNewContact = (key: keyof Contact) => (value: Contact[typeof key]) =>
    setNewContact({ ...newContact, [key]: value });

  const onSave = useMutation((contact: Contact) => saveContact({ contact }), {
    onSuccess: onClose,
  });

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="xs">
      <Box px={3} pt={2}>
        <Typography variant="subtitle2">{contact ? "Edycja Kontaktu" : "Nowy Kontakt"}</Typography>
      </Box>
      <Box px={3} py={2} display="flex" flexDirection="column" sx={{ gap: 2 }}>
        <TextInput value={newContact.name} onChange={editNewContact("name")} label="Imie i Nazwisko" />
        <TextInput value={newContact.position} onChange={editNewContact("position")} label="Stanowisko" />

        <TextInput value={newContact.email} onChange={editNewContact("email")} label="Email" />
        <TextInput value={newContact.phone} onChange={editNewContact("phone")} label="Telefon" />
      </Box>
      <Box px={3} py={1} display="flex" justifyContent="flex-end">
        <Button onClick={onClose}>Anuluj</Button>
        <Button onClick={() => onSave.mutate(newContact)} color="primary" disabled={onSave.isLoading}>
          Zapisz
        </Button>
      </Box>
    </Dialog>
  );
};
