import { Uuid, UuidResourceParam, uuidSerializer } from "@megarax/common";
import { BaseResource, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { removeContactInputSerializer, saveContactInputSerializer } from "./inputs";

export interface ContactListDto {
  customerUuid: Uuid;
  contacts: {
    uuid: Uuid;
    name: string;
    email: string;
    phone: string;
    position: string;
  }[];
}

export const contactListDetailSerializer = MSerializers.object<ContactListDto>({
  customerUuid: uuidSerializer,
  contacts: MSerializers.array(
    MSerializers.object<{
      uuid: Uuid;
      name: string;
      email: string;
      phone: string;
      position: string;
    }>({
      uuid: uuidSerializer,
      name: MSerializers.string,
      email: MSerializers.string,
      phone: MSerializers.string,
      position: MSerializers.string,
    }),
  ),
});

export type ContactDumpEntry = {
  uuid: Uuid;
  name: string;
  email: string;
  phone: string;
  position: string;
  customerUuid: Uuid;
  customerName: string;
  regionUuid: Uuid;
  regionName: string;
  macroregionUuid: Uuid;
  macroregionName: string;
};

const contactDumpSerializer = MSerializers.array(
  MSerializers.object<ContactDumpEntry>({
    uuid: uuidSerializer,
    name: MSerializers.string,
    email: MSerializers.string,
    phone: MSerializers.string,
    position: MSerializers.string,
    customerUuid: uuidSerializer,
    customerName: MSerializers.string,
    regionUuid: uuidSerializer,
    regionName: MSerializers.string,
    macroregionUuid: uuidSerializer,
    macroregionName: MSerializers.string,
  }),
);

export const contactListResource = BaseResource(["crm", "contactList"], {
  byCustomerUuid: UuidResourceParam("uuid", "uuid", {
    saveContact: ResourceActionV2({
      name: "saveContact",
      method: "post",
      requestBodySerializer: saveContactInputSerializer,
    }),
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      path: "",
      responseValueSerializer: contactListDetailSerializer,
    }),
    removeContact: ResourceActionV2({
      name: "removeContact",
      method: "delete",
      requestBodySerializer: removeContactInputSerializer,
    }),
  }),
  allContacts: ResourceActionV2({
    name: "allContacts",
    method: "get",
    path: "",
    responseValueSerializer: contactDumpSerializer,
  }),
});
