import { DateString, dateStringSerializer, Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export type BreakDto = {
  uuid: Uuid;
  startTime: Date;
  endTime: Date | null;
  startOdometer: number | null;
  endOdometer: number | null;
};

export type DayVisitDto = {
  uuid: Uuid;

  startTime: Date;
  endTime: Date | null;
};

export type EventDto = {
  uuid: Uuid;
  title: string;
  description: string;

  startTime: Date;
  endTime: Date;
};

export type WorkDayDto = {
  date: DateString;
  startTime: Date | null;
  endTime: Date | null;
  startOdometer: number | null;
  endOdometer: number | null;

  breaks: BreakDto[];
  visits: DayVisitDto[];
  events: EventDto[];

  workTimeMinutes: number;
  travelKm: number;
};

export const breakDtoSerializer = MSerializers.object<BreakDto>({
  uuid: uuidSerializer,
  endOdometer: MSerializers.integer.nullable(),
  endTime: MSerializers.datetime.nullable(),
  startOdometer: MSerializers.integer.nullable(),
  startTime: MSerializers.datetime,
});

export const dayVisitDtoSerializer = MSerializers.object<DayVisitDto>({
  uuid: uuidSerializer,
  endTime: MSerializers.datetime.nullable(),
  startTime: MSerializers.datetime,
});

export const eventDtoSerializer = MSerializers.object<EventDto>({
  uuid: uuidSerializer,
  title: MSerializers.string,
  endTime: MSerializers.datetime,
  startTime: MSerializers.datetime,
  description: MSerializers.string,
});

export const workDayDtoSerializer = MSerializers.object<WorkDayDto>({
  date: dateStringSerializer,
  endOdometer: MSerializers.integer.nullable(),
  endTime: MSerializers.datetime.nullable(),
  startOdometer: MSerializers.integer.nullable(),
  startTime: MSerializers.datetime.nullable(),
  breaks: MSerializers.array(breakDtoSerializer),
  workTimeMinutes: MSerializers.integer,
  travelKm: MSerializers.integer,
  visits: MSerializers.array(dayVisitDtoSerializer),
  events: MSerializers.array(eventDtoSerializer),
});
