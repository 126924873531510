import { useTheme } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { MdSend } from "react-icons/md";
import { useQueryClient } from "react-query";

import { Dialog } from "@megaron/dash-dialog";
import { Button, SwitchField, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { DocsSelectDialog } from "@megaron/dash-select";
import { Spinner } from "@megaron/dash-spinner";
import { useToast } from "@megaron/dash-toast";
import { BcVendorDoc } from "@megaron/docs-contracts";
import { ThreadDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  thread: ThreadDto;
  queryKey: string | string[];
  onClose: () => void;
  onSuccess: () => void;
};

export const AddToBcDialog: React.FC<Props> = ({ thread, queryKey, onClose, onSuccess }) => {
  const toast = useToast();

  const queryClient = useQueryClient();

  const wasMatchedBcVendorSet = useRef(false);

  const { isDesktop } = useDeviceType();

  const matchedBcVendor = useClientManager("docs")
    .matchBcVendor()
    .useQuery({ name: thread.invoice.issuer, taxId: thread.invoice.issuerTaxId });

  const [payedByEmployee, setPayedByEmployee] = useState(false);
  const [bcVendor, setBcVendor] = useState<BcVendorDoc | null>(matchedBcVendor.data || null);
  const [bcVendorNumber, setBcVendorNumber] = useState<string | null>(null);
  const [employee, setEmployee] = useState<BcVendorDoc | null>(null);
  const [employeeVendorNumber, setEmployeeVendorNumber] = useState<string | null>(null);

  const vendorNumber = payedByEmployee
    ? employee?.docId
      ? [employee?.docId]
      : undefined
    : bcVendor?.docId
    ? [bcVendor.docId]
    : undefined;
  const vatContractorNumber = payedByEmployee ? (bcVendor?.docId ? [bcVendor.docId] : undefined) : undefined;

  useEffect(() => {
    if (wasMatchedBcVendorSet.current) {
      return;
    }

    if (matchedBcVendor.data && !bcVendor) {
      wasMatchedBcVendorSet.current = true;
      setBcVendor(matchedBcVendor.data);
      setBcVendorNumber(matchedBcVendor.data.vendorNumber);
    }
  }, [bcVendor, matchedBcVendor.data]);

  const addToBcMutation = useClientManager("invoices").addInvoiceToBc().useMutation();

  useEffect(() => {
    if (
      bcVendor &&
      bcVendorNumber !== (payedByEmployee ? bcVendor.contacts[0]?.contactNumber : bcVendor.vendorNumber)
    ) {
      setBcVendor(null);
    }
  }, [bcVendor, bcVendorNumber, payedByEmployee]);

  useEffect(() => {
    if (employee && employeeVendorNumber !== employee.vendorNumber) {
      setEmployee(null);
    }
  }, [employee, employeeVendorNumber]);

  const handleSendToBc = () => {
    if ((payedByEmployee && !employeeVendorNumber) || (!payedByEmployee && !bcVendorNumber)) {
      toast.error("Numer dostawcy jest wymagany");
      return;
    }

    addToBcMutation.mutate(
      {
        vendorNumber: payedByEmployee ? employeeVendorNumber ?? "" : bcVendorNumber ?? "",
        vatContractorContactNumber: payedByEmployee ? bcVendorNumber || null : null,
        threadUuid: thread.uuid,
      },
      {
        onSuccess: () => {
          onSuccess();
          queryClient.invalidateQueries(queryKey);
          onClose();
          toast.info("Faktura została dodana do BC");
        },
        onError: (error) => {
          if (error === "BcVendorNotFound") {
            toast.error("Nie znaleziono dostawcy w BC");
            return;
          }

          toast.error("Nie udało się dodać faktury do BC");
        },
      },
    );
  };

  return (
    <Dialog
      header="Wyślij fakturę do BC"
      css={{ width: "400px" }}
      placement={isDesktop ? "center" : "top"}
      onClose={onClose}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.75rem", width: "100%" }}>
        <SwitchField
          label="Zapłacone przez pracownika"
          trueLabel="Tak"
          falseLabel="Nie"
          selectedValue={payedByEmployee}
          onChange={(value) => {
            if (bcVendor) {
              setBcVendorNumber(value ? bcVendor.contacts[0]?.contactNumber : bcVendor.vendorNumber);
            }

            if (!value) {
              setEmployee(null);
              setEmployeeVendorNumber(null);
            }

            setPayedByEmployee(value);
          }}
          fullWidth
        />
        <DocsSelectDialog
          key={`bc-vendor-${bcVendor?.docId}`}
          label="Wystawca faktury"
          docType={["bc-vendor"]}
          variant="single-select"
          initiallySelectedDocIds={bcVendor?.docId ? [bcVendor.docId] : undefined}
          onSelect={(doc) => {
            setBcVendor(doc as BcVendorDoc | null);
            setBcVendorNumber(
              payedByEmployee
                ? (doc as BcVendorDoc)?.contacts[0]?.contactNumber ?? ""
                : (doc as BcVendorDoc)?.vendorNumber ?? "",
            );
          }}
        />
        <div css={{ opacity: payedByEmployee ? 1 : 0.5 }}>
          <DocsSelectDialog
            isDisabled={!payedByEmployee}
            key={vendorNumber?.[0]}
            label="Pracownik"
            docType={["bc-vendor"]}
            variant="single-select"
            initiallySelectedDocIds={employee?.docId ? [employee.docId] : undefined}
            onSelect={(doc) => {
              setEmployee(doc as BcVendorDoc | null);
              setEmployeeVendorNumber((doc as BcVendorDoc)?.vendorNumber || "");
            }}
          />
        </div>
        <SelectDialogWithTextField
          keyString={`bc-vendor-number-${bcVendor?.docId}-${employee?.docId}`}
          label="Numer dostawcy"
          initiallySelectedDocIds={vendorNumber}
          onSelect={(doc) => {
            if (payedByEmployee) {
              setEmployeeVendorNumber((doc as BcVendorDoc)?.vendorNumber || "");
              setEmployee(doc as BcVendorDoc | null);
              return;
            }

            setBcVendor(doc as BcVendorDoc | null);
            setBcVendorNumber((doc as BcVendorDoc)?.vendorNumber || "");
          }}
          textFieldValue={payedByEmployee ? employeeVendorNumber ?? "" : bcVendorNumber ?? ""}
          onTextChange={(value) => {
            if (payedByEmployee) {
              setEmployeeVendorNumber(value);
              return;
            }

            setBcVendorNumber(value);
          }}
        />
        <SelectDialogWithTextField
          keyString={`vat-contractor-${vatContractorNumber?.[0]}`}
          label="Kontrahent VAT"
          initiallySelectedDocIds={vatContractorNumber}
          onSelect={(doc) => {
            setBcVendor(doc as BcVendorDoc | null);
            setBcVendorNumber((doc as BcVendorDoc)?.contacts[0]?.contactNumber ?? "");
          }}
          textFieldValue={payedByEmployee ? bcVendorNumber ?? "" : ""}
          onTextChange={(value) => {
            if (payedByEmployee) {
              setBcVendorNumber(value);
            }
          }}
          isDisabled={!payedByEmployee}
        />

        <Button
          icon={addToBcMutation.isLoading ? <Spinner size="20px" color={"white"} /> : <MdSend size={20} />}
          onClick={handleSendToBc}
          css={{ alignSelf: "flex-end" }}
          isDisabled={addToBcMutation.isLoading}
        >
          Wyślij
        </Button>
      </div>
    </Dialog>
  );
};

type SelectDialogWithTextFieldProps = {
  keyString: string;
  label: string;
  initiallySelectedDocIds?: string[];
  onSelect: (doc: BcVendorDoc | null) => void;
  textFieldValue: string;
  onTextChange: (value: string) => void;
  isDisabled?: boolean;
};

const SelectDialogWithTextField: React.FC<SelectDialogWithTextFieldProps> = ({
  keyString,
  label,
  initiallySelectedDocIds,
  onSelect,
  textFieldValue,
  onTextChange,
  isDisabled,
}) => {
  const theme = useTheme();

  return (
    <div
      css={{ display: "flex", flexDirection: "column", gap: "0.25rem", width: "100%", opacity: isDisabled ? 0.5 : 1 }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
        }}
      >
        <span>{label}</span>
        <DocsSelectDialog
          key={keyString}
          isDisabled={isDisabled}
          docType={["bc-vendor"]}
          variant="single-select"
          initiallySelectedDocIds={initiallySelectedDocIds}
          onSelect={(doc) => onSelect(doc as BcVendorDoc | null)}
          renderTrigger={(_, __, ___, open) => (
            <button
              onClick={open}
              css={{
                border: "none",
                padding: "none",
                textDecoration: "underline",
                color: theme.colors.primary,
                background: "none",
                cursor: isDisabled ? "auto" : "pointer",
              }}
            >
              Wybierz
            </button>
          )}
        />
      </div>
      <TextField isDisabled={isDisabled} value={textFieldValue} onChange={onTextChange} />
    </div>
  );
};
