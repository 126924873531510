import { useTheme } from "@emotion/react";
import React from "react";

type Props = {
  label: React.ReactNode;
  children: React.ReactNode;
  color?: string;
  className?: string;
};

export const DescriptionField: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <div className={props.className}>
      <dt
        css={{
          fontSize: "0.875rem",
          color: props.color ?? theme.colors.primary,
          fontFamily: theme.displayFontFamily,
          opacity: "0.8",
        }}
      >
        {props.label}
      </dt>
      <dd css={{ margin: 0, wordBreak: "break-word", fontSize: "1rem" }}>
        {props.children ?? <span css={{ color: theme.colors.secondaryText }}>-</span>}
      </dd>
    </div>
  );
};
