import React from "react";

import { EntityList } from "@megaron/dash-entity-list";
import { Page, PageHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

type Props = {};

export const ListingsPage: React.FunctionComponent<Props> = (props) => {
  const query = useClientManager("loyaltyPoints").listingsQuery().useQuery({});

  return (
    <Page>
      <PageHeader>Katalog nagród</PageHeader>
      <QuerySkeleton query={query}>
        {(listings) => (
          <EntityList
            getKey={(listing) => listing.listingUuid}
            items={listings.sort((a, b) => a.price.cmp(b.price))}
            columns={(listing) => [
              {
                header: "Nagroda",
                content: listing.rewardIds.map((r) => <div>{r}</div>),
                isPrimary: true,
              },
              { header: "Cena w pkt", content: listing.price.toFixed(0) },
              { header: "Limit", content: listing.limit ?? "-" },
              { header: "Limit na użytkownika", content: listing.limitPerUser ?? "-" },
              { header: "Wymagany status", content: listing.requiresProStatus ? "PRO" : "-" },
            ]}
          />
        )}
      </QuerySkeleton>
    </Page>
  );
};
