import { CustomerDoc } from "@megaron/docs-contracts";

import { CustomerTile } from "./CustomerTile";

type Props = {
  customers: CustomerDoc[];
  queryKey: string | string[];
};

export const CustomerList: React.FC<Props> = (props) => {
  return (
    <div css={{ display: "grid", gap: "0.75rem", gridTemplateColumns: "repeat(auto-fill, minmax(310px, 1fr))" }}>
      {props.customers.map((customer) => (
        <CustomerTile key={customer.uuid} customer={customer} queryKey={props.queryKey} />
      ))}
    </div>
  );
};
