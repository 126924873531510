import { List, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { Uuid } from "@megarax/common";
import { MySubscription } from "@megarax/crm-contracts";

import { CustomerChatFeedEntry } from "./CustomerChatFeedEntry";
import { NoThreadsScreen } from "./NoThreadsScreen";

export type ProfileMap = {
  [userId: number]:
    | {
        userId: number;
        name: string;
        pictureUrl: string;
      }
    | undefined;
};

interface Props {
  threads: MySubscription[];
  profiles: ProfileMap;
  customerNames: {
    [customerUuid: Uuid]: string | undefined;
  };

  onCustomerClick: (uuid: Uuid) => void;
}

export const Feed: React.FunctionComponent<Props> = ({ threads, profiles, customerNames, onCustomerClick }) => {
  return threads.length > 0 ? (
    <List>
      {threads.map((thread) => {
        if (thread.threadIdentifier.type === "customer") {
          const customerUuid = thread.threadIdentifier.customerUuid;
          return (
            <CustomerChatFeedEntry
              customerName={customerNames[customerUuid]}
              onClick={() => onCustomerClick(customerUuid)}
              isUnread={thread.unreadMessageCount > 0}
              lastMessage={{
                ...thread.lastMessage,
                userProfile: profiles[thread.lastMessage.userId],
              }}
            />
          );
        }
        return null;
      })}
    </List>
  ) : (
    <NoThreadsScreen />
  );
};

const useStyles = makeStyles((theme) => ({}));
