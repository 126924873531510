import { BreadcrumbMarker, PageTitleManager } from "@megarax/ui-components";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import { MapContainer } from "../mapsCommon/MapContainer";
import { DashboardMapManager } from "./DashboardMapManager";

export const DashboardMap = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box width="100%" height={isMobile ? "calc(100vh - 28px)" : "100vh"} marginBottom={isMobile ? "-64px" : "0"}>
      <PageTitleManager title="Mapa" />
      <BreadcrumbMarker title="Mapa" id="Mapa" />
      <MapContainer mapProps={({ map, google }) => <DashboardMapManager map={map} google={google} />} />
    </Box>
  );
};
