import { Failure, Ok } from "@megaron/result";

import { serializerExtensions, SerializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export const integer: Serializer<number> & SerializerExtensions<number> = {
  serialize: (value) => {
    if (!Number.isInteger(value)) throw new Error(`Value is not an integer (${value})`);
    return value;
  },
  deserialize: (raw) => {
    const value = parseInt(raw as any);

    if (isNaN(value)) return Failure(`InvalidInteger`);
    return Ok(value);
  },
  ...serializerExtensions(),
};
