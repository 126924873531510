import { Uuid, uuidSerializer } from "@megarax/common";
import { Gtin, gtinSerializer } from "../common";
import { Serializers } from "@megarax/serializers";

export interface TradeItemDto {
  uuid: Uuid;
  gtin: Gtin;
  name: string;
}

export const tradeItemDtoSerializer = Serializers.object<TradeItemDto>({
  uuid: uuidSerializer,
  gtin: gtinSerializer,
  name: Serializers.string,
});
