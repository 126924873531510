import { Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useMutation, useQuery } from "react-query";

import { Uuid } from "@megarax/common";
import { visitChecklistResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { assertOk } from "@megaron/result";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

import { ChecklistSection } from "./ChecklistSection";

interface Props {
  visitUuid: Uuid;
  isMine: boolean;
}

export const ChecklistSectionContainer: React.FunctionComponent<Props> = ({ visitUuid, isMine }) => {
  const classes = useStyles();
  const provider = useResourceProviderV2(visitChecklistResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    ChecklistNotFound: "Nie znaleziono listy zadań",
  });

  const { data: checklist, refetch } = useQuery(
    ["visit-checklist", visitUuid],
    () => provider.byUuid(visitUuid).retrieve().mapError(handleError),
    { retry: 3 },
  );

  const toggleTask = (taskUuid: Uuid) =>
    provider
      .byUuid(visitUuid)
      .toggleTask(undefined, { taskUuid })
      .mapError(handleError)
      .then(async (r) => {
        r.assertOk();
        await refetch();
      });

  const toggle = useMutation((uuid: Uuid) => toggleTask(uuid));

  return checklist?.value ? (
    <ChecklistSection
      isDisabled={!isMine}
      isLoading={toggle.isLoading}
      onToggleTask={toggle.mutate}
      tasks={checklist.value.tasks}
    />
  ) : (
    <Skeleton variant="rectangular" height={300} />
  );
};

const useStyles = makeStyles((theme) => ({}));
