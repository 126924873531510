import { Navigate } from "react-router-dom-v5-compat";

export const MyThreadsRedirect = () => {
  return (
    <Navigate
      // encodeURIComponent('{"sort":{"field":"invitedAt","order":"ASC"},"status":["open"],"isMine":"true"}')
      to="/faktury/watki?filters=%7B%22sort%22%3A%7B%22field%22%3A%22invitedAt%22%2C%22order%22%3A%22DESC%22%7D%2C%22status%22%3A%5B%22open%22%5D%2C%22isMine%22%3A%22true%22%7D"
      replace
    />
  );
};
