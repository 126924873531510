import { useQueries } from "react-query";

import { toDateString } from "@megarax/common";
import { tradeRoutesResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";

export const useUserScheduledTradeRoutes = (dates: Date[], userId: number) => {
  const provider = useResourceProviderV2(tradeRoutesResource);

  const queries = useQueries(
    dates.map((date) => ({
      queryKey: ["userScheduledTradeRoutes", date, userId],
      queryFn: () =>
        provider
          .bySalespersonId(userId)
          .byDate(toDateString(date))
          .retrieve()
          .then((r) => {
            const value = r.assertOk().value;
            return value ? { ...value, date: toDateString(date) } : null;
          }),
    })),
  );

  const tradeRoutes = queries.map((q) => q.data ?? null);
  const isLoading = queries.some((q) => q.isFetching);

  return { tradeRoutes, isLoading, refetch: () => queries.forEach((q) => q.refetch()) };
};
