import { AnyUser } from "@megaron/access-control";
import { HttpAction, HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";

import { bi } from "./bi";
import { customers } from "./customers";
import { history } from "./history";
import { items } from "./items";
import { purchases } from "./purchases";
import { regions } from "./regions";

export const crmHttpService = HttpService({
  ...customers,
  ...history,
  ...purchases,
  ...regions,
  ...items,
  ...bi,
});

export type CrmHandlers = HandlerMap<typeof crmHttpService, AnyUser>;
