import React, { useEffect, useState } from "react";

import { useGoogleMapsLoader } from "./useGoogleMapsLoader";

interface LatLng {
  lat: number;
  lng: number;
}

interface Props {
  latLng: LatLng;
  height?: string;
  width?: string;
}

export const MapPinDisplay: React.FC<Props> = ({ latLng, height, width }) => {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const onInit = (ggl: typeof google, map: google.maps.Map) => {
    if (!map || !ggl) return;
    setMarker(
      new ggl.maps.Marker({
        position: latLng,
        map: map,
        title: "Wizyta",
        draggable: false,
      }),
    );
  };

  const mapsLoaderResult = useGoogleMapsLoader(getMapOptions(latLng), onInit);
  const { map } = mapsLoaderResult;

  useEffect(() => {
    if (!map || !marker) return;
    marker.setPosition(latLng);
    map.setCenter(latLng);
  }, [latLng]);

  return (
    <div
      ref={(ref) => mapsLoaderResult.setMapRef(ref)}
      style={{ height: height ?? "150px", width: width ?? "100%", flexGrow: 1 }}
    />
  );
};

const getMapOptions = (latLng: LatLng): google.maps.MapOptions => {
  return {
    center: latLng,
    zoom: 13,
    streetViewControl: false,
    disableDefaultUI: true,
    zoomControl: true,
    draggable: false,
    mapTypeControlOptions: {
      mapTypeIds: [],
    },
  };
};
