import { useTheme } from "@emotion/react";
import heic2any from "heic2any";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineUploadFile } from "react-icons/md";

import { useDeviceType } from "@megaron/dash-mq";
import { Spinner } from "@megaron/dash-spinner";
import { useToast } from "@megaron/dash-toast";
import { AttachmentUpload } from "@megaron/invoices-contracts";

import { fileListToBase64 } from "../thread";

type Props = {
  onFileUpload: (attachments: AttachmentUpload[]) => void;
  isUploading?: boolean;
};

const convertHeicToPng = async (file: File): Promise<File> => {
  const result = await heic2any({ blob: file, toType: "image/png" });
  return new File([result as Blob], file.name.replace(/\.[^.]+$/, ".png"), { type: "image/png" });
};

const createFileList = (files: File[]): FileList => {
  const dataTransfer = new DataTransfer();
  files.forEach((file) => dataTransfer.items.add(file));
  return dataTransfer.files;
};

export const DragAndDrop: React.FC<Props> = ({ onFileUpload, isUploading }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const toast = useToast();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const onFilesSelect = async (files: File[]) => {
        if (!files) return;
        try {
          const fileConversions = acceptedFiles.map(async (file) => {
            if (file.type === "image/heic") {
              return convertHeicToPng(file);
            }
            return file;
          });

          const convertedFiles = await Promise.all(fileConversions);
          const fileList = createFileList(convertedFiles);
          const newAttachments = await fileListToBase64(fileList);

          onFileUpload(newAttachments);
        } catch (err) {
          console.log(err);
          toast.error("Nie udało się załadować plików.");
        }
      };

      onFilesSelect(acceptedFiles);
    },
    [toast, onFileUpload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      css={{
        position: "relative",
        border: isDragActive ? `2px dashed ${theme.colors.primary}` : `2px dashed ${theme.colors.border}`,
        padding: "10px",
        borderRadius: "8px",
        textAlign: "center",
      }}
    >
      <div
        {...getRootProps()}
        css={{
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
          padding: "2rem 1rem",
        }}
      >
        <input {...getInputProps()} />
        <div css={{ display: "flex", flexDirection: "column", gap: "0.75rem", alignItems: "center" }}>
          {isUploading ? (
            <div css={{ height: "80px" }}>
              <Spinner size="64px" />
            </div>
          ) : (
            <MdOutlineUploadFile size={80} css={{ color: theme.colors.primaryLight }} />
          )}
          <span
            css={{
              fontFamily: theme.displayFontFamily,
              fontSize: "1rem",
              color: isDragActive ? theme.colors.border : theme.colors.primary,
              marginBottom: "4px",
              textAlign: "center",
            }}
          >
            {isMobile ? (
              "Dodaj załącznik"
            ) : (
              <>
                Przeciągnij i upuść <br />
                lub <strong>dodaj załącznik</strong>
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
