import { HttpAction } from "@megaron/http-service";
import { Failure, Ok } from "@megaron/result";
import { Serializer, Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

const urlSafeStringSerializer: Serializer<string> = {
  serialize: (value) => encodeURIComponent(value),
  deserialize: (raw: any) => {
    try {
      return Ok(decodeURIComponent(raw));
    } catch (error) {
      return Failure("FailedToDecodeURIComponent");
    }
  },
};

export const accountDtoSerializer = Serializers.object({
  email: Serializers.string,
  isActive: Serializers.boolean,
  groups: Serializers.array(
    Serializers.object({
      id: uuidSerializer,
      name: Serializers.string,
    }),
  ),
  roles: Serializers.array(Serializers.string),
  firstName: Serializers.string,
  lastName: Serializers.string,
  profilePictureUrl: Serializers.string,
  lastSignInAt: Serializers.datetime.nullable(),
});

export type AccountDto = SerializerValue<typeof accountDtoSerializer>;

export const accountSimpleDtoSerializer = Serializers.object({
  email: Serializers.string,
  isActive: Serializers.boolean,
  firstName: Serializers.string,
  lastName: Serializers.string,
  profilePictureUrl: Serializers.string,
  lastSignInAt: Serializers.datetime.nullable(),
  groups: Serializers.array(
    Serializers.object({
      id: uuidSerializer,
      name: Serializers.string,
    }),
  ),
  roles: Serializers.array(Serializers.string),
});

export type AccountSimpleDto = SerializerValue<typeof accountSimpleDtoSerializer>;

export const saveAccountBodySerializer = Serializers.object({
  isActive: Serializers.boolean,
  groupIds: Serializers.array(uuidSerializer),
});

export type SaveAccountBody = SerializerValue<typeof saveAccountBodySerializer>;

export const profileSerializer = Serializers.object({
  email: Serializers.string,
  firstName: Serializers.string,
  lastName: Serializers.string,
  profilePictureUrl: Serializers.string,
});

export type Profile = SerializerValue<typeof profileSerializer>;

export const accountActions = {
  accountsQuery: HttpAction({
    path: "/accounts",
    method: "get",
    requiresAuth: true as const,
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      sortBy: Serializers.stringOneOf("email", "lastSignInAt"),
      isActive: Serializers.booleanString,
      role: Serializers.string.array().asJsonString(),
    }).partial(),
    valueSerializer: Serializers.array(accountSimpleDtoSerializer),
  }),

  accountQuery: HttpAction({
    path: "/accounts/email/:email",
    method: "get",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      email: urlSafeStringSerializer,
    }),
    valueSerializer: accountDtoSerializer,
    errorSerializer: Serializers.stringOneOf("AccountNotFound"),
  }),

  saveAccount: HttpAction({
    path: "/accounts/email/:email",
    method: "put",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      email: urlSafeStringSerializer,
    }),
    bodySerializer: saveAccountBodySerializer,
    errorSerializer: Serializers.stringOneOf("GroupNotFound"),
  }),

  createApiKey: HttpAction({
    path: "/accounts/email/:email/createApiKey",
    method: "post",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      email: urlSafeStringSerializer,
    }),
    bodySerializer: Serializers.object({
      name: Serializers.string,
    }),
    valueSerializer: Serializers.object({
      apiKey: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("AccountNotFound"),
  }),

  accountProfilesQuery: HttpAction({
    path: "/accounts/profile",
    method: "get",
    requiresAuth: true as const,
    qsSerializer: Serializers.object({
      emailList: Serializers.jsonString(Serializers.array(urlSafeStringSerializer)),
    }),
    valueSerializer: Serializers.array(accountSimpleDtoSerializer),
  }),

  allProfilesQuery: HttpAction({
    path: "/accountList",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: Serializers.array(profileSerializer),
  }),
};
