import qs from "qs";

import { Serializer, Serializers } from "@megarax/serializers";

export const getInitialValuesFromQs = (queryStrings: string): QsInitialValues => {
  const { searchText, tags } = qs.parse(queryStrings);

  return {
    searchText: deserializeWithFallback(Serializers.string, searchText, ""),
    tags: deserializeWithFallback(Serializers.array(Serializers.string), tags, []),
  };
};

const deserializeWithFallback = <T>(serializer: Serializer<T>, raw: any, fallback: T): T => {
  try {
    return serializer.forceDeserialize(raw);
  } catch (err) {
    return fallback;
  }
};

export interface QsInitialValues extends QueryFilters {}

export type QueryFilters = {
  searchText: string;
  tags: string[];
};
