import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom-v5-compat";

import { DescriptionField, DescriptionList } from "@megaron/dash-description-list";
import { Card, HeaderBar } from "@megaron/dash-page";
import { Spinner } from "@megaron/dash-spinner";
import { mutationToResult, useClientManager } from "@megaron/react-clients";

import { useBeep } from "./beep";
import { QrScanner } from "./QrScanner";
import { ScanStatusCard } from "./ScanStatusCard";

type Props = {};

export const UngroupCodeView: React.FunctionComponent<Props> = (props) => {
  const beep = useBeep();

  const [scannedCodeString, setScannedCodeString] = React.useState<string>();
  const groupsQuery = useClientManager("loyaltyPoints")
    .codeGroupsQuery()
    .useQuery(
      { codeId: scannedCodeString!, type: ["pokrywki", "produkcja_pokrywki"] },
      { enabled: !!scannedCodeString },
    );
  const queryClient = useQueryClient();

  useEffect(() => {
    if (scannedCodeString) {
      (async () => {
        await beep(800);
      })();
    }
  }, [scannedCodeString]);

  return (
    <div>
      <HeaderBar header="Usuń kody" actions={<></>} />
      <QrScanner onScan={setScannedCodeString}>
        {scannedCodeString && (
          <div css={{ position: "absolute", top: "50px", width: "100%", padding: "1rem" }}>
            <ScanStatusCard code={scannedCodeString} error={undefined}>
              <DescriptionList>
                {groupsQuery.data?.map((group) => (
                  <UngroupCodeField
                    codeId={scannedCodeString}
                    groupName={group.name}
                    key={scannedCodeString + group.name}
                  />
                ))}
              </DescriptionList>
            </ScanStatusCard>
          </div>
        )}
      </QrScanner>
    </div>
  );
};

export const UngroupCodeField: React.FunctionComponent<{
  groupName: string;
  codeId: string;
}> = (props) => {
  const beep = useBeep();

  const ungroupMutation = useClientManager("loyaltyPoints")
    .removeCodesFromGroup()
    .useMutation({
      onSuccess: async () => {
        await beep(1200);
      },
    });

  useEffect(() => {
    ungroupMutation.mutate({ codeIds: [props.codeId], groupName: props.groupName });
  }, [props.codeId, props.groupName]);

  return (
    <DescriptionField
      label={
        <div css={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
          Grupa
          {ungroupMutation.isLoading && <Spinner color="white" />}
        </div>
      }
      color="white"
    >
      <span css={{ textDecoration: ungroupMutation.isSuccess ? "line-through" : undefined }}>{props.groupName}</span>
    </DescriptionField>
  );
};
