import { BaseResource, ResourceAction, StringResourceParam } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

export type SetSizeInput = {
  packType: "pallet" | "layer" | "box";
  size: number | null;
};

export type SetSizesInput = {
  pallet: number | null;
  layer: number | null;
  box: number | null;
};

export interface PackSizeDefaultsDto {
  gtin: string;
  box: number | null;
  layer: number | null;
  pallet: number | null;
}

export const packSizeDefaultsResource = BaseResource(["sales", "packSizeDefaults"], {
  byGtin: StringResourceParam("gtin", "gtin", {
    retrieve: ResourceAction({
      name: "retrieve",
      method: "get",
      path: "",
      responseSerializer: MSerializers.object<PackSizeDefaultsDto>({
        gtin: MSerializers.string,
        box: MSerializers.integer.nullable(),
        layer: MSerializers.integer.nullable(),
        pallet: MSerializers.integer.nullable(),
      }).nullable(),
    }),
    setSize: ResourceAction({
      name: "setSize",
      method: "put",
      requestBodySerializer: MSerializers.object<SetSizeInput>({
        packType: MSerializers.stringOneOf("pallet", "box", "layer"),
        size: MSerializers.integer.nullable(),
      }),
    }),
    setSizes: ResourceAction({
      name: "setSizes",
      method: "put",
      path: "",
      requestBodySerializer: MSerializers.object<SetSizesInput>({
        box: MSerializers.integer.nullable(),
        layer: MSerializers.integer.nullable(),
        pallet: MSerializers.integer.nullable(),
      }),
    }),
  }),
});
