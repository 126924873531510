import { useTheme } from "@emotion/react";
import { MdOutlineGroup } from "react-icons/md";

import { BudgetDoc } from "@megaron/invoices-contracts";

type Props = {
  budget: BudgetDoc;
};

export const BudgetTileHeader: React.FC<Props> = ({ budget }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        gap: "0.375rem",
        flexDirection: "column",
        color: budget.isArchived ? theme.colors.secondaryText : theme.colors.primary,
      }}
    >
      <div css={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
        <div css={{ display: "flex", alignItems: "center", gap: "0.375rem" }}>
          <h4 css={{ fontSize: "0.875rem", fontWeight: 700, margin: 0 }}>
            {budget.name}
            <span css={{ fontSize: "0.875rem", fontWeight: 400, marginLeft: "0.25rem" }}>{budget.budgetNumber}</span>
          </h4>
        </div>
        <div css={{ display: "flex", alignItems: "center", gap: "0.25rem", color: theme.colors.default }}>
          <MdOutlineGroup size={16} />
          <span css={{ fontSize: "0.875rem", fontWeight: 400 }}>{budget.managerGroupIds.length}</span>
        </div>
      </div>
    </div>
  );
};
