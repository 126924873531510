import { CalendarViewWeek } from "@mui/icons-material";
import { Box, Container, Drawer, IconButton, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Uuid } from "@megarax/common";
import { LoadingBar } from "@megarax/ui-components";

import { VisitViewContainer } from "../../VisitView";

interface Props {
  dateSelect: React.ReactNode;
  alerts: React.ReactNode;
  buttons: React.ReactNode;
  workTime: React.ReactNode;
  activityList: React.ReactNode;
  isLoading: boolean;
  closeVisit: () => void;
  viewSwitchButton: React.ReactNode;
}

export const ViewLayout: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) return <MobileViewLayout {...props} />;

  return (
    <div className={classes.view}>
      <Paper className={classes.toolbarPaper}>
        <Box
          display="flex"
          flexDirection="row"
          alignContent="space-between"
          alignItems="center"
          mx={2}
        >
          <Box>{props.viewSwitchButton}</Box>
          <div className={classes.daySelect}>{props.dateSelect}</div>
          <Box width="40px"></Box>
        </Box>
        <LoadingBar loading={props.isLoading} />
      </Paper>
      <Container maxWidth="md">{props.alerts}</Container>

      <Container className={classes.container} maxWidth="md">
        <div className={classes.leftSection}>
          {props.buttons}
          {props.workTime}
        </div>
        <Box className={classes.activityList}>{props.activityList}</Box>
      </Container>
      <Route
        path={`${path}/wizyta/:uuid`}
        render={({ match }) => (
          <Drawer anchor="right" open={true} onClose={props.closeVisit}>
            <Box maxWidth={450} minWidth={320} sx={{ overflowX: "hidden" }}>
              <VisitViewContainer uuid={match.params.uuid as Uuid} />
            </Box>
          </Drawer>
        )}
      ></Route>
    </div>
  );
};

export const MobileViewLayout: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/wizyta/:uuid`}
        render={({ match }) => <VisitViewContainer uuid={match.params.uuid as Uuid} />}
      ></Route>
      <Route path={path}>
        <div className={classes.view}>
          <Paper className={classes.toolbarPaper}>
            <Box
              display="flex"
              flexDirection="row"
              alignContent="space-between"
              alignItems="center"
              mx={2}
            >
              <Box>{props.viewSwitchButton}</Box>
              <div className={classes.daySelect}>{props.dateSelect}</div>
              <Box width="40px"></Box>
            </Box>
            <LoadingBar loading={props.isLoading} />
          </Paper>
          <Container maxWidth="md">{props.alerts}</Container>

          <Container className={classes.mobileContainer} maxWidth="md">
            <div className={classes.leftSection}>
              {props.buttons}
              {props.workTime}
            </div>
            <Box className={classes.mobileActivityList}>{props.activityList}</Box>
          </Container>
        </div>
      </Route>
    </Switch>
  );
};

const useStyles = makeStyles((theme) => ({
  view: {
    background: theme.palette.grey[50],
    flexGrow: 1,
  },
  daySelect: {
    margin: "0 auto",
  },
  container: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "row-reverse",
  },
  mobileContainer: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  activityList: {
    width: "100%",
    flex: 3,
    marginRight: theme.spacing(2),
  },
  mobileActivityList: {
    width: "100%",
    flex: 3,
  },
  leftSection: {
    flex: 2,
    marginBottom: theme.spacing(3),
  },
  toolbarPaper: {
    zIndex: 10,
    position: "sticky",
    top: 0,
    borderRadius: 0,
    marginBottom: theme.spacing(2),
  },
  unfinishedVisitsAlert: {
    marginTop: theme.spacing(2),
  },
}));
