import { Failure, Ok } from "@megaron/result";

import { serializerExtensions, SerializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";
import { identity } from "./object";

export const result = <TValue, TError = never>(
  successSerializer: UnsafeSerializer<TValue> = identity(),
  failureSerializer: UnsafeSerializer<TError> = identity(),
): UnsafeSerializer<Ok<TValue> | Failure<TError>> & SerializerExtensions<Ok<TValue> | Failure<TError>> => ({
  serialize: (input) => {
    if (input.isOk)
      return {
        isOk: true,
        value: successSerializer.serialize(input.value),
      };
    else
      return {
        isOk: false,
        error: failureSerializer.serialize(input.error),
      };
  },
  forceDeserialize: (raw) => {
    if (raw.isOk) return Ok(successSerializer.forceDeserialize(raw.value));
    else return Failure(failureSerializer.forceDeserialize(raw.error));
  },
  ...serializerExtensions(),
});
