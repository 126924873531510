import { toDateString, Uuid } from "@megarax/common";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Decimal from "decimal.js";
import React from "react";

import { GetPriceReportSubjects, OnSubmit, ReportForm } from "./ReportForm";
import { ReportTable } from "./ReportTable";

export type Report = {
  uuid: Uuid;
  time: Date;
  createdById: number;
  netPrice: Decimal;
  customerUuid: Uuid;
  subjectUuid: Uuid;
  subjectName: string;
};

interface Props {
  reports: Report[] | undefined;
  onSubmit: OnSubmit;
  onClose: () => void;
  getPriceReportSubjects: GetPriceReportSubjects;
  reportUuid: Uuid;
  date: Date;
}

export const CustomerReportsDialog: React.FunctionComponent<Props> = ({
  getPriceReportSubjects,
  onSubmit,
  onClose,
  reports,
  reportUuid,
  date,
}) => {
  const isToday = toDateString(date) === toDateString(new Date());

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Ankiety cenowe</DialogTitle>
      <DialogContent>
        {isToday ? (
          <ReportForm key={reportUuid} getPriceReportSubjects={getPriceReportSubjects} onSubmit={onSubmit} />
        ) : null}
        {reports && <ReportTable reports={reports} />}
      </DialogContent>
    </Dialog>
  );
};
