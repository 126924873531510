import { useTheme } from "@emotion/react";
import React, { useState } from "react";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { Button } from "@megaron/dash-form";
import { Page, PageHeader } from "@megaron/dash-page";

import { GroupList } from "./GroupList";
import { getNewGroupPath } from "./paths";

type Props = {};

export const GroupsHome: React.FunctionComponent<Props> = ({}) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");

  return (
    <Page>
      <PageHeader
        actions={
          <Link to={getNewGroupPath()}>
            <Button icon={<MdAdd />}>Nowe</Button>
          </Link>
        }
      >
        Stanowiska
      </PageHeader>

      <GroupList />
    </Page>
  );
};
