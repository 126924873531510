import { SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import { Uuid } from "@megarax/common";
import { customerListResource, customerStatuses } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { LoadingBar, QsInput, useNewRouterQsV2 } from "@megarax/ui-components";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { renderToString } from "react-dom/server";
import { useQuery } from "react-query";
import { z } from "zod";

import { formatAddress } from "@megaron/megarax-v2-customers";

import { customerHasAddress } from "../mapsCommon/customerHasAddress";
import { ExtendedMarkerClusterer } from "../mapsCommon/ExtendedMarkerClusterer";
import { Google } from "../mapsCommon/MapContainer";
import { googleMarkerFactory } from "../mapsCommon/markerFactoryV2";
import { notEmpty } from "../utils";
import { Filters, QueryFilters } from "./Filters";

interface MapManagerProps {
  map: google.maps.Map;
  google: Google;
}

const qsSchema: QsInput<QueryFilters> = {
  regionUuid: {
    validator: z.array(z.string()),
    fallback: null,
  },
  macroregionUuid: {
    validator: z.array(z.string()),
    fallback: null,
  },
  searchText: {
    validator: z.string(),
    fallback: "",
  },
  visitedSince: {
    validator: z.preprocess(
      (obj: any) => ({
        value:
          obj?.value === null
            ? null
            : typeof obj?.value === "string" && !isNaN(parseInt(obj?.value))
            ? _.toNumber(obj?.value)
            : null,
        bool:
          typeof obj?.bool === "string" ? (obj?.bool === "true" ? true : obj?.bool === "false" ? false : true) : true,
      }),
      z.object({ value: z.number().nullable(), bool: z.boolean() }),
    ),
    fallback: { value: null, bool: true },
  },
  orderedSince: {
    validator: z.preprocess(
      (obj: any) => ({
        value:
          obj?.value === null
            ? null
            : typeof obj?.value === "string" && !isNaN(parseInt(obj?.value))
            ? _.toNumber(obj?.value)
            : null,
        bool:
          typeof obj?.bool === "string" ? (obj?.bool === "true" ? true : obj?.bool === "false" ? false : true) : true,
      }),
      z.object({ value: z.number().nullable(), bool: z.boolean() }),
    ),
    fallback: { value: null, bool: true },
  },
  status: {
    validator: z.array(z.enum(customerStatuses)).nullable(),
    fallback: null,
  },
  onRoute: {
    validator: z.enum(["true", "false"]).nullable(),
    fallback: null,
  },
  mapOptions: {
    validator: z
      .object({
        lat: z.number(),
        lng: z.number(),
        zoom: z.number(),
      })
      .nullable(),
    fallback: null,
    preprocess: (val) =>
      typeof val === "object" && !Array.isArray(val)
        ? {
            zoom: _.toNumber(val?.zoom),
            lat: _.toNumber(val?.lat),
            lng: _.toNumber(val?.lng),
          }
        : null,
  },
};

export const DashboardMapManager: React.FC<MapManagerProps> = ({ map, google }) => {
  const { list } = useResourceProviderV2(customerListResource);

  const { qs, setAllQs, setQs } = useNewRouterQsV2(qsSchema, { status: ["vip"] });
  const { mapOptions, ...filters } = qs;

  const { data: customers, isLoading } = useQuery(["customers", filters], () =>
    list({
      limit: 10000,
      status: filters.status ?? [],
      searchText: filters.searchText,
      regionUuid: (filters.regionUuid ?? undefined) as Uuid[] | undefined,
      macroregionUuid: (filters.macroregionUuid ?? undefined) as Uuid[] | undefined,
      visitedIn: filters.visitedSince.value
        ? filters.visitedSince.bool
          ? filters.visitedSince.value
          : -filters.visitedSince.value
        : undefined,
      orderedIn: filters.orderedSince.value
        ? filters.orderedSince.bool
          ? filters.orderedSince.value
          : -filters.orderedSince.value
        : undefined,
      isOnRoute: filters.onRoute === "true" ? true : filters.onRoute === "false" ? false : undefined,
    }).map((res) => res.items),
  );

  const idleListener = React.useRef<google.maps.MapsEventListener>();

  const [markerFactory] = React.useState(googleMarkerFactory({ google, map }));
  const [markerClusterer] = React.useState(
    new ExtendedMarkerClusterer({
      map,
      markers: [],
      algorithm: new SuperClusterAlgorithm({}),
      renderer: {
        render: ({ count, position }) => {
          return markerFactory.generate({
            position: { lat: position.lat(), lng: position.lng() },
            variant: "cluster",
            idx: 0,
            text: String(count),
          });
        },
      },
    }),
  );

  const prevZoom = React.useRef<number | undefined>(undefined);

  const customerEntries = React.useMemo(() => {
    if (!customers) return undefined;
    if (customers.isFailure) return customers;
    return customers.map((res) => res.filter(customerHasAddress));
  }, [customers]);

  const infoWindow = React.useRef<google.maps.InfoWindow>(new google.maps.InfoWindow());

  const updateMarkers = React.useCallback(
    (fullRefresh: boolean) => {
      const skipUpdate = prevZoom.current === map.getZoom() && !fullRefresh;
      if (!customerEntries || !customerEntries.isOk || skipUpdate) return;
      prevZoom.current = map.getZoom();

      const markers = customerEntries.value
        .map((entry, idx) => {
          if (!entry.status) return null;

          const position = { lat: entry.visitAddress.lat.toNumber(), lng: entry.visitAddress.lng.toNumber() };

          const marker = markerFactory.generate({
            position,
            variant: entry.status,
            idx,
            text: entry.name ?? "",
          });

          marker.addListener("click", () => {
            map.panTo(position);
            infoWindow.current.setContent(
              renderToString(
                <InfoBox
                  name={entry.name ?? ""}
                  uuid={entry.uuid}
                  address={formatAddress({
                    locality: entry.visitAddress.locality,
                    street: entry.visitAddress.street,
                    postalCode: "",
                    country: "",
                    name: entry.visitAddress.name,
                  })}
                />,
              ),
            );
            infoWindow.current.open({
              anchor: marker,
              map,
              shouldFocus: false,
            });
          });
          return marker;
        })
        .filter(notEmpty);

      markerClusterer.clearMarkers();
      markerClusterer.addMarkers(markers);
    },
    [customerEntries],
  );

  React.useEffect(() => {
    updateMarkers(true);
    markerClusterer.updateVisibility();

    if (idleListener.current) google.maps.event.removeListener(idleListener.current);
    idleListener.current = map.addListener("idle", () => {
      const mapCenter = map.getCenter()?.toJSON();
      const zoom = map.getZoom();
      setQs("mapOptions", mapCenter && zoom ? { zoom, ...mapCenter } : null);
      updateMarkers(false);
      markerClusterer.updateVisibility();
    });
  }, [updateMarkers]);

  React.useEffect(() => {
    if (mapOptions) {
      const { zoom, ...latLng } = mapOptions;
      map.setCenter(latLng);
      map.setZoom(zoom);
    }
    map.addListener("zoom_changed", () => {
      markerClusterer.clearMarkers();
    });
  }, []);

  return (
    <>
      <LoadingBar loading={isLoading} />
      <Box position="absolute" zIndex={1000000} top={0}>
        <Filters qs={qs} setQueryFilters={setAllQs} />
      </Box>
    </>
  );
};

const InfoBox: React.FC<{ name: string; address: string; uuid: Uuid }> = ({ name, uuid, address }) => {
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <a href={`/crm/pos/klienci/${uuid}`}>
        <Typography>{name}</Typography>
      </a>
      <Typography variant="caption">{address}</Typography>
    </Box>
  );
};
