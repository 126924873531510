import _, { identity } from "lodash";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export const mapObject = <TValue, TKey extends string = string>(
  valueSerializer: UnsafeSerializer<TValue>,
  keySerializer?: UnsafeSerializer<TKey>,
): UnsafeSerializer<Record<TKey, TValue>> & SerializerExtensions<Record<TKey, TValue>> => ({
  serialize: (input) =>
    _(input)
      .mapValues(valueSerializer.serialize)
      .mapKeys((v, key) => (keySerializer?.serialize ?? identity)(key as any))
      .value(),
  forceDeserialize: (raw) =>
    _(raw)
      .mapKeys((v, key) => (keySerializer?.forceDeserialize ?? identity)(key))
      .mapValues(valueSerializer.forceDeserialize)
      .value() as any,
  ...serializerExtensions(),
});
