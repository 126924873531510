import React from "react";

export interface Breadcrumb {
  id: string;
  title: string;
  to?: string;
}

export interface BreadcrumbsContext {
  addBreadcrumb: (breadcrumb: Breadcrumb) => void;
  updateBreadcrumb: (breadcrumb: Breadcrumb) => void;
  removeBreadcrumb: (id: string) => void;
  breadcrumbs: Breadcrumb[];
}

export const BreadcrumbsContext = React.createContext<BreadcrumbsContext>({
  addBreadcrumb: () => {},
  updateBreadcrumb: () => {},
  removeBreadcrumb: () => {},
  breadcrumbs: [],
});

export const BreadcrumbsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = React.useState<Breadcrumb[]>([]);

  const addBreadcrumb = (breadcrumb: Breadcrumb) => {
    setBreadcrumbs((breadcrumbs) => [...breadcrumbs, breadcrumb]);
  };

  const updateBreadcrumb = (breadcrumb: Breadcrumb) => {
    setBreadcrumbs((breadcrumbs) => breadcrumbs.map((bc) => (bc.id === breadcrumb.id ? breadcrumb : bc)));
  };

  const removeBreadcrumb = (id: string) => {
    setBreadcrumbs((breadcrumbs) => breadcrumbs.filter((breadcrumb) => breadcrumb.id !== id));
  };

  return (
    <BreadcrumbsContext.Provider value={{ addBreadcrumb, updateBreadcrumb, removeBreadcrumb, breadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};
