import { faArrowRight, faLayerGroup, faPlus, faSearchDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomerDetail, OrderDraftDto, TradeItemDto } from "@megarax/crm-contracts";
import { AreUSureWrapper, AsyncButton } from "@megarax/ui-components";
import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Dictionary } from "lodash";
import React from "react";

import { DraftDetailsInfo } from "./DraftDetailsInfo";
import { LinesTable } from "./LinesTable";

interface Props {
  draftDetails: OrderDraftDto;
  openNewItemDialog: () => void;
  openSendDraftDialog: () => void;
  openEditItemDialog: (lineUuid: string) => void;
  openMarginsDialog: () => void;
  openPackSizeDialog: () => void;
  getClientUrl: (uuid: string) => string;
  deleteDraft: () => Promise<void>;
  buyer: CustomerDetail | undefined;
  payer: CustomerDetail | undefined;
  recipient: CustomerDetail | undefined;
  tradeItemsDictionary: Dictionary<TradeItemDto> | undefined;
  onEditCustomers: () => void;
}

export const DraftDetails: React.FC<Props> = ({
  draftDetails,
  openNewItemDialog,
  openSendDraftDialog,
  openEditItemDialog,
  openMarginsDialog,
  openPackSizeDialog,
  getClientUrl,
  deleteDraft,
  buyer,
  payer,
  recipient,
  tradeItemsDictionary,
  onEditCustomers,
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <div className={classes.section}>
        <div className={classes.row}>
          <Typography variant="h5">Wersja robocza zamówienia</Typography>
        </div>
        <Typography variant="caption" color="textSecondary">
          {`utworzona ${draftDetails.startedAt.toLocaleDateString()} ${draftDetails.startedAt.toLocaleTimeString()}`}
        </Typography>
      </div>
      <div className={classes.section}>
        <DraftDetailsInfo
          buyer={{ customer: buyer, uuid: draftDetails.buyer.uuid }}
          payer={{ customer: payer, uuid: draftDetails.payer.uuid }}
          recipient={{
            customer: recipient,
            uuid: draftDetails.recipient.uuid,
          }}
          getClientUrl={getClientUrl}
        />
        <Box mt={2}>
          <Button onClick={onEditCustomers}>Edytuj klientów</Button>
        </Box>
      </div>
      <div className={classes.section}>
        <LinesTable
          currency={draftDetails.currency}
          lines={draftDetails.lines}
          tradeItemsDictionary={tradeItemsDictionary}
          openEditItemDialog={openEditItemDialog}
        />
        <div className={classes.row}>
          <Button
            className={classes.button}
            variant="text"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={openNewItemDialog}
          >
            Dodaj przedmiot
          </Button>
          <div>
            <IconButton className={classes.iconButton} onClick={openPackSizeDialog} size="large">
              <FontAwesomeIcon icon={faLayerGroup} fixedWidth />
            </IconButton>
            <IconButton className={classes.iconButton} onClick={openMarginsDialog} size="large">
              <FontAwesomeIcon icon={faSearchDollar} fixedWidth />
            </IconButton>
          </div>
        </div>
      </div>

      <div className={classes.row}>
        <AreUSureWrapper action={deleteDraft}>
          <Button className={classes.deleteButton} color="secondary">
            Usuń wersję roboczą
          </Button>
        </AreUSureWrapper>
        <AsyncButton
          asyncAction={openSendDraftDialog}
          className={classes.submitButton}
          variant="contained"
          color="primary"
          endIcon={<FontAwesomeIcon icon={faArrowRight} />}
        >
          Wyślij
        </AsyncButton>
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    section: {
      margin: theme.spacing(4, 0),
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: theme.spacing(1, 0),
    },
    pointer: {
      cursor: "pointer",
    },
    iconButton: {
      padding: "8px 7px",
      "& .fa-w-16": {
        fontSize: "1.3rem",
      },
    },
    button: {
      "& .fa-w-14": {
        fontSize: "0.9rem",
      },
    },
    deleteButton: {
      padding: "8px 7px",
      "& .fa-w-14": {
        fontSize: "1rem",
      },
    },
    submitButton: {
      marginLeft: "auto",
      "& .fa-w-14": {
        fontSize: "0.9rem",
      },
    },
  };
});
