import { addDays, differenceInDays } from "date-fns";
import Decimal from "decimal.js";
import React from "react";
import { MdPlayArrow } from "react-icons/md";

import { DescriptionField, DescriptionList } from "@megaron/dash-description-list";
import { Dialog } from "@megaron/dash-dialog";
import { Button, CheckboxField, DateField, TextField } from "@megaron/dash-form";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  groups: string[];
  onClose: () => void;
};

export const CreateSubgroupDialog: React.FunctionComponent<Props> = (props) => {
  const [groupName, setGroupName] = React.useState<string>("");
  const [firstCodeId, setFirstCodeId] = React.useState<string>("");
  const [lastCodeId, setLastCodeId] = React.useState<string>("");

  const mutation = useClientManager("loyaltyPoints")
    .createSubgroup()
    .useMutation({
      onSuccess: (data, vars, ctx) => {
        ctx.queryClient.invalidateQueries(["loyaltyPoints", "codeGroupsQuery"]);
        props.onClose();
      },
    });

  const dryRunQuery = useClientManager("loyaltyPoints").createSubgroup().useQuery({
    dryRun: true,
    firstCodeId,
    lastCodeId,
    parentGroups: props.groups,
    subgroupName: "",
  });

  const go = () => {
    if (groupName)
      mutation.mutate({
        dryRun: false,
        firstCodeId,
        lastCodeId,
        parentGroups: props.groups,
        subgroupName: groupName,
      });
  };

  return (
    <Dialog onClose={props.onClose} header="Utwórz grupę">
      <div css={{ minWidth: "250px", display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
        <TextField
          autoFocus
          value={firstCodeId}
          onChange={setFirstCodeId}
          label="Pierwszy kod"
          css={{ margin: "0.5rem 0" }}
        />
        <TextField value={lastCodeId} onChange={setLastCodeId} label="Ostatni kod" css={{ margin: "0.5rem 0" }} />
        <TextField value={groupName} onChange={setGroupName} label="Nazwa grupy" css={{ margin: "0.5rem 0" }} />
      </div>

      <QuerySkeleton query={dryRunQuery} height="100px">
        {(data) => (
          <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <DescriptionList itemMinWidth="100px" css={{ margin: "0.5rem 0" }}>
              <DescriptionField label="Liczba kodów">{data.count}</DescriptionField>
              <DescriptionField label="Nadzbiór">{data.parentGroup}</DescriptionField>
            </DescriptionList>

            <Button css={{ width: "100%" }} icon={<MdPlayArrow />} onClick={go} isDisabled={!groupName}>
              Utwórz
            </Button>
          </div>
        )}
      </QuerySkeleton>
    </Dialog>
  );
};
