import { useTheme } from "@emotion/react";
import { useCallback } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";

import { Dialog } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  onClose: () => void;
  draftUuid: string;
  queryKey?: string | string[];
};

export const DeleteDraftDialog: React.FC<Props> = ({ onClose, draftUuid, queryKey }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const toast = useToast();

  const queryClient = useQueryClient();

  const { mutate } = useClientManager("invoices").deleteDraft().useMutation();

  const handleDelete = useCallback(() => {
    mutate(
      { uuid: Uuid(draftUuid) },
      {
        onError: () => {
          onClose();
          toast.error("Nie udało się usunąć wersji roboczej");
        },
        onSuccess: () => {
          if (queryKey) {
            queryClient.invalidateQueries(queryKey);
          }
          onClose();
          navigate("/faktury/wersje-robocze", { replace: true });
        },
      },
    );
  }, [draftUuid, mutate, navigate, toast, onClose, queryClient, queryKey]);

  return (
    <Dialog onClose={onClose} placement="center" css={{ width: "400px" }} hideHeader>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <span css={{ fontSize: "1rem", color: theme.colors.danger, fontWeight: 700 }}>Usuń wersję roboczą</span>
        <span css={{ fontSize: "0.875rem", color: "black" }}>Czy na pewno chcesz usunąć wersję roboczą?</span>
        <div css={{ display: "flex", gap: "0.625rem", marginLeft: "auto" }}>
          <Button variant="outline" onClick={onClose}>
            Anuluj
          </Button>
          <Button icon={<MdDeleteOutline />} css={{ background: theme.colors.danger }} onClick={handleDelete}>
            Usuń
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
