import { Serializers } from "../";

export type SortFilter<TSortField extends string> = {
  field: TSortField;
  order?: "ASC" | "DESC";
};

export const sortFilter = <T extends string>(fields: readonly T[]) =>
  Serializers.object<SortFilter<T>>({
    field: Serializers.stringOneOf(...fields),
    order: Serializers.optional(Serializers.stringOneOf("ASC", "DESC")),
  });
