import { faDollyFlatbed } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Uuid } from "@megarax/common";
import { orderResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { OrderList } from "./OrderList";

interface Props {
  customerUuid: Uuid;
}
export const CustomerRecentOrdersSectionContainer: React.FunctionComponent<Props> = ({ customerUuid }) => {
  const classes = useStyles();
  const history = useHistory();
  const orderProvider = useResourceProviderV2(orderResource);

  const { data: result } = useQuery(["customerRecentOrders", customerUuid], () =>
    orderProvider.customerRecentOrders({
      customerUuid,
    }),
  );

  const goToOrder = (orderUuid: Uuid) => {
    history.push(`/sprzedaz/zamowienia/${orderUuid}`);
  };

  const goToNewOrderForm = () => {
    history.push(`/sprzedaz/moje_zamowienia/nowe?initialCustomerUuid=${customerUuid}`);
  };

  return (
    <div>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="flex" alignItems="center" sx={{ gap: 2 }} px={2} py={1}>
          <FontAwesomeIcon icon={faDollyFlatbed} fixedWidth size="lg" />
          <Typography variant="h5">Ostatnie zamówienia</Typography>
        </Box>
        <Box ml="auto" mr={1}>
          <Button size="small" variant="contained" color="primary" onClick={goToNewOrderForm}>
            Nowe
          </Button>
        </Box>
      </Box>

      {result === undefined ? (
        <Skeleton variant="rectangular" height="200px" />
      ) : result.value ? (
        <OrderList onGoToOrder={goToOrder} orders={result.value} />
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));
