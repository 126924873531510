import Decimal from "decimal.js";

import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

export type SourceType =
  | "productCode"
  | "affiliateCode"
  | "affiliateCommission"
  | "gift"
  | "referralBonus"
  | "affiliateCodeClaimBonus"
  | "taxReimbursement";

export type SourceBreakdown = {
  [type in SourceType]: Decimal;
};

export type Purchase = {
  uuid: Uuid;
  userUuid: Uuid;
  listingUuid: Uuid;
  rewardId: string;
  price: Decimal;
  time: Date;
  sourceBreakdown: SourceBreakdown;
  isBuyerPro: boolean;
  isRefunded: boolean;
};

export const sourceBreakdownSerializer = Serializers.object<SourceBreakdown>({
  productCode: Serializers.decimal,
  affiliateCode: Serializers.decimal,
  affiliateCommission: Serializers.decimal,
  gift: Serializers.decimal,
  referralBonus: Serializers.decimal,
  affiliateCodeClaimBonus: Serializers.decimal,
  taxReimbursement: Serializers.decimal,
});

export const purchaseSerializer = Serializers.object<Purchase>({
  uuid: uuidSerializer,
  userUuid: uuidSerializer,
  listingUuid: uuidSerializer,
  rewardId: Serializers.string,
  price: Serializers.decimal,
  time: Serializers.datetime,
  sourceBreakdown: sourceBreakdownSerializer,
  isBuyerPro: Serializers.boolean,
  isRefunded: Serializers.boolean,
});

export type PurchaseSimple = {
  uuid: Uuid;
  userUuid: Uuid;
  listingUuid: Uuid;
  rewardId: string;
  price: Decimal;
  time: Date;
};

export const purchaseSimpleSerializer = Serializers.object<PurchaseSimple>({
  uuid: uuidSerializer,
  userUuid: uuidSerializer,
  listingUuid: uuidSerializer,
  rewardId: Serializers.string,
  price: Serializers.decimal,
  time: Serializers.datetime,
});
