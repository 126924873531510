import { InvoiceThreadDoc } from "@megaron/invoices-contracts";

import { AllThreadsMobileCard } from "./AllThreadsMobileCard";

type Props = {
  threads: InvoiceThreadDoc[];
  className?: string;
  userEmail: string;
};

export const AllThreadsMobileList: React.FC<Props> = ({ threads, className, userEmail }) => {
  return (
    <div
      className={className}
      css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%", marginBottom: "1rem" }}
    >
      {threads.map((thread) => (
        <AllThreadsMobileCard key={thread.threadUuid} thread={thread} userEmail={userEmail} />
      ))}
    </div>
  );
};
