import { useTheme } from "@emotion/react";
import React from "react";

import { DescriptionField, DescriptionList } from "@megaron/dash-description-list";
import { Card } from "@megaron/dash-page";

type Props = {
  error: string | undefined;
  code: string;
  lastGroupName?: string;
  children?: React.ReactNode;
  isSuccess?: boolean;
};

export const ScanStatusCard: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const color = props.error ? theme.colors.danger : props.isSuccess ? theme.colors.success : theme.colors.primary;

  return (
    <Card color={color} css={{}}>
      <DescriptionList itemMinWidth="80px">
        <DescriptionField label="Kod" color="white">
          <span css={{ fontWeight: "700" }}>{props.code}</span>
        </DescriptionField>
        {props.lastGroupName && (
          <DescriptionField label="Grupa" color="white">
            <span css={{ fontWeight: "700" }}>{props.lastGroupName}</span>
          </DescriptionField>
        )}
        {props.error && (
          <DescriptionField label="Błąd" color="white">
            <span css={{ fontWeight: "700" }}>{props.error}</span>
          </DescriptionField>
        )}
      </DescriptionList>
      {props.children}
    </Card>
  );
};
