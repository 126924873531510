import { orderListResource, OrderSimple, OrderSortField } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { useIndependentAsyncLoad, useResourceProviderV2 } from "@megarax/react-client";
import { useDepPagination } from "@megarax/react-utils";
import { SortFilter } from "@megarax/rest-resource";
import { BreadcrumbMarker, commonErrorsMap, LoadingBar, useSnackbarErrorHandler } from "@megarax/ui-components";
import { LinearProgress } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { Failure, Ok } from "@megaron/result";

import { EdiAlertsContainer } from "./ediAlerts";
import { OrderList } from "./OrderList";

interface Props {}

export type OrdersResult =
  | Ok<{
      count: number;
      items: OrderSimple[];
    }>
  | Failure<HttpRequestError>;

const perPage = 100;

export const OrderListContainer: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const orderProvider = useResourceProviderV2(orderListResource);

  const initialValues = getInitialValuesFromQs(location.search);

  const [ordersResult, setOrdersResult] = useState<OrdersResult>();

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const [searchText, setSearchText] = useState<string>(initialValues.search);

  const { paginationProps, page } = useDepPagination(
    {
      perPage,
      allCount: ordersResult?.value?.count,
      initialPage: initialValues.page,
    },
    [searchText],
  );

  const [sortModel, setSortModel] = useState<GridSortModel>(initialValues.sortBy as any);

  const { loading, reload } = useIndependentAsyncLoad(
    () =>
      orderProvider
        .list({
          sortBy: getSortByFromModel(sortModel),
          searchText: searchText,
          limit: perPage,
          offset: perPage * page,
        })
        .mapError(handleError),
    setOrdersResult,
    [sortModel, searchText, page],
  );

  const navigateToDetails = (uuid: string) => navigate(location.pathname + "/" + uuid);

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: qs.stringify({
          sortBy: sortModel,
          currentPage: page,
          searchTerm: searchText,
        }),
      },
      { replace: true },
    );
  }, [searchText, page, sortModel]);

  if (ordersResult === undefined) return <LinearProgress />;
  if (ordersResult.isFailure) return null;

  return (
    <>
      <LoadingBar loading={loading} />
      <BreadcrumbMarker title="Zamówienia" to={location.pathname} id="orders" />
      <EdiAlertsContainer onEdiChanged={() => sleep(1000).then(reload)} />
      <OrderList
        ordersQuery={ordersResult.value}
        sort={{ sortModel, setSortModel }}
        search={{ setSearchText, initialValue: initialValues.search }}
        pagination={paginationProps}
        navigateToDetails={navigateToDetails}
      />
    </>
  );
};

export interface OrderFilters {
  isApproved?: boolean;
  isCancelled?: boolean;
}

export type SetOrderFilter = (field: keyof OrderFilters, value: OrderFilters[typeof field]) => void;

const fieldToSortMap: { [key: string]: OrderSortField } = {
  // orderDate: "orderDate",
  referenceNumber: "referenceNumber",
  sellToName: "sellToName",
  billToName: "billToName",
};

const getSortByFromModel = (model: GridSortModel): SortFilter<OrderSortField>[] | undefined => {
  const result = model.find((item) => item.sort);
  if (!result || !result.sort) return undefined;
  return [
    {
      field: fieldToSortMap[result.field],
      order: result.sort.toUpperCase() as "ASC" | "DESC",
    },
  ];
};

const getInitialValuesFromQs = (locationSearch: string) => {
  const { currentPage, searchTerm, sortBy, pinnedOrderUuid } = qs.parse(locationSearch.substring(1));
  return {
    pinnedOrderUuid: typeof pinnedOrderUuid === "string" ? pinnedOrderUuid : "",
    search: typeof searchTerm === "string" ? searchTerm : "",
    page: typeof currentPage === "string" && !isNaN(parseInt(currentPage)) ? parseInt(currentPage) : 0,
    sortBy: Array.isArray(sortBy) ? sortBy : [{ field: "referenceNumber", sort: "desc" }],
  };
};

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
