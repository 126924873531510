import { userResource } from "@megarax/iam-contracts";
import { useProfilesDictionary } from "@megarax/iam-webapp";
import { useResourceProviderV2 } from "@megarax/react-client";
import { BreadcrumbMarker, QsInput, useNewRouterQsV2 } from "@megarax/ui-components";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { z } from "zod";

import { useRegionsResource } from "@megaron/megarax-v2-resource-hooks";
import { MilageReportDialogContainer } from "@megaron/megarax-v2-visit-schedule";

import { NewSalespersonDialogContainer } from "./NewSalespersonDialog";
import { SalespeopleHome } from "./SalespeopleHome";
import { useSalespeopleResource } from "./useSalespeopleResource";

interface Props {}

const qsSchema: QsInput<QueryFilters> = {
  searchText: {
    validator: z.string(),
    fallback: "",
  },
};

interface QueryFilters {
  searchText: string;
}

export const SalespeopleHomeContainer: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const salespeopleResource = useSalespeopleResource();
  const regionsResource = useRegionsResource();
  const { list } = useResourceProviderV2(userResource);

  const { qs, setQs } = useNewRouterQsV2(qsSchema);

  const openMilageReportDialog = () => navigate(`${pathname}/przejechane_kilometry`);
  const openNewItemDialog = () => navigate(`${pathname}/nowy`);

  const isMilageReportDialogOpen = pathname.endsWith("handlowcy/przejechane_kilometry");
  const isNewItemDialogOpen = pathname.endsWith("handlowcy/nowy");

  const { data: salespeople, isFetching: isSalespeopleFetching } = useQuery(
    ["salespeople"],
    salespeopleResource.listAll,
    {},
  );

  const { data: regionsDictionary, isFetching: isRegionsFetching } = useQuery(
    ["regions"],
    regionsResource.getRegionsDictionary,
    {},
  );

  const { data: allUsersDictionary } = useQuery(["allUsers"], () =>
    list({ limit: 1000 })
      .map((result) => result.items)
      .then((res) => _.keyBy(res.value ?? [], "id")),
  );

  const profilesDictionary = useProfilesDictionary(salespeople?.value?.items.map((item) => ({ id: item.userId })));

  const navigation = {
    openAddSalespersonDialog: openNewItemDialog,
    navigateToDetails: (userId: number) => navigate("/crm/pos/handlowcy/" + userId),
    navigateToPlan: (userId: number) => navigate(`/crm/pos/handlowcy/${userId}/wizyty`),
  };
  return (
    <>
      <BreadcrumbMarker title="Handlowcy" to={pathname} id="salespeople" />

      <SalespeopleHome
        search={{
          onSearchChange: (val) => setQs("searchText", val),
          value: qs.searchText,
        }}
        navigation={navigation}
        query={{
          items: salespeople?.value?.items,
          loading:
            isRegionsFetching ||
            isSalespeopleFetching ||
            profilesDictionary === undefined ||
            allUsersDictionary === undefined,
        }}
        profilesDictionary={profilesDictionary}
        regionsDictionary={regionsDictionary}
        allUsersDictionary={allUsersDictionary}
        onOpenMilageReport={openMilageReportDialog}
      />
      {isMilageReportDialogOpen && <MilageReportDialogContainer onClose={() => navigate(-1)} />}
      {isNewItemDialogOpen && (
        <NewSalespersonDialogContainer salespeople={salespeople?.value?.items} onClose={() => navigate(-1)} />
      )}
    </>
  );
};
