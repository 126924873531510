import { TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Link } from "react-router-dom-v5-compat";

interface Props {
  getDetailsLink: () => string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const NewRouterLinkRow: React.FC<Props> = ({ getDetailsLink, onMouseEnter, onMouseLeave }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes["row"]} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <td className={classes["wideCell"]}>
        <Link to={getDetailsLink()} className={classes["link"]} />
      </td>
    </TableRow>
  );
};

const useStyles = makeStyles(() => {
  return {
    link: {
      width: "100%",
      height: "100%",
      display: "block",
      zIndex: 1,
    },
    wideCell: {
      width: "100%",
      padding: "0",
      display: "block",
      height: "56px",
    },
    row: { position: "absolute", width: "100%", display: "table-row" },
  };
});
