import { costTableResource, Gtin, Month, MonthCostList, tradeItemResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import {
  BreadcrumbMarkers,
  FeedbackSnackbarContext,
  LoadingBar,
  PageTitleManager,
  useSnackbarErrorHandler,
} from "@megarax/ui-components";
import { errorMessages } from "@megarax/validators";
import _ from "lodash";
import { useContext } from "react";

import { CostsView, CostTableRow } from "./CostsView";
import { InputRow } from "./input";

export const CostsViewContainer = () => {
  const costTableProvider = useResourceProviderV2(costTableResource);
  const feedbackSnackbar = useContext(FeedbackSnackbarContext);
  const tradeItemProvider = useResourceProviderV2(tradeItemResource);
  const handleError = useSnackbarErrorHandler(errorMessages);

  const { value: tradeItemsResult, loading: tradeItemsLoading } = useAsyncLoad(() => {
    return tradeItemProvider
      .list({ limit: 100000 })
      .map((result) =>
        _(result.items)
          .keyBy((i) => i.gtin)
          .mapValues((i) => i.name)
          .value(),
      )
      .mapError(handleError);
  }, []);

  const {
    value: months,
    loading,
    reload,
  } = useAsyncLoad(
    () =>
      costTableProvider
        .retrieve()
        .mapError(handleError)
        .then((r) => r.value),
    [],
  );

  const updateCostTable = async (rows: InputRow[]) => {
    costTableProvider
      .update(undefined, inputRowsToMonthLists(rows))
      .map(async () => {
        await reload();
        feedbackSnackbar.pushMessage({
          severity: "success",
          content: "Pomyślnie zaktualizowano koszty.",
        });
      })
      .mapError(handleError);
  };

  return (
    <div>
      <PageTitleManager title="Koszty standardowe" />
      <BreadcrumbMarkers
        breadcrumbs={[
          { title: "Marże", id: "profitMargins" },
          { title: "Koszty standardowe", id: "costs" },
        ]}
      />

      <LoadingBar loading={loading || tradeItemsLoading} />

      {months ? (
        <CostsView onInput={updateCostTable} rows={monthListsToTableRows(months, tradeItemsResult?.value)} />
      ) : null}
    </div>
  );
};

type TradeItemNameMap = {
  [gtin: Gtin]: string | undefined;
};

const monthListsToTableRows = (
  monthLists: MonthCostList[],
  tradeItemNames: TradeItemNameMap | undefined,
): CostTableRow[] => {
  const gtins = _(monthLists)
    .flatMap((m) => _.keys(m.costs) as Gtin[])
    .uniq()
    .sort()
    .value();

  return gtins.map<CostTableRow>((gtin) => ({
    gtin,
    tradeItemName: tradeItemNames && tradeItemNames[gtin],
    costs: _(monthLists)
      .keyBy((m) => m.month)
      .mapValues((m) => m.costs[gtin] ?? null)
      .value(),
  }));
};

const inputRowsToMonthLists = (rows: InputRow[]): MonthCostList[] => {
  const months = _(rows)
    .flatMap((r) => _.keys(r.costs) as Month[])
    .uniq()
    .sort()
    .value();

  return months.map<MonthCostList>((month) => ({
    month,
    costs: _(rows)
      .keyBy((row) => row.gtin)
      .mapValues((row) => row.costs[month] ?? null)
      .value(),
  }));
};
