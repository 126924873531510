import { useShallowEffect } from "@mantine/hooks";
import { useState } from "react";

import { tableHeadingsList } from "./CustomerTable";

const localStorageCustomerVisibleTableColumnsKey = "customersVisibleTableColumns";

export const useCustomerVisibleTableColumns = () => {
  const [visibleTableColumns, setVisibleTableColumns] = useState<string[]>([]);

  const defaultVisibleColumns = tableHeadingsList.map(({ heading }) => heading);

  useShallowEffect(() => {
    const visibleTableColumns = localStorage.getItem(localStorageCustomerVisibleTableColumnsKey);

    const areValuesCompatible =
      visibleTableColumns &&
      JSON.parse(visibleTableColumns).every((column: string) =>
        tableHeadingsList.some(({ heading }) => heading === column),
      );

    if (visibleTableColumns && areValuesCompatible) {
      setVisibleTableColumns(JSON.parse(visibleTableColumns));
      return;
    }

    setVisibleTableColumns(defaultVisibleColumns);
    localStorage.setItem(localStorageCustomerVisibleTableColumnsKey, JSON.stringify(defaultVisibleColumns));
  }, [defaultVisibleColumns]);

  const handleVisibleColumnsChange = (columns: string[]) => {
    if (columns.length === 0) {
      setVisibleTableColumns(defaultVisibleColumns);
      localStorage.setItem(localStorageCustomerVisibleTableColumnsKey, JSON.stringify(defaultVisibleColumns));
      return;
    }

    setVisibleTableColumns(columns);
    localStorage.setItem(localStorageCustomerVisibleTableColumnsKey, JSON.stringify(columns));
  };

  return { visibleTableColumns, handleVisibleColumnsChange };
};
