import { faPoll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newUuid, toDateString, Uuid } from "@megarax/common";
import { priceReportResource, priceReportSubjectResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, FeedbackSnackbarContext, useSnackbarErrorHandler } from "@megarax/ui-components";
import { Box, Button, CardContent, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { CustomerReportsDialog } from "./CustomerReportsDialog";
import { GetPriceReportSubjects, OnSubmit } from "./ReportForm";

interface Props {
  customerUuid: Uuid;
  date: Date;
}

export const CustomerReportsCardContainer: React.FunctionComponent<Props> = ({ customerUuid, date }) => {
  const classes = useStyles();
  const feedbackSnackbar = React.useContext(FeedbackSnackbarContext);
  const priceReportProvider = useResourceProviderV2(priceReportResource);
  const priceReportSubjectProvider = useResourceProviderV2(priceReportSubjectResource);
  const [reportUuid, setReportUuid] = React.useState(newUuid());
  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const isCustomerReportsDialogOpen = location.pathname.endsWith("ankiety_cenowe");

  const { data: reports, refetch } = useQuery(["customerReports", customerUuid, date], () =>
    priceReportProvider
      .customerReports({ customerUuid, date: toDateString(date) })
      .mapError((error) => {
        handleError(error);
      })
      .then((r) => r.assertOk().value),
  );

  const getPriceReportSubjects: GetPriceReportSubjects = (search: string) =>
    priceReportSubjectProvider
      .search({ searchText: search, limit: 20, offset: 0 })
      .mapError(handleError)
      .then((r) => r.assertOk().value.items);

  const submitPriceReport: OnSubmit = (input) =>
    priceReportProvider
      .byUuid(reportUuid)
      .add(undefined, {
        customerUuid,
        ...input,
      })
      .mapError(handleError)
      .map(async (result) => {
        feedbackSnackbar.pushMessage({
          severity: "success",
          content: "Pomyślnie wysłano ankietę cenową.",
        });
        await refetch();
        setReportUuid(newUuid());
        return result;
      })
      .then();

  const openCustomerReportsDialog = () => navigate(`${location.pathname}/ankiety_cenowe`);

  const reportCountText = reports && (reports.length ? `Zapisane ceny: ${reports.length}` : `Brak cen.`);

  return (
    <Box>
      <CardContent>
        <Typography variant="overline">Ankiety cenowe</Typography>
        <Box display="flex">
          <Box>
            <Typography variant="h5">
              {reportCountText === undefined ? (
                <Skeleton variant="text" width={150} />
              ) : (
                <>
                  <FontAwesomeIcon icon={faPoll} className={classes.icon} fixedWidth />
                  {reportCountText}
                </>
              )}
            </Typography>
          </Box>
          <Box ml="auto" display="flex" flexDirection="column-reverse">
            <Button variant="contained" color="primary" onClick={openCustomerReportsDialog}>
              Dodaj
            </Button>
          </Box>
        </Box>
      </CardContent>
      {isCustomerReportsDialogOpen && (
        <CustomerReportsDialog
          reportUuid={reportUuid}
          onClose={() => navigate(-1)}
          getPriceReportSubjects={getPriceReportSubjects}
          onSubmit={submitPriceReport}
          reports={reports}
          date={date}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
}));
