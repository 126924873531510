import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { baseDocSerializer } from "../baseDoc";

export const userDocSerializer = Serializers.object({
  ...baseDocSerializer("user").fields,

  name: Serializers.string,
  email: Serializers.string,
  groups: Serializers.object({
    id: uuidSerializer,
    name: Serializers.string,
  }).array(),
  firstName: Serializers.string,
  lastName: Serializers.string,
  profilePictureUrl: Serializers.string,
});

export type UserDoc = SerializerValue<typeof userDocSerializer>;

export const userActions = {
  searchUsers: HttpAction({
    path: "/users",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      limit: Serializers.integer,
      offset: Serializers.integer,
      isArchived: Serializers.booleanFieldFilter,
    }).partial(),
    valueSerializer: Serializers.paginatedList(userDocSerializer),
    requiresAuth: true,
  }),
};
