import Decimal from "decimal.js";

import { Uuid, uuidSerializer } from "@megarax/common";
import { Gtin, gtinSerializer } from "../common";
import { MSerializers } from "@megarax/serializers";

export type ReportSubject = {
  uuid: Uuid;

  name: string;
  varietyName: string;
  varietySizeKg: Decimal;

  productGroup: string;
  gtin: Gtin | null;
};

export const reportSubjectSerializer = MSerializers.object<ReportSubject>({
  uuid: uuidSerializer,
  gtin: gtinSerializer.nullable(),
  name: MSerializers.string,
  varietyName: MSerializers.string,
  productGroup: MSerializers.string,
  varietySizeKg: MSerializers.decimal,
});

export type ReportSubjectFilters = {
  limit: number;
  offset: number;
  searchText: string;
};
