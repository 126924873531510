import { useTheme } from "@emotion/react";
import React from "react";
import { MdErrorOutline, MdInfoOutline, MdOutlineWarningAmber } from "react-icons/md";

type Props = {
  title: string;
  variant: "error" | "info" | "warning";
  onClose: () => void;
  content: React.ReactNode;
  timeout?: number;
};

export const ToastCard: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      props.onClose();
    }, props.timeout ?? 6000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const backgroundColor = {
    error: theme.colors.dangerBackground,
    info: theme.colors.primaryLight,
    warning: theme.colors.warningBackground,
  }[props.variant];

  const color = {
    error: theme.colors.danger,
    info: theme.colors.primary,
    warning: theme.colors.warning,
  }[props.variant];

  const icon = {
    error: <MdErrorOutline />,
    info: <MdInfoOutline />,
    warning: <MdOutlineWarningAmber />,
  }[props.variant];

  return (
    <div
      css={{
        padding: "1rem",
        background: backgroundColor,
        borderRadius: theme.largeBorderRadius,
        boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
      onClick={props.onClose}
    >
      <div css={{ color, fontFamily: theme.displayFontFamily, display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <div>{icon}</div> {props.title}
      </div>
      {props.content}
    </div>
  );
};
