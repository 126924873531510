import { Currency, OrderDraftLineDto, PackSizeDefaultsDto, TradeItemDto } from "@megarax/crm-contracts";
import {
  DenominatedQuantity,
  denominatedQuantityToNumber,
  DenominationDefinitions,
  formatCurrency,
  numberToDenominatedQuantity,
} from "@megarax/react-utils";
import { AreUSureWrapper, AsyncButton, IntegerInput, SkeletonLoader } from "@megarax/ui-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import { Dictionary } from "lodash";
import React, { useEffect, useState } from "react";

import { PromoPriceInput } from "../PromoPriceInput";
import { EditLineInput } from "./EditItemDialogContainer";

interface Props {
  closeDialog: () => void;
  packSize: PackSizeDefaultsDto | null;
  editLine: (input: EditLineInput) => Promise<void>;
  removeLine: () => Promise<void>;
  line: OrderDraftLineDto;
  tradeItemsDictionary: Dictionary<TradeItemDto> | undefined;
  currency: Currency;
}

type Quantities = DenominatedQuantity<string>;

const initialQuantities = {
  item: 0,
  box: 0,
  layer: 0,
  pallet: 0,
};

export const EditItemDialog: React.FC<Props> = ({
  closeDialog,
  line,
  editLine,
  removeLine,
  packSize,
  tradeItemsDictionary,
  currency,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [quantities, setQuantities] = useState<Quantities>(initialQuantities);
  const [promoPrice, setPromoPrice] = useState<Decimal | null>(line.promoPrice);
  const [quantity, setQuantity] = useState<number>(line.quantity);

  const onQuantityChange = (fieldName: keyof Quantities) => (value: number) =>
    setQuantities({ ...quantities, [fieldName]: value });

  const recount = () => {
    const defs = getDefinitionsFromPackSize(packSize);
    setQuantities(numberToDenominatedQuantity(defs)(quantity));
  };

  const onSubmit = () =>
    editLine({
      quantity,
      promoPrice,
    }).then(closeDialog);

  useEffect(() => {
    setQuantity(denominatedQuantityToNumber(getDefinitionsFromPackSize(packSize))(quantities));
  }, [quantities]);

  useEffect(() => {
    recount();
  }, []);

  return (
    <Dialog open={true} onClose={closeDialog} fullScreen={isMobile}>
      <DialogTitle>
        <SkeletonLoader
          asyncData={tradeItemsDictionary}
          component={(tradeItemsDictionary) => (
            <Typography variant="inherit">{tradeItemsDictionary[line.item.gtin].name}</Typography>
          )}
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.quantities}>
          {packSize?.pallet && (
            <IntegerInput
              label="Palety"
              helperText={`Sztuk na palecie: ${packSize.pallet}`}
              variant="outlined"
              value={quantities.pallet}
              onChange={onQuantityChange("pallet")}
            />
          )}
          {packSize?.layer && (
            <IntegerInput
              label="Warstwy"
              helperText={`Sztuk na warstwie: ${packSize.layer}`}
              variant="outlined"
              value={quantities.layer}
              onChange={onQuantityChange("layer")}
            />
          )}
          {packSize?.box && (
            <IntegerInput
              label="Kartony"
              helperText={`Sztuk w pudle: ${packSize.box}`}
              variant="outlined"
              value={quantities.box}
              onChange={onQuantityChange("box")}
            />
          )}

          <IntegerInput label="Sztuki" variant="outlined" value={quantities.item} onChange={onQuantityChange("item")} />
          <div className={classes.row}>
            <Typography>{`Sztuk razem: ${quantity}`}</Typography>
            <Button onClick={recount}>Przelicz</Button>
          </div>
        </div>
        <div>
          <PromoPriceInput value={promoPrice} onChange={setPromoPrice} />

          <Typography className={classes.sumText}>
            {sumNetPriceText({
              netPricePerUnit: line.finalPrice,
              promoPrice,
              quantity,
              currency,
            })}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.deleteBtn}>
          <AreUSureWrapper action={() => removeLine().then(closeDialog)}>
            <Button color="secondary">Usuń</Button>
          </AreUSureWrapper>
        </div>
        <Button onClick={closeDialog}>Anuluj</Button>
        <AsyncButton asyncAction={onSubmit} color="primary">
          Zapisz
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: any) => {
  return {
    content: {
      width: "300px",
      [theme.breakpoints.down("sm")]: {
        boxSizing: "border-box",
        padding: theme.spacing(2),
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
    orderableItem: {
      marginBottom: theme.spacing(2),
    },
    quantities: {
      margin: theme.spacing(2, 0),
      "& > .MuiFormControl-root": {
        margin: theme.spacing(1, 0),
      },
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sumText: {
      marginTop: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium as any,
    },
    deleteBtn: {
      display: "block",
      marginRight: "auto",
    },
  };
});

const getDefinitionsFromPackSize = (packSize: PackSizeDefaultsDto | null): DenominationDefinitions<string> => {
  return {
    item: 1,
    ...(packSize?.box && { box: packSize.box }),
    ...(packSize?.layer && { layer: packSize.layer }),
    ...(packSize?.pallet && { pallet: packSize.pallet }),
  };
};

const sumNetPriceText = ({
  netPricePerUnit,
  promoPrice,
  quantity,
  currency,
}: {
  netPricePerUnit: Decimal;
  promoPrice: Decimal | null;
  quantity: number;
  currency: Currency;
}) => {
  const unitPrice = promoPrice ?? netPricePerUnit;
  const total = unitPrice.mul(quantity);
  return `Wartość netto: ${formatCurrency(total.toNumber(), currency)} (${formatCurrency(
    unitPrice.toNumber(),
    currency,
  )}/szt.)`;
};
