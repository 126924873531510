import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import React from "react";

import { NewRouterDialog, useNewRouterDialog } from "../../../display";
import { ResponsiveSelectProps } from "./ResponsiveSelect";

interface Props<T, V> extends ResponsiveSelectProps<T, V> {
  search: {
    value: string;
    onTextChange: (text: string) => void;
  };
  onValueChange: (newValue: T | null) => void;
  loading: boolean;
  options: T[];
  selectedValue: T | null;
  renderOption?: {
    desktop: (option: T) => React.ReactNode;
    mobile: (option: T) => React.ReactNode;
  };
}

export const NewRouterMobileSelect = <T, V>(props: Props<T, V>) => {
  const classes = useStyles();
  const dialog = useNewRouterDialog(`${_.camelCase(_.deburr(props.label))}Input`);

  return (
    <>
      <NewRouterDialog name={dialog.name} fullScreen>
        <Paper className={classes.paper} square={true} elevation={1}>
          <InputBase
            autoFocus
            fullWidth
            value={props.search.value}
            onChange={(e) => props.search.onTextChange(e.target.value)}
            placeholder="Szukaj..."
            startAdornment={<FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faSearch} />}
          />
        </Paper>
        <Box className={classes.overflow}>
          <List>
            {props.loading && (
              <ListItem dense={true}>
                <Typography color="textSecondary">Ładowanie...</Typography>
              </ListItem>
            )}
            {props.options.map((option) => (
              <ListItem
                dense={true}
                button
                onClick={() => {
                  dialog.close();
                  props.onValueChange(option);
                }}
                key={props.getKey(option)}
              >
                <ListItemIcon>
                  <Radio
                    checked={props.selectedValue ? props.getKey(props.selectedValue) === props.getKey(option) : false}
                  />
                </ListItemIcon>
                {props.renderOption ? (
                  props.renderOption.mobile(option)
                ) : (
                  <ListItemText primary={props.getLabel(option)} />
                )}
              </ListItem>
            ))}
            {!props.loading && props.options.length === 0 && (
              <ListItem dense={true}>
                <Typography color="textSecondary">Brak opcji</Typography>
              </ListItem>
            )}
          </List>
        </Box>
      </NewRouterDialog>
      <TextField
        label={props.label}
        value={props.selectedValue ? props.getLabel(props.selectedValue) : ""}
        variant={props.variant}
        onMouseDownCapture={(e) => {
          e.preventDefault();
        }}
        error={Boolean(props.error)}
        helperText={props.helperText ?? props.error}
        onClickCapture={() => dialog.open()}
        fullWidth
      >
        Wybierz
      </TextField>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: "0.5rem 1rem",
      boxSizing: "border-box",
    },
    overflow: {
      overflow: "auto",
    },
  };
});
