import { Uuid } from "@megarax/common";
import { DesignateHomeInput, RegionSimple, SalespersonDto, SetManagerInput } from "@megarax/crm-contracts";
import { UserSimple } from "@megarax/iam-contracts";
import { UserProfile } from "@megarax/iam-webapp";
import { InlineEditableRow, ResponsiveSelect, useInlineFieldManager } from "@megarax/ui-components";
import { Box, Divider } from "@mui/material";
import { Dictionary } from "lodash";
import React from "react";

import { Result } from "@megaron/result";

import { DesignateHomeRow } from "./DesignateHomeRow";
import { TimeEditRow } from "./TimeEditRow";

interface Props {
  regionsDictionary: Dictionary<RegionSimple>;
  salesperson: SalespersonDto;
  managerProfile: UserProfile | null;
  reassignRegion: (regionUuid: Uuid) => Promise<Result<void, void>>;
  setWorkStartTime: (workStartTime: number) => Promise<Result<void, void>>;
  getRegions: (search: string) => Promise<RegionSimple[]>;
  designateHome: (input: DesignateHomeInput) => Promise<Result<void, void>>;
  setManager: (input: SetManagerInput) => Promise<Result<void, void>>;
  getUsers: (searchText: string) => Promise<UserSimple[]>;
}

export const EditableProps: React.FC<Props> = ({
  regionsDictionary,
  salesperson,
  managerProfile,
  reassignRegion,
  setWorkStartTime,
  getRegions,
  setManager,
  designateHome,
  getUsers,
}) => {
  const fields = useInlineFieldManager({
    regionUuid: {
      currentValue: salesperson.regionUuid,
      editQuery: reassignRegion,
    },
    managerId: {
      currentValue: salesperson.managerId,
      editQuery: (managerId: number) => setManager({ managerId }),
    },
  });

  const regionName = regionsDictionary[fields["regionUuid"].currentValue]?.name ?? "Nieznany region";

  const managerName = managerProfile ? managerProfile.user.email : "Brak";

  return (
    <Box py={1}>
      <Box px={3}>
        <InlineEditableRow
          {...fields["regionUuid"]}
          label="Region"
          currentValue={regionName}
          InputNode={
            <ResponsiveSelect
              initialValue={regionsDictionary[fields["regionUuid"].newValue]}
              value={fields["regionUuid"].newValue}
              variant="standard"
              onChange={(val) => fields["regionUuid"].onEditValueChange(val ?? null)}
              getOptions={getRegions}
              label="Region"
              getKey={(o) => o.uuid}
              getLabel={(o) => o.name}
              getValue={(o) => o.uuid}
            />
          }
        />
      </Box>

      <Divider />
      <Box px={3}>
        <InlineEditableRow
          {...fields["managerId"]}
          label="Kierownik"
          currentValue={managerName}
          InputNode={
            <ResponsiveSelect
              initialValue={managerProfile?.user}
              value={fields["managerId"].newValue}
              variant="standard"
              onChange={(val) => fields["managerId"].onEditValueChange(val ?? null)}
              getOptions={getUsers}
              label="Kierownik"
              getKey={(o) => o.id}
              getLabel={(o) => o.email}
              getValue={(o) => o.id}
            />
          }
        />
      </Box>
      <Divider />
      <Box px={3}>
        <TimeEditRow setWorkStartTime={setWorkStartTime} workStartTime={salesperson.workStartTime} />
      </Box>
      <Divider />
      <DesignateHomeRow
        homeLocation={salesperson.homeLocation}
        address={salesperson.address}
        designateHome={designateHome}
      />
    </Box>
  );
};
