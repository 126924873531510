import { Dialog as MuiDialog, DialogProps } from "@mui/material";
import qs from "qs";
import React from "react";
import { useLocation } from "react-router-dom";

import { useDialog } from "./useDialog";

type MuiDialogProps = Pick<DialogProps, "fullWidth" | "maxWidth" | "fullScreen">;

interface Props extends MuiDialogProps {
  name: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const Dialog: React.FC<Props> = ({ children, name, onClose, ...muiProps }) => {
  const location = useLocation();
  const dialog = useDialog(name);

  const open = qs.parse(location.search.substring(1))[name] !== undefined;

  return (
    <MuiDialog open={open} onClose={onClose ? onClose : dialog.close} {...muiProps}>
      {children}
    </MuiDialog>
  );
};
