import { useTheme } from "@emotion/react";
import React from "react";
import tinycolor from "tinycolor2";

type Props = {
  children?: React.ReactNode;
  className?: string;
  variant?: "primary" | "secondary";
  color?: string;
  onClick?: () => void;
};

export const Card: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const baseColor = props.color ?? theme.colors.primary;

  const background = {
    primary: baseColor,
    secondary: "white",
  }[props.variant ?? "primary"];
  const color = {
    primary: theme.colors.background,
    secondary: "black",
  }[props.variant ?? "primary"];

  const gradient = `radial-gradient(at 35% top, rgba(255, 255, 255, 0.15), transparent)`;

  const border = props.variant === "secondary" ? `1px solid ${tinycolor(baseColor).setAlpha(0.1)}` : "none";

  return (
    <div
      onClick={props.onClick}
      className={props.className}
      css={{
        background: `${gradient}, ${background}`,
        color: color,
        padding: "1rem",
        borderRadius: theme.smallBorderRadius,
        border,
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      {props.children}
    </div>
  );
};
