import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { TextInput } from "@megarax/ui-components";

interface Props {
  initialName: string;
  onChangeName: (name: string) => Promise<void>;
  onClose: () => void;
}

export const RenameCustomerDialog: React.FunctionComponent<Props> = ({
  initialName,
  onChangeName,
  onClose,
}) => {
  const classes = useStyles();
  const [name, setName] = React.useState<string>(initialName);

  const save = async () => {
    await onChangeName(name);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Zmień nazwę</DialogTitle>
      <DialogContent className={classes.content}>
        <TextInput value={name} variant="standard" onChange={setName} label="Nazwa" fullWidth />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={save}>
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: "250px",
  },
}));
