import { Avatar, Box, Paper } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";

import { getParsedMessage, getUnparsedMessageCaretPosition } from "./mentionRegex";

interface Props {
  mentionableUsers: {
    id: number;
    name: string;
    surname: string;
    pictureUrl: string | null;
  }[];
  setContent: (input: (prev: string) => string) => void;
  inputRef: HTMLInputElement | null;
}

export const MentionableUsersList: React.FC<Props> = ({
  mentionableUsers,
  setContent,
  inputRef,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [selected, setSelected] = React.useState<number>(0);

  const pasteMention = (selected: number) => {
    const match = `${mentionableUsers[selected].name} ${mentionableUsers[selected].surname}`.trim();
    setContent((prev) => {
      const parsedContent = getParsedMessage(prev);
      const text = `[@${match}]{${mentionableUsers[selected].id}} `;
      const caret = inputRef?.selectionStart;
      if (!caret) return parsedContent + " " + text;
      const unparsedCaret = getUnparsedMessageCaretPosition(prev, caret);

      return (
        prev.slice(0, unparsedCaret).split(new RegExp("(@)")).slice(0, -2).join("") +
        text +
        prev.slice(unparsedCaret)
      );
    });
    inputRef?.focus();
  };
  const onKeyDown = (e: KeyboardEvent) => {
    e.stopImmediatePropagation();
    e.stopPropagation();
    if (e.key === "ArrowDown") setSelected((prev) => (prev + 1) % mentionableUsers.length);
    if (e.key === "ArrowUp")
      setSelected((prev) => (prev - 1 < 0 ? mentionableUsers.length - 1 : prev - 1));
    if (e.key === "Enter") pasteMention(selected);
  };

  React.useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [selected, mentionableUsers]);

  return (
    <Paper>
      <Box mb={1}>
        {mentionableUsers.map((user, idx) => {
          return (
            <Box
              display="flex"
              py={1}
              px={2}
              alignItems="center"
              key={user.id}
              className={classes.mentionableUser}
              onClick={() => pasteMention(idx)}
              sx={{
                backgroundColor: idx === selected ? theme.palette.grey[100] : "white",
              }}
            >
              <Avatar
                alt={user.name}
                src={user.pictureUrl ?? undefined}
                sx={{ width: "1.5rem", height: "1.5rem", mr: 1 }}
              />
              {`${user.name} ${user.surname}`.trim()}
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    mentionableUser: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  };
});
