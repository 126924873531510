import { css, useTheme } from "@emotion/react";
import React from "react";
import { MdFavorite, MdNotInterested, MdOutlineQuestionMark } from "react-icons/md";

import { InterestLevel } from "@megaron/crm-contracts";
import { Button } from "@megaron/dash-form";

interface Props {
  onSelect: (interestLevel: InterestLevel | null) => void;
  selectedInterest: InterestLevel | null;
}

export const InterestLevelButtons: React.FunctionComponent<Props> = ({ onSelect, selectedInterest }) => {
  const theme = useTheme();
  const buttons = [
    { icon: <MdNotInterested size="1.5rem" />, name: "Nie dotyczy", value: "notInterested" },
    { icon: <MdOutlineQuestionMark size="1.5rem" />, name: "Brak danych", value: null },
    { icon: <MdFavorite size="1.5rem" />, name: "Zainteresowany", value: "interested" },
  ];

  const buttonStyle = (isSelected: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      borderRadius: theme.largeBorderRadius,
      cursor: "pointer",
      width: "100%",
      height: "100px",
      borderColor: theme.colors.primary,
      gap: "0.75rem",
      fontSize: "0.75rem",
    });

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(80px, 1fr))",
        gap: "0.75rem",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {buttons.map((button) => (
        <Button
          variant={selectedInterest === button.value ? "primary" : "outline"}
          key={button.value}
          css={buttonStyle(selectedInterest === button.value)}
          onClick={() => onSelect(button.value)}
          icon={button.icon}
        >
          {button.name}
        </Button>
      ))}
    </div>
  );
};
