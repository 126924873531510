import { Theme, ThemeProvider } from "@emotion/react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { QueryClient, QueryClientProvider } from "react-query";

import { createTheme, DefaultGlobalStyles } from "@megaron/dash-default-theme";
import { ToastContextProvider } from "@megaron/dash-toast";
import { ConfigContextProvider } from "@megaron/dev";
import { IamAuthContextProvider } from "@megaron/iam-auth-react";
import { NotificationsProvider, ProfilesProvider, WebPushContextProvider } from "@megaron/megarax-webapps";

import { ApiServicesProvider } from "./ApiServicesProvider";
import { AppRouter } from "./AppRouter";
import { useLegacyMegaraxRedirects } from "./useLegacyMegaraxRedirects";

const theme: Theme = createTheme("273");
const defaultTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: `#2F2F2F`,
    primaryLight: `#E7E4EA`,
    background: `#F7F4FA`,
    hover: `#AEABAB`,
    selectedBackground: `#AEABAB`,
  },
};

export const App = () => {
  const posthogKey = process.env.NX_PUBLIC_POSTHOG_KEY;
  useLegacyMegaraxRedirects();

  if (typeof window !== "undefined" && posthogKey) {
    posthog.init(posthogKey, {
      api_host: process.env.NX_PUBLIC_POSTHOG_URL,
      persistence: "memory",
      person_profiles: "always",
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.debug();
      },
    });
  }

  return (
    <PostHogProvider client={posthog}>
      <QueryClientProvider client={new QueryClient()}>
        <ConfigContextProvider>
          <DefaultGlobalStyles />
          <ThemeProvider theme={defaultTheme}>
            <ToastContextProvider>
              <IamAuthContextProvider>
                {(auth) => (
                  <ApiServicesProvider auth={auth}>
                    <WebPushContextProvider isAuthLoaded={auth.isLoaded}>
                      <ProfilesProvider isAuthLoaded={auth.isLoaded}>
                        <NotificationsProvider isAuthLoaded={auth.isLoaded}>
                          <AppRouter isAuthLoaded={auth.isLoaded} />
                        </NotificationsProvider>
                      </ProfilesProvider>
                    </WebPushContextProvider>
                  </ApiServicesProvider>
                )}
              </IamAuthContextProvider>
            </ToastContextProvider>
          </ThemeProvider>
        </ConfigContextProvider>
      </QueryClientProvider>
    </PostHogProvider>
  );
};
