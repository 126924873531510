import { Uuid } from "@megarax/common";
import { ChecklistTemplateSimple, Tag } from "@megarax/crm-contracts";
import { MultiSelectChip, NewItemFab, SearchBar } from "@megarax/ui-components";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Container, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

interface Props {
  tags: Tag[];
  checklists: ChecklistTemplateSimple[];
  filters: {
    selectedTags: string[];
    setSelectedTags: (tags: string[]) => void;
    search: string;
    setSearch: (seach: string) => void;
  };
  openNewChecklistDialog: () => void;
  openEditChecklistDialog: (uuid: Uuid) => void;
}
export const ChecklistTemplatesHome: React.FC<Props> = ({
  tags,
  checklists,
  filters,
  openNewChecklistDialog,
  openEditChecklistDialog,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sortedChecklists = checklists.sort((a, b) => b.tags.length - a.tags.length);

  return (
    <Box height="100%" sx={{ backgroundColor: theme.palette.grey[100] }}>
      <Container maxWidth="md" disableGutters>
        <Box display="flex" justifyContent="space-between" py={2} px={2}>
          <Box
            display="flex"
            alignItems={isMobile ? "flex-start" : "center"}
            sx={{ gap: 1 }}
            flexDirection={isMobile ? "column" : "row"}
          >
            <SearchBar onSearchChange={filters.setSearch} initialValue={filters.search} />
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <FilterListIcon fontSize="medium" />
              <MultiSelectChip
                label="Tagi"
                value={filters.selectedTags}
                onChange={filters.setSelectedTags}
                options={tags.map((tag) => ({ label: tag.label, value: tag.label }))}
              />
            </Box>
          </Box>
          <NewItemFab text="nowy" onClick={openNewChecklistDialog} />
        </Box>
        <Box py={2} px={2} display="flex" flexDirection={"column"} sx={{ gap: 1 }}>
          {sortedChecklists.map((checklist) => {
            return (
              <Paper key={checklist.uuid} onClick={() => openEditChecklistDialog(checklist.uuid)}>
                <Box px={3} pt={1} sx={{ cursor: "pointer" }}>
                  <Typography variant="subtitle2">{checklist.name}</Typography>
                </Box>
                <Box px={3} py={1} display="flex" sx={{ gap: 1, cursor: "pointer" }}>
                  {checklist.tags
                    .sort((a, b) => a.localeCompare(b))
                    .map((tag) => {
                      return (
                        <Box
                          key={checklist.uuid + tag}
                          sx={{
                            display: "inline-block",
                            color: theme.palette.grey[900],
                            backgroundColor: theme.palette.grey[200],
                            px: 2,
                            py: 0.5,
                            fontWeight: 700,
                            fontSize: "0.8rem",
                            borderRadius: "5px",
                          }}
                        >
                          <Box display="flex" justifyContent="center" alignItems="center">
                            {tag}
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              </Paper>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
};
