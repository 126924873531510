import { validateUuid } from "@megarax/common";
import _ from "lodash";
import qs from "query-string";
import { useLocation } from "react-router-dom-v5-compat";

import { useCustomer } from "@megaron/megarax-v2-resource-hooks";
import { Failure, Ok } from "@megaron/result";

import { Customer } from "./NewDraftOrder";

export const useInitialCustomer = () => {
  const { search } = useLocation();
  const searchParams = qs.parse(search);

  const uuidResult = Ok(searchParams.initialCustomerUuid)
    .flatMap((uuidCandidate) => (_.isString(uuidCandidate) ? Ok(uuidCandidate) : Failure("CustomerNotSpecified")))
    .flatMap(validateUuid);

  const customer = useCustomer(uuidResult.value);

  return uuidResult.map<Customer | null>(() => customer ?? null);
};
