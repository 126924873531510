import { useEffect, useRef } from "react";

import { sleep } from "@megaron/utils";

export const useBeep = () => {
  const context = useRef(new AudioContext());

  return async (frequency: number) => {
    if (context.current.state === "suspended") await context.current.resume();

    const oscillator = context.current.createOscillator();

    oscillator.type = "sine";
    oscillator.frequency.value = frequency;
    oscillator.connect(context.current.destination);
    oscillator.start();
    await sleep(100);
    oscillator.stop();
  };
};
