import { Failure, Ok } from "@megaron/result";

import { Serializer } from "./serializer";

type SerializersWithExtensions<T> = Serializer<T> & SerializerExtensions<T>;

const nullableExtension = <T>(serializer: Serializer<T>): SerializersWithExtensions<T | null> => ({
  ...serializer,
  serialize: (value) => {
    if (value === null) return value;
    return serializer.serialize(value);
  },
  deserialize: (raw) => {
    if (raw === null) return Ok(raw);
    return serializer.deserialize(raw);
  },
  ...serializerExtensions(),
});

export const optionalExtension = <T>(serializer: Serializer<T>): SerializersWithExtensions<T | undefined> => ({
  ...serializer,
  serialize: (value) => {
    if (value === undefined) return value;
    return serializer.serialize(value);
  },
  deserialize: (raw) => {
    if (raw === undefined) return Ok(raw);
    return serializer.deserialize(raw);
  },
  ...serializerExtensions(),
});

export const jsonStringExtension = <T>(serializer: Serializer<T>): SerializersWithExtensions<T> => {
  const wrapped = jsonStringSerializer(serializer);

  return {
    ...serializer,
    serialize: wrapped.serialize,
    deserialize: wrapped.deserialize,
    ...serializerExtensions(),
  };
};

export const arrayExtension = <T>(serializer: Serializer<T>): SerializersWithExtensions<T[]> => {
  const wrapped = arraySerializer(serializer);

  return {
    ...serializer,
    serialize: wrapped.serialize,
    deserialize: wrapped.deserialize,
    ...serializerExtensions(),
  };
};

export interface SerializerExtensions<T> {
  nullable: () => SerializersWithExtensions<T | null>;
  optional: () => SerializersWithExtensions<T | undefined>;
  asJsonString: () => SerializersWithExtensions<T>;
  array: () => SerializersWithExtensions<T[]>;
}

export const serializerExtensions = <T>(): SerializerExtensions<T> => ({
  nullable(this: any) {
    return nullableExtension(this);
  },
  optional(this: any) {
    return optionalExtension(this);
  },
  asJsonString(this: any) {
    return jsonStringExtension(this);
  },
  array(this: any) {
    return arrayExtension(this);
  },
});

const arraySerializer = <T>(elementSerializer: Serializer<T>): Serializer<T[]> & SerializerExtensions<T[]> => ({
  serialize: (items) => items.map(elementSerializer.serialize),
  deserialize: (items) => {
    if (!Array.isArray(items)) return Failure("NotAnArray");

    const result: T[] = [];
    const errors: { [index: number]: unknown } = {};
    let hasErrors = false;

    for (const [index, item] of items.entries()) {
      const deserialized = elementSerializer.deserialize(item);
      if (deserialized.isOk) {
        result.push(deserialized.value);
      } else {
        hasErrors = true;
        errors[index] = deserialized.error;
      }
    }

    if (hasErrors) return Failure(errors);
    return Ok(result);
  },
  ...serializerExtensions(),
});

const jsonStringSerializer = <T>(contentSerializer: Serializer<T>): Serializer<T> & SerializerExtensions<T> => ({
  serialize: (value) => JSON.stringify(contentSerializer.serialize(value)),
  deserialize: (raw) => {
    if (typeof raw !== "string") return Failure("NotAString");
    try {
      const parsed = JSON.parse(raw);
      return contentSerializer.deserialize(parsed);
    } catch (e) {
      return Failure("InvalidJson");
    }
  },
  ...serializerExtensions(),
});
