import { Currency, Gtin, PriceListProposalDto } from "@megarax/crm-contracts";
import { ProfilesDictionary } from "@megarax/iam-webapp";
import { PageTitleManager } from "@megarax/ui-components";
import Decimal from "decimal.js";
import React from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom-v5-compat";

import { getNewProposalUrl, PROPOSAL_NEW } from "../../routes";
import { TradeItemDictionary } from "../PriceListDetailsContainer";
import { ConfirmCsvImportDialog } from "./ConfirmCsvImportDialog/ConfirmCsvImportDialog";
import { InputRow } from "./csvInput";
import { NewProposalView } from "./NewProposal";
import { ProposalsList } from "./ProposalsList";

interface Props {
  permissions: {
    create: boolean;
    approve: boolean;
    reject: boolean;
  };
  isMyProposal: (userId: number) => boolean;
  data: {
    priceListUuid: string;

    currency: Currency;
    tradeItems: TradeItemDictionary | null;
    profiles: ProfilesDictionary;
    proposals: PriceListProposalDto[];
  };
  proposalActions: {
    reloadProposals: () => void;
    retractProposal: (proposalUuid: string) => Promise<void>;
    rejectProposal: (proposalUuid: string) => Promise<void>;
    approveProposal: (proposalUuid: string) => Promise<void>;
    proposeChanges: (input: {
      changes: { gtin: Gtin; netPrice: Decimal | null }[];
      activeFrom: Date | null;
      comment: string;
    }) => Promise<void>;
  };
}

export const ProposalsView: React.FC<Props> = ({ permissions, isMyProposal, data, proposalActions }) => {
  const params = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  const openNewProposalDialog = () => navigate(getNewProposalUrl(params.uuid || "", "propozycje"));

  const newProposalMatchPath = useMatch(PROPOSAL_NEW);

  const [importRows, setImportRows] = React.useState<InputRow[] | null>(null);

  return (
    <>
      <PageTitleManager title="Propozycje cenników" />

      {newProposalMatchPath && (
        <NewProposalView
          reloadProposals={proposalActions.reloadProposals}
          currency={data.currency}
          tradeItems={data.tradeItems}
        />
      )}
      {importRows !== null ? (
        <ConfirmCsvImportDialog
          onClose={() => setImportRows(null)}
          onProposeChanges={proposalActions.proposeChanges}
          rows={importRows}
        />
      ) : null}
      <ProposalsList
        data={data}
        proposalActions={proposalActions}
        permissions={permissions}
        isMyProposal={isMyProposal}
        onOpenNewProposalDialog={openNewProposalDialog}
        onCsvImport={setImportRows}
      />
    </>
  );
};
