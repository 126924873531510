import { Uuid } from "@megarax/common";
import { ChecklistTemplateDetail, SaveTemplateInput } from "@megarax/crm-contracts";
import { useDragAndDrop } from "@megarax/react-utils";
import { TextInput } from "@megarax/ui-components";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Button, Dialog, Divider, InputBase, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useMutation } from "react-query";
import { v4 } from "uuid";

import { Ok, Result } from "@megaron/result";

import { TagSelectRow } from "./TagSelectRow";

interface Props {
  saveChecklist: (uuid: Uuid, input: SaveTemplateInput) => Promise<Result<void, void>>;
  close: () => void;
  open: boolean;
  initialTemplate: ChecklistTemplateDetail;
  disableEdit?: boolean;
}

export const ChecklistTemplateCreatorDialog: React.FC<Props> = ({
  saveChecklist,
  open,
  close,
  initialTemplate,
  disableEdit,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [newTasks, setNewTasks] = useState<{ label: string; uuid: Uuid; isEdit: boolean }[]>(
    initialTemplate.tasks.map((task) => ({ label: task.label, uuid: task.uuid, isEdit: false })),
  );

  const [newTemplate, setNewTemplate] = useState<{ uuid: Uuid; tags: string[]; name: string }>(initialTemplate);

  const { onDragEnd } = useDragAndDrop(newTasks, setNewTasks);

  const save = useMutation(
    () =>
      saveChecklist(newTemplate.uuid, {
        ...newTemplate,
        tasks: newTasks.map((task, idx) => ({ ...task, priority: idx })),
      }),
    {
      onSuccess: () => {
        close();
        setNewTemplate({
          uuid: v4() as Uuid,
          tags: [],
          name: "Nowy szablon",
        });
        setNewTasks([{ label: "Nowe zadanie", uuid: v4() as Uuid, isEdit: false }]);
      },
    },
  );

  const addTag = (tag: string) =>
    new Promise<Result<void, void>>((resolve) => {
      setNewTemplate({ ...newTemplate, tags: [...newTemplate.tags, tag] });
      resolve(Ok());
    });

  const removeTag = (tag: string) =>
    new Promise<Result<void, void>>((resolve) => {
      setNewTemplate({
        ...newTemplate,
        tags: newTemplate.tags.filter((tg) => tg !== tag),
      });
      resolve(Ok());
    });

  const getItemStyle = (isDragging: boolean, draggableStyle: any, isEditable: boolean) => ({
    userSelect: "none",
    background: isDragging ? theme.palette.grey[100] : isEditable && disableEdit ? "#f0faf3" : "white",
    borderRadius: "4px",
    ...draggableStyle,
  });

  const toggleItemEdit = (idx: number) => {
    setNewTasks(
      newTasks.map((task, index) => ({
        ...task,
        isEdit: (idx === index ? !task.isEdit : false) && isEditable(task.uuid),
      })),
    );
  };

  const isEditable = (itemUuid: string) => {
    if (!disableEdit) return true;
    return !initialTemplate.tasks.find((task) => task.uuid === itemUuid);
  };

  const onItemChange = (idx: number) => (value: string) => {
    const tasks = _.cloneDeep(newTasks);
    tasks[idx].label = value;
    setNewTasks(tasks);
  };

  const onAddNew = () => {
    setNewTasks([
      ...newTasks.map((task, index) => ({ ...task, isEdit: false })),
      { label: "", uuid: v4() as Uuid, isEdit: true },
    ]);
  };

  const onDeleteItem = (idx: number) => {
    setNewTasks(newTasks.filter((task, index) => idx !== index).map((task, index) => ({ ...task, isEdit: false })));
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="md" fullScreen={isMobile}>
      <Box sx={{ backgroundColor: theme.palette.grey[50] }} display="flex" flexDirection="column" height="100%">
        <Box px={2} py={1} sx={{ backgroundColor: "white" }}>
          <TagSelectRow
            currentTags={newTemplate.tags.map((tag) => ({ label: tag }))}
            addTag={addTag}
            removeTag={removeTag}
          />
        </Box>
        <Divider />
        <Box px={2} py={2} sx={{ backgroundColor: "white" }}>
          <TextInput
            fullWidth
            label="Nazwa"
            variant="standard"
            value={newTemplate.name}
            onChange={(name) => setNewTemplate({ ...newTemplate, name })}
          />
        </Box>
        <Divider />
        <Box
          sx={{ flexGrow: 1 }}
          position="relative"
          flexDirection="column"
          justifyContent="space-between"
          display="flex"
        >
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <DragDropContext onDragEnd={onDragEnd}>
              {newTasks.length > 0 && (
                <Box px={2} pt={2}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {newTasks.map((item, index) => (
                          <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                            {(provided, snapshot) => (
                              <Paper
                                onClick={() => !item.isEdit && toggleItemEdit(index)}
                                sx={{
                                  color: theme.palette.grey[900],
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  px: 3,
                                  py: 2,
                                  mb: 1,
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                  isEditable(item.uuid),
                                )}
                              >
                                <Box width="100%">
                                  {item.isEdit ? (
                                    <InputBase
                                      sx={{
                                        width: "100%",
                                        px: 1,
                                        py: 0,
                                      }}
                                      autoFocus
                                      multiline
                                      value={newTasks[index].label}
                                      onChange={(e) => onItemChange(index)(e.target.value)}
                                      onBlur={() => toggleItemEdit(index)}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        display: "block",
                                        minHeight: "23px",
                                        whiteSpace: "pre-line",
                                        width: "100%",
                                        cursor: isEditable(item.uuid) ? "pointer" : "inherit",
                                      }}
                                    >
                                      {item.label}
                                    </div>
                                  )}
                                </Box>
                                <Box display="flex" sx={{ gap: 1, pl: 2 }}>
                                  <DeleteIcon
                                    fontSize="small"
                                    sx={{ cursor: "pointer", color: theme.palette.grey[500] }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDeleteItem(index);
                                    }}
                                  />

                                  <DragIndicatorIcon fontSize="small" color="disabled" />
                                </Box>
                              </Paper>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Box>
              )}
            </DragDropContext>
            <Box px={2} py={1}>
              <Button sx={{ ...buttonSx, color: theme.palette.grey[500] }} onClick={onAddNew}>
                + Dodaj zadanie
              </Button>
            </Box>
          </Box>
          <Box
            px={2}
            py={1}
            display="flex"
            justifyContent="flex-end"
            position="sticky"
            bottom="0"
            sx={{
              gap: 1,
              backgroundColor: "white",
              borderTop: "1px solid lightgrey",
            }}
          >
            <Button onClick={() => close()} disabled={save.isLoading}>
              Anuluj
            </Button>
            <Button
              color="primary"
              onClick={() => save.mutate()}
              disabled={save.isLoading || newTasks.length === 0 || newTemplate.tags.length === 0}
            >
              Zapisz
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const buttonSx = {
  width: "100%",
  py: 0.5,
  px: 2,
  fontSize: "0.9rem",
  fontWeight: 400,
  textAlign: "left",
  justifyContent: "start",
  textTransform: "none",
};
