import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { DateString, validateDateString } from "@megarax/common";
import { Serializers } from "@megarax/serializers";
import { BreadcrumbMarker } from "@megarax/ui-components";

import { DayViewContainer } from "./DayView/DayViewContainer";
import { WeekViewContainer } from "./WeekView";

interface Props {
  userId: number;
}

export const SalespersonsVisitsSwitch: React.FunctionComponent<Props> = ({ userId }) => {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const goToDay = (dateStr: DateString) => history.push(`${url}/dzien/${dateStr}`);
  const goToWeek = (dateStr: DateString) => history.push(`${url}/tydzien/${dateStr}`);
  const goToTradeRoute = (uuid: string) => history.push(`/crm/trasy/${uuid}`);

  return (
    <Switch>
      <Route
        path={`${path}/tydzien/:date`}
        render={(props) => (
          <>
            <BreadcrumbMarker title={"Tydzień"} id="visitWeeks" to={`${url}/tydzien`} />
            <WeekViewContainer
              dateString={validateDateString(props.match.params.date).assertOk().value}
              onChangeDateString={goToWeek}
              onGoToDay={goToDay}
              goToTradeRoute={goToTradeRoute}
              userId={userId}
            />
          </>
        )}
      ></Route>

      <Route
        path={`${path}/dzien/:date`}
        render={(props) => (
          <>
            <BreadcrumbMarker title={"Dzień"} id="visitDays" to={`${url}/dzien`} />
            <DayViewContainer
              dateString={validateDateString(props.match.params.date).assertOk().value}
              onChangeDateString={goToDay}
              onGoToWeek={goToWeek}
              userId={userId}
            />
          </>
        )}
      ></Route>

      <Route path={`${path}/tydzien`}>
        <Redirect to={`${url}/tydzien/${Serializers.dateOnly.serialize(new Date())}`} />
      </Route>

      <Route path={`${path}/dzien`}>
        <Redirect to={`${url}/dzien/${Serializers.dateOnly.serialize(new Date())}`} />
      </Route>

      <Route path={`${path}`}>
        <Redirect to={`${url}/tydzien`} />
      </Route>
    </Switch>
  );
};

const useStyles = makeStyles((theme) => ({}));
