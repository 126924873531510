import { useTheme } from "@emotion/react";
import { MdOutlineNotifications } from "react-icons/md";

type Props = {
  alert: string;
};

export const BudgetTileAlert: React.FC<Props> = ({ alert }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        padding: "0.25rem 0.375rem",
        gap: "0.375rem",
        borderRadius: "3px",
        alignItems: "center",
        backgroundColor: theme.colors.primaryLight,
        color: theme.colors.primary,
      }}
    >
      <MdOutlineNotifications size={12} />
      <span css={{ fontSize: "0.75rem" }}>{alert}</span>
    </div>
  );
};
