import { paginatedListSerializer, ResourceAction } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { PriceListsFilters, PriceListSimple } from "../read/priceLists";

const priceListsFiltersSerializer = MSerializers.object<PriceListsFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
  modifiedSince: MSerializers.datetime.optional(),
});

const priceListSimpleSerializer = MSerializers.object<PriceListSimple>({
  name: MSerializers.string,
  uuid: MSerializers.uuid(),
  awaitingProposalCount: MSerializers.integer,
  modifiedAt: MSerializers.datetime,
});

export default ResourceAction({
  name: "list",
  path: "",
  responseSerializer: MSerializers.result(paginatedListSerializer(priceListSimpleSerializer)),
  querySerializer: priceListsFiltersSerializer,
});
