import { Uuid } from "@megarax/common";
import { MacroregionSimple } from "@megarax/crm-contracts";
import { useNewRouterDialog } from "@megarax/ui-components";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { v4 } from "uuid";

import { Result } from "@megaron/result";

import { MacroregionStep } from "./MacroregionStep";
import { NameStep } from "./NameStep";

interface Props {
  nameRegion: (uuid: Uuid, name: string) => Promise<Result<void, void>>;
  appendMacroregion: (uuid: Uuid, macroregionUuid: Uuid) => Promise<Result<void, void>>;
  reload: () => void;
  getMacroregions: (searchText: string) => Promise<MacroregionSimple[]>;
}

export const NewRegionDialog: React.FC<Props> = ({ nameRegion, appendMacroregion, getMacroregions, reload }) => {
  const classes = useStyles();

  const [uuid] = useState<Uuid>(v4() as Uuid);
  const dialog = useNewRouterDialog("newRegion");

  const [activeStep, setActiveStep] = useState<number>(0);
  const navigateToNextStep = () => setActiveStep(activeStep + 1);

  const onFinalizeCreation = () => {
    reload();
    dialog.close();
  };

  return (
    <>
      <Box py={2}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          <Step completed={activeStep > 0}>
            <StepLabel />
          </Step>
          <Step completed={activeStep > 1}>
            <StepLabel />
          </Step>
        </Stepper>
      </Box>
      <Box px={2}>
        {activeStep === 0 && (
          <NameStep nameRegion={nameRegion} uuid={uuid} onComplete={navigateToNextStep} closeDialog={dialog.close} />
        )}
        {activeStep === 1 && (
          <MacroregionStep
            closeDialog={dialog.close}
            appendMacroregion={appendMacroregion}
            uuid={uuid}
            onComplete={onFinalizeCreation}
            getMacroregions={getMacroregions}
          />
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    stepper: {
      "& .MuiStepLabel-iconContainer": {
        paddingLeft: "8px",
      },
    },
  };
});
