import React from "react";

export const useServiceWorker = (serviceWorkerPath: string): ServiceWorkerRegistration | undefined => {
  const [registration, setRegistration] = React.useState<ServiceWorkerRegistration | undefined>(undefined);

  React.useEffect(() => {
    if ("serviceWorker" in navigator) {
      const handleControllerChange = () => {
        navigator.serviceWorker.getRegistration().then(setRegistration);
      };

      navigator.serviceWorker
        .register(serviceWorkerPath)
        .then((reg) => {
          setRegistration(reg);
          navigator.serviceWorker.addEventListener("controllerchange", handleControllerChange);
        })
        .catch((error: Error) => {
          console.error("Service Worker registration failed:", error);
        });

      return () => {
        navigator.serviceWorker.removeEventListener("controllerchange", handleControllerChange);
      };
    }

    return undefined;
  }, [serviceWorkerPath]);

  return registration;
};

export default useServiceWorker;
