import { css, useTheme } from "@emotion/react";

interface Option {
  value: string;
  label: string;
}

interface MultiToggleGroupProps {
  options: Option[];
  onChange: (selectedValues: string[]) => void;
  values: string[];
  className?: string;
  label?: string;
  isDisabled?: boolean;
}

export const MultiToggleGroup: React.FC<MultiToggleGroupProps> = (props) => {
  const theme = useTheme();

  return (
    <div css={css({ display: "flex", flexDirection: "column", gap: "0.5rem" }, props.className)}>
      {props.label && (
        <span
          css={{
            fontFamily: theme.displayFontFamily,
            fontSize: "14px",
            color: theme.colors.primary,
          }}
        >
          {props.label}
        </span>
      )}
      <div css={{ display: "flex", gap: "0.375rem", flexWrap: "wrap" }}>
        {props.options.map(({ value, label }) => {
          const isSelected = props.values.includes(value);

          return (
            <button
              key={`multi-toggle-group-${value}`}
              css={{
                borderRadius: theme.smallBorderRadius,
                padding: "0.25rem 1rem",
                backgroundColor: isSelected ? theme.colors.primary : theme.colors.primaryLight,
                color: isSelected ? "white" : theme.colors.primary,
                cursor: props.isDisabled ? "not-allowed" : "pointer",
                fontSize: "14px",
                fontWeight: "normal",
                border: "none",
              }}
              onClick={() => {
                if (props.isDisabled) return;

                if (isSelected) {
                  props.onChange(props.values.filter((v) => v !== value));
                  return;
                }

                props.onChange([...props.values, value]);
              }}
            >
              {label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
