import { useTheme } from "@emotion/react";
import tinycolor from "tinycolor2";

type Props = {
  title: string;
  icon: React.ReactNode;
  hideIcon?: boolean;
  time?: string;
  additionalText?: string;
  onClick?: () => void;
};

export const BaseEntry: React.FC<Props> = ({ hideIcon, title, icon, time, additionalText, onClick }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: onClick ? "pointer" : "auto",
        minHeight: "42px",
        padding: "0.25rem 0",
        "&:last-child hr": {
          display: "none",
        },
      }}
      onClick={onClick}
    >
      <hr
        css={{
          height: "calc(100% - 24px)",
          width: "1px",
          background: `${tinycolor(theme.colors.primary).setAlpha(0.3)}`,
          position: "absolute",
          left: "15px",
          top: "33px",
          border: "none",
        }}
      />
      <div css={{ display: "flex", gap: "10px", alignItems: "center" }}>
        {!hideIcon && (
          <div
            css={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              background: theme.colors.primaryLight,
              padding: "7px",
              fontSize: "16px",
              alignItems: "center",
              justifyContent: "center",
              color: theme.colors.primary,
            }}
          >
            {icon}
          </div>
        )}
        <span css={{ color: theme.colors.primary, fontSize: "14px" }}> {title}</span>
      </div>
      <div css={{ fontSize: "12px", display: "flex", flexDirection: "column", textAlign: "right", opacity: 0.6 }}>
        {time && <span css={{ textWrap: "nowrap" }}>{time}</span>}
        {additionalText && <span>{additionalText}</span>}
      </div>
    </div>
  );
};
