import { useTheme } from "@emotion/react";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom-v5-compat";

import { useNotifications } from "@megaron/megarax-webapps";

type Props = {
  children: React.ReactNode;
  className?: string;
  color?: string;
  linkTo: string;
  icon?: React.ReactNode;
  label?: string;
  isNarrow?: boolean;
  isExternal?: boolean;
  id?: string;
  isFocused?: boolean;
  linkName?: string;
};

export const CardLink: React.FC<Props> = (props) => {
  const theme = useTheme();
  const baseColor = props.color ?? theme.colors.background;
  const { alertsMetric } = useNotifications();

  const alertMetricCount = alertsMetric(props.linkName);

  return (
    <CardLinkWrapper {...props}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          backgroundColor: baseColor,
          color: "white",
          alignItems: "center",
          padding: "0.625rem 0",
          width: props.isNarrow ? "2.25rem" : "3.25rem",
          flexShrink: 0,
          justifyContent: "center",
          textAlign: "left",
        }}
      >
        {props.icon && (
          <div css={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{props.icon}</div>
        )}
        {props.label && <span css={{ fontSize: "0.625rem" }}>{props.label}</span>}
      </div>
      <div
        css={{
          display: "flex",
          padding: "0.5rem 0.625rem",
          width: "100%",
          alignItems: "center",
          backgroundColor: "white",
          justifyContent: "space-between",
        }}
      >
        {props.children}
        {alertMetricCount > 0 && (
          <div
            css={{
              backgroundColor: "#E03A28",
              color: "#FBFFFF",
              borderRadius: alertMetricCount < 100 ? "50%" : "12px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              width: alertMetricCount < 100 ? "24px" : `${Math.min(50, alertMetricCount.toString().length * 16)}px`,
              height: "24px",
              padding: "4px",
              textAlign: "center",
              lineHeight: "1",
              fontSize: "14px",
              fontWeight: 400,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {alertMetricCount}
          </div>
        )}
      </div>
    </CardLinkWrapper>
  );
};

const CardLinkWrapper: React.FC<Props & PropsWithChildren> = ({
  children,
  className,
  linkTo,
  isExternal,
  id,
  color,
  isFocused,
}) => {
  const theme = useTheme();

  const linkStyles = {
    width: "100%",
    display: "flex",
    borderRadius: theme.smallBorderRadius,
    overflow: "hidden",
    color: theme.colors.border,
    border: isFocused ? `1px solid ${color}` : `1px solid ${theme.colors.skeleton}`,
  };

  if (isExternal) {
    return (
      <a href={linkTo} target="_blank" rel="noreferrer" css={{ ...linkStyles }} className={className} id={id}>
        {children}
      </a>
    );
  }

  return (
    <Link to={linkTo} css={{ ...linkStyles }} className={className} id={id}>
      {children}
    </Link>
  );
};
