import Decimal from "decimal.js";

import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

export type AvailableRewardDto = {
  rewardId: string;
  listingUuid: Uuid;
  price: Decimal;
  requiresProStatus: boolean;
};

export const availableRewardDtoSerializer = Serializers.object<AvailableRewardDto>({
  rewardId: Serializers.string,
  listingUuid: uuidSerializer,
  price: Serializers.decimal,
  requiresProStatus: Serializers.boolean,
});
