import { Dialog as MuiDialog, DialogProps } from "@mui/material";
import qs from "qs";
import React from "react";
import { useLocation } from "react-router-dom-v5-compat";

import { useNewRouterDialog } from "./useNewRouterDialog";

type MuiDialogProps = Pick<DialogProps, "fullWidth" | "maxWidth" | "fullScreen">;

interface Props extends MuiDialogProps {
  name: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const NewRouterDialog: React.FC<Props> = ({ children, name, onClose, fullScreen, ...muiProps }) => {
  const location = useLocation();
  const dialog = useNewRouterDialog(name);

  const open = qs.parse(location.search.substring(1))[name] !== undefined;

  return (
    <MuiDialog
      open={open}
      onClose={onClose ? onClose : dialog.close}
      {...muiProps}
      fullScreen={fullScreen}
      sx={{ paddingBottom: fullScreen ? "48px" : 0, zIndex: 1300 }}
    >
      {children}
    </MuiDialog>
  );
};
