import { ValidationError } from "../errors";
import { serializerExtensions, SerializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export const integer: UnsafeSerializer<number> & SerializerExtensions<number> = {
  serialize: (value) => {
    if (!Number.isInteger(value)) throw new ValidationError(`Value is not an integer (${value})`);
    return value;
  },
  forceDeserialize: (raw) => {
    const value = parseInt(raw);

    if (isNaN(value)) throw new ValidationError(`Value cannot be converted to an integer (${raw})`);
    return value;
  },
  ...serializerExtensions(),
};
