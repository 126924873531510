import { useQuery } from "react-query";

import { tradeRoutesResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

export const useFixedRoutes = (searchText: string, userId: number) => {
  const provider = useResourceProviderV2(tradeRoutesResource);

  const errorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const { data } = useQuery(["routeOptions", searchText], () =>
    provider
      .listNamed({
        salespersonId: userId,
        searchText,
      })
      .mapError(errorHandler)
      .then((r) => r.assertOk().value.items),
  );

  return data;
};
