import { Failure } from "@megaron/result";

import { serializerExtensions, SerializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export const jsonString = <T>(contentSerializer: Serializer<T>): Serializer<T> & SerializerExtensions<T> => ({
  serialize: (value) => JSON.stringify(contentSerializer.serialize(value)),
  deserialize: (raw) => {
    if (typeof raw !== "string") return Failure("NotAString");
    try {
      const parsed = JSON.parse(raw);
      return contentSerializer.deserialize(parsed);
    } catch (e) {
      return Failure("InvalidJson");
    }
  },
  ...serializerExtensions(),
});
