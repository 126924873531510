import { formatISO, parseISO } from "date-fns";

import { serializerExtensions, SerializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export const dateOnly: UnsafeSerializer<Date> & SerializerExtensions<Date> = {
  serialize: (value) => formatISO(value, { representation: "date" }),
  forceDeserialize: parseISO,
  ...serializerExtensions(),
};

export const datetime: UnsafeSerializer<Date> & SerializerExtensions<Date> = {
  serialize: (value) => value.toISOString(),
  forceDeserialize: (raw) => new Date(raw),
  ...serializerExtensions(),
};
