import { useTheme } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { MdAlternateEmail, MdOutlineNumbers } from "react-icons/md";

import { Profile } from "@megaron/iam-contracts";
import { useClientManager } from "@megaron/react-clients";

import { Button } from "./Button";
import { TagDropdown } from "./TagDropdown";
import { UserDropdown } from "./UserDropdown";

export type Tag = {
  tag: string;
  tagPosition: number;
  tagLength: number;
};

export type Mention = {
  user: string;
  tagPosition: number;
  tagLength: number;
};

type Props = {
  enableMentions?: boolean;
  enableTags?: boolean;
  tags?: string[];
  tagsValues?: Tag[];
  mentionsValues?: Mention[];
  commentValue?: string;
  onChange: (comment: string, mentions?: Mention[], tags?: Tag[]) => void;
  userDropdownPlacement?: "top" | "bottom";
};

export const CommentInput: React.FC<Props> = ({
  enableMentions = true,
  enableTags = true,
  tags = [],
  onChange,
  tagsValues,
  mentionsValues,
  commentValue,
  userDropdownPlacement,
}) => {
  const theme = useTheme();
  const [comment, setComment] = useState(commentValue ?? "");
  const [filteredUsers, setFilteredUsers] = useState<Profile[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [mentions, setMentions] = useState<Mention[]>(mentionsValues ?? []);
  const [tagList, setTagList] = useState<Tag[]>(tagsValues ?? []);
  const [showTagDropdown, setShowTagDropdown] = useState(false);
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const formattedContentRef = useRef<HTMLDivElement>(null);

  const userQuery = useClientManager("docs")
    .searchAnyDoc()
    .useQuery({ docType: ["user"], limit: 1000 });

  const users = userQuery.data?.items?.filter((item) => item.docType === "user") || [];

  const highlightMentionsAndTags = (text: string, mentions: Mention[], tags: Tag[]): JSX.Element[] => {
    const sortedMentionsAndTags = [...mentions, ...tags].sort((a, b) => a.tagPosition - b.tagPosition);

    const parts: JSX.Element[] = [];
    let lastIndex = 0;

    sortedMentionsAndTags.forEach((item) => {
      const { tagPosition, tagLength } = item;
      const itemText = text.slice(tagPosition, tagPosition + tagLength);

      if (lastIndex < tagPosition) {
        parts.push(<React.Fragment key={`text-${lastIndex}`}>{text.slice(lastIndex, tagPosition)}</React.Fragment>);
      }

      const style =
        "user" in item
          ? {
              padding: "0",
              borderRadius: "20px",
              backgroundColor: theme.colors.primaryLight,
              color: theme.colors.primary,
            }
          : {
              padding: "0",
              color: theme.colors.primary,
            };

      parts.push(
        <span key={`item-${tagPosition}-${itemText}`} style={style}>
          {itemText}
        </span>,
      );

      lastIndex = tagPosition + tagLength;
    });

    if (lastIndex < text.length) {
      parts.push(<React.Fragment key={`text-${lastIndex}`}>{text.slice(lastIndex)}</React.Fragment>);
    }

    return parts;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Backspace") {
      const cursorPos = event.currentTarget.selectionStart ?? 0;

      const lastTag = tagList
        .slice()
        .reverse()
        .find((tag) => tag.tagPosition <= cursorPos);

      if (lastTag) {
        const tagStart = lastTag.tagPosition;
        const tagEnd = tagStart + lastTag.tagLength;

        if (cursorPos >= tagStart && cursorPos <= tagEnd) {
          const removedLength = tagEnd - tagStart;
          const newComment = comment.slice(0, tagStart) + comment.slice(tagEnd);
          setComment(newComment);

          setTagList((prevTags) =>
            prevTags
              .filter((tag) => !(tag.tagPosition === lastTag.tagPosition && tag.tagLength === lastTag.tagLength))
              .map((tag) =>
                tag.tagPosition > tagStart ? { ...tag, tagPosition: tag.tagPosition - removedLength } : tag,
              ),
          );

          setMentions((prevMentions) =>
            prevMentions
              .map((mention) =>
                mention.tagPosition > tagStart
                  ? { ...mention, tagPosition: mention.tagPosition - removedLength }
                  : mention,
              )
              .filter(
                (mention) => !(mention.tagPosition === lastTag.tagPosition && mention.tagLength === lastTag.tagLength),
              ),
          );

          onChange(newComment, mentions, tagList);
          return;
        }
      }

      const lastMention = mentions
        .slice()
        .reverse()
        .find((mention) => mention.tagPosition <= cursorPos);

      if (lastMention) {
        const mentionStart = lastMention.tagPosition;
        const mentionEnd = mentionStart + lastMention.tagLength;

        if (cursorPos >= mentionStart && cursorPos <= mentionEnd) {
          const removedLength = mentionEnd - mentionStart;
          const newComment = comment.slice(0, mentionStart) + comment.slice(mentionEnd);
          setComment(newComment);

          setMentions((prevMentions) =>
            prevMentions
              .filter(
                (mention) =>
                  !(mention.tagPosition === lastMention.tagPosition && mention.tagLength === lastMention.tagLength),
              )
              .map((mention) =>
                mention.tagPosition > mentionStart
                  ? { ...mention, tagPosition: mention.tagPosition - removedLength }
                  : mention,
              ),
          );

          setTagList((prevTags) =>
            prevTags.map((tag) =>
              tag.tagPosition > mentionStart ? { ...tag, tagPosition: tag.tagPosition - removedLength } : tag,
            ),
          );

          onChange(newComment, mentions, tagList);
          return;
        }
      }
    }

    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setComment(value);

    if (enableMentions) {
      const updateMentions = (mentions: Mention[]) =>
        mentions
          .map((mention) => {
            const start = value.indexOf(comment.slice(mention.tagPosition, mention.tagPosition + mention.tagLength));
            return {
              ...mention,
              tagPosition: start !== -1 ? start : 0,
            };
          })
          .filter((mention) => mention.tagPosition >= 0);

      const updatedMentions = updateMentions(mentions);

      const atIndex = value.lastIndexOf("@");
      if (atIndex !== -1) {
        const search = value.slice(atIndex + 1).trim();
        const filteredUsers = (users || [])
          .filter(
            (user) =>
              `${user.firstName} ${user.lastName}`.toLowerCase().includes(search.toLowerCase()) &&
              !updatedMentions.some((mention) => mention.user === user.email),
          )
          .slice(0, 5);
        setFilteredUsers(filteredUsers);
        setShowDropdown(filteredUsers.length > 0);
      } else {
        setFilteredUsers([]);
        setShowDropdown(false);
      }

      setMentions(updatedMentions);
    }

    if (enableTags) {
      const updateTags = (tags: Tag[]) =>
        tags
          .map((tag) => {
            const start = value.indexOf(comment.slice(tag.tagPosition, tag.tagPosition + tag.tagLength));
            return {
              ...tag,
              tagPosition: start !== -1 ? start : 0,
            };
          })
          .filter((tag) => tag.tagPosition >= 0);

      const updatedTags = updateTags(tagList);

      const hashIndex = value.lastIndexOf("#");
      if (hashIndex !== -1) {
        const search = value.slice(hashIndex + 1).trim();
        const filteredTags = tags
          .filter((tag) => tag.toLowerCase().includes(search.toLowerCase()) && !updatedTags.some((t) => t.tag === tag))
          .slice(0, 5);
        setFilteredTags(filteredTags);
        setShowTagDropdown(filteredTags.length > 0);
      } else {
        setFilteredTags([]);
        setShowTagDropdown(false);
      }

      setTagList(updatedTags);
    }

    onChange(value, mentions, tagList);
  };

  const handleUserClick = (user: Profile) => {
    const atIndex = comment.lastIndexOf("@");
    const userFullName = `${user.firstName} ${user.lastName}`;
    const newComment = comment.slice(0, atIndex + 1) + `${userFullName} `;
    setComment(newComment);

    const newMention: Mention = {
      user: user.email,
      tagPosition: atIndex,
      tagLength: userFullName.length + 1,
    };

    const newMentions = [...mentions, newMention];
    setMentions(newMentions);
    setFilteredUsers([]);
    setShowDropdown(false);

    onChange(newComment, newMentions, tagList);
  };

  const handleTagClick = (tag: string) => {
    const hashIndex = comment.lastIndexOf("#");
    const newComment = comment.slice(0, hashIndex + 1) + `${tag} `;

    const newTag: Tag = {
      tag,
      tagPosition: hashIndex,
      tagLength: tag.length + 1,
    };

    const newTagList = [...tagList, newTag];
    setComment(newComment);
    setTagList(newTagList);
    setFilteredTags([]);
    setShowTagDropdown(false);

    onChange(newComment, mentions, newTagList);
  };

  const insertAtSymbol = () => {
    if (!enableMentions) return;

    const textarea = textareaRef.current!;
    const { selectionStart, value } = textarea;
    const before = value.slice(0, selectionStart);
    const after = value.slice(selectionStart);
    setComment(`${before}@${after}`);
    textarea.focus();
    setFilteredUsers(users.slice(0, 5));
    setShowDropdown(true);
  };

  const insertHashSymbol = () => {
    if (!enableTags) return;

    const textarea = textareaRef.current!;
    const { selectionStart, value } = textarea;
    const before = value.slice(0, selectionStart);
    const after = value.slice(selectionStart);
    setComment(`${before}#${after}`);
    textarea.focus();
    setFilteredTags(tags.slice(0, 5));
    setShowTagDropdown(true);
  };

  useEffect(() => {
    if (textareaRef.current && formattedContentRef.current) {
      formattedContentRef.current.scrollTop = textareaRef.current.scrollTop;
      formattedContentRef.current.scrollLeft = textareaRef.current.scrollLeft;
    }
  }, [comment]);

  useEffect(() => {
    if (commentValue !== undefined) {
      setComment(commentValue);
    }
  }, [commentValue]);

  const highlightedText = highlightMentionsAndTags(comment, mentions, tagList);

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      <div css={{ position: "relative", display: "inline-block" }}>
        {showDropdown && enableMentions && (
          <UserDropdown
            filteredUsers={filteredUsers}
            handleUserClick={handleUserClick}
            placement={userDropdownPlacement}
          />
        )}
        {showTagDropdown && enableTags && <TagDropdown filteredTags={filteredTags} handleTagClick={handleTagClick} />}
        <textarea
          ref={textareaRef}
          placeholder="Dodaj komentarz..."
          rows={5}
          value={comment}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          css={{
            width: "100%",
            padding: "10px 12px",
            fontSize: "1rem",
            borderRadius: "8px",
            border: `1px solid ${theme.colors.border}`,
            backgroundColor: "white",
            ":focus": {
              outline: `1px solid ${theme.colors.primary}`,
            },
            overflow: "auto",
          }}
        />
        <div
          css={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            border: `1px solid transparent`,
            overflow: "auto",
            zIndex: 9999,
            padding: "10px 12px",
            borderRadius: "8px",
            backgroundColor: "transparent",
            color: "transparent",
          }}
          ref={formattedContentRef}
        >
          {highlightedText}
        </div>
        <div
          css={{
            position: "absolute",
            bottom: "14px",
            right: "10px",
            display: "flex",
            gap: "0.5rem",
          }}
        >
          {enableTags && (
            <Button
              color={"secondary"}
              icon={<MdOutlineNumbers />}
              css={{ borderRadius: "50px" }}
              onClick={insertHashSymbol}
            >
              Dodaj tag
            </Button>
          )}
          {enableMentions && (
            <Button
              color={"secondary"}
              icon={<MdAlternateEmail />}
              css={{ borderRadius: "50px" }}
              onClick={insertAtSymbol}
            >
              Oznacz użytkownika
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
