import { fromDateString, Uuid } from "@megarax/common";
import { ChatBoxContainer } from "@megarax/crm-chat-webapp";
import { VisitDto } from "@megarax/crm-contracts";
import { useProfile } from "@megarax/iam-webapp";
import { useCurrentUser } from "@megarax/react-client";
import { BreadcrumbMarker, PageTitleManager } from "@megarax/ui-components";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { ContactListContainer } from "@megaron/megarax-v2-contacts";
import { CustomerRecentOrdersSectionContainer } from "@megaron/megarax-v2-customers";
import { CustomerReportsCardContainer } from "@megaron/megarax-v2-my-price-reports";
import { useCustomer } from "@megaron/megarax-v2-resource-hooks";

import { ChecklistSectionContainer } from "../ChecklistSection";
import { UnfinishedVisitsAlertContainer } from "../UnfinishedVisitsAlert";
import { ActionsMenu } from "./ActionsMenu";
import { CustomerHeader } from "./CustomerHeader";
import { TimeSection } from "./TimeSection";
import { useVisitActions } from "./useVisitActions";
import { ViewLayout } from "./ViewLayout";
import { VisitHeader } from "./VisitHeader";

interface Props {
  uuid: Uuid;
  visit: VisitDto | undefined;
  refetch: () => void;
}

export const VisitViewContainerContent: React.FunctionComponent<Props> = ({ uuid, visit, refetch }) => {
  const actions = useVisitActions(uuid, visit && fromDateString(visit.date), refetch);

  const customerUuid = visit?.customerUuid;
  const customer = useCustomer(customerUuid);

  const profile = useProfile(visit && { id: visit.userId });

  const currentUser = useCurrentUser();

  const isMyVisit = visit !== undefined && currentUser !== undefined && visit?.userId === currentUser?.id;

  return (
    <>
      {customer && <PageTitleManager title={`Wizyta ${customer.name}`} />}
      {customer && <BreadcrumbMarker title={customer.name} id="visit" />}
      <ViewLayout
        alerts={isMyVisit && <UnfinishedVisitsAlertContainer ignoredVisitUuid={uuid} />}
        header={
          <VisitHeader
            customerName={customer?.name}
            profilePictureUrl={profile?.pictureUrl ?? undefined}
            isCancelled={visit && visit?.isCancelled}
            isCompleted={visit && visit?.endTime !== null}
            startTime={visit?.startTime}
            scheduledStartTime={visit?.scheduledStartTime}
            actionsMenu={
              !visit?.isCancelled && (
                <ActionsMenu isCancelled={visit?.isCancelled ?? false} onCancel={actions.cancelVisit} />
              )
            }
          />
        }
        timeSection={
          visit && (
            <TimeSection
              isMine={isMyVisit}
              isCancelled={visit.isCancelled}
              onStart={actions.start}
              onEnd={actions.end}
              onStartTimeChange={actions.changeStartTime}
              onEndTimeChange={actions.changeEndTime}
              startTime={visit.startTime}
              visitDate={visit.date}
              endTime={visit.endTime}
            />
          )
        }
        checklistSection={<ChecklistSectionContainer isMine={isMyVisit} visitUuid={uuid} />}
        customerHeader={
          visit && customer && <CustomerHeader customerName={customer.name} customerUuid={visit.customerUuid} />
        }
        chat={visit && <ChatBoxContainer threadIdentifier={{ type: "customer", customerUuid: visit.customerUuid }} />}
        contacts={visit && <ContactListContainer customerUuid={visit.customerUuid} />}
        priceReports={
          visit && <CustomerReportsCardContainer customerUuid={visit.customerUuid} date={fromDateString(visit.date)} />
        }
        recentOrders={visit && <CustomerRecentOrdersSectionContainer customerUuid={visit.customerUuid} />}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({}));
