import { FilterList as FilterIcon } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

export type FilterConfig<TValue> = {
  label: string;
  render: (props: {
    value: TValue;
    onChange: (value: TValue) => void;
    className?: string;
    style?: { opaque?: boolean };
  }) => React.ReactNode;
};

export type FilterBarConfig<TFilterSet> = {
  [field in keyof TFilterSet]?: FilterConfig<TFilterSet[field]>;
};

interface Props<TFilterSet> {
  config: FilterBarConfig<TFilterSet>;
  filters: TFilterSet;
  onChange: (filters: TFilterSet) => void;
  className?: string;
  options?: {
    hideIcon?: boolean;
    opaque?: boolean;
    centered?: boolean;
  };
}

export const FilterBar = <TFilterSet,>({ filters, config, onChange, className, options }: Props<TFilterSet>) => {
  const classes = useStyles();

  const entries: [fieldName: keyof TFilterSet, fieldConfig: FilterConfig<unknown>][] = Object.entries(config) as any;

  const onFieldChange = (fieldName: keyof TFilterSet) => (value: any) =>
    onChange({
      ...filters,
      [fieldName]: value,
    });

  return (
    <div className={clsx(classes.filterBar, className)}>
      {!options?.hideIcon && <FilterIcon className={classes.icon} />}
      <div className={options?.centered ? classes.centered : undefined}>
        {entries.map(([fieldName, fieldConfig]) => {
          return (
            <React.Fragment key={fieldConfig.label}>
              {fieldConfig.render({
                value: filters[fieldName],
                onChange: onFieldChange(fieldName),
                className: classes.filterChip,
                style: {
                  opaque: options?.opaque,
                },
              })}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  filterBar: {
    display: "flex",
  },
  icon: {
    padding: "0.35rem 0",
    marginRight: "0.5rem",
  },
  filterChip: {
    margin: "0.25rem",
    pointerEvents: "auto",
  },
}));
