import { Backdrop, Paper, Popper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

export const usePopper = () => {
  const [popperAnchor, setPopperAnchor] = useState<(EventTarget & Element) | null>(null);

  const togglePopper = (e: React.MouseEvent<Element, MouseEvent>) => {
    setPopperAnchor(popperAnchor ? null : e.currentTarget);
  };

  const closePopper = () => {
    setPopperAnchor(null);
  };

  return { togglePopper, closePopper, popperAnchor };
};

interface BackdroppedPopperProps {
  anchor: (EventTarget & Element) | null;
  closePopper: () => void;
  children?: React.ReactNode;
}

export const BackdroppedPopper: React.FC<BackdroppedPopperProps> = ({ children, anchor, closePopper }) => {
  const classes = useStyles();
  return (
    <>
      <Popper open={Boolean(anchor)} anchorEl={anchor} className={classes.popper}>
        <Paper className={classes.paper}>{children}</Paper>
      </Popper>
      <Backdrop invisible open={Boolean(anchor)} onClick={closePopper} className={classes.backdrop} />
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    popper: {
      zIndex: 1401,
    },
    paper: {
      marginTop: theme.spacing(1),
      "& .MuiInputBase-input": {
        padding: theme.spacing(1),
      },
    },
    backdrop: {
      zIndex: 1400,
    },
  };
});
