import { Uuid } from "@megarax/common";
import { MyDraftSimple } from "@megarax/crm-contracts";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";

import { useCustomer } from "@megaron/megarax-v2-resource-hooks";

type Props = {
  onClick: () => void;
  draft: MyDraftSimple;
};

export const DraftRow: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const sellTo = useCustomer(props.draft.buyer.uuid as Uuid);
  const billTo = useCustomer(props.draft.payer.uuid as Uuid);

  return (
    <TableRow className={classes.row} hover={true} onClick={props.onClick}>
      <TableCell>
        <Box display="flex" flexDirection="column">
          <Typography variant="inherit">{sellTo?.name}</Typography>
          <Typography variant="caption" color="textSecondary">
            {formatDistanceToNowStrict(props.draft.draftStartedAt, {
              locale: pl,
              addSuffix: true,
            })}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>{billTo?.name}</TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    row: {
      cursor: "pointer",
    },
  };
});
