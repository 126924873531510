import { LinearProgress } from "@mui/material";
import React, { Suspense } from "react";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { Serializers } from "@megarax/serializers";
import { BreadcrumbMarker, PageTitleManager } from "@megarax/ui-components";

import { SalespeopleHomeContainer } from "./SalespeopleHomeContainer";
import { SalespersonSwitch } from "./SalespersonSwitch";

interface Props {}

export const SalespeopleSwitch: React.FC<Props> = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LinearProgress />}>
      <PageTitleManager title="Handlowcy" />
      <BreadcrumbMarker title="Handlowcy" to={path} id="salespeople" />
      <Switch>
        <Route
          path={`${path}/:userId(\\d+)`}
          render={(props) => (
            <SalespersonSwitch userId={Serializers.integer.forceDeserialize(props.match.params.userId)} />
          )}
        ></Route>

        <Route path={`${path}`}>
          <SalespeopleHomeContainer />
        </Route>
      </Switch>
    </Suspense>
  );
};
