import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { newUuid, Uuid } from "@megaron/uuid";

import { ToastCard } from "./ToastCard";

export type ToastContext = {
  error: (title: string, content?: React.ReactNode) => void;
  info: (title: string, content?: React.ReactNode) => void;
  warning: (title: string, content?: React.ReactNode) => void;
};

export const ToastContext = React.createContext<ToastContext>({
  error: () => {},
  info: () => {},
  warning: () => {},
});

type Toast = {
  uuid: Uuid;
  title: string;
  content?: React.ReactNode;
  variant: "error" | "info" | "warning";
};

export const ToastContextProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = (props) => {
  const [toasts, setToasts] = React.useState<Toast[]>([]);

  const addToast = (variant: "error" | "info" | "warning") => (title: string, content?: React.ReactNode) => {
    setToasts((prev) => [...prev, { title, uuid: newUuid(), variant, content }]);
  };

  const closeToast = (uuid: Uuid) => {
    setToasts((prev) => prev.filter((toast) => toast.uuid !== uuid));
  };

  return (
    <ToastContext.Provider
      value={{
        error: addToast("error"),
        info: addToast("info"),
        warning: addToast("warning"),
      }}
    >
      {props.children}
      <div
        css={{
          position: "fixed",
          top: "0",
          right: "0",
          margin: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          maxWidth: "400px",
          zIndex: 10000000,
        }}
      >
        <AnimatePresence>
          {toasts.map((toast) => (
            <motion.div
              key={toast.uuid}
              transition={{ duration: 0.1 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ToastCard
                title={toast.title}
                content={toast.content}
                variant={toast.variant}
                onClose={() => closeToast(toast.uuid)}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => React.useContext(ToastContext);
