import {
  ExtractValidatorError,
  ExtractValidatorInput,
  ExtractValidatorOutput,
} from "../typeExtractors";
import { ValidationResult, Validator } from "../validator";

export type ObjectValidatorSchema<
  TIn = any,
  TOut = any,
  TErr = any,
> = keyof TOut extends keyof TIn
  ? {
      [field in keyof TOut]: Validator<TIn[field], TOut[field], TErr>;
    }
  : "ERROR: The input type does not contain all fields of the output type";

export const ObjectValidatorSchema =
  <TIn, TOut>() =>
  <TValidator extends ObjectValidatorSchema<TIn, TOut, any>>(
    schema: TValidator,
  ): TValidator =>
    schema;

type SchemaInput<TSchema extends ObjectValidatorSchema> = {
  [field in keyof TSchema]: ExtractValidatorInput<TSchema[field]>;
};
type SchemaOutput<TSchema extends ObjectValidatorSchema> = {
  [field in keyof TSchema]: ExtractValidatorOutput<TSchema[field]>;
};
type SchemaErrors<TSchema extends ObjectValidatorSchema> = {
  [field in keyof TSchema]?: ExtractValidatorError<TSchema[field]>;
};

export const objectValidator =
  <TSchema extends ObjectValidatorSchema>(fieldValidators: TSchema) =>
  (
    input: SchemaInput<TSchema>,
  ): ValidationResult<SchemaOutput<TSchema>, SchemaErrors<TSchema>> => {
    type TOut = SchemaOutput<TSchema>;
    type TIn = SchemaInput<TSchema>;
    type TErr = SchemaErrors<TSchema>;

    const out: Partial<TOut> = {};
    const failures: Partial<TErr> = {};
    const fieldNames = Object.keys(fieldValidators) as (keyof TIn &
      keyof TOut)[];

    fieldNames.forEach((fieldName) => {
      const validator = fieldValidators[fieldName];
      const result = validator(input[fieldName]);
      if ("value" in result) out[fieldName] = result.value;
      else failures[fieldName] = result.failure;
    });

    if (Object.keys(failures).length > 0)
      return { failure: failures as TErr, successful: false };
    else return { value: out as TOut, successful: true };
  };
