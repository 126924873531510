import { Gtin, isMonth, Month, validateGtin } from "@megarax/crm-contracts";
import { Serializers } from "@megarax/serializers";
import Decimal from "decimal.js";
import _ from "lodash";

import { Failure, Ok } from "@megaron/result";

export type InputRow = {
  gtin: Gtin;
  costs: {
    [month: Month]: Decimal | null;
  };
};

export const rawToInput = (raw: unknown) => {
  if (!Array.isArray(raw)) return Failure("InvalidInput");
  if (raw.length === 0) return Failure("InvalidInput");

  const mapped: InputRow[] = [];

  for (const rawRow of raw) {
    if (typeof rawRow !== "object") return Failure("InvalidInput");
    const row = rawToInputRow(rawRow);
    if (row.isFailure) return row;
    mapped.push(row.value);
  }

  return Ok(mapped);
};

export const rawToInputRow = (raw: { [key: string]: unknown }) => {
  if (!("gtin" in raw)) return Failure("MissingGtin");
  if (typeof raw.gtin !== "string") return Failure("InvalidGtin");
  const gtinResult = validateGtin(raw.gtin);
  if (gtinResult.isFailure) return Failure("InvalidGtin");

  const allKeys = _.keys(raw);
  const months = allKeys.filter(isMonth);

  const costs: InputRow["costs"] = {};

  for (const month of months) {
    try {
      costs[month] = Serializers.decimal.forceDeserialize(raw[month]);
    } catch (err: any) {
      costs[month] = null;
    }
  }

  return Ok<InputRow>({
    gtin: gtinResult.value,
    costs,
  });
};
