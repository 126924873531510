import { Checkbox, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { CustomListProps } from "./propTypes";

export const CustomList = <T, K>(
  props: CustomListProps<T, K>,
): React.ReactElement => {
  const classes = useStyles();
  const { items, loading } = props;

  return (
    <Paper className={classes.paper} variant="outlined">
      {loading && (
        <Typography className={classes.message}>Ładowanie...</Typography>
      )}
      {!loading && items.length > 0 && (
        <FixedSizeList
          height={200}
          width={"100%"}
          itemSize={46}
          itemCount={items.length}
          itemData={{ ...props }}
        >
          {VirtualizedRow}
        </FixedSizeList>
      )}
      {!loading && items.length === 0 && (
        <Typography className={classes.message}>Brak</Typography>
      )}
    </Paper>
  );
};

const VirtualizedRow = (props: ListChildComponentProps) => {
  const classes = useStyles();
  const { index, style } = props;
  const { handleToggle, checked, items, getLabel } = props.data;
  const value = items[index];

  return (
    <ListItem
      style={style}
      key={"item" + index}
      className={classes.listItem}
      role="listitem"
      button
      onClick={() => handleToggle(value)}
    >
      <ListItemIcon className={classes.itemIcon}>
        <Checkbox
          checked={checked.indexOf(value) !== -1}
          tabIndex={-1}
          disableRipple
          className={classes.checkbox}
        />
      </ListItemIcon>
      <ListItemText primary={getLabel(value)} />
    </ListItem>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100%",
      height: 200,
      overflow: "auto",
    },
    listItem: {
      padding: "0",
    },
    itemIcon: {
      minWidth: "24px",
    },
    checkbox: {
      padding: "8px",
    },
    message: {
      marginTop: "0.5rem",
      textAlign: "center",
      color: theme.palette.grey[700],
    },
  }),
);
