import { Alert, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

interface Props {
  gtin: string;
}

export const InvalidGtinDialog: React.FC<Props> = ({ gtin }) => {
  const history = useHistory();
  return (
    <Dialog open={true}>
      <Alert severity="warning">
        <DialogTitle>{`Gtin (${gtin}) jest niepoprawny`}</DialogTitle>
        <DialogActions>
          <Button onClick={() => history.goBack()}>Powrót</Button>
          <Button onClick={() => history.push("/")}>Strona Główna</Button>
        </DialogActions>
      </Alert>
    </Dialog>
  );
};
