import { ChainSimple } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { useIndependentAsyncLoad } from "@megarax/react-client";
import { useDepPagination } from "@megarax/react-utils";
import {
  BreadcrumbMarker,
  LoadingBar,
  NewRouterDialog,
  PageTitleManager,
  useNewRouterDialog,
} from "@megarax/ui-components";
import { LinearProgress } from "@mui/material";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { useChainsResource } from "@megaron/megarax-v2-resource-hooks";
import { Result } from "@megaron/result";

import { ChainDetailDialog } from "./ChainDetailDialog";
import { ChainsHome } from "./ChainsHome";
import { NewChainDialog } from "./NewChainDialog";

interface Props {}

const perPage = 20;

export const ChainsHomeContainer: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { getChains, nameChain } = useChainsResource();

  const chainDialog = useNewRouterDialog("chain");
  const newChainDialog = useNewRouterDialog("newchain");

  const updateQueryUrl = (filters: ChainListQueryFilters, current: qs.ParsedQs) => {
    navigate(
      {
        pathname: location.pathname,
        search: qs.stringify({
          ...current,
          currentPage: filters.page,
          searchTerm: filters.searchTerm,
        }),
      },
      { replace: true },
    );
  };

  const initialValues = getInitialValues(location.search.substring(1));

  const [searchText, setSearchText] = useState<string>(initialValues.search);
  const [chainsQuery, setChainsQuery] = useState<ChainsQueryResult>();
  const { paginationProps, page } = useDepPagination(
    {
      perPage,
      allCount: chainsQuery?.value?.count,
      initialPage: initialValues.page,
    },
    [searchText],
  );

  const { loading: chainsLoading, reload } = useIndependentAsyncLoad(
    () =>
      getChains({
        limit: perPage,
        offset: page * perPage,
        searchText,
      }),
    setChainsQuery,
    [page, searchText],
  );

  useEffect(() => {
    updateQueryUrl({ searchTerm: searchText, page: page }, qs.parse(location.search.substring(1)));
  }, [searchText, page]);

  if (chainsQuery === undefined) return <LinearProgress />;
  if (chainsQuery.isFailure) return null;

  const chain = chainsQuery.value.items.find((chain) => chain.uuid === chainDialog.value);

  return (
    <>
      <PageTitleManager title="Sieci" />
      <BreadcrumbMarker title="Sieci" id="chains" />
      <LoadingBar loading={chainsLoading} />
      <NewRouterDialog name={chainDialog.name} fullWidth maxWidth="xs">
        {chain && (
          <ChainDetailDialog
            chain={chain}
            renameChain={nameChain({ onSuccess: reload })}
            closeDialog={chainDialog.close}
          />
        )}
      </NewRouterDialog>
      <NewRouterDialog name={newChainDialog.name} fullWidth maxWidth="xs">
        <NewChainDialog nameChain={nameChain({ onSuccess: reload })} closeDialog={newChainDialog.close} />
      </NewRouterDialog>
      <ChainsHome
        chains={chainsQuery.value}
        pagination={paginationProps}
        navigation={{
          navigateToDetails: chainDialog.open,
          openAddCustomerDialog: newChainDialog.open,
        }}
        search={{
          setSearchText,
          initialValue: initialValues.search,
        }}
      />
    </>
  );
};
const getInitialValues = (queryStrings: string) => {
  const { currentPage, searchTerm } = qs.parse(queryStrings);
  return {
    search: typeof searchTerm === "string" ? searchTerm : "",
    page: typeof currentPage === "string" && !isNaN(parseInt(currentPage)) ? parseInt(currentPage) : 0,
  };
};

type ChainsQueryResult = Result<
  {
    count: number;
    items: ChainSimple[];
  },
  HttpRequestError
>;

export type ChainListQueryFilters = { searchTerm: string; page: number };
