import React from "react";
import { Mention, MentionsInput, MentionsInputProps } from "react-mentions";

interface Props extends Omit<MentionsInputProps, "children"> {
  getMentionableUsers: (
    search: string,
    limit?: number,
  ) => { id: number; name: string; surname: string; pictureUrl: string | null }[];
}

export const InputWithMentions: React.FC<Props> = (props) => {
  const { getMentionableUsers, ...inputProps } = props;
  return (
    <MentionsInput {...inputProps}>
      <Mention
        markup="[__display__]{__id__}"
        trigger="undefined"
        data={(e) => getMentionableUsers(e, 10)}
        style={{
          backgroundColor: "#c3ebd7",
        }}
      />
    </MentionsInput>
  );
};
