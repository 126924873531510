import { faFileDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Month } from "@megarax/crm-contracts";
import { useSnackbarErrorHandler } from "@megarax/ui-components";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { parse, stringify } from "csv/browser/esm/sync";
import Decimal from "decimal.js";
import fileDownload from "js-file-download";
import _ from "lodash";
import React from "react";

import { InputRow, rawToInput } from "./input";
import { TargetTableDataGrid } from "./TargetTableDataGrid";

export type TargetTableRow = {
  regionUuid: string;
  regionName: string | undefined;

  targets: {
    [month: Month]: Decimal | null;
  };
};

interface Props {
  rows: TargetTableRow[];
  onInput: (rows: InputRow[]) => Promise<void>;
}

export const TargetsView: React.FunctionComponent<Props> = ({ rows, onInput }) => {
  const classes = useStyles();
  const handleError = useSnackbarErrorHandler({
    InvalidInput: "Niepoprawny plik.",
    MissingRegionUuid: "Plik musi zawierać kolumnę 'regionUuid'",
    InvalidRegionUuid: "Niepoprawne UUID regionu",
  });

  const onFileUpload: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    const parsed: any[] = parse(await file.text(), {
      columns: true,
    });

    const inputRowsResult = rawToInput(parsed);
    if (inputRowsResult.isFailure) {
      handleError(inputRowsResult.error);
      return;
    }
    onInput(inputRowsResult.value);
  };

  return (
    <div className={classes.view}>
      <div className={classes.actionBar}>
        <Button
          startIcon={<FontAwesomeIcon icon={faFileDownload} />}
          className={classes.downloadButton}
          onClick={() => downloadCsv(rows)}
        >
          Pobierz CSV
        </Button>

        <input
          accept=".csv"
          type="file"
          id="contained-button-file"
          style={{ display: "none" }}
          onChange={onFileUpload}
        />
        <label htmlFor="contained-button-file">
          <Button component="span" startIcon={<FontAwesomeIcon icon={faFileUpload} />} className={classes.uploadButton}>
            Importuj CSV
          </Button>
        </label>
      </div>
      <div className={classes.table}>
        <TargetTableDataGrid rows={rows}></TargetTableDataGrid>
      </div>
    </div>
  );
};

const downloadCsv = (rows: TargetTableRow[]) => {
  const months = _(rows)
    .flatMap((r) => _.keys(r.targets))
    .uniq()
    .sort()
    .value();
  const columns = [
    { key: "regionUuid" },
    { key: "regionName", header: "Nazwa regionu" },
    ...months.map((m) => ({ key: m })),
  ];

  const entries = rows.map((row) => ({
    regionUuid: row.regionUuid,
    regionName: row.regionName,
    ..._.mapValues(row.targets, (c) => c?.toFixed(2) ?? ""),
  }));

  const csv = stringify(entries, {
    header: true,
    columns: columns,
  });
  fileDownload(csv, `plan_marzy.csv`);
};

const useStyles = makeStyles((theme) => ({
  view: {},
  actionBar: {
    height: "60px",
  },
  table: {
    height: "calc(100vh - 166px)",
    overflow: "auto",
  },
  downloadButton: {
    margin: "0.5rem",
  },
  uploadButton: {
    margin: "0.5rem",
  },
}));
