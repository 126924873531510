import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom-v5-compat";

import { useDeviceType } from "@megaron/dash-mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { Tile } from "@megaron/dash-tile";
import { OrderDto } from "@megaron/loyalty-orders-contracts";
import { getUserPath, renderUserUuid } from "@megaron/loyalty-users-admin-webapp";

import { FulfillmentChip } from "./OrderList";

type Props = {
  orders: OrderDto[];
};

export const OrdersTable: React.FC<Props> = ({ orders }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const getRowLink = (order: OrderDto) => `/specjalista/orders/id/${order.purchaseUuid}`;

  const getRowsContent = (item: OrderDto): RowCellContent[] => [
    {
      isLink: true,
      element: (
        <span css={{ textDecoration: item.isCancelled ? "line-through" : "none", color: theme.colors.primary }}>
          {item.rewardId}
        </span>
      ),
    },
    {
      isLink: true,
      element: item.orderedAt.toLocaleString(),
    },
    {
      isLink: true,
      element: (
        <div css={{ display: "flex", gap: "0.25rem" }}>
          {item.fulfillments.map((f) => (
            <FulfillmentChip fulfillment={f} />
          ))}
        </div>
      ),
    },
    {
      cellCss: { color: theme.colors.primary },
      element: <Link to={getUserPath(item.userUuid)}>{renderUserUuid(item.userUuid)}</Link>,
    },
  ];

  if (isMobile) {
    return (
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
        {orders.map((order) => (
          <Tile>
            <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <Link
                to={getRowLink(order)}
                css={{
                  textDecoration: order.isCancelled ? "line-through" : "none",
                  color: theme.colors.primary,
                  fontWeight: 600,
                }}
              >
                {order.rewardId}
              </Link>
              {order.fulfillments.length > 0 && (
                <div css={{ display: "flex", gap: "0.25rem" }}>
                  {order.fulfillments.map((f) => (
                    <FulfillmentChip fulfillment={f} />
                  ))}
                </div>
              )}
              {order.orderedAt.toLocaleString()},
              <Link to={getUserPath(order.userUuid)}>{renderUserUuid(order.userUuid)}</Link>
            </div>
          </Tile>
        ))}
      </div>
    );
  }

  return (
    <Table columns={columns}>
      <TableBody
        rows={orders.map((order) => ({
          cellsContent: getRowsContent(order),
          linkTo: getRowLink(order),
          uuid: order.purchaseUuid,
          css: {
            "&:hover": {
              "td:nth-child(1)": {
                textDecoration: "underline",
              },
            },
          },
        }))}
      />
    </Table>
  );
};

const columns: TableHeadColumn<string>[] = [
  { label: "Nagroda" },
  { label: "Data" },
  { label: "Realizacja" },
  { label: "Użytkownik" },
];
