import { Uuid } from "@megarax/common";
import { CustomerReference, EditCustomersInput, PayerDetail, PriceListReference } from "@megarax/crm-contracts";
import { AsyncButton, NewRouterResponsiveSelect } from "@megarax/ui-components";
import {
  ExtractValidatorError,
  getErrorMessage,
  objectValidator,
  ObjectValidatorSchema,
  requiredValidator,
} from "@megarax/validators";
import { Box, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React, { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";

const customerValidator = ObjectValidatorSchema<CustomerReference, CustomerReference>()({
  uuid: requiredValidator(),
});

export const validatorSchema = ObjectValidatorSchema<NewDraftInput, NewDraftInput>()({
  uuid: requiredValidator(),
  payerDiscountRate: requiredValidator(),
  buyer: objectValidator(customerValidator),
  payer: objectValidator(customerValidator),
  priceList: objectValidator(customerValidator),
  recipient: objectValidator(customerValidator),
});

export const formValidator = objectValidator(validatorSchema);
export type FormErrors = ExtractValidatorError<typeof formValidator>;

export type Customer = {
  uuid: Uuid;
  name: string | null;
  regionUuid: Uuid | null;
};

export interface NewDraftInput {
  uuid: string;
  payerDiscountRate: Decimal;
  buyer: CustomerReference;
  payer: CustomerReference;
  priceList: PriceListReference;
  recipient: CustomerReference;
}

interface Props {
  getBuyers: (search: string) => Promise<Customer[]>;
  getPayers: (search: string) => Promise<Customer[]>;
  getPayerData: (customerUuid: string) => Promise<PayerDetail>;
  submitDraft: (input: EditCustomersInput) => Promise<void>;
  myRegions: Uuid[];
  initialBuyer: Customer | null;
  initialRecipient: Customer | null;
  initialPayer: Customer | null;
}

export const EditCustomers: React.FC<Props> = ({
  getBuyers,
  getPayers,
  getPayerData,
  submitDraft,
  myRegions,
  initialBuyer,
  initialRecipient,
  initialPayer,
}) => {
  const classes = useStyles();

  const changeBuyerRef = useRef<(newValue: Customer | null) => void>();
  const setChangeBuyerRef = (change: (newValue: Customer | null) => void) => {
    changeBuyerRef.current = change;
  };

  const [buyer, setBuyer] = useState<Customer | null>(initialBuyer);
  const [payer, setPayer] = useState<Customer | null>(initialPayer);
  const [recipient, setRecipient] = useState<Customer | null>(initialRecipient);
  const [payerData, setPayerData] = useState<PayerDetail | null>(null);

  const [error, setError] = useState<FormErrors>();

  const submit = () => {
    const input = {
      uuid: v4(),
      payerDiscountRate: payerData?.baseDiscount,
      buyer: { uuid: buyer?.uuid },
      payer: { uuid: payer?.uuid },
      priceList: { uuid: payerData?.priceList?.uuid },
      recipient: { uuid: recipient?.uuid },
    };

    const result = formValidator(input);

    if (result.successful)
      return submitDraft({
        billToUuid: result.value.payer.uuid as Uuid,
        priceListUuid: result.value.priceList.uuid as Uuid,
        payerDepartmentUuid: result.value.buyer.uuid as Uuid,
        sellToUuid: result.value.recipient.uuid as Uuid,
        payerDiscountRate: result.value.payerDiscountRate,
      });

    setError(result.failure);
    return;
  };

  useEffect(() => {
    if (!payer) return setPayerData(null);
    getPayerData(payer.uuid).then(setPayerData);
  }, [payer]);

  // const showRecipientAlert = recipient && (recipient.regionUuid === null || !myRegions.includes(recipient.regionUuid));

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        Edytuj klientów
      </Typography>
      <NewRouterResponsiveSelect
        label='Nabywca — dawniej "miejsce odbioru"'
        getOptions={getBuyers}
        initialValue={recipient ?? undefined}
        value={recipient}
        getKey={(o) => o.uuid}
        getValue={(o) => o}
        getLabel={(o) => o.name ?? "Nieznany"}
        onChange={(value) => {
          setRecipient(value);
          if (!recipient && changeBuyerRef.current) changeBuyerRef.current(value);
        }}
        error={getErrorMessage(error?.recipient?.uuid)}
      />
      <NewRouterResponsiveSelect
        label='Odbiorca faktury — dawniej "płatnik"'
        getOptions={getPayers}
        initialValue={payer ?? undefined}
        value={payer}
        getKey={(o) => o.uuid}
        getValue={(o) => o}
        getLabel={(o) => o.name ?? "Nieznany"}
        onChange={setPayer}
        error={getErrorMessage(error?.payer?.uuid)}
      />
      <NewRouterResponsiveSelect
        setValueChangeRef={setChangeBuyerRef}
        label='Oddział płatnika — dawniej "zamawiający"'
        getOptions={getBuyers}
        initialValue={buyer ?? undefined}
        value={buyer}
        getKey={(o) => o.uuid}
        getValue={(o) => o}
        getLabel={(o) => o.name ?? "Nieznany"}
        onChange={setBuyer}
        error={getErrorMessage(error?.buyer?.uuid)}
      />

      {/* {showRecipientAlert && (
        <Box mb={2}>
          <Alert severity="warning">Nabywca nie jest przypisany do twojego regionu.</Alert>
        </Box>
      )} */}
      {payerData && (
        <div>
          <Typography>{`Rabat: ${payerData.baseDiscount.mul(100).toNumber()}%`}</Typography>
        </div>
      )}
      <Box mb={2}>
        <AsyncButton variant="contained" color="primary" asyncAction={submit} className={classes.button}>
          Zapisz
        </AsyncButton>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      "& > .MuiFormControl-root": {
        marginBottom: theme.spacing(2),
      },
    },
    button: {
      display: " block",
      marginLeft: "auto",
    },
    title: {
      margin: theme.spacing(2, 0),
    },
  };
});
