import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { CalendarEvent } from "./CalendarEvent";

type Props = {
  appointmentProps: Appointments.AppointmentProps;
  event: CalendarEvent;
};

export const EventTile: React.FunctionComponent<Props> = ({ appointmentProps, event }) => {
  const classes = useStyles();
  const theme = useTheme();

  const style =
    event.variant === "outlined"
      ? {
          backgroundColor: "#fff",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: event.color,
          color: event.color,
        }
      : {
          backgroundColor: event.color,
          color: theme.palette.getContrastText(event.color),
        };

  return (
    <Appointments.Appointment
      {...appointmentProps}
      className={classes.appointment}
      style={style}
      onClick={event.onClick}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  appointment: {
    "& > div": {
      color: "inherit !important",
    },
  },
}));
