import { Uuid } from "@megarax/common";
import { ChainSimple, RegionSimple } from "@megarax/crm-contracts";
import { Business, Edit, Map } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { ChangeChainDialog, GetChains } from "./ChangeChainDialog";
import { ChangeRegionDialog, GetRegions } from "./ChangeRegionDialog";

export type RegionsDictionary = { [uuid: Uuid]: RegionSimple | undefined };
export type ChainsDictionary = { [uuid: Uuid]: ChainSimple | undefined };

export type { GetRegions, GetChains };

interface Props {
  regionUuid: Uuid;
  chainUuid: Uuid | null;

  regionsDictionary: RegionsDictionary;
  chainsDictionary: ChainsDictionary;

  onReassignRegion: (regionUuid: Uuid) => Promise<void>;
  onAssignToChain: (chainUuid: Uuid | null) => Promise<void>;

  getRegions: GetRegions;
  getChains: GetChains;
}

export const RegionAndChainSection: React.FunctionComponent<Props> = ({
  regionUuid,
  chainUuid,
  regionsDictionary,
  chainsDictionary,
  onReassignRegion,
  onAssignToChain,
  getRegions,
  getChains,
}) => {
  const classes = useStyles();

  const region = regionsDictionary[regionUuid];
  const chain = chainUuid && chainsDictionary[chainUuid];

  const location = useLocation();
  const navigate = useNavigate();

  const openChangeRegionDialog = () => navigate(`${location.pathname}/zmien_region`);
  const isChangeRegionDialogOpen = location.pathname.endsWith("zmien_region");
  const openChangeChainDialog = () => navigate(`${location.pathname}/zmien_siec`);
  const isChangeChainDialogOpen = location.pathname.endsWith("zmien_siec");

  const regionName = region?.name ?? <Skeleton variant="text" width={150} />;
  const chainName = chainUuid
    ? chainsDictionary[chainUuid]?.name ?? <Skeleton variant="text" width={150} />
    : "Brak sieci";

  return (
    <Box display="flex" flexDirection="row">
      {isChangeRegionDialogOpen && region !== undefined && (
        <ChangeRegionDialog
          onClose={() => navigate(-1)}
          getRegions={getRegions}
          initialRegion={region}
          onReassignRegion={onReassignRegion}
        />
      )}
      <Box display="flex" flexDirection="row" flexGrow={1}>
        <Map />
        <Typography ml={1}>{regionName}</Typography>
        <IconButton className={classes.editButton} onClick={openChangeRegionDialog}>
          <Edit />
        </IconButton>
      </Box>

      {isChangeChainDialogOpen && chain !== undefined && (
        <ChangeChainDialog
          onClose={() => navigate(-1)}
          getChains={getChains}
          initialChain={chain}
          onAssignToChain={onAssignToChain}
        />
      )}
      <Box display="flex" flexDirection="row" flexGrow={1}>
        <Business />
        <Typography ml={1}>{chainName}</Typography>
        <IconButton className={classes.editButton} onClick={openChangeChainDialog}>
          <Edit />
        </IconButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  editButton: {
    margin: theme.spacing(-1, 1, -1, 2),
    color: theme.palette.grey[500],
  },
}));
