import { Theme, ThemeProvider } from "@emotion/react";
import { MdSecurity } from "react-icons/md";
import { Outlet, Route } from "react-router-dom-v5-compat";

import { createTheme } from "@megaron/dash-default-theme";
import { useConfig } from "@megaron/dev";
import { LinkDoc } from "@megaron/docs-contracts";
import { Accounts } from "@megaron/iam-accounts-webapp";
import { Groups } from "@megaron/iam-groups-webapp";
import { appLinkTreeToNavEntries, MegaraxWebappContainer } from "@megaron/megarax-webapps";
import { filterLinkTree } from "@megaron/megarax-webapps-home";

const iamTheme: Theme = createTheme("191");

type Props = {
  linkDocs: LinkDoc[] | undefined;
};

export const useIamRouter: React.FC<Props> = ({ linkDocs }) => {
  return (
    <Route
      path="/iam"
      element={
        <ThemeProvider theme={iamTheme}>
          <IamMegaraxWebappContainer linkDocs={linkDocs} />
        </ThemeProvider>
      }
    >
      <Route path="accounts/*" element={<Accounts />} />
      <Route path="groups/*" element={<Groups />} />
    </Route>
  );
};

const IamMegaraxWebappContainer: React.FC<{
  linkDocs: LinkDoc[] | undefined;
}> = ({ linkDocs }) => {
  const fullLinkTree = filterLinkTree(linkDocs || []);
  const appLinkTree = fullLinkTree.find((appTree) => appTree.app === "iam");

  const { config } = useConfig();

  return (
    <MegaraxWebappContainer
      app={"iam"}
      logo={<MdSecurity width="1em" height="1em" />}
      title="Admin"
      subtitle="megarax.net"
      bypassAuth={config.testUser.enabled}
      navEntries={appLinkTreeToNavEntries(appLinkTree)}
    >
      <Outlet />
    </MegaraxWebappContainer>
  );
};
