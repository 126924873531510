import { CSSObject, useTheme } from "@emotion/react";
import tinycolor from "tinycolor2";

import { TableCell } from "./TableCell";

export type RowCellContent = { element: React.ReactNode; cellCss?: CSSObject; isLink?: boolean };

export type TableRowProps = {
  className?: string;
  cellsContent: RowCellContent[];
  linkTo?: string;
  visibleColumnIndexes?: number[];
  uuid?: string;
};

export const TableRow: React.FC<TableRowProps> = ({ className, visibleColumnIndexes, cellsContent, linkTo }) => {
  const theme = useTheme();

  return (
    <tr
      className={className}
      css={{
        fontSize: "0.9375rem",
        color: "black",
        minHeight: "3rem",
        height: "fit-content",
        fontWeight: 400,
        "td:first-child": {
          borderLeftStyle: "solid",
          padding: 0,
          overflow: "hidden",
        },
        "td:last-child": {
          borderRightStyle: "solid",
        },
        "&:last-child td": {
          borderBottomStyle: "solid",
        },
        "&:not(:first-child) td": {
          borderTopColor: `${tinycolor(theme.colors.primary).setAlpha(0.15)}`,
        },
        "&:first-child td:first-child": {
          borderTopLeftRadius: theme.smallBorderRadius,
        },
        "&:first-child td:last-child": {
          borderTopRightRadius: theme.smallBorderRadius,
        },
        "&:last-child td:first-child": {
          borderBottomLeftRadius: theme.smallBorderRadius,
        },
        "&:last-child td:last-child": {
          borderBottomRightRadius: theme.smallBorderRadius,
        },
        "&:hover": {
          td: { backgroundColor: theme.colors.background },
        },
      }}
    >
      {cellsContent.map((cell, index) => {
        if (visibleColumnIndexes && !visibleColumnIndexes.includes(index)) {
          return null;
        }

        return (
          <TableCell key={`table-cell-${index}`} linkTo={cell.isLink ? linkTo : undefined} cellCss={cell.cellCss}>
            {cell.element}
          </TableCell>
        );
      })}
    </tr>
  );
};
