import { MyProfileUpdater, ProfileStoreProvider } from "@megarax/iam-webapp";
import { CurrentUserContextProvider } from "@megarax/react-client";
import { BreadcrumbsContextProvider, FeedbackSnackbarProvider, PageTitleManager } from "@megarax/ui-components";
import { StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";

import FailedConnectionView from "./main/views/FailedConnectionView";
import theme from "./theme";
import themeInvoices from "./themeInvoices";

const queryClient = new QueryClient();

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const MegaraxProviders: React.FC<{ themeConfig?: "default" | "invoices" } & PropsWithChildren> = ({
  children,
  themeConfig = "default",
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={themeConfig === "invoices" ? themeInvoices : theme}>
            <FeedbackSnackbarProvider>
              <BreadcrumbsContextProvider>
                <CurrentUserContextProvider ifConnectionFailed={<FailedConnectionView />}>
                  <ProfileStoreProvider>
                    <BrowserRouter>
                      <PageTitleManager title={"Megarax"} />
                      {children}
                    </BrowserRouter>
                  </ProfileStoreProvider>
                </CurrentUserContextProvider>
              </BreadcrumbsContextProvider>
            </FeedbackSnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};
