export const postMessageCommandErrors = ["MessageAlreadyPosted"] as const;
export type PostMessageCommandError = typeof postMessageCommandErrors[number];

export const subscribeToThreadErrors = ["AlreadySubscribed"] as const;
export type SubscribeToThreadError = typeof postMessageCommandErrors[number];

export const unsubscribeFromThreadErrors = ["NotSubscribed"] as const;
export type UnsubscribeFromThreadError = typeof postMessageCommandErrors[number];

export const acknowledgeMessagesErrors = ["NotAParticipant", "InvalidMessageCount", "AlreadyAcknowledged"] as const;
export type AcknowledgeMessagesError = typeof postMessageCommandErrors[number];
