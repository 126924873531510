import React from "react";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { PrintView } from "./PrintView";

interface Props {
  threadUuid: Uuid;
  onPrintEnd: () => void;
}

export const PrintViewContainer: React.FC<Props> = ({ threadUuid, onPrintEnd }) => {
  const threadQuery = useClientManager("invoices")
    .threadQuery()
    .useQuery({ uuid: threadUuid ? Uuid(threadUuid) : ("" as Uuid) }, { enabled: !!threadUuid });

  const thread = threadQuery.data;

  const isApproved = thread?.status === "approved";

  if (!thread) return null;

  return <PrintView displayConditions={{ isApproved }} thread={threadQuery.data} onEndPrint={onPrintEnd} />;
};
