import { faArrowAltCircleDown, faArrowAltCircleUp, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Uuid } from "@megarax/common";
import {
  ContactDumpEntry,
  contactListResource,
  CustomerSimple,
  CustomerSortField,
  RegionSimple,
} from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Pagination } from "@megarax/ui-components";
import { Clear, Payments as PaymentsIcon, QuestionMark, Star } from "@mui/icons-material";
import { Box, Button, Chip, Divider, List, ListItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { stringify } from "csv/browser/esm/sync";
import { differenceInHours, formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";

import { statusMap } from "@megaron/megarax-v2-customer-map";
import { useCustomersResource } from "@megaron/megarax-v2-resource-hooks";

import { pricingRulesMap } from "./CustomerHomeContainer";
import { QueryFilters } from "./customerHomeFilters";
import { formatAddress } from "./shared/LocationPickerV2";
import { TopSection } from "./TopSection";

interface Props {
  customers: {
    count: number;
    items: CustomerSimple[];
  };
  queryFilters: {
    queryFilters: QueryFilters;
    setQueryFilters: (qf: QueryFilters) => void;
    regionsDictionary: _.Dictionary<RegionSimple>;
    getRegions: (searchText: string) => Promise<RegionSimple[]>;
    getTags: (searchText: string) => Promise<string[]>;
    onSortHeaderClick: (field: CustomerSortField) => () => void;
  };
  pagination: {
    onChange: (e: React.ChangeEvent<unknown>, page: number) => void;
    count: number;
    page: number;
  };
  navigation: {
    navigateToDetails: (uuid: Uuid) => void;
    openAddCustomerDialog: () => void;
  };
}

export type PricingRulesFilterOption = "true" | "false" | "both";

export const CustomersHome: React.FC<Props> = ({ customers, navigation, queryFilters, pagination }) => {
  const classes = useStyles();

  const setRegionUuids = (regionUuid: Uuid[]) =>
    queryFilters.setQueryFilters({ ...queryFilters.queryFilters, regionUuid });

  const [regions] = useState<RegionSimple[]>(
    queryFilters.queryFilters.regionUuid.map((uuid) => queryFilters.regionsDictionary[uuid]).filter((x) => x),
  );

  useEffect(() => {
    setRegionUuids(regions.map((region) => region.uuid));
  }, [regions]);

  const tableHeaderProps = {
    sortFilter: queryFilters.queryFilters.sortBy,
    onClick: queryFilters.onSortHeaderClick,
  };

  return (
    <Box width="100%">
      <TopSection
        queryFilters={queryFilters.queryFilters}
        setQueryFilters={queryFilters.setQueryFilters}
        getTags={queryFilters.getTags}
        openAddCustomerDialog={navigation.openAddCustomerDialog}
        getRegions={queryFilters.getRegions}
      />
      <Box display="flex" flexDirection="row">
        <CustomersCsvDownload queryFilters={queryFilters.queryFilters} />
        <ContactsCsvDownload />
      </Box>
      <Box className={classes.table}>
        <Box
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            minWidth: "100%",
          }}
        >
          <List disablePadding dense>
            <ListItem>
              <TableHeader
                minWidth="130px"
                width="35%"
                label={"Nazwa"}
                sortable={{
                  ...tableHeaderProps,
                  field: "name",
                }}
              />
              <TableHeader
                minWidth="130px"
                width="10%"
                label="Sieć"
                sortable={{
                  ...tableHeaderProps,
                  field: "chainName",
                }}
              />
              <TableHeader
                minWidth="130px"
                width="15%"
                label="Adres"
                sortable={{
                  ...tableHeaderProps,
                  field: "locality",
                }}
              />
              <TableHeader
                minWidth="130px"
                width="10%"
                label="Region"
                sortable={{
                  ...tableHeaderProps,
                  field: "regionName",
                }}
              />

              <TableHeader
                minWidth="130px"
                width="10%"
                label="Makroregion"
                sortable={{
                  ...tableHeaderProps,
                  field: "macroregionName",
                }}
              />
              <TableHeader
                minWidth="130px"
                width="10%"
                label={"Ostatnia wizyta"}
                sortable={{
                  ...tableHeaderProps,
                  field: "lastVisitTime",
                }}
              />

              <TableHeader
                minWidth="130px"
                width="10%"
                label={"Ostatnie zamówienie"}
                sortable={{
                  ...tableHeaderProps,
                  field: "lastOrderTime",
                }}
              />
            </ListItem>
          </List>
          <List dense disablePadding>
            <Divider />
            {customers.items.map((customer) => {
              return (
                <React.Fragment key={customer.uuid}>
                  <ListItem
                    button
                    className={classes.itemRow}
                    onClick={() => navigation.navigateToDetails(customer.uuid as Uuid)}
                  >
                    <Box width="35%" minWidth="130px" className={classes.tableCell} px={1}>
                      <Typography
                        variant="body2"
                        noWrap
                        display="flex"
                        flexDirection="row"
                        lineHeight={0.875}
                        alignItems="center"
                      >
                        {customer.status === "vip" && (
                          <Star fontSize="inherit" className={classes.statusIcon} color="primary" />
                        )}
                        {customer.status === "inactive" && <Clear fontSize="inherit" className={classes.statusIcon} />}
                        {customer.status === "unverified" && (
                          <QuestionMark fontSize="inherit" className={classes.statusIcon} />
                        )}
                        {customer.name}
                        {customer.hasPricingRules ? (
                          <Chip label="Płatnik" size="small" icon={<PaymentsIcon />} className={classes.payerChip} />
                        ) : null}
                      </Typography>
                    </Box>
                    <Box width="10%" minWidth="130px" className={classes.tableCell} px={1}>
                      <Typography variant="body2" noWrap>
                        {customer.chainName}
                      </Typography>
                    </Box>
                    <Box width="15%" minWidth="130px" className={classes.tableCell} px={1}>
                      <Typography variant="body2" noWrap>
                        {customer.visitAddress
                          ? formatAddress({
                              locality: customer.visitAddress.locality,
                              street: customer.visitAddress.street,
                              name: "",
                              postalCode: "",
                              country: "",
                            })
                          : ""}
                      </Typography>
                    </Box>
                    <Box width="10%" minWidth="130px" className={classes.tableCell} px={1}>
                      <Typography variant="body2" noWrap>
                        {customer.regionName}
                      </Typography>
                    </Box>
                    <Box width="10%" minWidth="130px" className={classes.tableCell} px={1}>
                      <Typography variant="body2" noWrap>
                        {customer.macroregionName}
                      </Typography>
                    </Box>
                    <Box width="10%" minWidth="130px" className={classes.tableCell} px={1}>
                      <Typography variant="body2" noWrap>
                        {customer.lastVisitTime
                          ? formatDistanceToNowStrict(customer.lastVisitTime, {
                              locale: pl,
                              addSuffix: true,
                              unit: differenceInHours(new Date(), customer.lastVisitTime) > 24 ? "day" : undefined,
                            })
                          : "-"}
                      </Typography>
                    </Box>
                    <Box width="10%" minWidth="130px" className={classes.tableCell} px={1}>
                      <Typography variant="body2" noWrap>
                        {customer.lastOrderTime
                          ? formatDistanceToNowStrict(customer.lastOrderTime, {
                              locale: pl,
                              addSuffix: true,
                              unit: differenceInHours(new Date(), customer.lastOrderTime) > 24 ? "day" : undefined,
                            })
                          : "-"}
                      </Typography>
                    </Box>
                  </ListItem>
                </React.Fragment>
              );
            })}
          </List>
        </Box>
      </Box>

      <Pagination {...pagination} allCount={customers.count} />
    </Box>
  );
};

const TableHeader = ({
  width,
  minWidth,
  sortable,
  label,
}: {
  width: string;
  minWidth?: string;
  label: string;
  sortable?: {
    onClick: (field: CustomerSortField) => () => void;
    field: CustomerSortField;
    sortFilter?: { field: string; order?: "ASC" | "DESC" };
  };
}) => {
  const isSortable = sortable !== undefined;
  return (
    <Box
      width={width}
      minWidth={minWidth ?? "130px"}
      onClick={isSortable ? sortable.onClick(sortable.field) : undefined}
      display="flex"
      style={{ cursor: isSortable ? "pointer" : "default" }}
    >
      <Typography variant="subtitle2" display="inline">
        {label}
      </Typography>
      <Box width="10px" ml={1}>
        {isSortable && sortable.sortFilter?.field === sortable.field && (
          <>
            {sortable.sortFilter?.order === "ASC" && <FontAwesomeIcon icon={faArrowAltCircleUp} />}
            {sortable.sortFilter?.order === "DESC" && <FontAwesomeIcon icon={faArrowAltCircleDown} />}
          </>
        )}
      </Box>
    </Box>
  );
};

const CustomersCsvDownload: React.FC<{ queryFilters: QueryFilters }> = ({ queryFilters }) => {
  const { listCustomers } = useCustomersResource();

  const downloadCsv = useMutation(() => {
    const customerToCsvEntry = (customer: CustomerSimple) => ({
      Nazwa: customer.name,
      Status: customer.status ? statusMap[customer.status] : "Brak",
      Sieć: customer.chainName,
      KodPocztowy: customer.visitAddress?.postalCode,
      Miejscowość: customer.visitAddress?.locality,
      Ulica: customer.visitAddress?.street,
      Państwo: customer.visitAddress?.country,
      Region: customer.regionName,
      Makroregion: customer.macroregionName,
      "Ostatnia wizyta": customer.lastVisitTime?.toISOString(),
      "Ostatnie zamówienie": customer.lastOrderTime?.toISOString(),
    });
    return listCustomers({
      limit: 10000,
      searchText: queryFilters.searchText,
      sortBy: queryFilters.sortBy ? [queryFilters.sortBy] : undefined,
      regionUuid: queryFilters.regionUuid.length > 0 ? queryFilters.regionUuid : undefined,
      hasPricingRules: pricingRulesMap[queryFilters.hasPricingRules],
      tags: queryFilters.tags,
      status: queryFilters.status,
    }).map((result) =>
      fileDownload(
        stringify(result.items.map(customerToCsvEntry), { header: true }),
        `klienci_${new Date().toISOString()}.csv`,
      ),
    );
  });

  return (
    <Box ml={2} mb={2}>
      <Button startIcon={<FontAwesomeIcon icon={faDownload} />} color="primary" onClick={() => downloadCsv.mutate()}>
        Klienci CSV
      </Button>
    </Box>
  );
};

const ContactsCsvDownload: React.FC = ({}) => {
  const resource = useResourceProviderV2(contactListResource);

  const downloadCsv = useMutation(() => {
    const contactToCsvEntry = (contact: ContactDumpEntry) => ({
      nazwa_klienta: contact.customerName,
      imie_nazwisko: contact.name,
      stanowisko: contact.position,
      telefon: contact.phone,
      email: contact.email,
      region: contact.regionName,
      makroregion: contact.macroregionName,
    });
    return resource
      .allContacts({})
      .map((contacts) =>
        fileDownload(
          stringify(contacts.map(contactToCsvEntry), { header: true }),
          `kontakty_${new Date().toISOString()}.csv`,
        ),
      );
  });

  return (
    <Box ml={2} mb={2}>
      <Button startIcon={<FontAwesomeIcon icon={faDownload} />} color="primary" onClick={() => downloadCsv.mutate()}>
        Kontakty CSV
      </Button>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  statusIcon: {
    marginRight: theme.spacing(1),
  },
  payerChip: {
    marginLeft: theme.spacing(1),
  },
  table: {
    overflowX: "auto",
    "& ul": {
      width: "100%",
    },
  },
  bold: {
    fontWeight: "bold",
  },
  tableCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    boxSizing: "border-box",
  },
  itemRow: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: "40px",
  },
}));
