export const planMyVisitErrors = ["VisitAlreadyPlanned"] as const;
export const cancelVisitErrors = ["VisitAlreadyCancelled"] as const;

export const startVisitErrors = [
  "VisitAlreadyStarted",
  "UserIsNotTheVisitOwner",
  "VisitIsCancelled",
] as const;
export const endVisitErrors = [
  "VisitAlreadyEnded",
  "VisitNotStarted",
  "UserIsNotTheVisitOwner",
  "VisitIsCancelled",
  "InvalidVisitDuration",
] as const;
export const changeStartTimeErrors = [
  "UserIsNotTheVisitOwner",
  "VisitIsCancelled",
  "StartTimeInTheFuture",
  "InvalidStartTime",
] as const;
export const changeEndTimeErrors = [
  "UserIsNotTheVisitOwner",
  "VisitIsCancelled",
  "EndTimeInTheFuture",
  "InvalidEndTime",
] as const;

export const myVisitQueryErrors = ["VisitNotFound"] as const;
