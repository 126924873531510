import { BaseResource, NestedResourceNode, ResourceActionV2, UuidResourceParam } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";
import Decimal from "decimal.js";

import { DocumentError, OrderSendError } from "./common";

export type ComarchRelation = {
  id: number;
  partnerIln: string;
  documentType: string;
  documentVersion: string;
  documentStandard: string;
};

export type EdiOrderDetail = {
  rawDocument: string;
  lines: {
    uuid: string;
    demandedPrice: Decimal | null;
  }[];
  remarks: string | null;
};

type EdiOrderDetailError = "OrderNotFound" | "UnableToParseDocument";

export const ediConnectorOrderResource = BaseResource(["ediConnector", "orders"], {
  byOrderUuid: UuidResourceParam("orderUuid", {
    skip: ResourceActionV2({
      name: "skip",
      method: "delete",
      path: "",
      responseErrorSerializer: MSerializers.stringOneOf("OrderNotFound", "OrderAlreadySkipped"),
    }),
    retry: ResourceActionV2({
      name: "retry",
      method: "post",
      path: "retry",
      responseErrorSerializer: MSerializers.stringOneOf(
        "DocumentNotFound",
        "DocumentSkipped",
        "DocumentNotFailed",
        "BuyerGlnNotFound",
        "RecipientGlnNotFound",
        "UnableToParseDocument",
      ),
    }),
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      path: "",
      responseValueSerializer: MSerializers.object<EdiOrderDetail>({
        rawDocument: MSerializers.string,
        lines: MSerializers.array(
          MSerializers.object({
            uuid: MSerializers.uuid(),
            demandedPrice: MSerializers.decimal.nullable(),
          }),
        ),
        remarks: MSerializers.string.nullable(),
      }),
      responseErrorSerializer: MSerializers.identity<EdiOrderDetailError>(),
    }),
  }),
  documentErrors: ResourceActionV2({
    name: "documentErrors",
    method: "get",
    path: "documentErrors",
    responseValueSerializer: MSerializers.array(
      MSerializers.object<DocumentError>({
        error: MSerializers.identity<OrderSendError>(),
        orderUuid: MSerializers.uuid(),
        path: MSerializers.string,
        orderNumber: MSerializers.string.nullable(),
      }),
    ),
  }),

  maintenance: NestedResourceNode("maintenance", {
    syncInfiniteDocuments: ResourceActionV2({
      name: "maintenance.syncInfiniteDocuments",
      method: "post",
      path: "syncInfiniteDocuments",
    }),
    syncComarchRelation: ResourceActionV2({
      name: "maintenance.syncComarchRelation",
      method: "post",
      path: "syncComarchRelation",
      requestBodySerializer: MSerializers.object<ComarchRelation>({
        documentStandard: MSerializers.string,
        documentType: MSerializers.string,
        documentVersion: MSerializers.string,
        id: MSerializers.integer,
        partnerIln: MSerializers.string,
      }),
    }),
  }),
});
