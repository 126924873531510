import { Uuid } from "@megarax/common";
import { visitResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Alert, AlertTitle, Button } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";

import { useCustomer } from "@megaron/megarax-v2-resource-hooks";

interface Props {
  alertClassName?: string;
  ignoredVisitUuid?: Uuid;
}

export const UnfinishedVisitsAlertContainer: React.FunctionComponent<Props> = ({
  alertClassName,
  ignoredVisitUuid,
}) => {
  const visitProvider = useResourceProviderV2(visitResource);
  const navigate = useNavigate();

  const { data: unfinishedVisits } = useQuery(
    ["myUnfinishedVisits"],
    () => visitProvider.myUnfinishedVisits().then((r) => r.assertOk().value),
    {
      refetchOnMount: true,
    },
  );
  const relevantVisits = unfinishedVisits?.filter((v) => v.uuid !== ignoredVisitUuid);

  const lastVisit = relevantVisits?.[0];
  const customer = useCustomer(lastVisit?.customerUuid);

  if (lastVisit === undefined) return null;
  const count = relevantVisits!.length;

  const goToVisit = () => {
    if (!lastVisit) return;
    navigate(`/crm/pos/handlowcy/${lastVisit.userId}/wizyty/dzien/${lastVisit.date}/wizyta/${lastVisit.uuid}`);
  };

  return (
    <Alert
      className={alertClassName}
      severity="warning"
      variant="standard"
      action={<Button onClick={goToVisit}>Zobacz</Button>}
      sx={{ mb: 2 }}
    >
      <AlertTitle>Masz niezakończoną wizytę {count > 1 ? `(${count})` : ""}</AlertTitle>
      Zakończ lub anuluj wizytę {customer?.name} ({lastVisit?.date})
    </Alert>
  );
};
