export const formatGoogleDuration = (googleDuration: number, additionalMinutes?: number) => {
  const allMinutes = Math.ceil(googleDuration / 60) + (additionalMinutes ?? 0);
  const hours = Math.floor(allMinutes / 60);
  const remainingMinutes = Math.floor(allMinutes % 60);

  const hoursPart = hours ? [`${hours}h`] : [];
  const minutesPart = remainingMinutes ? [`${remainingMinutes}min`] : [];
  return [...hoursPart, ...minutesPart].join(" ");
};

export const routeDistancesFromDirections = (
  legs: google.maps.DirectionsLeg[],
  returnLeg: google.maps.DirectionsLeg,
) => {
  return [
    ...legs.map((leg) => ({
      duration: leg.duration?.value ?? 0,
      distance: leg.distance?.value ?? 0,
    })),
    { duration: returnLeg.duration?.value ?? 0, distance: returnLeg.distance?.value ?? 0 },
  ];
};

export const notEmpty = <T>(x: T | null): x is T => x !== null;
