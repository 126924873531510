import { Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

import { visitLocationSerializer } from "./customerDetail";
import { CustomerStatus, customerStatuses } from "./customerStatus";
import { VisitLocation } from "./inputs";

export interface CustomerBasic {
  uuid: Uuid;
  name: string;
  visitLocation: VisitLocation | null;
  status: CustomerStatus;
}

export const customerBasicSerializer = MSerializers.object<CustomerBasic>({
  uuid: uuidSerializer,
  name: MSerializers.string,
  visitLocation: visitLocationSerializer.nullable(),
  status: MSerializers.stringOneOf(...customerStatuses),
});
