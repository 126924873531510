import { useTheme } from "@emotion/react";
import { BsThreeDots } from "react-icons/bs";
import tinycolor from "tinycolor2";

type Props = {
  header: string;
};

export const HistorySectionHeader: React.FC<Props> = ({ header }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        position: "relative",
        padding: "4px 0",
        display: "flex",
        alignItems: "center",
        gap: "0.625rem",
        fontSize: "12px",
        color: `${tinycolor(theme.colors.primary).setAlpha(0.3)}`,
      }}
    >
      <hr
        css={{
          height: "calc(100% - 12px)",
          width: "1px",
          background: `${tinycolor(theme.colors.primary).setAlpha(0.3)}`,
          position: "absolute",
          left: "15px",
          top: "20px",
          border: "none",
        }}
      />
      <hr
        css={{
          width: "calc(100% - 42px)",
          height: 0,
          border: "none",
          borderBottom: `1px dashed ${tinycolor(theme.colors.primary).setAlpha(0.3)}`,
          position: "absolute",
          left: "42px",
          top: "50%",
          margin: 0,
          transform: "translate(0, -50%)",
        }}
      />
      <div
        css={{
          height: "20px",
          width: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BsThreeDots size={16} />
      </div>
      <div css={{ margin: "0 auto", background: theme.colors.background, zIndex: 1, padding: "0 8px" }}>{header}</div>
    </div>
  );
};
