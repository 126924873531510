import { AuthAttribute, pickRoleAttrs } from "@megaron/access-control";
import { appLinkTreesObject } from "@megaron/megarax-links";

type AppLinkTreeKeys = keyof typeof appLinkTreesObject;

export const notificationDetailsMap: Record<
  string,
  {
    name: string;
    app: AppLinkTreeKeys;
    isApp: boolean;
    isEmail: boolean;
    isWebPush: boolean;
    authorizedUserAttributes: AuthAttribute[];
    links?: string[];
  }
> = {
  crmRegionAssigned: {
    name: "Klient przypisany do Twojego regionu",
    app: "crm",
    isApp: true,
    isEmail: false,
    isWebPush: false,
    authorizedUserAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
  },
  crmInteractionMention: {
    name: "Oznaczenie w interakcji w CRM",
    app: "crm",
    isApp: true,
    isEmail: false,
    isWebPush: true,
    authorizedUserAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
  },
  invoiceNew: {
    name: "Nowa faktura",
    app: "invoices",
    isApp: false,
    isEmail: false,
    isWebPush: false,
    authorizedUserAttributes: ["userType:megarax"],
    links: ["Moje faktury"],
  },
  invoiceThreadMention: {
    name: "Oznaczenie w komentarzu wątku faktury",
    app: "invoices",
    isApp: true,
    isEmail: false,
    isWebPush: true,
    authorizedUserAttributes: ["userType:megarax"],
    links: ["Moje faktury"],
  },
  testWebPush: {
    name: "test webpush",
    app: "external",
    isApp: false,
    isEmail: false,
    isWebPush: true,
    authorizedUserAttributes: ["userType:megarax"],
  },
  test: {
    name: "test",
    app: "external",
    isApp: true,
    isEmail: true,
    isWebPush: true,
    authorizedUserAttributes: ["userType:megarax"],
  },
};

export type NotificationType = keyof typeof notificationDetailsMap;
export const notificationTypes: NotificationType[] = Object.keys(notificationDetailsMap) as NotificationType[];

export type NotificationEmailsMapType = {
  [key in NotificationType]: {
    messageType: string;
    templateId: number;
  };
};

export const notificationEmailsMap: NotificationEmailsMapType = {
  test: { messageType: "test", templateId: 11 },
};

export type NotificationWebPushMapType = {
  [key in NotificationType]: {
    image: string | null;
    link: string | null;
    title: string;
    body: string;
  };
};

export const notificationWebPushMap: NotificationWebPushMapType = {
  test: {
    image: null,
    link: null,
    title: "test",
    body: "test",
  },
  testWebPush: {
    image: null,
    link: null,
    title: "testowe powiadomienie",
    body: "treść testowego powiadomienia",
  },
  invoiceThreadMention: {
    image: null,
    link: "https://megarax.net/faktury/watki?filters=%7B%22page%22%3A0%2C%22searchText%22%3A%22%22%2C%22isDigital%22%3A%22any%22%2C%22isReceived%22%3A%22any%22%2C%22isPosted%22%3A%22any%22%2C%22isOverdue%22%3A%22any%22%2C%22isMine%22%3A%22true%22%7D",
    title: "Zostałeś oznaczony",
    body: "Zostałeś oznaczony w komentarzu wątku faktury",
  },
  crmInteractionMention: {
    image: null,
    link: "https://megarax.net/crm/customers?filters=%7B%22page%22%3A0%2C%22searchText%22%3A%22%22%2C%22isPro%22%3Afalse%2C%22isAffiliate%22%3Afalse%7D",
    title: "Zostałeś oznaczony",
    body: "Zostałeś oznaczony w interakcji w CRM",
  },
};
