import { MSerializers } from "@megarax/serializers";
import Decimal from "decimal.js";

export type VisitAddress = {
  name: string;
  locality: string;
  street: string;
  postalCode: string;
  country: string;
  lat: Decimal;
  lng: Decimal;
};

export const visitAddressSerialziers = MSerializers.object<VisitAddress>({
  country: MSerializers.string,
  locality: MSerializers.string,
  name: MSerializers.string,
  postalCode: MSerializers.string,
  street: MSerializers.string,
  lat: MSerializers.decimal,
  lng: MSerializers.decimal,
});
