import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { MdOutlineGroupAdd, MdOutlinePersonAddAlt } from "react-icons/md";
import { useQueryClient } from "react-query";

import { Avatar, AvatarGroup } from "@megaron/dash-avatar";
import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SectionHeader } from "@megaron/dash-page";
import { SearchButton } from "@megaron/dash-search";
import { DocsSelectDialog } from "@megaron/dash-select";
import { useToast } from "@megaron/dash-toast";
import { getDocId } from "@megaron/docs-contracts";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { ThreadDto } from "@megaron/invoices-contracts";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  thread: ThreadDto;
  queryKey: string | string[];
};

export const ParticipantsSection: React.FC<Props> = (props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const auth = useContext(IamAuthContext);

  const { profiles } = useProfiles();
  const { isDesktop } = useDeviceType();
  const toast = useToast();

  const sortedParticipants = props.thread.participants
    .sort((a, b) => {
      const dateA = new Date(a.invitedAt).getTime();
      const dateB = new Date(b.invitedAt).getTime();
      return dateA - dateB;
    })
    .map((participant) => participant.user);

  const participantsMutation = useClientManager("invoices").inviteParticipant().useMutation();

  const emailOrderMap = new Map(sortedParticipants.map((email, index) => [email, index]));

  const participantsData = profiles(sortedParticipants) ?? [];

  const userEmail = auth.iamUser?.userType === "megarax" ? auth.iamUser.email : undefined;
  const isParticipant = userEmail && props.thread.participants.map((p) => p.user).includes(userEmail);

  const sortedParticipantsData = participantsData.sort((a, b) => {
    const indexA = emailOrderMap.get(a.email) ?? -1;
    const indexB = emailOrderMap.get(b.email) ?? -1;
    return indexA - indexB;
  });

  const inviteParticipant = async (participant: string) => {
    participantsMutation.mutate(
      {
        user: participant,
        threadUuid: props.thread.uuid,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(props.queryKey);
        },
        onError: () => {
          toast.error("Error", "Coś poszło nie tak");
        },
      },
    );
  };

  const addParticipantDialog = useDialogRoute("/zapros-uzytkownika", ({ onClose }) => (
    <DocsSelectDialog
      docType={["user"]}
      initiallySelectedDocIds={participantsData.map((participant) => getDocId("user", participant.email))}
      variant="multi-select"
      label="Zaproś użytkowników"
      onSelectedChange={(options) => {
        const optionsValues = options.map((option) => option.value);
        optionsValues.forEach((option) => {
          inviteParticipant(option);
        });
      }}
      isUnselectDisabled
      isOpen
      onClose={onClose}
      noTrigger
    />
  ));

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isDesktop && (
        <SectionHeader
          isHr
          css={{ h2: { fontSize: "16px" } }}
          actions={
            !isParticipant &&
            userEmail && (
              <Button
                css={{ fontSize: "14px" }}
                icon={<MdOutlinePersonAddAlt />}
                variant="outline"
                isLoading={participantsMutation.isLoading}
                onClick={() => inviteParticipant(userEmail)}
              >
                Dołącz
              </Button>
            )
          }
        >
          Uczestnicy
        </SectionHeader>
      )}
      {!isDesktop ? (
        <div css={{ display: "flex", gap: "8px", alignItems: "center", paddingLeft: "8px" }}>
          <AvatarGroup profiles={sortedParticipantsData} />
          <button
            onClick={addParticipantDialog.open}
            css={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              border: "none",
              padding: "0.25rem",
              width: "30px",
              height: "30px",
              background: theme.colors.primaryLight,
              flexShrink: 0,
            }}
          >
            <MdOutlineGroupAdd size={16} css={{ color: theme.colors.primary }} />
          </button>

          {addParticipantDialog.element}
        </div>
      ) : (
        <div css={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <div css={{ display: "flex", flexDirection: "column", padding: "0 8px", gap: "10px" }}>
            {sortedParticipantsData.map((profile) => (
              <div css={{ display: "flex", gap: "10px", alignItems: "center" }} key={profile.email}>
                <Avatar src={profile.profilePictureUrl} />
                <span css={{ fontSize: "14px", fontWeight: 400 }}>
                  {profile.firstName} {profile.lastName}
                </span>
              </div>
            ))}
          </div>

          <SearchButton variant="pale" onClick={addParticipantDialog.open} inputText="Zaproś użytkowników..." />

          {addParticipantDialog.element}
        </div>
      )}
    </>
  );
};
