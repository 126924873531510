import { Backdrop, Button, CircularProgress, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  loading: boolean;
  error?: boolean;
  onClick?: () => void;
}

export const DialogLoading: React.FC<Props> = ({ loading, error, onClick }) => {
  const history = useHistory();
  const classes = useStyles();

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), 500);
  }, []);

  return (
    <Backdrop
      open={visible && loading}
      className={classes.backdrop}
      onClick={onClick}
    >
      {error ? (
        <Paper className={classes.error}>
          <Typography>Wystąpił błąd podczas ładowania. :(</Typography>
          <Button onClick={() => history.goBack()}>Powrót</Button>
        </Paper>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Backdrop>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    error: {
      padding: theme.spacing(2, 2, 1, 2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  };
});
