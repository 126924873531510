import { useTheme } from "@emotion/react";
import { IconType } from "react-icons/lib";

type Props = {
  Icon: IconType;
  isFullySigned: boolean;
  className?: string;
  remainingDays: number;
  isThreadOpen: boolean;
};

export const SignatureTile: React.FC<Props> = ({ Icon, isFullySigned, className, remainingDays, isThreadOpen }) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "1.5rem",
        height: "1.5rem",
        flexShrink: 0,
        borderRadius: "100%",
        opacity: isFullySigned ? 1 : 0.5,
        backgroundColor: isFullySigned ? theme.colors.success : theme.colors.primaryLight,
        color: isFullySigned ? "white" : theme.colors.primary,
      }}
    >
      <Icon size={12} />
    </div>
  );
};
