import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import { MdCable, MdCamera, MdPause, MdPlayArrow, MdSkipNext } from "react-icons/md";

import { DescriptionField } from "@megaron/dash-description-list";
import { IconButton } from "@megaron/dash-form";
import { Card } from "@megaron/dash-page";
import { ScanStatus } from "@megaron/loyalty-qr-sync-contracts";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  isConnected: boolean;
  name: string;
  scanStatus: ScanStatus;
  lineId: string;
  cameraId: string;
};

export const CameraCard: React.FunctionComponent<Props> = (props) => {
  const status = props.isConnected ? props.scanStatus : "disconnected";

  const theme = useTheme();

  const pauseMutation = useClientManager("qrSync").pauseCamera().useMutation();
  const resumeMutation = useClientManager("qrSync").resumeCamera().useMutation();

  useEffect(() => {
    // keyboard shortcuts
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === "r") resumeMutation.mutate({ cameraId: props.cameraId, lineId: props.lineId });
      if (e.key === "p") pauseMutation.mutate({ cameraId: props.cameraId, lineId: props.lineId });
    };
    window.addEventListener("keyup", onKeyUp);
    return () => window.removeEventListener("keyup", onKeyUp);
  }, []);

  const statusText = {
    ok: "OK",
    paused: "Pauza",
    failedScan: "Nieudany skan",
    duplicateCode: "Powtórzony kod",
    invalidQr: "Nieprawidłowy kod QR",
    disconnected: "Brak połączenia",
  }[status];

  const variant = status === "ok" ? "secondary" : "primary";
  const foregroundColor = status === "ok" ? theme.colors.primary : "white";
  const backgroundColor = status === "ok" ? theme.colors.primary : theme.colors.danger;

  const onButtonClick = () => {
    if (props.scanStatus === "ok") pauseMutation.mutate({ cameraId: props.cameraId, lineId: props.lineId });
    else resumeMutation.mutate({ cameraId: props.cameraId, lineId: props.lineId });
  };

  const isScanFailed = ["failedScan", "duplicateCode", "invalidQr"].includes(props.scanStatus);

  return (
    <Card
      color={backgroundColor}
      variant={variant}
      css={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center" }}
    >
      <MdCamera size="1.5rem" color={foregroundColor} />
      <DescriptionField label={props.name} color={foregroundColor}>
        <span css={{ fontWeight: "700", forgroundColor: foregroundColor }}>{statusText}</span>
      </DescriptionField>
      <IconButton css={{ color: foregroundColor, marginLeft: "auto" }} onClick={onButtonClick}>
        {isScanFailed ? <MdSkipNext /> : props.scanStatus === "paused" ? <MdPlayArrow /> : <MdPause />}
      </IconButton>
    </Card>
  );
};
