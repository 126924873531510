import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

const FailedConnectionView: React.FC = () => {
  const classes = useStyles();

  const logout = () =>
    gapi.auth2.getAuthInstance().currentUser.get().disconnect();

  return (
    <div className={classes.wrapper}>
      <div className={`${classes.logo} ${classes.disabled}`}>
        <Typography variant="h6">Megaron</Typography>
      </div>
      <Typography className={classes.logoSubtitle} color="textSecondary">
        Nie udało się połączyć z serwerami Megaraxu
      </Typography>
      <Button className={classes.logoutButton} onClick={logout}>
        Wyloguj
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      height: "80vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
    },
    logo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "6rem",
      height: "6rem",
      position: "absolute",
      borderRadius: "6rem",
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    disabled: {
      backgroundColor: theme.palette.grey[500],
    },
    logoSubtitle: {
      marginTop: "12rem",
    },
    logoutButton: {
      marginTop: theme.spacing(2),
    },
  };
});

export default FailedConnectionView;
