import { taskResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import { CircularProgress, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { MyTasksView } from "./MyTasksView";

export const MyTasksViewContainer = () => {
  const classes = useStyles();

  const tasksProvider = useResourceProviderV2(taskResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const { value: result, loading } = useAsyncLoad(() => tasksProvider.myTasks().mapError(handleError), []);

  if (result === undefined || result.isFailure)
    return (
      <div className={classes.loadingScreen}>
        <CircularProgress />
      </div>
    );

  return (
    <>
      {loading && <LinearProgress />}
      <MyTasksView tasks={result.value.items}></MyTasksView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  loadingScreen: {
    textAlign: "center",
    marginTop: "6rem",
  },
}));
