import { CustomerDetails as Customer } from "@megaron/crm-contracts";

import { CustomerAddress } from "./CustomerAddress";
import { CustomerPersonalDetails } from "./CustomerPersonalDetails";

type Props = {
  customer: Customer;
  queryKey: string | string[];
};

export const CustomerDetails: React.FC<Props> = ({ customer, queryKey }) => {
  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <CustomerPersonalDetails customer={customer} queryKey={queryKey} />
      <CustomerAddress customer={customer} queryKey={queryKey} />
    </div>
  );
};
