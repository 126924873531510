import { Validator } from "..";

export const stringValidator = <T>(): Validator<
  string | undefined,
  string,
  "required"
> => (input) => {
  if (typeof input !== "string")
    return { failure: "required", successful: false };
  return { value: input, successful: true };
};
