import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { differenceInMinutes } from "date-fns";
import React from "react";

interface Props {
  startTime: Date | null;
  endTime: Date | null;
}

export const VisitDuration: React.FunctionComponent<Props> = ({ startTime, endTime }) => {
  const [now, setNow] = React.useState(new Date());
  const classes = useStyles();
  const durationMinutes = startTime && differenceInMinutes(endTime ?? now, startTime);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (!durationMinutes) return null;

  return (
    <Typography variant="subtitle1" marginTop={3} textAlign={"center"}>
      {durationMinutes} min
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({}));
