import { Item } from "@megaron/crm-contracts";
import { DescriptionField } from "@megaron/dash-description-list";
import { AutocompleteTextField, SwitchField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SectionHeader } from "@megaron/dash-page";
import { useServiceClient } from "@megaron/react-clients";

type Props = {
  item: Item;
  values: {
    index: string;
    group: string;
    isArchived: boolean;
  };
  handlers: {
    onIndexChange: (index: string) => void;
    onGroupChange: (group: string) => void;
    onArchivedChange: (isArchived: boolean) => void;
  };
};

export const TradeItemDetails: React.FC<Props> = ({ item, values, handlers }) => {
  const { isMobile } = useDeviceType();

  const docs = useServiceClient("docs");

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      <SwitchField
        label="Aktywny?"
        falseLabel="Zarchiwizowany"
        trueLabel="Aktywny"
        selectedValue={!values.isArchived}
        onChange={(v) => handlers.onArchivedChange(!v)}
      />
      <SectionHeader isHr>Dane podstawowe</SectionHeader>
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "0.625rem", width: "100%" }}>
          <DescriptionField label="Jednostka handlowa" css={{ width: "100%" }}>
            {item.tradeItemName}
          </DescriptionField>
          <DescriptionField label="Id" css={{ width: "100%" }}>
            {item.itemId}
          </DescriptionField>
        </div>
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "0.625rem", width: "100%" }}>
          <DescriptionField label="EAN" css={{ width: "100%" }}>
            {item.gtin}
          </DescriptionField>
          <DescriptionField label="Numer BC" css={{ width: "100%" }}>
            {item.bcNumber}
          </DescriptionField>
        </div>
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "0.625rem", width: "100%" }}>
          <AutocompleteTextField
            label="Indeks"
            value={values.index}
            onChange={handlers.onIndexChange}
            css={{ width: "100%" }}
            keyName="item-index"
            getOptions={async (searchText) => {
              const results = await docs.itemsAutocomplete({ text: searchText, field: "productId" });

              return results.value || [];
            }}
          />
          <AutocompleteTextField
            label="Grupa"
            value={values.group}
            onChange={handlers.onGroupChange}
            css={{ width: "100%" }}
            keyName="item-group"
            getOptions={async (searchText) => {
              const results = await docs.itemsAutocomplete({ text: searchText, field: "productGroup" });

              return results.value || [];
            }}
          />
        </div>
      </div>
    </div>
  );
};
