import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const publicProfileActions = {
  publicProfileQuery: HttpAction({
    method: "get",
    path: "/publicProfiles/uuid/:uuid",
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
    }),
    valueSerializer: Serializers.object({
      uuid: uuidSerializer,
      isPro: Serializers.boolean,
      affiliateCodeIds: Serializers.array(Serializers.string),
      proNumber: Serializers.integer.nullable(),
      isAffiliate: Serializers.boolean,
    }),
  }),
};
