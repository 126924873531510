import { Delete } from "@mui/icons-material";
import { Box, Container, List, ListItemButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  isCancelled: boolean;
  onCancel: () => void;
}

export const ActionsMenu: React.FunctionComponent<Props> = ({ isCancelled, onCancel }) => {
  const classes = useStyles();

  return (
    <List disablePadding>
      {!isCancelled && (
        <ListItemButton onClick={onCancel}>
          <Delete className={classes.icon} />
          Anuluj wizytę
        </ListItemButton>
      )}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));
