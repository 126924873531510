import { BaseResource, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";
import Decimal from "decimal.js";

import { Month, monthSerializer } from "./month";

export type MonthTargetList = {
  month: Month;

  targets: {
    [regionUuid: string]: Decimal | null;
  };
};

export const targetsSerializer = MSerializers.mapObject(MSerializers.decimal.nullable(), MSerializers.uuid());

export const monthTargetListSerializer = MSerializers.object<MonthTargetList>({
  targets: targetsSerializer,
  month: monthSerializer,
});

export const targetTableResource = BaseResource(["profitMargin", "targetTable"], {
  retrieve: ResourceActionV2({
    name: "retrieve",
    method: "get",
    path: "",
    responseValueSerializer: MSerializers.array(monthTargetListSerializer),
  }),
  update: ResourceActionV2({
    name: "update",
    method: "put",
    path: "",
    requestBodySerializer: MSerializers.array(monthTargetListSerializer),
  }),
});
