import { Serializers, SerializerValue } from "@megaron/serializers";

export const webPushNotificationPaylodSerializer = Serializers.object({
  title: Serializers.string,
  body: Serializers.string,
  image: Serializers.string.nullable(),
  link: Serializers.string.nullable(),
  actions: Serializers.array(
    Serializers.object({
      title: Serializers.string,
      action: Serializers.stringOneOf("openLink"),
    }),
  ),
});

export type WebPushNotificationPayload = SerializerValue<typeof webPushNotificationPaylodSerializer>;
