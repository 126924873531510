import { useQuery } from "react-query";

import { visitResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";

export const useUserVisits = (date: Date, userId: number | undefined) => {
  const provider = useResourceProviderV2(visitResource);

  const { data, isFetching, refetch } = useQuery(
    ["visitsUserDay", date, userId],
    () => provider.userDay({ userId: userId!, date }).then((r) => r.assertOk().value),
    {
      enabled: userId !== undefined,
    },
  );

  return { visits: data, isLoading: isFetching, reload: refetch };
};
