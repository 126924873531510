import React from "react";

import { TextField } from "./TextField";

type ValueProps =
  | {
      value: number;
      onChange: (value: number) => void;
      isNullable?: false;
    }
  | {
      value: number | null;
      onChange: (value: number | null) => void;
      isNullable: true;
    };

type Props = ValueProps & {
  label?: string;
  className?: string;
};

export const IntegerField: React.FunctionComponent<Props> = (props) => {
  const handleChange = (value: string) => {
    const int = parseInt(value);
    if (isNaN(int)) {
      if (props.isNullable) props.onChange(null);
      else props.onChange(0);
      return;
    }
    props.onChange(parseInt(value));
  };

  return (
    <TextField
      {...props}
      inputElementProps={{ type: "number" }}
      value={props.value?.toString() ?? ""}
      onChange={handleChange}
    />
  );
};
