import React from "react";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { Avatar } from "@megaron/dash-avatar";
import { useDialogRoute } from "@megaron/dash-dialog";
import { EntityList } from "@megaron/dash-entity-list";
import { Button } from "@megaron/dash-form";
import { Page, PageHeader } from "@megaron/dash-page";
import { SearchBox } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

import { NewAccountDialog } from "./NewAccountDialog";

export const AccountsHome = () => {
  const [search, setSearch] = React.useState("");
  const accountsQuery = useClientManager("iam").accountsQuery().useQuery({ searchText: search });

  const dialog = useDialogRoute("nowe-konto", ({ onClose }) => <NewAccountDialog onClose={onClose} />);

  return (
    <Page>
      <PageHeader
        actions={
          <Button icon={<MdAdd />} onClick={dialog.open}>
            Nowe
          </Button>
        }
      >
        Konta użytkowników
      </PageHeader>

      <SearchBox onChange={setSearch} value={search} css={{ marginBottom: "1rem" }} />
      <QuerySkeleton query={accountsQuery}>
        {(accounts) => (
          <EntityList
            items={accounts}
            getKey={(u) => u.email}
            columns={(item) => [
              {
                header: "Email",
                isPrimary: true,
                content: (
                  <Link
                    to={`email/${item.email}`}
                    css={{ display: "flex", alignItems: "center", gap: "0.5rem", opacity: item.isActive ? 1 : 0.4 }}
                  >
                    <Avatar src={item.profilePictureUrl || null} size="1.25rem" />
                    {item.email}
                  </Link>
                ),
              },
              { header: "Aktywny?", content: item.isActive ? "Aktywny" : "Nieaktywny" },
            ]}
          />
        )}
      </QuerySkeleton>
      {dialog.element}
    </Page>
  );
};
