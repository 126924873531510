import { useTheme } from "@emotion/react";
import { useOs } from "@mantine/hooks";
import React, { useEffect } from "react";
import { MdOutlineSearch } from "react-icons/md";
import tinycolor from "tinycolor2";

import { useDeviceType } from "@megaron/dash-mq";

type SearchVariant = "grayscale" | "transparent" | "primary" | "pale";

type Shortcut = {
  key: string;
  modifier?: "meta" | "ctrl";
};

type Props = {
  onClick: () => void;
  className?: string;
  variant?: SearchVariant;
  shortcut?: Shortcut;
  inputText?: string;
};

export const SearchButton: React.FC<Props> = ({ onClick, className, variant = "primary", shortcut, inputText }) => {
  const theme = useTheme();
  const { isDesktop } = useDeviceType();
  const os = useOs();
  const isMacOs = os === "macos";

  useEffect(() => {
    if (!shortcut) {
      return;
    }

    const callback = (e: KeyboardEvent) => {
      const isMac = isMacOs && shortcut.modifier === "meta";
      const isCtrl = !isMacOs && shortcut.modifier === "ctrl";
      if (e.key === shortcut.key && (isMac ? e.metaKey : e.ctrlKey)) {
        e.preventDefault();
        onClick();
      }
    };

    document.addEventListener("keydown", callback);

    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, [shortcut, isMacOs, onClick]);

  const getTextColor = (): string => {
    switch (variant) {
      case "grayscale":
        return theme.colors.secondaryText;
      case "transparent":
        return "rgba(255, 255, 255, 0.6)";
      case "pale":
        return "rgba(0, 0, 0, 0.5)";
      case "primary":
      default:
        return theme.colors.primary;
    }
  };

  const getBackgroundColor = (): string => {
    switch (variant) {
      case "grayscale":
        return "white";
      case "transparent":
        return "rgba(255, 255, 255, 0.15)";
      case "pale":
        return tinycolor(theme.colors.primary).setAlpha(0.05).toString();
      case "primary":
      default:
        return theme.colors.primaryLight;
    }
  };

  return (
    <button
      onClick={onClick}
      css={{
        border: "none",
        margin: 0,
        padding: 0,
        overflow: "visible",
        background: "transparent",
        color: "inherit",
        font: "inherit",
        lineHeight: "normal",
        width: "100%",
        cursor: "pointer",
      }}
      className={className}
    >
      <div css={{ alignItems: "center", position: "relative", flex: "1" }}>
        <MdOutlineSearch
          css={{
            position: "absolute",
            left: "1rem",
            top: "50%",
            transform: "translateY(-50%)",
            color: getTextColor(),
          }}
        />
        <div
          css={{
            padding: "0.75rem 3rem 0.75rem 2.5rem",
            border: "none",
            background: getBackgroundColor(),
            borderRadius: theme.smallBorderRadius,
            fontSize: "1rem",
            width: "100%",
            margin: "1px 0",
            textAlign: "left",
            color: getTextColor(),
          }}
        >
          {inputText ?? "Szukaj..."}
        </div>
        {isDesktop && shortcut && <SearchShortcut shortcut={shortcut} variant={variant} />}
      </div>
    </button>
  );
};

export const SearchShortcut = ({ shortcut, variant = "primary" }: { shortcut?: Shortcut; variant?: SearchVariant }) => {
  const theme = useTheme();
  const os = useOs();
  const isMacOs = os === "macos";

  if (!shortcut) return null;

  const modifier = shortcut.modifier === "meta" ? (isMacOs ? "Cmd" : "Ctrl") : "Ctrl";

  return (
    <div
      css={{
        position: "absolute",
        right: "1rem",
        top: "50%",
        transform: "translateY(-50%)",
        display: "flex",
        gap: "0.125rem",
        alignItems: "center",
      }}
    >
      <div
        css={{
          backgroundColor: variant === "grayscale" ? "black" : theme.colors.primary,
          borderRadius: theme.extraSmallBorderRadius,
          padding: "2px 4px",
          color: "white",
          fontSize: "0.75rem",
          fontWeight: 700,
        }}
      >
        {modifier}
      </div>
      <div
        css={{ fontSize: "0.875", color: variant === "grayscale" ? theme.colors.secondaryText : theme.colors.default }}
      >
        +
      </div>
      <div
        css={{
          backgroundColor: variant === "grayscale" ? "black" : theme.colors.primary,
          borderRadius: theme.extraSmallBorderRadius,
          padding: "2px 4px",
          color: "white",
          fontSize: "0.75rem",
          fontWeight: 700,
        }}
      >
        {shortcut.key.toUpperCase()}
      </div>
    </div>
  );
};
