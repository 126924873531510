import { useContext, useState } from "react";
import { MdApi } from "react-icons/md";

import { useDialogRoute } from "@megaron/dash-dialog";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { ThreadDto } from "@megaron/invoices-contracts";

import { AddToBcDialog } from "./AddToBcDialog";
import { Checkbox } from "./Checkbox";

type Props = {
  thread: ThreadDto;
  queryKey: string | string[];
};

export const AddedToBcCheckbox: React.FC<Props> = ({ thread, queryKey }) => {
  const [isAddedToBc, setIsAddedToBc] = useState(!!thread.bcInvoice);

  const auth = useContext(IamAuthContext);

  const addToBcDialog = useDialogRoute("/dodaj-do-bc", ({ onClose }) => (
    <AddToBcDialog thread={thread} onClose={onClose} onSuccess={() => setIsAddedToBc(true)} queryKey={queryKey} />
  ));

  const userRoles = auth.iamUser?.userType === "megarax" ? auth.iamUser?.roles : undefined;

  const allowAction = userRoles?.includes("invoices.coordinator") || userRoles?.includes("invoices.admin");

  if (thread.status !== "approved") {
    return null;
  }

  return (
    <>
      <Checkbox
        isChecked={isAddedToBc}
        icon={<MdApi size={12} css={{ color: "white" }} />}
        isDisabled={isAddedToBc || !allowAction}
        label={isAddedToBc ? "Faktura dodana do BC" : "Wyślij fakturę do BC"}
        onCheck={() => {
          if (!isAddedToBc) {
            addToBcDialog.open();
          }
        }}
      />
      {addToBcDialog.element}
    </>
  );
};
