import { Chat as ChatIcon } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  children?: React.ReactNode;
}

export const NoThreadsScreen: React.FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box display="flex" height="120px" alignItems="center">
      <Box textAlign="center" marginX="auto">
        <Box textAlign="center">
          <ChatIcon fontSize="large" />
        </Box>
        <Typography align="center" color="textSecondary" variant="caption">
          Brak wiadomości.
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({}));
