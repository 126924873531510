import { css, useTheme } from "@emotion/react";
import React from "react";
import { MdCheck } from "react-icons/md";

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  customCheckMark?: React.ReactNode;
  label?: string;
};

export const Checkbox: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const checkboxContainerStyles = css({
    position: "relative",
    display: "inline-block",
    borderRadius: theme.extraSmallBorderRadius,
    width: "16px",
    height: "16px",
    border: "none",
    backgroundColor: theme.colors.primaryLight,
    div: {
      visibility: "hidden",
    },
    '&[data-checked="true"] div': {
      borderRadius: theme.extraSmallBorderRadius,
      backgroundColor: theme.colors.primary,
      visibility: "visible",
    },
  });

  const customCheckMarkStyles = css({
    position: "absolute",
    inset: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10%",
    color: "white",
  });

  return (
    <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
      <button
        role="checkbox"
        type="button"
        aria-checked={props.value}
        data-checked={props.value.toString()}
        css={checkboxContainerStyles}
        onClick={() => {
          props.onChange(!props.value);
        }}
        className={props.className}
      >
        <div css={customCheckMarkStyles}>{props.customCheckMark ?? <MdCheck />}</div>
      </button>
      {props.label && (
        <label css={{ fontSize: "0.875rem", color: theme.colors.primary, fontFamily: theme.displayFontFamily }}>
          {props.label}
        </label>
      )}
    </div>
  );
};
