export const changeBreakErrors = [
  "InvalidWorkTime",
  "InvalidOdometer",
  "InvalidVisitTime",
] as const;
export const removeBreakErrors = [
  "InvalidWorkTime",
  "InvalidOdometer",
  "InvalidVisitTime",
] as const;
export const changeStartErrors = [
  "InvalidWorkTime",
  "InvalidOdometer",
  "InvalidVisitTime",
] as const;
export const changeEndErrors = ["InvalidWorkTime", "InvalidOdometer", "InvalidVisitTime"] as const;
export const changeVisitErrors = [
  "InvalidWorkTime",
  "InvalidOdometer",
  "InvalidVisitTime",
] as const;
export const removeVisitErrors = [
  "InvalidWorkTime",
  "InvalidOdometer",
  "InvalidVisitTime",
] as const;
export const changeEventErrors = ["InvalidEventTime"] as const;
export const refreshVisitErrors = ["VisitNotFound"] as const;
