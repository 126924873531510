import { Skeleton } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { useRouteMatch } from "react-router-dom";

import { Uuid } from "@megarax/common";
import { Dialog, useDialog, useDialogWithRoute } from "@megarax/ui-components";

import { ContactCreatorDialog } from "./ContactCreatorDialog";
import { ContactList } from "./ContactList";
import { useCustomerContacts } from "./useCustomerContacts";

interface Props {
  customerUuid: Uuid;
}

export const ContactListContainer: React.FC<Props> = ({ customerUuid }) => {
  const { retrieveCustomerContacts, saveContact, removeContact } = useCustomerContacts();
  const newContactDialog = useDialogWithRoute<{}>(`kontakt/nowy`, (props) => (
    <ContactCreatorDialog
      {...props}
      contact={null}
      saveContact={saveContact({ onSuccess: contacts.refetch })(customerUuid)}
    />
  ));
  const editContactDialog = useDialogWithRoute<{ contactUuid: Uuid }>(
    `kontakt/:contactUuid`,
    (props) => (
      <>
        {contacts.data?.value && (
          <ContactCreatorDialog
            {...props}
            contact={
              contacts.data?.value?.contacts.find(
                (contact) => contact.uuid === props.contactUuid,
              ) ?? null
            }
            saveContact={saveContact({ onSuccess: contacts.refetch })(customerUuid)}
          />
        )}
      </>
    ),
  );
  const contacts = useQuery(["contact-list", customerUuid], () =>
    retrieveCustomerContacts(customerUuid),
  );

  return (
    <>
      {editContactDialog.element}
      {newContactDialog.element}
      {contacts.data?.value ? (
        <ContactList
          contactList={contacts.data.value}
          removeContact={removeContact({ onSuccess: contacts.refetch })(customerUuid)}
          navigation={{
            openCreateNewDialog: () => newContactDialog.open(),
            openEditDialog: (contactUuid) => editContactDialog.open({ contactUuid }),
          }}
        />
      ) : (
        <Skeleton height="100px" />
      )}
    </>
  );
};
