import { useTheme } from "@emotion/react";

type Props = {
  onCheck: (newIsChecked: boolean) => void;
  isChecked: boolean;
  icon: React.ReactNode;
  label: string;
  showSuccessColor?: boolean;
  isDisabled: boolean;
};

export const Checkbox: React.FC<Props> = ({ onCheck, isChecked, showSuccessColor, isDisabled, icon, label }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        gap: "0.625rem",
        padding: "0.75rem",
        borderRadius: theme.smallBorderRadius,
        alignItems: "center",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.colors.border,
        color: isChecked
          ? showSuccessColor
            ? theme.colors.success
            : theme.colors.primary
          : isDisabled
          ? theme.colors.secondaryText
          : theme.colors.primary,
        opacity: isDisabled && !isChecked ? 0.5 : 1,
      }}
    >
      <button
        onClick={() => onCheck(!isChecked)}
        css={{
          cursor: isDisabled ? "default" : "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "100%",
          border: "none",
          padding: "0.25rem",
          width: "1.25rem",
          height: "1.25rem",
          background: isChecked
            ? showSuccessColor
              ? theme.colors.success
              : theme.colors.primary
            : isDisabled
            ? theme.colors.border
            : "white",
          flexShrink: 0,
        }}
      >
        {isChecked && icon}
      </button>
      <span css={{ fontWeight: isChecked ? 700 : 400, fontSize: "0.875rem" }}>{label}</span>
    </div>
  );
};
