import { useTheme } from "@emotion/react";
import { stringify } from "csv/browser/esm/sync";
import { format } from "date-fns";
import fileDownload from "js-file-download";
import { useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";

import { Spinner } from "@megaron/dash-spinner";
import { InvoiceThreadDoc, ThreadStatus } from "@megaron/invoices-contracts";
import { useServiceClient } from "@megaron/react-clients";

import { InvoiceThreadFlat, tableHeadingsList } from "./AllThreadsHome";
import { Filters } from "./useThreadFilters";

type Props = {
  filters: Filters;
};

export const DownloadCsvButton: React.FC<Props> = ({ filters }) => {
  const theme = useTheme();

  const docs = useServiceClient("docs");

  const [isFetching, setIsFetching] = useState(false);

  const fetchCsvThreads = async () => {
    const threads = await docs.searchInvoiceThreads({
      limit: 10000,
      offset: 0,
      searchText: filters.searchText,
      sort: filters.sort,
      status: filters.status,
      signedBy: filters.signedBy,
      budgetUuid: filters.budgetUuid,
      isDigital: filters.isDigital,
      isReceived: filters.isReceived,
      isPosted: filters.isPosted,
      participant: filters.participant,
      isRead: filters.isRead,
      isOverdue: filters.isOverdue,
      isMine: filters.isMine,
      issuer: filters.issuer,
    });

    return threads.value?.items || [];
  };

  const handleDownload = async () => {
    setIsFetching(true);

    const threads = await fetchCsvThreads();
    setIsFetching(false);

    downloadCsv(threads);
  };

  return (
    <button
      onClick={handleDownload}
      css={{
        display: "flex",
        gap: "0.25rem",
        alignItems: "center",
        border: "none",
        background: "none",
        padding: "0.375rem 0.625rem",
        color: theme.colors.primary,
        cursor: "pointer",
      }}
    >
      {isFetching ? (
        <div css={{ width: "20px" }}>
          <Spinner size="16px" />
        </div>
      ) : (
        <MdOutlineFileDownload size={20} />
      )}
      CSV
    </button>
  );
};

const mapInvoiceToCsvEntry = (invoice: InvoiceThreadDoc): InvoiceThreadFlat => ({
  referenceNumber: invoice.referenceNumber,
  title: invoice.title,
  decreeNumber: invoice.decreeNumber,
  approvedAt: invoice.approvedAt ? format(invoice.approvedAt, "yyyy-MM-dd, h:mm:ss") : undefined,
  deadline: format(invoice.deadline, "yyyy-MM-dd, h:mm:ss"),
  createdAt: format(invoice.createdAt, "yyyy-MM-dd, h:mm:ss"),
  invoiceNumber: invoice.invoice.invoiceNumber,
  issuer: invoice.invoice.issuer,
  issueDate: format(invoice.invoice.issueDate, "yyyy-MM-dd, h:mm:ss"),
  total: invoice.invoice.total.toNumber(),
  currency: invoice.invoice.currency,
  dueDate: invoice.invoice.dueDate ? format(invoice.invoice.dueDate, "yyyy-MM-dd, h:mm:ss") : undefined,
  status: parseStatusName(invoice.status),
});

const downloadCsv = (threads: InvoiceThreadDoc[]) => {
  const entries = threads.slice(0, 10000).map(mapInvoiceToCsvEntry);

  const columns = [
    ...tableHeadingsList.filter(({ key }) => key).map(({ key, label }) => ({ key: key as string, header: label })),
    { key: "total", header: "Kwota netto" },
    { key: "currency", header: "Waluta" },
    { key: "status", header: "Status" },
  ];

  const csv = stringify(entries, {
    header: true,
    columns: columns,
  });
  fileDownload(csv, `faktury.csv`);
};

const parseStatusName = (status: ThreadStatus) => {
  if (status === "open") {
    return "otwarty";
  }

  if (status === "approved") {
    return "zatwierdzony";
  }

  if (status === "closed") {
    return "odrzucony";
  }

  return undefined;
};
