import { Gtin, validateGtin } from "@megarax/crm-contracts";

import { Validator } from "../";

export const gtinValidator =
  <T>(): Validator<string | Gtin | undefined, Gtin, "invalidGtin"> =>
  (input) => {
    if (!input) return { failure: "invalidGtin", successful: false };
    const result = validateGtin(input);
    if (result.isFailure) return { failure: "invalidGtin", successful: false };
    return { value: result.value, successful: true };
  };
