import { Serializers } from "@megarax/serializers";

export type DeliveryAddress = {
  name: string;
  locality: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  country: string;
};

export const deliveryAddressSerializer = Serializers.object<DeliveryAddress>({
  houseNumber: Serializers.string,
  country: Serializers.string,
  locality: Serializers.string,
  name: Serializers.string,
  postalCode: Serializers.string,
  street: Serializers.string,
});
