import { Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export interface CreateTradeRouteInput {
  name: string;
  salespersonId: number;
  route: { customerUuid: Uuid; uuid: Uuid; durationMinutes: number }[];
  distanceMeters: number;
  fullDurationMinutes: number;
}

export const createTradeRouteInputSerializer = MSerializers.object<CreateTradeRouteInput>({
  name: MSerializers.string,
  salespersonId: MSerializers.integer,
  route: MSerializers.array(
    MSerializers.object<{ uuid: Uuid; customerUuid: Uuid; durationMinutes: number }>({
      uuid: uuidSerializer,
      customerUuid: uuidSerializer,
      durationMinutes: MSerializers.integer,
    }),
  ),
  distanceMeters: MSerializers.integer,
  fullDurationMinutes: MSerializers.integer,
});

export interface SaveRouteAsPermanentInput {
  name: string;
  salespersonId: number;
  route: { customerUuid: Uuid; uuid: Uuid; durationMinutes: number }[];
  distanceMeters: number;
  fullDurationMinutes: number;
}

export const saveAsPermanentInputSerializer = MSerializers.object<SaveRouteAsPermanentInput>({
  name: MSerializers.string,
  salespersonId: MSerializers.integer,
  route: MSerializers.array(
    MSerializers.object<{ uuid: Uuid; customerUuid: Uuid; durationMinutes: number }>({
      uuid: uuidSerializer,
      customerUuid: uuidSerializer,
      durationMinutes: MSerializers.integer,
    }),
  ),
  distanceMeters: MSerializers.integer,
  fullDurationMinutes: MSerializers.integer,
});

export interface SaveAndScheduleRouteInput {
  scheduledAt: Date;
  salespersonId: number;
  route: { customerUuid: Uuid; uuid: Uuid; durationMinutes: number }[];
  distanceMeters: number;
  fullDurationMinutes: number;
}

export const saveAndScheduleRouteInputSerializer = MSerializers.object<SaveAndScheduleRouteInput>({
  scheduledAt: MSerializers.dateOnly,
  salespersonId: MSerializers.integer,
  route: MSerializers.array(
    MSerializers.object<{ uuid: Uuid; customerUuid: Uuid; durationMinutes: number }>({
      uuid: uuidSerializer,
      customerUuid: uuidSerializer,
      durationMinutes: MSerializers.integer,
    }),
  ),
  distanceMeters: MSerializers.integer,
  fullDurationMinutes: MSerializers.integer,
});

export interface ScheduleRouteInput {
  scheduledAt: Date;
  salespersonId: number;
}

export const scheduleRouteInputSerializer = MSerializers.object<ScheduleRouteInput>({
  scheduledAt: MSerializers.dateOnly,
  salespersonId: MSerializers.integer,
});

export interface RenameRouteInput {
  name: string;
}

export const renameRouteInputSerializer = MSerializers.object<RenameRouteInput>({
  name: MSerializers.string,
});
