import { AnyUser } from "@megaron/access-control";
import { HttpAction, HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";
import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

import { accountsAdminActions } from "./accountsAdminActions";
import { brevoActions } from "./brevo";
import { emailActions } from "./email";
import { myAccountDtoSerializer } from "./myAccountDto";
import { publicProfileActions } from "./publicProfile";
import { registrationDataSerializer } from "./registrationData";
import { userPreferencesActions } from "./userPreferences";
import { webPushNotificationPaylodSerializer } from "./webPush";

const requestOtp = HttpAction({
  path: "/requestOtp",
  method: "post",
  bodySerializer: Serializers.first(
    Serializers.object({ phoneNumber: Serializers.string, recaptchaToken: Serializers.string }),
    Serializers.object({ accountUuid: uuidSerializer, recaptchaToken: Serializers.string }),
  ),
  valueSerializer: Serializers.object({ uuid: uuidSerializer }),
  errorSerializer: Serializers.stringOneOf("InvalidPhoneNumber", "FailedRecaptcha", "AccountNotFound"),
});

const signInWithOtp = HttpAction({
  path: "/signInWithOtp",
  method: "post",
  bodySerializer: Serializers.object({ uuid: uuidSerializer, password: Serializers.string }),
  valueSerializer: Serializers.object({
    idToken: Serializers.string,
    expiresAt: Serializers.datetime,
    refreshToken: Serializers.string,
  }),
  errorSerializer: Serializers.stringOneOf("InvalidOtp", "RequestExpired", "TooManyAttempts"),
});

const signInAsTestUser = HttpAction({
  path: "/signInAsTestUser",
  method: "post",
  bodySerializer: Serializers.object({ phoneNumber: Serializers.string, expirationMinutes: Serializers.integer }),
  valueSerializer: Serializers.object({
    idToken: Serializers.string,
    expiresAt: Serializers.datetime,
    refreshToken: Serializers.string,
  }),
});

const signOut = HttpAction({
  path: "/signOut",
  method: "post",
  requiresAuth: true as const,
});

const exchangeToken = HttpAction({
  path: "/exchangeToken",
  method: "post",
  bodySerializer: Serializers.object({ refreshToken: Serializers.string }),
  valueSerializer: Serializers.object({ idToken: Serializers.string, expiresAt: Serializers.datetime }),
  errorSerializer: Serializers.stringOneOf("InvalidRefreshToken"),
});

const myAccount = HttpAction({
  path: "/myAccount",
  method: "get",
  valueSerializer: myAccountDtoSerializer,
  errorSerializer: Serializers.stringOneOf("AccountNotFound", "AccountDeleted"),
  requiresAuth: true as const,
});

const requestAccountDeletion = HttpAction({
  path: "/requestAccountDeletion",
  method: "post",
  errorSerializer: Serializers.stringOneOf("DeletionAlreadyScheduled", "AccountNotFound", "AccountDeleted"),
  requiresAuth: true as const,
});

const cancelAccountDeletion = HttpAction({
  path: "/cancelAccountDeletion",
  method: "post",
  errorSerializer: Serializers.stringOneOf("DeletionNotScheduled", "AccountNotFound", "AccountDeleted"),
  requiresAuth: true as const,
});

const executeScheduledDeletions = HttpAction({
  path: "/executeScheduledDeletions",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({ cutoff: Serializers.datetime.optional() }),
});

const register = HttpAction({
  path: "/register",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: registrationDataSerializer,
  errorSerializer: Serializers.stringOneOf(
    "AlreadyRegistered",
    "InvalidEmail",
    "AccountNotFound",
    "EmailAlreadyInUse",
    "FailedToSendEmail",
  ),
});

const updateMyAccount = HttpAction({
  path: "/updateMyAccount",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: registrationDataSerializer,
  errorSerializer: Serializers.stringOneOf(
    "InvalidEmail",
    "AccountNotFound",
    "EmailAlreadyInUse",
    "NotRegistered",
    "FailedToSendEmail",
  ),
});

const subscribeToWebPush = HttpAction({
  path: "/subscribeToWebPush",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    endpoint: Serializers.string,
    keys: Serializers.object({
      p256dh: Serializers.string,
      auth: Serializers.string,
    }),
  }),
  valueSerializer: Serializers.object({ subscriptionUuid: uuidSerializer }),
});

const updateWebPushSubscription = HttpAction({
  path: "/updateWebPushSubscription",
  method: "post",
  bodySerializer: Serializers.object({
    oldEndpoint: Serializers.string,
    newEndpoint: Serializers.string,
    keys: Serializers.object({
      p256dh: Serializers.string,
      auth: Serializers.string,
    }),
  }),
  errorSerializer: Serializers.stringOneOf("SubscriptionNotFound"),
});

const broadcastWebPushNotification = HttpAction({
  path: "/webPush/broadcast",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    broadcastUuid: uuidSerializer,
    ...webPushNotificationPaylodSerializer.fields,
  }),
  valueSerializer: Serializers.object({
    successCount: Serializers.integer,
    failureCount: Serializers.integer,
  }),
});

const wipeWebPushSubscriptions = HttpAction({
  path: "/webPush/wipe",
  method: "post",
  requiresAuth: true as const,
});

const reportNotificationOpened = HttpAction({
  path: "/webPush/reportNotificationOpened",
  method: "post",
  bodySerializer: Serializers.object({ broadcastUuid: uuidSerializer, subscriptionUuid: uuidSerializer }),
  errorSerializer: Serializers.stringOneOf("NotificationNotFound", "NotificationAlreadyOpened"),
});

const broadcastSmsNotification = HttpAction({
  path: "/sms/broadcast",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object<{
    broadcastUuid: Uuid;
    content: string;
    sendToAllUsers: boolean;
    userUuids?: Uuid[];
  }>({
    broadcastUuid: uuidSerializer,
    content: Serializers.string,
    userUuids: Serializers.array(uuidSerializer).optional(),
    sendToAllUsers: Serializers.boolean,
  }),
  valueSerializer: Serializers.object({
    successCount: Serializers.integer,
    failureCount: Serializers.integer,
    skippedCount: Serializers.integer,
  }),
  errorSerializer: Serializers.stringOneOf("ContentTooLong", "InvalidOptions"),
});

const findAccount = HttpAction({
  path: "/preregisterUser",
  method: "post",
  bodySerializer: Serializers.object({
    phoneNumber: Serializers.string,
    preregister: Serializers.boolean,
  }),
  valueSerializer: Serializers.object({
    uuid: uuidSerializer,
    isNew: Serializers.boolean,
  }),
  errorSerializer: Serializers.stringOneOf("AccountNotFound"),
  requiresAuth: true as const,
});

export const accountsHttpService = HttpService({
  requestOtp,
  signInWithOtp,
  signOut,
  signInAsTestUser,
  exchangeToken,
  myAccount,
  requestAccountDeletion,
  cancelAccountDeletion,
  executeScheduledDeletions,
  register,
  updateMyAccount,
  subscribeToWebPush,
  updateWebPushSubscription,
  broadcastWebPushNotification,
  wipeWebPushSubscriptions,
  reportNotificationOpened,
  broadcastSmsNotification,
  findAccount,
  ...emailActions,
  ...userPreferencesActions,
  ...accountsAdminActions,
  ...publicProfileActions,
  ...brevoActions,
});

export type AccountsHandlers = HandlerMap<typeof accountsHttpService, AnyUser>;
