import { useNavigate } from "react-router-dom-v5-compat";

import { DraftsQuery, DraftsTab } from "./DraftsTab";

interface Props {
  draftsQuery: DraftsQuery;
  pagination: {
    onChange: (e: React.ChangeEvent<unknown>, page: number) => void;
    count: number;
    page: number;
  };
}

export const DraftsTabContainer: React.FC<Props> = ({ draftsQuery, pagination }) => {
  const navigate = useNavigate();

  const navigateToDetails = (uuid: string) => navigate(`/crm/pos/moje_zamowienia/${uuid}`);

  return <DraftsTab draftsQuery={draftsQuery} pagination={pagination} navigateToDetails={navigateToDetails} />;
};
