import { Uuid } from "@megarax/common";
import { payerResource, priceListResource, PriceListSimple, ProposeRuleChangeInput } from "@megarax/crm-contracts";
import { useProfilesDictionary } from "@megarax/iam-webapp";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import { Skeleton } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { Failure, Ok } from "@megaron/result";

import { PricingDialog } from "./PricingDialog";
import { PricingSection } from "./PricingSection";

interface Props {
  customerUuid: Uuid;
}

export const PricingSectionContainer: React.FunctionComponent<Props> = ({ customerUuid }) => {
  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    CustomerNotFound: "Klient nie istnieje",
  });

  const priceListProvider = useResourceProviderV2(priceListResource);
  const payerProvider = useResourceProviderV2(payerResource).byCustomerUuid(customerUuid);

  const {
    data: payer,
    isFetching,
    refetch,
  } = useQuery(["payer", customerUuid], () =>
    payerProvider
      .retrieve()
      .flatMapError((error) => {
        if (error === "PayerDoesNotExist") return Ok(null);
        return Failure(error);
      })
      .mapError(handleError)
      .then((r) => r.value),
  );

  const { data: priceLists, isFetching: priceListsLoading } = useQuery(["pricingSectionPriceLists"], () =>
    priceListProvider
      .list({ searchText: "", limit: 1000 })
      .mapError(handleError)
      .then((r) => r.value?.items),
  );

  const profiles = useProfilesDictionary(
    _.uniqBy(
      payer?.proposals.map((proposal) => proposal.proposedBy),
      "id",
    ),
  );

  const addProposal = (input: ProposeRuleChangeInput) =>
    payerProvider.proposals.propose(undefined, input).then(() => refetch());

  const rejectProposal = (uuid: string) =>
    payerProvider.proposals
      .byUuid(uuid)
      .reject()
      .then(() => refetch());
  const approveProposal = (uuid: string) =>
    payerProvider.proposals
      .byUuid(uuid)
      .approve()
      .then(() => refetch());

  const location = useLocation();
  const navigate = useNavigate();

  const openPricingDialog = () => navigate(`${location.pathname}/warunki_handlowe`);
  const isPricingDialogOpen = location.pathname.endsWith("warunki_handlowe");

  if (payer === undefined || !priceLists || !profiles)
    return <Skeleton variant="rectangular" height={100} width={135} />;

  return (
    <>
      {isPricingDialogOpen && payer !== undefined && priceLists && profiles && (
        <PricingDialog
          onClose={() => navigate(-1)}
          getPriceLists={getPriceLists(priceLists)}
          onAddProposal={addProposal}
          onApproveProposal={approveProposal}
          onRejectProposal={rejectProposal}
          payer={payer}
          priceLists={priceLists}
          profiles={profiles}
        />
      )}
      <PricingSection onOpenDialog={openPricingDialog} payer={payer} priceLists={priceLists} profiles={profiles} />
    </>
  );
};

const getPriceLists = (priceLists: PriceListSimple[]) => (search: string) => {
  const reg = new RegExp(search, "i");
  return Promise.resolve(priceLists.filter((item) => reg.test(item.name)));
};
