import { Serializers, SerializerValue } from "@megaron/serializers";

import { baseDocSerializer } from "../baseDoc";

export const userGroupDocSerializer = Serializers.object({
  ...baseDocSerializer("user-group").fields,

  name: Serializers.string,
  id: Serializers.string,
});

export type UserGroupDoc = SerializerValue<typeof userGroupDocSerializer>;
