import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { Button } from "@megaron/dash-form";
import { useToast } from "@megaron/dash-toast";
import { Currency, Invoice } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { ThreadDetailsForm } from "../draft/ThreadDetailsForm";

type Props = {
  invoice: Invoice;
  onClose: () => void;
  queryKey: string | string[];
  threadUuid: Uuid;
};

export const EditInvoiceBody: React.FC<Props> = ({ invoice, onClose, queryKey, threadUuid }) => {
  const queryClient = useQueryClient();
  const updateInvoiceMutation = useClientManager("invoices").updateInvoice().useMutation();

  const toast = useToast();

  const [invoiceNumber, setInvoiceNumber] = useState(invoice.invoiceNumber);
  const [issuer, setIssuer] = useState(invoice.issuer);
  const [issuerTaxId, setIssuerTaxId] = useState(invoice.issuerTaxId);
  const [total, setTotal] = useState(invoice.total.toFixed(2));
  const [currency, setCurrency] = useState<Currency>(invoice.currency);
  const [issueDate, setIssueDate] = useState(invoice.issueDate);
  const [dueDate, setDueDate] = useState(invoice.dueDate);
  const [receivedDate, setReceivedDate] = useState(invoice.receivedDate);
  const [isDigital, setIsDigital] = useState(invoice.isDigital);
  const [isPayed, setIsPayed] = useState(!invoice.dueDate);

  useEffect(() => {
    if (dueDate && isPayed) {
      setDueDate(null);
    }

    if (!dueDate && !isPayed) {
      setDueDate(new Date());
    }
  }, [dueDate, isPayed, setDueDate]);

  useEffect(() => {
    setInvoiceNumber(invoice.invoiceNumber);
    setTotal(invoice.total.toFixed(2));
    setCurrency(invoice.currency);
    setIssueDate(invoice.issueDate);
    setDueDate(invoice.dueDate);
    setReceivedDate(invoice.receivedDate);
    setIsDigital(invoice.isDigital);
    setIssuer(invoice.issuer);
    setIssuerTaxId(invoice.issuerTaxId);
    setIsPayed(!invoice.dueDate);
  }, []);

  const updateInvoice = () => {
    if (!invoiceNumber || !issueDate || !issuer || !receivedDate || !total || (!isPayed && !dueDate)) {
      toast.error("Uzupełnij brakujące pola w formularzu");
      return;
    }

    const updatedInvoice: Invoice = {
      ...invoice,
      invoiceNumber,
      issuer,
      total: new Decimal(total),
      currency,
      issueDate,
      dueDate: dueDate ? new Date(dueDate) : null,
      receivedDate,
      isDigital,
      issuerTaxId,
    };

    updateInvoiceMutation.mutate(
      {
        invoice: updatedInvoice,
        threadUuid,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
          onClose();
        },
        onError: () => {
          toast.error("Nie udało się zaktualizować faktury");
        },
      },
    );
  };

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <ThreadDetailsForm
        values={{
          currency,
          dueDate,
          invoiceNumber,
          isDigital,
          isPayed,
          issueDate,
          issuer,
          issuerTaxId,
          receivedDate,
          total,
        }}
        handlers={{
          onCurrencyChange: setCurrency,
          onDueDateChange: setDueDate,
          onInvoiceNumberChange: setInvoiceNumber,
          onIsDigitalChange: setIsDigital,
          onPayedChange: setIsPayed,
          onIssueDateChange: setIssueDate,
          onIssuerChange: setIssuer,
          onIssuerTaxIdChange: setIssuerTaxId,
          onReceivedDateChange: setReceivedDate,
          onTotalChange: setTotal,
        }}
      />
      <Button css={{ alignSelf: "flex-end", margin: "0.25rem 0" }} onClick={updateInvoice}>
        Zapisz
      </Button>
    </div>
  );
};
