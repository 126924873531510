import Decimal from "decimal.js";

import { Serializer, serializerExtensions, SerializerExtensions, Serializers } from "@megarax/serializers";

export type GeoPoint = {
  latitude: Decimal;
  longitude: Decimal;
};

export const geoPointSerialzier = Serializers.object<GeoPoint>({
  latitude: Serializers.decimal,
  longitude: Serializers.decimal,
});

export const geoPointElasticsearchSerializer: Serializer<GeoPoint> & SerializerExtensions<GeoPoint> = {
  serialize: (value) => ({
    lat: Serializers.decimal.serialize(value.latitude),
    lon: Serializers.decimal.serialize(value.longitude),
  }),
  forceDeserialize: (raw) => ({
    latitude: Serializers.decimal.forceDeserialize(raw.lat),
    longitude: Serializers.decimal.forceDeserialize(raw.lon),
  }),
  ...serializerExtensions(),
};
