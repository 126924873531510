import { useTheme } from "@emotion/react";
import React from "react";

import { DescriptionField, DescriptionList } from "@megaron/dash-description-list";
import { CardLink } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { LoyaltyAdminLogo } from "@megaron/megarax-links";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  loyaltyUuid: Uuid;
};

export const LoyaltyTile: React.FC<Props> = (props) => {
  const theme = useTheme();

  const pointsAccountQuery = useClientManager("loyaltyPoints").account().useQuery({ uuid: props.loyaltyUuid });

  return (
    <QuerySkeleton query={pointsAccountQuery} height="90px">
      {(account) => (
        <CardLink
          linkTo={`/specjalista/users/id/${props.loyaltyUuid}`}
          label="Konto"
          icon={<LoyaltyAdminLogo css={{ height: "21px", width: "21px", color: "white" }} />}
          color={theme.colors.primary}
        >
          <DescriptionList itemMinWidth="100px" css={{ color: "black", width: "100%" }}>
            <DescriptionField label="Stan konta">{account.balance.toString()} pkt.</DescriptionField>
            <DescriptionField label="Status">{getStatus(account)}</DescriptionField>
          </DescriptionList>
        </CardLink>
      )}
    </QuerySkeleton>
  );
};

const getStatus = (acc: { isAffiliate: boolean; isPro: boolean }) => {
  if (acc.isAffiliate && acc.isPro) return "#partner, #pro";
  if (acc.isAffiliate) return "#partner";
  if (acc.isPro) return "#pro";

  return null;
};
