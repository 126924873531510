import { BoolFilter, FilterList, SelectFilter, Sort } from "@megaron/dash-filters";
import { ItemSortField } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";

import { ItemsFilters, SortField } from "./TradeItemsPage";

type Props = {
  filters: ItemsFilters;
  handlers: {
    onSortChange: (sort: SortField | undefined) => void;
    onIsLoyaltyChange: (isLoyalty: boolean | undefined) => void;
    onGroupsChange: (groups: string[]) => void;
    onIsArchivedChange: (isArchived: boolean | undefined) => void;
  };
};

export const TradeItemsFilters: React.FC<Props> = ({ filters, handlers }) => {
  const groupsQuery = useClientManager("docs").searchProductGroups().useQuery({});

  return (
    <FilterList>
      <BoolFilter
        label={"Jest lojalnościowy?"}
        value={filters.isLoyalty}
        onChange={handlers.onIsLoyaltyChange}
        falseLabel="Nie"
        trueLabel="Tak"
      />
      <BoolFilter
        label={"Jest zarchiwizowany?"}
        value={filters.isArchived}
        onChange={handlers.onIsArchivedChange}
        falseLabel="Nie"
        trueLabel="Tak"
      />
      <SelectFilter
        label={"Grupy"}
        variant="multi-select"
        onSelectedChange={(options) => handlers.onGroupsChange(options.map((option) => option.value))}
        initiallySelectedValues={filters.groups}
        options={groupsQuery.data?.map((group) => ({ label: group, value: group })) ?? []}
      />
      <Sort
        type="button"
        label={"Sortuj"}
        value={{
          field: filters.sort?.field || "",
          order: filters.sort?.order || "",
        }}
        options={sortOptions}
        onChange={(v) => handlers.onSortChange((v as SortField) ?? undefined)}
      />
    </FilterList>
  );
};

const sortOptions = [
  { label: "EAN", value: "gtin" },
  { label: "Wielkość", value: "size" },
  { label: "Paleta", value: "pallet" },
  { label: "Warstwa", value: "layer" },
  { label: "Karton", value: "box" },
] satisfies { label: string; value: ItemSortField }[];
