import { Ok } from "@megaron/result";

import { Serializer } from "../serializer";

export const optional = <T>(serializer: Serializer<T>): Serializer<T | undefined> => ({
  ...serializer,
  serialize: (value) => {
    if (value === undefined) return value;
    return serializer.serialize(value);
  },
  deserialize: (raw) => {
    if (raw === undefined) return Ok(raw);
    return serializer.deserialize(raw);
  },
});
