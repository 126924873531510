import { Box, Button, Dialog, Typography } from "@mui/material";
import React, { useState } from "react";

import { RegionSimple, RegisterSalespersonInput } from "@megarax/crm-contracts";
import { UserSimple } from "@megarax/iam-contracts";
import { Result } from "@megaron/result";
import { ResponsiveSelect } from "@megarax/ui-components";

interface Props {
  open: boolean;
  close: () => void;
  getRegions: (searchText: string) => Promise<RegionSimple[]>;
  getUsers: (searchText: string) => Promise<UserSimple[]>;
  registerSalesperson: (userId: number) => (input: RegisterSalespersonInput) => Promise<Result<void, void>>;
}

export const NewSalespersonDialog: React.FC<Props> = ({ open, close, getRegions, getUsers, registerSalesperson }) => {
  const [user, setUser] = useState<UserSimple | null>(null);
  const [region, setRegion] = useState<RegionSimple | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const isSubmitable = user && region;
  const onSubmit = async () => {
    if (!isSubmitable) return;
    setLoading(true);
    const result = await registerSalesperson(user.id)({ regionUuid: region.uuid });
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
      <Box px={2} pt={2}>
        <Typography variant="subtitle2">Zarejestruj nowego handlowca:</Typography>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ gap: 2 }} p={2}>
        <ResponsiveSelect
          label="Użytkownik"
          getOptions={getUsers}
          value={user}
          onChange={setUser}
          getKey={(i) => i.id}
          getValue={(i) => i}
          getLabel={(i) => i.email}
        />
        <ResponsiveSelect
          label="Region"
          getOptions={getRegions}
          value={region}
          onChange={setRegion}
          getKey={(i) => i.uuid}
          getValue={(i) => i}
          getLabel={(i) => i.name}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" px={2} pb={1}>
        <Button color="primary" disabled={loading} onClick={onSubmit}>
          Utwórz
        </Button>
      </Box>
    </Dialog>
  );
};
