import { Serializers, SerializerValue } from "@megaron/serializers";
import { newUuid } from "@megaron/uuid";

import { baseDocSerializer } from "../baseDoc";

export const testDocSerializer = Serializers.object({
  ...baseDocSerializer("test-doc").fields,
  id: Serializers.string,
  name: Serializers.string,
  content: Serializers.string,
});

export type TestDoc = SerializerValue<typeof testDocSerializer>;

export const newTestDoc = (props: Partial<TestDoc> = {}): TestDoc => ({
  authorizedAttributes: ["userType:megarax"],
  content: "content",
  docId: newUuid(),
  docType: "test-doc",
  id: newUuid(),
  name: "test doc",
  isArchived: false,
  ...props,
});
