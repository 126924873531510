import { useTheme } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";

import { useDeviceType } from "@megaron/dash-mq";

type Props = {
  openButton: React.ReactNode;
  children: React.ReactNode;
  placement?: "top" | "bottom";
};

export const Dropdown: React.FC<Props> = ({ children, openButton, placement = "bottom" }) => {
  const theme = useTheme();
  const { isMobile } = useDeviceType();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const dropdownStyle = {
    position: "absolute" as const,
    top: placement === "bottom" ? "45px" : undefined,
    bottom: placement === "top" ? "45px" : undefined,
    right: "8px",
    borderRadius: theme.smallBorderRadius,
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
    backgroundColor: "white",
    zIndex: 999,
    color: theme.colors.primary,
    padding: "8px",
    margin: isMobile ? 0 : "0 2rem",
  };

  return (
    <>
      <div onClick={handleToggleDropdown}>{openButton}</div>
      {isDropdownOpen && (
        <div ref={dropdownRef} css={dropdownStyle}>
          {children}
        </div>
      )}
    </>
  );
};
