import { useTheme } from "@emotion/react";
import React, { useCallback, useEffect, useRef } from "react";
import { MdClose, MdOutlinePlaylistRemove } from "react-icons/md";

import { SearchDialog } from "./SearchDialog";
import SelectListElement from "./SelectListElement";

export type SelectOption = {
  value: string;
  label: string;
  imageUrl?: string | null;
};

export type CommonSearchProps<TOption extends SelectOption = SelectOption> = {
  label?: string;
  onClose?: () => void;
  isOpen?: boolean;
  className?: string;
  isUnselectDisabled?: boolean;
  renderItem?: (item: TOption) => React.ReactNode;
  hideDefaultResults?: boolean;
  hideHeader?: boolean;
  optionalNoResultsText?: string;
  isDisabled?: boolean;
  isSearchReady?: boolean;
  shouldNotCloseOnSelect?: boolean;
  renderTrigger?: (
    selectedOptions: TOption[],
    onUnselectOption: (option: TOption) => void,
    onUnselectAll: () => void,
    onOpen: () => void,
  ) => React.ReactNode;
} & (
  | {
      variant: "single-select";
      onSelect?: (option: TOption | null | undefined) => void;
      onSelectedChange?: undefined;
    }
  | {
      variant: "multi-select";
      onSelectedChange?: (options: TOption[]) => void;
      onSelect?: undefined;
    }
  | {
      variant: "search";
      onSelect?: undefined;
      onSelectedChange?: undefined;
    }
);

export type BaseSelectDialogProps<TOption extends SelectOption = SelectOption> = CommonSearchProps<TOption> & {
  initialSelectedOptions?: TOption[];
  search: (searchText: string) => Promise<TOption[]>;
};
export type SelectDialogVariant = BaseSelectDialogProps<SelectOption>["variant"];

export const BaseSelectDialog = <TOption extends SelectOption>(props: BaseSelectDialogProps<TOption>) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(!!props.isOpen);
  const areInitialOptionsSet = useRef(false);

  const [selectedOptions, setSelectedOptions] = React.useState<TOption[]>(props.initialSelectedOptions ?? []);

  useEffect(() => {
    if (props.isDisabled) {
      return;
    }

    if (props.isOpen !== undefined) {
      setIsOpen(!!props.isOpen);
    }
  }, [props.isOpen, props.isDisabled]);

  useEffect(() => {
    if (areInitialOptionsSet.current) {
      return;
    }

    /**
     * We don't want to override selectedOptions if they are already set.
     */
    if (selectedOptions.length > 0) {
      areInitialOptionsSet.current = true;

      return;
    }

    if (props.initialSelectedOptions && props.initialSelectedOptions.length > 0) {
      areInitialOptionsSet.current = true;
      setSelectedOptions(props.initialSelectedOptions);
    }
  }, [props.initialSelectedOptions, selectedOptions.length]);

  const onClose = () => {
    setIsOpen(false);
    props.onClose?.();
  };

  const checkIsSelected = useCallback(
    (item: TOption) => selectedOptions.some((selectedOption) => selectedOption.value === item.value),
    [selectedOptions],
  );

  const onListItemClick = (item: TOption) => {
    if (props.variant === "search") {
      onClose();
      return;
    }

    if (props.variant === "single-select") {
      setSelectedOptions([item]);
      props.onSelect?.(item);
      if (props.shouldNotCloseOnSelect) {
        return;
      }

      onClose();

      return;
    }

    const isSelected = checkIsSelected(item);

    if (props.isUnselectDisabled && isSelected) {
      return;
    }

    const newSelectedOptions = isSelected
      ? selectedOptions.filter((selectedOption) => !(selectedOption.value === item.value))
      : [...selectedOptions, item];

    setSelectedOptions(newSelectedOptions);
    props.onSelectedChange?.(newSelectedOptions);
  };

  const onUnselectOption = (option: TOption) => {
    if (props.isUnselectDisabled) {
      return;
    }

    if (props.variant === "single-select") {
      setSelectedOptions([]);
      props.onSelect?.(null);

      return;
    }

    /**
     * Logic for removing selected option is the same as clicking on selected
     * one in the case of multiselect
     */
    onListItemClick(option);
  };

  const onUnselectAll = () => {
    if (props.isUnselectDisabled) {
      return;
    }

    setSelectedOptions([]);
    props.onSelectedChange?.([]);
    props.onSelect?.(null);
  };

  const handleSearch = props.search;

  const onSearch = useCallback(
    async (searchText: string) => {
      const result = await handleSearch(searchText);

      const labelCounts: { [label: string]: number } = {};

      result.forEach((option) => {
        labelCounts[option.label] = (labelCounts[option.label] || 0) + 1;
      });

      const modifiedOptions = result.map((option) => {
        if (labelCounts[option.label] > 1) {
          return {
            ...option,
            label: `${option.label} (${option.value})`,
          };
        } else {
          return option;
        }
      });

      /**
       * Remove selected options that are present in the search result.
       * It's better to do it this way and not the other way around, since
       * selectedOptions might consist options without labels, while fetched options
       * can't.
       */
      const filteredSelectedOptions = selectedOptions.filter((option) => {
        return !modifiedOptions.some((modifiedOption) => modifiedOption.value === option.value);
      });

      const sortedFetchedOptions = [...modifiedOptions].sort((optionA, optionB) => {
        const isASelected = checkIsSelected(optionA);
        const isBSelected = checkIsSelected(optionB);
        if (isASelected === isBSelected) return 0;

        return isASelected ? -1 : 1;
      });

      return [...filteredSelectedOptions, ...sortedFetchedOptions];
    },
    [checkIsSelected, handleSearch, selectedOptions],
  );

  const onOpen = () => {
    if (props.isDisabled) {
      return;
    }
    setIsOpen(true);
  };

  return (
    <>
      {props.renderTrigger?.(selectedOptions, onUnselectOption, onUnselectAll, onOpen)}

      {isOpen && (
        <SearchDialog
          className={props.className}
          onClose={onClose}
          optionalNoResultsText={props.optionalNoResultsText}
          header={
            <div
              css={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "white",
                fontSize: "1rem",
              }}
            >
              <span>
                Wybrano: <strong>{selectedOptions.length}</strong>
              </span>
              <MdOutlinePlaylistRemove
                css={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={onUnselectAll}
              />
            </div>
          }
          search={onSearch}
          onListItemClick={onListItemClick}
          renderItem={
            props.renderItem ??
            ((option) => (
              <SelectElement
                isUnselectDisabled={props.isUnselectDisabled}
                option={option}
                isSelected={checkIsSelected(option)}
              />
            ))
          }
          checkCloseButton
          hideDefaultResults={props.hideDefaultResults}
          hideHeader={props.hideHeader}
          isSearchReady={props.isSearchReady}
        />
      )}
    </>
  );
};

const SelectElement = ({
  option,
  isSelected,
  isUnselectDisabled,
}: {
  option: SelectOption;
  isSelected?: boolean;
  isUnselectDisabled?: boolean;
}) => {
  return (
    <SelectListElement<string>
      key={option.label}
      label={option.label}
      value={option.value}
      imageUrl={option.imageUrl}
      isSelected={!!isSelected}
      isUnselectDisabled={isUnselectDisabled}
    />
  );
};

export const SelectTrigger = <TOption extends SelectOption>(props: {
  className?: string;
  label?: string;
  isDisabled?: boolean;
  placeholder?: string;
  selectedOptions: TOption[];
  onUnselectOption: (option: TOption) => void;
  onClick?: () => void;
}) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "4px" }} className={props.className}>
      {props.label && (
        <span
          css={{
            fontFamily: theme.displayFontFamily,
            fontSize: "14px",
            color: theme.colors.primary,
          }}
        >
          {props.label}
        </span>
      )}
      <div
        style={{
          // minHeight: "46px",
          display: "flex",
          flexWrap: "wrap",
          background: theme.colors.primaryLight,
          padding: "0.5rem 0.75rem",
          borderRadius: theme.smallBorderRadius,
          cursor: props.isDisabled ? "auto" : "pointer",
          gap: "4px",
        }}
        onClick={props.onClick}
      >
        {props.selectedOptions?.length > 0 ? (
          props.selectedOptions.map((option) => (
            <SelectedOptionTile
              key={option.value}
              option={option}
              isDisabled={props.isDisabled}
              onUnselect={() => props.onUnselectOption(option)}
            />
          ))
        ) : (
          <span css={{ color: theme.colors.secondaryText, margin: "auto 0" }}>{props.placeholder ?? "Wybierz..."}</span>
        )}
      </div>
    </div>
  );
};

const SelectedOptionTile = ({
  isDisabled,
  option: { label },
  onUnselect,
}: {
  option: SelectOption;
  isDisabled?: boolean;
  onUnselect: () => void;
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        background: theme.colors.primary,
        color: "white",
        borderRadius: theme.extraSmallBorderRadius,
        padding: "4px 8px",
        cursor: isDisabled ? "auto" : "pointer",
      }}
    >
      <span>{label}</span>
      <MdClose
        style={{ marginLeft: "4px", cursor: isDisabled ? "auto" : "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          !isDisabled && onUnselect();
        }}
      />
    </div>
  );
};
