import { CommonSearchProps, SelectDialog, SelectOption } from "@megaron/dash-select";

import { FlagFilter } from "./FlagFilter";

type SelectProps = CommonSearchProps & {
  initiallySelectedValues?: string[] | undefined;
} & (
    | { options: SelectOption[]; search?: undefined }
    | { options?: undefined; search: (searchText: string) => Promise<SelectOption[]> }
  );

export const SelectFilter = (props: SelectProps) => {
  return (
    <SelectDialog
      {...props}
      renderTrigger={(selectedOptions, onUnselectOption, onUnselectAll, onOpen) => {
        const isSelected = selectedOptions.length > 0;

        const chipLabel = isSelected
          ? selectedOptions.length === 1
            ? `${props.label}: ${selectedOptions[0].label}`
            : `${props.label} (${selectedOptions.length})`
          : props.label;

        return <FlagFilter isActive={isSelected} label={chipLabel} onClick={onOpen} onDelete={onUnselectAll} />;
      }}
    />
  );
};
