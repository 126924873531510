import { AmqpTopic } from "@megarax/amqp";
import { DateString, dateStringSerializer, Uuid, uuidSerializer } from "@megarax/common";
import { Serializers } from "@megarax/serializers";

export type ScheduleDayEventMessage = {
  userId: number;
  date: DateString;
};

export const scheduleDayEventMessageSerializer = Serializers.object<ScheduleDayEventMessage>({
  date: dateStringSerializer,
  userId: Serializers.integer,
});

export const scheduleDayEventAmqpTopic: AmqpTopic<ScheduleDayEventMessage> = {
  key: "crm.visit-schedule.day-event",
  serializer: scheduleDayEventMessageSerializer,
};

export type ScheduleVisitEventMessage = {
  visitUuid: Uuid;
  customerUuid: Uuid;
};

export const scheduleVisitEventMessageSerializer = Serializers.object<ScheduleVisitEventMessage>({
  visitUuid: uuidSerializer,
  customerUuid: uuidSerializer,
});

export const scheduleVisitEventAmqpTopic: AmqpTopic<ScheduleVisitEventMessage> = {
  key: "crm.visit-schedule.schedule-visit-event",
  serializer: scheduleVisitEventMessageSerializer,
};
