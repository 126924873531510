import { useTheme } from "@emotion/react";
import tinycolor from "tinycolor2";

import { ThreadDetailsForm } from "./ThreadDetailsForm";
import { DraftData, useDraftForm } from "./useDraftForm";

type Props = {
  setDraft: ReturnType<typeof useDraftForm>["setDraft"];
  draftData: DraftData;
};

export const ThreadDetails: React.FC<Props> = ({ setDraft, draftData }) => {
  const theme = useTheme();

  return (
    <ThreadDetailsForm
      handlers={{
        onCurrencyChange: setDraft("currency"),
        onDueDateChange: setDraft("dueDate"),
        onInvoiceNumberChange: setDraft("invoiceNumber"),
        onIsDigitalChange: setDraft("isDigital"),
        onIssueDateChange: setDraft("issueDate"),
        onIssuerChange: setDraft("issuer"),
        onIssuerTaxIdChange: setDraft("issuerTaxId"),
        onPayedChange: setDraft("isPayed"),
        onReceivedDateChange: setDraft("receivedDate"),
        onTotalChange: setDraft("total"),
      }}
      values={draftData}
      css={{
        padding: "1rem",
        borderRadius: theme.smallBorderRadius,
        border: `1px solid ${tinycolor("#000000").setAlpha(0.1)}`,
        background: "white",
      }}
    />
  );
};
