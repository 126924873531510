import { useTheme } from "@emotion/react";
import { useQueryClient } from "react-query";

import { CustomerDetails } from "@megaron/crm-contracts";
import { Checkbox } from "@megaron/dash-form";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  customerData: CustomerDetails;
  queryKey: string | string[];
};

export const ArchivedCustomerCheckbox: React.FC<Props> = ({ customerData, queryKey }) => {
  const theme = useTheme();

  const archiveCustomerMutation = useClientManager("crm").archiveCustomer().useMutation();

  const queryClient = useQueryClient();

  const archiveCustomer = (isArchived: boolean) => {
    archiveCustomerMutation.mutate(
      {
        isArchived,
        customerId: customerData.uuid,
      },
      {
        onSuccess: (r) => {
          queryClient.invalidateQueries(queryKey);
        },
      },
    );
  };

  return (
    <div
      css={{
        padding: "0.75rem",
        borderRadius: theme.smallBorderRadius,
        border: `1px solid rgba(0, 0, 0, 0.1)`,
      }}
    >
      <Checkbox label="Zarchiwizowany" value={customerData.isArchived} onChange={archiveCustomer} />
    </div>
  );
};
