import { DateString, toDateString } from "@megarax/common";
import { PlayArrow, Stop } from "@mui/icons-material";
import { Box, Fab, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { VisitDuration } from "./VisitDuration";
import { VisitTimePicker } from "./VisitTimePicker";

interface Props {
  isMine: boolean;
  startTime: Date | null;
  endTime: Date | null;
  onStart: () => void;
  onEnd: () => void;
  onStartTimeChange: (time: Date | null) => void;
  onEndTimeChange: (time: Date | null) => void;
  visitDate: DateString;
  isCancelled: boolean;
}

export const TimeSection: React.FunctionComponent<Props> = ({
  isMine,
  onStartTimeChange,
  startTime,
  onEndTimeChange,
  endTime,
  onEnd,
  onStart,
  visitDate,
  isCancelled,
}) => {
  const classes = useStyles();

  const isToday = toDateString(new Date()) === visitDate;

  const showDuration = isToday || (endTime !== null && startTime !== null);
  const showStartButton = isMine && startTime === null && endTime === null && isToday;
  const showEndButton = isMine && endTime === null && isToday;

  if (isCancelled) return null;

  return (
    <Box>
      {showStartButton ? (
        <Box textAlign="center">
          <Fab color="primary" className={classes.endVisitFab} onClick={onStart}>
            <PlayArrow />
          </Fab>
        </Box>
      ) : (
        <div className={classes.startedVisitTimeSection}>
          <Box>
            <Typography variant="overline" lineHeight={0.1} className={classes.timePickerLabel}>
              Start
            </Typography>
            <VisitTimePicker isDisabled={!isMine} isClearable onChange={onStartTimeChange} value={startTime} />
          </Box>
          <Box>{showDuration ? <VisitDuration endTime={endTime} startTime={startTime} /> : null}</Box>
          {showEndButton ? (
            <Box textAlign="center">
              <Fab color="primary" className={classes.endVisitFab} onClick={onEnd}>
                <Stop />
              </Fab>
            </Box>
          ) : (
            <Box textAlign="right">
              <Typography variant="overline" lineHeight={0.1} className={classes.timePickerLabel}>
                Koniec
              </Typography>
              <VisitTimePicker isDisabled={!isMine} isClearable onChange={onEndTimeChange} value={endTime} rightAlign />
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: "none",
    marginBottom: theme.spacing(4),
  },
  timePickerLabel: {
    maxWidth: "100px",
  },

  startedVisitTimeSection: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr 3fr",
    gridGap: "1rem",
  },
  endVisitFab: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
}));
