import { useTheme } from "@emotion/react";
import React from "react";

import { useQrScanner } from "./useQrScanner";

type Props = {
  onScan: (code: string) => void;
  children?: React.ReactNode;
};

export const QrScanner: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const onScanResult = (rawResult: string) => {
    const splitMatch = rawResult.split("/");
    const code = splitMatch[splitMatch.length - 1].split("-").join("");
    props.onScan(code);
    const parsedCode = code.split("-").join("");
    // if (parsedCode.length === 12 || parsedCode.length === 4) onScan("qr")(code);
  };

  const { start, cameraError } = useQrScanner({ onResult: onScanResult });

  return (
    <div
      css={{
        position: "fixed",
        width: "100%",
        height: "100%",
        "& .scan-region-highlight-svg": {
          stroke: `${theme.colors.primary} !important`,
          strokeWidth: "2px !important",
        },
        "& .code-outline-highlight": {
          stroke: `${theme.colors.primary} !important`,
          strokeWidth: "4px !important",
        },
      }}
    >
      <video
        ref={(ref) => {
          if (!ref) return;
          start(ref);
        }}
        css={{
          width: "100%",
          height: "100%",
          background: "black",
        }}
      />
      {props.children}
    </div>
  );
};
