import React from "react";
import { Link } from "react-router-dom-v5-compat";

import { EntityList } from "@megaron/dash-entity-list";
import { SearchBox } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

import { renderUserUuid } from "./renderUserUuid";
import { getUserPath } from "./UserPage";

export const UsersList = () => {
  const [searchText, setSearchText] = React.useState("");

  const usersQuery = useClientManager("loyaltyAccounts").searchAccounts().useQuery({ searchText });

  return (
    <div>
      <SearchBox onChange={setSearchText} value={searchText} css={{ marginBottom: "1rem" }} />
      <QuerySkeleton query={usersQuery}>
        {(users) => (
          <EntityList
            items={users}
            getKey={(u) => u.uuid}
            columns={(item) => [
              {
                header: "Id",
                content: <Link to={getUserPath(item.uuid)}>{renderUserUuid(item.uuid)}</Link>,
                isPrimary: true,
              },
              {
                header: "Nr telefonu",
                content: <Link to={getUserPath(item.uuid)}>{item.phoneNumber}</Link>,
                isPrimary: true,
              },
              { header: "Email", content: item.email ?? "-" },
              { header: "Imię i nazwisko", content: `${item.firstName ?? "-"} ${item.lastName ?? ""}` },
            ]}
          />
        )}
      </QuerySkeleton>
    </div>
  );
};
