import { addressSerializer, BasicAddress, GeoPoint, geoPointSerialzier, Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export interface RegisterSalespersonInput {
  regionUuid: Uuid;
}

export const registerSalespersonInputSerializer = MSerializers.object<RegisterSalespersonInput>({
  regionUuid: uuidSerializer,
});

export interface DesignateHomeInput {
  address: BasicAddress;
  homeLocation: GeoPoint;
}

export const designateHomeInputSerializer = MSerializers.object<DesignateHomeInput>({
  address: addressSerializer,
  homeLocation: geoPointSerialzier,
});

export interface SetWorkStartTimeInput {
  workStartTime: number;
}

export const setWorkStartTimeInputSerializer = MSerializers.object<SetWorkStartTimeInput>({
  workStartTime: MSerializers.integer,
});

export interface UpdateRegionInput {
  regionUuid: Uuid;
}

export const updateRegionInputSerializer = MSerializers.object<UpdateRegionInput>({
  regionUuid: uuidSerializer,
});

export interface SetManagerInput {
  managerId: number;
}

export const setManagerInputSerializer = MSerializers.object<SetManagerInput>({
  managerId: MSerializers.integer,
});
