import { Gtin, TradeItemDto } from "@megarax/crm-contracts";
import { Pagination, SearchBar } from "@megarax/ui-components";
import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  openPackSizeDefaultsDialog: (gtin: Gtin) => void;
  goToGroupings: () => void;
  tradeItemsQuery: {
    count: number;
    items: TradeItemDto[];
  };
  pagination: {
    onChange: (e: React.ChangeEvent<unknown>, page: number) => void;
    count: number;
    page: number;
  };

  setSearchText: (text: string) => void;
}

export const TradeItemsHome: React.FunctionComponent<Props> = ({
  openPackSizeDefaultsDialog,
  tradeItemsQuery,
  pagination,
  goToGroupings,
  setSearchText,
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" disableGutters>
      <Box display="flex" py={2} sx={{ gap: "8px" }} justifyContent="space-between" alignItems="center">
        <SearchBar onSearchChange={setSearchText} />
      </Box>
      <div className={classes.table}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>GTIN (EAN)</TableCell>
              <TableCell>Nazwa</TableCell>
              <TableCell className={classes.buttonCol} />
            </TableRow>
          </TableHead>
          <TableBody>
            {tradeItemsQuery.items.map((item) => {
              return (
                <TableRow hover={true} key={item.gtin} className={classes.item}>
                  <TableCell>{item.gtin}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell className={classes.buttonCol}>
                    <div className={classes.buttonRow}>
                      <Button
                        size="small"
                        className={classes.button}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          openPackSizeDefaultsDialog(item.gtin);
                        }}
                      >
                        Wielkości
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
            {tradeItemsQuery.items.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  Brak
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <Pagination {...pagination} allCount={tradeItemsQuery.count} />
    </Container>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    table: {
      overflow: "auto",
    },
    buttonCol: {
      paddingTop: 0,
      paddingBottom: 0,
      width: "150px",
    },
    buttonRow: {
      display: "flex",
      flexWrap: "nowrap",
    },
    button: {
      marginLeft: "10px",
      minWidth: "32px",
      fontSize: "12px",
      padding: 0,
    },
    item: {},
    row: {
      marginLeft: theme.spacing(1),
      display: "flex",
      "& > div": {
        marginRight: theme.spacing(1),
      },
    },
  };
});

export default TradeItemsHome;
