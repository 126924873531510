import { useTheme } from "@emotion/react";
import { MdWarningAmber } from "react-icons/md";

import { QuerySkeleton } from "@megaron/dash-skeleton";
import { DraftDto, ThreadDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

import { SimilarThreadTile } from "./SimilarThreadTile";

export const SimilarThreadAlert: React.FC<{ thread: ThreadDto | DraftDto; height?: string; className?: string }> = ({
  thread,
  height,
  className,
}) => {
  const theme = useTheme();

  const similarInvoiceThreadsQuery = useClientManager("docs")
    .similarInvoiceThreadsQuery()
    .useQuery({
      invoiceIssuer: thread.invoice.issuer,
      invoiceNumber: thread.invoice.invoiceNumber,
      status: ["open", "approved"],
    });

  return (
    <QuerySkeleton query={similarInvoiceThreadsQuery} height={height ?? "68px"}>
      {(threads) => {
        const filteredThreads = threads.filter((t) => t.threadUuid !== thread.uuid);

        if (filteredThreads.length > 0) {
          return (
            <div css={{ display: "flex", flexDirection: "column", gap: "10px" }} className={className}>
              <span
                css={{
                  fontSize: "16px",
                  fontWeight: 700,
                  color: theme.colors.warning,
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <MdWarningAmber size={"20px"} />
                Istnieje podobny wątek
              </span>
              <div css={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {filteredThreads.map((thread) => (
                  <SimilarThreadTile
                    key={"similar-tile-" + thread.threadUuid}
                    threadUuid={thread.threadUuid}
                    title={thread.title}
                    invoiceIssuer={thread.invoiceIssuer}
                    invoiceNumber={thread.invoiceNumber}
                    status={thread.status}
                  />
                ))}
              </div>
            </div>
          );
        }
        return null;
      }}
    </QuerySkeleton>
  );
};
