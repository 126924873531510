import { css, useTheme } from "@emotion/react";
import {
  MdAlternateEmail,
  MdOutlineDirectionsCar,
  MdOutlineHeadsetMic,
  MdOutlinePhone,
  MdOutlineSchool,
} from "react-icons/md";

import { InteractionType } from "@megaron/crm-contracts";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";

interface Props {
  onSelect: (type: InteractionType) => void;
  selectedType: InteractionType | undefined;
  isDisabled?: boolean;
}

export const InteractionTypeSelect: React.FunctionComponent<Props> = ({ onSelect, selectedType, isDisabled }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const iconFontSize = isMobile ? "1rem" : "1.25rem";

  const buttons = [
    { icon: <MdOutlinePhone fontSize={iconFontSize} />, name: "telefon", value: "call" },
    { icon: <MdOutlineDirectionsCar fontSize={iconFontSize} />, name: "wizyta", value: "visit" },
    { icon: <MdOutlineHeadsetMic fontSize={iconFontSize} />, name: "infolinia", value: "hotline" },
    { icon: <MdOutlineSchool fontSize={iconFontSize} />, name: "szkolenie", value: "training" },
    { icon: <MdAlternateEmail fontSize={iconFontSize} />, name: "inna", value: "other" },
  ];

  const buttonStyle = (isSelected: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: isMobile ? "0.625rem" : "0.75rem",
      width: "calc(max(3.25rem, 15%))",
      border: isSelected ? "1px solid theme.colors.primary" : "1px solid transparent",
    });

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
        }}
      >
        Rodzaj interakcji
      </div>
      <div css={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "1em", paddingTop: "0.5rem" }}>
        {buttons.map((button) => (
          <Button
            isDisabled={isDisabled}
            variant={selectedType === button.value ? "outline" : "text"}
            key={button.value}
            icon={button.icon}
            css={buttonStyle(selectedType === button.value)}
            onClick={() => onSelect(button.value as InteractionType)}
          >
            {button.name}
          </Button>
        ))}
      </div>
    </div>
  );
};
