import { CustomerStatus, macroregionResource, regionResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import {
  BoolSelectFilterConfig,
  FilterBar,
  FilterBarConfig,
  MultiSelectFilterConfig,
  SearchBar,
  SearchMultiSelectFilterConfig,
  SelectFilterConfig,
} from "@megarax/ui-components";
import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface Props {
  qs: QueryFilters;
  setQueryFilters: (qf: QueryFilters) => void;
}

type MapOptions = { lat: number; lng: number; zoom: number };

export interface QueryFilters {
  regionUuid: string[] | null;
  macroregionUuid: string[] | null;
  searchText: string;
  visitedSince: { value: number | null; bool: boolean };
  orderedSince: { value: number | null; bool: boolean };
  status: CustomerStatus[] | null;
  onRoute: "true" | "false" | null;
  mapOptions: MapOptions | null;
}

type FilterSet = {
  regionUuid: string[];
  macroregionUuid: string[];
  searchText: string;
  visitedSince: { value: number | null; bool: boolean };
  orderedSince: { value: number | null; bool: boolean };
  status: CustomerStatus[];
  onRoute: "true" | "false" | null;
};

export const Filters: React.FC<Props> = ({ qs, setQueryFilters }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { list: listRegions } = useResourceProviderV2(regionResource);
  const { list: listMacroregions } = useResourceProviderV2(macroregionResource);

  const getRegionOptions = (searchText: string) =>
    listRegions({ searchText, limit: 30 })
      .map((result) => result.items.map((region) => ({ value: region.uuid as string, label: region.name })))
      .mapError((err) => Promise.reject(err))
      .then((res) => res.value);

  const getMacroregionOptions = (searchText: string) =>
    listMacroregions({ searchText, limit: 30 })
      .map((result) =>
        result.items.map((macroregion) => ({ value: macroregion.uuid as string, label: macroregion.name })),
      )
      .mapError((err) => Promise.reject(err))
      .then((res) => res.value);

  const config: FilterBarConfig<FilterSet> = {
    status: MultiSelectFilterConfig<CustomerStatus>({
      label: "Status",
      options: [
        { label: "VIP", value: "vip" },
        { label: "Zweryfikowany", value: "verified" },
        { label: "Do weryfikacji", value: "unverified" },
        { label: "Nieaktywny", value: "inactive" },
      ],
    }),
    regionUuid: SearchMultiSelectFilterConfig({
      label: "Region",
      getOptions: getRegionOptions,
    }),
    macroregionUuid: SearchMultiSelectFilterConfig({
      label: "Makroregion",
      getOptions: getMacroregionOptions,
    }),
    visitedSince: BoolSelectFilterConfig({
      label: "Odwiedzony w ciągu",
      labels: {
        positive: "Odwiedzony",
        negative: "Nie odwiedzony",
        main: "W ciągu?",
      },
      options: [
        { label: "30 dni", value: 30 },
        { label: "90 dni", value: 90 },
        { label: "180 dni", value: 180 },
        { label: "360 dni", value: 360 },
      ],
    }),
    orderedSince: BoolSelectFilterConfig({
      label: "Zamówił w ciągu",
      labels: {
        positive: "Zamówił",
        negative: "Nie zamówił",
        main: "W ciągu?",
      },
      options: [
        { label: "30 dni", value: 30 },
        { label: "90 dni", value: 90 },
        { label: "180 dni", value: 180 },
        { label: "360 dni", value: 360 },
      ],
    }),
    onRoute: SelectFilterConfig({
      label: "Na stałej trasie?",
      options: [
        { label: "Na stałej trasie: Tak", value: "true" },
        { label: "Na stałej trasie: Nie", value: "false" },
      ],
    }),
  };

  const filters = toFilterSet(qs);

  return (
    <Box
      display="flex"
      px={2}
      py={1}
      sx={{ gap: "8px" }}
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      top={60}
    >
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        width="100%"
        alignItems={isMobile ? "center" : "flex-start"}
        justifyContent="center"
      >
        <Paper sx={{ mx: "16px", mt: "8px", pointerEvents: "auto" }}>
          <SearchBar
            onSearchChange={(searchText) => setQueryFilters({ ...qs, searchText: searchText })}
            initialValue={qs.searchText}
            sx={{ my: 0 }}
          />
        </Paper>

        <Box mt={1} ml={2}>
          <FilterBar<FilterSet>
            filters={filters}
            config={config}
            className="opaque"
            onChange={(filterSet) =>
              setQueryFilters({
                ...qs,
                ...fromFilterSet(filterSet, qs.searchText, qs.mapOptions),
              })
            }
            options={{
              hideIcon: true,
              opaque: true,
              centered: isMobile,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const toFilterSet = (qf: QueryFilters): FilterSet => ({
  ...qf,
  regionUuid: qf.regionUuid ?? [],
  macroregionUuid: qf.macroregionUuid ?? [],
  status: qf.status ?? [],
});

const fromFilterSet = (filterSet: FilterSet, searchText: string, mapOptions: MapOptions | null): QueryFilters => ({
  ...filterSet,
  searchText,
  mapOptions,
  regionUuid: filterSet.regionUuid.length > 0 ? filterSet.regionUuid : null,
  macroregionUuid: filterSet.macroregionUuid.length > 0 ? filterSet.macroregionUuid : null,
  status: filterSet.status.length > 0 ? filterSet.status : null,
});
