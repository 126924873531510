import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { DesktopViewLayout } from "./DesktopViewLayout";
import { MobileViewLayout } from "./MobileViewLayout";

interface Props {
  alerts: React.ReactNode | undefined;
  titleSection: React.ReactNode | undefined;
  tagSection: React.ReactNode | undefined;
  regionAndChainSection: React.ReactNode | undefined;
  pricingSection: React.ReactNode;
  addressSection: React.ReactNode | undefined;

  chat: React.ReactElement;
  contacts: React.ReactElement;
  recentOrders: React.ReactElement;
}

export const ViewLayout: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isMobile) return <MobileViewLayout {...props} />;

  return <DesktopViewLayout {...props} />;
};
