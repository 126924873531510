import Decimal from "decimal.js";

import { Failure } from "@megaron/result";
import { Serializer, Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

export type TransactionDto =
  | ProductCodeRedemptionTransactionDto
  | AffiliateCodeRedemptionTransactionDto
  | RewardPurchaseTransactionDto
  | AffiliatePointsTransactionDto
  | GiftPointsTransactionDto
  | ReferralBonusTransactionDto
  | AffiliateCodeClaimBonusTransactionDto
  | TaxReimbursementTransactionDto
  | PurchaseRefundTransactionDto;

export type ProductCodeRedemptionTransactionDto = {
  type: "productCodeRedemption";
  transactionUuid: Uuid;
  amount: Decimal;
  time: Date;
  codeId: string;
  productId: string;
};

export type AffiliateCodeRedemptionTransactionDto = {
  type: "affiliateCodeRedemption";
  transactionUuid: Uuid;
  amount: Decimal;
  time: Date;
  codeId: string;
};

export type RewardPurchaseTransactionDto = {
  type: "rewardPurchase";
  transactionUuid: Uuid;
  amount: Decimal;
  time: Date;
  rewardId: string;
  listingUuid: Uuid;
};

export type AffiliatePointsTransactionDto = {
  type: "affiliatePoints";
  transactionUuid: Uuid;
  amount: Decimal;
  time: Date;
};

export type GiftPointsTransactionDto = {
  type: "giftPoints";
  transactionUuid: Uuid;
  amount: Decimal;
  time: Date;
};
export type ReferralBonusTransactionDto = {
  type: "referralBonus";
  transactionUuid: Uuid;
  amount: Decimal;
  time: Date;
};
export type AffiliateCodeClaimBonusTransactionDto = {
  type: "affiliateCodeClaimBonus";
  transactionUuid: Uuid;
  amount: Decimal;
  time: Date;
};
export type TaxReimbursementTransactionDto = {
  type: "taxReimbursement";
  transactionUuid: Uuid;
  amount: Decimal;
  time: Date;
};
export type PurchaseRefundTransactionDto = {
  type: "purchaseRefund";
  transactionUuid: Uuid;
  amount: Decimal;
  time: Date;
};

const codeRedemptionTransactionDtoSerializer = Serializers.object<ProductCodeRedemptionTransactionDto>({
  amount: Serializers.decimal,
  codeId: Serializers.string,
  productId: Serializers.string,
  time: Serializers.datetime,
  type: Serializers.stringOneOf("productCodeRedemption"),
  transactionUuid: uuidSerializer,
});

const affiliateCodeRedemptionTransactionDtoSerializer = Serializers.object<AffiliateCodeRedemptionTransactionDto>({
  amount: Serializers.decimal,
  codeId: Serializers.string,
  time: Serializers.datetime,
  type: Serializers.stringOneOf("affiliateCodeRedemption"),
  transactionUuid: uuidSerializer,
});

const rewardPurchaseTransactionDtoDtoSerializer = Serializers.object<RewardPurchaseTransactionDto>({
  amount: Serializers.decimal,
  time: Serializers.datetime,
  listingUuid: uuidSerializer,
  transactionUuid: uuidSerializer,
  rewardId: Serializers.string,
  type: Serializers.stringOneOf("rewardPurchase"),
});

const affiliatePointsTransactionDtoSerializer = Serializers.object<AffiliatePointsTransactionDto>({
  amount: Serializers.decimal,
  transactionUuid: uuidSerializer,
  time: Serializers.datetime,
  type: Serializers.stringOneOf("affiliatePoints"),
});

const giftPointsTransactionDtoSerializer = Serializers.object<GiftPointsTransactionDto>({
  type: Serializers.stringOneOf("giftPoints"),
  amount: Serializers.decimal,
  transactionUuid: uuidSerializer,
  time: Serializers.datetime,
});
const referralBonusTransactionDtoSerializer = Serializers.object<ReferralBonusTransactionDto>({
  type: Serializers.stringOneOf("referralBonus"),
  amount: Serializers.decimal,
  transactionUuid: uuidSerializer,
  time: Serializers.datetime,
});
const affiliateCodeClaimBonusTransactionDtoSerializer = Serializers.object<AffiliateCodeClaimBonusTransactionDto>({
  type: Serializers.stringOneOf("affiliateCodeClaimBonus"),
  amount: Serializers.decimal,
  transactionUuid: uuidSerializer,
  time: Serializers.datetime,
});
const taxReimbursementTransactionDtoSerializer = Serializers.object<TaxReimbursementTransactionDto>({
  type: Serializers.stringOneOf("taxReimbursement"),
  amount: Serializers.decimal,
  transactionUuid: uuidSerializer,
  time: Serializers.datetime,
});
const purchaseRefundTransactionDtoSerializer = Serializers.object<PurchaseRefundTransactionDto>({
  type: Serializers.stringOneOf("purchaseRefund"),
  amount: Serializers.decimal,
  transactionUuid: uuidSerializer,
  time: Serializers.datetime,
});

const serializerMap = {
  affiliatePoints: affiliatePointsTransactionDtoSerializer,
  productCodeRedemption: codeRedemptionTransactionDtoSerializer,
  rewardPurchase: rewardPurchaseTransactionDtoDtoSerializer,
  affiliateCodeRedemption: affiliateCodeRedemptionTransactionDtoSerializer,
  giftPoints: giftPointsTransactionDtoSerializer,
  referralBonus: referralBonusTransactionDtoSerializer,
  affiliateCodeClaimBonus: affiliateCodeClaimBonusTransactionDtoSerializer,
  taxReimbursement: taxReimbursementTransactionDtoSerializer,
  purchaseRefund: purchaseRefundTransactionDtoSerializer,
};

export const transactionDtoSerializer: Serializer<TransactionDto> = {
  deserialize: (data: any) => {
    const type = data.type;
    const serializer = (serializerMap as any)[type];
    if (!serializer) {
      return Failure(`Unknown transaction type ${type}`);
    }
    return serializer.deserialize(data);
  },
  serialize: (data: TransactionDto) => {
    const type = data.type;
    const serializer = (serializerMap as any)[type];
    if (!serializer) {
      throw new Error(`Unknown transaction type ${type}`);
    }
    return serializer.serialize(data);
  },
};
