import { parseUrlToLabel } from "./parseUrlToLabel";

// production environment
export const POINTS_URL = "https://loyalty-points.prod.megarax.net";
export const ACCOUNTS_URL = "https://loyalty-accounts.prod.megarax.net";
export const ORDERS_URL = "https://loyalty-orders.prod.megarax.net";
export const CRM_URL = "https://crm.prod.megarax.net";
export const INVOICES_URL = "https://invoices.prod.megarax.net";
export const IAM_URL = "https://iam.prod.megarax.net";
export const API_URL = "https://legacy.prod.megarax.net";
export const DOCS_URL = "https://docs.prod.megarax.net";
export const QR_SYNC_URL = "https://loyalty-qr-sync.megarax.net";

// dev environment
export const DEV_POINTS_URL = "https://loyalty-points.sandbox.megarax.net";
export const DEV_ACCOUNTS_URL = "https://loyalty-accounts.sandbox.megarax.net";
export const DEV_ORDERS_URL = "https://loyalty-orders.sandbox.megarax.net";
export const DEV_CRM_URL = "https://crm.sandbox.megarax.net";
export const DEV_INVOICES_URL = "https://invoices.sandbox.megarax.net";
export const DEV_IAM_URL = "https://iam.sandbox.megarax.net";
export const DEV_API_URL = "https://legacy.sandbox.megarax.net";
export const DEV_DOCS_URL = "https://docs.sandbox.megarax.net";

// local environment
export const LOCAL_POINTS_URL = "http://localhost:5005";
export const LOCAL_ACCOUNTS_URL = "http://localhost:5006";
export const LOCAL_ORDERS_URL = "http://localhost:5007";
export const LOCAL_CRM_URL = "http://localhost:5350";
export const LOCAL_INVOICES_URL = "http://localhost:5001";
export const LOCAL_IAM_URL = "http://localhost:5250";
export const LOCAL_API_URL = "http://localhost:5000";
export const LOCAL_DOCS_URL = "http://localhost:5100";
export const LOCAL_QR_SYNC_URL = "http://localhost:5008";

// export const TEST_USER_UUID=25a4ed5b-bd18-4112-9853-dbace86168ba
// export const TEST_USER_TOKEN_PAYLOAD=null

export type UrlOption<T> = { value: T; label: string };

export type IamUrl = typeof IAM_URL | typeof DEV_IAM_URL | typeof LOCAL_IAM_URL;

export const iamUrls: UrlOption<IamUrl>[] = [
  { value: IAM_URL, label: parseUrlToLabel(IAM_URL) },
  { value: DEV_IAM_URL, label: parseUrlToLabel(DEV_IAM_URL) },
  { value: LOCAL_IAM_URL, label: parseUrlToLabel(LOCAL_IAM_URL) },
];

export type LegacyUrl = typeof API_URL | typeof DEV_API_URL | typeof LOCAL_API_URL;

export const legacyUrls: UrlOption<LegacyUrl>[] = [
  { value: API_URL, label: parseUrlToLabel(API_URL) },
  { value: DEV_API_URL, label: parseUrlToLabel(DEV_API_URL) },
  { value: LOCAL_API_URL, label: parseUrlToLabel(LOCAL_API_URL) },
];

export type DocsUrl = typeof DOCS_URL | typeof DEV_DOCS_URL | typeof LOCAL_DOCS_URL;

export const docsUrls: UrlOption<DocsUrl>[] = [
  { value: DOCS_URL, label: parseUrlToLabel(DOCS_URL) },
  { value: DEV_DOCS_URL, label: parseUrlToLabel(DEV_DOCS_URL) },
  { value: LOCAL_DOCS_URL, label: parseUrlToLabel(LOCAL_DOCS_URL) },
];

export type InvoicesUrl = typeof INVOICES_URL | typeof DEV_INVOICES_URL | typeof LOCAL_INVOICES_URL;

export const invoicesUrls: UrlOption<InvoicesUrl>[] = [
  { value: INVOICES_URL, label: parseUrlToLabel(INVOICES_URL) },
  { value: DEV_INVOICES_URL, label: parseUrlToLabel(DEV_INVOICES_URL) },
  { value: LOCAL_INVOICES_URL, label: parseUrlToLabel(LOCAL_INVOICES_URL) },
];

export type CrmUrl = typeof CRM_URL | typeof DEV_CRM_URL | typeof LOCAL_CRM_URL;

export const crmUrls: UrlOption<CrmUrl>[] = [
  { value: CRM_URL, label: parseUrlToLabel(CRM_URL) },
  { value: DEV_CRM_URL, label: parseUrlToLabel(DEV_CRM_URL) },
  { value: LOCAL_CRM_URL, label: parseUrlToLabel(LOCAL_CRM_URL) },
];

export type LoyaltyPointsUrl = typeof POINTS_URL | typeof DEV_POINTS_URL | typeof LOCAL_POINTS_URL;

export const loyaltyPointsUrls: UrlOption<LoyaltyPointsUrl>[] = [
  { value: POINTS_URL, label: parseUrlToLabel(POINTS_URL) },
  { value: DEV_POINTS_URL, label: parseUrlToLabel(DEV_POINTS_URL) },
  { value: LOCAL_POINTS_URL, label: parseUrlToLabel(LOCAL_POINTS_URL) },
];

export type LoyaltyAccountsUrl = typeof ACCOUNTS_URL | typeof DEV_ACCOUNTS_URL | typeof LOCAL_ACCOUNTS_URL;

export const loyaltyAccountsUrls: UrlOption<LoyaltyAccountsUrl>[] = [
  { value: ACCOUNTS_URL, label: parseUrlToLabel(ACCOUNTS_URL) },
  { value: DEV_ACCOUNTS_URL, label: parseUrlToLabel(DEV_ACCOUNTS_URL) },
  { value: LOCAL_ACCOUNTS_URL, label: parseUrlToLabel(LOCAL_ACCOUNTS_URL) },
];

export type LoyaltyOrderUrl = typeof ORDERS_URL | typeof DEV_ORDERS_URL | typeof LOCAL_ORDERS_URL;

export const loyaltyOrdersUrls: UrlOption<LoyaltyOrderUrl>[] = [
  { value: ORDERS_URL, label: parseUrlToLabel(ORDERS_URL) },
  { value: DEV_ORDERS_URL, label: parseUrlToLabel(DEV_ORDERS_URL) },
  { value: LOCAL_ORDERS_URL, label: parseUrlToLabel(LOCAL_ORDERS_URL) },
];

export type QrSyncUrl = typeof QR_SYNC_URL | typeof LOCAL_QR_SYNC_URL;

export const QrSyncUrls: UrlOption<QrSyncUrl>[] = [
  {
    value: QR_SYNC_URL,
    label: parseUrlToLabel(QR_SYNC_URL),
  },
  {
    value: LOCAL_QR_SYNC_URL,
    label: parseUrlToLabel(LOCAL_QR_SYNC_URL),
  },
];

export type UrlTemplate = "PROD" | "DEV" | "LOCAL";

export const urlTemplates: UrlOption<UrlTemplate>[] = [
  { label: "PROD", value: "PROD" },
  { label: "DEV", value: "DEV" },
  { label: "LOCAL", value: "LOCAL" },
];
