import {
  DesignateHomeInput,
  RegisterSalespersonInput,
  salespersonResource,
  SetManagerInput,
  SetWorkStartTimeInput,
  UpdateRegionInput,
} from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

import { promisifyResult, voidifyResult } from "../resourceHooks";

export type OnSuccessFunction = (() => void) | (() => Promise<void>);

export const useSalespeopleResource = () => {
  const salespeopleProvider = useResourceProviderV2(salespersonResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    SalespersonNotFound: "Nie znaleziono takiego handlowca",
    SalespersonAlreadyRegistered: "Handlowiec został już zarejestrowany",
  });

  const registerSalesperson = ({ onSuccess }: { onSuccess: OnSuccessFunction }) => (
    userId: number,
    input: RegisterSalespersonInput,
  ) =>
    salespeopleProvider
      .byUserId(userId)
      .registerSalesperson(undefined, input)
      .mapError(handleError)
      .map(onSuccess)
      .then(voidifyResult);

  const designateHome = ({ onSuccess }: { onSuccess: OnSuccessFunction }) => (
    userId: number,
    input: DesignateHomeInput,
  ) =>
    salespeopleProvider
      .byUserId(userId)
      .designateHome(undefined, input)
      .mapError(handleError)
      .map(onSuccess)
      .then(voidifyResult);

  const setWorkStartTime = ({ onSuccess }: { onSuccess: OnSuccessFunction }) => (
    userId: number,
    input: SetWorkStartTimeInput,
  ) =>
    salespeopleProvider
      .byUserId(userId)
      .setWorkStartTime(undefined, input)
      .mapError(handleError)
      .map(onSuccess)
      .then(voidifyResult);

  const updateRegion = ({ onSuccess }: { onSuccess: OnSuccessFunction }) => (
    userId: number,
    input: UpdateRegionInput,
  ) =>
    salespeopleProvider
      .byUserId(userId)
      .updateRegion(undefined, input)
      .mapError(handleError)
      .map(onSuccess)
      .then(voidifyResult);

  const setManager = ({ onSuccess }: { onSuccess: OnSuccessFunction }) => (userId: number, input: SetManagerInput) =>
    salespeopleProvider
      .byUserId(userId)
      .setManager(undefined, input)
      .mapError(handleError)
      .map(onSuccess)
      .then(voidifyResult);

  const listAll = () => salespeopleProvider.list({ limit: 1000 }).mapError(handleError);

  const getSalespeopleItems = (searchText: string) =>
    salespeopleProvider
      .list({ limit: 1000 })
      .mapError(handleError)
      .map((result) => result.items)
      .then(promisifyResult);

  const retrieve = (userId: number) => salespeopleProvider.byUserId(userId).retrieve().mapError(handleError);
  return {
    registerSalesperson,
    designateHome,
    setWorkStartTime,
    updateRegion,
    setManager,
    listAll,
    retrieve,
    getSalespeopleItems,
  };
};
