import { ChevronRight } from "@mui/icons-material";
import { Breadcrumbs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { BreadcrumbsContext } from "./BreadcrumbsContext";

export const BreadcrumbsBar: React.FC = () => {
  const classes = useStyles();
  const { breadcrumbs } = useContext(BreadcrumbsContext);
  if (breadcrumbs.length === 0) return null;

  return (
    <Breadcrumbs separator={<ChevronRight />} className={classes.breadcrumbs}>
      {breadcrumbs.map(({ to, title }, idx) => {
        const isLast = idx === breadcrumbs.length - 1;
        if (!to || isLast) return <Typography key={title}>{title}</Typography>;

        return (
          <Link color="inherit" to={to} key={title} style={{ textDecoration: "none" }}>
            <Typography color="textPrimary">{title}</Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
const useStyles = makeStyles((theme) => {
  return {
    breadcrumbs: {
      overflowX: "auto",
      minHeight: "50px",
      maxHeight: "50px",
      boxSizing: "border-box",
      padding: "12px 0",
      borderWidth: "0 0 1px 0",
      borderColor: theme.palette.divider,
      borderStyle: "solid",
      "& > ol": {
        display: "inline-flex",
        flexWrap: "nowrap",
        whiteSpace: "nowrap",
        padding: "0 12px",
      },
      "& > ol > .MuiBreadcrumbs-separator": {
        marginLeft: 2,
        marginRight: 2,
      },
    },
  };
});
