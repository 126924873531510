import { Uuid, uuidSerializer } from "@megarax/common";
import { Serializers } from "@megarax/serializers";

export type ScheduleVisitInput = {
  customerUuid: Uuid;
  durationMinutes: number;
};

export const scheduleVisitInputSerializer = Serializers.object<ScheduleVisitInput>({
  customerUuid: uuidSerializer,
  durationMinutes: Serializers.integer,
});
