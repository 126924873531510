import { paginatedListSerializer, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { regionSimpleSerializer } from "./regionSimple";

export interface RegionListQueryFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
}

const regionListQueryFiltersSerializer = MSerializers.object<RegionListQueryFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
});

export default ResourceActionV2({
  name: "list",
  method: "get",
  path: "",
  querySerializer: regionListQueryFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(regionSimpleSerializer),
});
