import _ from "lodash";

import { ResourceAction } from "./resource";
import { ResourceActionProvider, ResourceProvider } from "./resourceProvider";

export type LegacyHttpClient = {
  get: (url: string, query?: any) => Promise<any>;
  post: (url: string, query?: any, body?: any) => Promise<any>;
  put: (url: string, query?: any, body?: any) => Promise<any>;
  delete: (url: string, query?: any, body?: any) => Promise<any>;
};

const HttpActionProvider = (client: LegacyHttpClient) => <TResponse, TQuery, TBody>(
  action: ResourceAction<TResponse, TQuery, TBody>,
  basePath: string[],
): ResourceActionProvider<TResponse, TQuery, TBody> => {
  const fullPath = [...basePath, action.path];
  const fullUrl = fullPath.join("/");

  return async (query?, body?) => {
    const queryRaw = action.querySerializer && action.querySerializer.serialize(query!);
    const bodyRaw = action.requestBodySerializer && action.requestBodySerializer.serialize(body!);

    return client[action.method](fullUrl, queryRaw, bodyRaw).then(action.responseSerializer.forceDeserialize);
  };
};

export const HttpResourceProvider = (client: LegacyHttpClient) => ResourceProvider(HttpActionProvider(client));
