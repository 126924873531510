import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const myProductConsumptionSerializer = Serializers.object({
  totalConsumptionKg: Serializers.decimal,
  totalCodeCount: Serializers.integer,
  totalPointValue: Serializers.decimal,

  products: Serializers.record(
    Serializers.object({
      productId: Serializers.string,
      consumptionKg: Serializers.decimal,
      codeCount: Serializers.integer,
      pointValue: Serializers.decimal,
    }),
  ),
});

export type MyProductConsumption = SerializerValue<typeof myProductConsumptionSerializer>;

export const myProductConsumption = HttpAction({
  path: "/myProductConsumption",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: myProductConsumptionSerializer,
});
