import { Box, Drawer, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Uuid } from "@megarax/common";
import { LoadingBar, useDialogWithRoute } from "@megarax/ui-components";

import { VisitViewContainer } from "../../VisitView";

interface Props {
  dateSelect: React.ReactNode;
  isLoading: boolean;
  calendar: React.ReactNode;
  closeVisit: () => void;
}

export const ViewLayout: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) return <MobileViewLayout {...props} />;

  return (
    <div>
      <Paper className={classes.toolbarPaper}>
        <div className={classes.toolbar}>{props.dateSelect}</div>
        <LoadingBar loading={props.isLoading} />
      </Paper>
      {props.calendar}
      <Route
        path={`${path}/wizyta/:uuid`}
        render={({ match }) => (
          <Drawer anchor="right" open={true} onClose={props.closeVisit}>
            <Box maxWidth={450} minWidth={320} sx={{ overflowX: "hidden" }}>
              <VisitViewContainer uuid={match.params.uuid as Uuid} />
            </Box>
          </Drawer>
        )}
      ></Route>
    </div>
  );
};

export const MobileViewLayout: React.FunctionComponent<Props> = ({
  dateSelect,
  calendar,
  isLoading,
  closeVisit,
}) => {
  const classes = useStyles();
  const { path, url } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route
          path={`${path}/wizyta/:uuid`}
          render={({ match }) => <VisitViewContainer uuid={match.params.uuid as Uuid} />}
        ></Route>
        <Route>
          <Paper className={classes.toolbarPaper}>
            <div className={classes.toolbar}>{dateSelect}</div>
            <LoadingBar loading={isLoading} />
          </Paper>
          {calendar}
        </Route>
      </Switch>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  calendarSection: {
    display: "flex",
    flexDirection: "row",
  },
  view: {
    flexGrow: "1",
  },
  toolbarPaper: {
    zIndex: 10,
    position: "sticky",
    top: 0,
    borderRadius: 0,
  },
  toolbar: {
    display: "flex",
    alignContent: "space-around",
    "& > *": {
      margin: "0 auto",
    },
  },
}));
