import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";

import { BudgetDetails } from "./BudgetDetails";

export const NewBudgetPage = () => {
  const { isMobile } = useDeviceType();

  return (
    <>
      <HeaderBar header={"Nowy"} />

      <Page
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
          marginTop: "4.5rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
        }}
      >
        <BudgetDetails isNew />
      </Page>
    </>
  );
};
