import { gtinSerializer } from "../../common";
import { idObjectReferenceSerializer } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { PriceListChangeDto } from "../read/detail";

export const priceListChangeDtoSerializer = MSerializers.object<PriceListChangeDto>({
  uuid: MSerializers.uuid(),
  activeFrom: MSerializers.datetime,
  approvedAt: MSerializers.datetime,
  approvedBy: idObjectReferenceSerializer,
  comment: MSerializers.string,
  gtin: gtinSerializer,
  netPrice: MSerializers.decimal.nullable(),
  proposedAt: MSerializers.datetime,
  proposedBy: idObjectReferenceSerializer,
});
