import React, { useEffect, useRef } from "react";

import { useClientManager } from "@megaron/react-clients";
import useServiceWorker from "@megaron/react-use-service-worker";
import { Failure, Ok } from "@megaron/result";

export type WebPushContextType = {
  isSupported: boolean;
  permission: NotificationPermission;
  endpoint: string | null;
};

export const WebPushContext = React.createContext<WebPushContextType | undefined>(undefined);

type Props = {
  children: React.ReactNode;
  isAuthLoaded: boolean;
};

export const WebPushContextProvider: React.FunctionComponent<Props> = ({ children, isAuthLoaded }) => {
  const sw = useServiceWorker("/sw.js");
  const subscribeMutation = useClientManager("notifications").subscribeToWebPush().useMutation();

  const [permission, setPermission] = React.useState<NotificationPermission>("default");
  const [isSupported, setIsSupported] = React.useState<boolean>(false);
  const [endpoint, setEndpoint] = React.useState<string | null>(null);

  const isSubscribing = useRef(false);

  const subscribe = async () => {
    if (isSubscribing.current) return Failure("AlreadySubscribing");
    isSubscribing.current = true;

    if (!sw || !sw.pushManager) {
      isSubscribing.current = false;
      return Failure("PushManagerNotSupported");
    }

    try {
      let subscription = await sw.pushManager.getSubscription();
      if (!subscription) {
        subscription = await sw.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: process.env["NX_PUBLIC_WEB_PUSH_PUBLIC_KEY"],
        });
      }

      if (!subscription) {
        isSubscribing.current = false;
        return Failure("FailedToSubscribe");
      }

      const auth = arrayBufferToBase64(subscription.getKey("auth"));
      const p256dh = arrayBufferToBase64(subscription.getKey("p256dh"));
      if (!auth || !p256dh) {
        isSubscribing.current = false;
        return Failure("FailedToSubscribe");
      }

      await subscribeMutation.mutateAsync({
        endpoint: subscription.endpoint,
        keys: { auth, p256dh },
      });

      setEndpoint(subscription.endpoint);
      isSubscribing.current = false;
      return Ok({ endpoint: subscription.endpoint });
    } catch (error) {
      console.error("Error subscribing to web push notifications:", error);
      isSubscribing.current = false;
      return Failure("FailedToSubscribe");
    }
  };

  const enableNotifications = async () => {
    if (window.Notification.permission === "default") {
      await window.Notification.requestPermission();
    }

    setPermission(window.Notification.permission);

    if (window.Notification.permission === "granted") {
      return subscribe();
    }

    return Failure("PermissionDenied");
  };

  useEffect(() => {
    const initialize = async () => {
      if (!window.Notification || !sw?.pushManager) {
        setIsSupported(false);
        return;
      }

      setPermission(window.Notification.permission);
      setIsSupported(true);

      if (window.Notification.permission === "granted") {
        const result = await subscribe();
        if (result.isOk) {
          setEndpoint(result.value.endpoint);
        }
      }
    };

    if (isAuthLoaded) {
      initialize();
    }
  }, [sw, isAuthLoaded]);

  useEffect(() => {
    if (isAuthLoaded && permission === "default") {
      enableNotifications();
    }
  }, [isAuthLoaded, permission]);

  return <WebPushContext.Provider value={{ isSupported, permission, endpoint }}>{children}</WebPushContext.Provider>;
};

const arrayBufferToBase64 = (buf: ArrayBuffer | null) => {
  if (!buf) return null;
  return window.btoa(String.fromCharCode(...new Uint8Array(buf)));
};
