import { Currency, OrderPreview, TradeItemDto } from "@megarax/crm-contracts";
import { formatCurrency } from "@megarax/react-utils";
import { Table } from "@megarax/ui-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TableFooter,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  margins: OrderPreview;
  closeDialog: () => void;
  tradeItemsDictionary: {
    [gtin: string]: TradeItemDto | undefined;
  };
  currency: Currency;
}

export const MarginsDialog: React.FC<Props> = ({ margins, closeDialog, tradeItemsDictionary, currency }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog open={true} onClose={closeDialog} fullScreen={isMobile}>
      <DialogTitle>Marże</DialogTitle>
      <DialogContent className={classes.content}>
        <Table
          items={margins.lines}
          getKey={JSON.stringify}
          columns={[
            {
              title: "Przedmiot",
              getValue: (i) => tradeItemsDictionary[i.gtin]?.name ?? i.gtin,
            },
            { title: "Ilość", getValue: (i) => i.quantity.toString() },
            {
              title: "Marża",
              getValue: (i) => (i.lineTotalMargin ? formatCurrency(i.lineTotalMargin?.toNumber(), currency) : "Brak"),
            },
          ]}
          footer={
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>Razem</TableCell>
                <TableCell>{formatCurrency(margins.totalMargin.toNumber(), currency)}</TableCell>
              </TableRow>
            </TableFooter>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Zamknij</Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    content: {
      width: "400px",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        width: "100%",
        marginTop: theme.spacing(4),
      },
    },
  };
});
