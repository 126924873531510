import { Google } from "./MapContainer";

const getMarkerUrl = (variant: MarkerVariant) =>
  `https://storage.googleapis.com/megarax-map--icons/map_marker_${variant}.png`;

const markerVariants = {
  vip: {
    url: getMarkerUrl("vip"),
    zIndexBonus: 100000,
    labelColor: "#208a29",
  },
  verified: {
    url: getMarkerUrl("verified"),
    zIndexBonus: 10000,
    labelColor: "#424242",
  },
  unverified: {
    url: getMarkerUrl("unverified"),
    zIndexBonus: 0,
    labelColor: "#424242",
  },
  inactive: {
    url: getMarkerUrl("inactive"),
    zIndexBonus: 0,
    labelColor: "#424242",
  },
};

type MarkerVariant = "vip" | "verified" | "unverified" | "inactive" | "cluster" | "route" | "home";

export const googleMarkerFactory = ({ google, map }: { google: Google; map: google.maps.Map }) => {
  const baseMarkerIcon = {
    scaledSize: new google.maps.Size(21, 33), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(11, 33), // anchor
  };

  return {
    generate: (props: {
      position: { lat: number; lng: number };
      variant: MarkerVariant;
      idx: number;
      text: string;
    }) => {
      const zoom = map.getZoom();

      if (props.variant === "cluster")
        return new google.maps.Marker({
          position: props.position,
          map: map,
          label: { text: props.text, color: "white", fontSize: "10px", className: "cluster-label" },
          icon: {
            url: `https://storage.googleapis.com/megarax-map--icons/map_cluster_2.png`,
            ...baseMarkerIcon,
          },
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + parseInt(props.text),
        });

      if (props.variant === "home")
        return new google.maps.Marker({
          position: props.position,
          map: map,
          icon: {
            url: `https://storage.googleapis.com/megarax-map--icons/map_marker_home.png`,
            ...baseMarkerIcon,
          },
          zIndex: 1,
        });

      if (props.variant === "route")
        return new google.maps.Marker({
          position: props.position,
          map: map,
          label: {
            text: String(props.idx + 1),
            // color: "#174aa4",
            color: "white",
            fontSize: "12px",
            className: "cluster-label",
          },
          icon: {
            url: `https://storage.googleapis.com/megarax-map--icons/map_marker_route_2.png`,
            ...baseMarkerIcon,
          },
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + props.idx,
        });

      const variantOptions = markerVariants[props.variant];

      return new google.maps.Marker({
        position: props.position,
        map: map,
        icon: {
          ...baseMarkerIcon,
          url: variantOptions.url,
        },
        zIndex: props.idx + variantOptions.zIndexBonus,
        label:
          zoom && props.text && zoom > 11
            ? {
                text: props.text,
                className: "map-label",
                fontSize: zoom > 13 ? "14px" : "11px",
                color: variantOptions.labelColor,
              }
            : undefined,
      });
    },
  };
};
