import Decimal from "decimal.js";

import { Gtin } from "../common";

export type PricedItemDto = {
  gtin: Gtin;
  name: string | null;
  basePrice: Decimal | null;
  finalPrice: Decimal | null;
  cost: Decimal | null;
  absoluteProfitMargin: Decimal | null;
  relativeProfitMargin: Decimal | null;
};

export type PricedItemQueryFilters = {
  priceListUuid: string;
  discountRate: Decimal;
  postSaleDiscountRate: Decimal;
  searchText?: string;
  limit?: number;
  offset?: number;
  time?: Date;
};

export const pricedItemQueryErrors = ["InvalidDiscountRate", "InvalidPostSaleDiscountRate"] as const;

export type PricedItemQueryError = (typeof pricedItemQueryErrors)[number];
