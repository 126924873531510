import { Uuid } from "@megarax/common";
import {
  customerListResource,
  customerResource,
  CustomerStatus,
  DesignateVisitLocationInput,
  RegisterCustomerInput,
  TagCustomerInput,
} from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import Decimal from "decimal.js";

import { forceUnpack } from "@megaron/result";

export const useCustomerActions = (uuid: Uuid, onChange: () => Promise<void>) => {
  const customersProvider = useResourceProviderV2(customerResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    CustomerNotFound: "Nie udało się odnaleźć takiego klienta.",
    CustomerAlreadyRegistered: "Klient został już wcześniej utworzony.",
    CustomerWithThatNameExists: "Klient o takiej nazwie już istnieje.",
    CustomerNotDeleted: "Klient nie jest usunięty.",
  });

  const registerCustomer = (input: RegisterCustomerInput) =>
    customersProvider
      .byUuid(uuid)
      .registerCustomer(undefined, input)
      .mapError(handleError)
      .then(forceUnpack)
      .then(onChange);

  const renameCustomer = (name: string) =>
    customersProvider
      .byUuid(uuid)
      .renameCustomer(undefined, { name })
      .mapError(handleError)
      .then(forceUnpack)
      .then(onChange);

  const reassignRegion = (regionUuid: Uuid) =>
    customersProvider
      .byUuid(uuid)
      .reassignRegion(undefined, { regionUuid })
      .mapError(handleError)
      .then(forceUnpack)
      .then(onChange);

  const designateVisitLocation = (input: DesignateVisitLocationInput) =>
    customersProvider
      .byUuid(uuid)
      .designateVisitLocation(undefined, input)
      .mapError(handleError)
      .then(forceUnpack)
      .then(onChange);

  const assignToChain = (chainUuid: Uuid | null) =>
    customersProvider
      .byUuid(uuid)
      .assignToChain(undefined, { chainUuid })
      .mapError(handleError)
      .then(forceUnpack)
      .then(onChange);

  const tagCustomer = (tag: string) =>
    customersProvider
      .byUuid(uuid)
      .tagCustomer(undefined, { tag })
      .mapError(handleError)
      .then(forceUnpack)
      .then(onChange);

  const untagCustomer = (tag: string) =>
    customersProvider
      .byUuid(uuid)
      .untagCustomer(undefined, { tag })
      .mapError(handleError)
      .then(forceUnpack)
      .then(onChange);

  const changeStatus = (status: CustomerStatus) =>
    customersProvider
      .byUuid(uuid)
      .changeStatus(undefined, { status })
      .mapError(handleError)
      .then(forceUnpack)
      .then(onChange);

  return {
    designateVisitLocation,
    registerCustomer,
    renameCustomer,
    reassignRegion,
    assignToChain,
    tagCustomer,
    untagCustomer,
    changeStatus,
  };
};
