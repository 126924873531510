import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";

type Props = {
  decreeNumber: string | null;
  onSubmit: (decreeNumber: string | null) => void;
  isDisabled?: boolean;
  className?: string;
  label?: string;
};

export const DecreeNumberInput: React.FC<Props> = ({ decreeNumber, onSubmit, isDisabled, className, label }) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState<string>(decreeNumber ?? "");

  useEffect(() => {
    if (decreeNumber && decreeNumber !== inputValue && inputValue === "") {
      setInputValue(decreeNumber || "");
    }
  }, [decreeNumber, inputValue]);

  const inputProps = {
    value: inputValue,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setInputValue(e.target.value),
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        onSubmit(inputValue === "" ? null : inputValue);
      }
    },
    css: {
      width: "100%",
      border: "none",
      padding: "0.25rem 0.5rem",
      fontSize: "0.875rem",
      borderRadius: "6px",
      backgroundColor: theme.colors.primaryLight,
      ":focus": {
        outline: `1px solid ${theme.colors.primary}`,
      },
      disabled: {
        cursor: "text",
      },
    },
    disabled: isDisabled ? true : false,
  };

  return (
    <div
      css={{ display: "flex", flexDirection: "column", width: "100%", paddingRight: "0.5rem" }}
      className={className}
    >
      <label
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
          marginBottom: "0.25rem",
        }}
      >
        {label}
      </label>
      <input {...inputProps} type="text" />
    </div>
  );
};
