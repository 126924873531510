import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";
import { MdAdd, MdOutlineHistory } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useParams as useParamsCompat } from "react-router-dom-v5-compat";

import { CustomerEvent, Interaction } from "@megaron/crm-contracts";
import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { EntriesList } from "@megaron/dash-history";
import { useDeviceType } from "@megaron/dash-mq";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { TabHeader } from "../customers/TabHeader";
import { EventTile } from "./EventTile";
import { HistorySectionHeader } from "./HistorySectionHeader";
import { InteractionPage } from "./InteractionPage";
import { InteractionTile } from "./InteractionTile";

export const InteractionHistory: React.FC = () => {
  const { customerId }: { customerId?: Uuid } = useParamsCompat();

  if (!customerId) {
    return <div>Error: Customer ID not provided.</div>;
  }

  return <InteractionHistoryContent customerId={customerId} />;
};

const InteractionHistoryContent: React.FC<{ customerId: Uuid }> = ({ customerId }) => {
  const queryClient = useQueryClient();

  const { isMobile } = useDeviceType();

  const { profiles } = useProfiles();

  const interactionsQuery = useClientManager("crm").getCustomerHistory().useQuery({ customerId });

  const emailListSet = new Set(interactionsQuery.data?.interactions?.map((interaction) => interaction.ownerEmail));
  const emailList = Array.from(emailListSet);

  const profilesData = profiles(emailList);

  const dialog = useDialogRoute("/add-interaction", ({ onClose }) => (
    <InteractionPage
      onClose={onClose}
      header={"Dodaj Interakcje"}
      action={() => {
        queryClient.invalidateQueries(interactionsQuery.key);
      }}
    />
  ));

  return (
    <>
      {!isMobile && <TabHeader icon={<MdOutlineHistory size={18} />} text="Historia" />}
      <Button icon={<MdAdd />} variant="outline" onClick={dialog.open} css={{ alignSelf: "flex-end" }}>
        Dodaj
      </Button>

      <div css={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <QuerySkeleton query={interactionsQuery}>
          {(interactions) => (
            <EntriesList>
              {Object.entries(sortInteractionByDate(interactions)).map(([key, value], i) => {
                const getSectionHeader = () => {
                  if (key === "najnowsze") {
                    return undefined;
                  }

                  if (key === "poniżej miesiąca") {
                    const interaction = value[0];
                    const interactionDate =
                      "interactionDate" in interaction
                        ? new Date(interaction.interactionDate)
                        : new Date(interaction.createdAt);

                    return formatDistanceToNow(interactionDate, { locale: pl });
                  }

                  return key;
                };

                const header = getSectionHeader();

                return (
                  <>
                    {header && <HistorySectionHeader key={`header-${header}`} header={header} />}
                    {value.map((item) =>
                      "eventBody" in item && item.type ? (
                        <EventTile event={item} key={item.uuid} />
                      ) : (
                        <InteractionTile
                          key={item.uuid}
                          interaction={item}
                          profile={profilesData?.find((profile) => profile.email === item.ownerEmail)}
                          queryKey={interactionsQuery.key}
                        />
                      ),
                    )}
                  </>
                );
              })}
            </EntriesList>
          )}
        </QuerySkeleton>
      </div>
      {dialog.element}
    </>
  );
};

const sortInteractionByDate = (interactions: { interactions: Interaction[]; events: CustomerEvent[] }) => {
  const sortedInteractions = [...interactions.interactions, ...interactions.events].sort((a, b) => {
    const dateA = "interactionDate" in a ? new Date(a.interactionDate) : new Date(a.createdAt);
    const dateB = "interactionDate" in b ? new Date(b.interactionDate) : new Date(b.createdAt);

    return dateB.getTime() - dateA.getTime();
  });

  const groupedInteractions = sortedInteractions
    .map((interaction) => {
      const interactionDate =
        "interactionDate" in interaction ? new Date(interaction.interactionDate) : new Date(interaction.createdAt);

      return {
        interaction,
        distanceToNow: getDistanceToNow(interactionDate),
      };
    })
    .reduce((acc, interaction) => {
      if (acc[interaction.distanceToNow]) {
        acc[interaction.distanceToNow].push(interaction.interaction);
        return acc;
      }

      return { ...acc, [interaction.distanceToNow]: [interaction.interaction] };
    }, {} as Record<string, (Interaction | CustomerEvent)[]>);

  return groupedInteractions;
};

const getDistanceToNow = (date: Date) => {
  const isInFuture = date.getTime() > new Date().getTime();

  const daysDifference = getDaysDifference(date);

  if (isInFuture || daysDifference < 7) {
    return "najnowsze";
  }

  if (daysDifference <= 8) {
    return "tydzień";
  }

  if (daysDifference <= 30) {
    return "poniżej miesiąca";
  }

  const distanceToNow = formatDistanceToNow(date, { locale: pl });

  if (distanceToNow.startsWith("około")) {
    return distanceToNow.slice(6);
  }

  return distanceToNow;
};

const getDaysDifference = (date: Date) => {
  const today = new Date();
  const differenceTime = Math.abs(today.getTime() - date.getTime());
  return Math.ceil(differenceTime / (1000 * 60 * 60 * 24));
};
