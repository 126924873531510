import { css, useTheme } from "@emotion/react";
import React from "react";

import { Checkbox } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";

type Props<TOpt, TVal> = BaseProps<TOpt, TVal> & SelectProps<TOpt, TVal>;

type BaseProps<TOpt, TVal> = {
  items: TOpt[];
  columns: (item: TOpt) => { header: string; content: React.ReactNode; isPrimary?: boolean }[];
  getKey: (value: TOpt | TVal) => string | number;
  className?: string;
};

type SelectProps<TOpt, TVal> =
  | {
      isSelectable: true;
      onSelect: (values: (TOpt | TVal)[]) => void;
      selected: (TOpt | TVal)[];
    }
  | {
      isSelectable?: false;
      onSelect?: (values: (TOpt | TVal)[]) => void;
      selected?: (TOpt | TVal)[];
    };

export const EntityList = <TOpt, TVal = TOpt>(props: Props<TOpt, TVal>) => {
  const { isMobile } = useDeviceType();
  const theme = useTheme();

  const select = (value: TOpt) => {
    if (!props.isSelectable) return;
    if (props.onSelect) props.onSelect([...(props.selected || []), value]);
  };

  const onDeselect = (value: TVal | TOpt) => {
    if (!props.isSelectable) return;
    if (props.onSelect) props.onSelect((props.selected || []).filter((v) => props.getKey(v) !== props.getKey(value)));
  };

  const isRowSelected = (value: TOpt | TVal) => {
    if (!props.isSelectable) return false;
    return (props.selected || []).some((v) => props.getKey(v) === props.getKey(value));
  };

  if (isMobile)
    return (
      <div className={props.className}>
        {props.items.map((item) => (
          <MobileRow
            key={props.getKey(item)}
            isSelectable={props.isSelectable ?? false}
            isSelected={isRowSelected(item)}
            onDeselect={() => onDeselect(item)}
            onSelect={() => select(item)}
          >
            {props.columns(item).map((cell) => (
              <Cell header={cell.header} isPrimary={cell.isPrimary} key={cell.header}>
                {cell.content}
              </Cell>
            ))}
          </MobileRow>
        ))}
      </div>
    );

  const headers = props.items.length ? props.columns(props.items[0]).map((c) => c.header) : [];

  return (
    <div
      className={props.className}
      css={{
        minHeight: "300px",
      }}
    >
      <table
        css={{
          width: "100%",
          borderCollapse: "collapse",
          position: "relative",
        }}
      >
        <thead css={{}}>
          <tr css={{}}>
            {props.isSelectable ? <td></td> : null}
            {headers?.map((header) => (
              <th
                key={header}
                css={{
                  background: theme.colors.background,
                  position: "sticky",
                  top: 0,
                  textAlign: "left",
                  padding: "0.5rem",
                  fontWeight: 400,
                  fontFamily: theme.displayFontFamily,
                  color: theme.colors.primary,
                  fontSize: "0.875rem",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.items.map((item) => (
            <DesktopRow
              key={props.getKey(item)}
              isSelectable={props.isSelectable ?? false}
              isSelected={isRowSelected(item)}
              onDeselect={() => onDeselect(item)}
              onSelect={() => select(item)}
            >
              {props.columns(item).map((cell) => (
                <Cell header={cell.header} isPrimary={cell.isPrimary} key={cell.header}>
                  {cell.content}
                </Cell>
              ))}
            </DesktopRow>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const MobileRow = (props: {
  children: React.ReactNode;
  isSelectable: boolean;
  isSelected: boolean;
  onSelect: () => void;
  onDeselect: () => void;
}) => {
  const theme = useTheme();
  const onSelectChange = (value: boolean) => {
    if (!props.isSelectable) return;
    if (value) props.onSelect();
    else props.onDeselect();
  };

  return (
    <div
      onClick={() => onSelectChange(!props.isSelected)}
      css={{
        padding: "0.5rem 1rem 0.75rem",
        margin: "0 -1rem",
        display: "flex",
        flexDirection: "row",
        flexShrink: "0",
        background: props.isSelected ? theme.colors.selectedBackground : undefined,
        borderBottom: "1px solid",
        borderColor: theme.colors.border,
      }}
    >
      {props.isSelectable ? (
        <div css={{ display: "flex", alignItems: "center" }}>
          <Checkbox css={{ marginRight: "1rem" }} value={props.isSelected} onChange={onSelectChange} />
        </div>
      ) : null}
      <div
        css={{
          minWidth: 0,
          flexShrink: 1,
          width: "100%",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export const DesktopRow: React.FunctionComponent<{
  children: React.ReactNode;
  isSelectable: boolean;
  isSelected: boolean;
  onSelect: () => void;
  onDeselect: () => void;
}> = (props) => {
  const theme = useTheme();
  const onSelectChange = (value: boolean) => {
    if (!props.isSelectable) return;
    if (value) props.onSelect();
    else props.onDeselect();
  };
  return (
    <tr
      onClick={() => onSelectChange(!props.isSelected)}
      css={{
        cursor: props.isSelectable ? "pointer" : undefined,
        background: props.isSelected ? theme.colors.selectedBackground : undefined,
        ":hover": {
          backgroundColor: props.isSelected ? theme.colors.selectedBackground : theme.colors.hover,
        },
      }}
    >
      {props.isSelectable ? (
        <td css={{ paddingLeft: "0.5rem" }}>
          <Checkbox value={props.isSelected} onChange={onSelectChange} />
        </td>
      ) : null}
      {props.children}
    </tr>
  );
};

export const Cell = (props: { children: React.ReactNode; header: string; isPrimary?: boolean }) => {
  const theme = useTheme();
  const { isMobile } = useDeviceType();

  const fontSize = props.isPrimary || !isMobile ? "1rem" : "0.875rem";

  const commonCss = css({
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize,
    "& a": {
      color: theme.colors.primary,
    },
  });

  if (isMobile)
    return (
      <div css={css(commonCss, { padding: props.isPrimary ? "0.125rem 0" : 0, width: "100%" })}>{props.children}</div>
    );
  return <td css={css(commonCss, { padding: "0.375rem 0.5rem" })}>{props.children}</td>;
};
