import { FlagFilter } from "./FlagFilter";

type Props = {
  className?: string;
  value: boolean | undefined;
  label: string;
  trueLabel: string;
  falseLabel: string;
  onChange: (value: boolean | undefined) => void;
};

export const BoolFilter = (props: Props) => {
  const label =
    props.value === true
      ? `${props.label}: ${props.trueLabel}`
      : props.value === false
      ? `${props.label}: ${props.falseLabel}`
      : props.label;

  return (
    <FlagFilter
      isActive={props.value !== undefined}
      label={label}
      onClick={() => props.onChange(!props.value)}
      onDelete={() => props.onChange(undefined)}
      color={props.value === false ? "danger" : "normal"}
    />
  );
};
