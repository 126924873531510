import { useDeviceType } from "@megaron/dash-mq";
import { Page } from "@megaron/dash-page";
import { LinkDoc } from "@megaron/docs-contracts";

import { filterLinkTree } from "./helpers";
import { LinkGroup } from "./LinkGroup";

type Props = {
  linkDocs: LinkDoc[] | undefined;
};

export const HomePage: React.FC<Props> = ({ linkDocs }) => {
  const { isMobile } = useDeviceType();

  const filteredLinkTree = filterLinkTree(linkDocs || []);

  return (
    <Page css={{ maxWidth: "1600px" }}>
      <div css={{ maxWidth: isMobile ? "unset" : "21rem", margin: "2rem 0 1rem" }}></div>
      <div
        css={{
          display: "grid",
          gap: "2rem",
          width: "100%",
          gridTemplateColumns: "repeat(auto-fill, minmax(16rem, 1fr))",
        }}
      >
        {filteredLinkTree.map((appTree) => (
          <LinkGroup key={appTree.app} linkTree={appTree} />
        ))}
      </div>
    </Page>
  );
};
