import { useDeviceType } from "@megaron/dash-mq";
import { BudgetDoc } from "@megaron/invoices-contracts";

import { BudgetTile } from "./BudgetTile";

type Props = {
  budgets: BudgetDoc[];
};

export const BudgetList: React.FC<Props> = ({ budgets }) => {
  const { isMobile } = useDeviceType();

  return (
    <div
      css={{
        display: "grid",
        gap: isMobile ? "0.5rem" : "0.75rem",
        gridTemplateColumns: "repeat(auto-fill, minmax(20rem, 1fr))",
        marginBottom: "1rem",
      }}
    >
      {budgets.map((budget) => (
        <BudgetTile key={budget.uuid} budget={budget} />
      ))}
    </div>
  );
};
