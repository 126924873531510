import {
  CustomerDetail,
  orderCommentResource,
  orderDraftResource,
  shippingOrderResource,
} from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import {
  commonErrorsMap,
  DialogLoading,
  FeedbackSnackbarContext,
  useSnackbarErrorHandler,
} from "@megarax/ui-components";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { Result } from "@megaron/result";

import { SendDraftDialog, SendDraftInput } from "./SendDraftDialog";

interface Props {
  recipient: Result<CustomerDetail, HttpRequestError | "CustomerNotFound"> | undefined;
}

export const SendDraftDialogContainer: React.FC<Props> = ({ recipient }) => {
  const params = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  const snackbarContext = useContext(FeedbackSnackbarContext);

  const commentProvider = useResourceProviderV2(orderCommentResource).byOrderUuid(params.uuid || "");

  const draftProvider = useResourceProviderV2(orderDraftResource);
  const deliveryOrderProvider = useResourceProviderV2(shippingOrderResource);

  const closeDialog = () => navigate(`/crm/pos/moje_zamowienia/${params.uuid}`);

  const navigateToOrdersList = () => navigate("/crm/pos/moje_zamowienia");

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    VersionConflict: "Wystąpił konflikt wersji wydarzenia.",
    OrderNotFound: "Zamówienie nie istnieje.",
    DraftNotOpen: "Wersja robocza nie posiada statusu: otwarta.",
    UserNotOrderOwner: "Nie jesteś właścicielem tego zamówienia.",
    DemandedDeliveryDateInThePast: "Data dostawy jest w przeszłości.",
    PreferredDatePastDeadline: "Preferowana data jest po deadlinie.",
    CommentAlreadyExists: "Komentarz już istnieje.",
    UnknownGtin: "Nieznana jednostka handlowa (GTIN).",
  });

  const { value: earliestDeliveryDateResult } = useAsyncLoad(
    () => deliveryOrderProvider.fastestDeliveryDate().mapError(handleError),
    [],
  );
  const onSendDraft = (input: SendDraftInput) =>
    draftProvider
      .byUuid(params.uuid || "")
      .place(undefined, input)
      .mapError(handleError)
      .map(() => {
        navigateToOrdersList();
        snackbarContext.pushMessage({
          content: "Pomślnie wysłano zamówienie.",
          severity: "success",
        });
      })
      .then(() => {});

  const ready =
    earliestDeliveryDateResult !== undefined &&
    earliestDeliveryDateResult.isOk &&
    recipient !== undefined &&
    recipient.isOk;

  return (
    <>
      {ready && (
        <SendDraftDialog
          earliestDeliveryDate={earliestDeliveryDateResult.value}
          recipient={recipient.value}
          closeDialog={closeDialog}
          onSendDraft={onSendDraft}
        />
      )}
      <DialogLoading loading={!ready} />
    </>
  );
};
