import React from "react";

import { Failure, Ok, Result } from "@megaron/result";

import { ErrorScreen } from "./ErrorScreen";
import { Skeleton } from "./Skeleton";

type Props<TVal, TErr> = {
  width?: string;
  height?: string;

  result: Ok<TVal> | Failure<TErr> | undefined;

  onRetry?: () => void;
  children?: (value: TVal) => React.ReactNode;
  errorScreen?: (error: TErr) => React.ReactNode;
  skeleton?: () => React.ReactNode;
};

export const ResultSkeleton = <TVal, TErr = never>(props: Props<TVal, TErr>): JSX.Element => {
  if (props.result === undefined) return <Skeleton height={props.height} width={props.width} />;

  if (props.result.isFailure)
    return (
      <ErrorScreen height={props.height} width={props.width} onRetry={props.onRetry}>
        {props.errorScreen?.(props.result.error) ??
          (typeof props.result.error === "string" ? props.result.error : JSON.stringify(props.result.error))}
      </ErrorScreen>
    );

  return <>{props.children?.(props.result.value)}</>;
};
