import { css, Global, Theme } from "@emotion/react";

export const createTheme = (hue: string): Theme => ({
  desktopMinWidth: "992px",
  tabletMinWidth: "640px",
  siteMaxWidth: "1120px",
  extraSmallBorderRadius: "4px",
  smallBorderRadius: "6px",
  mediumBorderRadius: "8px",
  largeBorderRadius: "13px",
  primaryFontFamily: "'Source Sans 3', sans-serif",
  displayFontFamily: "'Red Hat Display', sans-serif",
  baseHue: hue,
  colors: {
    primary: `hsl(${hue}, 75%, 23%)`,
    primaryLight: `hsl(${hue},  70%, 94%)`,
    background: `hsl(${hue}, 30%, 96.5%)`,
    hover: `hsl(${hue}, 40%, 94%)`,

    secondaryText: "#666",
    selectedBackground: `hsl(${hue}, 40%, 93%)`,

    border: "#00000033",
    skeleton: "#E5E5E5",
    overlay: "rgba(0, 0, 0, 0.25)",

    danger: "hsla(0, 100%, 23%, 1)",
    dangerBackground: "hsl(0, 60%, 93%)",
    success: `hsl(140, 75%, 23%)`,
    warning: "#DD6B20",
    warningBackground: "#FFFAF0",

    loyalty: "hsl(328, 75%, 25%)",
    crm: "hsl(273, 75%, 25%)",
    iam: "hsl(191, 75%, 25%)",
    invoices: "hsl(234, 75%, 25%)",
    plm: "hsl(14, 75%, 25%)",
    dev: "hsl(205, 75%, 25%)",
    default: "#333",
  },
});

export const DefaultGlobalStyles = () => (
  <Global
    styles={css`
      @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Roboto:wght@300;400;500;700&display=swap");

      html,
      body {
        padding: 0;
        height: 100%;
        margin: 0;
        font-family: "Source Sans 3", sans-serif;
        font-optical-sizing: auto;
      }

      #root {
        height: 100%;
      }

      * {
        font-family: inherit;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      p a {
        font-weight: 400;
      }

      * {
        box-sizing: border-box;
      }

      nav ul {
        list-style: none;
      }

      :target {
        scroll-margin-top: 80px;
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      .clamped-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;

        -webkit-box-orient: vertical;
      }
    `}
  />
);
