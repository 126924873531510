import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Add, Remove } from "@mui/icons-material";
import { Box, Divider, FormControl, IconButton, TextField } from "@mui/material";
import React from "react";

import { InputProps, PickedTextFieldProps } from "../props";
import { useIntegerInputStyles } from "./styled";

interface IntegerInputProps extends InputProps<number>, PickedTextFieldProps {
  caption?: string;
  onBlur?: () => void;
}

export const IntegerInput: React.FC<IntegerInputProps> = (props) => {
  const classes = useIntegerInputStyles();
  const { onChange, value, helperText, label, error, ...muiProps } = props;

  const onNumberChange = (newValue: string) => {
    const parsedNumber = parseInt(newValue);
    const isEmptyString = newValue === "";
    const isValidNumber = !Number.isNaN(parsedNumber);
    if (isEmptyString || parsedNumber < 0) return onChange(0);
    if (!isValidNumber) return;
    onChange(parsedNumber);
  };

  return (
    <FormControl fullWidth>
      <TextField
        {...muiProps}
        fullWidth
        disabled={props.disabled}
        label={label}
        onChange={(e) => onNumberChange(e.target.value)}
        value={!props.disabled ? value : ""}
        error={Boolean(error)}
        helperText={helperText ?? error}
        InputProps={{
          className: classes.input,
          startAdornment: (
            <StartAdornment
              onNumberChange={onNumberChange}
              value={value.toString()}
              disabled={props.disabled}
            />
          ),
          endAdornment: (
            <EndAdornment
              onNumberChange={onNumberChange}
              value={value.toString()}
              disabled={props.disabled}
            />
          ),
        }}
      />
    </FormControl>
  );
};

interface AdornmentProps {
  onNumberChange: (value: string) => void;
  value: string;
  disabled: boolean | undefined;
}

const StartAdornment: React.FC<AdornmentProps> = ({ onNumberChange, value, ...rest }) => {
  const classes = useIntegerInputStyles();

  return (
    <Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center">
      <IconButton
        {...rest}
        onClick={() => onNumberChange((parseInt(value) - 1).toString())}
        size="large"
      >
        <Remove className={classes.icon} />
      </IconButton>
      <Divider orientation="vertical" className={classes.divider} />
    </Box>
  );
};

const EndAdornment: React.FC<AdornmentProps> = ({ onNumberChange, value, ...rest }) => {
  const classes = useIntegerInputStyles();

  return (
    <Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center">
      <Divider orientation="vertical" className={classes.divider} />
      <IconButton
        {...rest}
        onClick={() => onNumberChange((parseInt(value) + 1).toString())}
        size="large"
      >
        <Add className={classes.icon} />
      </IconButton>
    </Box>
  );
};
