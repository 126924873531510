import { BaseLogger } from "./BaseLogger";

export type Logger = BaseLogger;

let Logger = (module?: string): BaseLogger => console;

if (process.env["NX_PUBLIC_APP_ENV"] !== "browser") {
  Logger = require("./NodeLogger").NodeLogger;
}

export { Logger };
export const logger = Logger();
