import { useTheme } from "@emotion/react";
import { MdArrowForward, MdInfoOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { useToast } from "@megaron/dash-toast";
import { AttachmentUpload, NewDraft } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { newUuid } from "@megaron/uuid";

import { DragAndDrop } from "./DragAndDrop";

export const CreateDraftPage = () => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const navigate = useNavigate();

  const toast = useToast();

  const newDraftMutation = useClientManager("invoices").newDraft().useMutation();

  const handleCreateDraft = async (attachments: AttachmentUpload[]) => {
    const newThreadUuid = newUuid();

    const newDraftData: NewDraft = {
      uuid: newThreadUuid,
      ocr: true,
      attachments,
    };

    newDraftMutation.mutate(newDraftData, {
      onSuccess: () => {
        navigate(`/faktury/wersje-robocze/${newThreadUuid}`);
      },
      onError: () => {
        toast.error("Nie udało się utworzyć wersji roboczej.");
      },
    });
  };

  return (
    <>
      <HeaderBar header={"Utwórz wersję roboczą"} />

      <Page
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
          marginTop: "4.5rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
        }}
      >
        <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%", marginTop: "1rem" }}>
          <DragAndDrop onFileUpload={handleCreateDraft} isUploading={newDraftMutation.isLoading} />
        </div>
        <div css={{ display: "flex", gap: "0.375rem", color: theme.colors.primary }}>
          <MdInfoOutline size={20} css={{ flexShrink: 0 }} />
          <span css={{ fontSize: "0.875rem" }}>Załącznik powinien zawierać dokładne dane faktury.</span>
        </div>
        <Button
          variant="outline"
          css={{
            alignSelf: "flex-end",
            width: isMobile ? "100%" : "auto",
            alignItems: "center",
            display: "flex",
            gap: "0.25rem",
          }}
          onClick={() => handleCreateDraft([])}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem",
            }}
          >
            Kontynuuj bez załącznika
            <MdArrowForward size={16} />
          </div>
        </Button>
      </Page>
    </>
  );
};
