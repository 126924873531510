import { useTheme } from "@emotion/react";
import { ChartData, ChartOptions } from "chart.js";
import { addDays } from "date-fns";
import React from "react";
import { Line } from "react-chartjs-2";
import tinycolor from "tinycolor2";

import { ErrorScreen, Skeleton } from "@megaron/dash-skeleton";
import { toDateString } from "@megaron/date-string";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  groups: string[] | undefined;
};

export const options: ChartOptions<"line"> = {
  responsive: true,

  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
    x: {},
  },
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

export const DailyUserStats: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const since = toDateString(addDays(new Date(), -30));
  const until = toDateString(new Date());

  const query = useClientManager("loyaltyPoints")
    .dailyUserActivity()
    .useQuery({ groupNames: props.groups, since, until });

  if (query.data === undefined) return <Skeleton />;
  if (query.error) return <ErrorScreen></ErrorScreen>;
  const days = query.data;

  const redeemColor = theme.colors.primary;

  const data: ChartData<"line"> = {
    labels: days.map((d) => d.date),
    datasets: [
      {
        label: "Użytkownicy wykorzystujący kody",
        data: days.map((d) => d.redeemingUsers),
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointHoverBackgroundColor: redeemColor,
        borderColor: redeemColor,
        backgroundColor: redeemColor,
      },
    ],
  };

  return (
    <div>
      <Line options={options} data={data} css={{ maxHeight: "350px" }} />
    </div>
  );
};
