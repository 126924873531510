import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom-v5-compat";

import { getEnvString } from "@megaron/get-env-var";

import { App } from "./app/app";

const sentryDsn = getEnvString("NX_PUBLIC_SENTRY_DSN", "");
if (sentryDsn)
  Sentry.init({
    dsn: sentryDsn,
    integrations: [],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
  });

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
);
