import { Currency, Gtin, profitMarginOrderResource, tradeItemResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { commonErrorsMap, DialogLoading, useSnackbarErrorHandler } from "@megarax/ui-components";
import Decimal from "decimal.js";
import _ from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { MarginsDialog } from "./MarginsDialog";

interface Line {
  uuid: string;
  gtin: Gtin;
  quantity: Decimal;
  netPricePerUnit: Decimal;
}

interface Props {
  lines: Line[];
  payer: { uuid: string };
  currency: Currency;
}

export const MarginsDialogContainer: React.FC<Props> = ({ lines, payer, currency }) => {
  const profitMarginOrderProvider = useResourceProviderV2(profitMarginOrderResource);
  const tradeItemProvider = useResourceProviderV2(tradeItemResource);

  const params = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    PayerPostSaleDiscountRateNotFound: "Płatnik nie posiada rabatu posprzedażowego.",
    TradeItemNotFound: "Nie znaleziono jednostki handlowej.",
  });

  const closeDialog = () => navigate(`/crm/pos/moje_zamowienia/${params.uuid}`);

  const { value: marginsResult } = useAsyncLoad(
    () =>
      profitMarginOrderProvider
        .preview(undefined, {
          lines: lines.map((line) => ({
            gtin: line.gtin,
            price: line.netPricePerUnit,
            quantity: line.quantity,
          })),
          payerUuid: payer.uuid,
          time: new Date(),
        })
        .mapError((error) => {
          handleError(error);
          closeDialog();
        }),
    [],
  );

  const { value: tradeItemsDictionary } = useAsyncLoad(() => {
    if (!marginsResult?.isOk) return Promise.resolve(undefined);
    const tradeItemGtins = marginsResult.value.lines.map((line) => line.gtin);
    return Promise.all(
      tradeItemGtins.map((gtin) =>
        tradeItemProvider
          .byGtin(gtin)
          .retrieve()
          .mapError((error) => {
            handleError(error);
            return Promise.reject(error);
          })
          .then((result) => result.value ?? undefined),
      ),
    ).then((result) => _.keyBy(result, "gtin"));
  }, [marginsResult]);

  const ready = marginsResult !== undefined && !marginsResult.isFailure && tradeItemsDictionary;

  return (
    <>
      {ready && (
        <MarginsDialog
          margins={marginsResult.value}
          tradeItemsDictionary={tradeItemsDictionary}
          closeDialog={closeDialog}
          currency={currency}
        />
      )}
      <DialogLoading loading={!ready} error={marginsResult?.isFailure} />
    </>
  );
};
