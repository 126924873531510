import { Box, Chip, InputBase, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

type Props<TValue> = {
  className?: string;
  labels: {
    positive: string;
    negative: string;
    main: string;
  };
  value: {
    value: TValue | null;
    bool: boolean;
  };
  options: {
    label: string;
    value: TValue;
  }[];
  onChange: (newValue: { value: TValue | null; bool: boolean }) => void;
  style?: {
    opaque?: boolean;
  };
};

export const BoolSelectChip = <TValue extends string | number>({
  value,
  className,
  options,
  onChange,
  labels,
  style,
}: Props<TValue>): React.ReactElement => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const isSelected = value.value !== null;

  const selectedOption = options.find((o) => o.value === value?.value) ?? null;

  const onDelete = () => {
    onChange({ bool: value.bool, value: null });
  };

  return (
    <div className={[classes.container, className].join(" ")}>
      <Select
        open={isOpen}
        className={classes.select}
        value={value?.value ?? ""}
        inputProps={{ hidden: true }}
        input={<InputBase />}
        onClose={() => setIsOpen(false)}
        onChange={(e) =>
          onChange({ value: e.target.value as TValue, bool: value?.bool !== undefined ? value.bool : true })
        }
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {(value?.bool ? labels.positive : labels.negative) + ": " + option.label}
          </MenuItem>
        ))}
      </Select>

      <Chip
        className={[classes.chip, style?.opaque && !isSelected ? classes.opaque : ""].join(" ")}
        onClick={() => setIsOpen(!isOpen)}
        clickable
        label={selectedOption?.label ?? labels.main}
        color={isSelected ? "primary" : "default"}
        onDelete={isSelected ? onDelete : undefined}
        component={({ children, ...props }: React.ComponentProps<typeof Box>) => {
          return (
            <Box {...props}>
              <Box
                sx={{
                  px: 2,
                  borderRight: "1px solid black",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange({
                    value: value.value,
                    bool: !value.bool,
                  });
                }}
              >
                {value.bool ? labels.positive : labels.negative}
              </Box>
              {children}
            </Box>
          );
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  chip: {},
  opaque: {
    backgroundColor: "#ededed",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  container: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  select: {
    visibility: "hidden",
    width: 0,
    height: 0,
    // opacity: 0,
  },
}));
