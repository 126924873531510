import { useTheme } from "@emotion/react";
import React, { useEffect, useRef } from "react";

type Props = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  className?: string;
  multiline?: boolean;
  inputElementProps?: React.InputHTMLAttributes<HTMLInputElement>;
  isDisabled?: boolean;
  autoFocus?: boolean;
};

export const TextField: React.FunctionComponent<Props> = ({
  value,
  onChange,
  label,
  className,
  inputElementProps,
  multiline = false,
  isDisabled = false,
  autoFocus = false,
}) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autoFocus) {
      if (multiline && textAreaRef.current) {
        textAreaRef.current.focus();
        textAreaRef.current.setSelectionRange(value.length, value.length);
      } else if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.selectionStart = value.length;
        inputRef.current.selectionEnd = value.length;
      }
    }
  }, [autoFocus]);

  const inputProps = {
    value,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value),
    css: {
      width: "100%",
      border: "none",
      padding: "0.5rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "6px",
      backgroundColor: theme.colors.primaryLight,
      ":focus": {
        outline: `1px solid ${theme.colors.primary}`,
      },
      disabled: {
        cursor: "text",
      },
    },
    disabled: isDisabled ? true : false,
  };

  const element = multiline ? (
    <textarea ref={textAreaRef} {...inputProps} />
  ) : (
    <input ref={inputRef} {...inputProps} {...inputElementProps} type="text" />
  );

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }} className={className}>
      <label
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
          marginBottom: "0.25rem",
        }}
      >
        {label}
      </label>
      {element}
    </div>
  );
};
