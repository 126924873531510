import { Pagination, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

interface Props {
  perPage: number;
  allItemsCount: number | undefined;
  onChange: (newPage: number) => void;
  page: number;
}
export const PaginationV2: React.FC<Props> = ({ perPage, allItemsCount, onChange, page }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Typography variant="caption" sx={{ color: theme.palette.grey[500], px: 2 }}>{`Wszystkich: ${
        allItemsCount ?? ""
      }`}</Typography>
      <Pagination
        disabled={allItemsCount === undefined}
        sx={{ display: "flex", justifyContent: "center", flexWrap: "nowrap" }}
        shape="rounded"
        size={isMobile ? "small" : "medium"}
        count={allItemsCount ? Math.ceil(allItemsCount / perPage) : undefined}
        page={page + 1}
        onChange={(e, v) => onChange(v - 1)}
        showFirstButton
        showLastButton
      />
    </div>
  );
};
