import { paginatedListSerializer, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

export interface Tag {
  label: string;
}

export interface TagQueryFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
}
const tagQueryFiltersSerializer = MSerializers.object<TagQueryFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
});

const tagSerializer = MSerializers.object<Tag>({
  label: MSerializers.string,
});

export default ResourceActionV2({
  name: "list",
  method: "get",
  path: "list",
  querySerializer: tagQueryFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(tagSerializer),
});
