import { ToggleChip } from "@megarax/ui-components";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React from "react";

interface Props {
  value: Decimal | null;
  onChange: (value: Decimal | null) => void;
  children?: React.ReactNode;
}

export const PromoPriceInput: React.FunctionComponent<Props> = ({ children, value, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.multiSelect}>
      <ToggleChip
        label="Za złotówkę"
        onChange={(v) => onChange(v ? new Decimal(1) : null)}
        value={value?.eq(1) ?? false}
      />
      <ToggleChip label="RW" onChange={(v) => onChange(v ? new Decimal(0) : null)} value={value?.eq(0) ?? false} />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    multiSelect: {
      "& > *": {
        margin: theme.spacing(1, 1, 1, 0),
      },
    },
  };
});
