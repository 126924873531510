import { Uuid } from "@megarax/common";
import { ChainSimple } from "@megarax/crm-contracts";
import { NewRouterResponsiveSelect } from "@megarax/ui-components";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export type GetChains = (search: string) => Promise<ChainSimple[]>;

interface Props {
  onClose: () => void;
  getChains: GetChains;
  initialChain: ChainSimple | null;
  onAssignToChain: (chainUuid: Uuid | null) => Promise<void>;
}

export const ChangeChainDialog: React.FunctionComponent<Props> = ({
  onClose,
  getChains,
  initialChain,
  onAssignToChain,
}) => {
  const classes = useStyles();
  const [uuid, setUuid] = React.useState<Uuid | null>(initialChain?.uuid ?? null);

  const save = async () => {
    await onAssignToChain(uuid);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Zmień sieć</DialogTitle>
      <DialogContent className={classes.content}>
        <NewRouterResponsiveSelect
          initialValue={initialChain}
          value={uuid}
          variant="standard"
          onChange={setUuid}
          getOptions={getChains}
          label="Sieć"
          getKey={(o) => o!.uuid}
          getLabel={(o) => o!.name}
          getValue={(o) => o!.uuid}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={save}>
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: "200px",
  },
}));
