import { useQuery } from "react-query";

import { toDateString, Uuid } from "@megarax/common";
import { ChangeBreakInput, myWorkDayResource, workDayResource } from "@megarax/crm-contracts";
import { useCurrentUser, useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

export const useWorkTime = (userId: number, date: Date) => {
  const errorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const fixDate = (time: Date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes());

  const workTimeErrorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
    InvalidWorkTime: "Niepoprawne godziny pracy.",
    InvalidOdometer: "Niepoprawny stan licznika.",
    InvalidVisitTime: "Czas pracy niezgodny z godzinami wizyt.",
  });

  const breakActionErrorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
    InvalidWorkTime: "Czas przerwy niezgodny z godzinami pracy.",
    InvalidOdometer: "Niepoprawny stan licznika.",
    InvalidVisitTime: "Czas przerwy niezgodny z godzinami wizyt.",
  });

  const dayProvider = useResourceProviderV2(workDayResource).byUserId(userId).byDate(toDateString(date));
  const myDayProvider = useResourceProviderV2(myWorkDayResource).byDate(toDateString(date));

  const { data: myWorkDay, isFetching: isLoading, refetch } = useQuery(
    ["workDay", userId, date],
    () => dayProvider.retrieve().mapError(errorHandler),
    {},
  );

  const currentUser = useCurrentUser();
  const isMine = currentUser?.id === userId;

  const changeStart = async (input: { time: Date | null; odometer: number | null }) => {
    if (!isMine) return;
    const result = await myDayProvider
      .changeStart(undefined, {
        startOdometer: input.odometer,
        startTime: input.time && fixDate(input.time),
      })
      .mapError(workTimeErrorHandler);
    result.assertOk();
    refetch();
  };
  const changeEnd = async (input: { time: Date | null; odometer: number | null }) => {
    if (!isMine) return;

    const result = await myDayProvider
      .changeEnd(undefined, {
        endOdometer: input.odometer,
        endTime: input.time && fixDate(input.time),
      })
      .mapError(workTimeErrorHandler);
    result.assertOk();
    refetch();
  };
  const changeBreak = async (breakUuid: Uuid, input: ChangeBreakInput) => {
    if (!isMine) return;

    const result = await myDayProvider.breaks
      .byUuid(breakUuid)
      .changeBreak(undefined, {
        ...input,
        endTime: input.endTime && fixDate(input.endTime),
        startTime: fixDate(input.startTime),
      })
      .mapError(breakActionErrorHandler);
    result.assertOk();
    refetch();
  };

  const deleteBreak = async (breakUuid: Uuid) => {
    if (!isMine) return;

    const result = await myDayProvider.breaks.byUuid(breakUuid).removeBreak().mapError(breakActionErrorHandler);
    result.assertOk();
    refetch();
  };

  return {
    myWorkDay,
    isLoading,
    refetch,

    changeStart,
    changeEnd,
    changeBreak,
    deleteBreak,
  };
};
