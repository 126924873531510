import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

import { PayerDetail, PriceListSimple } from "@megarax/crm-contracts";
import { ProfilesDictionary } from "@megarax/iam-webapp";

import { AddProposal, GetPriceLists, NewDiscountProposalDialog } from "./NewDiscountProposalDialog";

interface Props {
  payer: PayerDetail | null;
  priceLists: PriceListSimple[];
  profiles: ProfilesDictionary;

  onClose: () => void;
  onAddProposal: AddProposal;
  onApproveProposal: (uuid: string) => Promise<any>;
  onRejectProposal: (uuid: string) => Promise<any>;

  getPriceLists: GetPriceLists;
}

export const PricingDialog: React.FunctionComponent<Props> = ({
  payer,
  priceLists,
  profiles,
  onAddProposal,
  getPriceLists,
  onApproveProposal,
  onRejectProposal,
  onClose,
}) => {
  const classes = useStyles();
  const [newOpen, setNewOpen] = useState<boolean>(false);

  const getPriceListName = (
    priceList: {
      uuid: string;
    } | null,
  ) => {
    if (!priceList) return "-";
    const list = priceLists.find((list) => list.uuid === priceList.uuid);
    if (!list) return "-";
    return list.name;
  };

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Aktualne warunki handlowe</DialogTitle>
        <DialogContent>
          <Typography>{payer?.priceList ? `Cennik ${getPriceListName(payer.priceList)}` : "Brak cennika"}</Typography>
          <Typography>
            {`Rabat podstawowy `}
            {payer ? `${payer.baseDiscount.mul(100).toFixed(2)}%` : "0%"}
          </Typography>
          <Typography className={classes.otherTermsText}>{payer?.otherTerms ?? "-"}</Typography>
          {payer && payer.proposals.length ? (
            <div>
              <Typography variant="h6">Propozycje zmian</Typography>
              <List disablePadding>
                {payer!.proposals.map((proposal) => (
                  <ListItem key={proposal.uuid} disableGutters>
                    <ListItemAvatar>
                      <div>
                        <Avatar
                          className={classes.avatar}
                          alt={profiles[proposal.proposedBy.id]?.displayedName}
                          src={profiles[proposal.proposedBy.id]?.pictureUrl ?? undefined}
                        />
                      </div>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Cennik ${getPriceListName(proposal.priceList)} - rabat ${proposal.baseDiscount
                        .mul(100)
                        .toFixed(2)}%`}
                      secondary={
                        <div className={classes.proposalActions}>
                          <Typography className={classes.otherTermsText} variant="body2">
                            {`${profiles[proposal.proposedBy.id]?.displayedName ?? "Nieznany użytkownik"} ${
                              proposal.otherTerms ? ` - ${proposal.otherTerms}` : ""
                            }`}
                          </Typography>

                          <div>
                            <Button
                              size="small"
                              variant="text"
                              color="primary"
                              onClick={() => onApproveProposal(proposal.uuid)}
                            >
                              Zatwierdź
                            </Button>
                            <Button
                              size="small"
                              variant="text"
                              color="secondary"
                              onClick={() => onRejectProposal(proposal.uuid)}
                            >
                              Odrzuć
                            </Button>
                          </div>
                        </div>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setNewOpen(true)} variant="text" color="primary">
            Zaproponuj zmianę
          </Button>
        </DialogActions>
      </Dialog>
      <NewDiscountProposalDialog
        addProposal={(proposal) => onAddProposal(proposal).then(() => setNewOpen(false))}
        getPriceLists={getPriceLists}
        open={newOpen}
        closeDialog={() => setNewOpen(false)}
      />
    </>
  );
};

const useStyles = makeStyles((theme: any) => {
  return {
    proposalActions: {
      display: "flex",
      flexDirection: "column",
    },
    otherTermsText: {
      whiteSpace: "pre-wrap",
    },
    paper: {
      maxWidth: "600px",
    },
    hover: {
      cursor: "pointer",
    },
    avatar: {
      fontSize: "0.9rem",
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium as any,
    },
  };
});
