import { Box, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-query";

import { ThreadIdentifier } from "@megarax/crm-contracts";
import { meResource } from "@megarax/iam-contracts";
import { useProfilesDictionary } from "@megarax/iam-webapp";
import { useResourceProviderV2 } from "@megarax/react-client";
import { LoadingBar } from "@megarax/ui-components";

import { EmptyChatScreen } from "./EmptyChatScreen";
import { useMentions } from "./mentions";
import { MessageInput } from "./MessageInput";
import { MessageLog, ProfileMap } from "./MessageLog";
import { useThread } from "./useChatSubscription";

interface Props {
  threadIdentifier: ThreadIdentifier;
  className?: string;
}
export const ChatBoxContainer: React.FunctionComponent<Props> = ({ threadIdentifier, className }) => {
  const classes = useStyles();

  const {
    threadDto,
    isFetching,
    refetch,
    send,
    isSubscribed,
    subscribe,
    unsubscribe,
    isUnacknowledged,
    acknowledgeMessages,
  } = useThread(threadIdentifier);

  const { getMentionableUsers } = useMentions();

  React.useEffect(() => {
    if (threadDto && isUnacknowledged) acknowledgeMessages(threadDto.messages.length);
  }, [threadDto?.messages.length, isUnacknowledged]);

  const meProvider = useResourceProviderV2(meResource);
  const me = useQuery("me", () => meProvider.user());
  const profilesDictionary = useProfilesDictionary(threadDto?.participants.map((p) => ({ id: p.userId }))) ?? {};

  const profiles: ProfileMap = _.mapValues(
    profilesDictionary,
    (p) =>
      p && {
        userId: p.user.id,
        name: p.fullName ?? "?",
        pictureUrl: p.pictureUrl ?? "",
      },
  );

  return (
    <div className={clsx(classes.paper, className)}>
      {threadDto?.messages !== undefined ? (
        <>
          <Box mb="-4px">
            <LoadingBar loading={isFetching} />
          </Box>
          {threadDto.messages.length === 0 ? (
            <EmptyChatScreen />
          ) : (
            <div className={classes.messageLogContainer}>
              <MessageLog messages={threadDto.messages} profiles={profiles} myId={me.data?.value?.id} />
            </div>
          )}
          <MessageInput
            getMentionableUsers={getMentionableUsers}
            onSend={send}
            isSubscribed={isSubscribed}
            onSubscribe={subscribe}
            onUnsubscribe={unsubscribe}
          />
        </>
      ) : (
        <Skeleton variant="rectangular" height={500} />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "hidden",
  },
  messageLogContainer: {
    maxHeight: "500px",
    minHeight: "120px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column-reverse",
  },
}));
