import { CSSObject, useTheme } from "@emotion/react";
import { Link } from "react-router-dom-v5-compat";
import tinycolor from "tinycolor2";

type Props = { linkTo?: string; children: React.ReactNode; cellCss?: CSSObject };

export const TableCell: React.FC<Props> = ({ linkTo, children, cellCss }) => {
  const theme = useTheme();

  const tdStyles = {
    background: "white",
    borderColor: `${tinycolor(theme.colors.primary).setAlpha(0.2)}`,
    borderWidth: "1px",
    borderTopStyle: "solid",
    padding: linkTo ? 0 : "0.75rem",
    height: "100%",
    position: "relative",
  } as const;

  if (linkTo) {
    return (
      <td css={tdStyles}>
        <Link
          css={{
            padding: "0.75rem",
            width: "100%",
            display: "flex",
            height: "100%",
            alignItems: "center",
            ...cellCss,
            ":hover": {
              textDecoration: "none",
              ...cellCss?.[":hover"],
            },
          }}
          to={linkTo}
        >
          {children}
        </Link>
      </td>
    );
  }

  return <td css={{ ...tdStyles, ...cellCss }}>{children}</td>;
};
