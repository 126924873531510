import Decimal from "decimal.js";

import { UuidResourceParam } from "@megarax/common";
import { Gtin, gtinSerializer } from "../common";
import { BaseResource, NestedResourceNode, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { MonthResourceParam, monthSerializer } from "./month";
import { orderMarginSerializer } from "./orderMargin";
import { regionMarginSerializer } from "./regionMargin";

export type OrderPreviewInput = {
  lines: {
    gtin: Gtin;
    price: Decimal;
    quantity: Decimal;
  }[];
  payerUuid: string;
  time: Date;
};

export const orderPreviewInputSerializer = MSerializers.object<OrderPreviewInput>({
  time: MSerializers.datetime,
  lines: MSerializers.array(
    MSerializers.object({
      gtin: gtinSerializer,
      price: MSerializers.decimal,
      quantity: MSerializers.decimal,
    }),
  ),
  payerUuid: MSerializers.uuid(),
});

export type OrderPreviewLine = {
  gtin: Gtin;
  quantity: Decimal;
  marginPerUnit: Decimal | null;
  lineTotalMargin: Decimal | null;
};

export const orderPreviewLineSerializer = MSerializers.object<OrderPreviewLine>({
  gtin: gtinSerializer,
  lineTotalMargin: MSerializers.decimal.nullable(),
  marginPerUnit: MSerializers.decimal.nullable(),
  quantity: MSerializers.decimal,
});

export type OrderPreview = {
  lines: OrderPreviewLine[];
  totalMargin: Decimal;
};

export const orderPreviewSerializer = MSerializers.object<OrderPreview>({
  lines: MSerializers.array(orderPreviewLineSerializer),
  totalMargin: MSerializers.decimal,
});

const regionMarginsAction = ResourceActionV2({
  name: "regionMargins",
  method: "get",
  path: "regionMargins",
  querySerializer: MSerializers.object({
    months: MSerializers.asJson(MSerializers.array(monthSerializer)),
  }),
  responseValueSerializer: MSerializers.array(regionMarginSerializer),
});

const marginsByOrderAction = ResourceActionV2({
  name: "marginsByOrder",
  method: "get",
  querySerializer: MSerializers.object({
    months: MSerializers.asJson(MSerializers.array(monthSerializer)),
  }),
  responseValueSerializer: MSerializers.array(orderMarginSerializer),
});

export const profitMarginOrderResource = BaseResource(["profitMargin", "orders"], {
  maintenance: NestedResourceNode("maintenance", {
    rebuildOrderChanges: ResourceActionV2({
      name: "maintenance.rebuildOrderChanges",
      method: "post",
      path: "rebuildOrderChanges",
    }),
    loadPastOrders: ResourceActionV2({
      name: "maintenance.loadPastOrders",
      method: "post",
      path: "loadPastOrders",
    }),
  }),

  preview: ResourceActionV2({
    name: "preview",
    method: "post",
    path: "preview",
    requestBodySerializer: orderPreviewInputSerializer,
    responseValueSerializer: orderPreviewSerializer,
    responseErrorSerializer: MSerializers.stringOneOf("PostSaleDiscountRateNotFound"),
  }),

  regionMargins: regionMarginsAction,
  byRegionUuid: UuidResourceParam("regionUuid", "regionUuid", {
    marginsByOrder: marginsByOrderAction,
  }),
  byUuid: UuidResourceParam("orderUuid", "orderUuid", {
    adjustMargin: ResourceActionV2({
      name: "adjustMargin",
      method: "post",
      requestBodySerializer: MSerializers.object({
        amount: MSerializers.decimal,
        reason: MSerializers.string,
        month: monthSerializer,
      }),
      responseErrorSerializer: MSerializers.identity<"VersionConflict">(),
    }),
  }),
});
