import { Gtin } from "@megarax/crm-contracts";
import { AsyncButton, DateTimeInput, SwitchInput, TextInput } from "@megarax/ui-components";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Decimal from "decimal.js";
import React from "react";

import { InputRow } from "../csvInput";

interface Props {
  onClose: () => void;
  rows: InputRow[];
  onProposeChanges: (input: {
    changes: { gtin: Gtin; netPrice: Decimal | null }[];
    activeFrom: Date | null;
    comment: string;
  }) => Promise<void>;
}

export const ConfirmCsvImportDialog: React.FunctionComponent<Props> = ({ rows, onClose, onProposeChanges }) => {
  const [activeImmediately, setActiveImmediately] = React.useState<boolean>(true);
  const [activeFrom, setActiveFrom] = React.useState<Date>(new Date());
  const [comment, setComment] = React.useState<string>("");

  const onConfirm = async () => {
    await onProposeChanges({
      changes: rows,
      activeFrom: activeImmediately ? null : activeFrom,
      comment,
    });
    onClose();
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Importuj CSV</DialogTitle>
      <DialogContent>
        <SwitchInput setValue={setActiveImmediately} value={activeImmediately} label="Aktywna od razu?" />
        <DateTimeInput
          label={`Aktywna od ${activeImmediately ? "razu" : ""}`}
          inputVariant="outlined"
          value={activeFrom}
          onChange={(date) => date && setActiveFrom(date)}
          minDate={new Date()}
          ampm={false}
          disabled={activeImmediately}
        />

        <TextInput label={"Komentarz"} variant="outlined" value={comment} onChange={setComment} multiline />
      </DialogContent>
      <DialogActions>
        <AsyncButton asyncAction={onConfirm} variant="contained" color="primary">
          Zatwierdź
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};
