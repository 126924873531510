import React from "react";
import { MdCheck } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { Dialog } from "@megaron/dash-dialog";
import { Button, TextField } from "@megaron/dash-form";
import { useClientManager } from "@megaron/react-clients";

import { getAccountPath } from "./paths";

type Props = {
  onClose: () => void;
};

export const NewAccountDialog: React.FunctionComponent<Props> = (props) => {
  const [email, setEmail] = React.useState("");
  const navigate = useNavigate();

  const createAccountMutation = useClientManager("iam")
    .saveAccount()
    .useMutation({
      onSuccess: () => {
        navigate(getAccountPath(email));
      },
    });

  return (
    <Dialog
      header="Nowe konto"
      onClose={props.onClose}
      footer={
        <Button
          icon={<MdCheck />}
          onClick={() => createAccountMutation.mutate({ email, isActive: true, groupIds: [] })}
          isLoading={createAccountMutation.isLoading}
          css={{ marginLeft: "auto" }}
        >
          Dalej
        </Button>
      }
    >
      <TextField label="Email" onChange={setEmail} value={email} css={{ minWidth: "300px" }} />
    </Dialog>
  );
};
