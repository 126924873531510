import { TextField } from "@mui/material";
import React from "react";
import { InputProps, PickedTextFieldProps } from "../props";

interface TextInputProps extends InputProps<string>, PickedTextFieldProps {}

export const TextInput: React.FC<TextInputProps> = ({ ...props }) => {
  const { helperText, error, value, label, onChange, ...muiProps } = props;

  return (
    <TextField
      {...muiProps}
      error={Boolean(error)}
      helperText={helperText ?? error}
      label={label}
      onChange={(event) => onChange(event.target.value)}
      value={value}
    ></TextField>
  );
};
