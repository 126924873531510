import { Uuid } from "@megarax/common";
import { RegionSimple, RegisterCustomerInput } from "@megarax/crm-contracts";
import { NewRouterResponsiveSelect, TextInput } from "@megarax/ui-components";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";

import { Result } from "@megaron/result";

interface Props {
  getRegions: (search: string) => Promise<RegionSimple[]>;
  registerCustomer: (input: RegisterCustomerInput) => Promise<Result<void, void>>;
  onComplete: () => void;
}

export const RegisterCustomerStep: React.FC<Props> = ({ getRegions, registerCustomer, onComplete }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [selectedRegionUuid, setSelectedRegionUuid] = useState<Uuid | null>(null);

  const nextStepDisabled = !name || !selectedRegionUuid || loading;

  const onRegisterCustomer = async () => {
    if (nextStepDisabled) return;
    setLoading(true);
    const result = await registerCustomer({ name, regionUuid: selectedRegionUuid });
    if (result.isFailure) return setLoading(false);
    onComplete();
  };

  return (
    <Box px={3}>
      <Box mb={2}>
        <Typography variant="subtitle2">Zarejestruj nowego klienta:</Typography>
      </Box>
      <Box mb={2}>
        <TextInput fullWidth label="Nazwa" variant="outlined" value={name} onChange={setName} />
      </Box>
      <NewRouterResponsiveSelect
        value={selectedRegionUuid}
        onChange={setSelectedRegionUuid}
        getOptions={getRegions}
        label="Region"
        getKey={(o) => o.uuid}
        getLabel={(o) => o.name}
        getValue={(o) => o.uuid as Uuid}
      />
      <Box display="flex" justifyContent="flex-end" py={2}>
        <Button variant="contained" color="primary" disabled={nextStepDisabled} onClick={onRegisterCustomer}>
          Dalej
        </Button>
      </Box>
    </Box>
  );
};
