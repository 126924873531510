import { AnyUser } from "@megaron/access-control";
import { DateString, dateStringSerializer } from "@megaron/date-string";
import { HttpAction, HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { accountDtoSerializer } from "./accountDto";
import { accountHistoryQuery } from "./accountHistoryQuery";
import { affiliateCodeActions } from "./affiliateCode";
import { availableRewardDtoSerializer } from "./availableRewardDto";
import { biActions } from "./bi";
import { codeActivityDayDtoSerializer } from "./codeActivityDayDto";
import { codeGroupsActions } from "./codeGroups";
import { codeSummarySerializer } from "./codeSummary";
import { emitGiftPointsResultSerializer } from "./giftPoints";
import { importTestCodesInputSerializer } from "./importTestCodes";
import { listingActions } from "./listing";
import { myAccountDtoSerializer } from "./myAccountDto";
import { myCustomerDtoSerializer } from "./myCustomerDto";
import { myProductConsumption } from "./myProductConsumption";
import { myPurchaseSummaryQuery } from "./myPurchaseSummaryQuery";
import { productCodeDtoSerializer } from "./productCodeDto";
import { publicProfileActions } from "./publicProfile";
import { purchaseSerializer, purchaseSimpleSerializer } from "./purchase";
import { transactionDtoSerializer } from "./transactionDto";
import { userActivityDayDtoSerializer } from "./userActivityDayDto";

const checkProductCode = HttpAction({
  path: "/productCodes/id/:codeId",
  method: "get",
  paramsSerializer: Serializers.object({
    codeId: Serializers.string,
  }),
  qsSerializer: Serializers.object<{ key?: string }>({
    key: Serializers.string.optional(),
  }),
  valueSerializer: productCodeDtoSerializer,
  errorSerializer: Serializers.stringOneOf("CodeNotFound"),
});

const redeemProductCode = HttpAction({
  path: "/productCodes/id/:codeId/redeem",
  method: "post",
  paramsSerializer: Serializers.object({
    codeId: Serializers.string,
  }),
  bodySerializer: Serializers.object({
    key: Serializers.string,
  }),
  requiresAuth: true as const,
  errorSerializer: Serializers.stringOneOf("InvalidCode", "CodeAlreadyRedeemed", "CodeExpired"),
});

const myAccount = HttpAction({
  path: "/myAccount",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: myAccountDtoSerializer,
});

const generateProductCode = HttpAction({
  path: "/productCodes/generate",
  method: "post",
  requiresAuth: true as const,
  valueSerializer: Serializers.object({
    id: Serializers.string,
    key: Serializers.string,
  }),
});

const generateProductCodes = HttpAction({
  path: "/productCodes/generateBatch",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    quantity: Serializers.integer,
    groupNames: Serializers.array(Serializers.string),
  }),
  valueSerializer: Serializers.array(
    Serializers.object({
      id: Serializers.string,
      key: Serializers.string,
    }),
  ),
});

const generateProductCodesCsv = HttpAction({
  path: "/productCodes/generateCsv",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object<{
    quantity: number;
    groupNames: string[];
    linkPrefixes?: { public: string; secret: string };
  }>({
    quantity: Serializers.integer,
    groupNames: Serializers.array(Serializers.string),
    linkPrefixes: Serializers.object({
      public: Serializers.string,
      secret: Serializers.string,
    }).optional(),
  }),
  valueSerializer: Serializers.object({ fileName: Serializers.string }),
  errorSerializer: Serializers.stringOneOf("FileAlreadyExists"),
});

const generateEncryptedProductCodesCsv = HttpAction({
  path: "/productCodes/generateEncryptedCsv",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    quantity: Serializers.integer,
    groupNames: Serializers.array(Serializers.string),
  }),
  valueSerializer: Serializers.object({ fileName: Serializers.string }),
  errorSerializer: Serializers.stringOneOf("FileAlreadyExists"),
});

const activateProductCode = HttpAction({
  path: "/productCodes/id/:codeId/activate",
  method: "post",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    codeId: Serializers.string,
  }),
  bodySerializer: Serializers.object({
    pointValue: Serializers.decimal,
    productId: Serializers.string,
    generatesCommission: Serializers.boolean,
    expiresAfterDays: Serializers.integer,
    massKg: Serializers.decimal,
  }),
});

const activateProductCodeGroups = HttpAction({
  path: "/productCodeGroups/activate",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    groupNames: Serializers.array(Serializers.string),
    pointValue: Serializers.decimal,
    productId: Serializers.string,
    expiresAfterDays: Serializers.integer,
    generatesCommission: Serializers.boolean,
    massKg: Serializers.decimal,
  }),
});

const myAvailableRewards = HttpAction({
  path: "/myAvailableRewards",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(availableRewardDtoSerializer),
});

const availableRewards = HttpAction({
  path: "/availableRewards",
  method: "get",
  valueSerializer: Serializers.array(availableRewardDtoSerializer),
});

const buyReward = HttpAction({
  path: "/buyReward",
  method: "post",
  bodySerializer: Serializers.object({
    listingUuid: uuidSerializer,
    rewardId: Serializers.string,
  }),
  valueSerializer: Serializers.object({
    purchaseUuid: uuidSerializer,
  }),
  errorSerializer: Serializers.stringOneOf(
    "NotEnoughPoints",
    "ListingNotFound",
    "ListingUnavailable",
    "ProStatusRequired",
    "RewardNotListed",
  ),
  requiresAuth: true as const,
});

const purchases = HttpAction({
  path: "/purchases",
  method: "get",
  requiresAuth: true as const,
  qsSerializer: Serializers.object({
    since: Serializers.datetime.optional(),
  }),
  valueSerializer: Serializers.array(purchaseSimpleSerializer),
});

const purchaseQuery = HttpAction({
  path: "/purchases/uuid/:purchaseUuid",
  method: "get",
  paramsSerializer: Serializers.object({
    purchaseUuid: uuidSerializer,
  }),
  requiresAuth: true as const,
  valueSerializer: purchaseSerializer.nullable(),
});

const myTransactionHistory = HttpAction({
  path: "/myTransactionHistory",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(transactionDtoSerializer),
});

const myCustomers = HttpAction({
  path: "/myCustomers",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(myCustomerDtoSerializer),
});

const productCodeSummary = HttpAction({
  path: "/productCodes/summary",
  method: "get",
  valueSerializer: codeSummarySerializer,
  qsSerializer: Serializers.object<{ groupNames?: string[] }>({
    groupNames: Serializers.jsonString(Serializers.array(Serializers.string)).optional(),
  }),
  requiresAuth: true as const,
});

const importTestCodes = HttpAction({
  path: "/productCodes/importTestCodes",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: importTestCodesInputSerializer,
});

const dailyCodeActivity = HttpAction({
  path: "/codeActivity/daily",
  qsSerializer: Serializers.object<{
    groupNames?: string[];
    since?: DateString;
    until?: DateString;
  }>({
    groupNames: Serializers.jsonString(Serializers.array(Serializers.string)).optional(),
    since: dateStringSerializer.optional(),
    until: dateStringSerializer.optional(),
  }),
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(codeActivityDayDtoSerializer),
});

const dailyUserActivity = HttpAction({
  path: "/userActivity/daily",
  qsSerializer: Serializers.object<{
    groupNames?: string[];
    since?: DateString;
    until?: DateString;
  }>({
    groupNames: Serializers.jsonString(Serializers.array(Serializers.string)).optional(),
    since: dateStringSerializer.optional(),
    until: dateStringSerializer.optional(),
  }),
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(userActivityDayDtoSerializer),
});

const emitGiftPoints = HttpAction({
  path: "/giftPoints/emit",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    amount: Serializers.decimal,
    lifetimeLimit: Serializers.decimal,
    dryRun: Serializers.boolean,
  }),
  valueSerializer: emitGiftPointsResultSerializer,
});

const claimGiftPoints = HttpAction({
  path: "/giftPoints/claim",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({}),
  errorSerializer: Serializers.stringOneOf("NoUnclaimedGiftPoints"),
});

const grantOverdueProStatuses = HttpAction({
  path: "/grantOverdueProStatuses",
  method: "post",
  requiresAuth: true as const,
  valueSerializer: Serializers.object({
    grantedCount: Serializers.integer,
  }),
});

const grantOverdueReferralBonuses = HttpAction({
  path: "/grantOverdueReferralBonuses",
  method: "post",
  requiresAuth: true as const,
  valueSerializer: Serializers.object({
    grantedCount: Serializers.integer,
  }),
});

const account = HttpAction({
  path: "/account/uuid/:uuid",
  method: "get",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    uuid: uuidSerializer,
  }),
  valueSerializer: accountDtoSerializer,
  errorSerializer: Serializers.stringOneOf("AccountNotFound"),
});

const grantProStatus = HttpAction({
  requiresAuth: true as const,
  path: "/account/uuid/:accountUuid/grantProStatus",
  method: "post",
  paramsSerializer: Serializers.object({
    accountUuid: uuidSerializer,
  }),
});

export const pointsHttpService = HttpService({
  account,
  accountHistoryQuery,
  activateProductCode,
  activateProductCodeGroups,
  availableRewards,
  buyReward,
  checkProductCode,
  claimGiftPoints,
  dailyCodeActivity,
  dailyUserActivity,
  emitGiftPoints,
  generateEncryptedProductCodesCsv,
  generateProductCode,
  generateProductCodes,
  generateProductCodesCsv,
  grantOverdueProStatuses,
  grantOverdueReferralBonuses,
  grantProStatus,
  importTestCodes,
  myAccount,
  myAvailableRewards,
  myCustomers,
  myProductConsumption,
  myPurchaseSummaryQuery,
  myTransactionHistory,
  productCodeSummary,
  purchaseQuery,
  purchases,
  redeemProductCode,
  ...publicProfileActions,
  ...affiliateCodeActions,
  ...listingActions,
  ...biActions,
  ...codeGroupsActions,
});

export type PointsHandlers = HandlerMap<typeof pointsHttpService, AnyUser>;
