import { useEffect, useState } from "react";

import { BaseSelectDialog, CommonSearchProps, SelectOption, SelectTrigger } from "./BaseSelectDialog";

type SelectProps<TOption extends SelectOption = SelectOption> = CommonSearchProps<TOption> & {
  initiallySelectedValues?: string[] | undefined;
  noTrigger?: boolean;
} & (
    | { options: TOption[]; search?: undefined }
    | { options?: undefined; search?: (searchText: string) => Promise<TOption[]> }
  );

export const SelectDialog = <TOption extends SelectOption = SelectOption>({
  options,
  noTrigger,
  ...selectProps
}: SelectProps<TOption>) => {
  const [initialOptions, setInitialOptions] = useState<TOption[]>([]);

  const search = selectProps.search;

  useEffect(() => {
    if (initialOptions.length > 0) {
      return;
    }

    const getInitialOptions = async () => {
      if (search) {
        const initialOptions = await search("");
        setInitialOptions(initialOptions);

        return;
      }

      if (options) {
        setInitialOptions(options);
      }
    };

    getInitialOptions();
  }, [options, search, initialOptions.length]);

  return (
    <BaseSelectDialog<TOption>
      {...selectProps}
      isSearchReady={initialOptions.length === 0}
      renderTrigger={
        noTrigger
          ? undefined
          : selectProps.renderTrigger
          ? selectProps.renderTrigger
          : (selectedOptions, onUnselectOption, _, onOpen) => (
              <SelectTrigger
                isDisabled={selectProps.isDisabled}
                onUnselectOption={onUnselectOption}
                selectedOptions={selectedOptions}
                label={selectProps.label}
                onClick={onOpen}
              />
            )
      }
      initialSelectedOptions={initialOptions.filter((option) =>
        selectProps.initiallySelectedValues?.includes(option.value),
      )}
      search={
        selectProps.search ??
        ((searchText) => {
          const modifiedOptions = initialOptions.filter((option) =>
            option.label.toLowerCase().includes(searchText.toLowerCase()),
          );

          return Promise.resolve(modifiedOptions);
        })
      }
    />
  );
};
