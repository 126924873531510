import { Serializers, SerializerValue } from "@megaron/serializers";

export const proStatusReasonSerializer = Serializers.stringOneOf("codeCount", "other");
export type ProStatusReason = SerializerValue<typeof proStatusReasonSerializer>;

export const myAccountDtoSerializer = Serializers.object({
  uuid: Serializers.string,
  balance: Serializers.decimal,
  isAffiliate: Serializers.boolean,
  unclaimedGiftPoints: Serializers.decimal,
  myAffiliateCodeIds: Serializers.array(Serializers.string),
  isPro: Serializers.boolean,
  proNumber: Serializers.integer.nullable(),
  lifetimeCodeCount: Serializers.integer,
  lifetimeConsumptionKg: Serializers.decimal,
});

export type MyAccountDto = SerializerValue<typeof myAccountDtoSerializer>;
