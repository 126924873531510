import { DateString, dateStringSerializer } from "@megarax/common";
import { MSerializers, serializerExtensions } from "@megarax/serializers";

export type UserMilageReport = {
  userId: number;
  totalMilageKm: number;
  days: {
    date: DateString;
    milageKm: number | null;
  }[];
};

export const userMilageReportSerializer = MSerializers.object<UserMilageReport>({
  userId: MSerializers.integer,
  totalMilageKm: MSerializers.integer,
  days: MSerializers.array(
    MSerializers.object({
      date: dateStringSerializer,
      milageKm: MSerializers.integer.nullable(),
    }),
  ),
});

export type MilageReport = {
  users: UserMilageReport[];
};

export const milageReportSerializer = MSerializers.object<MilageReport>({
  users: MSerializers.array(userMilageReportSerializer),
});
