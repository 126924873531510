import makeStyles from "@mui/styles/makeStyles";
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import React, { useMemo } from "react";

import { OrderSimple } from "@megarax/crm-contracts";
import { Pagination, SearchBar, SelectChip, ToggleChip } from "@megarax/ui-components";

import { getOrderListColumns } from "./orderListColumns";

interface Props {
  ordersQuery: {
    count: number;
    items: OrderSimple[];
  };
  sort: {
    sortModel: GridSortModel;
    setSortModel: (model: GridSortModel) => void;
  };
  pagination: {
    onChange: (e: React.ChangeEvent<unknown>, page: number) => void;
    count: number;
    page: number;
  };
  search: {
    setSearchText: (search: string) => void;
    initialValue: string;
  };
  navigateToDetails: (uuid: string) => void;
}

export const OrderList: React.FC<Props> = ({ ordersQuery, sort, pagination, search, navigateToDetails }) => {
  const classes = useStyles();

  const Footer = () => <Pagination {...pagination} allCount={ordersQuery.count} />;

  const orderColumns = useMemo(() => getOrderListColumns(), []);

  return (
    <div>
      <div className={classes.filterBar}>
        <div className={classes.search}>
          <SearchBar onSearchChange={search.setSearchText} initialValue={search.initialValue} width="300px" />
        </div>
      </div>

      <div className={classes.table}>
        <DataGrid
          className={classes.dataGrid}
          autoHeight
          paginationMode="server"
          filterMode="server"
          sortingMode="server"
          rowHeight={45}
          onRowClick={(params) => navigateToDetails(params.row.uuid)}
          columns={orderColumns}
          hideFooterSelectedRowCount
          hideFooterPagination
          rows={ordersQuery.items}
          getRowId={(rowData) => rowData.uuid}
          components={{
            Footer: Footer,
          }}
          sortModel={sort.sortModel}
          onSortModelChange={sort.setSortModel}
          localeText={{
            noRowsLabel: "Brak",
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    search: {
      marginRight: theme.spacing(2),
    },
    filterBar: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      margin: theme.spacing(2, 1),
    },
    multiSelect: {
      minWidth: "400px",
      [theme.breakpoints.down("md")]: {
        minWidth: "100%",
      },
      "& > .MuiChip-root": {
        margin: theme.spacing(1, 1, 1, 0),
      },
    },
    dataGrid: {
      borderRight: "0",
      borderLeft: "0",
      borderRadius: "0",
    },
    table: {
      "& .MuiDataGrid-menuIcon": {
        visibility: "visible",
      },
      "& .MuiDataGrid-row": {
        cursor: "pointer",
      },
      "& .MuiDataGrid-cell:focus": {
        outline: "none",
      },
    },
  };
});
