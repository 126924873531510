import { useTheme } from "@emotion/react";
import React from "react";
import { MdRefresh } from "react-icons/md";

type Props = {
  children?: React.ReactNode;
  onRetry?: () => void;
  width?: string;
  height?: string;
};

export const ErrorScreen: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        width: props.width ?? "100%",
        height: props.height ?? "300px",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: theme.colors.dangerBackground,
        borderRadius: theme.largeBorderRadius,
        color: theme.colors.danger,
        gap: "0.5rem",
      }}
    >
      {props.children ?? "Wystąpił błąd"}
      {props.onRetry && (
        <button
          css={{
            padding: "0.25rem 0.5rem",
            marginTop: "0.5rem",
            border: "none",
            background: "none",
            borderRadius: theme.largeBorderRadius,
            color: theme.colors.danger,
            cursor: "pointer",
          }}
          onClick={props.onRetry}
        >
          <MdRefresh size="2rem" />
        </button>
      )}
    </div>
  );
};
