import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

import { docTypeSerializer } from "./anyDoc";

const indexModuleDtoSerializer = Serializers.object({
  docType: docTypeSerializer,
  indexVersionName: Serializers.string,
  indexAlias: Serializers.string,
  isEnabled: Serializers.boolean,
  lastRefreshAt: Serializers.datetime.nullable(),
  // mappings: Serializers.identity(),
  version: Serializers.string,
  isSetupComplete: Serializers.boolean,
  setupError: Serializers.identity(),
});

export type IndexModuleDto = SerializerValue<typeof indexModuleDtoSerializer>;

export const indexModuleActions = {
  indexModulesQuery: HttpAction({
    path: "/indexModules",
    valueSerializer: indexModuleDtoSerializer.array(),
    requiresAuth: true,
  }),
  refreshIndexDocs: HttpAction({
    path: "/refreshDocs",
    method: "post",
    requiresAuth: true,
    qsSerializer: Serializers.object({
      module: docTypeSerializer,
    }),
  }),
};
