import { Alert, Box, Button, Typography } from "@mui/material";
import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";

import { CustomerSimple, DesignateVisitLocationInput } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { AsyncResult, Result } from "@megaron/result";

import { Address, formatAddress, LocationPickerV2 } from "../shared/LocationPickerV2";

interface Props {
  onComplete: () => void;
  designateVisitLocation: (input: DesignateVisitLocationInput) => Promise<Result<void, void>>;
  getNearbyCustomers: (input: {
    lat: Decimal;
    lng: Decimal;
  }) => AsyncResult<Result<CustomerSimple[], HttpRequestError>>;
}

export const SetVisitLocationStep: React.FC<Props> = ({ onComplete, designateVisitLocation, getNearbyCustomers }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [nearbyCustomers, setNearbyCustomers] = useState<CustomerSimple[] | undefined>([]);
  const [address, setAddress] = useState<Address>({
    name: "",
    locality: "",
    street: "",
    postalCode: "",
    country: "Polska",
  });
  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>(null);

  const onDesignateVisitLocation = async () => {
    if (!latLng) return;
    setLoading(true);
    const result = await designateVisitLocation({
      ...address,
      lat: new Decimal(latLng.lat),
      lng: new Decimal(latLng.lng),
    });
    if (result.isFailure) return setLoading(false);
    onComplete();
  };

  useEffect(() => {
    if (!latLng) return;
    getNearbyCustomers({ lat: new Decimal(latLng.lat), lng: new Decimal(latLng.lng) }).then((result) => {
      if (result.isFailure) return setNearbyCustomers(undefined);
      setNearbyCustomers(result.value);
    });
  }, [latLng]);

  const designateVisitLocationDisabled = loading || !latLng;

  return (
    <div>
      {nearbyCustomers !== undefined && nearbyCustomers.length > 0 && (
        <Box mb={2} px={3}>
          <Alert severity="warning">
            <Typography display="block" variant="subtitle2">
              Istnieje klient o pobliskim adresie
            </Typography>
            <Typography variant="inherit" display="block">
              {nearbyCustomers[0].name}
            </Typography>
            <Typography variant="inherit" display="block">
              {nearbyCustomers[0].visitAddress ? formatAddress(nearbyCustomers[0].visitAddress) : "Brak Adresu"}
            </Typography>
          </Alert>
        </Box>
      )}
      <LocationPickerV2 address={address} setAddress={setAddress} latLng={latLng} setLatLng={setLatLng} />

      <Box display="flex" justifyContent="flex-end" px={3} py={2}>
        <Button
          variant="contained"
          color="primary"
          disabled={designateVisitLocationDisabled}
          onClick={onDesignateVisitLocation}
        >
          Dalej
        </Button>
      </Box>
    </div>
  );
};
