import React from "react";

import { NotificationButton } from "./notifications/NotificationButton";

type Props = {
  title: React.ReactNode;
  logo: React.ReactNode;
  content?: React.ReactNode;
  className?: string;
  isCompact?: boolean;
  hideNotification?: boolean;
};

export const NavHeader: React.FunctionComponent<Props> = (props) => {
  return (
    <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          color: "white",
          gap: "12px",
          height: "32px",
        }}
      >
        <span css={{ height: "24px", width: "24px", fontSize: "24px" }}>{props.logo}</span>
        {props.content ?? <span css={{ fontSize: "16px" }}>{props.title}</span>}
      </div>
      <NotificationButton hideNotification={props.hideNotification} />
    </div>
  );
};
