import _ from "lodash";

import { Failure, Ok } from "@megaron/result";
import { Serializer, SerializerExtensions, serializerExtensions } from "@megaron/serializers";

import { MegaraxRole, MegaraxRoleWildcard, megaraxRoleWildcard } from "./roles";
import { UserType } from "./user";

export type AuthAttribute = `user:${string}` | `role:${MegaraxRole | MegaraxRoleWildcard}` | `userType:${UserType}`;

export const userIdToAuthAttribute = (userId: string): AuthAttribute => `user:${userId}`;

export const pickRoleAttrs = <T extends MegaraxRole | MegaraxRoleWildcard>(...roles: T[]): AuthAttribute[] =>
  roles.map(roleToAuthAttribute);

export const roleToAuthAttribute = (role: MegaraxRole | MegaraxRoleWildcard): AuthAttribute => `role:${role}`;

export const authAttributeSerializer: Serializer<AuthAttribute> & SerializerExtensions<AuthAttribute> = {
  serialize: (value) => value,
  deserialize: (value) => {
    if (typeof value !== "string") return Failure("NotAString");

    return Ok(value as AuthAttribute);
  },
  ...serializerExtensions(),
};

export const getRoleWildcards = (roles: MegaraxRole[]): MegaraxRoleWildcard[] =>
  megaraxRoleWildcard.filter((wildcard) => roles.some((role) => role.startsWith(wildcard.split(".")[0] + ".")));

export const getUserAuthAttributes = (userProps: {
  id: string;
  userType: UserType;
  roles?: MegaraxRole[];
}): AuthAttribute[] => {
  const roleAttributes = userProps.roles?.map(roleToAuthAttribute) ?? [];
  const roleWildcardAttrs = getRoleWildcards(userProps.roles ?? []).map(roleToAuthAttribute);

  return [
    `userType:${userProps.userType}` as const,
    userIdToAuthAttribute(userProps.id),
    ...roleAttributes,
    ...roleWildcardAttrs,
  ];
};
