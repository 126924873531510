import { BaseResource, ResourceAction, ResourceActionV2, UuidResourceParam } from "@megarax/rest-resource";
import { Serializers } from "@megarax/serializers";

import { PayerProposalStatusDto, payerProposalStatusSerializer } from "./payer";
import { PriceListProposalStatusDto, priceListProposalStatusSerializer } from "./priceList";

export type MyNotificationDto = {
  uuid: string;
  emittedAt: Date;
  payload: NotificationPayload;
};

export type NotificationPayload = AwaitingPriceListProposalsStatus | AwaitingPayerProposalsStatus;

export type NotificationPayloadType = NotificationPayload["type"];

export type AwaitingPriceListProposalsStatus = {
  type: "awaitingPriceListProposals";
  status: PriceListProposalStatusDto;
};

export type AwaitingPayerProposalsStatus = {
  type: "awaitingPayerProposals";
  status: PayerProposalStatusDto;
};

export type UnacknowledgedInvoiceThreadsStatus = {
  type: "unacknowledgedInvoiceThreads";
  threads: {
    uuid: string;
    title: string;
  }[];
};

export const notificationPayloadSerializer = Serializers.match<NotificationPayload, NotificationPayload["type"]>(
  (i) => i.type,
  (i) => i.type,
  {
    awaitingPayerProposals: Serializers.object<AwaitingPayerProposalsStatus>({
      type: Serializers.stringOneOf("awaitingPayerProposals"),
      status: payerProposalStatusSerializer,
    }),
    awaitingPriceListProposals: Serializers.object<AwaitingPriceListProposalsStatus>({
      type: Serializers.stringOneOf("awaitingPriceListProposals"),
      status: priceListProposalStatusSerializer,
    }),
  },
);

export const feedMyNotificationResource = BaseResource(["feed", "myNotifications"], {
  byUuid: UuidResourceParam("notificationUuid", {
    dismiss: ResourceActionV2({
      name: "dismiss",
      method: "delete",
      path: "",
      responseErrorSerializer: Serializers.stringOneOf<"NotificationNotFound">(),
    }),
  }),
  list: ResourceActionV2({
    name: "list",
    method: "get",
    path: "",
    responseValueSerializer: Serializers.array(
      Serializers.object<MyNotificationDto>({
        uuid: Serializers.uuid(),
        emittedAt: Serializers.datetime,
        payload: notificationPayloadSerializer,
      }),
    ),
  }),
});

export const feedNotificationsResource = BaseResource(["feed", "notifications"], {
  refresh: ResourceAction({
    name: "refresh",
    method: "post",
  }),
});
