import { useTheme } from "@emotion/react";
import React from "react";
import { MdStart } from "react-icons/md";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  orderUuid: Uuid;
};

export const NewFulfillmentCard: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const toast = useToast();

  const { mq } = useDeviceType();

  const mutation = useClientManager("loyaltyOrders")
    .restartOrderFulfillment()
    .useMutation({
      onError: (error) => toast.error("Nie udało się rozpocząć realizacji zamówienia", error),
      onSuccess: (data, vars, ctx) => {
        ctx.queryClient.invalidateQueries(["loyaltyOrders", "orders"]);
        ctx.queryClient.invalidateQueries(["loyaltyOrders", "order"]);
      },
    });

  return (
    <Button
      variant="outline"
      icon={<MdStart />}
      isLoading={mutation.isLoading}
      onClick={() => mutation.mutate({ uuid: props.orderUuid })}
      css={{
        border: `1px solid`,
        borderRadius: theme.largeBorderRadius,
        padding: "1rem",
        borderStyle: "dashed",
        borderColor: theme.colors.border,
        minWidth: "280px",
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        flexGrow: 1,
        minHeight: "150px",
        maxWidth: "400px",
      }}
    >
      Rozpocznij realizację
    </Button>
  );
};
