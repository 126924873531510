import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { addDays } from "date-fns";
import React from "react";

import { getWeekNumber } from "./getWeekNumber";

interface Props {
  date: Date;
  onChange: (date: Date) => void;
  className?: string;
}

export const WeekSelect: React.FunctionComponent<Props> = ({ date, onChange, className }) => {
  const classes = useStyles();

  const moveDate = (offset: number) => () => {
    onChange(addDays(date, offset));
  };

  const [year, weekNumber] = getWeekNumber(date);

  return (
    <div className={clsx(classes.container, className)}>
      <IconButton onClick={moveDate(-7)}>
        <ChevronLeft className={classes.chevron} />
      </IconButton>
      <div className={classes.selectedDay}>
        <Typography variant="body1" lineHeight={1}>
          {year}
        </Typography>
        <Typography textTransform="uppercase" variant="body1">
          tydzień {weekNumber}
        </Typography>
      </div>
      <IconButton onClick={moveDate(7)}>
        <ChevronRight className={classes.chevron} />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  chevron: {
    height: "2rem",
    width: "2rem",
  },
  container: {
    display: "flex",
    alignItems: "center",
    alignContent: "space-around",
  },
  selectedDay: {
    textAlign: "center",
  },
}));
