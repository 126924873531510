import { Uuid, uuidSerializer } from "@megarax/common";
import {
  CustomerSortField,
  customerSortFields,
  CustomerStatus,
  customerStatusSerializer,
} from "@megarax/crm-contracts";
import { SortFilter } from "@megarax/rest-resource";
import { Serializer, Serializers } from "@megarax/serializers";
import qs from "qs";

export const getInitialValuesFromQs = (queryStrings: string): QsInitialValues => {
  const { page, searchText, regionUuid, hasPricingRules, sortBy, tags, status } = qs.parse(queryStrings);

  const deserializeSortByObject = () => {
    try {
      const sort = sortBy as any;
      if (!customerSortFields.includes(sort?.field)) return undefined;
      if (!["ASC", "DESC", undefined].includes(sort?.order)) return undefined;
      return { field: sort.field, order: sort.order };
    } catch (err) {
      return undefined;
    }
  };

  return {
    page: deserializeWithFallback(Serializers.integer, page, 0),
    searchText: deserializeWithFallback(Serializers.string, searchText, ""),
    hasPricingRules: deserializeWithFallback(
      Serializers.oneOf("true", "false", "both"),
      hasPricingRules,
      "both",
    ) as PricingRulesFilterOption,
    regionUuid: deserializeWithFallback(Serializers.array(uuidSerializer), regionUuid, []),
    sortBy: deserializeSortByObject(),
    tags: deserializeWithFallback(Serializers.array(Serializers.string), tags, []),
    status: deserializeWithFallback(Serializers.array(customerStatusSerializer), status, [
      "vip",
      "verified",
      "unverified",
    ]),
  };
};

const deserializeWithFallback = <T>(serializer: Serializer<T>, raw: any, fallback: T): T => {
  try {
    return serializer.forceDeserialize(raw);
  } catch (err) {
    return fallback;
  }
};

export interface QsInitialValues extends QueryFilters {
  page: number;
}

export type QueryFilters = {
  status: CustomerStatus[];
  searchText: string;
  regionUuid: Uuid[];
  tags: string[];
  hasPricingRules: PricingRulesFilterOption;
  sortBy?: SortFilter<CustomerSortField>;
};

export type PricingRulesFilterOption = "true" | "false" | "both";
