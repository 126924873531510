import { Uuid } from "@megarax/common";
import { Currency, OrderSimpleDto, OrderStatus } from "@megarax/crm-contracts";
import { formatCurrency } from "@megarax/react-utils";
import { Link, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import Decimal from "decimal.js";
import React from "react";

import { NoOrdersScreen } from "./NoOrdersScreen";
import { StatusAvatar } from "./StatusAvatar";

export type Order = {
  uuid: Uuid;
  referenceNumber: string;
  receivedAt: Date;
  shippingStatus: OrderStatus;

  netTotal: Decimal;
  currency: Currency;
};

interface Props {
  orders: OrderSimpleDto[];
  onGoToOrder: (orderUuid: Uuid) => void;
}

export const OrderList: React.FunctionComponent<Props> = ({ orders, onGoToOrder }) => {
  const classes = useStyles();

  if (orders.length === 0) return <NoOrdersScreen />;

  return (
    <List dense>
      {orders.map((order) => (
        <ListItem key={order.uuid}>
          <ListItemAvatar className={classes.avatar}>
            <StatusAvatar status={order.status} />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ fontSize: "1rem", display: "flex" }}
            primary={
              <>
                <Link sx={{ cursor: "pointer" }} onClick={() => onGoToOrder(order.uuid)}>
                  {order.referenceNumber}
                </Link>
                <span className={classes.orderTotal}>{formatCurrency(order.netTotal, order.currency)}</span>
              </>
            }
            secondary={<>{formatDistanceToNowStrict(order.placedAt, { locale: pl, addSuffix: true })}</>}
          />
        </ListItem>
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  statusChip: {
    marginLeft: "1rem",
  },
  avatar: {
    minWidth: "45px",
  },
  orderTotal: {
    marginLeft: "auto",
  },
}));
