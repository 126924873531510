import { BaseResource, IdResourceParam, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { salespersonSerializer } from "./dto";
import {
  designateHomeInputSerializer,
  registerSalespersonInputSerializer,
  setManagerInputSerializer,
  setWorkStartTimeInputSerializer,
  updateRegionInputSerializer,
} from "./inputs";
import list from "./list";

export const salespersonResource = BaseResource(["crm", "salespeople"], {
  list,
  byUserId: IdResourceParam("id", {
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      responseValueSerializer: salespersonSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("SalespersonNotFound"),
    }),
    registerSalesperson: ResourceActionV2({
      name: "register",
      method: "post",
      requestBodySerializer: registerSalespersonInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("SalespersonAlreadyRegistered"),
    }),
    designateHome: ResourceActionV2({
      name: "designateHome",
      method: "put",
      requestBodySerializer: designateHomeInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("SalespersonNotFound"),
    }),
    setWorkStartTime: ResourceActionV2({
      name: "setWorkStartTime",
      method: "put",
      requestBodySerializer: setWorkStartTimeInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("SalespersonNotFound"),
    }),
    updateRegion: ResourceActionV2({
      name: "updateRegion",
      method: "put",
      requestBodySerializer: updateRegionInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("SalespersonNotFound"),
    }),
    setManager: ResourceActionV2({
      name: "setManager",
      method: "put",
      requestBodySerializer: setManagerInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("SalespersonNotFound"),
    }),
  }),
});
