import { useTheme } from "@emotion/react";
import { useMediaQuery } from "react-responsive";

export const useDeviceType = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ query: `(min-width: ${theme.desktopMinWidth})` });
  const isMobile = useMediaQuery({ query: `(max-width: ${theme.tabletMinWidth})` });
  return {
    isDesktop,
    isMobile,
    isTablet: !isDesktop && !isMobile,
    mq: (breakpoint: "tablet" | "desktop") => {
      switch (breakpoint) {
        case "tablet":
          return `@media (min-width: ${theme.tabletMinWidth})`;
        case "desktop":
          return `@media (min-width: ${theme.desktopMinWidth})`;
      }
    },
  };
};
