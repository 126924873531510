import _ from "lodash";

import { Uuid } from "@megaron/uuid";

import { AuthAttribute } from "./authAttribute";
import { MegaraxRole } from "./roles";

export const userTypes = ["megarax", "loyalty"] as const;

export type UserType = (typeof userTypes)[number];

export type AnyUser = {
  id: string;
  userType: UserType;
  uuid: Uuid;
  phoneNumber?: string;
  email?: string;
  roles?: MegaraxRole[];
  groups?: {
    id: string;
    name: string;
  }[];
  authAttributes: AuthAttribute[];
};
