import { discountTableResource, Month, MonthDiscountList } from "@megarax/crm-contracts";
import { useCustomersResource } from "@megarax/crm-webapp";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import {
  BreadcrumbMarkers,
  FeedbackSnackbarContext,
  LoadingBar,
  PageTitleManager,
  useSnackbarErrorHandler,
} from "@megarax/ui-components";
import { errorMessages } from "@megarax/validators";
import _ from "lodash";
import { useContext } from "react";

import { DiscountsView, DiscountTableRow } from "./DiscountsView";
import { InputRow } from "./input";

export const DiscountsViewContainer = () => {
  const discountTableProvider = useResourceProviderV2(discountTableResource);
  const feedbackSnackbar = useContext(FeedbackSnackbarContext);

  const { listCustomers } = useCustomersResource();
  const handleError = useSnackbarErrorHandler(errorMessages);

  const { value: customersResult, loading: customersLoading } = useAsyncLoad(() => {
    return listCustomers({ limit: 9999 })
      .map((result) =>
        _(result.items)
          .keyBy((i) => i.uuid)
          .mapValues((i) => i.name ?? "Nieznany")
          .value(),
      )
      .mapError(handleError);
  }, []);

  const {
    value: months,
    loading,
    reload,
  } = useAsyncLoad(
    () =>
      discountTableProvider
        .retrieve()
        .mapError(handleError)
        .then((r) => r.value),
    [],
  );

  const updateDiscountTable = async (rows: InputRow[]) => {
    discountTableProvider
      .update(undefined, inputRowsToMonthLists(rows))
      .map(async () => {
        await reload();
        feedbackSnackbar.pushMessage({
          severity: "success",
          content: "Pomyślnie zaktualizowano rabaty.",
        });
      })
      .mapError(handleError);
  };

  return (
    <div>
      <PageTitleManager title="Rabaty posprzedażowe" />
      <BreadcrumbMarkers
        breadcrumbs={[
          { title: "Marże", id: "profitMargins" },
          { title: "Rabaty posprzedażowe", id: "postSaleDiscounts" },
        ]}
      />
      <LoadingBar loading={loading || customersLoading} />

      {months ? (
        <DiscountsView onInput={updateDiscountTable} rows={monthListsToTableRows(months, customersResult?.value)} />
      ) : null}
    </div>
  );
};

type CustomerNameMap = {
  [customerUuid: string]: string | undefined;
};

const monthListsToTableRows = (
  monthLists: MonthDiscountList[],
  customerNames: CustomerNameMap | undefined,
): DiscountTableRow[] => {
  const customerUuids = _(monthLists)
    .flatMap((m) => _.keys(m.discountRates))
    .uniq()
    .value();

  return customerUuids.map<DiscountTableRow>((customerUuid) => ({
    customerUuid,
    customerName: customerNames && customerNames[customerUuid],
    discountRates: _(monthLists)
      .keyBy((m) => m.month)
      .mapValues((m) => m.discountRates[customerUuid] ?? null)
      .value(),
  }));
};

const inputRowsToMonthLists = (rows: InputRow[]): MonthDiscountList[] => {
  const months = _(rows)
    .flatMap((r) => _.keys(r.discountRates) as Month[])
    .uniq()
    .sort()
    .value();

  return months.map<MonthDiscountList>((month) => ({
    month,
    discountRates: _(rows)
      .keyBy((row) => row.customerUuid)
      .mapValues((row) => row.discountRates[month] ?? null)
      .value(),
  }));
};
