import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { BreadcrumbMarker } from "@megarax/ui-components";

import { PriceReportFormContainer } from "./PriceReportForm";

interface Props {}

export const MyPriceReportHome: React.FC<Props> = (props) => {
  const route = useRouteMatch();
  return (
    <>
      <BreadcrumbMarker title="Moje ankiety cenowe" to={route.path} id="myPriceReports" />
      <Switch>
        <Route path={route.path}>
          <PriceReportFormContainer />
        </Route>
      </Switch>
    </>
  );
};
