import { DateString, fromDateString, Uuid } from "@megarax/common";
import { WorkDayDto } from "@megarax/crm-contracts";
import { AccessTime, Route } from "@mui/icons-material";
import { Box, Button, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { intervalToDuration } from "date-fns";
import React from "react";

interface Props {
  date: DateString;
  workDay: WorkDayDto | undefined;
  onGoToDay: (date: DateString) => void;
  goToTradeRoute: (uuid: string) => void;

  tradeRoute:
    | {
        name: string | null;
        uuid: Uuid;
      }
    | undefined;
}

export const WeekDaySummary: React.FunctionComponent<Props> = ({
  date,
  workDay,
  onGoToDay,
  goToTradeRoute,
  tradeRoute,
}) => {
  return (
    <Box mx={0.5}>
      <Typography variant="overline" lineHeight={1} fontWeight="bold">
        {fromDateString(date).toLocaleString(window.navigator.language, { weekday: "long" })}
      </Typography>
      <div>
        <Link sx={{ cursor: "pointer" }} onClick={() => onGoToDay(date)}>
          {date}
        </Link>
      </div>
      <Typography display="flex" flexDirection="row" alignItems="center">
        <AccessTime fontSize="inherit" sx={{ mr: 1 }} /> {workDay && formatDuration(workDay.workTimeMinutes)}
      </Typography>
      <Typography display="flex" flexDirection="row" alignItems="center">
        <Route fontSize="inherit" sx={{ mr: 1 }} /> {workDay && formatDistance(workDay.travelKm)}
      </Typography>
      {tradeRoute && (
        <Link sx={{ cursor: "pointer" }} onClick={() => goToTradeRoute(tradeRoute.uuid)}>
          <Typography display="flex" flexDirection="row" alignItems="center" variant="caption" noWrap={true}>
            {tradeRoute.name ?? "Niestandardowa trasa"}
          </Typography>
        </Link>
      )}
    </Box>
  );
};

const formatDuration = (minutes: number) => {
  if (minutes === 0) return "-";
  let text = "";
  const duration = intervalToDuration({ start: 0, end: minutes * 6e4 });

  if (duration.days) return "> 24h";
  if (duration.hours) text += `${duration.hours}h `;
  if (duration.minutes) text += `${duration.minutes}min`;
  return text;
};

const formatDistance = (km: number) => {
  if (km === 0) return "-";
  return `${Math.round(km)} km`;
};
