import Decimal from "decimal.js";

import { DateString, dateStringSerializer, Uuid, uuidSerializer } from "@megarax/common";
import { currencies, Currency } from "../common";
import { MSerializers } from "@megarax/serializers";

export type OrderSimple = {
  uuid: Uuid;
  referenceNumber: string;
  currency: Currency;
  sellToUuid: Uuid | null;
  sellToName: string | null;
  billToUuid: Uuid | null;
  billToName: string | null;
  orderDate: DateString;
  updatedAt: Date;
  createdAt: Date;
  netTotal: Decimal;
};

export const orderSimpleSerializer = MSerializers.object<OrderSimple>({
  uuid: uuidSerializer,
  referenceNumber: MSerializers.string,
  currency: MSerializers.stringOneOf(...currencies),
  netTotal: MSerializers.decimal,
  billToName: MSerializers.string.nullable(),
  billToUuid: uuidSerializer.nullable(),
  sellToName: MSerializers.string.nullable(),
  sellToUuid: uuidSerializer.nullable(),
  orderDate: dateStringSerializer,
  updatedAt: MSerializers.datetime,
  createdAt: MSerializers.datetime,
});
