import { Uuid } from "@megarax/common";
import { EditCustomersInput, orderDraftResource, payerResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import { Dialog, Skeleton } from "@mui/material";
import React from "react";

import { useCrmGroupsResource, useCustomer, useCustomersResource } from "@megaron/megarax-v2-resource-hooks";

import { EditCustomers } from "./EditCustomers";

interface Props {
  draftUuid: Uuid;
  initialSellToUuid: Uuid;
  initialBillToUuid: Uuid;
  initialPayerDepartmentUuid: Uuid;
  onClose: () => void;
  onChange: () => void;
}

export const EditCustomersContainer: React.FC<Props> = (props) => {
  const { getMyGroups } = useCrmGroupsResource();
  const { getCustomersItems } = useCustomersResource();
  const orderDraftProvider = useResourceProviderV2(orderDraftResource);
  const payerProvider = useResourceProviderV2(payerResource);

  const initialSellTo = useCustomer(props.initialSellToUuid);
  const initialBillTo = useCustomer(props.initialBillToUuid);
  const initialPayerDepartment = useCustomer(props.initialPayerDepartmentUuid);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    InvalidDiscountRate: "Nieprawidłowy rabat.",
    PayerDoesNotExist: "Płatnik nie istnieje.",
  });

  const { value: myGroups } = useAsyncLoad(getMyGroups, []);

  const getBuyers = (search: string) => getCustomersItems(search);

  const getPayers = (search: string) => getCustomersItems(search, true);

  const getPayerData = (customerUuid: string) =>
    payerProvider
      .byCustomerUuid(customerUuid)
      .retrieve()
      .mapError((error) => {
        handleError(error);
        return Promise.reject();
      })
      .then((result) => result.value);

  const submitDraft = (input: EditCustomersInput) =>
    orderDraftProvider
      .byUuid(props.draftUuid)
      .editCustomers(undefined, input)
      .mapError(handleError)
      .map(props.onChange)
      .map(props.onClose)
      .then(() => {});

  return (
    <Dialog open={true} onClose={props.onClose}>
      {!initialSellTo || !initialBillTo || !initialPayerDepartment ? (
        <Skeleton />
      ) : (
        <EditCustomers
          myRegions={myGroups?.isOk ? myGroups.value.map((item) => item.regionUuid) : []}
          getBuyers={getBuyers}
          getPayers={getPayers}
          getPayerData={getPayerData}
          submitDraft={submitDraft}
          initialBuyer={initialPayerDepartment}
          initialPayer={initialBillTo}
          initialRecipient={initialSellTo}
        />
      )}
    </Dialog>
  );
};
