import { Button, ButtonProps } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

interface Props extends ButtonProps {
  asyncAction: (() => void) | (() => Promise<unknown>);
}

export const AsyncButton: React.FC<Props> = (props) => {
  const { asyncAction, children, ...muiProps } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mounted = useRef<boolean>(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const onClick = async () => {
    try {
      setIsLoading(true);
      await asyncAction();
      if (mounted.current) setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Button
      {...muiProps}
      onClick={onClick}
      disabled={props.disabled || isLoading}
    >
      {children}
    </Button>
  );
};
