import React from "react";

type Props = {
  children: React.ReactNode;
  itemMinWidth?: string;
  className?: string;
};

export const DescriptionList: React.FunctionComponent<Props> = (props) => {
  return (
    <div
      className={props.className}
      css={{
        display: "grid",
        gridTemplateColumns: `repeat(auto-fit, minmax(${props.itemMinWidth ?? "240px"}, 1fr))`,
        gridGap: "1rem",
      }}
    >
      {props.children}
    </div>
  );
};
