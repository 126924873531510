import { Uuid, uuidSerializer } from "@megarax/common";
import { paginatedListSerializer, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

export interface ChecklistTemplateQueryFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
  tags?: string[];
}

const checklistTemplateQueryFiltersSerializer = MSerializers.object<ChecklistTemplateQueryFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
  tags: MSerializers.array(MSerializers.string).optional(),
});

export interface ChecklistTemplateSimple {
  uuid: Uuid;
  name: string;
  tags: string[];
}

const checklistTemplateSimpleSerializer = MSerializers.object<ChecklistTemplateSimple>({
  uuid: uuidSerializer,
  name: MSerializers.string,
  tags: MSerializers.array(MSerializers.string),
});

export default ResourceActionV2({
  name: "list",
  method: "get",
  path: "list",
  querySerializer: checklistTemplateQueryFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(checklistTemplateSimpleSerializer),
});
