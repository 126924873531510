import React from "react";
import { useQuery } from "react-query";

import { Uuid } from "@megarax/common";
import { chatThreadResource, MentionDto, ThreadDto, ThreadIdentifier } from "@megarax/crm-contracts";
import { CurrentUserContext, useResourceProviderV2 } from "@megarax/react-client";
import { assertOk, Failure, Ok } from "@megaron/result";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

export const useThread = (identifier: ThreadIdentifier) => {
  const errorHandler = useSnackbarErrorHandler({ ...commonErrorsMap });

  const {
    data: threadDto,
    isFetching,
    refetch,
  } = useQuery(["chatThread", identifier], () =>
    threadProvider
      .retrieve()
      .mapError(errorHandler)
      .then((r) => r.assertOk().value),
  );

  const currentUser = React.useContext(CurrentUserContext);

  const threadProvider = useResourceProviderV2(chatThreadResource).byThreadIdentifier(identifier);

  const thisParticipant =
    currentUser && threadDto ? threadDto.participants.find((p) => p.userId === currentUser.id) ?? null : undefined;

  const isSubscribed = thisParticipant === null ? false : thisParticipant?.isSubscribed;

  const isUnacknowledged =
    !!thisParticipant && !!threadDto && thisParticipant.readMessageCount !== threadDto.messages.length;

  return {
    isSubscribed,
    isUnacknowledged,
    threadDto,
    isFetching,
    refetch,
    subscribe: () =>
      threadProvider
        .subscribe()
        .flatMapError((err) => (err === "AlreadySubscribed" ? Ok() : Failure(err)))
        .mapError(errorHandler)
        .then(assertOk)
        .then(() => refetch())
        .then(() => {}),
    unsubscribe: () =>
      threadProvider
        .unsubscribe()
        .flatMapError((err) => (err === "NotSubscribed" ? Ok() : Failure(err)))
        .mapError(errorHandler)
        .then(assertOk)
        .then(() => refetch())
        .then(() => {}),
    send: (uuid: Uuid, content: string, mentions: MentionDto[]) =>
      threadProvider.messages
        .post(undefined, { uuid, content, mentions })
        .mapError(errorHandler)
        .then(assertOk)
        .then(() => refetch())
        .then(() => {}),
    acknowledgeMessages: (readCount: number) =>
      threadProvider
        .acknowledgeMessages(undefined, { readCount })
        .mapError(errorHandler)
        .then(assertOk)
        .then(() => refetch())
        .then(() => {}),
  };
};
