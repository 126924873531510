import { PropsWithChildren } from "react";

import { TableHead, TableHeadProps } from "./TableHead";

type Props<TSortField extends string> = {
  className?: string;
} & PropsWithChildren &
  TableHeadProps<TSortField>;

export const Table = <TSortField extends string>({ children, className, ...tableHeadProps }: Props<TSortField>) => {
  return (
    <table className={className} css={{ width: "100%", borderSpacing: 0 }}>
      <TableHead {...tableHeadProps} />
      {children}
    </table>
  );
};
