import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const accountDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  balance: Serializers.decimal,
  isAffiliate: Serializers.boolean,
  isPro: Serializers.boolean,
  proNumber: Serializers.integer.nullable(),
  lifetimeCodeCount: Serializers.integer,
  affiliateCommissionRate: Serializers.decimal,
  parentAffiliateUuid: uuidSerializer.nullable(),
});

export type AccountDto = SerializerValue<typeof accountDtoSerializer>;
