import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export const string: Serializer<string> & SerializerExtensions<string> = {
  serialize: (str: string) => {
    if (typeof str !== "string") throw new Error("NotAString");
    return str;
  },
  deserialize: (raw: any) => {
    if (typeof raw !== "string") return Failure("NotAString");
    return Ok(raw);
  },
  ...serializerExtensions(),
};
