import { Box, Button, Dialog, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { StaticTimePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";

import { Result } from "@megaron/result";

interface Props {
  setWorkStartTime: (workStartTime: number) => Promise<Result<void, void>>;
  workStartTime: number | null;
}

export const TimeEditRow: React.FC<Props> = ({ setWorkStartTime, workStartTime }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [newTime, setNewTime] = useState<Date | null>(workStartTime ? minutesFromMidnightToDate(workStartTime) : null);

  const onSave = async () => {
    if (!newTime) return;
    setLoading(true);
    await setWorkStartTime(dateToMinutesFromMidnight(newTime)).then(() => setOpen(false));
    setLoading(false);
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box py={1}>
          <StaticTimePicker ampm={false} displayStaticWrapperAs="mobile" value={newTime} onChange={setNewTime} />
          <Box display="flex" justifyContent="flex-end" alignItems="center" pt={2} px={2}>
            <Button onClick={() => setOpen(false)} className={classes.button} disabled={loading} color="inherit">
              Anuluj
            </Button>
            <Button color="primary" disabled={!newTime || loading} onClick={onSave} className={classes.button}>
              Zapisz
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end"></Box>
      </Dialog>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box py={2}>
          <Typography variant="caption" color="textSecondary">
            Rozpoczęcie pracy
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            {workStartTime
              ? minutesFromMidnightToDate(workStartTime).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "Nie sprecyzowano"}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button className={classes.button} color="inherit" onClick={() => setOpen(true)}>
            Edytuj
          </Button>
        </Box>
      </Box>
    </>
  );
};

const minutesFromMidnightToDate = (minutes: number) => {
  const timeHour = Math.floor(minutes / 60);
  const timeMinute = minutes - timeHour * 60;
  const date = new Date();
  date.setHours(timeHour);
  date.setMinutes(timeMinute);
  return date;
};

const dateToMinutesFromMidnight = (date: Date) => date.getHours() * 60 + date.getMinutes();

const useStyles = makeStyles((theme) => {
  return {
    value: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "0.8rem",
    },
  };
});
