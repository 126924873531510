import _ from "lodash";
import React from "react";
import { v4 } from "uuid";

import { Uuid } from "@megarax/common";
import { MentionDto } from "@megarax/crm-contracts";

export const mentionTagRegex = /(\[(@[^\]]+)\]\{(\d+)\})/g;
export const parsedMentionRegex = /(@\w+\s\w+)/g;
export const nonGlobalMentionTagRegex = /(\[(@[^\]]+)\]\{(\d+)\})/;

export const getMessageParts = (message: string, mentions: MentionDto[], myId: number | undefined) => {
  const getPart = (
    message: string,
    parts: React.ReactNode[],
    mentions: MentionDto[],
    cutLength: number,
  ): { message: string; parts: React.ReactNode[]; mentions: MentionDto[] } => {
    const mention = mentions[0];
    const part = <span key={`${cutLength}-end`}>{message}</span>;
    if (!mention) return { message, parts: [...parts, part], mentions: [] };
    const left = (
      <span key={`${cutLength}-${mention.mentionStart}`}>{message.slice(0, mention.mentionStart - cutLength)}</span>
    );
    const mentionPart = (
      <span
        style={{ color: myId === mention.mentionedUser ? "#33a56e" : "#0d633a", fontWeight: "500" }}
        key={`${mention.mentionStart}-${mention.mentionEnd}`}
      >
        {message.slice(mention.mentionStart - cutLength, mention.mentionEnd - cutLength)}
      </span>
    );
    const right = message.slice(mention.mentionEnd - cutLength);
    return getPart(right, [...parts, left, mentionPart], mentions.slice(1), mention.mentionEnd);
  };

  const result = getPart(message, [], mentions, 0);
  return result.parts;
};

export const getParsedMessage = (message: string) =>
  _.replace(message, mentionTagRegex, (match) => {
    const result = Array.from(match.matchAll(mentionTagRegex));
    if (!result[0][2]) return match;
    return result[0][2];
  });

export const getUnparsedMessageCaretPosition = (message: string, caret: number) => {
  const parsed = getParsedMessage(message);
  const parsedCount = parsed.slice(0, caret).match(parsedMentionRegex)?.length ?? 0;
  const additionalCharacters = Array.from(message.matchAll(mentionTagRegex)).reduce((sum, match, idx) => {
    if (idx >= parsedCount) return sum;
    return sum + match[0].length - match[2].length;
  }, 0);
  return caret + additionalCharacters;
};

export const getMentionsList = (content: string) => {
  const getMentions = (content: string, mentions: MentionDto[]): { content: string; mentions: MentionDto[] } => {
    const match = content.match(nonGlobalMentionTagRegex);
    if (!match) return { content, mentions };
    const idxStart = content.indexOf(match[0]);
    const modifiedContent = content.replace(match[0], match[2]);
    return getMentions(modifiedContent, [
      ...mentions,
      {
        uuid: v4() as Uuid,
        mentionedUser: parseInt(match[3]),
        mentionStart: idxStart,
        mentionEnd: match[2].length + idxStart,
      },
    ]);
  };
  return getMentions(content, []);
};
