import { Box, Step, StepLabel, Stepper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React, { useState } from "react";

import {
  AssignToChainInput,
  ChainSimple,
  CustomerSimple,
  DesignateVisitLocationInput,
  RegionSimple,
  RegisterCustomerInput,
} from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { AsyncResult, Result } from "@megaron/result";

import { RegisterCustomerStep } from "./RegisterCustomerStep";
import { SetVisitLocationStep } from "./SetVisitLocationStep";

interface Props {
  navigateToDetails: () => void;
  registerCustomerStep: {
    getRegions: (search: string) => Promise<RegionSimple[]>;
    registerCustomer: (input: RegisterCustomerInput) => Promise<Result<void, void>>;
  };
  setVisitLocationStep: {
    designateVisitLocation: (input: DesignateVisitLocationInput) => Promise<Result<void, void>>;
    getNearbyCustomers: (input: {
      lat: Decimal;
      lng: Decimal;
    }) => AsyncResult<Result<CustomerSimple[], HttpRequestError>>;
  };
  chainStep: {
    getChains: (search: string) => Promise<ChainSimple[]>;
    assignToChain: (input: AssignToChainInput) => Promise<Result<void, void>>;
  };
}

export const AddCustomerDialog: React.FC<Props> = ({
  navigateToDetails,
  setVisitLocationStep,
  registerCustomerStep,
  chainStep,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(0);

  const navigateToNextStep = () => setActiveStep(activeStep + 1);

  const onFinalizeCreation = () => {
    navigateToDetails();
  };

  return (
    <>
      <Box py={2}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          <Step completed={activeStep > 0}>
            <StepLabel />
          </Step>
          <Step completed={activeStep > 1}>
            <StepLabel />
          </Step>
        </Stepper>
      </Box>
      {activeStep === 0 && <RegisterCustomerStep {...registerCustomerStep} onComplete={navigateToNextStep} />}
      {activeStep === 1 && <SetVisitLocationStep {...setVisitLocationStep} onComplete={onFinalizeCreation} />}
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    stepper: {
      "& .MuiStepLabel-iconContainer": {
        paddingLeft: "8px",
      },
    },
  };
});
