import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { Uuid } from "@megarax/common";
import { ResponsiveSelect } from "@megarax/ui-components";

export type CustomerOption = {
  uuid: Uuid;
  name: string | null;
};

export type GetCustomerOptions = (search: string) => Promise<CustomerOption[]>;

export type OnComplete = (formData: { customerUuid: Uuid }) => void;

interface Props {
  onClose: () => void;
  isOpen: boolean;
  getCustomerOptions: GetCustomerOptions;
  onComplete: OnComplete;
}

export const AddUnplannedVisitDialog: React.FunctionComponent<Props> = ({
  onClose,
  isOpen,
  getCustomerOptions,
  onComplete,
}) => {
  const classes = useStyles();

  const [customerUuid, setCustomerUuid] = React.useState<Uuid | null>(null);

  const isFormFilledIn = customerUuid !== null;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Nieplanowana wizyta</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.form}>
          <ResponsiveSelect
            getOptions={getCustomerOptions}
            getKey={(o) => o.uuid}
            getLabel={(o) => o.name ?? "?"}
            getValue={(o) => o.uuid}
            onChange={setCustomerUuid}
            value={customerUuid}
            label="Miejsce wizyty"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!isFormFilledIn}
          onClick={() => onComplete({ customerUuid: customerUuid! })}
        >
          Dalej
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: "200px",
  },

  form: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));
