import React, { useContext, useEffect } from "react";

import { BreadcrumbsContext } from "./BreadcrumbsContext";

type Props = {
  id: string;
  to?: string;
  title: string;
};

export const BreadcrumbMarker: React.FC<Props> = ({ id, title, to }) => {
  const { addBreadcrumb, updateBreadcrumb: updateBreadCrumb, removeBreadcrumb } = useContext(BreadcrumbsContext);

  useEffect(() => {
    addBreadcrumb({ id, title, to });
    return () => removeBreadcrumb(id);
  }, [id]);

  useEffect(() => {
    updateBreadCrumb({ id, title, to });
  }, [to, title]);

  return null;
};
