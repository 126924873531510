import { Serializers } from "@megarax/serializers";

export type Travel = {
  meters: number;
  seconds: number;
};

export const travelSerializer = Serializers.object<Travel>({
  seconds: Serializers.integer,
  meters: Serializers.integer,
});

export const emptyTravel = (): Travel => ({
  meters: 0,
  seconds: 0,
});

export const addTravel = (a: Travel, b: Travel): Travel => ({
  meters: a.meters + b.meters,
  seconds: a.seconds + b.seconds,
});

export const sumTravel = (travels: Travel[]): Travel => travels.reduce(addTravel, emptyTravel());
