import { useTheme } from "@emotion/react";
import React from "react";
import tinycolor from "tinycolor2";

type Props = {
  children: React.ReactNode;
  className?: string;
  variant?: "primary" | "secondary" | "outline";
  color?: string;
  inverted?: boolean;
  onClick?: () => void;
};

export const Chip: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const color = props.color ?? theme.colors.primary;

  const configName = `${props.variant ?? "primary"}${props.inverted ? "-inverted" : ""}`;

  const colorConfig = {
    primary: {
      background: color,
      color: "white",
    },
    "primary-inverted": {
      background: "white",
      color: color,
    },
    secondary: {
      background: tinycolor(color).setAlpha(0.15).toString(),
      color: color,
    },
    "secondary-inverted": {
      background: tinycolor("#ffffff").setAlpha(0.15).toString(),
      color: "white",
    },
    outline: {
      background: "transparent",
      color: color,
      border: `1px solid ${color}`,
    },
  }[configName];

  return (
    <div
      className={props.className}
      onClick={props.onClick}
      css={{
        display: "inline-block",
        padding: "1px 0.5rem",
        fontSize: "0.875rem",
        borderRadius: theme.extraSmallBorderRadius,
        textDecorationColor: colorConfig?.color,
        ...colorConfig,
      }}
    >
      {props.children}
    </div>
  );
};
