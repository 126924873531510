import { BasicAddress, GeoPoint } from "@megarax/common";
import { DesignateHomeInput } from "@megarax/crm-contracts";
import { Dialog, NewRouterDialog, useDialog, useNewRouterDialog } from "@megarax/ui-components";
import { Box, Button, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React, { useState } from "react";

import { Result } from "@megaron/result";

import { formatAddress, LocationPickerV2, MapPinDisplay } from "./address";

interface Props {
  homeLocation: GeoPoint | null;
  address: BasicAddress | null;
  designateHome: (input: DesignateHomeInput) => Promise<Result<void, void>>;
}

export const DesignateHomeRow: React.FC<Props> = ({ homeLocation, address, designateHome }) => {
  const classes = useStyles();
  const [queryLoading, setQueryLoading] = useState<boolean>(false);
  const dialog = useNewRouterDialog("editAddress");

  const currentLatLng = homeLocation
    ? { lat: homeLocation.latitude.toNumber(), lng: homeLocation.longitude.toNumber() }
    : null;
  const currentAddress = address ?? {
    name: "",
    locality: "",
    street: "",
    postalCode: "",
    country: "Polska",
  };

  const [newAddress, setNewAddress] = useState<BasicAddress>(currentAddress);

  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>(currentLatLng);
  const addressFormated = address ? formatAddress(address) : "Brak";

  const closeDialog = () => {
    setNewAddress(currentAddress);
    setLatLng(currentLatLng);
    dialog.close();
  };

  const onSave = async () => {
    if (!latLng) return;
    setQueryLoading(true);
    const result = await designateHome({
      address: newAddress,
      homeLocation: {
        latitude: new Decimal(latLng.lat),
        longitude: new Decimal(latLng.lng),
      },
    });
    setQueryLoading(false);
    if (result.isFailure) return;
    dialog.close();
  };

  return (
    <Box minHeight="64px" mb={2}>
      <NewRouterDialog name={dialog.name} fullWidth maxWidth="sm" onClose={closeDialog}>
        <Box pt={3} pb={1}>
          <LocationPickerV2 address={newAddress} setAddress={setNewAddress} latLng={latLng} setLatLng={setLatLng} />
          <Box display="flex" justifyContent="flex-end" alignItems="center" pt={2} px={3}>
            <Button onClick={() => closeDialog()} className={classes.button} disabled={queryLoading} color="inherit">
              Anuluj
            </Button>
            <Button color="primary" disabled={!latLng || queryLoading} onClick={onSave} className={classes.button}>
              Zapisz
            </Button>
          </Box>
        </Box>
      </NewRouterDialog>
      <Box px={3} display="flex" justifyContent="space-between" alignItems="center">
        <Box py={2}>
          <Typography variant="caption" color="textSecondary">
            Adres zamieszkania
          </Typography>
          <Typography variant="subtitle2" className={classes.value}>
            {addressFormated === "" ? "Brak" : addressFormated}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button onClick={() => dialog.open()} className={classes.button} color="inherit">
            Edytuj
          </Button>
        </Box>
      </Box>
      {currentLatLng && (
        <>
          <Divider />
          <MapPinDisplay latLng={currentLatLng} height="400px" />
          <Divider />
        </>
      )}
    </Box>
  );
};
const useStyles = makeStyles((theme) => {
  return {
    value: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "0.8rem",
    },
  };
});
