import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";

export const dataExportActions = {
  monthlyBlikChecksToSheets: HttpAction({
    path: "/monthlyBlikChecksToSheets",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      sheetId: Serializers.string,
      month: dateStringSerializer.optional(),
    }),
  }),
  proRewardOrdersToSheets: HttpAction({
    path: "/proRewardOrdersToSheets",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      sheetId: Serializers.string,
    }),
  }),
};
