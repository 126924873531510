import { useTheme } from "@emotion/react";
import { useContext, useState } from "react";
import { useParams as useParamsCompat } from "react-router-dom-v5-compat";

import { Interaction, InteractionType } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { Button, CommentInput, DateTimeField, Mention } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { MultiToggleGroup } from "@megaron/dash-toggle-group";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { useClientManager } from "@megaron/react-clients";

import { InteractionTypeSelect } from "./InteractionTypeSelect";

type Props = {
  header: React.ReactNode;
  onClose: () => void;
  interaction?: Interaction;
  action: () => void;
};

export const InteractionPage: React.FC<Props> = (props) => {
  const { customerId }: { customerId?: string } = useParamsCompat();
  if (!customerId) {
    return <div>Error: Customer ID not provided.</div>;
  }

  return <InteractionPageContent customerId={customerId} {...props} />;
};

const InteractionPageContent: React.FC<Props & { customerId: string }> = (props) => {
  const auth = useContext(IamAuthContext);

  const { isMobile } = useDeviceType();
  const theme = useTheme();

  const productIdsQuery = useClientManager("docs").searchProductIds().useQuery({});
  const saveInteraction = useClientManager("crm").saveInteraction().useMutation();

  const [comment, setComment] = useState<string>(props.interaction?.message ?? "");
  const [mentions, setMentions] = useState<Mention[]>(props.interaction?.mentions ?? []);
  const [interactionDate, setInteractionDate] = useState<Date>(props.interaction?.interactionDate ?? new Date());
  const [selectedType, setSelectedType] = useState<InteractionType | null | undefined>(props.interaction?.type ?? null);
  const [isTypeSelected, setIsTypeSelected] = useState<boolean>(false);
  const [samples, setSamples] = useState<string[]>(props.interaction?.samples ?? []);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const handleCommentChange = (comment: string, mentions?: Mention[]) => {
    setComment(comment);
    setMentions(mentions || []);
  };

  const handleTypeSelect = (type: InteractionType) => {
    setSelectedType(type);
    setIsTypeSelected(true);
  };

  const saveInteractionMutation = async () => {
    if (!isTypeSelected) {
      setShowWarning(true);
      return;
    }

    saveInteraction.mutate(
      {
        customerId: props.customerId,
        type: selectedType ?? "other",
        message: comment,
        interactionId: props.interaction?.uuid,
        samples: samples,
        interactionDate,
        mentions,
      },

      {
        onSuccess: (r) => {
          props.action();
          props.onClose();
        },
      },
    );
  };

  const canUserEdit =
    auth.iamUser?.userType === "megarax" &&
    !(props.interaction?.ownerEmail !== auth.iamUser?.email && !!props.interaction?.ownerEmail);

  const samplesOptions = (productIdsQuery.data ?? []).map((sample) => ({
    label: sample,
    value: sample,
  }));

  return (
    <Dialog
      header={props.header}
      onClose={props.onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: "600px" }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <DateTimeField
          value={interactionDate}
          onChange={setInteractionDate}
          showTime={true}
          label="Data"
          isDisabled={!canUserEdit}
          disableFuture
        />

        <CommentInput
          onChange={handleCommentChange}
          enableMentions={true}
          enableTags={false}
          commentValue={comment}
          mentionsValues={mentions}
          userDropdownPlacement="bottom"
        />
        <InteractionTypeSelect
          isDisabled={!canUserEdit}
          onSelect={(type) => handleTypeSelect(type as InteractionType)}
          selectedType={selectedType ?? undefined}
        />
        {showWarning && !isTypeSelected && (
          <div style={{ color: "red", textAlign: "left", fontFamily: theme.primaryFontFamily, fontSize: "14px" }}>
            Wybierz rodzaj interakcji
          </div>
        )}
        <MultiToggleGroup
          label="Próbki i gadzety"
          options={samplesOptions}
          values={samples}
          onChange={setSamples}
          isDisabled={!canUserEdit}
        />
        <Button
          isDisabled={!canUserEdit}
          isLoading={saveInteraction.isLoading}
          css={{
            alignSelf: "flex-end",
            marginBottom: "1rem",
          }}
          onClick={saveInteractionMutation}
        >
          Zapisz
        </Button>
      </div>
    </Dialog>
  );
};
