import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { DateString, fromDateString, toDateString, Uuid } from "@megarax/common";
import { BreadcrumbMarker, PageTitleManager, useDialogWithRoute } from "@megarax/ui-components";
import { isDefined } from "@megarax/utils";

import { getWeekNumber } from "../../../getWeekNumber";
import { useCustomers } from "../../../resourceHooks";
import { WeekSelect } from "../../../WeekSelect";
import { getVisitCalendarEvents } from "./getVisitCalendarEvents";
import { getWeekDates } from "./getWeekDates";
import { getWorkDayCalendarEvents } from "./getWorkDayCalendarEvents";
import { useUserScheduledTradeRoutes } from "./useUserScheduledTradeRoutes";
import { useUserWeekVisits } from "./useUserWeekVisits";
import { useOnVisitClose, useVisitNavigation } from "./useVisitNavigation";
import { useWorkWeekDays } from "./useWorkWeekDays";
import { ViewLayout } from "./ViewLayout";
import { WeekCalendar } from "./WeekCalendar";
import { WeekDaySummary } from "./WeekDaySummary";
import { WorkTimeReportDialogContainer } from "./WorkTimeReportDialog";

interface Props {
  dateString: DateString;
  onChangeDateString: (str: DateString) => void;
  onGoToDay: (str: DateString) => void;
  goToTradeRoute: (uuid: string) => void;
  userId: number;
}

export const WeekViewContainer: React.FunctionComponent<Props> = ({
  dateString,
  userId,
  onChangeDateString,
  goToTradeRoute,
  onGoToDay,
}) => {
  const classes = useStyles();
  const date = fromDateString(dateString);

  const weekDates = getWeekDates(date);

  const { workDays, isLoading: workDaysIsLoading, refetch: reloadWorkDays } = useWorkWeekDays(weekDates, userId);
  const { visits, isLoading: visitsIsLoading, refetch: reloadVisits } = useUserWeekVisits(weekDates, userId);
  const { tradeRoutes, isLoading: tradeRoutesIsLoading } = useUserScheduledTradeRoutes(weekDates, userId);

  const customerMap = useCustomers(visits.map((v) => v.customerUuid));

  const { closeVisit, openVisit } = useVisitNavigation();
  const visitEvents = getVisitCalendarEvents(visits, customerMap, openVisit);

  const workTimeEvents = workDays.filter(isDefined).flatMap(getWorkDayCalendarEvents);

  const workTimeReportDialog = useDialogWithRoute("zestawienie_czasu_pracy", ({ onClose }) => (
    <WorkTimeReportDialogContainer onClose={onClose} userId={userId} />
  ));

  useOnVisitClose(() => {
    reloadWorkDays();
    reloadVisits();
  });

  const [year, weekNumber] = getWeekNumber(date);

  return (
    <>
      <PageTitleManager title={"Kalendarz"} />
      <BreadcrumbMarker title={`${year}-W${weekNumber}`} id="visitWeek" />
      {workTimeReportDialog.element}
      <ViewLayout
        dateSelect={<WeekSelect date={date} onChange={(date) => onChangeDateString(toDateString(date))} />}
        isLoading={workDaysIsLoading || visitsIsLoading || tradeRoutesIsLoading}
        calendar={
          <WeekCalendar
            events={[...visitEvents, ...workTimeEvents]}
            date={date}
            onOpenReport={workTimeReportDialog.open}
            renderDaySummary={(date) => (
              <WeekDaySummary
                onGoToDay={onGoToDay}
                goToTradeRoute={goToTradeRoute}
                workDay={workDays?.find((w) => w?.date === date)}
                date={date}
                tradeRoute={tradeRoutes.find((route) => route?.date === date) ?? undefined}
              />
            )}
          />
        }
        closeVisit={closeVisit}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    overflowX: "hidden",
  },
}));
