import React from "react";
import { useHistory } from "react-router-dom";

import { RegisterSalespersonInput, SalespersonSimple } from "@megarax/crm-contracts";
import { useUsersResource } from "@megarax/iam-webapp";

import { useRegionsResource } from "../../resourceHooks";
import { useSalespeopleResource } from "../useSalespeopleResource";
import { NewSalespersonDialog } from "./NewSalespersonDialog";

interface Props {
  onClose: () => void;
  salespeople: SalespersonSimple[] | undefined;
}

export const NewSalespersonDialogContainer: React.FC<Props> = (props) => {
  const history = useHistory();

  const salespeopleResource = useSalespeopleResource();
  const userResource = useUsersResource();
  const regionsResource = useRegionsResource();

  const onSuccess = (id: number) => {
    history.push("/crm/handlowcy/handlowiec/" + id);
  };

  const registeredIds = props.salespeople?.map((item) => item.userId) ?? [];

  return (
    <NewSalespersonDialog
      close={props.onClose}
      open={true}
      getUsers={(searchText: string) =>
        userResource.getUsers(searchText).then((items) => items.filter((item) => !registeredIds.includes(item.id)))
      }
      getRegions={(searchText: string) => regionsResource.getRegionsItems({ searchText, limit: 50 })}
      registerSalesperson={(id: number) => (input: RegisterSalespersonInput) =>
        salespeopleResource.registerSalesperson({ onSuccess: () => onSuccess(id) })(id, input)}
    />
  );
};
