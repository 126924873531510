import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import React from "react";
import { InputProps } from "../props";

export type PickedSelectProps = Pick<
  SelectProps,
  "variant" | "fullWidth" | "name"
>;

interface MiniSelectInputProps extends InputProps<string>, PickedSelectProps {
  options: string[];
  getTitle?: (op: string) => string;
}

export const MiniSelectInput: React.FC<MiniSelectInputProps> = ({
  getTitle = (op) => op,
  ...props
}) => {
  const {
    helperText,
    error,
    value,
    label,
    onChange,
    options,
    ...muiProps
  } = props;

  return (
    <FormControl {...muiProps}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        error={Boolean(error)}
        onChange={(event) => onChange(event.target.value as string)}
        value={value}
        label={label}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              {getTitle(option)}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{helperText ?? error}</FormHelperText>
    </FormControl>
  );
};
