import React, { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";

export const useDialogRoute = (pathFragment: string) => {
  const safeToGoBack = React.useRef(false);
  const navigate = useNavigate();
  const [initialPathname, setInitialPathname] = useState<string | null>(null);
  const [initialDate, setInitialDate] = useState<string | undefined>(undefined);

  const { pathname } = useLocation();
  const { date } = useParams();


  useEffect(() => {
    if (initialPathname === null || initialDate !== date) {
      setInitialPathname(pathname);
      setInitialDate(date);
    }
  }, [initialPathname, pathname, date, initialDate]);

  const open = () => {
    navigate(`${pathname}/${pathFragment}`);
    safeToGoBack.current = true;
  };

  const close = () => {
    setInitialPathname(null);

    if (safeToGoBack.current) {
      navigate(-1);
      safeToGoBack.current = false;
    } else {
      navigate(pathname, { replace: true });
    }
  };

  const isOpen = matchPath(`${initialPathname}/${pathFragment}`, pathname) !== null;


  return { isOpen, open, close };
};
