import QrScanner from "qr-scanner";
import React from "react";

export type QrScannerError = "NotAllowedError" | "ErrorStartingQrScanner" | string;

export const useQrScanner = (props: { onResult: (result: string) => void }) => {
  const isMounted = React.useRef<boolean>(true);
  const [cameraError, setCameraError] = React.useState<QrScannerError>();
  const qrScanner = React.useRef<QrScanner>();

  React.useEffect(
    () => () => {
      if (qrScanner.current && process.env["NODE_ENV"] === "production") qrScanner.current.destroy();
    },
    [],
  );

  const start = async (el: HTMLVideoElement) => {
    try {
      if (qrScanner.current) return;
      qrScanner.current = new QrScanner(
        el,
        (result) => {
          props.onResult(result.data);
        },
        {
          highlightScanRegion: true,
          highlightCodeOutline: true,
        },
      );
      await qrScanner.current.start();
    } catch (err) {
      setCameraError("NotAllowedError");
      throw err;
    }
  };

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return { start, cameraError };
};
