import React, { useContext } from "react";

import { Currency } from "@megarax/crm-contracts";
import { priceListResource } from "@megarax/crm-contracts";
import { useResourceProvider, useResourceProviderV2 } from "@megarax/react-client";
import { useSnackbarErrorHandler } from "@megarax/ui-components";

import { TradeItemDictionary } from "../../PriceListDetailsContainer";
import { NewProposalDialog } from "./NewProposalDialog";
import { ProposalInput } from "./newProposalForm";

interface Props {
  onDialogClose: () => void;
  reloadProposals: () => void;
  uuid: string;
  currency: Currency;
  tradeItems: TradeItemDictionary | null;
}

const normalizeText = (text: string) =>
  text
    .replace(/\u0142/g, "l")
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

export const NewProposalContainer: React.FC<Props> = ({
  uuid,
  reloadProposals,
  onDialogClose,
  currency,
  tradeItems,
}) => {
  const handleError = useSnackbarErrorHandler({
    DateInThePast: "Data wprowadzenia zmiany musi być w przyszłości.",
    PriceListDoesNotExist: "Nie znaleziono cennika.",
    NegativePrice: "Cena nie może być ujemna.",
  });

  const proposalsProvider = useResourceProviderV2(priceListResource).byUuid(uuid).proposals;

  const getTradeItemOptions = (search: string) => {
    const reg = new RegExp(normalizeText(search), "i");
    const options = tradeItems ? Object.values(tradeItems) : [];
    return Promise.resolve(options.filter((item) => reg.test(normalizeText(item.name))));
  };
  const onCreateProposal = (input: ProposalInput) =>
    proposalsProvider
      .create(undefined, {
        activeFrom: input.activeFrom,
        comment: input.comment,
        changes: [{ gtin: input.gtin, netPrice: input.netPrice }],
      })
      .mapError(handleError)
      .map(() => {
        reloadProposals();
        onDialogClose();
      })
      .then(() => {});

  return (
    <NewProposalDialog
      getTradeItemOptions={getTradeItemOptions}
      onCreateProposal={onCreateProposal}
      onDialogClose={onDialogClose}
      currency={currency}
    />
  );
};
