import { useTheme } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";

type TagDropdownProps = {
  filteredTags: string[];
  handleTagClick: (tag: string) => void;
};
export type Tag = {
  tag: string;
  tagPosition: number;
  tagLength: number;
};

export const TagDropdown: React.FC<TagDropdownProps> = ({ filteredTags, handleTagClick }) => {
  const theme = useTheme();
  const [currentTagIndex, setCurrentTagIndex] = useState<number>(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dropdownRef.current && currentTagIndex !== undefined) {
      const currentElement = document.getElementById(`tag-${currentTagIndex}`);
      if (currentElement) {
        currentElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [currentTagIndex]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (filteredTags.length && currentTagIndex !== undefined) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          if (currentTagIndex < filteredTags.length - 1) {
            setCurrentTagIndex(currentTagIndex + 1);
          }
        }
        if (e.key === "ArrowUp") {
          e.preventDefault();
          if (currentTagIndex > 0) {
            setCurrentTagIndex(currentTagIndex - 1);
          }
        }
      }
    };

    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter" && currentTagIndex !== undefined) {
        e.preventDefault();
        const tag = filteredTags[currentTagIndex];
        if (tag) {
          handleTagClick(tag);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keydown", handleEnter);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keydown", handleEnter);
    };
  }, [filteredTags, currentTagIndex, handleTagClick]);

  useEffect(() => {
    if (filteredTags.length > 0) {
      setCurrentTagIndex(0);
    }
  }, [filteredTags]);

  return (
    <div
      css={{
        position: "absolute",
        bottom: "100%",
        left: 0,
        width: "80%",
        maxWidth: "400px",
        background: theme.colors.background,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: "8px",
        zIndex: 9999,
        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
        overflowY: "auto",
        maxHeight: "300px",
      }}
      ref={dropdownRef}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "8px", padding: "8px" }}>
        {filteredTags.map((tag, index) => {
          const isFocused = index === currentTagIndex;
          return (
            <div
              key={tag}
              id={`tag-${index}`}
              css={{
                padding: "10px 12px",
                borderRadius: theme.smallBorderRadius,
                background: isFocused ? theme.colors.primaryLight : "white",
                border: isFocused ? `1px solid ${theme.colors.primary}` : `1px solid white`,
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => handleTagClick(tag)}
            >
              {`#${tag}`}
            </div>
          );
        })}
      </div>
    </div>
  );
};
