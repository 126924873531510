import { HttpAction } from "@megaron/http-service";
import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

const taxDeclarationDtoSerializer = Serializers.object({
  taxDeclarationUuid: uuidSerializer,
  year: Serializers.integer,
  accountUuid: uuidSerializer,
  taxBase: Serializers.decimal,
  reimbursementPointValue: Serializers.decimal,
  reimbursementIssuedAt: Serializers.datetime.nullable(),
});
export type TaxDeclarationDto = SerializerValue<typeof taxDeclarationDtoSerializer>;

export const taxDeclarationActions = {
  generateTaxDeclarations: HttpAction({
    path: "/taxDeclarations/year/:year/generate",
    method: "post",
    paramsSerializer: Serializers.object({
      year: Serializers.integer,
    }),
  }),
  taxDeclarationsQuery: HttpAction({
    path: "/taxDeclarations/year/:year",
    method: "get",
    paramsSerializer: Serializers.object({
      year: Serializers.integer,
    }),
    valueSerializer: taxDeclarationDtoSerializer.array(),
  }),
  issueTaxReimbursements: HttpAction({
    path: "/taxDeclarations/year/:year/issueReimbursements",
    method: "post",
    paramsSerializer: Serializers.object({
      year: Serializers.integer,
    }),
  }),
};

export const taxReimbursementIssuedTopic = PubsubTopic({
  name: "loyalty-orders-tax-reimbursement-issued",
  serializer: Serializers.object({
    taxDeclarationUuid: uuidSerializer,
    accountUuid: uuidSerializer,
    reimbursementPointValue: Serializers.decimal,
  }),
});

export type TaxReimbursementIssuedMessage = SerializerValue<typeof taxReimbursementIssuedTopic.serializer>;
