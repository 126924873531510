import makeStyles from '@mui/styles/makeStyles';

export const useIntegerInputStyles = makeStyles({
  input: {
    padding: "0 5px",
    "& .MuiInputBase-input": {
      padding: "1rem 0.5rem",
    },
  },
  divider: {
    margin: "0 5px",
    height: "30px",
  },
  icon: {
    margin: "0 0.1rem",
    fontSize: "0.9rem",
  },
});
