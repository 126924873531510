import { BaseResource, ResourceAction } from "@megarax/rest-resource";
import { Serializers } from "@megarax/serializers";

const isUserAllowed = ResourceAction({
  name: "isUserAllowed",
  querySerializer: Serializers.object({
    userId: Serializers.integer,
    action: Serializers.string,
  }),
  responseSerializer: Serializers.boolean,
});

const userDirectories = ResourceAction({
  name: "userDirectories",
  querySerializer: Serializers.object({
    userId: Serializers.integer,
    permission: Serializers.string,
  }),
  responseSerializer: Serializers.array(Serializers.string).nullable(),
});

const allUserPermissions = ResourceAction({
  name: "allUserPermissions",
  querySerializer: Serializers.object({
    userId: Serializers.integer,
  }),
  responseSerializer: Serializers.array(Serializers.string),
});

const isUserAllowedEach = ResourceAction({
  name: "isUserAllowedEach",
  method: "post",
  requestBodySerializer: Serializers.object({
    user: Serializers.object({
      id: Serializers.integer,
    }),
    actions: Serializers.array(Serializers.string),
  }),
  responseSerializer: Serializers.mapObject(Serializers.boolean),
});

export const authorizationResource = BaseResource(["iam", "authorization"], {
  isUserAllowed,
  isUserAllowedEach,
  userDirectories,
  allUserPermissions,
});
