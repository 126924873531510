import { useTheme } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";

import { Avatar } from "@megaron/dash-avatar";
import { Profile } from "@megaron/iam-contracts";

type UserDropdownProps = {
  filteredUsers: Profile[];
  handleUserClick: (user: Profile) => void;
  placement?: "top" | "bottom";
};

export const UserDropdown: React.FC<UserDropdownProps> = ({ filteredUsers, handleUserClick, placement }) => {
  const theme = useTheme();
  const [currentUserIndex, setCurrentUserIndex] = useState<number | undefined>(undefined);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const validUsers = filteredUsers.filter((user) => user.firstName && user.lastName);

    if (validUsers.length > 0) {
      if (currentUserIndex === undefined) {
        setCurrentUserIndex(0);
      } else if (currentUserIndex >= validUsers.length) {
        setCurrentUserIndex(validUsers.length - 1);
      }
    } else {
      setCurrentUserIndex(undefined);
    }
  }, [filteredUsers, currentUserIndex]);

  useEffect(() => {
    if (dropdownRef.current && currentUserIndex !== undefined) {
      const currentElement = document.getElementById(`user-${currentUserIndex}`);
      if (currentElement) {
        currentElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [currentUserIndex]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (filteredUsers.length && currentUserIndex !== undefined) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          if (currentUserIndex < filteredUsers.length - 1) {
            setCurrentUserIndex(currentUserIndex + 1);
          }
        }
        if (e.key === "ArrowUp") {
          e.preventDefault();
          if (currentUserIndex > 0) {
            setCurrentUserIndex(currentUserIndex - 1);
          }
        }
      }
    };

    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter" && currentUserIndex !== undefined) {
        e.preventDefault();
        const user = filteredUsers[currentUserIndex];
        if (user) {
          handleUserClick(user);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keydown", handleEnter);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keydown", handleEnter);
    };
  }, [filteredUsers, currentUserIndex, handleUserClick]);

  return (
    <div
      css={{
        position: "absolute",
        top: placement === "bottom" ? "100%" : "none",
        bottom: !placement || placement === "top" ? "100%" : "none",
        left: 0,
        width: "80%",
        maxWidth: "400px",
        background: theme.colors.background,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: "8px",
        zIndex: 10,
        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
        overflowY: "auto",
        maxHeight: "300px",
      }}
      ref={dropdownRef}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "8px", padding: "8px" }}>
        {filteredUsers
          .filter((user) => user.firstName && user.lastName)
          .map((user, index) => {
            const isFocused = index === currentUserIndex;
            return (
              <div
                key={user.email}
                id={`user-${index}`}
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  padding: "10px 12px",
                  borderRadius: theme.smallBorderRadius,
                  background: isFocused ? theme.colors.primaryLight : "white",
                  border: isFocused ? `1px solid ${theme.colors.primary}` : `1px solid white`,
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleUserClick(user)}
              >
                <Avatar src={user.profilePictureUrl} />
                <div css={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <span>{user.firstName}</span>
                  <span>{user.lastName}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
