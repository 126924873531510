import { Currency, OrderLineDto, PackSizeDefaultsDto } from "@megarax/crm-contracts";
import { Table } from "@mui/material";
import React from "react";

import { LinesTableBody } from "./LinesTableBody";
import { LinesTableHead } from "./LinesTableHead";

export type PackSizeDictionary = _.Dictionary<PackSizeDefaultsDto | null>;

interface Props {
  lines: OrderLineDto[];
  currency: Currency;
}

export const LinesTable: React.FC<Props> = ({ lines, currency }) => {
  return (
    <Table size="small">
      <LinesTableHead />
      <LinesTableBody lines={lines} currency={currency} />
    </Table>
  );
};
