import { Box, Container, Divider, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  alerts: React.ReactNode;
  header: React.ReactNode;
  timeSection: React.ReactNode | undefined;
  checklistSection: React.ReactNode;
  customerHeader: React.ReactNode;
  chat: React.ReactNode | undefined;
  contacts: React.ReactNode | undefined;
  priceReports: React.ReactNode | undefined;
  recentOrders: React.ReactNode | undefined;
}

export const ViewLayout: React.FunctionComponent<Props> = ({
  alerts,
  header,
  timeSection,
  checklistSection,
  customerHeader,
  chat,
  contacts,
  priceReports,
  recentOrders,
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" sx={{ minWidth: "320px" }} disableGutters>
      <Box mt={2} mx={2}>
        {alerts}
        <Box mb={4}>{header}</Box>
        <Box mb={4}>{timeSection ?? <Skeleton variant="rectangular" height={72} />}</Box>
        <Box mb={8}>{checklistSection}</Box>
      </Box>
      <Box>
        <Box mb={4} mx={2}>
          {customerHeader}
        </Box>
        <Divider />
        <Box mb={1}>{chat}</Box>
        <Divider />
        <Box my={1}>{contacts}</Box>
        <Divider />
        <Box>{priceReports}</Box>
        <Divider />
        <Box mt={2}>{recentOrders}</Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({}));
