import { useTheme } from "@emotion/react";
import React, { useCallback, useState } from "react";
import { MdOutlinePhone } from "react-icons/md";
import { useQueryClient } from "react-query";

import { CustomerDetails } from "@megaron/crm-contracts";
import { Button, TextField } from "@megaron/dash-form";
import { SectionHeader } from "@megaron/dash-page";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";
import { Email } from "@megaron/serializers";

type Props = {
  customer: CustomerDetails;
  queryKey: string | string[];
};

export const CustomerPersonalDetails: React.FC<Props> = ({ customer, queryKey }) => {
  const theme = useTheme();

  const [isEdited, setIsEdited] = useState(false);

  const queryClient = useQueryClient();
  const updateCustomer = useClientManager("crm").saveCustomerDetails().useMutation();

  const toast = useToast();

  const [firstName, setFirstName] = useState(customer.firstName);
  const [lastName, setLastName] = useState(customer.lastName);
  const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumber.toString());
  const [email, setEmail] = useState(customer.email?.toString());

  const updateCustomerMutation = useCallback(async () => {
    updateCustomer.mutate(
      {
        customerId: customer.uuid,
        firstName: firstName,
        lastName: lastName,
        email: (email as Email) || null,
        phoneNumber: phoneNumber,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
          toast.info("Dane klienta zostały zaktualizowane");
        },
        onError: (error) => {
          if (error === "CustomerRegistered") {
            toast.error("Klient przeszedł rejestrację. Edycja danych nie jest możliwa.");
            return;
          }

          if (error === "CustomerDeleted") {
            toast.error("Klient usunięty. Edycja danych nie jest możliwa.");
            return;
          }

          if (error === "UserInLoyalty") {
            toast.error(
              "Klient jest zarejestrowany w programie lojalnościowym. Edycja numeru telefonu nie jest możliwa.",
            );
            return;
          }

          toast.error("Nie udało się zaktualizować danych klienta");
        },
      },
    );
  }, [updateCustomer, customer.uuid, firstName, lastName, email, phoneNumber, queryClient, queryKey, toast]);

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SectionHeader isHr>Dane osobowe</SectionHeader>

      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        <div css={{ display: "flex", alignItems: "center", gap: "0.625rem" }}>
          <TextField
            label="Imię"
            value={firstName}
            onChange={(v) => {
              setFirstName(v);
              setIsEdited(true);
            }}
          />
          <TextField
            label="Nazwisko"
            value={lastName}
            onChange={(v) => {
              setLastName(v);
              setIsEdited(true);
            }}
          />
        </div>
        <div css={{ position: "relative" }}>
          <TextField
            label="Nr telefonu"
            value={phoneNumber}
            onChange={(v) => {
              setPhoneNumber(v);
              setIsEdited(true);
            }}
          />
          <a
            href={`tel:${phoneNumber}`}
            css={{
              position: "absolute",
              bottom: "0.5rem",
              right: "0.375rem",
              padding: "0.125rem",
              background: "none",
              textDecoration: "none",
              color: theme.colors.primary,
              height: "24px",
            }}
          >
            <MdOutlinePhone size={20} />
          </a>
        </div>
        <TextField
          label="Email"
          value={email ?? ""}
          onChange={(v) => {
            setEmail(v);
            setIsEdited(true);
          }}
        />
        {isEdited && (
          <Button
            variant="outline"
            css={{ alignSelf: "flex-end", marginTop: "0.375rem" }}
            onClick={() => {
              updateCustomerMutation();
              setIsEdited(false);
            }}
          >
            Zapisz
          </Button>
        )}
      </div>
    </div>
  );
};

const formatPhoneNumber = (phoneNumber: string) => {
  const formattedNumber = phoneNumber.replace(/(\+\d{2})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4");
  return formattedNumber;
};
