import { Uuid, validateUuid } from "@megarax/common";
import { useCurrentUser } from "@megarax/react-client";
import { Serializers } from "@megarax/serializers";
import React from "react";
import { useMatch, useNavigate } from "react-router-dom-v5-compat";

import { EditBreakDialog } from "./EditBreakDialog";
import { EditEndDialog } from "./EditEndDialog";
import { EditStartDialog } from "./EditStartDialog";
import { useDialogRoute } from "./useDialogRoute";
import { useWorkTime } from "./useWorkTime";
import { WorkTimeCard } from "./WorkTimeCard";
import { WorkTimeCardSkeleton } from "./WorkTimeCardSkeleton";

interface Props {
  userId: number;
  date: Date;
}

export const WorkTimeCardContainer: React.FunctionComponent<Props> = ({ userId, date }) => {
  const editStartDialog = useDialogRoute("poczatek");
  const editEndDialog = useDialogRoute("koniec");
  const navigate = useNavigate();

  const { myWorkDay, isLoading, changeBreak, changeEnd, changeStart, deleteBreak } = useWorkTime(userId, date);

  const openBreakDialog = (uuid: Uuid) =>
    navigate(`/crm/pos/handlowcy/${userId}/wizyty/dzien/${Serializers.dateOnly.serialize(date)}/przerwa/${uuid}`);
  const breakDialogMatchPath = useMatch("/crm/pos/handlowcy/:userId/wizyty/dzien/:date/przerwa/:breakUuid");

  const currentUser = useCurrentUser();
  const isMine = currentUser?.id === userId;

  return myWorkDay?.value !== undefined ? (
    <>
      <WorkTimeCard
        myWorkDay={myWorkDay.value}
        isLoading={isLoading}
        onEditEndDialogOpen={editEndDialog.open}
        onEditStartDialogOpen={editStartDialog.open}
        openBreakDialog={openBreakDialog}
        isDisabled={!isMine}
      />

      {breakDialogMatchPath && (
        <EditBreakDialog
          onDelete={deleteBreak}
          onClose={() => navigate(-1)}
          allBreaks={myWorkDay.value.breaks}
          onSave={changeBreak}
          breakUuid={validateUuid(breakDialogMatchPath.params.breakUuid || "").assertOk().value}
        />
      )}

      {editStartDialog.isOpen && (
        <EditStartDialog
          onSave={changeStart}
          isOpen={true}
          onClose={editStartDialog.close}
          startOdometer={myWorkDay.value.startOdometer}
          startTime={myWorkDay.value.startTime}
        />
      )}

      {editEndDialog.isOpen && (
        <EditEndDialog
          onSave={changeEnd}
          isOpen={true}
          onClose={editEndDialog.close}
          endOdometer={myWorkDay.value.endOdometer}
          endTime={myWorkDay.value.endTime}
        />
      )}
    </>
  ) : (
    <WorkTimeCardSkeleton />
  );
};
