import { BaseResource, ResourceActionV2, UuidResourceParam } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";
import Decimal from "decimal.js";

import { Month, monthSerializer } from "./month";

export type MonthDiscountList = {
  month: Month;

  discountRates: {
    [payerUuid: string]: Decimal | null;
  };
};

export const discountRatesSerializer = MSerializers.mapObject(MSerializers.decimal.nullable(), MSerializers.uuid());

export const monthDiscountListSerializer = MSerializers.object<MonthDiscountList>({
  discountRates: discountRatesSerializer,
  month: monthSerializer,
});

export const discountTableResource = BaseResource(["profitMargin", "discountTable"], {
  retrieve: ResourceActionV2({
    name: "retrieve",
    method: "get",
    path: "",
    responseValueSerializer: MSerializers.array(monthDiscountListSerializer),
  }),
  byPayerUuid: UuidResourceParam("payerUuid", {
    currentDiscountRate: ResourceActionV2({
      name: "currentDiscountRate",
      method: "get",
      path: "currentDiscountRate",
      responseValueSerializer: MSerializers.decimal.nullable(),
    }),
  }),
  update: ResourceActionV2({
    name: "update",
    method: "put",
    path: "",
    requestBodySerializer: MSerializers.array(monthDiscountListSerializer),
  }),
});
