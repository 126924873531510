import React from "react";

import { useDeviceType } from "@megaron/dash-mq";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const FilterList: React.FunctionComponent<Props> = (props) => {
  const { isDesktop } = useDeviceType();

  return (
    <div
      css={{
        width: isDesktop ? "calc(100% + 4rem)" : "100vw",
        overflow: "auto",
        margin: isDesktop ? "1rem -2rem 0" : "1rem -1rem 0",
        paddingBottom: "1rem",
      }}
      className={props.className}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          width: "fit-content",
          padding: isDesktop ? "0 2rem" : "0 1rem",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
