import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { useValidatorForm } from "@megarax/react-client";
import {
  AsyncButton,
  BreadcrumbMarker,
  MiniSelectInput,
  PageTitleManager,
  TextInput,
} from "@megarax/ui-components";

import { formValidator, getErrorMessage, INITIAL_VALUE, PriceListInput } from "./newPriceListForm";

interface Props {
  closeDialog: () => void;
  createPriceList: (input: PriceListInput) => Promise<void>;
}

export const NewPriceListDialog: React.FC<Props> = ({ createPriceList, closeDialog }) => {
  const classes = useStyles();
  const { formValue, formErrors, onFormInput, onSubmit } = useValidatorForm(
    INITIAL_VALUE,
    formValidator,
    createPriceList,
  );

  return (
    <Dialog open={true} onClose={closeDialog}>
      <PageTitleManager title={"Nowy cennik"} />
      <BreadcrumbMarker title="Nowy" id="newPriceList" />
      <DialogTitle>Nowy cennik</DialogTitle>
      <DialogContent className={classes.container}>
        <TextInput
          label="Nazwa"
          variant="outlined"
          value={formValue.name}
          onChange={onFormInput("name")}
          error={getErrorMessage(formErrors?.name)}
        />
        <div>
          <MiniSelectInput
            fullWidth
            label={"Waluta"}
            variant="outlined"
            value={formValue.currency}
            onChange={onFormInput("currency")}
            options={["PLN", "EUR", "USD", "GBP"]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Anuluj</Button>
        <AsyncButton
          className={classes.button}
          variant="text"
          color="primary"
          asyncAction={onSubmit}
        >
          Utwórz
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    button: {
      marginTop: theme.spacing(1),
      display: "block",
      marginLeft: "auto",
    },
    container: {
      "& > div": {
        margin: theme.spacing(1, 0),
      },
    },
  };
});
