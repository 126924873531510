import { deliveryAddressSerializer, gtinSerializer } from "../common";
import {
  BaseResource,
  NestedResourceNode,
  paginatedListSerializer,
  ResourceAction,
  ResourceActionV2,
  sortFilterSerializer,
  uuidObjectReferenceSerializer,
  UuidResourceParam,
} from "@megarax/rest-resource";
import { Serializers } from "@megarax/serializers";

import {
  AddLineInput,
  createCopyInputSerializer,
  editCustomersInputSerializer,
  MyDraftListFilters,
  MyDraftSimple,
  MyDraftSimpleCustomer,
  myOrderSortFields,
  PlaceOrderInput,
  StartDraftInput,
  UpdateLineInput,
} from "./featureInterfaces";
import { OrderDraftDto, orderDraftDtoSerializer } from "./orderDraftDto";

const listAction = ResourceAction({
  name: "list",
  method: "get",
  path: "",
  querySerializer: Serializers.object<MyDraftListFilters>({
    limit: Serializers.integer.optional(),
    offset: Serializers.integer.optional(),
    searchText: Serializers.string.optional(),
    sortBy: sortFilterSerializer(...myOrderSortFields).optional(),
  }),
  responseSerializer: Serializers.result(
    paginatedListSerializer(
      Serializers.object<MyDraftSimple>({
        buyer: Serializers.object<MyDraftSimpleCustomer>({
          name: Serializers.string.optional(),
          uuid: Serializers.uuid(),
        }),
        payer: Serializers.object<MyDraftSimpleCustomer>({
          name: Serializers.string.optional(),
          uuid: Serializers.uuid(),
        }),
        draftStartedAt: Serializers.datetime,
        isPlaced: Serializers.boolean,
        placedAt: Serializers.datetime.nullable(),
        uuid: Serializers.uuid(),
      }),
    ),
  ),
});

const linesNestedResource = NestedResourceNode("lines", {
  byUuid: UuidResourceParam("lineUuid", {
    remove: ResourceActionV2({
      name: "removeLine",
      method: "delete",
      path: "",
      responseErrorSerializer: Serializers.stringOneOf(
        "OrderNotFound",
        "DraftNotOpen",
        "UserNotOrderOwner",
        "LineDoesNotExist",
      ),
    }),
    update: ResourceActionV2({
      name: "updateLine",
      method: "put",
      path: "",
      requestBodySerializer: Serializers.object<UpdateLineInput>({
        promoPrice: Serializers.decimal.nullable(),
        quantity: Serializers.integer,
      }),
      responseErrorSerializer: Serializers.stringOneOf(
        "OrderNotFound",
        "DraftNotOpen",
        "UserNotOrderOwner",
        "LineDoesNotExist",
        "InvalidLineQuantity",
      ),
    }),
  }),
  add: ResourceActionV2({
    name: "addLine",
    method: "post",
    path: "",
    requestBodySerializer: Serializers.object<AddLineInput>({
      lineUuid: Serializers.uuid(),
      item: Serializers.object({ gtin: gtinSerializer }),
      promoPrice: Serializers.decimal.nullable(),
      quantity: Serializers.integer,
    }),
    responseErrorSerializer: Serializers.stringOneOf(
      "OrderNotFound",
      "DraftNotOpen",
      "UserNotOrderOwner",
      "LineAlreadyExists",
      "InvalidLineQuantity",
    ),
  }),
});

export const orderDraftResource = BaseResource(["sales", "orderDrafts"], {
  list: listAction,
  byUuid: UuidResourceParam("uuid", {
    retrieve: ResourceActionV2({
      path: "",
      name: "retrieve",
      responseValueSerializer: orderDraftDtoSerializer,
      responseErrorSerializer: Serializers.stringOneOf(
        "DraftNotFound",
        "PriceListNotFound",
        "DraftDiscarded",
        "DraftFinalized",
      ),
    }),
    place: ResourceActionV2({
      name: "place",
      method: "post",
      requestBodySerializer: Serializers.object<PlaceOrderInput>({
        deliveryAddress: deliveryAddressSerializer,
        comment: Serializers.string,
        deliveryDeadline: Serializers.dateOnly,
        externalOrderNumber: Serializers.string,
      }),
      responseErrorSerializer: Serializers.stringOneOf(
        "PriceListNotFound",
        "OrderNotFound",
        "DraftNotOpen",
        "ItemNotFound",
        "UserNotOrderOwner",
        "UnknownGtin",
      ),
    }),
    discard: ResourceActionV2({
      name: "discard",
      method: "delete",
      path: "",
      responseErrorSerializer: Serializers.stringOneOf("OrderNotFound", "DraftNotOpen", "UserNotOrderOwner"),
    }),
    editCustomers: ResourceActionV2({
      name: "editCustomers",
      method: "put",
      path: "",
      requestBodySerializer: editCustomersInputSerializer,
      responseErrorSerializer: Serializers.stringOneOf("OrderNotFound", "DraftNotOpen", "UserNotOrderOwner"),
    }),
    lines: linesNestedResource,
  }),
  startDraft: ResourceActionV2({
    path: "",
    name: "startDraft",
    method: "post",
    requestBodySerializer: Serializers.object<StartDraftInput>({
      uuid: Serializers.uuid(),
      payerDiscountRate: Serializers.decimal,
      buyer: uuidObjectReferenceSerializer,
      payer: uuidObjectReferenceSerializer,
      recipient: uuidObjectReferenceSerializer,
      priceList: uuidObjectReferenceSerializer,
    }),
    responseErrorSerializer: Serializers.stringOneOf("InvalidDiscountRate"),
  }),
  createCopy: ResourceActionV2({
    name: "createCopy",
    method: "post",
    path: "createCopy",
    responseErrorSerializer: Serializers.stringOneOf("DraftNotFound"),
    requestBodySerializer: createCopyInputSerializer,
  }),
});
