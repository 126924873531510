import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";

interface Props {
  action: () => void;
  title?: string;
  content?: string;
  color?: "primary" | "secondary";
  children?: React.ReactNode;
}

export const AreUSureWrapper: React.FC<Props> = ({ action, title, content, children, color }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onAccept = () => {
    action();
    setIsOpen(false);
  };

  const defaultTitle = "Czy jesteś pewien?";

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{title ?? defaultTitle}</DialogTitle>
        {content && <DialogContent>{content}</DialogContent>}
        <DialogActions>
          <Box mr={"auto"}>
            <Button onClick={() => setIsOpen(false)}>Anuluj</Button>
          </Box>
          <Button onClick={onAccept} color={color ?? "secondary"}>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      <Box onClick={() => setIsOpen(true)}>{children}</Box>
    </>
  );
};
