import { Box, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

import { Currency } from "@megarax/crm-contracts";
import { OrderLineDto } from "@megarax/crm-contracts";
import { formatCurrency } from "@megarax/react-utils";

interface BodyProps {
  lines: OrderLineDto[];
  currency: Currency;
}

export const LinesTableBody: React.FC<BodyProps> = ({ lines, currency }) => {
  return (
    <TableBody>
      {lines.map((line) => (
        <TableRow key={line.uuid} hover>
          <TableCell>
            <Box display="flex" flexWrap="nowrap">
              <Typography variant="inherit">
                {line.tradeItemName ?? line.tradeItemGtin ?? line.tradeItemUuid}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>{line.quantity.toFixed(2)}</TableCell>
          <TableCell align="right">{formatCurrency(line.price.toNumber(), currency)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
