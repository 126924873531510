type Props = {
  children: React.ReactNode;
  minWidth?: string;
};

export const TileGrid: React.FC<Props> = (props) => {
  const minWidth = props.minWidth ?? "310px";

  return (
    <div css={{ display: "grid", gap: "0.5rem", gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}, 1fr))` }}>
      {props.children}
    </div>
  );
};
