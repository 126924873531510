import { Uuid } from "@megarax/common";
import { tagResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { BreadcrumbMarker, LoadingBar } from "@megarax/ui-components";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { v4 } from "uuid";

import { ChecklistTemplateCreatorDialog } from "./ChecklistTemplateCreatorDialog";
import { getInitialValuesFromQs } from "./checklistTemplatesFilters";
import { ChecklistTemplatesHome } from "./ChecklistTemplatesHome";
import { EditChecklistTemplateDialogContainer } from "./EditChecklistTemplateDialogContainer";
import { useChecklistResource } from "./useChecklistResource";

interface Props {}

export const ChecklistTemplatesHomeContainer: React.FC<Props> = (props) => {
  const tagsProvider = useResourceProviderV2(tagResource);
  const checklistsProvider = useChecklistResource();
  const location = useLocation();
  const navigate = useNavigate();

  const openEditChecklistDialog = (uuid: Uuid) => navigate("/crm/pos/szablony/edycja/" + uuid);
  const openNewChecklistDialog = () => navigate("/crm/pos/szablony/nowy_szablon");

  const isEditChecklistDialogOpen = location.pathname.includes("szablony/edycja");
  const isNewChecklistDialogOpen = location.pathname.endsWith("szablony/nowy_szablon");

  const initialValues = getInitialValuesFromQs(location.search.substring(1));

  const [search, setSearch] = useState<string>(initialValues.searchText);
  const [selectedTags, setSelectedTags] = useState<string[]>(initialValues.tags);

  const tagsQuery = useQuery("tags", () => tagsProvider.list({ limit: 1000 }).map((result) => result.items));

  const checklistsQuery = useQuery(
    ["checklist-templates", selectedTags, search],
    () =>
      checklistsProvider.getChecklists({
        limit: 1000,
        tags: selectedTags ?? undefined,
        searchText: search,
      }),
    { keepPreviousData: true },
  );

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: qs.stringify({
          searchText: search,
          tags: selectedTags,
        }),
      },
      { replace: true },
    );
  }, [search, selectedTags]);

  return (
    <>
      <BreadcrumbMarker title="Szablony" id="checklistTemplates" />

      {isNewChecklistDialogOpen && (
        <ChecklistTemplateCreatorDialog
          saveChecklist={checklistsProvider.saveChecklist({ onSuccess: checklistsQuery.refetch })}
          open={true}
          close={() => navigate(-1)}
          initialTemplate={{
            uuid: v4() as Uuid,
            tasks: [{ uuid: v4() as Uuid, label: "Nowe zadanie", priority: 0 }],
            tags: [],
            name: "Nowy szablon",
          }}
        />
      )}
      {isEditChecklistDialogOpen && (
        <EditChecklistTemplateDialogContainer
          saveChecklist={checklistsProvider.saveChecklist({ onSuccess: checklistsQuery.refetch })}
          close={() => navigate(-1)}
        />
      )}
      <LoadingBar loading={tagsQuery.isFetching || checklistsQuery.isFetching} />
      {tagsQuery.data?.isOk && checklistsQuery.data?.isOk && (
        <ChecklistTemplatesHome
          filters={{
            search,
            setSearch,
            selectedTags,
            setSelectedTags,
          }}
          tags={tagsQuery.data.value}
          checklists={checklistsQuery.data.value.items}
          openNewChecklistDialog={openNewChecklistDialog}
          openEditChecklistDialog={openEditChecklistDialog}
        />
      )}
    </>
  );
};
