import { useQueries, useQuery } from "react-query";

import { toDateString } from "@megarax/common";
import { workDayResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";

export const useWorkWeekDays = (dates: Date[], userId: number) => {
  const workDayProvider = useResourceProviderV2(workDayResource);

  const queries = useQueries(
    dates.map((date) => ({
      queryKey: ["userWorkDay", date, userId],
      queryFn: () =>
        workDayProvider
          .byUserId(userId!)
          .byDate(toDateString(date))
          .retrieve()
          .then((r) => r.assertOk().value),
    })),
  );

  return {
    workDays: queries.map((q) => q.data),
    isLoading: queries.some((q) => q.isFetching),
    refetch: () => queries.forEach((q) => q.refetch()),
  };
};
