import { MobileTimePicker } from "@mui/x-date-pickers";
import "@mui/lab";
import { InputBase } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

interface Props {
  isClearable?: boolean;
  isDisabled: boolean;
  value: Date | null;
  onChange: (date: Date | null) => void;
  rightAlign?: boolean;
}

const isTimeEqual = (a: Date | null, b: Date | null) => {
  if (a === null) return b === null;
  if (b === null) return false;

  return a.getHours() === b.getHours() && a.getMinutes() === b.getMinutes();
};

export const VisitTimePicker: React.FunctionComponent<Props> = ({
  isClearable,
  isDisabled,
  value,
  onChange,
  rightAlign,
}) => {
  const classes = useStyles();

  const onAccept = (newDate: Date | null) => {
    if (!isTimeEqual(newDate, value)) onChange(newDate);
  };

  return <MobileTimePicker ampm={false} disabled={isDisabled} onChange={() => {}} onAccept={onAccept} value={value} />;
};

const useStyles = makeStyles((theme) => ({
  rightAlign: {
    marginLeft: "auto",
    marginRight: "0",
    "& > input": {
      textAlign: "right",
    },
  },

  timePickerField: {
    fontSize: "2rem",
    display: "block",
    maxWidth: "100px",
    "& > input": {
      cursor: "pointer",
      fontWeight: "bold",
    },
  },
}));
