import Decimal from "decimal.js";

import { ValidationError } from "../errors";
import { serializerExtensions, SerializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export const decimal: UnsafeSerializer<Decimal> & SerializerExtensions<Decimal> = {
  serialize: (value) => value.toString(),
  forceDeserialize: (raw) => {
    try {
      return new Decimal(raw);
    } catch (err) {
      throw new ValidationError(`Could not convert the input value to a Decimal (${raw})`);
    }
  },
  ...serializerExtensions(),
};
