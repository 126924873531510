import { Uuid } from "@megarax/common";
import { SaveTemplateInput } from "@megarax/crm-contracts";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom-v5-compat";

import { Result } from "@megaron/result";

import { ChecklistTemplateCreatorDialog } from "./ChecklistTemplateCreatorDialog";
import { useChecklistResource } from "./useChecklistResource";

interface Props {
  saveChecklist: (uuid: Uuid, input: SaveTemplateInput) => Promise<Result<void, void>>;
  close: () => void;
}

export const EditChecklistTemplateDialogContainer: React.FC<Props> = ({ saveChecklist, close }) => {
  const location = useLocation();
  const checklistsProvider = useChecklistResource();

  const pathnameElements = location.pathname.split("/");
  const uuid = pathnameElements[pathnameElements.length - 1];

  const checklist = useQuery(["checklist-details", uuid], () => checklistsProvider.retrieveChecklist(uuid as Uuid));

  useEffect(() => {
    const refetch = () => {
      checklist.refetch();
    };
    return () => refetch();
  }, []);

  return (
    <>
      {checklist.data?.isOk && (
        <ChecklistTemplateCreatorDialog
          saveChecklist={saveChecklist}
          open={true}
          close={close}
          disableEdit
          initialTemplate={{
            ...checklist.data.value,
            tasks: checklist.data.value.tasks.sort((a, b) => a.priority - b.priority),
          }}
        />
      )}
    </>
  );
};
