import { AmqpTopic } from "@megarax/amqp";
import { DateString, dateStringSerializer, Uuid, uuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

export type WorkDayEventMessage = {
  date: DateString;
  userId: number;
  time: Date;

  type: "visitChanged";
  visitUuid: Uuid;
};

export const workDayEventMessageSerializer = MSerializers.object<WorkDayEventMessage>({
  date: dateStringSerializer,
  userId: MSerializers.integer,
  time: MSerializers.datetime,
  type: MSerializers.stringOneOf("visitChanged"),
  visitUuid: uuidSerializer,
});

export const workDayEventAmqpTopic: AmqpTopic<WorkDayEventMessage> = {
  key: "crm.work-day.event",
  serializer: workDayEventMessageSerializer,
};
