export const abbreviationToCountryMap = {
  DE: "Niemcy",
  GB: "Wielka Brytania",
  CZ: "Czechy",
  SE: "Szwecja",
  LT: "Litwa",
  IE: "Irlandia",
  LV: "Litwa",
  RU: "Rosja",
  BG: "Bułgaria",
  ES: "Hiszpania",
  NL: "Holandia",
} as const;

export const countryToAbbreviationMap = Object.fromEntries(
  Object.entries(abbreviationToCountryMap).map(([key, value]) => [value, key]),
);
