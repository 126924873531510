import { AutocompleteTextField, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SectionHeader } from "@megaron/dash-page";
import { useServiceClient } from "@megaron/react-clients";

type Props = {
  values: {
    index: string;
    group: string;
    id: string;
    tradeItemName: string;
  };
  handlers: {
    onIndexChange: (index: string) => void;
    onGroupChange: (group: string) => void;
    onIdChange: (id: string) => void;
    onTradeItemNameChange: (tradeItemName: string) => void;
  };
};

export const NewTradeItemDetails: React.FC<Props> = ({ values, handlers }) => {
  const { isMobile } = useDeviceType();

  const docs = useServiceClient("docs");

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      <SectionHeader isHr>Dane podstawowe</SectionHeader>
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "0.625rem", width: "100%" }}>
          <TextField
            label="Jednostka handlowa"
            value={values.tradeItemName}
            onChange={handlers.onTradeItemNameChange}
            css={{ width: "100%" }}
          />
          <TextField label="Id" value={values.id} onChange={handlers.onIdChange} css={{ width: "100%" }} />
        </div>
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "0.625rem", width: "100%" }}>
          <AutocompleteTextField
            label="Indeks"
            value={values.index}
            onChange={handlers.onIndexChange}
            css={{ width: "100%" }}
            keyName="item-index"
            getOptions={async (searchText) => {
              const results = await docs.itemsAutocomplete({ text: searchText, field: "productId" });

              return results.value || [];
            }}
          />
          <AutocompleteTextField
            label="Grupa"
            value={values.group}
            onChange={handlers.onGroupChange}
            css={{ width: "100%" }}
            keyName="item-group"
            getOptions={async (searchText) => {
              const results = await docs.itemsAutocomplete({ text: searchText, field: "productGroup" });

              return results.value || [];
            }}
          />
        </div>
      </div>
    </div>
  );
};
