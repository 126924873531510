import { ValidationError } from "../errors";
import { SerializerExtensions, serializerExtensions } from "../extensions";
import { UnsafeSerializer } from "../serializer";

export const string: UnsafeSerializer<string> & SerializerExtensions<string> = {
  serialize: (str: string) => {
    if (typeof str !== "string") throw new ValidationError(`Value is not a string (${typeof str})`);
    return str;
  },
  forceDeserialize: (raw: any) => {
    if (typeof raw !== "string") throw new ValidationError(`Value is not a string (${typeof raw})`);
    return raw;
  },
  ...serializerExtensions(),
};
