import React from "react";
import { MdCheckCircle, MdOutlinePersonSearch, MdQuestionMark } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { DescriptionField, DescriptionList } from "@megaron/dash-description-list";
import { Button } from "@megaron/dash-form";
import { AccountDto } from "@megaron/loyalty-accounts-contracts";

type Props = {
  account: AccountDto;
};

export const AccountDescriptionList: React.FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <DescriptionList>
        <DescriptionField label="Nr telefonu">{props.account.phoneNumber}</DescriptionField>
        <DescriptionField
          label={
            <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              Email
              {props.account.email && <MdCheckCircle />}
              {props.account.unverifiedEmail && <MdQuestionMark />}
            </div>
          }
        >
          {props.account.email ?? props.account.unverifiedEmail}
        </DescriptionField>
        <DescriptionField label="Imię i nazwisko">
          {props.account.firstName && `${props.account.firstName} ${props.account.lastName}`}
        </DescriptionField>
        <DescriptionField label="Data dołączenia">{props.account.joinedAt?.toLocaleString()}</DescriptionField>
        <DescriptionField label="Data rejestracji">{props.account.registeredAt?.toLocaleString()}</DescriptionField>
      </DescriptionList>
      <Button
        variant={"outline"}
        icon={<MdOutlinePersonSearch />}
        onClick={() => navigate(`/crm/customers/id/${props.account.uuid}`)}
      >
        Klient w CRM
      </Button>
    </>
  );
};
