import { AnyUser } from "@megaron/access-control";
import { HttpAction, HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { myAccountDataDtoSerializer, registerTaxpayerInputSerializer } from "./account";
import { blikCheckActions } from "./blikChecks";
import { dataExportActions } from "./dataExport";
import { myOrderDtoSerializer, orderDtoSerializer } from "./order";
import { shoperActions } from "./shoper";
import { taxDeclarationActions } from "./taxDeclaration";

const myOrders = HttpAction({
  path: "/myOrders",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: Serializers.array(myOrderDtoSerializer),
});

const myOrder = HttpAction({
  path: "/myOrders/uuid/:purchaseUuid",
  method: "get",
  paramsSerializer: Serializers.object({
    purchaseUuid: uuidSerializer,
  }),
  valueSerializer: myOrderDtoSerializer.nullable(),
  requiresAuth: true as const,
});

const registerTaxpayer = HttpAction({
  path: "/registerTaxpayer",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: registerTaxpayerInputSerializer,
});

const myAccountData = HttpAction({
  path: "/myAccountData",
  method: "get",
  requiresAuth: true as const,
  valueSerializer: myAccountDataDtoSerializer.nullable(),
});

const orders = HttpAction({
  path: "/orders",
  method: "get",
  requiresAuth: true as const,
  qsSerializer: Serializers.object({
    userUuid: uuidSerializer,
    rewardId: Serializers.jsonString(Serializers.array(Serializers.string)),
    isFulfilled: Serializers.booleanString,
    limit: Serializers.integer,
    offset: Serializers.integer,
  }).partial(),
  valueSerializer: Serializers.object({
    items: Serializers.array(orderDtoSerializer),
    count: Serializers.integer,
  }),
});

const order = HttpAction({
  path: "/orders/uuid/:uuid",
  method: "get",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    uuid: uuidSerializer,
  }),
  valueSerializer: orderDtoSerializer,
  errorSerializer: Serializers.stringOneOf("OrderNotFound"),
});

const restartOrderFulfillment = HttpAction({
  path: "/orders/uuid/:uuid/restartFulfillment",
  method: "post",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    uuid: uuidSerializer,
  }),
  errorSerializer: Serializers.stringOneOf(
    "OrderCancelled",
    "ConflictingFulfillment",
    "TaxpayerNotRegistered",
    "OrderNotFound",
  ),
});

const cancelOrder = HttpAction({
  path: "/orders/uuid/:uuid/cancel",
  method: "post",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    uuid: uuidSerializer,
  }),
  errorSerializer: Serializers.stringOneOf("OrderNotFound", "OrderAlreadyCancelled", "FulfillmentInProgress"),
});

const recalculateTaxedValues = HttpAction({
  path: "/recalculateTaxedValues",
  method: "post",
  requiresAuth: true as const,
});

export const ordersHttpService = HttpService({
  orders,
  order,
  myOrders,
  myOrder,
  registerTaxpayer,
  myAccountData,
  restartOrderFulfillment,
  cancelOrder,
  recalculateTaxedValues,
  ...blikCheckActions,
  ...shoperActions,
  ...taxDeclarationActions,
  ...dataExportActions,
});

export type OrdersHandlers = HandlerMap<typeof ordersHttpService, AnyUser>;
