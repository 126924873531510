import { useDialogRoute } from "@megaron/dash-dialog";
import { IndexModuleDto } from "@megaron/docs-contracts";

import { DocsIndexDialog } from "./DocsIndexDialog";

type Props = {
  docsIndex: IndexModuleDto;
  onRefresh: () => void;
  isRefreshLoading: boolean;
};

export const DocsIndexDialogElement: React.FC<Props> = ({ docsIndex, onRefresh, isRefreshLoading }) => {
  const docsIndexDialog = useDialogRoute(`/${docsIndex.indexVersionName}`, ({ onClose }) => (
    <DocsIndexDialog
      onClose={onClose}
      docsIndex={docsIndex}
      onRefresh={onRefresh}
      isRefreshLoading={isRefreshLoading}
    />
  ));

  return docsIndexDialog.element;
};
