import { MyDraftSimple } from "@megarax/crm-contracts";
import { Pagination } from "@megarax/ui-components";
import { Container, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

import { DraftRow } from "./DraftRow";

interface Props {
  navigateToDetails: (uuid: string) => void;
  draftsQuery: DraftsQuery;
  pagination: {
    onChange: (e: React.ChangeEvent<unknown>, page: number) => void;
    count: number;
    page: number;
  };
}

export interface DraftsQuery {
  count: number;
  items: MyDraftSimple[];
}

export const DraftsTab: React.FC<Props> = ({ draftsQuery, navigateToDetails, pagination }) => {
  return (
    <Container disableGutters>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Zamawiający</TableCell>
            <TableCell>Płatnik</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {draftsQuery.items.map((draft) => (
            <DraftRow key={draft.uuid} onClick={() => navigateToDetails(draft.uuid)} draft={draft} />
          ))}
        </TableBody>
      </Table>
      <Pagination {...pagination} allCount={draftsQuery.count} />
    </Container>
  );
};
