import { Month } from "@megarax/crm-contracts";
import { Table, TableColumn } from "@megarax/ui-components";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import React from "react";

import { CostTableRow } from "./CostsView";

interface Props {
  rows: CostTableRow[];
}

export const CostTablesDataGrid: React.FunctionComponent<Props> = ({ rows }) => {
  const classes = useStyles();

  const months = (_.keys(rows[0]?.costs) as Month[]).sort();

  const columns: TableColumn<CostTableRow>[] = [
    { title: "EAN", getValue: (r) => r.gtin },
    {
      title: "Nazwa",
      getValue: (r) => r.tradeItemName ?? "",
    },
    ...months.map<TableColumn<CostTableRow>>((month) => ({
      title: month,
      getValue: (r) => r.costs[month]?.toFixed(2) ?? "-",
    })),
  ];

  return (
    <div className={classes.table}>
      <Table stickyHeader items={rows} getKey={(r) => r.gtin} columns={columns}></Table>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {},
}));
