import { Failure, Ok } from "@megaron/result";

import { serializerExtensions, SerializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export const array = <T>(elementSerializer: Serializer<T>): Serializer<T[]> & SerializerExtensions<T[]> => ({
  serialize: (items) => {
    if (!Array.isArray(items)) throw new Error("Not an array");
    return items.map(elementSerializer.serialize);
  },
  deserialize: (items) => {
    if (!Array.isArray(items)) return Failure("NotAnArray");

    const result: T[] = [];
    const errors: { [index: number]: unknown } = {};
    let hasErrors = false;

    for (const [index, item] of items.entries()) {
      const deserialized = elementSerializer.deserialize(item);
      if (deserialized.isOk) {
        result.push(deserialized.value);
      } else {
        hasErrors = true;
        errors[index] = deserialized.error;
      }
    }

    if (hasErrors) return Failure(errors);
    return Ok(result);
  },
  ...serializerExtensions(),
});
