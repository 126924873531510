import { Box, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useQuery } from "react-query";

import { fromDateString, Uuid } from "@megarax/common";
import { ChatBoxContainer } from "@megarax/crm-chat-webapp";
import { visitResource } from "@megarax/crm-contracts";
import { CustomerRecentOrdersSectionContainer } from "@megarax/crm-order-webapp";
import { useProfile } from "@megarax/iam-webapp";
import { useCurrentUser, useResourceProviderV2 } from "@megarax/react-client";
import { BreadcrumbMarker, commonErrorsMap, PageTitleManager, useSnackbarErrorHandler } from "@megarax/ui-components";

import { ContactListContainer } from "../../contacts";
import { CustomerReportsCardContainer } from "../../myPriceReport";
import { useCustomer } from "../../resourceHooks";
import { ChecklistSectionContainer } from "../ChecklistSection";
import { UnfinishedVisitsAlertContainer } from "../UnfinishedVisitsAlert";
import { ActionsMenu } from "./ActionsMenu";
import { CustomerHeader } from "./CustomerHeader";
import { TimeSection } from "./TimeSection";
import { useVisitActions } from "./useVisitActions";
import { ViewLayout } from "./ViewLayout";
import { VisitHeader } from "./VisitHeader";

interface Props {
  uuid: Uuid;
}

export const VisitViewContainer: React.FunctionComponent<Props> = ({ uuid }) => {
  const classes = useStyles();
  const errorHandler = useSnackbarErrorHandler(commonErrorsMap);
  const visitProvider = useResourceProviderV2(visitResource);

  const { data: visitResult, isFetching, refetch } = useQuery(["visit", uuid], () =>
    visitProvider.byUuid(uuid).retrieve(),
  );

  if (visitResult?.isFailure) {
    if (visitResult.error === "VisitNotFound") return null; // TODO "not found" screen
    errorHandler(visitResult.error);
    return null;
  }
  const visit = visitResult?.value;

  const actions = useVisitActions(uuid, visit && fromDateString(visit.date), refetch);

  const customerUuid = visit?.customerUuid;
  const customer = useCustomer(customerUuid);

  const profile = useProfile(visit && { id: visit.userId });

  const currentUser = useCurrentUser();

  const isMyVisit = visit !== undefined && currentUser !== undefined && visit?.userId === currentUser?.id;

  return (
    <>
      {customer && <PageTitleManager title={`Wizyta ${customer.name}`} />}
      {customer && <BreadcrumbMarker title={customer.name} id="visit" />}
      <ViewLayout
        alerts={isMyVisit && <UnfinishedVisitsAlertContainer ignoredVisitUuid={uuid} />}
        header={
          <VisitHeader
            customerName={customer?.name}
            profilePictureUrl={profile?.pictureUrl ?? undefined}
            isCancelled={visit && visit?.isCancelled}
            isCompleted={visit && visit?.endTime !== null}
            startTime={visit?.startTime}
            scheduledStartTime={visit?.scheduledStartTime}
            actionsMenu={
              !visit?.isCancelled && (
                <ActionsMenu isCancelled={visit?.isCancelled ?? false} onCancel={actions.cancelVisit} />
              )
            }
          />
        }
        timeSection={
          visit && (
            <TimeSection
              isMine={isMyVisit}
              isCancelled={visit.isCancelled}
              onStart={actions.start}
              onEnd={actions.end}
              onStartTimeChange={actions.changeStartTime}
              onEndTimeChange={actions.changeEndTime}
              startTime={visit.startTime}
              visitDate={visit.date}
              endTime={visit.endTime}
            />
          )
        }
        checklistSection={<ChecklistSectionContainer isMine={isMyVisit} visitUuid={uuid} />}
        customerHeader={
          visit && customer && <CustomerHeader customerName={customer.name} customerUuid={visit.customerUuid} />
        }
        chat={visit && <ChatBoxContainer threadIdentifier={{ type: "customer", customerUuid: visit.customerUuid }} />}
        contacts={visit && <ContactListContainer customerUuid={visit.customerUuid} />}
        priceReports={
          visit && <CustomerReportsCardContainer customerUuid={visit.customerUuid} date={fromDateString(visit.date)} />
        }
        recentOrders={visit && <CustomerRecentOrdersSectionContainer customerUuid={visit.customerUuid} />}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({}));
