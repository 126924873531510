import { useTheme } from "@emotion/react";
import { MdCheckCircleOutline } from "react-icons/md";
import tinycolor from "tinycolor2";

import { useProfiles } from "@megaron/megarax-webapps";

export const ApprovalTile: React.FC<{ actingUser: string; time?: Date }> = ({ actingUser, time }) => {
  const theme = useTheme();
  const { profile } = useProfiles();

  const formatDate = (date: Date, time?: boolean) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const profileData = profile(actingUser);
  const formattedTime = time && formatDate(new Date(time));

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        background: `${tinycolor(theme.colors.success).setAlpha(0.1)}`,
        border: `1px solid ${tinycolor(theme.colors.success).setAlpha(0.1)}`,
        borderRadius: theme.smallBorderRadius,
        padding: "16px",
      }}
    >
      <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <span
          css={{
            fontSize: "16px",
            fontWeight: 700,
            color: theme.colors.success,
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <MdCheckCircleOutline size={"20px"} />
          Wątek zatwierdzony
        </span>
        <span css={{ fontSize: "14px", color: theme.colors.secondaryText }}>{formattedTime}</span>
      </div>

      <span
        css={{ fontSize: "14px", color: theme.colors.secondaryText }}
      >{`Zatwierdził(a) zgodnie z planem wydatków/zarządzeniem: ${profileData?.firstName} ${profileData?.lastName}`}</span>
    </div>
  );
};
