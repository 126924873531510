import React from "react";

import { BreadcrumbMarker } from "./BreadcrumbMarker";

type Props = {
  breadcrumbs: { id: string; to?: string; title: string }[];
};

export const BreadcrumbMarkers: React.FC<Props> = ({ breadcrumbs }) => {
  return (
    <>
      {breadcrumbs.map((props) => (
        <BreadcrumbMarker {...props} key={`breadcrumb-${props.id}`} />
      ))}
    </>
  );
};
