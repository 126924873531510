import { Sort } from "@megaron/dash-filters";
import { ThreadSortField } from "@megaron/docs-contracts";

import { Filters, SortField } from "./useThreadFilters";

type Props = {
  onSortChange: (sort: SortField | undefined) => void;
  filters: Filters;
};

const sortOptions = [
  { label: "Numer wątku", value: "referenceNumber" },
  { label: "Tytuł", value: "title" },
  { label: "Wystawca", value: "invoiceIssuer" },
  { label: "Numer Faktury", value: "invoiceNumber" },
  { label: "Kwota netto", value: "invoiceTotal" },
  { label: "Data utworzenia", value: "createdAt" },
  { label: "Data wystawienia", value: "invoiceIssueDate" },
  { label: "Data zaproszenia", value: "invitedAt" },
  { label: "Data zatwierdzenia", value: "approvedAt" },
  { label: "Deadline", value: "deadline" },
  { label: "Termin płatności", value: "dueDate" },
  { label: "Nr dekretu", value: "decreeNumber" },
] satisfies { label: string; value: ThreadSortField }[];

export const AllThreadsSortFilter: React.FC<Props> = ({ onSortChange, filters }) => {
  return (
    <Sort
      type="button"
      label={"Sortuj"}
      value={{
        field: filters.sort?.field || "",
        order: filters.sort?.order || "",
      }}
      options={sortOptions}
      onChange={(v) => onSortChange((v as SortField) ?? undefined)}
    />
  );
};
