export interface Ok<TValue> {
  isOk: true;
  isFailure: false;
  value: TValue;
  error?: never;

  map<TMapped>(callback: (value: TValue) => TMapped): Ok<TMapped>;
  flatMap<TResult extends Result<unknown, unknown>>(callback: (value: TValue) => TResult): TResult;

  mapError(): Ok<TValue>;
  flatMapError(): Ok<TValue>;

  assertOk(): Ok<TValue>;

  /**
   * @deprecated
   */
  mapAsync<TMapped>(callback: (value: TValue) => Promise<TMapped> | TMapped): AsyncResult<Ok<TMapped>>;

  /**
   * @deprecated
   */
  flatMapAsync<TResult extends Result<unknown, unknown>>(
    callback: (value: TValue) => Promise<TResult> | TResult,
  ): AsyncResult<TResult>;
}

export function Ok(): Ok<void>;
export function Ok<T>(value: T): Ok<T>;
export function Ok<T>(value?: T): Ok<T | void> {
  return {
    isOk: true,
    isFailure: false,
    value,
    map: (callback) => {
      const mapped = callback(value);
      return Ok(mapped);
    },
    flatMap: (callback) => callback(value),
    mapError() {
      return Ok(value);
    },
    flatMapError() {
      return Ok(value);
    },
    assertOk: () => Ok(value),

    mapAsync: (callback) => AsyncResult(Promise.resolve(Ok(value))).map(callback) as any,
    flatMapAsync: (callback) => AsyncResult(Promise.resolve(Ok(value))).flatMap(callback) as any,
  };
}

export interface Failure<TError> {
  isOk: false;
  isFailure: true;
  error: TError;
  value?: never;

  map: () => Failure<TError>;
  flatMap: () => Failure<TError>;

  mapError<TMappedError>(mapper: (error: TError) => TMappedError): Failure<TMappedError>;

  flatMapError<TResult extends Result<unknown, unknown>>(callback: (error: TError) => TResult): TResult;

  assertOk: () => never;

  /**
   * @deprecated
   */
  mapAsync: () => AsyncResult<Failure<TError>>;
  /**
   * @deprecated
   */
  flatMapAsync: () => AsyncResult<Failure<TError>>;
}

export function Failure(): Failure<void>;
export function Failure<T extends string>(error: T): Failure<T>;
export function Failure<T>(error: T): Failure<T>;
export function Failure<T>(error?: T): Failure<T | void> {
  return {
    isOk: false,
    isFailure: true,
    error,
    map() {
      return Failure(error);
    },
    mapError(callback) {
      return Failure(callback(error));
    },
    flatMap: () => Failure(error),
    flatMapError: (callabck) => callabck(error),
    assertOk: () => {
      throw new Error("Assertion failed. Error: " + JSON.stringify(error));
    },
    mapAsync: () => AsyncResult(Promise.resolve(Failure(error))),
    flatMapAsync: () => AsyncResult(Promise.resolve(Failure(error))),
  };
}

export type Result<TValue = never, TError = never> =
  | (TValue extends never ? never : Ok<TValue>)
  | (TError extends never ? never : Failure<TError>);

export const isResult = <TResult extends Result<any, any>>(item: TResult | any): item is TResult =>
  typeof item === "object" && item !== null && "isOk" in item && typeof item.isOk === "boolean";

export const isOk = <TValue, TError>(result: Ok<TValue> | Failure<TError>): result is Ok<TValue> =>
  result.isOk === true;

export const isFailure = <TValue, TError>(
  result: Result<TValue, TError> | Ok<TValue> | Failure<TError>,
): result is Result<never, TError> => result.isOk === false;

export type FunctionError<TFunction extends (...args: any) => any> = Extract<
  ReturnType<TFunction>,
  { error: any }
>["error"];

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

export type AsyncFunctionError<TFunction extends (...args: any) => any> = Extract<
  Awaited<ReturnType<TFunction>>,
  { error: unknown }
>["error"];

export const unpackOk = <TValue>(ok: Ok<TValue>) => ok.value;

export const assertOk = <TValue>(result: Ok<TValue> | Failure<any>) => result.assertOk();

export const forceUnpack = <TValue>(result: Ok<TValue> | Failure<any>) => result.assertOk().value;

/**
 * @deprecated
 */
type ExtractValue<TResult> = TResult extends Ok<infer TValue> ? TValue : never;
/**
 * @deprecated
 */
type ExtractError<TResult> = TResult extends Failure<infer TError> ? TError : never;

/**
 * @deprecated
 */
export type AsyncResult<TResult extends Result<unknown, unknown>> = Promise<TResult> & {
  map<TMapped>(
    callback: (value: ExtractValue<TResult>) => TMapped | Promise<TMapped>,
  ): AsyncResult<Result<TMapped, ExtractError<TResult>>>;

  flatMap<TMappedResult extends Result<unknown, unknown>>(
    callback: (value: ExtractValue<TResult>) => Promise<TMappedResult> | TMappedResult,
  ): AsyncResult<Result<ExtractValue<TMappedResult>, ExtractError<TMappedResult> | ExtractError<TResult>>>;

  mapError<TMappedError>(
    callback: (value: ExtractError<TResult>) => TMappedError | Promise<TMappedError>,
  ): AsyncResult<Result<ExtractValue<TResult>, TMappedError>>;

  flatMapError<TMappedResult extends Result<unknown, unknown>>(
    callback: (error: ExtractError<TResult>) => Promise<TMappedResult> | TMappedResult,
  ): AsyncResult<Result<ExtractValue<TMappedResult> | ExtractValue<TResult>, ExtractError<TMappedResult>>>;
};

/**
 * @deprecated
 */
export const AsyncResult = <TResult extends Result<unknown, unknown>>(
  promise: Promise<TResult>,
): AsyncResult<TResult> => {
  return {
    then: (...args: any[]) => promise.then(...args) as any,
    catch: (...args: any[]) => promise.catch(...args) as any,
    finally: (...args: any[]) => promise.finally(...args) as any,
    [Symbol.toStringTag]: promise[Symbol.toStringTag] as any,
    map: (callback: any) => {
      const mappedPromise = promise.then(async (result) => {
        const mapped = result.map(callback);

        if (mapped.isFailure) return mapped;
        if (mapped.value instanceof Promise) return Ok(await mapped.value);
        return mapped;
      });

      return AsyncResult(mappedPromise as any);
    },
    flatMap: (callback: any) => AsyncResult(promise.then((result) => result.flatMap(callback)) as any),
    mapError: (callback: any) => {
      const mappedPromise = promise.then(async (result) => {
        const mapped = result.mapError(callback);

        if (mapped.isOk) return mapped;
        if (mapped.error instanceof Promise) return Failure(await mapped.error);
        return mapped;
      });
      return AsyncResult(mappedPromise as any);
    },
    flatMapError: (callback: any) => AsyncResult(promise.then((result) => result.flatMapError(callback)) as any),
  };
};
