import { DocumentError } from "@megarax/crm-contracts";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  documentError: DocumentError;
  skipDocument: (orderUuid: string) => Promise<any>;
  retryDocument: (orderUuid: string) => Promise<any>;
  children?: React.ReactNode;
}

const errorText = {
  RecipientGlnNotFound: "Nieznany odbiorca.",
  BuyerGlnNotFound: "Nieznany kupujący.",
  UnableToParseDocument: "Nie udało się odczytać dokumentu.",
  UnknownGtin: "Nieznany GTIN.",
} as const;

export const EdiErrorAlert: React.FunctionComponent<Props> = ({
  children,
  documentError,
  skipDocument,
  retryDocument,
}) => {
  const classes = useStyles();

  return (
    <Alert
      severity="error"
      className={classes.alert}
      action={
        <>
          <Button onClick={() => retryDocument(documentError.orderUuid)}>Spróbuj ponownie</Button>
          <Button onClick={() => skipDocument(documentError.orderUuid)}>Usuń</Button>
        </>
      }
    >
      <AlertTitle>Błąd importu EDI: {errorText[documentError.error]}</AlertTitle>
      {documentError.orderNumber && <Typography variant="body1">Nr zamówienia: {documentError.orderNumber}</Typography>}
      <Typography variant="subtitle2">{documentError.path}</Typography>
    </Alert>
  );
};

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: "0.5rem",
    overflowX: "auto",
  },
}));
