import React, { createContext, useContext } from "react";

import { UserDoc } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";

const ProfilesContext = createContext<UserDoc[] | undefined>(undefined);

export const ProfilesProvider: React.FC<{ children: React.ReactNode; isAuthLoaded: boolean }> = ({
  children,
  isAuthLoaded,
}) => {
  const profiles = useClientManager("docs")
    .searchUsers()
    .useQuery({ limit: 10000, offset: 0 }, { enabled: isAuthLoaded });

  return <ProfilesContext.Provider value={profiles.data?.items}>{children}</ProfilesContext.Provider>;
};

export const useProfiles = () => {
  const profiles = useContext(ProfilesContext);

  const getProfileByEmail = (email: string | null) => {
    if (!email) return null;
    return profiles?.find((profile) => profile.email === email) || null;
  };

  const getProfilesByEmailList = (emails: string[]) => {
    return profiles?.filter((profile) => emails.includes(profile.email));
  };

  return {
    allProfiles: profiles,
    profiles: getProfilesByEmailList,
    profile: getProfileByEmail,
  };
};
