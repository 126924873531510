import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";

export const brevoActions = {
  exportContactsToBrevo: HttpAction({
    path: "/exportContactsToBrevo",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      listIds: Serializers.integer.array(),
      disableNotification: Serializers.boolean.optional(),
    }),
  }),
};
