import { Month, MonthTargetList, regionResource, targetTableResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import {
  BreadcrumbMarkers,
  FeedbackSnackbarContext,
  LoadingBar,
  PageTitleManager,
  useSnackbarErrorHandler,
} from "@megarax/ui-components";
import { errorMessages } from "@megarax/validators";
import _ from "lodash";
import React, { useContext } from "react";

import { InputRow } from "./input";
import { TargetsView, TargetTableRow } from "./TargetsView";

export const TargetsViewContainer = () => {
  const targetTableProvider = useResourceProviderV2(targetTableResource);
  const regionProvider = useResourceProviderV2(regionResource);
  const feedbackSnackbar = useContext(FeedbackSnackbarContext);
  const handleError = useSnackbarErrorHandler(errorMessages);

  const {
    value: months,
    loading,
    reload,
  } = useAsyncLoad(
    () =>
      targetTableProvider
        .retrieve()
        .mapError(handleError)
        .then((r) => r.value),
    [],
  );

  const { value: regions, loading: regionsLoading } = useAsyncLoad(
    () =>
      regionProvider
        .list({ limit: 9999 })
        .mapError(handleError)
        .then((r) => r.value?.items),
    [],
  );

  const regionNameMap = _(regions)
    .keyBy((r) => r.uuid)
    .mapValues((r) => r.name)
    .value();

  const updateTargetTable = async (rows: InputRow[]) => {
    targetTableProvider
      .update(undefined, inputRowsToMonthLists(rows))
      .map(async () => {
        await reload();
        feedbackSnackbar.pushMessage({
          severity: "success",
          content: "Pomyślnie zaktualizowano rabaty.",
        });
      })
      .mapError(handleError);
  };

  return (
    <div>
      <PageTitleManager title="Plan marży" />
      <BreadcrumbMarkers
        breadcrumbs={[
          { title: "Marże", id: "profitMargins" },
          { title: "Plan marży", id: "targets" },
        ]}
      />
      <LoadingBar loading={loading || regionsLoading} />

      {months ? <TargetsView onInput={updateTargetTable} rows={monthListsToTableRows(months, regionNameMap)} /> : null}
    </div>
  );
};

type RegionNameMap = {
  [regionUuid: string]: string | undefined;
};

const monthListsToTableRows = (
  monthLists: MonthTargetList[],
  regionNames: RegionNameMap | undefined,
): TargetTableRow[] => {
  const regionUuids = _(monthLists)
    .flatMap((m) => _.keys(m.targets))
    .uniq()
    .value();

  return regionUuids.map<TargetTableRow>((regionUuid) => ({
    regionUuid: regionUuid,
    regionName: regionNames && regionNames[regionUuid],
    targets: _(monthLists)
      .keyBy((m) => m.month)
      .mapValues((m) => m.targets[regionUuid] ?? null)
      .value(),
  }));
};

const inputRowsToMonthLists = (rows: InputRow[]): MonthTargetList[] => {
  const months = _(rows)
    .flatMap((r) => _.keys(r.targets) as Month[])
    .uniq()
    .sort()
    .value();

  return months.map<MonthTargetList>((month) => ({
    month,
    targets: _(rows)
      .keyBy((row) => row.regionUuid)
      .mapValues((row) => row.targets[month] ?? null)
      .value(),
  }));
};
