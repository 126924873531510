import { useTheme } from "@emotion/react";
import tinycolor from "tinycolor2";

import { Avatar } from "@megaron/dash-avatar";

type Props = {
  message: string | JSX.Element | JSX.Element[];
  onClick?: () => void;
  fullName: string;
  time: string;
  imageSrc?: string;
  showWarningColor?: boolean;
  hideAvatar?: boolean;
  isMessageHighlighted?: boolean;
  badge?: JSX.Element;
};

export const CommentEntry: React.FC<Props> = ({
  showWarningColor,
  hideAvatar,
  imageSrc,
  fullName,
  time,
  message,
  isMessageHighlighted,
  onClick,
  badge,
}) => {
  const theme = useTheme();

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
        gap: "10px",
        width: "100%",
        padding: "0.25rem 0",
        "&:last-child hr": {
          display: "none",
        },
      }}
    >
      <hr
        css={{
          height: "calc(100% - 24px)",
          width: "1px",
          background: `${tinycolor(theme.colors.primary).setAlpha(0.3)}`,
          position: "absolute",
          left: "15px",
          top: "33px",
          border: "none",
        }}
      />
      {!hideAvatar && <Avatar src={imageSrc} />}
      <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "12px",
            width: "100%",
            textAlign: "right",
          }}
        >
          <span css={{ color: showWarningColor ? theme.colors.warning : theme.colors.primary }}>{fullName}</span>
          <span css={{ opacity: 0.6 }}>{time}</span>
        </div>
        <div
          onClick={onClick}
          css={{
            position: "relative",
            background: showWarningColor ? `${tinycolor(theme.colors.warning).setAlpha(0.1)}` : "white",
            fontSize: "14px",
            padding: badge ? "10px 12px 28px" : "10px 12px",
            cursor: onClick ? "pointer" : "text",
            border: isMessageHighlighted
              ? `2px solid ${theme.colors.primary}`
              : `1px solid ${showWarningColor ? tinycolor(theme.colors.warning).setAlpha(0.1) : theme.colors.border}`,
            borderRadius: theme.smallBorderRadius,
            borderTopLeftRadius: 0,
          }}
        >
          {message}
          {badge && <div css={{ position: "absolute", bottom: "6px", right: "12px" }}>{badge}</div>}
        </div>
      </div>
    </div>
  );
};
