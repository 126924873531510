import { CustomerDetail } from "@megarax/crm-contracts";
import { DefinitionItem, DefinitionList, SkeletonLoader } from "@megarax/ui-components";
import React from "react";
import { Link } from "react-router-dom-v5-compat";

interface Props {
  buyer: { customer: CustomerDetail | undefined; uuid: string };
  payer: { customer: CustomerDetail | undefined; uuid: string };
  recipient: { customer: CustomerDetail | undefined; uuid: string };
  getClientUrl: (uuid: string) => string;
}

export const DraftDetailsInfo: React.FC<Props> = ({ buyer, payer, recipient, getClientUrl }) => {
  return (
    <DefinitionList>
      <DefinitionItem term="Nabywca">
        <SkeletonLoader
          asyncData={recipient.customer}
          component={(recipient) => <Link to={getClientUrl(recipient.uuid)}>{recipient.name}</Link>}
        />
      </DefinitionItem>
      <DefinitionItem term="Odbiorca faktury">
        <SkeletonLoader
          asyncData={payer.customer}
          component={(payer) => <Link to={getClientUrl(payer.uuid)}>{payer.name}</Link>}
        />
      </DefinitionItem>
      <DefinitionItem term="Oddział płatnika">
        <SkeletonLoader
          asyncData={buyer.customer}
          component={(buyer) => <Link to={getClientUrl(buyer.uuid)}>{buyer.name}</Link>}
        />
      </DefinitionItem>
    </DefinitionList>
  );
};
