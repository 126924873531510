import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { useProfile } from "@megarax/iam-webapp";
import { BreadcrumbMarker, PageTitleManager } from "@megarax/ui-components";

import { SalespersonsVisitsSwitch } from "../visits";
import { SalespersonDetailsContainer } from "./SalespersonDetails";

interface Props {
  userId: number;
}

export const SalespersonSwitch: React.FunctionComponent<Props> = ({ userId }) => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  const profile = useProfile({ id: userId });

  return (
    <>
      <PageTitleManager title={profile?.displayedName ?? "Handlowiec"} />
      <BreadcrumbMarker
        title={profile?.displayedName ?? "?"}
        to={`/crm/handlowcy/${userId}`}
        id="salesperson"
      />
      <Switch>
        <Route path={path} exact>
          <SalespersonDetailsContainer userId={userId} />
        </Route>

        <Route path={`${path}/wizyty`}>
          <SalespersonsVisitsSwitch userId={userId} />
        </Route>
      </Switch>
    </>
  );
};

const useStyles = makeStyles((theme) => ({}));
