import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const myCustomerDtoSerializer = Serializers.object({
  userUuid: uuidSerializer,
  totalCommission: Serializers.decimal,
  invitedAt: Serializers.datetime,
  lastCommissionAt: Serializers.datetime.nullable(),
  affiliateCodeId: Serializers.string,
  myCustomerNumber: Serializers.integer,
  bonusProgress: Serializers.decimal.nullable(),
  bonusRequirement: Serializers.decimal.nullable(),
  isBonusCompleted: Serializers.boolean,
  bonusValue: Serializers.decimal,
});

export type MyCustomerDto = SerializerValue<typeof myCustomerDtoSerializer>;
