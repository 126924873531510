import { css, useTheme } from "@emotion/react";
import React from "react";
import { MdCheck, MdClose } from "react-icons/md";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";

export type DialogPlacement = "top" | "bottom" | "left" | "right" | "center" | "fullscreen";

type Props = {
  children?: React.ReactNode;
  onClose?: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  hideHeader?: boolean;
  placement?: DialogPlacement;
  className?: string;
  checkCloseButton?: boolean;
};

export const Dialog: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const drawerCSS = css({
    position: "fixed",
    borderRadius: 0,
    margin: 0,
    maxWidth: "calc(100% - 1.5rem)",
    maxHeight: "calc(100% - 1.5rem)",
  });

  const fullscreenCss = css({
    borderRadius: 0,
    margin: 0,
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%",
    width: "100%",
  });

  const topCss = css(drawerCSS, {
    top: "0.75rem",
    height: "min-content",
    width: "100%",
    minHeight: "35%",
    borderRadius: theme.largeBorderRadius,
  });

  const bottomCss = css(drawerCSS, {
    bottom: "0.75rem",
    paddingBottom: "2rem",
    height: "min-content",
    width: "100%",
    minHeight: "35%",
    borderRadius: theme.largeBorderRadius,
  });

  const leftCss = css(drawerCSS, {
    left: 0,
    height: "100%",
    width: "min-content",
    maxWidth: "75%",
    borderBottomRightRadius: theme.largeBorderRadius,
    borderTopRightRadius: theme.largeBorderRadius,
  });

  const rightCss = css(drawerCSS, {
    right: 0,
    height: "100%",
    width: "min-content",
    maxWidth: "75%",
    borderBottomLeftRadius: theme.largeBorderRadius,
    borderTopLeftRadius: theme.largeBorderRadius,
  });

  return (
    <Modal
      isOpen={true}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick={true}
      preventScroll
      style={{
        overlay: {
          backgroundColor: theme.colors.overlay,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        },
      }}
      css={css(
        {
          margin: "2rem",
          border: "none",
          outline: "none",
          borderRadius: theme.largeBorderRadius,
          maxWidth: theme.siteMaxWidth,

          background: theme.colors.background,
          maxHeight: "calc(100% - 4rem)",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          boxShadow: "0 2px 4px rgba(0,0,0,0.25)",
        },
        props.placement === "fullscreen" && fullscreenCss,
        props.placement === "top" && topCss,
        props.placement === "bottom" && bottomCss,
        props.placement === "left" && leftCss,
        props.placement === "right" && rightCss,
      )}
      className={props.className}
    >
      <RemoveScroll css={{ height: "100%", display: "flex", flexDirection: "column", width: "100%" }}>
        {!props.hideHeader && (
          <div
            css={{
              padding: "0.75rem 1rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1rem",
              background: theme.colors.primary,
            }}
          >
            <div
              css={{
                fontFamily: theme.displayFontFamily,
                margin: 0,
                fontSize: "1rem",
                fontWeight: "normal",
                color: "white",
                width: "100%",
              }}
            >
              {props.header}
            </div>
            <button
              onClick={props.onClose}
              css={{
                background: "none",
                border: "none",
                padding: "0.25rem",
                margin: "-0.25rem",
                cursor: "pointer",
                radius: theme.smallBorderRadius,
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              {props.checkCloseButton ? <MdCheck size="1.5rem" /> : <MdClose size="1.5rem" />}
            </button>
          </div>
        )}
        <div css={{ minHeight: 0, padding: "1rem", flex: 1, display: "flex", flexDirection: "column" }}>
          {props.children}
        </div>
        {props.footer && (
          <div
            css={{
              padding: "0.75rem 1rem",
              marginTop: "auto",
            }}
          >
            <div css={{ display: "flex", flexDirection: "row" }}>{props.footer}</div>
          </div>
        )}
      </RemoveScroll>
    </Modal>
  );
};
