import { Serializers } from "@megaron/serializers";

export type ImportTestCodesInput = {
  groupNames: string[];
  codes: {
    id: string;
    key: string;
  }[];
};

export const importTestCodesInputSerializer = Serializers.object<ImportTestCodesInput>({
  groupNames: Serializers.array(Serializers.string),
  codes: Serializers.array(
    Serializers.object({
      id: Serializers.string,
      key: Serializers.string,
    }),
  ),
});
