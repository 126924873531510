import { newUuid, Uuid } from "@megarax/common";
import { MentionDto } from "@megarax/crm-contracts";
import { NotificationsActive, NotificationsOff, Send as SendIcon } from "@mui/icons-material";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { Box, IconButton, Popper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { getMentionsList, getParsedMessage, InputWithMentions } from "./mentions";
import { MentionableUsersList } from "./mentions/MentionableUsersList";

export type OnSend = (messageUuid: Uuid, messageContent: string, mentions: MentionDto[]) => Promise<void>;
export type OnSubscribe = () => Promise<void>;
export type OnUnsubscribe = () => Promise<void>;

interface Props {
  getMentionableUsers: (
    search: string,
    limit?: number,
  ) => { id: number; name: string; surname: string; pictureUrl: string | null }[];
  onSend: OnSend;
  isSubscribed: boolean | undefined;
  onSubscribe: OnSubscribe;
  onUnsubscribe: OnUnsubscribe;
}

export const MessageInput: React.FunctionComponent<Props> = ({
  getMentionableUsers,
  onSend,
  isSubscribed,
  onSubscribe,
  onUnsubscribe,
}) => {
  const [mentionsAnchor, setMentionsAnchor] = React.useState<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const classes = useStyles();
  const [uuid, setUuid] = React.useState(newUuid());
  const [content, setContent] = React.useState<string>("");
  const [isSending, setIsSending] = React.useState(false);

  const [mentionName, setMentionName] = React.useState<string>();

  const getMentionName = (content: string) => {
    const caret = inputRef.current?.selectionStart;
    if (!caret) return;
    const parsedContent = getParsedMessage(content);

    const matches = Array.from(parsedContent.slice(0, caret).matchAll(/@\w*\s?\w*$/g));
    const lastMatch = matches[matches.length - 1];
    return lastMatch ? lastMatch[0] ?? undefined : undefined;
  };

  const mentionableUsers = getMentionableUsers(mentionName ? mentionName.slice(1) : "", 10);
  const isMentioning = mentionName !== undefined && mentionableUsers.length > 0;

  React.useEffect(() => {
    setMentionName(getMentionName(content));
  }, [content]);

  const onInputKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    if ((e.key === "ArrowUp" || e.key === "ArrowDown") && isMentioning) e.preventDefault();
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (isMentioning) return;
      submit();
    }
  };

  const submit = async () => {
    setIsSending(true);
    const parsed = getMentionsList(content);
    await onSend(uuid, parsed.content, parsed.mentions).catch((err) => {
      setIsSending(false);
      throw err;
    });

    setUuid(newUuid());
    setContent("");
    setIsSending(false);
  };

  const onAddMentionClick = () => {
    inputRef.current?.focus();
    setContent(content + "@");
  };

  return (
    <div className={classes.container}>
      <div className={classes.notificationButtonContainer}>
        {isSubscribed !== false ? (
          <IconButton onClick={onUnsubscribe} disabled={isSubscribed === undefined}>
            <NotificationsActive />
          </IconButton>
        ) : (
          <IconButton onClick={onSubscribe}>
            <NotificationsOff />
          </IconButton>
        )}
      </div>

      <div className={classes.frame} ref={setMentionsAnchor}>
        <Popper id="mentions-popover" open={isMentioning} anchorEl={mentionsAnchor} placement="top-start">
          {content && (
            <MentionableUsersList
              mentionableUsers={mentionableUsers}
              setContent={setContent}
              inputRef={inputRef.current}
            />
          )}
        </Popper>
        <Box className={classes.framedContent} sx={{ position: "relative" }}>
          <InputWithMentions
            inputRef={inputRef}
            getMentionableUsers={getMentionableUsers}
            disabled={isSending}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            onKeyDown={onInputKeyDown}
            className={classes.input}
            style={{
              input: {
                height: "100%",
                padding: "8px 16px",
                borderStyle: "none",
                outline: "none",
              },
              highlighter: {
                height: "100%",
                padding: "8px 16px",
              },
              suggestions: {
                display: "none",
              },
            }}
          />
          <Box position="absolute" right={0} top={0}>
            <IconButton onClick={onAddMentionClick}>
              <PersonPinIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
      <div className={classes.sendButtonContainer}>
        <IconButton className={classes.sendButton} color="primary" disabled={!content} onClick={submit}>
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 2, 2),
    display: "flex",
    flexDirection: "row",
  },
  input: {
    width: "100%",
    height: "100%",
  },
  frame: {
    flexGrow: 1,
    borderWidth: "1px",
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    borderRadius: "20px",
  },
  framedContent: {
    height: "100%",
  },
  sendButtonContainer: {
    marginLeft: theme.spacing(1),
    display: "flex",

    alignContent: "center",
  },
  notificationButtonContainer: {
    marginRight: theme.spacing(0.5),
    display: "flex",

    alignContent: "center",
  },
  sendButton: {},
}));
