import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";

import { Result } from "@megaron/result";
import { TextInput } from "@megarax/ui-components";

interface Props {
  nameMacroregion: (name: string) => Promise<Result<void, void>>;
  onComplete: () => void;
  closeDialog: () => void;
}

export const NameStep: React.FC<Props> = ({ nameMacroregion, onComplete, closeDialog }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");

  const nextStepDisabled = !name || loading;

  const onNameMacroregion = async () => {
    if (nextStepDisabled) return;
    const result = await nameMacroregion(name);
    if (result.isFailure) return setLoading(false);
    onComplete();
  };

  return (
    <div>
      <Box mb={2}>
        <Typography variant="subtitle2">Zarejestruj nowy makro region:</Typography>
      </Box>
      <Box mb={2}>
        <TextInput fullWidth label="Nazwa" variant="outlined" value={name} onChange={setName} />
      </Box>

      <Box display="flex" justifyContent="flex-end" pb={2} style={{ gap: "16px" }}>
        <Button onClick={closeDialog}>Anuluj</Button>
        <Button color="primary" disabled={nextStepDisabled} onClick={onNameMacroregion}>
          Zapisz
        </Button>
      </Box>
    </div>
  );
};
