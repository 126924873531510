import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";
import React, { useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { useQueryClient } from "react-query";

import { Dialog } from "@megaron/dash-dialog";
import { Button, DecimalField, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SelectDialog } from "@megaron/dash-select";
import { useToast } from "@megaron/dash-toast";
import { BudgetDoc, ThreadDto } from "@megaron/invoices-contracts";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { newUuid } from "@megaron/uuid";

import { BudgetTile } from "./BudgetTile";

type DialogType = "budget" | "validity";

type BudgetSignParams = {
  uuid: string;
  subject: string;
  amount: Decimal;
  threadUuid: string;
  budgetUuid: string;
  isSuggestion: boolean;
};

type ValiditySignParams = {
  uuid: string;
  subject: string;
  amount: Decimal;
  threadUuid: string;
};

type Props = {
  thread: ThreadDto;
  queryKey: string | string[];
  onClose: () => void;
  type: DialogType;
};

type BudgetOption = BudgetDoc & { label: string; value: string };

export const SignatureDialog: React.FC<Props> = ({ thread, queryKey, onClose, type }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const theme = useTheme();
  const { isMobile } = useDeviceType();

  const signBudgetMutation = useClientManager("invoices").signBudget().useMutation();
  const signValidityMutation = useClientManager("invoices").signValidity().useMutation();

  const mutationHook = type === "budget" ? signBudgetMutation : signValidityMutation;

  const sumOfAmountsWithSuggestions =
    type === "budget"
      ? thread.budgetSignatures
          .map((b) => new Decimal(b.amount))
          .reduce((total, current) => total.plus(current), new Decimal(0))
      : thread.validitySignatures
          .map((b) => new Decimal(b.amount))
          .reduce((total, current) => total.plus(current), new Decimal(0));

  const total = Number(thread.invoice.total);
  const sum = Number(sumOfAmountsWithSuggestions);
  const restAmount = Number.isNaN(total - sum) ? 0 : total - sum;
  const [subject, setSubject] = useState<string>("");
  const [amount, setAmount] = useState<string>(restAmount.toFixed(2).toString());
  const [budget, setBudget] = useState<BudgetOption | undefined>();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  const docs = useServiceClient("docs");

  const handleAmountChange = (value: string) => {
    setAmount(value);
  };

  const sign = async () => {
    try {
      const decimalAmount = new Decimal(amount);
      const params: BudgetSignParams | ValiditySignParams =
        type === "budget"
          ? {
              uuid: newUuid(),
              subject,
              amount: decimalAmount,
              threadUuid: thread.uuid,
              budgetUuid: budget?.uuid as string,
              isSuggestion: !isAuthorized,
            }
          : {
              uuid: newUuid(),
              subject,
              amount: decimalAmount,
              threadUuid: thread.uuid,
            };

      await mutationHook.mutateAsync(params as any, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
          onClose();
        },
        onError: () => {
          toast.error("Error", "Coś poszło nie tak");
        },
      });
    } catch (error) {
      toast.error("Error", "Invalid amount");
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {type === "budget" && !budget ? (
        <SelectDialog<BudgetOption>
          variant="single-select"
          renderItem={(budgetOption) => (
            <BudgetTile
              budget={budgetOption}
              css={{
                border: "none",
                "&:hover": { border: "none", background: theme.colors.background, cursor: "pointer" },
              }}
            />
          )}
          isOpen
          onClose={onClose}
          onSelect={(selected) => setBudget(selected ?? undefined)}
          noTrigger
          shouldNotCloseOnSelect
          search={async (searchText: string) => {
            const result = await docs.searchBudgets({ searchText, limit: 10000 });

            return result.value?.items?.map((b) => ({ ...b, label: b.name, value: b.uuid })) ?? [];
          }}
        />
      ) : (
        <Dialog
          onClose={onClose}
          placement={isMobile ? "top" : "center"}
          header={type === "budget" ? (budget ? "Podpisz fakturę" : "Wybierz budżet") : "Podpisz"}
          css={{ width: "400px" }}
        >
          {type === "budget" ? (
            <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <div>
                <label
                  css={{
                    color: theme.colors.primary,
                    fontSize: "0.875rem",
                    fontFamily: theme.displayFontFamily,
                  }}
                >
                  Budżet
                </label>
                <BudgetTile budget={budget as BudgetDoc} onAuthorizationChange={setIsAuthorized} />
              </div>
              {budget?.alert && (
                <span
                  css={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    color: theme.colors.primary,
                    fontSize: "14px",
                    padding: "0 8px",
                  }}
                >
                  <MdInfoOutline size={16} />
                  {budget.alert}
                </span>
              )}
              <TextField
                css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
                label={"Przedmiot"}
                value={subject}
                onChange={(e) => setSubject(e)}
                autoFocus
              />
              <DecimalField
                css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
                label={"Kwota"}
                value={amount}
                onChange={handleAmountChange}
              />
              <Button css={{ alignSelf: "flex-end", margin: "0.25rem 0" }} onClick={sign}>
                {isAuthorized ? "Podpisz" : "Zasugeruj"}
              </Button>
            </div>
          ) : (
            <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <TextField
                css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
                label={"Przedmiot"}
                value={subject}
                onChange={(e) => setSubject(e)}
                autoFocus
              />
              <DecimalField
                css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
                label={"Kwota"}
                value={amount}
                onChange={handleAmountChange}
              />
              <Button css={{ alignSelf: "flex-end", margin: "0.25rem 0" }} onClick={sign}>
                Podpisz
              </Button>
            </div>
          )}
        </Dialog>
      )}
    </>
  );
};
