import { createTheme } from "@mui/material";
import { plPL as corePlPL } from "@mui/material/locale";
import { plPL as dataGridPlPL } from "@mui/x-data-grid";
import { plPL } from "@mui/x-date-pickers/locales";

const themeInvoices = createTheme(
  {
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: "8px !important",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: "inherit",
        },
      },
    },
    palette: {
      primary: {
        main: "hsl(234, 75%, 25%)",
      },
      secondary: {
        main: "#ff1744",
      },
      warning: {
        main: "#ffc107",
      },
      error: {
        main: "#ff1744",
      },
      success: {
        main: "#008f4a",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  },
  plPL,
  dataGridPlPL,
  corePlPL,
);

export default themeInvoices;